import React, { useState, useEffect, Fragment, useRef } from "react";
import { Link } from "react-router-dom";
import SweetAlert from "sweetalert2";
import { Modal, ModalHeader, ModalBody, ModalFooter, Progress, } from "reactstrap";
import { Container, Row, Col, Card, Button, Table, Form, } from "react-bootstrap";
import Alert from "react-bootstrap/Alert";
import InputGroup from "react-bootstrap/InputGroup";
import EditToolTip from "../UI/ToolTips/EditToolTip";
import DeleteToolTip from "../UI/ToolTips/DeleteToolTip";
import { Typeahead } from "react-bootstrap-typeahead";
import axios from "axios";
import Breadcrumb from "../../components/common/breadcrumb";
import { FrontDesk_URL } from '../../constant';
import LoadingButton from '../commonComponent/LoadingButton';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { MultiSelect } from 'react-multi-select-component';
import '../../pages/react-multiselect.css';
import Select from 'react-select';

var temparr = [];
const Dcr = () => {
    const multiple = false;
    const [options, setOptions] = useState([]);
    const [options1, setOptions1] = useState([]);
    const [defaultdate, setDefaultDate] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [dcrworkingtype, setDCRWorkingType] = useState("0");
    const [dcruniqueid, setDCRUniqueId] = useState("");
    const [table, setTable] = useState([]);
    const [showstate, setShowState] = useState(false);
    const [dcrvisittype, setDCRVisitType] = useState("0");
    const [dcrtime, setDCRTime] = useState("0");
    const [hideflag, setHideFlag] = useState(true);
    const [isbuttonloading, setIsButtonLoading] = useState(false);
    const [defaultdatedynamic, setDefaultDateDynamic] = useState();
    const [stateid, setStateId] = useState("0");
    const [regionid, setRegionId] = useState("0");
    const [districtid, setDistrictId] = useState("0");
    const [doctorModal, setDoctorModal] = useState();
    const [sapstatelist, setSAPStateList] = useState([]);
    const [sapregionlist, setSAPRegionList] = useState([]);
    const [sapdistrictlist, setSAPDistrictList] = useState([]);
    const [specializationlist, setSpecializationList] = useState([]);
    const [doctortitlelist, setDoctorTitleList] = useState([]);
    const [jointsalesexecutive, setJointSalesExecutive] = useState([]);
    const [selectsalesperson, setSelectSalesPerson] = useState([]);
    const [dcrpartnertype, setDCRPartnerType] = useState([]);
    const [typeSelected, setTypeSelected] = useState([]);

    useEffect(() => {
        const today = new Date();
        const date = today.setDate(today.getDate());
        const defaultValue = new Date(date).toISOString().split('T')[0] // yyyy-mm-dd
        setDefaultDate(defaultValue);
        GetDCRWorkingType();
        GetDCRVisitTypeTime();
        GetJointSalesExecutive();
        GetThirdPartyPartners();
    }, []);
    let testreference = useRef();
    let testreference1 = useRef();
    let workingtype = useRef();
    let dcrdate = useRef();
    let finalremarks = useRef();
    let dcrvisittypee = useRef();
    let dcrtimee = useRef();
    let dcrremarks = useRef();
    let dcrpartners = useRef();
    const Displayalert = (DrUniqueId) => {
        SweetAlert.fire({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Ok",
            cancelButtonText: "Cancel",
            reverseButtons: true,
        }).then((result) => {
            if (result.value) {
                DeleteDoctor(DrUniqueId);
            } else {
                SweetAlert.fire("Your data is safe!");
            }
        });
    };
    const DeleteDoctor = async (DrUniqueId) => {
        setTable(table.filter((d) => d.DrUniqueId != DrUniqueId));
        SweetAlert.fire("Deleted!", "Data has been deleted.", "success");
        setOptions([]);
        setOptions1([]);
    }
    const GetDCRWorkingType = async () => {
        setIsLoading(true);
        let url = FrontDesk_URL + 'reports/getdcrworkingtype/' + localStorage.CentreSNo + '/' + localStorage.LoggedInUser
        await axios.get(url).then((response) => {
            const data = response.data;
            if (data.Success) {
                setStateId(data.Data[0].SAPStateId.split(" ")[0]);
                setRegionId(data.Data[0].SAPRegionId.split(" ")[0]);
                setDistrictId(data.Data[0].SAPDistrictId.split(" ")[0]);
                setDefaultDateDynamic(data.Data[0].LastDate.split(" ")[0]);
                dcrdate.current.value = data.Data[0].LastDate.split(" ")[0];
                setDCRWorkingType(data.Data.map((d) => (<option key={d.DCRWorkingTypeId} value={d.DCRWorkingTypeId}>{d.DCRWorkingType}</option>)))
                setIsLoading(false);
            }
            else {
                setStateId("0");
                setRegionId("0");
                setDistrictId("0");
                setDefaultDateDynamic("");
                dcrdate.current.value = "";
                setDCRWorkingType("0")
                SweetAlert.fire("No record found"); setIsLoading(false);
            }
        }).catch(function (error) { if (error.response) { ErrorFunction(error.response.status); } setIsLoading(false); });
    }
    const GetDCRVisitTypeTime = async () => {
        setIsLoading(true);
        let url = FrontDesk_URL + 'reports/getdcrtypeandtime/'
        await axios.get(url).then((response) => {
            const data = response.data;
            if (data.Success) {
                setDCRVisitType(data.Data.map((d) => (d.Flag == "1" ? <option key={d.Id} value={d.Id}>{d.TypeTime}</option> : null)))
                setDCRTime(data.Data.map((d) => (d.Flag == "2" ? <option key={d.Id} value={d.Id}>{d.TypeTime}</option> : null)))
                setIsLoading(false);
            }
            else { setIsLoading(false); }
        }).catch(function (error) { if (error.response) { ErrorFunction(error.response.status); } setIsLoading(false); });
    }
    const getdoctorsagainstsalesperson = async (e, owndoctorflag) => {
        if ((workingtype.current.value == "" || workingtype.current.value == "0") && (dcrdate.current.value == "" || dcrdate.current.value == undefined || dcrdate.current.value == null)) {
            if (owndoctorflag == "1") { testreference.current.clear(); }
            else if (owndoctorflag == "0") { testreference1.current.clear(); }
            SweetAlert.fire('Please select date and working type')
            return false;
        }
        if (dcrdate.current.value == "" || dcrdate.current.value == undefined || dcrdate.current.value == null) {
            if (owndoctorflag == "1") { testreference.current.clear(); }
            else if (owndoctorflag == "0") { testreference1.current.clear(); }
            SweetAlert.fire('Please select date.')
            return false;
        }
        if (workingtype.current.value == "" || workingtype.current.value == "0") {
            if (owndoctorflag == "1") { testreference.current.clear(); }
            else if (owndoctorflag == "0") { testreference1.current.clear(); }
            SweetAlert.fire('Please select working type.')
            return false;
        }
        if (e.target.value == "") {
            if (owndoctorflag == "1") { setOptions([]); }
            else if (owndoctorflag == "0") { setOptions1([]); } return false;
        }
        let url = FrontDesk_URL + 'reports/getmydoctoragainstsalesperson/' + e.target.value + '/' + localStorage.CentreSNo + '/' + owndoctorflag + '/' + localStorage.LoggedInUser + '/' + stateid + '/' + regionid + '/' + districtid
        await axios.get(url).then((response) => {
            const data = response.data;
            if (data.Success) {
                if (data === null) {
                    if (owndoctorflag == "1") { setOptions([]); }
                    else if (owndoctorflag == "0") { setOptions1([]); }
                } else {
                    if (owndoctorflag == "1") { setOptions(data.Data); }
                    else if (owndoctorflag == "0") { setOptions1(data.Data); }
                }
            }
            else {
                if (owndoctorflag == "1") { setOptions([]); }
                else if (owndoctorflag == "0") { setOptions1([]); }
            }
        }).catch(function (error) { ErrorFunction(error.response.status); });
    }
    const BindGrid = async (dcuniqueid, doctorname, isapproved, owndoctorflag, partnerflag) => {
        if (partnerflag == "1") {
            if (dcuniqueid == "") {
                if (owndoctorflag == "1") {
                    SweetAlert.fire('Please add my doctor'); testreference.current.clear(); return false;
                }
                else if (owndoctorflag == "0") {
                    SweetAlert.fire('Please add other doctor'); testreference1.current.clear(); return false;
                }
            }
        }
        if (partnerflag == "-1") { if (dcuniqueid == "0") { return false; } }
        for (var key in table) {
            if (table[key].DrUniqueId == dcuniqueid && partnerflag == "1") {
                SweetAlert.fire('Doctor already added');
                if (owndoctorflag == "1") { setOptions([]); testreference.current.clear(); } else if (owndoctorflag == "0") { setOptions1([]); testreference1.current.clear(); }
                return false;
            }
            if (table[key].DrUniqueId == dcuniqueid && partnerflag == "-1") {
                SweetAlert.fire('Partner already added');
                return false;
            }
        }
        var arr = [];
        arr.push({
            DrUniqueId: dcuniqueid,
            DoctorName: doctorname,
            MyOtherDoctorIdFlag: owndoctorflag, // 1 means my doctor and 0 means other doctor and -1 means partner,
            DCRVisitType: "0",
            DCRTime: "0",
            DCRRemarks: "",
            IsNewDoctor: (isapproved == "1" || isapproved.toLowerCase() == "true" ? "0" : isapproved == "0" || isapproved.toLowerCase() == "false" ? "1" : "-1"),
            SalesPersonIds: "0",
            PartnerFlag: partnerflag
        })
        setTable([...table, ...arr]);
        if (owndoctorflag == "1") { setOptions([]); testreference.current.clear(); }
        else if (owndoctorflag == "0") { setOptions1([]); testreference1.current.clear(); }
        else {
            setTypeSelected([]);
            //dcrpartners.current.value = "0" 
        }
    }
    const callstatechange = async (e) => {
        if (e.target.value == "0" || e.target.value == "") {
            setTable([]);
            temparr = [];
            setSelectSalesPerson([]);
            setShowState(false);
        }
        else { setSelectSalesPerson([]); setTable([]); temparr = []; setShowState(true); }
        if (e.target.value == "2" || e.target.value == "3" || e.target.value == "4") { setHideFlag(true); }
        else { setHideFlag(false); }
        if (finalremarks.current != undefined) {
            finalremarks.current.value = "";
        }
    }
    const CallPageReset = async () => {
        const today = new Date();
        const date = today.setDate(today.getDate());
        const defaultValue = new Date(date).toISOString().split('T')[0] // yyyy-mm-dd
        setDefaultDate(defaultValue);
        GetDCRWorkingType();
        GetDCRVisitTypeTime();
        GetJointSalesExecutive();
        GetThirdPartyPartners();
        finalremarks.current.value = "";
        setTable([]);
        setOptions([]);
        setOptions1([]);
        setSelectSalesPerson([]);
        temparr = [];
        if (testreference.current != undefined) { testreference.current.clear(); }
        if (testreference1.current != undefined) { testreference1.current.clear(); }
        workingtype.current.value = "0";
        setShowState(false);
        setHideFlag(true);
    }
    const SaveDCRData = async () => {
        if (workingtype.current.value == "1" || workingtype.current.value == "5" || workingtype.current.value.toLowerCase() == "true") {
            for (var key in table) {
                if ((table[key].DCRVisitType == "0" || table[key].DCRVisitType == "") || (table[key].DCRTime == "0" || table[key].DCRTime == "") || (table[key].DCRRemarks == "" || table[key].DCRRemarks == undefined)) {
                    SweetAlert.fire('Visit type, time and remarks is mandatory');
                    return false;
                }
            }
        }
        if (workingtype.current.value == "5") {
            for (var keys in table) {
                if (table[keys].SalesPersonIds == "0" || table[keys].SalesPersonIds == "") {
                    SweetAlert.fire('Sales executive is mandatory');
                    return false;
                }
            }
        }
        if (finalremarks.current.value == "" || finalremarks.current.value == undefined) {
            SweetAlert.fire('Remarks is mandatory');
            return false;
        }
        const requestOptions = {
            DCRDate: dcrdate.current.value, WorkingTypeId: workingtype.current.value, FinalRemarks: finalremarks.current.value,
            CentreId: localStorage.CentreSNo, LoginId: localStorage.LoggedInUser, StateId: stateid, RegionId: regionid, DistrictId: districtid,
            DCRDoctorData: JSON.stringify(table)
        };
        setIsButtonLoading(true);
        let url = FrontDesk_URL + 'reports/SaveDCRData'
        await axios.post(url, requestOptions).then((response) => {
            const data = response.data;
            setIsButtonLoading(false);
            if (data.Success) {
                SweetAlert.fire({ title: "Success", text: "Data Saved Successfully", icon: "success" });
                CallPageReset();
            }
            else {
                setIsButtonLoading(false);
                if (data.Data = "-1") { SweetAlert.fire({ title: "Error", text: "You had already submitted the details for the same date", icon: "error" }); return false; }
                else { SweetAlert.fire({ title: "Error", text: data.Data, icon: "error" }); return false; }
            }
        }).catch(function (error) { setIsButtonLoading(false); if (error.response) { ErrorFunction(error.response.status); } });
    }
    const CallType = async (e, DrUniqueId, Flag) => {
        var arr = [];
        arr = table;
        if (Flag == "4") {
            if (e.length > 0) {
                temparr = temparr.filter((l) => l.DrUniqueId != DrUniqueId)
                temparr = [...temparr, ...e.map(v => ({ ...v, DrUniqueId: DrUniqueId }))]
                temparr = temparr.filter(val => !e.includes(val));
                setSelectSalesPerson(temparr);
            }
            else {
                setSelectSalesPerson([]); temparr = [];
            }
        }
        for (var key in arr) {
            if (Flag == "1") { if (arr[key].DrUniqueId == DrUniqueId) { arr[key].DCRVisitType = e.target.value; } }
            if (Flag == "2") { if (arr[key].DrUniqueId == DrUniqueId) { arr[key].DCRTime = e.target.value; } }
            if (Flag == "3") { if (arr[key].DrUniqueId == DrUniqueId) { arr[key].DCRRemarks = e.target.value; } }
            if (Flag == "4") {
                if (arr[key].DrUniqueId == DrUniqueId) {
                    var salep = "";
                    for (var keyy in e) {
                        if (parseInt(keyy) + 1 === parseInt(e.length)) { salep += e[keyy].value }
                        else { salep += e[keyy].value + "," }
                    }
                    arr[key].SalesPersonIds = salep;
                }
            }
        }
        setTable(arr);
    }
    const phoneRegExpdoctor = /^'?\p{L}+(?:[' ]\p{L}+)*'?$/u
    const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
    const doctorformik = useFormik({
        initialValues: { doctortitle: '', docfirstname: '', docmiddlename: '', doclastname: '', doctorsappincode: '', doctorsapstate: '', doctorsapregion: '', doctorsapdistrict: '', doctorspecialization: '', docclinicno: '', docmobileno: '', doclandmark: '' },//, docemail: '' after disable doctor search in add new
        validationSchema: Yup.object({
            doctortitle: Yup.string().required('Title is required'),
            docfirstname: Yup.string().required('First name is required').matches(phoneRegExpdoctor, 'Special characters is not allowed'),
            docmiddlename: Yup.string().matches(phoneRegExpdoctor, 'Special characters is not allowed'),
            doclastname: Yup.string().matches(phoneRegExpdoctor, 'Special characters is not allowed'),
            docmobileno: Yup.string().max(10, 'Mobile phone must be 10 numbers').min(10, 'Mobile phone must be 10 numbers').matches(phoneRegExp, 'Mobile phone is not valid'),
            docclinicno: Yup.string().max(10, 'Clinic phone must be 10 numbers').min(10, 'Clinic phone must be 10 numbers').matches(phoneRegExp, 'Clinic phone is not valid'),
            doctorsappincode: Yup.string().typeError('Pincode  must be a number')
        }),
        onSubmit: values => { SaveDCRNewDoctor(values); }
    });
    const SaveDCRNewDoctor = async (values) => {
        const requestOptions = {
            DoctorTitle: values.doctortitle,
            DoctorFirstName: values.docfirstname,
            DoctorMiddleName: values.docmiddlename === undefined ? "" : values.docmiddlename,
            DoctorLastName: values.doclastname === undefined ? "" : values.doclastname,
            DoctorPincode: values.doctorsappincode === undefined ? "0" : values.doctorsappincode,
            DoctorSAPState: values.doctorsapstate === undefined ? "0" : values.doctorsapstate,
            DoctorSAPRegion: values.doctorsapregion === undefined ? "0" : values.doctorsapregion,
            DoctorSAPDistrict: values.doctorsapdistrict === undefined ? "0" : values.doctorsapdistrict,
            DoctorSpecialization: values.doctorspecialization === undefined ? "0" : values.doctorspecialization,
            DoctorClinicNo: values.docclinicno === undefined ? "" : values.docclinicno,
            DoctorMobileNo: values.docmobileno === undefined ? "" : values.docmobileno,
            DoctorLandmark: values.doclandmark === undefined ? "" : values.doclandmark,
            CentreSNo: localStorage.CentreSNo,
            CreatedBy: localStorage.LoggedInUser
        };
        //let url = FrontDesk_URL + 'master/SaveReferringDoctor'
        let url = FrontDesk_URL + 'reports/SaveDCRNewDoctor'
        await axios.post(url, requestOptions).then((response) => {
            const data = response.data;
            if (data.Success) {
                if (data.Data === "0") { SweetAlert.fire('Unable to save.Please try again') }
                else {
                    SweetAlert.fire({
                        title: 'Success!',
                        text: "Data has been saved!",
                        icon: 'success',
                        showCancelButton: false,
                        confirmButtonText: 'Ok',
                        reverseButtons: true
                    })
                        .then((result) => {
                            if (result.value) { openDoctorModal(); CallPageReset(); }
                            else { openDoctorModal(); CallPageReset(); }
                        })
                }
            }
            else {
                if (data.Data === "-2001") { SweetAlert.fire('Doctor with same mobile number already exist.Please search'); }
                else if (data.Data === "-2003") { SweetAlert.fire('Doctor with same name and landmark already exist.Please change landmark'); }
                else if (data.Data === "-2004") { SweetAlert.fire('Doctor with same name already exist.Please add landmark also'); }
                else if (data.Data === "-2000") { SweetAlert.fire({ title: "DB Error", text: "Some error occured! Check db logs", icon: "error" }); }
                else { SweetAlert.fire({ title: "Application Exception", text: "Some error occured! Please try after sometime.", icon: "error" }); }
            }
        }).catch(function (error) { if (error.response) { ErrorFunction(error.response.status); } });
    }
    const GetDoctorTitle = async () => {
        try {
            let url = FrontDesk_URL + 'master/getdoctortitlelist'
            await axios.get(url).then((response) => {
                const data = response.data;
                if (data.Success) { setDoctorTitleList(data.Data.map((d) => (<option key={d.Id} value={d.Id}>{d.Value}</option>))); }
            }).catch(function (error) { });
        }
        catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    }
    const GetSAPStates = async () => {
        try {
            let url = FrontDesk_URL + 'master/getstateslist/1'
            await axios.get(url).then((response) => {
                const data = response.data;
                if (data.Success) { setSAPStateList(data.Data.map((d) => (<option key={d.Id} value={d.Id}>{d.Value}</option>))); }
            }).catch(function (error) { });
        }
        catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    }
    const GetSAPRegion = async (StateId) => {
        try {
            let url = FrontDesk_URL + 'master/getregionslist/' + StateId
            await axios.get(url).then((response) => {
                const data = response.data;
                if (data.Success) { setSAPRegionList(data.Data.map((d) => (<option key={d.Id} value={d.Id}>{d.Value}</option>))); }
            }).catch(function (error) { });
        }
        catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    }
    const GetSAPDistrict = async (RegionId) => {
        try {
            let url = FrontDesk_URL + 'master/getdistrictslist/' + RegionId
            await axios.get(url).then((response) => {
                const data = response.data;
                if (data.Success) { setSAPDistrictList(data.Data.map((d) => (<option key={d.Id} value={d.Id}>{d.Value}</option>))); }
            }).catch(function (error) { });
        }
        catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    }
    const GetSpecialization = async () => {
        try {
            let url = FrontDesk_URL + 'master/getspecializationlist'
            await axios.get(url).then((response) => {
                const data = response.data;
                if (data.Success) { setSpecializationList(data.Data.map((d) => (<option key={d.Id} value={d.Id}>{d.Value}</option>))); }
            }).catch(function (error) { });
        }
        catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    }
    const openDoctorModal = () => {
        doctorformik.resetForm();
        setDoctorModal(!doctorModal);
        GetDoctorTitle();
        GetSAPStates();
        GetSpecialization();
    }
    const GetPincodeSAPLocations = async (e) => {
        let text = e.target.value;
        let length = text.length;
        if (length === 6) {
            try {
                let url = FrontDesk_URL + 'master/getpincodesaplocations/' + e.target.value
                await axios.get(url).then((response) => {
                    const data = response.data;
                    if (data.Success) {
                        if (data != null) {
                            const pinCodeData = data.Data;
                            doctorformik.values.doctorsapstate = pinCodeData[0].SAPStateId;
                            GetSAPRegion(doctorformik.values.doctorsapstate);
                            doctorformik.values.doctorsapregion = pinCodeData[0].SAPRegionId;
                            GetSAPDistrict(doctorformik.values.doctorsapregion);
                            doctorformik.values.doctorsapdistrict = pinCodeData[0].SAPDistrictId;
                        }
                        else {
                            doctorformik.values.doctorsapstate = '';
                            doctorformik.values.doctorsapregion = '';
                            doctorformik.values.doctorsapdistrict = '';
                        }
                    }
                    else {
                        doctorformik.values.doctorsapstate = '';
                        doctorformik.values.doctorsapregion = '';
                        doctorformik.values.doctorsapdistrict = '';
                    }
                }).catch(function (error) { if (error.response) { ErrorFunction(error.response.status); } });
            }
            catch (error) {
                SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" });
            }
        }
        else {
            doctorformik.values.doctorsapstate = '';
            doctorformik.values.doctorsapregion = '';
            doctorformik.values.doctorsapdistrict = '';
            setSAPRegionList([]);
            setSAPDistrictList([]);
        }
    }
    const SAPStateChangeHandler = (e) => {
        doctorformik.values.doctorsapregion = "";
        doctorformik.values.doctorsapdistrict = "";
        if (e.target.value !== "") { GetSAPRegion(e.target.value); } else { setSAPRegionList([]); }
        setSAPDistrictList([]);
    }
    const SAPRegionChangeHandler = (e) => {
        doctorformik.values.doctorsapdistrict = "";
        if (e.target.value !== "0") { GetSAPDistrict(e.target.value); } else { setSAPRegionList([]); }
    }
    const GetJointSalesExecutive = async () => {
        setIsLoading(true);
        let url = FrontDesk_URL + 'reports/getjointsalesexecutive/' + localStorage.CentreSNo + '/' + localStorage.LoggedInUser
        await axios.get(url).then((response) => {
            const data = response.data;
            if (data.Success) {
                let ds = data.Data;
                const TypeOptionDs1 = [];
                ds.map((option) => {
                    const TypeOptions1 = {
                        'label': option.Name,
                        'value': option.Id,
                        'disabled': false
                    }
                    return TypeOptionDs1.push(TypeOptions1);
                });
                setJointSalesExecutive(TypeOptionDs1);
                setIsLoading(false);
            }
            else { setJointSalesExecutive([]); setIsLoading(false); }
        }).catch(function (error) { if (error.response) { ErrorFunction(error.response.status); } setIsLoading(false); });
    }
    const GetThirdPartyPartners = async () => {
        var glblArr = [];
        var arrpartners = [];
        setIsLoading(true);
        let url = FrontDesk_URL + 'reports/getthirdpartypartners/' + localStorage.CentreSNo + '/' + localStorage.LoggedInUser
        await axios.get(url).then((response) => {
            const data = response.data;
            if (data.Success) {
                if (data != null) {
                    for (var key in data.Data) {
                        arrpartners.push({ 'Id': data.Data[key].Id, 'Value': data.Data[key].Name });
                    }
                    var itmData = arrpartners;
                    itmData.map((option) => { return glblArr.push({ 'value': option.Id, 'label': option.Value, 'disabled': false }); });
                    setDCRPartnerType(glblArr);
                }
                // setDCRPartnerType(data.Data.map((d) => (<option key={d.Id} value={d.Id}>{d.Name}</option>)));
                setIsLoading(false);
            }
            else { setDCRPartnerType([]); setIsLoading(false); }
        }).catch(function (error) { if (error.response) { ErrorFunction(error.response.status); } setIsLoading(false); });
    }
    const TypeChangeHandler = (options) => {
        var typearr = [];
        typearr.push({
            value: options.value,
            label: options.label
        })
        setTypeSelected(typearr);
        BindGrid(typearr[0].value, typearr[0].label, "-1", "-1", "-1")
    }
    const ErrorFunction = async (ErrorCode) => {
        if (ErrorCode == "400") { SweetAlert.fire({ title: "Error 400", text: "Bad request", icon: "error" }); }
        else if (ErrorCode == "401") { SweetAlert.fire({ title: "Error 401", text: "Unauthorized", icon: "error" }); }
        else if (ErrorCode == "403") { SweetAlert.fire({ title: "Error 403", text: "Forbidden", icon: "error" }); }
        else if (ErrorCode == "404") { SweetAlert.fire({ title: "Error 404", text: "Page not found", icon: "error" }); }
        else if (ErrorCode == "500") { SweetAlert.fire({ title: "Error 500", text: "Internal Server Error", icon: "error" }); }
        else if (ErrorCode == "503") { SweetAlert.fire({ title: "Error 503", text: "Service Unavailable", icon: "error" }); }
    }
    return (
        <Fragment>
            <Breadcrumb title="DCR" parent="Reports" />
            <Form>
                <Container fluid>
                    <Row>
                        <Col md={12}>
                            <Card>
                                <Card.Header className="pb-0">
                                    <Row className="align-items-center">
                                        <Col md={8} xs={6}>
                                            <Card.Title>Add DCR</Card.Title>
                                        </Col>
                                        <Col md={4} xs={6} className="text-right mb-2">
                                            <Button className="btn-sm" variant='primary' onClick={openDoctorModal}>Add Doctor</Button>
                                        </Col>
                                    </Row>
                                </Card.Header>
                                <Card.Body>
                                    <Form.Row className="justify-content-center">
                                        <Col md={3}>
                                            <Form.Group>
                                                <Form.Label>Date</Form.Label>
                                                {/* <Form.Control type="date" id="dcrdate" name="dcrdate" ref={dcrdate} min={defaultdatedynamic} max={defaultdate} /> */}
                                                <Form.Control type="text" id="dcrdate" name="dcrdate" ref={dcrdate} disabled />
                                            </Form.Group>
                                        </Col>
                                        <Col md={3}>
                                            <Form.Group>
                                                <Form.Label>Working Type <span className='text-red'>*</span></Form.Label>
                                                <Form.Control as="select" name="workingtype" ref={workingtype} onChange={(e) => callstatechange(e)}>
                                                    <option key="0" value="0">---Select---</option>
                                                    {dcrworkingtype}
                                                </Form.Control>
                                            </Form.Group>
                                        </Col>
                                    </Form.Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    {
                        showstate == false ? null :
                            <>
                                {
                                    hideflag == true ? null :
                                        <>
                                            <Row>
                                                <Col md={12}>
                                                    <Card>
                                                        <Card.Body>
                                                            <div className="row justify-content-center">
                                                                <div className="col-md-4">
                                                                    <Form.Group>
                                                                        <Form.Label>My Doctor</Form.Label>
                                                                        <Typeahead
                                                                            id="basic-typeahead"
                                                                            labelKey="DoctorName"
                                                                            multiple={multiple}
                                                                            options={options}
                                                                            placeholder="Choose..."
                                                                            ref={testreference}
                                                                            onInputChange={(text, e) => { getdoctorsagainstsalesperson(e, "1"); }}
                                                                            onChange={(text) => {
                                                                                JSON.stringify(text) == "[]" ? setDCRUniqueId("") : setDCRUniqueId(text[0].DrUnquieId)
                                                                                BindGrid(text[0].DrUnquieId, text[0].DoctorName, text[0].IsApporved, "1", "1")
                                                                            }}
                                                                        />
                                                                    </Form.Group>
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <Form.Group>
                                                                        <Form.Label>Other Doctor</Form.Label>
                                                                        <Typeahead
                                                                            id="basic-typeahead1"
                                                                            labelKey="DoctorName"
                                                                            multiple={multiple}
                                                                            options={options1}
                                                                            placeholder="Choose..."
                                                                            ref={testreference1}
                                                                            onInputChange={(text, e) => { getdoctorsagainstsalesperson(e, "0"); }}
                                                                            onChange={(text) => {
                                                                                JSON.stringify(text) == "[]" ? setDCRUniqueId("") : setDCRUniqueId(text[0].DrUnquieId)
                                                                                BindGrid(text[0].DrUnquieId, text[0].DoctorName, text[0].IsApporved, "0", "1")
                                                                            }}
                                                                        />
                                                                    </Form.Group>
                                                                </div>
                                                                {
                                                                    workingtype.current.value === "1" || workingtype.current.value === "5" ?
                                                                        <div className="col-md-4">
                                                                            {/* <Form.Group>
                                                                                <Form.Label>Partners</Form.Label>
                                                                                <Form.Control as="select" ref={dcrpartners} onChange={(e) => BindGrid(e.target.value, e.target[e.target.selectedIndex].text, "-1", "-1", "-1")}>
                                                                                    <option key="0" value="0">---Select---</option>
                                                                                    {dcrpartnertype}
                                                                                </Form.Control>
                                                                            </Form.Group> */}
                                                                            <Form.Group>
                                                                                <Form.Label>Partners</Form.Label>
                                                                                {/* onChange={(e) => BindGrid(e.target.value, e.target[e.target.selectedIndex].text, "-1", "-1", "-1")} */}
                                                                                <Select onChange={TypeChangeHandler}
                                                                                    options={dcrpartnertype}
                                                                                    value={typeSelected} />
                                                                            </Form.Group>
                                                                        </div>
                                                                        :
                                                                        null
                                                                }
                                                            </div>
                                                            <div className="mt15 d-none d-lg-block">
                                                                <Table>
                                                                    <thead className="thead-light">
                                                                        <tr>
                                                                            <th scope="col">#</th>
                                                                            {
                                                                                workingtype.current.value === "1" ?
                                                                                    <th scope="col" style={{ width: '20%' }}>Name</th>
                                                                                    :
                                                                                    <th scope="col">Doctor Name</th>
                                                                            }
                                                                            {
                                                                                workingtype.current.value === "1" ?
                                                                                    <th scope="col" style={{ width: '20%' }}>Category</th>
                                                                                    :
                                                                                    null
                                                                            }
                                                                            <th scope="col">Visit Type</th>
                                                                            <th scope="col">Time</th>
                                                                            {
                                                                                workingtype.current.value === "5" ?
                                                                                    <th scope="col" style={{ width: '20%' }}>Sales Executive</th>
                                                                                    :
                                                                                    null
                                                                            }
                                                                            <th scope="col">Remarks (100 characters allowed)</th>
                                                                            <th scope="col" className="text-center">Remove</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {
                                                                            table.length > 0 ?
                                                                                <>
                                                                                    {
                                                                                        table.map((d, index) =>
                                                                                            <>
                                                                                                <tr>
                                                                                                    <td>{index + 1}</td>
                                                                                                    <td>{d.DoctorName}</td>
                                                                                                    {
                                                                                                        workingtype.current.value === "1" ?
                                                                                                            d.PartnerFlag == "-1" ? <td>Partner</td> : <td>Doctor</td>
                                                                                                            :
                                                                                                            null
                                                                                                    }
                                                                                                    <td>
                                                                                                        <Form.Control as="select" ref={dcrvisittypee} onChange={(e) => CallType(e, d.DrUniqueId, "1")}>
                                                                                                            <option key="0" value="0">---Select---</option>
                                                                                                            {dcrvisittype}
                                                                                                        </Form.Control>
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <Form.Control as="select" ref={dcrtimee} onChange={(e) => CallType(e, d.DrUniqueId, "2")}>
                                                                                                            <option key="0" value="0">---Select---</option>
                                                                                                            {dcrtime}
                                                                                                        </Form.Control>
                                                                                                    </td>
                                                                                                    {
                                                                                                        workingtype.current.value === "5" ?

                                                                                                            <td>
                                                                                                                <div className='txtMultiSelect1' style={{ width: '250px' }}>
                                                                                                                    <MultiSelect id="CentreSelect" name="CentreSelect"
                                                                                                                        options={jointsalesexecutive}
                                                                                                                        // value={selectsalesperson}
                                                                                                                        value={selectsalesperson.filter((z) => z.DrUniqueId == d.DrUniqueId)}
                                                                                                                        onChange={(e) => CallType(e, d.DrUniqueId, "4")}
                                                                                                                        labelledBy="Select"
                                                                                                                        selectionLimit="1"
                                                                                                                    /></div>
                                                                                                            </td> :
                                                                                                            null
                                                                                                    }
                                                                                                    <td>
                                                                                                        <Form.Control type="text" maxLength={100} ref={dcrremarks} onBlur={(e) => CallType(e, d.DrUniqueId, "3")}>
                                                                                                        </Form.Control>
                                                                                                    </td>
                                                                                                    <td className="text-center">
                                                                                                        <DeleteToolTip
                                                                                                            title="Delete"
                                                                                                            placement="top"
                                                                                                            id={`Delete${d.DrUniqueId}`}
                                                                                                            onClick={() => Displayalert(d.DrUniqueId)}
                                                                                                        />
                                                                                                    </td>
                                                                                                </tr>
                                                                                            </>
                                                                                        )
                                                                                    }
                                                                                </>
                                                                                :
                                                                                <tr><td className="text-center" colSpan={6} style={{ color: 'red' }}><b>No record found</b></td></tr>
                                                                        }
                                                                    </tbody>
                                                                </Table>
                                                            </div>
                                                        </Card.Body>
                                                    </Card>
                                                </Col>
                                            </Row>
                                            <div className="d-lg-none">
                                                <Row>
                                                    {
                                                        table.length > 0 ?
                                                            <>
                                                                {
                                                                    table.map((f, indexx) =>
                                                                        <>
                                                                            <Col md={12}>
                                                                                <Card className='routeCard'>
                                                                                    <Card.Header>
                                                                                        <Row className='align-items-center'>
                                                                                            <Col xs={6}>
                                                                                                <h3 className='routeSN'>{indexx + 1}</h3>
                                                                                            </Col>
                                                                                        </Row>
                                                                                    </Card.Header>
                                                                                    <Card.Body>
                                                                                        <Row>
                                                                                            <Col xs={12}>
                                                                                                <Form.Group>
                                                                                                    <Form.Label>Doctor Name: <b>{f.DoctorName}</b></Form.Label>
                                                                                                </Form.Group>
                                                                                                {
                                                                                                    workingtype.current.value === "1" ?
                                                                                                        f.PartnerFlag == "-1" ?
                                                                                                            <Form.Group>
                                                                                                                <Form.Label>Category: <b>Partner</b></Form.Label>
                                                                                                            </Form.Group> :
                                                                                                            <Form.Group>
                                                                                                                <Form.Label>Category: <b>Doctor</b></Form.Label>
                                                                                                            </Form.Group>
                                                                                                        :
                                                                                                        null
                                                                                                }
                                                                                                <Form.Group>
                                                                                                    <Form.Label>Visit Type: <span className='text-red'>*</span></Form.Label>
                                                                                                    <Form.Control as="select" ref={dcrvisittypee} onChange={(e) => CallType(e, f.DrUniqueId, "1")}>
                                                                                                        <option key="0" value="0">---Select---</option>
                                                                                                        {dcrvisittype}
                                                                                                    </Form.Control>
                                                                                                </Form.Group>
                                                                                                <Form.Group>
                                                                                                    <Form.Label>Time: <span className='text-red'>*</span></Form.Label>
                                                                                                    <Form.Control as="select" ref={dcrtimee} onChange={(e) => CallType(e, f.DrUniqueId, "2")}>
                                                                                                        <option key="0" value="0">---Select---</option>
                                                                                                        {dcrtime}
                                                                                                    </Form.Control>
                                                                                                </Form.Group>
                                                                                                {
                                                                                                    workingtype.current.value === "5" ?
                                                                                                        <Form.Group>
                                                                                                            <Form.Label>Sales Executive</Form.Label>
                                                                                                            <div className='txtMultiSelect1'>
                                                                                                                <MultiSelect id="CentreSelect" name="CentreSelect"
                                                                                                                    options={jointsalesexecutive}
                                                                                                                    //value={selectsalesperson}
                                                                                                                    value={selectsalesperson.filter((z) => z.DrUniqueId == f.DrUniqueId)}
                                                                                                                    onChange={(e) => CallType(e, f.DrUniqueId, "4")}
                                                                                                                    labelledBy="Select"
                                                                                                                /></div>
                                                                                                        </Form.Group>
                                                                                                        :
                                                                                                        null
                                                                                                }
                                                                                                <Form.Group>
                                                                                                    <Form.Label>Remarks (100 characters allowed): <span className='text-red'>*</span></Form.Label>
                                                                                                    <Form.Control type="text" maxLength={100} ref={dcrremarks} onBlur={(e) => CallType(e, f.DrUniqueId, "3")}>
                                                                                                    </Form.Control>
                                                                                                </Form.Group>
                                                                                            </Col>
                                                                                        </Row>
                                                                                    </Card.Body>
                                                                                    <Card.Footer>
                                                                                        <Button variant='primary' id={`Delete${f.DrUniqueId}`} onClick={() => Displayalert(f.DrUniqueId)}>Delete <i className="fa fa-trash mr-2"></i></Button>
                                                                                    </Card.Footer>
                                                                                </Card>
                                                                            </Col>
                                                                        </>
                                                                    )
                                                                }
                                                            </>
                                                            :
                                                            null
                                                    }
                                                </Row>
                                            </div>
                                        </>
                                }
                                <Row>
                                    <Col md={12}>
                                        <Card>
                                            <Card.Body>
                                                <Form.Row className="justify-content-start">
                                                    <Col md={12}>
                                                        <Form.Group>
                                                            <Form.Label>Remarks</Form.Label>
                                                            <Form.Control as="textarea" rows={5} ref={finalremarks} />
                                                        </Form.Group>
                                                    </Col>
                                                </Form.Row>

                                            </Card.Body>
                                        </Card>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12} className='text-center'>
                                        <Button variant='secondary' className="mr-2" onClick={() => CallPageReset()}>Reset</Button>
                                        {
                                            hideflag == false ?
                                                table.length > 0 ?
                                                    isbuttonloading ? <LoadingButton variantName='primary' cssName="" /> :
                                                        <Button variant='primary' onClick={() => SaveDCRData()}>Submit</Button>
                                                    : null
                                                :
                                                isbuttonloading ? <LoadingButton variantName='primary' cssName="" /> :
                                                    <Button variant='primary' onClick={() => SaveDCRData()}>Submit</Button>
                                        }
                                    </Col>
                                </Row>
                            </>
                    }
                </Container>
            </Form>
            <Modal isOpen={doctorModal} toggle={openDoctorModal} centered={true} size="lg">
                <ModalHeader toggle={openDoctorModal}>Add Doctor</ModalHeader>
                <ModalBody>
                    <Row>
                        <Col md={12}>
                            <Card>
                                <Card.Body>
                                    <Form.Row>
                                        <Col md={6}>
                                            <Form.Group>
                                                <Form.Label>Title <span className='text-red'>*</span></Form.Label>
                                                <Form.Control as="select" name="doctortitle" onChange={doctorformik.handleChange}
                                                    onBlur={doctorformik.handleBlur} value={doctorformik.values.doctortitle}>
                                                    <option value=''>---Select---</option>
                                                    {doctortitlelist}
                                                </Form.Control>
                                                {doctorformik.touched.doctortitle && doctorformik.errors.doctortitle ? (<div className='text-required'>{doctorformik.errors.doctortitle}</div>) : null}
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group>
                                                <Form.Label>First Name <span className='text-red'>*</span></Form.Label>
                                                <Form.Control type="text" name="docfirstname" onChange={doctorformik.handleChange} onBlur={doctorformik.handleBlur} value={doctorformik.values.docfirstname} />
                                                {doctorformik.touched.docfirstname && doctorformik.errors.docfirstname ? (<div className='text-required'>{doctorformik.errors.docfirstname}</div>) : null}
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group>
                                                <Form.Label>Middle Name</Form.Label>
                                                <Form.Control type="text" name="docmiddlename" onChange={doctorformik.handleChange} onBlur={doctorformik.handleBlur} value={doctorformik.values.docmiddlename} />
                                                {doctorformik.touched.docmiddlename && doctorformik.errors.docmiddlename ? (<div className='text-required'>{doctorformik.errors.docmiddlename}</div>) : null}
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group>
                                                <Form.Label>Last Name</Form.Label>
                                                <Form.Control type="text" name="doclastname" onChange={doctorformik.handleChange} onBlur={doctorformik.handleBlur} value={doctorformik.values.doclastname} />
                                                {doctorformik.touched.doclastname && doctorformik.errors.doclastname ? (<div className='text-required'>{doctorformik.errors.doclastname}</div>) : null}
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group>
                                                <Form.Label>Pin Code</Form.Label>
                                                <Form.Control type="text" name="doctorsappincode" onChange={doctorformik.handleChange} onBlur={doctorformik.handleBlur} value={doctorformik.values.doctorsappincode} onKeyUp={(e) => GetPincodeSAPLocations(e)} />
                                                {doctorformik.touched.doctorsappincode && doctorformik.errors.doctorsappincode ? (<div className='text-required'>{doctorformik.errors.doctorsappincode}</div>) : null}
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group>
                                                <Form.Label>SAP State</Form.Label>
                                                <Form.Control as="select" name="doctorsapstate" onChange={doctorformik.handleChange}
                                                    onBlur={doctorformik.handleBlur} value={doctorformik.values.doctorsapstate} onChangeCapture={SAPStateChangeHandler}>
                                                    <option value=''>---Select---</option>
                                                    {sapstatelist}
                                                </Form.Control>
                                                {doctorformik.touched.doctorsapstate && doctorformik.errors.doctorsapstate ? (<div className='text-required'>{doctorformik.errors.doctorsapstate}</div>) : null}
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group>
                                                <Form.Label>SAP Region</Form.Label>
                                                <Form.Control as="select" name="doctorsapregion" onChange={doctorformik.handleChange}
                                                    onBlur={doctorformik.handleBlur} value={doctorformik.values.doctorsapregion} onChangeCapture={SAPRegionChangeHandler}>
                                                    <option value=''>---Select---</option>
                                                    {sapregionlist}
                                                </Form.Control>
                                                {doctorformik.touched.doctorsapregion && doctorformik.errors.doctorsapregion ? (<div className='text-required'>{doctorformik.errors.doctorsapregion}</div>) : null}
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group>
                                                <Form.Label>SAP District</Form.Label>
                                                <Form.Control as="select" name="doctorsapdistrict" onChange={doctorformik.handleChange}
                                                    onBlur={doctorformik.handleBlur} value={doctorformik.values.doctorsapdistrict}>
                                                    <option value=''>---Select---</option>
                                                    {sapdistrictlist}
                                                </Form.Control>
                                                {doctorformik.touched.doctorsapdistrict && doctorformik.errors.doctorsapdistrict ? (<div className='text-required'>{doctorformik.errors.doctorsapdistrict}</div>) : null}
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group>
                                                <Form.Label>Specialization</Form.Label>
                                                <Form.Control as="select" name="doctorspecialization" onChange={doctorformik.handleChange}
                                                    onBlur={doctorformik.handleBlur} value={doctorformik.values.doctorspecialization}>
                                                    <option value=''>---Select---</option>
                                                    {specializationlist}
                                                </Form.Control>
                                                {doctorformik.touched.doctorspecialization && doctorformik.errors.doctorspecialization ? (<div className='text-required'>{doctorformik.errors.doctorspecialization}</div>) : null}
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group>
                                                <Form.Label>Clinic Phone</Form.Label>
                                                <Form.Control type="text" name="docclinicno" onChange={doctorformik.handleChange} onBlur={doctorformik.handleBlur} value={doctorformik.values.docclinicno} />
                                                {doctorformik.touched.docclinicno && doctorformik.errors.docclinicno ? (<div className='text-required'>{doctorformik.errors.docclinicno}</div>) : null}
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group>
                                                <Form.Label>Mobile Phone</Form.Label>
                                                <Form.Control type="text" name="docmobileno" onChange={doctorformik.handleChange} onBlur={doctorformik.handleBlur} value={doctorformik.values.docmobileno} />
                                                {doctorformik.touched.docmobileno && doctorformik.errors.docmobileno ? (<div className='text-required'>{doctorformik.errors.docmobileno}</div>) : null}
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group>
                                                <Form.Label>Landmark <span className='text-red'>(Max 500 characters allowed)</span></Form.Label>
                                                <Form.Control type="text" name="doclandmark" max={500} onChange={doctorformik.handleChange} onBlur={doctorformik.handleBlur} value={doctorformik.values.doclandmark} />
                                                {doctorformik.touched.doclandmark && doctorformik.errors.doclandmark ? (<div className='text-required'>{doctorformik.errors.doclandmark}</div>) : null}
                                            </Form.Group>
                                        </Col>
                                    </Form.Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>

                    <Row>
                        <Col md={12} className='text-center'>
                            <Button variant='secondary' className="mr-2" onClick={openDoctorModal}>Cancel</Button>
                            <Button variant='primary' type='submit' onClick={doctorformik.handleSubmit}>Save</Button>
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>
        </Fragment>
    );
};

export default Dcr;
