import React, { useEffect, useState, useRef } from 'react';
import { Container, Row, Col, Card, Form, Button } from 'react-bootstrap';
import SweetAlert from 'sweetalert2';
import { Image_Path, FrontDesk_URL } from '../../constant';
import axios from 'axios';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import LoadingButton from '../commonComponent/LoadingButton';
import Select from 'react-select';

var glblimgname = "";
const CashFlowDetails = (props) => {
    const [isButtonLoading, setIsButtonLoading] = useState(false);
    const [financeperson, setFinancePerson] = useState([]);
    const [bankname, setBankName] = useState([]);
    const [imagepath, setImagePath] = useState('');
    const [invalidfiletype, setInvalidFileType] = useState(false)
    let remarks = useRef();
    let refno = useRef();
    const [transferType, setTransferType] = useState("1");
    const [typeSelected, setTypeSelected] = useState([]);
    const [typeSelected1, setTypeSelected1] = useState([]);

    useEffect(() => { GetFinancePersonOrBankName("1"); }, [])
    useEffect(() => {
        glblimgname = "";
        // formik.values.personamount = parseFloat(props.holdamount).toFixed(2);
        // bankFormik.values.bankamount = parseFloat(props.holdamount).toFixed(2);
    }, [props]);

    const GetFinancePersonOrBankName = async (tType) => {
        var glblArr = [];
        var glblArr1 = [];
        try {
            let url = FrontDesk_URL + 'master/GetFinancePersonORBankName/' + tType + '/' + localStorage.getItem('LoggedInUser')
            await axios.get(url).then((response) => {
                const data = response.data;
                if (data.Success) {
                    let ds = data.Data;
                    if (tType == "1") {
                        if (ds != null) {
                            ds.map((option) => { return glblArr.push({ 'value': option.Id, 'label': option.Value, 'disabled': false }); });
                            setFinancePerson(glblArr);
                        }
                        else { setFinancePerson([]); }
                    }
                    else {
                        if (ds != null) {
                            ds.map((option) => { return glblArr1.push({ 'value': option.Id, 'label': option.Value, 'disabled': false }); });
                            setBankName(glblArr1);
                        }
                        else { setBankName([]); }
                    }
                    // tType === "1" ? setFinancePerson(data.Data.map((d) => (<option key={d.Id} value={d.Id}>{d.Value}</option>))) :
                    //     setBankName(data.Data.map((d) => (<option key={d.Id} value={d.Id}>{d.Value}</option>)))
                }
            }).catch(function (error) { if (error.response) { ErrorFunction(error.response.status); } });
        }
        catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    }

    const bankFormik = useFormik({
        initialValues: { transfertobankname: '', bankamount: '', refno: '' },
        validationSchema: Yup.object({
            // transfertobankname: Yup.string().required('Bank name is required'),
            bankamount: Yup.number().required('Amount is required').typeError('Amount must be a number')
        }),
        onSubmit: values => { SaveCashFlowDetails(values); }
    });
    const formik = useFormik({
        initialValues: { transfertoperson: '', personamount: '', remarks: '' },//shortageAmount: '0',
        validationSchema: Yup.object({
            // transfertoperson: Yup.string().required('Transfer to is required'),
            personamount: Yup.number().required('Amount is required').typeError('Amount must be a number')
                .min(0.1, 'Amount must be greater than 0').max(props.holdamount, 'You can not transfer amount more than available amount')
            //shortageAmount: Yup.number().min(0, 'Amount must be greater than 0').max(props.DailyLimit, 'You are adding shortage amount more than available limit')
        }),
        onSubmit: values => { SaveCashFlowDetails(values); }
    });
    const ClearData = async () => {
        formik.resetForm();
        bankFormik.resetForm();
        if (transferType === "1") { remarks.current.value = ""; }
        else { setImagePath(""); glblimgname = ""; refno.current.value = ""; }
        { props.allowCashTransfer(false); }
    }
    const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
    const UploadBankReceipt = async (e, folderName) => {
        glblimgname = "";
        const arryVal = e.target.files[0].name.split(".");
        const fileExtn = arryVal[arryVal.length - 1];

        if (fileExtn.toLowerCase() === "jpg" || fileExtn.toLowerCase() === "jpeg" || fileExtn.toLowerCase() === "png" ||
            fileExtn.toLowerCase() === "gif" || fileExtn.toLowerCase() === "svg" || fileExtn.toLowerCase() === "pdf") { setInvalidFileType(false); }
        else { SweetAlert.fire("Invalid file type!"); setInvalidFileType(true); setImagePath(''); return false; }
        var base64 = await toBase64(e.target.files[0]);
        var base64img = base64.split("base64,");
        const formData = new FormData();
        formData.append('Image', base64img[1]);
        formData.append('FolderName', folderName);
        const randomImageId = Math.random();
        var imgName = randomImageId + folderName + "." + fileExtn;
        formData.append('ImageName', imgName);
        let url = FrontDesk_URL + 'master/UploadImage_Root/'
        await axios.post(url, formData, {
            headers: { "Content-type": "multipart/form-data", },
        }).then((response) => {
            const data = response.data;
            if (data.Success) {
                const finalimg = Image_Path + folderName + "/" + imgName;
                setImagePath(finalimg);
                glblimgname = imgName;
            } else { SweetAlert.fire({ title: "Upload failed", text: "Unable to upload document due to " + data.Data + ".Please retry again", icon: "error" }) }
        }).catch(function (error) { if (error.response) { } });
    }
    const SaveCashFlowDetails = async (values) => {
        if (transferType === "2") {
            if (invalidfiletype === true) { SweetAlert.fire({ title: "Validation Failed!", text: "Please upload valid file type.", icon: "error" }); return false; }
            else if (imagepath === "") { SweetAlert.fire({ title: "Validation Failed!", text: "Bank receipt is required.", icon: "error" }); return false; }
        }
        if (transferType == "1" && (typeSelected.length == 0 || typeSelected.length == undefined || typeSelected.length == null || typeSelected.length == "")) {
            SweetAlert.fire({ title: "Validation Failed!", text: "Select person is required.", icon: "error" }); return false;
        }
        if (transferType == "2" && (typeSelected1.length == 0 || typeSelected1.length == undefined || typeSelected1.length == null || typeSelected1.length == "")) {
            SweetAlert.fire({ title: "Validation Failed!", text: "Select bank is required.", icon: "error" }); return false;
        }
        setIsButtonLoading(true);
        const requestOptions = {
            // transferto: transferType === "1" ? values.transfertoperson : values.transfertobankname,
            transferto: transferType === "1" ? typeSelected : typeSelected1,
            amount: transferType === "1" ? (values.personamount.toString() === "" ? "0" : values.personamount.toString()) : (values.bankamount.toString() === "" ? "0" : values.bankamount.toString()),
            imagename: glblimgname,
            remarksORreference: transferType === "1" ? (values.remarks === undefined ? "" : values.remarks) : (values.refno === undefined ? "" : values.refno),
            flag: transferType,
            CentreId: localStorage.CentreSNo,
            LoginUser: localStorage.LoggedInUser,
            ShortAmount: "0" //transferType === "1" ? values.shortageAmount.toString() : "0"
        };
        let url = FrontDesk_URL + 'master/savecashflowdetails'
        await axios.post(url, requestOptions).then((response) => {
            setIsButtonLoading(false);
            const data = response.data;
            if (data.Success) {
                SweetAlert.fire({ text: "Amount successfully transferred", icon: "success" });
                ClearData();
                window.location.reload(false);
            }
            else {
                if (data.ErrorList[0].errorCode == -2000 || data.Data == -2000) { SweetAlert.fire({ title: "API Runtime Exception", text: "Some error occured! Please try after sometime.", icon: "error" }) }
                else if (data.ErrorList[0].errorCode == -1 || data.Data == -1) {
                    SweetAlert.fire({
                        title: 'Already transferred',
                        text: "Already transferred",
                        icon: 'warning',
                        showCancelButton: false,
                        confirmButtonText: 'Ok',
                        reverseButtons: true
                    }).then((result) => { if (result.value) { window.location.reload(false); } else { window.location.reload(false); } })
                }
                else if (data.ErrorList[0].errorCode == -2 || data.Data == -2) {
                    SweetAlert.fire("Amount cannot be greater than transferrable amount");
                }
                else if (data.ErrorList[0].errorCode == -2005 || data.Data == -2005) {
                    SweetAlert.fire({
                        title: 'Day end request pending',
                        text: "You had already requested for day end. You cannot transfer any amount before reconcilation.",
                        icon: 'warning',
                        showCancelButton: false,
                        confirmButtonText: 'Ok',
                        reverseButtons: true
                    }).then((result) => { if (result.value) { window.location.reload(false); } else { window.location.reload(false); } })
                }
                else { SweetAlert.fire({ title: "Application Exception", text: "Some error occured! Please try after sometime.", icon: "error" }) }
            }
        }).catch(function (error) {
            setIsButtonLoading(false);
            if (error.response) { ErrorFunction(error.response.status); }
        });
    }
    const handleTransferType = (e) => { e.target.value === "0" ? setTransferType("0") : setTransferType(e.target.value); GetFinancePersonOrBankName(e.target.value); setTypeSelected([]); setTypeSelected1([]); };
    const handleAmountTransfer = (e) => {
        const curntAmount = e.target.value;
        if (curntAmount === "0") { formik.values.shortageAmount = parseFloat(props.holdamount).toFixed(2); }
        else { formik.values.shortageAmount = (parseFloat(props.holdamount).toFixed(2) - parseFloat(curntAmount).toFixed(2)); }
    };
    const TypeChangeHandler = (options) => { setTypeSelected(options.value); }
    const TypeChangeHandler1 = (options) => { setTypeSelected1(options.value); }
    const ErrorFunction = async (ErrorCode) => {
        if (ErrorCode == "400") { SweetAlert.fire({ title: "Error 400", text: "Bad request", icon: "error" }); }
        else if (ErrorCode == "401") { SweetAlert.fire({ title: "Error 401", text: "Unauthorized", icon: "error" }); }
        else if (ErrorCode == "403") { SweetAlert.fire({ title: "Error 403", text: "Forbidden", icon: "error" }); }
        else if (ErrorCode == "404") { SweetAlert.fire({ title: "Error 404", text: "Page not found", icon: "error" }); }
        else if (ErrorCode == "500") { SweetAlert.fire({ title: "Error 500", text: "Internal Server Error", icon: "error" }); }
        else if (ErrorCode == "503") { SweetAlert.fire({ title: "Error 503", text: "Service Unavailable", icon: "error" }); }
    }
    return (
        <>
            <Row>
                <Col md={12}>
                    <Card>
                        <Card.Body>
                            <Row>
                                <Col md={4}>
                                    <Form.Group>
                                        <Form.Label>Transfer Type<span className='text-red'>*</span></Form.Label>
                                        <Form.Control as="select" name="transferto" onChange={(e) => handleTransferType(e)}>
                                            <option value="1">Transfer to person</option>
                                            <option value="2">Transfer to bank</option>
                                        </Form.Control>
                                    </Form.Group>
                                </Col>
                            </Row>
                            {
                                transferType === "1" ?
                                    <>
                                        <Form>
                                            <Form.Row>
                                                <Col md={4}>
                                                    <Form.Group>
                                                        <Form.Label>Select Person<span className='text-red'>*</span></Form.Label>
                                                        {/* <Form.Control as="select" name="transfertoperson" value={formik.values.transfertoperson} onChange={formik.handleChange} onBlur={formik.handleBlur}>
                                                            <option key="" value="">---Select---</option>
                                                            {financeperson}
                                                        </Form.Control>
                                                        {formik.touched.transfertoperson && formik.errors.transfertoperson ? (<div className='text-required'>{formik.errors.transfertoperson}</div>) : null} */}
                                                        <Select onChange={TypeChangeHandler}
                                                            options={financeperson}
                                                            value={financeperson.filter(function (x) { return x.value === typeSelected; })} />
                                                    </Form.Group>
                                                </Col>
                                                <Col md={4}>
                                                    <Form.Group>
                                                        <Form.Label>Amount in Rs.<span className='text-red'>*</span></Form.Label>
                                                        <Form.Control type="text" name="personamount" value={formik.values.personamount} onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur} /> {/* onChangeCapture={(e) => handleAmountTransfer(e)}  */}
                                                        {formik.touched.personamount && formik.errors.personamount ? (<div className='text-required'>{formik.errors.personamount}</div>) : null}
                                                    </Form.Group>
                                                </Col>
                                                {/* <Col md={3}>
                                                    <Form.Group>
                                                        <Form.Label>Shortage Amount in Rs.<span className='text-red'>*</span></Form.Label>
                                                        <Form.Control type="text" name="shortageAmount" value={formik.values.shortageAmount} disabled={true} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                                                        {formik.touched.shortageAmount && formik.errors.shortageAmount ? (<div className='text-required'>{formik.errors.shortageAmount}</div>) : null}
                                                    </Form.Group>
                                                </Col> */}
                                                <Col md={4}>
                                                    <Form.Group>
                                                        <Form.Label>Remarks</Form.Label>
                                                        <Form.Control type="text" ref={remarks} name="remarks" value={formik.values.remarks} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                                                    </Form.Group>
                                                </Col>
                                            </Form.Row>
                                            <Row>
                                                <Col md={12} className='text-center'>
                                                    <Button variant='secondary' className="mr-2" onClick={() => ClearData()}>Cancel</Button>
                                                    {
                                                        isButtonLoading ?
                                                            <LoadingButton variantName='primary' cssName="" /> :
                                                            <Button variant='primary' type='submit' onClick={formik.handleSubmit}>Save</Button>
                                                    }
                                                </Col>
                                            </Row>
                                        </Form>
                                    </> :
                                    <>
                                        <Form>
                                            <Form.Row>
                                                <Col md={3}>
                                                    <Form.Group>
                                                        <Form.Label>Select Bank<span className='text-red'>*</span></Form.Label>
                                                        {/* <Form.Control as="select" name="transfertobankname" value={bankFormik.values.transfertobankname} onChange={bankFormik.handleChange} onBlur={bankFormik.handleBlur}>
                                                            <option key="" value="">---Select---</option>
                                                            {bankname}
                                                        </Form.Control>
                                                        {bankFormik.touched.transfertobankname && bankFormik.errors.transfertobankname ? (<div className='text-required'>{bankFormik.errors.transfertobankname}</div>) : null} */}
                                                        <Select onChange={TypeChangeHandler1}
                                                            options={bankname}
                                                            value={bankname.filter(function (x) { return x.value === typeSelected1; })} />
                                                    </Form.Group>
                                                </Col>
                                                <Col md={3}>
                                                    <Form.Group>
                                                        <Form.Label>Amount in Rs.<span className='text-red'>*</span></Form.Label>
                                                        <Form.Control type="text" name="bankamount" value={bankFormik.values.bankamount} onChange={bankFormik.handleChange} onBlur={bankFormik.handleBlur} />
                                                        {bankFormik.touched.bankamount && bankFormik.errors.bankamount ? (<div className='text-required'>{bankFormik.errors.bankamount}</div>) : null}
                                                    </Form.Group>
                                                </Col>
                                                <Col md={3}>
                                                    <Form.Group>
                                                        <Form.Label>Referrence No</Form.Label>
                                                        <Form.Control type="text" ref={refno} name="refno" value={bankFormik.values.refno} onChange={bankFormik.handleChange} onBlur={bankFormik.handleBlur} />
                                                    </Form.Group>
                                                </Col>
                                                <Col md={3}>
                                                    <Form.Group>
                                                        <Form.Label>Bank Receipt<span className='text-red'>*</span></Form.Label>
                                                        <div><input type="file" id="bankreceipt" onChange={(e) => UploadBankReceipt(e, "bankreceipt")} accept="image/*" /></div>
                                                        {imagepath === "" ? null : <img src={imagepath} width="80"></img>}
                                                    </Form.Group>
                                                </Col>
                                            </Form.Row>
                                            <Row>
                                                <Col md={12} className='text-center'>
                                                    <Button variant='secondary' className="mr-2" onClick={() => ClearData()}>Cancel</Button>
                                                    {isButtonLoading ? <LoadingButton variantName='primary' cssName="" /> : <Button variant='primary' type='submit' onClick={bankFormik.handleSubmit}>Save</Button>}
                                                </Col>
                                            </Row>
                                        </Form>
                                    </>
                            }
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </>
    )
}

export default CashFlowDetails;