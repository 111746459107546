import React, { useState, useEffect, useRef } from 'react';
import { Container, Row, Col, Card, Form, Button, Table } from 'react-bootstrap';
import SweetAlert from 'sweetalert2';
import axios from 'axios';
import { FrontDesk_URL } from '../../constant';

const PrintStickerdetails = () => {

  const [Date, setDate] = useState("");
  const [MRNo, setMRNo] = useState("");
  const [VisitNo, setVisitNo] = useState("");
  const [Name, setName] = useState("");
  const [Age, setAge] = useState("");
  const [Modality, setModality] = useState("");
  const [TestName, setTestName] = useState("");

  useEffect(() => { Stickerdetails(); }, [])

  const Stickerdetails = async () => {
    try {
      let url = FrontDesk_URL + 'master/getStickerdetails/' + localStorage.getItem('LoggedInUser') + '/' + localStorage.getItem('vId') + '/' + localStorage.getItem('CentreSNo')
      await axios.get(url).then((response) => {
        const data = response.data;
        if (data.Success) {
          setDate(data.Data.Date);
          setMRNo(data.Data.MRNo);
          setVisitNo(data.Data.VisitNo);
          setName(data.Data.Name);
          setAge(data.Data.Age);
          var modality = [];
          var modalityname = "";
          var testName = "";
          var modalityInfo = data.Data.objStickerDetailList;
          var isCheck = 0;
          var ismodalitycheck = 0;
          if (modalityInfo.length > 1) {
            for (var i in modalityInfo) {
              // modality = modalityInfo[i].Modality + ", " + modality;
              // testName = modalityInfo[i].Testname + ", " + testName;
              if (modalityInfo.length == parseInt(parseInt(i) + 1)) {
                modality.push(modalityInfo[i].Modality);
                if (modalityInfo[i].Description.toLowerCase() === "radiology") {
                  testName += ", " + modalityInfo[i].Testname;
                }
              } else {
                modality.push(modalityInfo[i].Modality);
                if (modalityInfo[i].Description.toLowerCase() === "radiology" && isCheck === 0) {
                  testName += modalityInfo[i].Testname;
                  isCheck = 1;
                }
                else if (modalityInfo[i].Description.toLowerCase() === "radiology" && isCheck === 1) {
                  testName += ", " + modalityInfo[i].Testname;
                }
              }
            }
            modality = modality.filter((value, index, self) => self.indexOf(value) === index);
            for (var key in modality) {
              if (modality.length == parseInt(parseInt(key) + 1)) {
                modalityname += ", " + modality[key];
              }
              else {
                if (ismodalitycheck === 0) {
                  modalityname += modality[key];
                  ismodalitycheck = 1;
                }
                else {
                  modalityname += ", " + modality[key];
                }
              }
            }
            setModality(modalityname);
            setTestName(testName);
          } else {
            setModality(modalityInfo[0].Modality);
            if (modalityInfo[0].Description.toLowerCase() === "radiology") {
              setTestName(modalityInfo[0].Testname);
            }
          }
        }
        else {
          setDate("");
          setMRNo("");
          setVisitNo("");
          setName("");
          setAge("");
          setModality("");
          setTestName("");
        }
      }).catch(function (error) {
        if (error.response) {
          ErrorFunction(error.response.status);
        }
      });
    }
    catch (error) {
      SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" });
    }
  }

  const ErrorFunction = async (ErrorCode) => {
    if (ErrorCode == "400")
      SweetAlert.fire({ title: "Error 400", text: "Bad request", icon: "error" })
    else if (ErrorCode == "401")
      SweetAlert.fire({ title: "Error 401", text: "Unauthorized", icon: "error" })
    else if (ErrorCode == "403")
      SweetAlert.fire({ title: "Error 403", text: "Forbidden", icon: "error" })
    else if (ErrorCode == "404")
      SweetAlert.fire({ title: "Error 404", text: "Page not found", icon: "error" })
    else if (ErrorCode == "500")
      SweetAlert.fire({ title: "Error 500", text: "Internal Server Error", icon: "error" })
    else if (ErrorCode == "503")
      SweetAlert.fire({ title: "Error 503", text: "Service Unavailable", icon: "error" })
  }

  return (
    <>
      <Container fluid>
        <Row>
          <Col md={12}>
            <Card>
              <Card.Body>
                <div className="main stickerresp">
                  <div className="row mb-2">
                    <div className="col-12"> Date: <span className="dotedclass"><b>{Date}</b></span></div>
                  </div>
                  <div className="row mb-2">
                    <div className="col-12"> MR No:  <span className="dotedclass"><b>{MRNo}</b></span></div>
                  </div>
                  <div className="row mb-2">
                    <div className="col-12"> VisitNo:  <span className="dotedclass"><b>{VisitNo}</b></span></div>
                  </div>
                  <div className="row mb-2">
                    <div className="col-12"> Name:   <span className="dotedclass"><b>{Name}</b></span></div>
                  </div>
                  <div className="row mb-2">
                    <div className="col-12"> Age/Sex: <span className="dotedclass"><b>{Age}</b></span></div>
                  </div>
                  {
                    Modality === "" ? null :
                      <div className="row mb-2">
                        <div className="col-12"> Modality: <span className="dotedclass"><b>{Modality}</b></span></div>
                      </div>
                  }
                  {
                    TestName === "" ? null :
                      <div className="row mb-2">
                        <div className="col-12"> TestName: <span className="dotedclass"><b>{TestName}</b></span></div>
                      </div>
                  }
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default PrintStickerdetails
