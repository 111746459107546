import React from "react";
import ReactToPrint from "react-to-print";
import ConsentFormCoagulationandhemo from "../ConsentForms/ConsentFormCoagulationandhemo";
import ConsentFormCytopathologyRequis from "../ConsentForms/ConsentFormCytopathologyRequis";
import ConsentFormElectrophoresisRequ from "../ConsentForms/ConsentFormElectrophoresisRequ";
import ConsentFormforHIVTest from "../ConsentForms/ConsentFormforHIVTest";
import ConsentFormHistoConsent from "../ConsentForms/ConsentFormHistoConsent";
import ConsentFormMaternalScreen from "../ConsentForms/ConsentFormMaternalScreen";
import RequestFormMaternal from "../ConsentForms/RequestFormMaternal";
import ConsentFormFNAC from "../ConsentForms/ConsentFormFNAC";

class ExportPdfComponent extends React.Component {
    render() {
        const pdfProps = this.props;
        const formName = pdfProps.formName;
        console.log(formName);
        let patientName = pdfProps.PName;
        let age = pdfProps.Age;
        let gender = pdfProps.Gender;
        let refDoctor = pdfProps.RefDoctor;
        if (refDoctor === "") { refDoctor = "Self"; }
        if (gender === "1") { gender = "Male"; } else if (gender === "2") { gender = "Female"; } else { gender = "Other"; }
        return (
            <div>

                {
                    formName === "COAGULATION AND HEMO" ? <ConsentFormCoagulationandhemo PName={patientName} Age={age} Gender={gender} RefDoctor={refDoctor} ref={(response) => (this.componentRef = response)} />
                        : formName === "CONSENT FOR HIV TEST" ? <ConsentFormforHIVTest PName={patientName} Age={age} Gender={gender} RefDoctor={refDoctor} ref={(response) => (this.componentRef = response)} />
                            : formName === "CONSENT FORM  FNAC" ? <ConsentFormFNAC PName={patientName} Age={age} Gender={gender} RefDoctor={refDoctor} ref={(response) => (this.componentRef = response)} />
                                : formName === "CYTOPATHOLOGY REQUIS" ? <ConsentFormCytopathologyRequis PName={patientName} Age={age} Gender={gender} RefDoctor={refDoctor} ref={(response) => (this.componentRef = response)} />
                                    : formName === "ELECTROPHORESIS REQU" ? <ConsentFormElectrophoresisRequ PName={patientName} Age={age} Gender={gender} RefDoctor={refDoctor} ref={(response) => (this.componentRef = response)} />
                                        : formName === "HISTO CONCENT" ? <ConsentFormHistoConsent PName={patientName} Age={age} Gender={gender} RefDoctor={refDoctor} ref={(response) => (this.componentRef = response)} />
                                            : formName === "MATERNAL SCREEN" ? <ConsentFormMaternalScreen PName={patientName} Age={age} Gender={gender} RefDoctor={refDoctor} ref={(response) => (this.componentRef = response)} />
                                                : null
                }
                <ReactToPrint content={() => this.componentRef} trigger={() => <button type="button" className="btn btn-primary">Print to PDF!</button>} />
            </div>
        );
    }
}

export default React.memo(ExportPdfComponent);