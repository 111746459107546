import React from "react";
import { Container, Row, Col, Card, Form, Button, Table } from 'react-bootstrap';

class ConsentFormHistoConsent extends React.Component {

    render() {

        const pdfProps = this.props;
        const patientName = pdfProps.PName;
        const age = pdfProps.Age;
        const gender = pdfProps.Gender;
        const refDoctor = pdfProps.RefDoctor;
        const Address = pdfProps.Address;
        const Phnno = pdfProps.Phnno;
        console.log(pdfProps);
        return (
            <>
                <div className="formWrapper">
                    <div className="header">
                        <div className="logo"> <img src="/static/media/logo-atulaya.fbfb36f9.png" alt="thumb" /> </div>
                        <div className="headinginnersec">
                            <h1> HISTOPATHOLOGY REQUISITION FORM </h1>
                        </div>
                    </div>
                    <div className="main">
                        <div className="row mb-2">
                            <div className="col-sm-6"></div>
                            <div className="col-sm-6"> Histopath No: <span className="dotedclass"></span></div>
                        </div>
                        <div className="row mb-2">
                            <div className="col-sm-6"> Patient's Name: <span className="dotedclass"><b>{patientName}</b></span></div>
                            <div className="col-sm-6"> Age: <span className="dotedclass"><b>{age}</b></span></div>
                        </div>
                        <div className="row mb-2">
                            <div className="col-sm-6"> MR No:  <span className="dotedclass"></span></div>
                            <div className="col-sm-6"> Gender:  <span className="dotedclass"><b>{gender}</b></span></div>
                        </div>
                        <div className="row mb-2">
                            <div className="col-sm-6"> Referred by: Dr.   <span className="dotedclass"><b>{refDoctor}</b></span></div>
                            <div className="col-sm-6"> Tel/Fax:  <span className="dotedclass"></span></div>
                        </div>
                        <div className="row mb-2">
                            <div className="col-sm-12">Date & Time of collection:<span className="dotedclassnew"></span></div>
                        </div>
                        <div className="row mb-2">
                            <div className="col-sm-12">Date & Time of Sample Receipt:<span className="dotedclassnew"></span></div>
                        </div>
                        <div className="row mb-2">
                            <div className="col-sm-6"> For appointments and home sample collection:</div>
                            <div className="col-sm-3"> </div>
                            <div className="col-sm-3"> <span className="dotedclassnew"></span></div>
                        </div>
                        <div className="row mb-2">
                            <div className="col-sm-3"> +911724558888 </div>
                            <div className="col-sm-3"> </div>
                            <div className="col-sm-3"> </div>
                            <div className="col-sm-3"> Doctor's Signature</div>
                        </div>
                        <div className="row mb-2">
                            <div className="col-sm-4">(AHC/QF/2/ver Feb 2015) </div>
                            <div className="col-sm-2"> </div>
                            <div className="col-sm-2"> </div>
                            <div className="col-sm-4"> Timings: 8.00 a.m. to 8.00 p.m.               SUNDAY OPEN</div>
                        </div>
                        
                    </div>
                </div>
            </>
        );
    }
}

export default ConsentFormHistoConsent;