import React from "react";
import { Container, Row, Col, Card, Form, Button, Table } from 'react-bootstrap';

class ConsentFormCoagulationandhemo extends React.Component {

    render() {
        const pdfProps = this.props;
        const patientName = pdfProps.PName;
        const age = pdfProps.Age;
        const gender = pdfProps.Gender;
        const refDoctor = pdfProps.RefDoctor;
        return (
            <>
                <div className="formWrapper">
                    <div className="header">
                        <div className="logo"> <img src="/static/media/logo-atulaya.fbfb36f9.png" alt="thumb" /> </div>
                        <div className="headinginnersec">
                            <h1> COAGULATION AND HEMOSTATIS SCREENING HISTORY FORM </h1>
                            <p>( PT, APTT, LUPUS ANTICOAGULANT, PROTEIN C, PROTEIN S, ANTI THROMBIN III, FIBRINOGEN, FACTOR STUDIES V, VIII, IX, X )</p>
                        </div>
                    </div>
                    <div className="main">
                        <div className="row mb-2">
                            <div className="col-sm-6"> Patient's Name: <span className="dotedclass"><b>{patientName}</b></span></div>
                            <div className="col-sm-6"> Specimen Collected by: <span className="dotedclass"></span></div>
                        </div>
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <div className="float-left w-25"> Age:  <span className="dotedclass"><b>{age}</b></span></div>
                                <div className="float-right w-75"> Gender:  <span className="dotedclass"><b>{gender}</b></span></div>
                            </div>
                            <div className="col-sm-6"> Name of Sub Centre / Labs:  <span className="dotedclass"></span></div>
                        </div>
                        <div className="row mb-2">
                            <div className="col-sm-6"> Referred by: Dr.   <span className="dotedclass"><b>{refDoctor}</b></span></div>
                            <div className="col-sm-6"> Date & Time of collection:  <span className="dotedclass"></span></div>
                        </div>
                        <div className="row mb-2">
                            <div className="col-sm-6"> Tel/Fax:   <span className="dotedclass"></span></div>
                            <div className="col-sm-6"> Pervious MR No. (if any):  <span className="dotedclass"></span></div>
                        </div>
                        <div className="row mt-3 mb-3">
                            <div className="col-sm-12"> <b> Indications: </b> </div>
                        </div>
                        <div className="row mb-2">
                            <div className="col-sm-6"> Confirm a suspected diagnosis  <span className="checkboxclass"></span></div>
                            <div className="col-sm-6"> Carrier screening for a healthy individual without symptoms  <span className="checkboxclass"></span></div>
                        </div>
                        <div className="row mb-2">
                            <div className="col-sm-6"> Monitoring of Anticoagulant Therapy  <span className="checkboxclass"></span></div>
                            <div className="col-sm-6"> Others</div>
                        </div>
                        <div className="row mb-2">
                            <div className="col-sm-12"> Does the patient have SYMPTOMS? &nbsp;&nbsp; No <span className="checkboxclass"></span> &nbsp;&nbsp; Yes  <span className="checkboxclass"></span></div>
                        </div>
                        <div className="row mb-2">
                            <div className="col-sm-12"> <b>Pregnancy</b> &nbsp;&nbsp; No <span className="checkboxclass"></span> &nbsp;&nbsp; Yes  <span className="checkboxclass"></span></div>
                        </div>
                        <div className="row mt-3 mb-3">
                            <div className="col-sm-12"> <b> Bleeding History in Patient: </b> </div>
                        </div>
                        <div className="row mb-2">
                            <div className="col-sm-12"> Bruising <span className="checkboxclass"></span> &nbsp;&nbsp; Nose Bleeds  <span className="checkboxclass"></span> &nbsp;&nbsp; Heavy Periods <span className="checkboxclass"></span> &nbsp;&nbsp; EExcessive Bleeding with Dental Extraction <span className="checkboxclass"></span> &nbsp;&nbsp; Excessive Bleeding with Dental Extraction <span className="checkboxclass"></span></div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12">  Type of Surgery/Date: Any Other:  <span className="dotedclass"></span> </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12">  Family History:  <span className="dotedclass2"></span> </div>
                        </div>
                        <div className="row ">
                            <div className="col-sm-12">  <span className="fulldotedclass"></span> </div>
                        </div>
                        <div className="row mt-2">
                            <div className="col-sm-12">  Previous PT/INR Value(if applicable):  <span className="dotedclass"></span> </div>
                        </div>
                        <div className="row mt-3 mb-3">
                            <div className="col-sm-12"> <b> Medications: </b> </div>
                        </div>
                        <div className="row mb-2">
                            <div className="col-sm-12"> Aspirin <span className="checkboxclass"></span> &nbsp;&nbsp; NSAIDS(ibuprofen etc)  <span className="checkboxclass"></span> &nbsp;&nbsp; Anti-coagulants <span className="checkboxclass"></span></div>
                        </div>
                        <div className="row mb-2">
                            <div className="col-sm-12"> Aspirin <span className="checkboxclass"></span> &nbsp;&nbsp; NSAIDS(ibuprofen etc)  <span className="checkboxclass"></span> &nbsp;&nbsp; Anti-coagulants <span className="checkboxclass"></span></div>
                        </div>
                        <div className="row mb-2">
                            <div className="col-sm-12"><b>If Yes</b> &nbsp;&nbsp; Warfarin <span className="checkboxclass"></span> &nbsp;&nbsp; Low Molecular Weight Heparin  <span className="checkboxclass"></span> &nbsp;&nbsp; Unfractionated Heparin <span className="checkboxclass"></span> &nbsp;&nbsp; Other <span className="checkboxclass"></span></div>
                        </div>
                        <table class="table table-bordered">
                            <thead>
                                <tr>
                                    <th scope="col" colspan="3"> <b> For Lupus Only </b></th>
                                </tr>
                                <tr>
                                    <th scope="col"> Pregnancy Losses</th>
                                    <th scope="col"> Gestation (weeks) </th>
                                    <th scope="col"> (YY/MM) </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>1.</td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td>2.</td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td>3.</td>
                                    <td></td>
                                    <td></td>
                                </tr>
                            </tbody>
                        </table>
                        <div className="row mt-2">
                            <div className="col-sm-4"><b> Infertillty Problems:</b></div>
                            <div className="col-sm-4">  &nbsp;&nbsp; Yes <span className="checkboxclass"></span> &nbsp;&nbsp; No  <span className="checkboxclass"></span> </div>
                            <div className="col-sm-4"> </div>
                            </div>
                        <div className="row mt-2 mb-2">
                            <div className="col-sm-4"><b> Pregnant at Present:</b></div>
                            <div className="col-sm-3">  &nbsp;&nbsp; Yes <span className="checkboxclass"></span> &nbsp;&nbsp; No  <span className="checkboxclass"></span> </div>
                            <div className="col-sm-4"> Weeks Gestation &nbsp;&nbsp; <span className="dotedclass"></span> </div>
                        </div>
                        <div className="row mt-2">
                            <div className="col-sm-4"><b> Thrombotic Promblems:</b></div>
                            <div className="col-sm-4">  &nbsp;&nbsp; Yes <span className="checkboxclass"></span> &nbsp;&nbsp; No  <span className="checkboxclass"></span> </div>
                            <div className="col-sm-4"> </div>
                            </div>
                        <div className="row mt-2 mb-2">
                            <div className="col-sm-3"><b> On Anticoagulants Now:</b></div>
                            <div className="col-sm-3">  &nbsp;&nbsp; Yes <span className="checkboxclass"></span> &nbsp;&nbsp; No  <span className="checkboxclass"></span> </div>
                            <div className="col-sm-3"> Warfarin &nbsp;&nbsp; <span className="dotedclass"></span></div>
                            <div className="col-sm-3"> Heparin &nbsp;&nbsp; <span className="dotedclass"></span></div>
                        </div>
                    </div>
                </div>

            </>
        );
    }
}

export default ConsentFormCoagulationandhemo;