import React, { useEffect, useState, useRef } from 'react';
import { Container, Row, Col, Card, Form, Button, Table } from 'react-bootstrap';
import Breadcrumb from '../../components/common/breadcrumb';
import paginationFactory from 'react-bootstrap-table2-paginator';
import DataTables from '../commonComponent/DataTable/DataTables';
import SweetAlert from 'sweetalert2';
import { FrontDesk_URL } from '../../constant';
import axios from 'axios';
import { Spinner } from 'react-bootstrap';
import ToolTip from '../commonComponent/ToolTip';
import { Modal, ModalHeader, ModalBody } from "reactstrap";

const RISOrders = () => {
    let batchNo = useRef();
    let fromDate = useRef();
    let toDate = useRef();
    const [isLoading, setIsLoading] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [orderInfoModal, setOrderInfoModal] = useState();
    const [popupTableData, setPopupTableData] = useState([]);
    const openModal = () => { setOrderInfoModal(!orderInfoModal); };

    useEffect(() => { BindGrid("", "", ""); }, []);

    const idFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                <span>{rowIndex + 1}</span>
            </div>
        </React.Fragment>
    }
    const actionFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                <ToolTip title="Order Info" placement="top" onClick={() => GetInfo(rowData.OrderItems)} linkId="Id" faName="fa fa-info-circle mr-2"></ToolTip>
            </div>
        </React.Fragment>
    }
    const popupActionFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                {
                    parseInt(rowData.InvestigationStatus) === 0 ? <ToolTip title="Push to RIS" placement="top" onClick={() => PushInfo(rowData)} linkId="Id" faName="fa fa-paper-plane mr-2"></ToolTip>
                        : parseInt(rowData.InvestigationStatus) < 3 ? <span>Report Pending</span>
                            : parseInt(rowData.InvestigationStatus) === 3 ? <span>Report Generated</span> : null
                }
            </div>
        </React.Fragment>
    }
    const columns = [
        { dataField: '#', text: '#', editable: false, formatter: idFormatter, headerStyle: (colum, colIndex) => { return { width: '5%' }; } },
        { dataField: 'VisitingCode', text: 'Visit No.', editable: false },
        { dataField: 'PatientName', text: 'Name', editable: false },
        { dataField: 'Gender', text: 'Gender', editable: false },
        { dataField: 'MobileNo', text: 'Mobile No', editable: false },
        { dataField: 'OrderDate', text: 'OrderDate', editable: false },
        { dataField: '', text: 'Action', formatter: actionFormatter, editable: false, headerStyle: (colum, colIndex) => { return { width: '5%' }; } },
    ];
    const paginationOptions = { sizePerPage: 10, showTotal: false, firstPageText: 'First', prePageText: 'Back', nextPageText: 'Next', lastPageText: 'Last' };
    const popupColumns = [
        { dataField: '#', text: '#', editable: false, formatter: idFormatter, headerStyle: (colum, colIndex) => { return { width: '7%' }; } },
        { dataField: 'OrderType', text: 'Investigation', editable: false },
        { dataField: 'OrderTypeCode', text: 'Test Code', editable: false, headerStyle: (colum, colIndex) => { return { width: '20%' }; } },
        { dataField: 'ModalityType', text: 'Modality', editable: false, headerStyle: (colum, colIndex) => { return { width: '15%' }; } },
        { dataField: '', text: 'Action', formatter: popupActionFormatter, editable: false, headerStyle: (colum, colIndex) => { return { width: '20%' }; } },
    ];

    const handleSearch = async () => { BindGrid(batchNo.current.value, fromDate.current.value, toDate.current.value); }
    const BindGrid = async (vNo, fDte, tDte) => {
        setIsLoading(true);
        const requestOptions = { VisitNo: vNo, Status: "0", FromDate: fDte, ToDate: tDte, uId: localStorage.LoggedInUser, cId: localStorage.getItem('CentreSNo') };
        let url = FrontDesk_URL + 'ris/getorders'
        await axios.post(url, requestOptions).then((response) => {
            const data = response.data;
            if (data.Success) {
                const orderData = data.Data;
                console.log(orderData);
                if (orderData !== null) { setTableData(orderData); } else { setTableData([]); }
                setIsLoading(false);
            }
            else { SweetAlert.fire({ title: "Application Exception", text: "Some error occured! Please try after sometime", icon: "error" }); setIsLoading(false); }
        }).catch(function (error) { if (error.response) { ErrorFunction(error.response.status); } setIsLoading(false); });
    }
    const GetInfo = async (rowData) => { setPopupTableData(rowData); openModal(); }
    const PushInfo = async (rowData) => {
        let url = FrontDesk_URL + 'ris/pushtoris/' + localStorage.getItem('LoggedInUser') + '/' + localStorage.getItem('CentreSNo') + '/' + rowData.VisitingId + '/' + rowData.Id
        await axios.get(url).then((response) => {
            const data = response.data;
            if (data.Success) { BindGrid(batchNo.current.value, fromDate.current.value, toDate.current.value); }
            else { SweetAlert.fire({ title: "Transaction Alert", text: data.ErrorList[0].errorMsg, icon: "error" }); } 
            //else { SweetAlert.fire({ title: "Transaction Alert", text: "This  could not be pushed, please try again later.", icon: "error" }); }
        }).catch(function (error) { if (error.response) { ErrorFunction(error.response.status); } });
    }
    const ErrorFunction = async (ErrorCode) => {
        if (ErrorCode == "400")
            SweetAlert.fire({ title: "Error 400", text: "Bad request", icon: "error" })
        else if (ErrorCode == "401")
            SweetAlert.fire({ title: "Error 401", text: "Unauthorized", icon: "error" })
        else if (ErrorCode == "403")
            SweetAlert.fire({ title: "Error 403", text: "Forbidden", icon: "error" })
        else if (ErrorCode == "404")
            SweetAlert.fire({ title: "Error 404", text: "Page not found", icon: "error" })
        else if (ErrorCode == "500")
            SweetAlert.fire({ title: "Error 500", text: "Internal Server Error", icon: "error" })
        else if (ErrorCode == "503")
            SweetAlert.fire({ title: "Error 503", text: "Service Unavailable", icon: "error" })
    }
    return (
        <>
            <Breadcrumb title="Orders" parent="RIS" />
            <Container fluid>
                <Row>
                    <Col md={12}>
                        <Card>
                            <Card.Body>
                                <Form>
                                    <Form.Row>
                                        <Col md={2}>
                                            <Form.Group>
                                                <Form.Label>Order No.:</Form.Label>
                                                <Form.Control type='text' ref={batchNo} />
                                            </Form.Group>
                                        </Col>
                                        <Col md={3}>
                                            <Form.Group>
                                                <Form.Label>Date From</Form.Label>
                                                <Form.Control type="date" id="date1" name="date1" ref={fromDate} />
                                            </Form.Group>
                                        </Col>
                                        <Col md={3}>
                                            <Form.Group>
                                                <Form.Label>Date To</Form.Label>
                                                <Form.Control type="date" id="date2" name="date2" ref={toDate} />
                                            </Form.Group>
                                        </Col>
                                        <Col md={2}>
                                            <Form.Group>
                                                <Form.Label>Status</Form.Label>
                                                <Form.Control as="select" id="sample" name="sample" >
                                                    <option value="-1" selected>All</option>
                                                    <option selected={true} value="0">Pending</option>
                                                    <option value="1">Submitted</option>
                                                    <option value="2">Completed</option>
                                                </Form.Control>
                                            </Form.Group>
                                        </Col>
                                        <Col md={2} className='text-center mt25'>
                                            <Button variant='secondary' className="mr-2" onClick={handleSearch} >Search</Button>
                                        </Col>
                                    </Form.Row>
                                </Form>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <Card>
                            <Card.Header className='pb-0'>
                                <Card.Title>Orders List</Card.Title>
                            </Card.Header>
                            <Card.Body>
                                {
                                    isLoading ?
                                        <div className='text-center'> <Spinner animation="border" size='sm' /></div> :
                                        <>
                                            <DataTables keyField='id' tableData={tableData} columns={columns} pagination={paginationFactory(paginationOptions)} />
                                        </>
                                }
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                {orderInfoModal &&
                    <Modal isOpen={orderInfoModal} toggle={openModal} centered={true} size="lg">
                        <ModalHeader toggle={openModal}>Tests List</ModalHeader>
                        <ModalBody>
                            <Row>
                                <Col md={12}>
                                    <Card>
                                        <Card.Body>
                                            <DataTables keyField='PlabId' tableData={popupTableData} columns={popupColumns} pagination={paginationFactory(paginationOptions)} />
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        </ModalBody>
                    </Modal>
                }
            </Container>
        </>
    )
}

export default RISOrders