import React, { useState, useEffect, useRef } from 'react';
import logo from '../assets/images/logo-atulaya.png';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { withRouter } from "react-router";
import { LOGIN, YourName, Password, RememberMe, FrontDesk_URL } from '../constant/index';
import SweetAlert from 'sweetalert2';
import axios from 'axios';
import LoginButton from '../pages/commonComponent/LoginButton';
import { Container, Row, Col, Card, Button, Table, Form } from 'react-bootstrap'
import LoadingButton from '../pages/commonComponent/LoadingButton';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import DataTables from '../pages/commonComponent/DataTable/DataTables';
import NoRecordFounds from '../pages/commonComponent/DataTable/NoRecordFounds';
import { useLocation } from 'react-router-dom';
import paginationFactory from 'react-bootstrap-table2-paginator';
import InputGroup from 'react-bootstrap/InputGroup';

let GlobalAccessKeyId = ''
let AccessKey = "";
var glbRecords = [];
const Signin = () => {
    const location = useLocation();
    const [checkbox, setCheckbox] = useState(localStorage.CheckBox == undefined ? false : true);
    const [isLoading, setIsLoading] = useState(false);
    const [choosecentremodal, setChooseCentreModal] = useState(false);
    const [viewcentres, setViewCentres] = useState([]);
    const [crntPageNO, setCrntPageNO] = useState(0);
    let textSearch = useRef();

    useEffect(() => {

        if (location != null && location != '') {
            const params = new URLSearchParams(location.search);
            if (params != null && params != '') {
                AccessKey = params.get('Loginkey');
                GlobalAccessKeyId = params.get('Loginkey');
              
                AllowModuleChangeMove(GlobalAccessKeyId);
            }
        }
        else {
            localStorage.setItem('Session', "");
            if (localStorage.getItem('Email') !== null) { formik.values.email = localStorage.getItem('Email'); }
            if (localStorage.getItem('Password') !== null) { formik.values.password = localStorage.getItem('Password'); }
            usernameref.current.focus();
            btnLogin.current.focus();
            var input1 = usernameref.current;
            var input2 = passwordref.current;
            input1.addEventListener("keyup", function (event) {
                if (event.key == undefined) { return false; }
                if (event.key.toLowerCase() === "enter") { event.preventDefault(); btnLogin.current.click(); }
            });
            input2.addEventListener("keyup", function (event) {
                if (event.key == undefined) { return false; }
                if (event.key.toLowerCase() === "enter") { event.preventDefault(); btnLogin.current.click(); }
            });
        }

    }, []);

    const popupPaginationOptions = {
        sizePerPage: 10,
        showTotal: false,
        firstPageText: 'First',
        prePageText: 'Back',
        nextPageText: 'Next',
        lastPageText: 'Last',
        onPageChange: (page, sizePerPage) => { setCrntPageNO(((page - 1) * sizePerPage)); }
    };
    let usernameref = useRef();
    let passwordref = useRef();
    let btnLogin = useRef();
    const openCentreModal = () => { setCrntPageNO(0); setChooseCentreModal(!choosecentremodal); }
    const popupidcentreFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                <span>{crntPageNO + rowIndex + 1}</span>
            </div>
        </React.Fragment>
    }
    const popupactioncentreFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div><a href="javascript:void(0)" onClick={() => SelectedCentre(rowData.CentreId, rowData.CentreName, rowData.CentreCode, rowData.IsSourceMandatory)}>Select</a></div>
        </React.Fragment>
    }
    const popupcentresColumns = [
        { dataField: '#', text: '#', editable: false, formatter: popupidcentreFormatter, headerStyle: (colum, colIndex) => { return { width: '7%' }; } },
        { dataField: 'CentreName', text: 'Centres', editable: false },
        { dataField: 'actions', text: 'Actions', formatter: popupactioncentreFormatter, editable: false, headerStyle: (colum, colIndex) => { return { width: '15%' }; } }
    ];
    const SelectedCentre = (CentreId, CentreName, CentreCode, IsSourceMandatory) => {
        openCentreModal();
        localStorage.setItem('CentreSNo', CentreId);
        localStorage.setItem('CentreName', CentreName);
        localStorage.setItem('CentreCode', CentreCode);
        localStorage.setItem('IsSourceMandatory', IsSourceMandatory);
        GetSettings(CentreId);
    }
    const formik = useFormik({
        initialValues: { email: '', password: '' },
        validationSchema: Yup.object({
            email: Yup.string().required('Email address is required.').email('Invalid email'),
            password: Yup.string().required('Password is required.')
        }),
        onSubmit: values => {
            callLogin(values);
        },
    });
    const handleCheckbox = (e) => {
        const checked = e.target.checked;
        if (checked) {
            setCheckbox(true);
        } else {
            setCheckbox(false);
        }
    };
    const callLogin = async (values) => {
        try {
            setIsLoading(true);
            const requestOptions = {
                UserName: values.email, Password: values.password, ModuleId: "2"
            };
            let url = FrontDesk_URL + 'users/verifyuser'
            await axios.post(url, requestOptions).then((response) => {
                const data = response.data;
                if (data.Success) {
                    setIsLoading(false);
                    localStorage.clear();
                    let ds = data.Data;
                    if (ds != null) {
                        if (ds.objVerifiedUserInfo != null) {
                            if (ds.centreuserinfosList !== null) {
                                if (checkbox == true) {
                                    localStorage.setItem('Email', values.email);
                                    localStorage.setItem('Password', values.password);
                                    localStorage.setItem('CheckBox', checkbox);
                                }
                                //localStorage.setItem('PendingAmountOnUser', ds.objVerifiedUserInfo.AvailableAmount);
                                const today = new Date();
                                const date = today.setDate(today.getDate());
                                const defaultValue = new Date(date).toISOString().split('T')[0] // yyyy-mm-dd
                                localStorage.setItem("LoginDate",defaultValue);
                                localStorage.setItem('Session', "Active");
                                localStorage.setItem("UserAccessKey",ds.objVerifiedUserInfo.LoginActiveKey);
                                //localStorage.setItem("UserAccessKey","");
                                localStorage.setItem('LoggedInUser', ds.objVerifiedUserInfo.UserId);
                                localStorage.setItem('UserName', ds.objVerifiedUserInfo.UserName);
                                localStorage.setItem("UserGroups", JSON.stringify(ds.objUserAllowGroupsDetailsList));
                                localStorage.setItem("CentreInfo", JSON.stringify(ds.centreuserinfosList));
                                localStorage.setItem("UserGroups", JSON.stringify(ds.objUserAllowGroupsDetailsList));
                                localStorage.setItem("UserSubGroups", JSON.stringify(ds.objUserSubAllowGroupsList));
                                localStorage.setItem("UserAllowModule", JSON.stringify(ds.objUserAssignedModuleList));
                                localStorage.setItem("UserSourceMandatory", JSON.stringify(ds.centreuserinfosList));
                                localStorage.setItem('EmailId', ds.objVerifiedUserInfo.Email);
                                localStorage.setItem('MobileNo', ds.objVerifiedUserInfo.MobileNo);
                                localStorage.setItem("PageGrpName", ds.objUserAllowGroupsDetailsList[0]["PageGroupName"]);
                                if (ds.centreuserinfosList.length > 1) {
                                    openCentreModal();
                                    setViewCentres(ds.centreuserinfosList);
                                    glbRecords = ds.centreuserinfosList;
                                }
                                else {
                                    localStorage.setItem('CentreSNo', ds.centreuserinfosList[0].CentreId); //4 centerno is hardcoded for now
                                    localStorage.setItem('CentreName', ds.centreuserinfosList[0].CentreName);
                                    localStorage.setItem('CentreCode', ds.centreuserinfosList[0].CentreCode);
                                    localStorage.setItem('IsSourceMandatory', ds.centreuserinfosList[0].IsSourceMandatory);
                                    GetSettings(ds.centreuserinfosList[0].CentreId);
                                }
                            }
                            else { SweetAlert.fire({ title: "Access Denied", text: "You do not have access, please contact administrator for access.", icon: "error" }) }
                        }
                        else { SweetAlert.fire({ title: "Invalid", text: "Wrong Credentials", icon: "error" }) }
                    }
                    else { SweetAlert.fire({ title: "Invalid", text: "Wrong Credentials", icon: "error" }) }
                }
                else { APIError(data.Data, data.ErrorList.length === 0 ? "" : data.ErrorList[0].errorMsg); setIsLoading(false); }
            }).catch(function (error) { if (error.response) { AxiosError(error.response.status); } setIsLoading(false); })
        } catch (error) { APIError("-3"); setIsLoading(false); }
    }
    const GetSettings = async (cId) => {
        try {
            let url = FrontDesk_URL + 'master/getcentresettings/' + cId + '/' + localStorage.LoggedInUser;
            await axios.get(url).then((response) => {
                const data = response.data;
                if (data.Success) {
                    const settingInfo = data.Data;
                    let hideBookingAmount = false; if (settingInfo.HideBookingAmount === "True") { hideBookingAmount = true; }
                    let showBalanceAmount = false; if (settingInfo.ShowBalanceAmount === "True") { showBalanceAmount = true; }
                    let hideReceiptRate = false; if (settingInfo.HideReceiptRate === "True") { hideReceiptRate = true; }
                    let batchRequired = false; if (settingInfo.BatchRequired === "True") { batchRequired = true; }
                    let showMRPBill = false; if (settingInfo.ShowMRPBill === "True") { showMRPBill = true; }
                    let printDepartmentSlip = false; if (settingInfo.PrintDepartmentSlip === "True") { printDepartmentSlip = true; }
                    let showPROInRegistration = false; if (settingInfo.ShowPROInRegistration === "True") { showPROInRegistration = true; }
                    let showCentreDetailOnReport = false; if (settingInfo.ShowCentreDetailOnReport === "True") { showCentreDetailOnReport = true; }
                    let hideFooterOnWithoutLetterheadReport = false; if (settingInfo.HideFooterOnWithoutLetterheadReport === "True") { hideFooterOnWithoutLetterheadReport = true; }
                    localStorage.removeItem('CreditLimit');
                    localStorage.setItem('CentreTypeId', settingInfo.CentreTypeId);
                    localStorage.setItem('PartnerTypeId', settingInfo.PartnerTypeId);
                    localStorage.setItem('HideBookingAmount', hideBookingAmount);
                    localStorage.setItem('ShowBalanceAmount', showBalanceAmount);
                    localStorage.setItem('HideReceiptRate', hideReceiptRate);
                    localStorage.setItem('BatchRequired', batchRequired);
                    localStorage.setItem('ShowMRPBill', showMRPBill);
                    localStorage.setItem('PrintDepartmentSlip', printDepartmentSlip);
                    localStorage.setItem('ShowPROInRegistration', showPROInRegistration);
                    localStorage.setItem('HideFooterOnWithoutLetterheadReport', hideFooterOnWithoutLetterheadReport);
                    localStorage.setItem('UHIDAbbreviation', settingInfo.UHIDAbbreviation);
                    localStorage.setItem('SAPCode', settingInfo.SAPCode);
                    localStorage.setItem('PaymentModeId', settingInfo.PaymentModeId);
                    localStorage.setItem('OtherLabRefNo', settingInfo.OtherLabRefNo);
                    localStorage.setItem('ReportEmail', settingInfo.ReportEmail);
                    localStorage.setItem('InvoiceEmail', settingInfo.InvoiceEmail);
                    localStorage.setItem('CreditLimit', settingInfo.CreditLimit);
                    localStorage.setItem('ReportingLimit', settingInfo.ReportingLimit);
                    localStorage.setItem('BookingLimit', settingInfo.BookingLimit);
                    localStorage.setItem('ParentCentreName', settingInfo.ParentCentreName);
                    localStorage.setItem('MinBookingCash', settingInfo.MinBookingCash);
                    localStorage.setItem('CentreDiscountLimit', settingInfo.CentreDiscountLimit);
                    localStorage.setItem('DashboardMessage', (settingInfo.DashboardMessage == null || settingInfo.DashboardMessage == undefined || settingInfo.DashboardMessage == "") ? "" : settingInfo.DashboardMessage);
                    if(localStorage.getItem('PageGrpName')=="External Phlebo")
                    {
                        window.location.assign(`${process.env.PUBLIC_URL}/phlebo/myroutes`);
                    }
                    else{
                        window.location.assign(`${process.env.PUBLIC_URL}/dashboard`);
                    }
                  
                }
            }).catch(function (error) { setIsLoading(false); });
        }
        catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); setIsLoading(false); }
    }
    const handleSearch = async () => {
        setCrntPageNO(0);
        setViewCentres(glbRecords.filter((item) => item.CentreName.toLowerCase().indexOf(textSearch.current.value.toLowerCase()) !== -1));
    }
    const AxiosError = async (ErrorCode) => {
        if (ErrorCode === 400) { SweetAlert.fire({ title: "Error 400", text: "Bad request", icon: "error" }) }
        else if (ErrorCode === 401) { SweetAlert.fire({ title: "Error 401", text: "Unauthorized", icon: "error" }) }
        else if (ErrorCode === 403) { SweetAlert.fire({ title: "Error 403", text: "Forbidden", icon: "error" }) }
        else if (ErrorCode === 404) { SweetAlert.fire({ title: "Error 404", text: "Page not found", icon: "error" }) }
        else if (ErrorCode === 500) { SweetAlert.fire({ title: "Error 500", text: "Internal Server Error", icon: "error" }) }
        else if (ErrorCode === 503) { SweetAlert.fire({ title: "Error 503", text: "Service Unavailable", icon: "error" }) }
    }
    const APIError = async (ErrorCode, ErrorMsg) => {
        if (ErrorCode === "-1") { SweetAlert.fire({ title: "Data unavailable", text: "No record found", icon: "error" }) }
        else if (ErrorCode === "-2") { SweetAlert.fire({ title: "API Server Error", text: ErrorMsg, icon: "error" }); }
        else if (ErrorCode === "-3") { SweetAlert.fire({ title: "Runtime Exception", text: "Some error occured! Please try after sometime.", icon: "error" }) }
        else if (ErrorCode === "-4") { SweetAlert.fire({ title: "Wrong Credentials", text: "Unauthorized user!", icon: "error" }) }
        else if (ErrorCode === "-5") { SweetAlert.fire({ title: "Oops", text: "Data could not be saved, Please try again later", icon: "error" }) }
        else if (ErrorCode === "-6") { SweetAlert.fire({ title: "Failed", text: "Check DB Logs", icon: "error" }) }
        else if (ErrorCode === "-7") { SweetAlert.fire({ title: "Duplicate", text: "Template already exists", icon: "error" }) }
        else if (ErrorCode === "-8") { SweetAlert.fire({ title: "Data unavailable", text: "No record found to import", icon: "error" }) }
        else if (ErrorCode === "-9") { SweetAlert.fire({ title: "LDAP authentication failed", text: ErrorMsg, icon: "error" }) }
        else { SweetAlert.fire({ title: "Application Exception", text: "Some error occured! Please try after sometime", icon: "error" }) }
    }
    async function AllowModuleChangeMove(GlobalAccessKeyId) {
        try {
            localStorage.setItem('Session', "");
            localStorage.removeItem('LoggedInUser');
            localStorage.removeItem('UserName');
            localStorage.removeItem("UserGroups");
            localStorage.removeItem("CentreInfo");
            localStorage.removeItem("UserGroups");
            localStorage.removeItem("UserSubGroups");
            localStorage.removeItem("UserAllowModule");
            localStorage.removeItem("UserSourceMandatory");
            localStorage.removeItem("PageGrpName");
            if (GlobalAccessKeyId!=null) {
                let url = FrontDesk_URL + 'Users/GetLogModuleChangeStatusDetails';
                let options = {
                    method: 'POST',
                    url: url,
                    headers: { 'Accept': 'application/json', 'Content-Type': 'application/json;charset=UTF-8' },
                    data: { ModuleId: "2", SecretKey: `${GlobalAccessKeyId}` }
                };
               
                let response = await axios(options);
                let responseOK = response && response.status == 200;
                if (responseOK) {
                  
                    // localStorage.removeItem("auth0_profile")
                    // localStorage.setItem("authenticated", false)
                   
                   //localStorage.clear();
                    let data = response.data;
                   
                    if (data.Success == true) {
                     
                        let ds = data.Data;
                        if (ds != null) {
                            const today = new Date();
                            const date = today.setDate(today.getDate());
                            const defaultValue = new Date(date).toISOString().split('T')[0] // yyyy-mm-dd
                            localStorage.setItem("LoginDate",defaultValue);
                            localStorage.setItem("UserAccessKey",ds.objVerifiedUserInfo.LoginActiveKey);
                            //localStorage.setItem("UserAccessKey","");
                            //localStorage.setItem('Session', "");
                            localStorage.setItem('Session', "Active");
                            localStorage.setItem('LoggedInUser', ds.objVerifiedUserInfo.UserId);
                            localStorage.setItem('UserName', ds.objVerifiedUserInfo.UserName);
                            localStorage.setItem("UserGroups", JSON.stringify(ds.objUserAllowGroupsDetailsList));
                            localStorage.setItem("CentreInfo", JSON.stringify(ds.centreuserinfosList));
                            localStorage.setItem("UserGroups", JSON.stringify(ds.objUserAllowGroupsDetailsList));
                            localStorage.setItem("UserSubGroups", JSON.stringify(ds.objUserSubAllowGroupsList));
                            localStorage.setItem("UserAllowModule", JSON.stringify(ds.objUserAssignedModuleList));
                            localStorage.setItem("UserSourceMandatory", JSON.stringify(ds.centreuserinfosList));
                            if (ds.centreuserinfosList.length > 1) {
                                openCentreModal();
                                setViewCentres(ds.centreuserinfosList);
                            }
                            else {
                                localStorage.setItem('CentreSNo', ds.centreuserinfosList[0].CentreId);
                                localStorage.setItem('CentreName', ds.centreuserinfosList[0].CentreName);
                                localStorage.setItem('CentreCode', ds.centreuserinfosList[0].CentreCode);
                                localStorage.setItem('IsSourceMandatory', ds.centreuserinfosList[0].IsSourceMandatory);
                                GetSettings(ds.centreuserinfosList[0].CentreId);
                            }
                        }
                        else { 
                           
                            window.location.assign(`${process.env.PUBLIC_URL}/login`); }
                    }
                    else {
                     
                        window.location.assign(`${process.env.PUBLIC_URL}/login`); }
                }
                else { 
                  
                    window.location.assign(`${process.env.PUBLIC_URL}/login`); }
            }
        } catch (error) {
            
            window.location.assign(`${process.env.PUBLIC_URL}/login`); console.log(error.message); }
    }

    return (
        <>
            <Form onSubmit={formik.handleSubmit}>
                <div className="page-wrapper">
                    <div className="container-fluid p-0">
                        <div className="authentication-main">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="auth-innerright">
                                        <div className="authentication-box">
                                            <div className="signinLogoHeader">
                                                <img src={logo} alt="" /></div>
                                            <div className='logoTagLine'>Imaging <span>&amp;</span> Laboratories</div>
                                            <div className="card mt-4">
                                                <div className="card-body">
                                                    <div className="text-center">
                                                        <h4>{LOGIN}</h4>
                                                        <h6>{"Enter your Username and Password"} </h6>
                                                    </div>
                                                    <form className="theme-form" >
                                                        <div className="form-group">
                                                            <label className="col-form-label pt-0">{YourName}</label>
                                                            <input className="form-control" type="text" name="email"
                                                                onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.email}
                                                                placeholder="Email address" ref={usernameref}
                                                            />
                                                            {formik.touched.email && formik.errors.email ? (<div className='text-required'>{formik.errors.email}</div>) : null}
                                                        </div>
                                                        <div className="form-group">
                                                            <label className="col-form-label">{Password}</label>
                                                            <input className="form-control" type="password" name="password"
                                                                onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.password}
                                                                placeholder="Password" ref={passwordref}
                                                            />
                                                            {formik.touched.password && formik.errors.password ? (<div className='text-required'>{formik.errors.password}</div>) : null}
                                                        </div>
                                                        <div className="checkbox p-0">
                                                            <input id="checkbox1" type="checkbox" onClick={(e) => { handleCheckbox(e); }} checked={checkbox} />
                                                            <label htmlFor="checkbox1">{RememberMe}</label>
                                                        </div>
                                                        {
                                                            isLoading ?
                                                                <LoadingButton variantName='secondary' cssName="btn btn-primary btn-block" /> :
                                                                <LoginButton reference={btnLogin} onClick={formik.handleSubmit} />
                                                        }
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <ToastContainer />
                    </div>
                </div>
            </Form>
            {choosecentremodal &&
                <Modal isOpen={choosecentremodal} toggle={openCentreModal} centered={true} size="lg">
                    <ModalHeader toggle={openCentreModal}>Centres</ModalHeader>
                    <ModalBody>
                        <Form.Row className="justify-content-center mt-3">
                            <Col lg={6} md={10}>
                                <Form.Group>
                                    <InputGroup className="mb-3">
                                        <Form.Control onChange={handleSearch} placeholder="Search by centre name..." ref={textSearch} />
                                        <InputGroup.Append>
                                            <Button variant="secondary" onClick={handleSearch}><i className='fa fa-search'></i></Button>
                                        </InputGroup.Append>
                                    </InputGroup>
                                </Form.Group>
                            </Col>
                        </Form.Row>

                        <Row>
                            <Col md={12}>
                                <Card>
                                    <Card.Body className='p-0'>
                                        <DataTables keyField='CentreId' tableData={viewcentres} columns={popupcentresColumns} pagination={paginationFactory(popupPaginationOptions)} />
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </ModalBody>
                </Modal>
            }
        </>
    );
};

export default withRouter(Signin);