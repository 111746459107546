import React, { useState, useEffect, useRef, useContext } from 'react'
import SweetAlert from 'sweetalert2';
import { Container, Row, Col, Card, Button, Form } from 'react-bootstrap';
import axios from 'axios';
import { Typeahead } from 'react-bootstrap-typeahead';
import { FrontDesk_URL, CallCentre_URL } from '../../../constant';
import CartsNewOrder from './CartsNewOrder';

var RegionId = "0";
var CenterId = "0";
var LocationId = "0";
var RateTemplateId = "0";
var showval = "0";
var cartarrnew = [];

const AppointmentNewOrder = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const [region, setRegion] = useState([]);
    const [location, setLocation] = useState([]);
    const [center, setCenter] = useState([]);
    const [options, setOptions] = useState([]);
    const [opencart, setOpenCart] = useState(false);
    const [reviewstatus, setReviewStatus] = useState(false);
    const [shownew, setShowNew] = useState(false);
    const multiple = false;
    let testreference = useRef();
    useEffect(() => {
        if (props.UserExistFlag === "1") {
            if (localStorage.getItem("sampleitemmasterdetails") === null) {
                GetWebsiteAppointmentDetails();
            }
            else {
                GetSampleDetails();
            }
        }
        else {
            if (localStorage.getItem("sampleitemmasterdetails") === null) {
                RateTemplateId = "0"; RegionId = "0"; CenterId = "0"; LocationId = "0"; GetRegions();
            }
            else {
                GetSampleDetails();
            }
        }
    }, [])
    useEffect(() => {
        if (opencart === false && (showval === "1" || showval === "2")) { 
            setOpenCart(true); 
            //props.opencart(false);    // check later
    }
        showval = "0";
    }, [opencart])
    useEffect(() => {
        GetRegions();
    }, [reviewstatus])

    const GetWebsiteAppointmentDetails = async () => {
        let url = CallCentre_URL + 'master/getwebsiteappointmentdetails/' + props.AppointmentId
        await axios.get(url).then((response) => {
            const data = response.data.Data;
            if (data === null) {
                localStorage.setItem("rid", "0");
                RegionId = "0";
                localStorage.setItem("did", "0");
                LocationId = "0";
                localStorage.setItem("cid", "0");
                CenterId = "0";
                localStorage.setItem('itemmasterdetails', []);
                RateTemplateId = "0"
                setOpenCart(false);
            } else {
                localStorage.setItem("rid", data.RegionId);
                RegionId = data.RegionId;
                localStorage.setItem("did", data.LocationId);
                LocationId = data.LocationId;
                localStorage.setItem("cid", data.CentreId);
                CenterId = data.CentreId;
                localStorage.setItem("appointmentdate", data.AppointmentDate);
                localStorage.setItem("appdate", data.SlotAppointmentDate);
                localStorage.setItem("apptime", data.SlotAppointmentTime);
                localStorage.setItem("apptimetext", data.FromTime.substring(0, 5) + " - " + data.ToTime.substring(0, 5));
                localStorage.setItem("appaddress", data.WebsiteAppointmentAddress);
                if (props.UserExistFlag === "1") { localStorage.setItem("RateTempId", data.RateTemplateId); RateTemplateId = data.RateTemplateId; }
                else {
                    var cartarr = [];
                    for (var key in data.itemdetailsrecord) {
                        cartarr.push({
                            ItemMasterId: data.itemdetailsrecord[key].ItemId,
                            IsPackage: data.itemdetailsrecord[key].IsPackage === "True" ? "1" : data.itemdetailsrecord[key].IsPackage === "False" ? "0" : data.itemdetailsrecord[key].IsPackage
                        })
                    }
                    localStorage.setItem('itemmasterdetails', JSON.stringify(cartarr));
                    RateTemplateId = "0";
                }
                GetRegions();
                // setOpenCart(true);
            }
        }).catch(function (error) { if (error.response) { ErrorFunction(error.response.status); } });
    }   
    const GetRegions = async () => {
        setIsLoading(true);
        let url = CallCentre_URL + 'cart/getcentreregions/0/0'
        await axios.get(url).then((response) => {
            const data = response.data;
            setIsLoading(false);
            if (data.Success) {
                var glblregion = [];
                for (var key in data.Data) {
                    if (data.Data[key].RegionId === RegionId) {
                        glblregion.push(<option key={data.Data[key].RegionId} value={data.Data[key].RegionId} selected='true'>{data.Data[key].RegionName}</option>)
                    }
                    else {
                        glblregion.push(<option key={data.Data[key].RegionId} value={data.Data[key].RegionId}>{data.Data[key].RegionName}</option>)
                    }
                }
                CallLocation(RegionId, "2");
                setRegion(glblregion);
            }
            else {
                RegionId = "0";
                setRegion([]);
                APIError(data.Data, data.ErrorList.length === 0 ? "" : data.ErrorList[0].errorMsg);
            }
        }).catch(function (error) {
            setIsLoading(false);
            if (error.response) {
                ErrorFunction(error.response.status);
            }
        });
    }
    const CallLocation = async (RegionId, flag) => {
        if (RegionId === "0") {
            CenterId = "0";
            LocationId = "0";
            setLocation([]);
            setCenter([]);
            localStorage.setItem("cid", "0");
            localStorage.setItem("did", "0");
            setOptions([]);
            testreference.current.clear();
            cartarrnew = [];
            localStorage.removeItem('addedmoreitems');
            localStorage.removeItem('itemmasterdetails');
        }
        else {
            if (flag === "1") {
                CenterId = "0";
                LocationId = "0";
                setLocation([]);
                setCenter([]);
                localStorage.setItem("cid", "0");
                localStorage.setItem("did", "0");
                setOptions([]);
                testreference.current.clear();
                cartarrnew = [];
                localStorage.removeItem('addedmoreitems');
                localStorage.removeItem('itemmasterdetails');
            }
            GetCities(RegionId);
        }
    }
    const GetCities = async (RegionId) => {
        setIsLoading(true);
        let url = CallCentre_URL + 'cart/getcentrelocation/0/0/' + RegionId
        await axios.get(url).then((response) => {
            const data = response.data;
            setIsLoading(false);
            if (data.Success) {
                var glbldistrict = [];
                for (var key in data.Data) {
                    if (data.Data[key].DistrictId === LocationId) {
                        glbldistrict.push(<option key={data.Data[key].DistrictId} value={data.Data[key].DistrictId} selected='true'>{data.Data[key].DistrictName}</option>)
                    }
                    else {
                        glbldistrict.push(<option key={data.Data[key].DistrictId} value={data.Data[key].DistrictId}>{data.Data[key].DistrictName}</option>)
                    }
                }
                setLocation(glbldistrict);
                CallCentresFunction(LocationId, "2");
            }
            else {
                LocationId = "0";
                setLocation([]);
                APIError(data.Data, data.ErrorList.length === 0 ? "" : data.ErrorList[0].errorMsg);
            }
        }).catch(function (error) {
            setIsLoading(false);
            if (error.response) {
                ErrorFunction(error.response.status);
            }
        });
    }
    const CallCentresFunction = (LocationId, flag) => {
        if (LocationId === "0") {
            setCenter([]);
            CenterId = "0";
            localStorage.setItem("cid", "0");
            setOptions([]);
            testreference.current.clear();
            cartarrnew = [];
            localStorage.removeItem('addedmoreitems');
            localStorage.removeItem('itemmasterdetails');
        }
        else {
            if (flag === "1") {
                setCenter([]); CenterId = "0";
                localStorage.setItem("cid", "0");
                setOptions([]);
                testreference.current.clear();
                cartarrnew = [];
                localStorage.removeItem('addedmoreitems');
                localStorage.removeItem('itemmasterdetails');
            }
            GetCentreAgainstLocation(LocationId);
        }
    }
    const GetCentreAgainstLocation = async (LocationId) => {
        setIsLoading(true);
        let url = CallCentre_URL + 'cart/getcentreagainstlocation/0/0/' + LocationId
        await axios.get(url).then((response) => {
            const data = response.data;
            setIsLoading(false);
            if (data.Success) {
                var glblcenter = [];
                for (var key in data.Data) {
                    if (data.Data[key].CentreId === CenterId) {
                        glblcenter.push(<option key={data.Data[key].CentreId} value={data.Data[key].CentreId} selected='true'>{data.Data[key].CentreName}</option>)
                    }
                    else {
                        glblcenter.push(<option key={data.Data[key].CentreId} value={data.Data[key].CentreId}>{data.Data[key].CentreName}</option>)
                    }
                }
                setCenter(glblcenter);
                if (props.UserExistFlag === "1") { setOpenCart(true); }
            }
            else {
                CenterId = "0";
                setCenter([]);
                APIError(data.Data, data.ErrorList.length === 0 ? "" : data.ErrorList[0].errorMsg);
            }
        }).catch(function (error) {
            setIsLoading(false);
            if (error.response) {
                ErrorFunction(error.response.status);
            }
        });
    }
    const getitemlist = async (e, text) => {
        if (center.current.value === "0") {
            SweetAlert.fire("Please select center");
            testreference.current.clear();
            return false;
        }
        if(RateTemplateId==null){RateTemplateId="0"}
        const requestOptions = {
            CentreSNo: CenterId, RateTypeSNo: RateTemplateId, Gender: props.Gender, Age: props.Age.toString(), Item: e.target.value, ItemTypeSno: "0"
        };
        let url = CallCentre_URL + 'cart/getItemList'
        await axios.post(url, requestOptions).then((response) => {
            const data = response.data.Data;
            if (data === null) { } else { setOptions(data); }
        }).catch(function (error) { });
    }
    const Addtestandpackagestocart = async (itmsno, ispackage, ratetemplateid) => {
        if (itmsno == "") { SweetAlert.fire('Please add test'); return false }
        testreference.current.clear();
        if (props.UserExistFlag === "1" && props.CenterId === center.current.value) {
            cartarrnew.push({
                ItemMasterId: itmsno,
                IsPackage: ispackage === "True" ? "1" : ispackage === "False" ? "0" : ispackage
            });
            localStorage.setItem('addedmoreitems', JSON.stringify(cartarrnew));
            localStorage.setItem('itemmasterdetails', JSON.stringify(cartarrnew));
            setShowNew(false);
            if (opencart === true) { setOpenCart(false); showval = "1"; }
            else { setOpenCart(true); }
        }
        else {
            if (localStorage.getItem('itemmasterdetails') !== null && localStorage.getItem('itemmasterdetails') !== "") {
                var arrf = JSON.parse(localStorage.getItem('itemmasterdetails'));
                var isBreak = "0";
                for (var y in arrf) {
                    if (arrf[y].ItemMasterId === itmsno && arrf[y].IsPackage === ispackage && isBreak === "0") {
                        isBreak = "1";
                    }
                }
                if (isBreak === "1") { SweetAlert.fire("Item already added"); return false; }
            }
            var cartarr = [];
            cartarr.push({
                ItemMasterId: itmsno,
                IsPackage: ispackage === "True" ? "1" : ispackage === "False" ? "0" : ispackage
            });
            if (localStorage.getItem('itemmasterdetails') !== null && localStorage.getItem('itemmasterdetails') !== "") {
                var arrnew = JSON.parse(localStorage.getItem('itemmasterdetails'));
                for (var keyy in arrnew) {
                    cartarr.push({
                        ItemMasterId: arrnew[keyy].ItemMasterId,
                        IsPackage: arrnew[keyy].IsPackage
                    })
                }
            }
            localStorage.setItem('addedmoreitems', JSON.stringify(cartarr));
            localStorage.setItem('itemmasterdetails', JSON.stringify(cartarr));
            setShowNew(true);
            if (opencart === true) { setOpenCart(false); showval = "1"; }
            else { setOpenCart(true);}
        }
    }
    const ChangeCentre = async (CentreId) => {
        setOptions([]);
        testreference.current.clear();
        localStorage.setItem("rid", region.current.value);
        RegionId = region.current.value;
        localStorage.setItem("did", location.current.value);
        LocationId = location.current.value;
        localStorage.setItem("cid", CentreId);
        CenterId = CentreId;
        localStorage.removeItem('addedmoreitems');
        localStorage.removeItem('itemmasterdetails');
        if (opencart === true) { setOpenCart(false); showval = "2"; }
        else { if (props.UserExistFlag === "1") { setOpenCart(true); } }
        if (props.CenterId === CenterId) { RateTemplateId = localStorage.getItem("RateTempId"); setShowNew(false); }
        else { RateTemplateId = "0"; setShowNew(true); }
    }
    const GetSampleDetails = () => {
        localStorage.setItem("rid", localStorage.getItem("samplerid"));
        RegionId = localStorage.getItem("samplerid");
        localStorage.setItem("did", localStorage.getItem("sampledid"));
        LocationId = localStorage.getItem("sampledid");
        localStorage.setItem("cid", localStorage.getItem("samplecid"));
        CenterId = localStorage.getItem("samplecid");
        GetRegions();
        setShowNew(true);
        if (opencart === true) { setOpenCart(false); showval = "1"; }
        else { setOpenCart(true); }
    }
    const ErrorFunction = async (ErrorCode) => {
        if (ErrorCode == "400") {
            SweetAlert.fire({ title: "Error 400", text: "Bad request", icon: "error" })
        }
        else if (ErrorCode == "401") {
            SweetAlert.fire({ title: "Error 401", text: "Unauthorized", icon: "error" })
        }
        else if (ErrorCode == "403") {
            SweetAlert.fire({ title: "Error 403", text: "Forbidden", icon: "error" })
        }
        else if (ErrorCode == "404") {
            SweetAlert.fire({ title: "Error 404", text: "Page not found", icon: "error" })
        }
        else if (ErrorCode == "500") {
            SweetAlert.fire({ title: "Error 500", text: "Internal Server Error", icon: "error" })
        }
        else if (ErrorCode == "503") {
            SweetAlert.fire({ title: "Error 503", text: "Service Unavailable", icon: "error" })
        }
    }
    const APIError = async (ErrorCode, ErrorMsg) => {
        if (ErrorCode === "-1") { SweetAlert.fire({ title: "Duplicate", text: "User already exist", icon: "error" }) }
        else if (ErrorCode === "0") { SweetAlert.fire({ title: "Location not found", text: "Please select location", icon: "error" }) }
        else if (ErrorCode === "-1997") { SweetAlert.fire({ title: "Empty", text: "No record found", icon: "error" }) }
        else if (ErrorCode === "-1998") { SweetAlert.fire({ title: "Some value is missing", text: "Check server and db logs", icon: "error" }) }
        else if (ErrorCode === "-1999") { SweetAlert.fire({ title: "Runtime Exception", text: ErrorMsg, icon: "error" }) }
        else { SweetAlert.fire({ title: "Application Exception", text: "Some error occured! Please try after sometime", icon: "error" }) }
    }
    return (
        <>
            {
                reviewstatus === false ?
                    <>
                        <Row>
                            <Col md={12}>
                                <Card>
                                    <Card.Header className='pb-0'>
                                        <Card.Title>Appointment Info</Card.Title>
                                    </Card.Header>
                                    <Card.Body>
                                        <Form.Row className='justify-content-center'>
                                            <Col md={4} lg={4}>
                                                <Form.Group>
                                                    <Form.Label>Region <span className='text-red'>*</span></Form.Label>
                                                    {
                                                        props.UserExistFlag === "0" ?
                                                            <Form.Control as="select" name="region" ref={region} onChange={(e) => CallLocation(e.target.value, "1")}>
                                                                <option key='0' value='0'>---Select---</option>
                                                                {region}
                                                            </Form.Control>
                                                            : <Form.Control as="select" name="region" ref={region} onChange={(e) => CallLocation(e.target.value, "1")} disabled>
                                                                <option key='0' value='0'>---Select---</option>
                                                                {region}
                                                            </Form.Control>
                                                    }
                                                </Form.Group>
                                            </Col>
                                            <Col md={4} lg={4}>
                                                <Form.Group>
                                                    <Form.Label>Location <span className='text-red'>*</span></Form.Label>
                                                    {
                                                        props.UserExistFlag === "0" ?
                                                            <Form.Control as="select" name="location" ref={location} onChange={(e) => CallCentresFunction(e.target.value, "1")}>
                                                                <option key='0' value='0'>---Select---</option>
                                                                {location}
                                                            </Form.Control>
                                                            : <Form.Control as="select" name="location" ref={location} onChange={(e) => CallCentresFunction(e.target.value, "1")} disabled>
                                                                <option key='0' value='0'>---Select---</option>
                                                                {location}
                                                            </Form.Control>
                                                    }
                                                </Form.Group>
                                            </Col>
                                            <Col md={4} lg={4}>
                                                <Form.Group>
                                                    <Form.Label>Center <span className='text-red'>*</span></Form.Label>
                                                    {
                                                        props.UserExistFlag === "0" ?
                                                            <Form.Control as="select" name="center" ref={center} onChange={(e) => ChangeCentre(e.target.value)}>
                                                                <option value='0'>---Select---</option>
                                                                {center}
                                                            </Form.Control>
                                                            : <Form.Control as="select" name="center" ref={center} onChange={(e) => ChangeCentre(e.target.value)} disabled>
                                                                <option value='0'>---Select---</option>
                                                                {center}
                                                            </Form.Control>
                                                    }
                                                </Form.Group>
                                            </Col>
                                        </Form.Row>
                                        <Form.Row>
                                            <Col md={12}>
                                                <Form.Group>
                                                    <Form.Label>Search Test</Form.Label>
                                                    <Typeahead
                                                        id="basic-typeahead"
                                                        labelKey="Item"
                                                        multiple={multiple}
                                                        options={options}
                                                        placeholder="Choose a test..."
                                                        ref={testreference}
                                                        onInputChange={(text, e) => { getitemlist(e, text); }}
                                                        onChange={(text) => {
                                                            JSON.stringify(text) == "[]" ? Addtestandpackagestocart("") : Addtestandpackagestocart(text[0].SNo, text[0].IsPackage, text[0].RateTemplatesId)
                                                        }}
                                                    />
                                                </Form.Group>
                                            </Col>
                                        </Form.Row>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </> :
                    null
            }
            {
                opencart &&
                <>
                    {
                        localStorage.cid === "0" ? null :
                            <CartsNewOrder  reviewstatus={(reviewstatus) => setReviewStatus(reviewstatus)} UserExistFlag={props.UserExistFlag} ShowNew={shownew} opencart={(opencart) => setOpenCart(opencart)} AppointmentId={props.AppointmentId} Status={props.Status} />
                    }
                </>
            }
        </>
    )
}
export default AppointmentNewOrder