import React, { useEffect, useState, useRef } from "react";
import { useHistory } from 'react-router';
import { Container, Row, Col, Card, Form, Button, Table } from 'react-bootstrap';
import Breadcrumb from '../../components/common/breadcrumb';
import SweetAlert from 'sweetalert2';
import { FrontDesk_URL, Image_Path } from '../../constant';
import axios from 'axios';
import LoadingButton from "../commonComponent/LoadingButton";
import Html5QrcodePlugin from '../UI/Barcode/Html5QrcodePlugin';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import paginationFactory from 'react-bootstrap-table2-paginator';
import DataTables from '../commonComponent/DataTable/DataTables';
import NoRecordFounds from '../commonComponent/DataTable/NoRecordFounds';

var glblCount = 0;
var fdate = "";
var fdate1 = "";
var glblDocArr = [];
const SampleDetail = () => {
    const history = useHistory();
    const [patientName, setPatientName] = useState();
    const [isbuttonLoading, setIsButtonLoading] = useState(false);
    const [patientAge, setPatientAge] = useState();
    const [visitNo, setVisitNo] = useState();
    const [visitid, setVisitId] = useState();
    const [itemCount, setItemCount] = useState();
    const [tableData, setTableData] = useState([]);
    const [mobiletableData, setMobileTableData] = useState([]);
    const [openmodal, setOpenModal] = useState(false);
    const [barcodesampleid, setBarcodeSampleID] = useState("0");
    const [remarksModal, setRemarksModal] = useState();
    const [viewremarks, setViewRemarks] = useState([]);


    var typeArray = [];
    const refSampleType = useRef([]);
    const refBarCode = useRef([]);
    const refVialQty = useRef([]);
    const refId = useRef([]);
    const [isSaving, setIsSaving] = useState(false);
    const [hideCollectButton, setHideCollectButton] = useState(false);
    const [doctype, setDocType] = useState("0");
    const [doctypename, setDocTypeName] = useState("");
    const [documenttype, setDocumentType] = useState([]);
    const [tabledocdata1, setTableDocData1] = useState([]);
    const [viewreqfieldsModal, setViewReqFieldsModal] = useState();
    const [tabledocdata, setTableDocData] = useState([]);
    const [doccrntPageNO, setDocCrntPageNO] = useState(0);
    const [doccrntPageNO1, setDocCrntPageNO1] = useState(0);
    const [prescriptiondocument, setPrescriptionDocument] = useState("");
    const [isButtonAllLoading, setIsButtonAllLoading] = useState(false);
    let doctypee = useRef();
    useEffect(() => {
        GetDetails();
        return () => {
            localStorage.removeItem('vId');
        }
    }, []);

    let remarks = useRef();
    const GetDetails = async () => {
        try {
            let url = FrontDesk_URL + 'samplecollection/getdetails/' + localStorage.getItem('vId');
            await axios.get(url).then((response) => {
                const data = response.data;
                if (data.Success) {
                    const sampleData = data.Data;
                    setPatientName(sampleData.PatientName);
                    setPatientAge(sampleData.Age);
                    setVisitNo(sampleData.VisitingCode);
                    setVisitId(sampleData.VisitingId);
                    if (sampleData.Status === "0" || sampleData.Status === "1" || sampleData.Status === "True" || sampleData.Status === "False" || sampleData.Status === "6") { setHideCollectButton(false); }
                    else { setHideCollectButton(true); }
                    let i = 0;
                    let hexaColor = "";
                    glblCount = sampleData.Investigations.length;
                    for (i = 0; i < sampleData.Investigations.length; i++) {
                        const invDetails = sampleData.Investigations[i];
                        typeArray = [];
                        let x = 0;
                        for (x = 0; x < invDetails.SampleTypes.length; x++) {
                            const sampleType = invDetails.SampleTypes[x];
                            if (sampleType.IsDefault === "1") { typeArray.push(<option selected value={sampleType.Id} data-color={sampleType.Color}>{sampleType.Name}</option>); }
                            else { typeArray.push(<option value={sampleType.Id} data-color={sampleType.Color}>{sampleType.Name}</option>); }
                            if (x === 0) { hexaColor = sampleType.Color; }
                        }
                        refId.current[i] = invDetails.Id;
                        setItemCount(i + 1);
                        const mediaQuery = window.matchMedia('(max-width: 1024px)')
                        if (mediaQuery.matches) {
                            setTableData((tableData) => [
                                ...tableData,
                                (<tr key={i} id={`${i}`}>
                                    <th>{i + 1}</th>
                                    <td>
                                        {
                                            invDetails.Status === "0" || invDetails.Status === "6" ?
                                                <div className='colorCode1' style={{ 'backgroundColor': `${hexaColor}` }} id={`dv${invDetails.Id}`}></div> :
                                                <div className='colorCode1' style={{ 'backgroundColor': `${invDetails.ColorCode}` }} id={`dv${invDetails.Id}`}></div>
                                        }

                                    </td>
                                    <td>
                                        {invDetails.Status === "0" || invDetails.Status === "6" ?
                                            <Form.Control as="select" className='selSampleType' onChange={changeHandler(invDetails.Id)} ref={(d) => (refSampleType.current[i] = d)}>
                                                {typeArray}
                                            </Form.Control>
                                            :
                                            invDetails.SampleType
                                        }
                                    </td>
                                    <td>{invDetails.ItemName} <br /><p><b>{invDetails.SampleQty + invDetails.SampleRemarks}</b></p></td>
                                    <td>
                                        {(invDetails.Status === "0" || invDetails.Status === "6") && (invDetails.IsRefunded == "0" || invDetails.IsRefunded.toLowerCase() == "false") ?
                                            <input type="text" className="form-control inputSin" maxLength={10} ref={(d) => (refBarCode.current[i] = d)} onChange={barcodeChangeHandler(i)}></input> :
                                            (invDetails.Status === "0" || invDetails.Status === "6") && (invDetails.IsRefunded == "1" || invDetails.IsRefunded.toLowerCase() == "true") ?
                                                <input type="text" className="form-control inputSin" maxLength={10} ref={(d) => (refBarCode.current[i] = d)} disabled></input>
                                                : invDetails.Barcode
                                        }
                                    </td>
                                    <td>
                                        {(invDetails.Status === "0" || invDetails.Status === "6") && (invDetails.IsRefunded == "0" || invDetails.IsRefunded.toLowerCase() == "false") ?
                                            <><i className="fa fa-camera" id={`adesktopBarcode${invDetails.Id}`} onClick={BarCodeReaderOpen(i)}></i> &nbsp; &nbsp; <input className="checkbox_animated" type="checkbox" ref={(d) => (refVialQty.current[i] = d)} id={`chkBx${invDetails.Id}`} defaultChecked={true} /></> : null}
                                    </td>
                                    <td>{invDetails.ItemStatus}</td>
                                </tr >),
                            ]);
                            setMobileTableData((mobiletableData) => [
                                ...mobiletableData,
                                (<tr key={i} id={`${i}`}>
                                    <td>
                                        <Row>
                                            <Col xs={6}>
                                                <Form.Group className='mb-2'>
                                                    <Form.Label><b>Color Code</b></Form.Label><br />
                                                    {
                                                        invDetails.Status === "0" || invDetails.Status === "6" ?
                                                            <div className='colorCode1' style={{ 'backgroundColor': `${hexaColor}` }} id={`dv${invDetails.Id}`}></div> :
                                                            <div className='colorCode1' style={{ 'backgroundColor': `${invDetails.ColorCode}` }} id={`dv${invDetails.Id}`}></div>
                                                    }
                                                </Form.Group>
                                            </Col>
                                            <Col xs={6}>
                                                <Form.Group className='animate-chk icRight'>
                                                    <Form.Label>&nbsp;</Form.Label>
                                                    {invDetails.Status === "0" || invDetails.Status === "6" ?
                                                        <Form.Label>
                                                            <input className="checkbox_animated" type="checkbox" ref={(d) => (refVialQty.current[i] = d)} id={`chkBx${invDetails.Id}`} defaultChecked={true} /> Same Vial?
                                                        </Form.Label>
                                                        : null}
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={6}>
                                                <Form.Group>
                                                    <Form.Label><b>Sample Type</b></Form.Label><br />
                                                    {invDetails.Status === "0" ?
                                                        <Form.Control as="select" className='txtSample' onChange={changeHandler(invDetails.Id)} ref={(d) => (refSampleType.current[i] = d)}>
                                                            {typeArray}
                                                        </Form.Control>
                                                        :
                                                        invDetails.SampleType
                                                    }
                                                </Form.Group>
                                            </Col>

                                            <Col xs={6}>
                                                <Form.Group>
                                                    {
                                                        (invDetails.IsRefunded == "0" || invDetails.IsRefunded.toLowerCase() == "false") ?
                                                            <>
                                                                <Form.Label><b>SIN No <i className="fa fa-camera" id={`aBarcode${invDetails.Id}`} onClick={BarCodeReaderOpen(i)}></i></b></Form.Label><br />
                                                            </>
                                                            : null
                                                    }
                                                    {(invDetails.Status === "0" || invDetails.Status === "6") && (invDetails.IsRefunded == "0" || invDetails.IsRefunded.toLowerCase() == "false") ?
                                                        <Form.Control type="text" className='txtSin' maxLength={10} ref={(d) => (refBarCode.current[i] = d)} onChange={barcodeChangeHandler(i)} />
                                                        :
                                                        (invDetails.Status === "0" || invDetails.Status === "6") && (invDetails.IsRefunded == "1" || invDetails.IsRefunded.toLowerCase() == "true") ?
                                                            <Form.Control type="text" className='txtSin' maxLength={10} ref={(d) => (refBarCode.current[i] = d)} disabled />
                                                            : invDetails.Barcode
                                                    }
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={12}>
                                                <b>Investigation:</b><br />
                                                <p className="mb-0">{invDetails.ItemName} <b> {invDetails.SampleQty + invDetails.SampleRemarks}</b></p>
                                            </Col>
                                        </Row>
                                        <Row className='mt-3'>
                                            <Col xs={12}>
                                                <b>Status:</b> {invDetails.ItemStatus}
                                            </Col>
                                        </Row>
                                    </td>
                                </tr >),
                            ]);
                        }
                        else {
                            setTableData((tableData) => [
                                ...tableData,
                                (<tr key={i} id={`${i}`}>
                                    <th>{i + 1}</th>
                                    <td>
                                        {
                                            invDetails.Status === "0" || invDetails.Status === "6" ?
                                                <div className='colorCode1' style={{ 'backgroundColor': `${hexaColor}` }} id={`dv${invDetails.Id}`}></div> :
                                                <div className='colorCode1' style={{ 'backgroundColor': `${invDetails.ColorCode}` }} id={`dv${invDetails.Id}`}></div>
                                        }

                                    </td>
                                    <td>
                                        {invDetails.Status === "0" || invDetails.Status === "6" ?
                                            <Form.Control as="select" className='selSampleType' onChange={changeHandler(invDetails.Id)} ref={(d) => (refSampleType.current[i] = d)}>
                                                {typeArray}
                                            </Form.Control>
                                            :
                                            invDetails.SampleType
                                        }
                                    </td>
                                    <td>{invDetails.ItemName} <br /><p><b>{invDetails.SampleQty + invDetails.SampleRemarks}</b></p></td>
                                    <td>
                                        {(invDetails.Status === "0" || invDetails.Status === "6") && (invDetails.IsRefunded == "0" || invDetails.IsRefunded.toLowerCase() == "false") ?
                                            <input type="text" className="form-control inputSin" maxLength={10} ref={(d) => (refBarCode.current[i] = d)} onChange={barcodeChangeHandler(i)}></input> :
                                            (invDetails.Status === "0" || invDetails.Status === "6") && (invDetails.IsRefunded == "1" || invDetails.IsRefunded.toLowerCase() == "true") ?
                                                <input type="text" className="form-control inputSin" maxLength={10} ref={(d) => (refBarCode.current[i] = d)} disabled></input>
                                                :
                                                invDetails.Barcode
                                        }
                                    </td>
                                    <td>
                                        {(invDetails.Status === "0" || invDetails.Status === "6") && (invDetails.IsRefunded == "0" || invDetails.IsRefunded.toLowerCase() == "false") ? <><i className="fa fa-camera" id={`adesktopBarcode${invDetails.Id}`} onClick={BarCodeReaderOpen(i)}></i> &nbsp;&nbsp; <input className="checkbox_animated" type="checkbox" ref={(d) => (refVialQty.current[i] = d)} id={`chkBx${invDetails.Id}`} defaultChecked={true} /> </> : null}
                                    </td>
                                    <td>{invDetails.ItemStatus}</td>
                                </tr >),
                            ]);
                        }
                    }
                }
            }).catch(function (error) { });
        }
        catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    }
    const onNewScanResult = (decodedText, decodedResult) => {
        //alert(JSON.stringify(decodedResult));
        //alert(JSON.parse(decodedResult));
        console.log(decodedText);
        const barCodeVal = decodedText;
        let sampleTypeVal = 0;
        if (refSampleType.current[barcodesampleid] !== undefined) { sampleTypeVal = refSampleType.current[barcodesampleid].value; }
        for (let i = 0; i < glblCount; i++) {
            if (refSampleType.current[i] !== undefined) {
                if (refSampleType.current[i].value === sampleTypeVal) {
                    if ((refBarCode.current[i] !== undefined) && (refVialQty.current[i].checked)) {
                        refBarCode.current[i].value = barCodeVal;
                    }
                }
            }
        }
        setOpenModal(false);
    };
    const changeHandler = (id) => (e) => {
        document.getElementById('dv' + id).style.backgroundColor = e.target[e.target.selectedIndex].getAttribute('data-color');
    }
    const barcodeChangeHandler = (id) => (e) => {
        const barCodeVal = e.target.value;
        let sampleTypeVal = 0;
        if (refSampleType.current[id] !== undefined) { sampleTypeVal = refSampleType.current[id].value; }
        for (let i = 0; i < glblCount; i++) {
            if (refSampleType.current[i] !== undefined) {
                if (refSampleType.current[i].value === sampleTypeVal) {
                    if ((refBarCode.current[i] !== undefined) && (refVialQty.current[i].checked)) {
                        refBarCode.current[i].value = barCodeVal;
                    }
                }
            }
        }
    }
    const vialChangeHandler = (id) => (e) => {
        const vialQtyVal = e.target.value;
        let sampleTypeVal = 0;
        if (refSampleType.current[id] !== undefined) { sampleTypeVal = refSampleType.current[id].value; }
        for (let i = 0; i < glblCount; i++) {
            if (refSampleType.current[i] !== undefined) {
                if (refSampleType.current[i].value === sampleTypeVal) {
                    if (refVialQty.current[i] !== undefined) {
                        refVialQty.current[i].value = vialQtyVal;
                    }
                }
            }
        }
    }
    const backHandler = (event) => { event.preventDefault(); localStorage.removeItem('vId'); history.push('/sample-collection/list'); }
    const handleSave = async () => {
        var chkexist = 0;
        var duplicatebarcodes = "";
        for (let i = 0; i < itemCount; i++) {
            if (refBarCode.current[i] !== undefined) {
                if (refBarCode.current[i].value !== "") {
                    try {
                        let url = FrontDesk_URL + 'samplecollection/checkduplicatebarcode/' + refBarCode.current[i].value
                        await axios.get(url).then((response) => {
                            const data = response.data;
                            if (data.Success === true) {
                                if (data.Data === "1") {
                                    chkexist = 1; duplicatebarcodes += refBarCode.current[i].value + ", "
                                }
                            }
                            else {
                                SweetAlert.fire({ title: "Error!", text: data.Data, icon: "error" });
                            }
                        }).catch(function (error) { if (error.response) { } });
                    }
                    catch (error) {
                        SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" });
                    }
                }
            }
        }
        if (chkexist === 1) {
            SweetAlert.fire({ title: "Duplicate Barcode!", text: "Barcode already in LIS: " + duplicatebarcodes, icon: "error" });
        }
        if (chkexist === 0) { SaveSamplesCollected(); }
    }
    function trimStr(str) {
        if (!str) return str;
        return str.replace(/^\s+|\s+$/g, '');
    }
    const SaveSamplesCollected = async () => {
        var sampleList = [];
        var callSave = false;
        for (let i = 0; i < itemCount; i++) {
            if (refBarCode.current[i] !== undefined) {
                if (refBarCode.current[i].value !== "") {
                    callSave = true;
                    var bcode = trimStr(refBarCode.current[i].value);
                    sampleList.push(
                        {
                            Id: refId.current[i], SampleType: refSampleType.current[i].value, Barcode: bcode,
                            VialQty: "1", ModifiedBy: localStorage.LoggedInUser, VisitingId: visitid
                        }
                    );
                }
            }
        }
        if (callSave) {
            setIsSaving(true);
            let url = FrontDesk_URL + 'samplecollection/savecollectedsamples'
            await axios.post(url, sampleList).then((response) => {
                const data = response.data;
                if (data.Success) {
                    SweetAlert.fire({ title: "Success", text: "Data saved successfully", icon: "success" });
                    localStorage.removeItem('vId');
                    history.push('/sample-collection/list');
                }
                else {
                    var errorList = data.ErrorList;
                    if (errorList[0].errorCode === "-2") {
                        SweetAlert.fire({ title: "Validation Failed", text: errorList[0].errorMsg, icon: "error" }); setIsSaving(false);
                    }
                    else if (errorList[0].errorCode === "-3") {
                        SweetAlert.fire({ title: "Validation Failed", text: "Barcode could not same for different sample type", icon: "error" }); setIsSaving(false);
                    }
                    else if (errorList[0].errorCode === "-4") {
                        SweetAlert.fire({ title: "Validation Failed", text: "You had edited items either from another window or another device.Please refresh page", icon: "error" }); setIsSaving(false);
                    }
                    else {
                        SweetAlert.fire({ title: "Application Exception", text: "Some error occured! Please try after sometime", icon: "error" }); setIsSaving(false);
                    }
                }
            }).catch(function (error) { setIsSaving(false); });
        }
        else { SweetAlert.fire({ title: "Validation", text: "Please add barcode against atleast one record.", icon: "error" }); }
    }
    const openBarCodeModal = () => { setOpenModal(!openmodal); }
    const BarCodeReaderOpen = (id) => (e) => {
        setOpenModal(true);
        setBarcodeSampleID(id);
    }
    const closeBtn = (
        <>
            <button className="close" onClick={openBarCodeModal} type="button">
                &times; </button>
        </>
    );
    const openRemarksModal = async () => {
        setRemarksModal(!remarksModal);
        ViewAllRemarks(visitid);
    }
    const ViewAllRemarks = async (vId) => {
        var visitid = (vId == null || vId == undefined || vId == "" ? "0" : vId)
        try {
            let url = FrontDesk_URL + 'master/viewremarks/' + visitid + '/' + localStorage.CentreSNo + '/' + localStorage.LoggedInUser
            await axios.get(url).then((response) => {
                const data = response.data;
                if (data.Success) {
                    if (data.Data != null) { setViewRemarks(data.Data); }
                    else {
                        setViewRemarks([]);
                        // SweetAlert.fire("No record found");
                    }
                } else { setViewRemarks([]); }
            }).catch(function (error) { if (error.response) { setViewRemarks([]); ErrorFunction(error.response.status); } });
        }
        catch (error) { setViewRemarks([]); SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    }
    const SaveRemarks = async () => {
        if (visitid == "0") { SweetAlert.fire("Unable to get some values.Please refresh before proceed!"); return false; }
        if (remarks.current.value == null || remarks.current.value == undefined || remarks.current.value == "") { SweetAlert.fire("Please enter remarks"); return false; }
        setIsButtonLoading(true);
        const requestOptions = { Remarks: remarks.current.value, VisitingId: visitid, CentreId: localStorage.getItem('CentreSNo'), LoginId: localStorage.getItem('LoggedInUser') };
        let url = FrontDesk_URL + 'master/saveremarks'
        await axios.post(url, requestOptions).then((response) => {
            const data = response.data;
            if (data.Success) {
                if (data.Data !== null) {
                    SweetAlert.fire({
                        title: 'Successful',
                        text: "Remarks saved succesfully",
                        icon: 'success',
                        showCancelButton: false,
                        confirmButtonText: 'Ok',
                        reverseButtons: true
                    }).then((result) => {
                        if (result.value) { window.location.reload(false); } else { window.location.reload(false); }
                    });
                }
                else { SweetAlert.fire("Unable to save data. Please try again."); }
                setIsButtonLoading(false);
            }
            else { SweetAlert.fire({ title: "Application Exception", text: "Some error occured! Check db and server logs", icon: "error" }); setIsButtonLoading(false); }
        }).catch(function (error) { if (error.response) { ErrorFunction(error.response.status); } setIsButtonLoading(false); });
    }
    const openSaveRequiredFieldModal = () => {
        setDocType("0");
        setDocTypeName("");
        setDocumentType([]);
        glblDocArr = [];
        setTableDocData1([]);
        if (localStorage.getItem('vId') == null || localStorage.getItem('vId') == undefined || localStorage.getItem('vId') == "") { SweetAlert.fire("Unable to get visit number. Please refresh and come back again from previous screen."); return false; }
        setVisitId(localStorage.getItem('vId'));
        openRequiredFieldModal();
        GetDocumentType();
        ShowAllDocuments(localStorage.getItem('vId'));
    }
    const openRequiredFieldModal = () => { setViewReqFieldsModal(!viewreqfieldsModal); }
    const GetDocumentType = async () => {
        try {
            let url = FrontDesk_URL + 'master/GetDocumentType/' + localStorage.CentreSNo
            await axios.get(url).then((response) => {
                const data = response.data;
                if (data.Success) {
                    if (data.Data == null) { SweetAlert.fire("No document type available"); }
                    else { setDocumentType(data.Data.map((d) => (<option key={d.DocumentId} value={d.DocumentId}>{d.DocumentName}</option>))); }
                }
                else { setDocumentType([]); SweetAlert.fire("Error occured due to: " + data.Data); }
            }).catch(function (error) {
                setDocumentType([]);
                if (error.response) {
                    ErrorFunction(error.response.status);
                }
            });
        }
        catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    }
    const ShowAllDocuments = async (vId) => {
        try {
            let url = FrontDesk_URL + 'master/getalldocuments_doc/' + vId
            await axios.get(url).then((response) => {
                const data = response.data;
                if (data.Success) {
                    if (data.Data != null) {
                        setTableDocData(data.Data);
                    }
                    else { setTableDocData([]); }
                }
                else {
                    if (data.data != null) { SweetAlert.fire("Error: " + data.Data); }
                    else { SweetAlert.fire("Some error occured. Check logs!"); }
                }
            }).catch(function (error) { if (error.response) { ErrorFunction(error.response.status); } });
        }
        catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    }
    function downloadFile(url, fileName) {
        const aElement = document.createElement('a');
        aElement.setAttribute('download', fileName);
        aElement.href = url;
        aElement.setAttribute('target', '_blank');
        aElement.click();
        URL.revokeObjectURL(url);
    }
    const docPaginationOptions = {
        sizePerPage: 10,
        showTotal: false,
        firstPageText: 'First',
        prePageText: 'Back',
        nextPageText: 'Next',
        lastPageText: 'Last',
        onPageChange: (page, sizePerPage) => { setDocCrntPageNO(((page - 1) * sizePerPage)); }
    };
    const docPaginationOptions1 = {
        sizePerPage: 10,
        showTotal: false,
        firstPageText: 'First',
        prePageText: 'Back',
        nextPageText: 'Next',
        lastPageText: 'Last',
        onPageChange: (page, sizePerPage) => { setDocCrntPageNO1(((page - 1) * sizePerPage)); }
    };
    const OpenDocument = async (VisitingId, Type, Document) => {
        var prename = "";
        var preurl = "";
        if (Type.toLowerCase() == "doctor prescription") {
            prename = "Prescription_" + VisitingId;
            preurl = Image_Path + "prescription/" + Document;
        }
        else {
            prename = "Prerequisite_" + VisitingId;
            preurl = Image_Path + "prerequisite/" + Document;
        }
        downloadFile(preurl, prename);
    }
    const iddocFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                <span>{doccrntPageNO + rowIndex + 1}</span>
            </div>
        </React.Fragment>
    }
    const iddocFormatter1 = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                <span>{doccrntPageNO1 + rowIndex + 1}</span>
            </div>
        </React.Fragment>
    }
    const docFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                <button className='btn btn-link' style={{ color: '#e31e25' }} id={`Documents${rowData.RowId}`} onClick={() => OpenDocument(rowData.VisitingId, rowData.Type, rowData.Document)}>View Document</button>
            </div>
        </React.Fragment>
    }
    const docFormatter1 = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                <button className='btn btn-link' style={{ color: '#e31e25' }} id={`Documents${rowData.RowId}`} onClick={() => OpenDocument(rowData.VisitingId, rowData.Type, rowData.Document)}>View Document</button>
            </div>
        </React.Fragment>
    }
    const docColumns = [
        { dataField: '#', text: '#', editable: false, formatter: iddocFormatter, headerStyle: (colum, colIndex) => { return { width: '2%' }; } },
        { dataField: 'Type', text: 'Type', editable: false, headerStyle: (colum, colIndex) => { return { width: '5%' }; } },
        { dataField: 'actions', text: 'Actions', editable: false, formatter: docFormatter, headerStyle: (colum, colIndex) => { return { width: '5%' }; } }
    ];
    const docColumns1 = [
        { dataField: '#', text: '#', editable: false, formatter: iddocFormatter1, headerStyle: (colum, colIndex) => { return { width: '2%' }; } },
        { dataField: 'Type', text: 'Type', editable: false, headerStyle: (colum, colIndex) => { return { width: '5%' }; } },
        { dataField: 'actions', text: 'Actions', editable: false, formatter: docFormatter1, headerStyle: (colum, colIndex) => { return { width: '5%' }; } }
    ];
    const ChangeDocType = async (e) => {
        setDocType(e.target.value);
        setDocTypeName(e.target.options[e.target.selectedIndex].text);
    }
    const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
    const UploadPrescription = async (e) => {
        const arryVal = e.target.files[0].name.split(".");
        const extension = arryVal[arryVal.length - 1];
        var base64 = await toBase64(e.target.files[0]);
        var base64img = base64.split("base64,");
        const formData = new FormData();
        formData.append('Image', base64img[1]);
        formData.append('FolderName', doctype == "2" ? 'prescription' : 'prerequisite');
        formData.append('pathflag', doctype == "2" ? 'prescription' : 'prerequisite');
        const ImageName = Math.random();
        var imgName = "";
        if (doctype == "2") { imgName = ImageName + "prescription." + extension; }
        else { imgName = ImageName + "prerequisite." + extension; }
        formData.append('ImageName', imgName);
        let url = FrontDesk_URL + 'master/UploadImage_Root/'
        await axios.post(url, formData, {
            headers: { "Content-type": "multipart/form-data", },
        }).then((response) => {
            const data = response.data;
            if (data.Success) {
                var key = glblDocArr.length;
                glblDocArr.push({
                    RowId: (parseInt(key) + 1).toString(),
                    VisitingId: (visitid == null || visitid == undefined || visitid == "") ? "0" : visitid,
                    Document: imgName,
                    Type: doctypename//doctype == "2" ? "Prescription" : "Others"
                });
                setPrescriptionDocument(imgName);
                setTableDocData1(glblDocArr);
                setDocType("0");
                doctypee.current.value = "0";
            } else { SweetAlert.fire({ title: "Upload failed", text: "Unable to upload document due to " + data.Data + ".Please retry again", icon: "error" }) }
        }).catch(function (error) { if (error.response) { } });
    }
    const SaveRequiredFields = async () => {
        if (tabledocdata1.length == 0 || tabledocdata1 == [] || tabledocdata1 == null) {
            SweetAlert.fire("Please upload document first");
            return false;
        }
        var requestOptions = {
            CentreId: localStorage.CentreSNo,
            LoginId: localStorage.LoggedInUser,
            VisitingId: (visitid == null || visitid == undefined || visitid == "") ? "0" : visitid,
            PrescriptionDocument: JSON.stringify(tabledocdata1)//prescriptiondocument
        };
        setIsButtonAllLoading(true);
        try {
            let url = FrontDesk_URL + 'master/saveprescriptiondocument'
            await axios.post(url, requestOptions).then((response) => {
                const data = response.data;
                setIsButtonAllLoading(false);
                if (data.Success) {
                    SweetAlert.fire({
                        title: 'Success',
                        text: "Data saved successfully",
                        icon: 'success',
                        confirmButtonText: 'Ok',
                        reverseButtons: true
                    }).then((result) => {
                        if (result.value) { openRequiredFieldModal(); }
                        else { openRequiredFieldModal(); }
                    })
                }
                else {
                    SweetAlert.fire({ title: "Error", text: "Unable to save data.Please try again", icon: "error" });
                }
            }).catch(function (error) {
                setIsButtonAllLoading(false);
                if (error.response) {
                    ErrorFunction(error.response.status);
                }
            });
        }
        catch (error) {
            setIsButtonAllLoading(false);
            SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" });
        }
    }
    const ErrorFunction = async (ErrorCode) => {
        if (ErrorCode == "400")
            SweetAlert.fire({ title: "Error 400", text: "Bad request", icon: "error" })
        else if (ErrorCode == "401")
            SweetAlert.fire({ title: "Error 401", text: "Unauthorized", icon: "error" })
        else if (ErrorCode == "403")
            SweetAlert.fire({ title: "Error 403", text: "Forbidden", icon: "error" })
        else if (ErrorCode == "404")
            SweetAlert.fire({ title: "Error 404", text: "Page not found", icon: "error" })
        else if (ErrorCode == "500")
            SweetAlert.fire({ title: "Error 500", text: "Internal Server Error", icon: "error" })
        else if (ErrorCode == "503")
            SweetAlert.fire({ title: "Error 503", text: "Service Unavailable", icon: "error" })
    }
    return (
        <>
            <Breadcrumb title="Sample Collection" parent="Master" />
            <Container fluid>
                <div className='d-none d-lg-block'>
                    <Row>
                        <Col md={12}>
                            <Card>
                                <Card.Header className='pb-0'>
                                    <Card.Title>Sample Detail</Card.Title>
                                </Card.Header>
                                <Card.Body>
                                    <div className='row align-items-center'>
                                        <div className='col-md-7 order-2 order-md-1'>
                                            <p><b>Name:</b>{patientName}, <b>Age:</b> {patientAge} Years, <b>Visit No:</b> {visitNo}</p>
                                        </div>
                                        <div className='col-md-5 order-1 order-md-2 text-right'>
                                            <Button className='mr-3' variant="primary" onClick={openSaveRequiredFieldModal}>Upload Document</Button>
                                            <Button className='mr-3' variant="primary" onClick={openRemarksModal}>Add/View Remarks</Button>
                                            <Button variant="secondary" onClick={backHandler}>Back</Button>
                                        </div>
                                        {/* <div className='col-md-2 text-right order-1 order-md-2 mb-4 mb-md-0'>
                                            <Button variant="primary" onClick={openSaveRequiredFieldModal}>Upload Document</Button>
                                        </div>
                                        <div className='col-md-2 text-right order-1 order-md-2 mb-4 mb-md-0'>
                                            <Button variant="secondary" onClick={openRemarksModal}>Add/View Remarks</Button>
                                        </div>
                                        <div className='col-md-1 text-right order-1 order-md-2 mb-4 mb-md-0'>
                                            <Button variant="primary" onClick={backHandler}>Back</Button>
                                        </div> */}
                                    </div>
                                    <div className="table-responsive mt-2">
                                        <table className="table">
                                            <thead className="thead-light">
                                                <tr>
                                                    <th scope="col">#</th>
                                                    <th scope="col">Color Code</th>
                                                    <th scope="col">Sample Type</th>
                                                    <th scope="col">Investigation</th>
                                                    <th scope="col">SIN No</th>
                                                    <th scope="col">Same Vial?</th>
                                                    <th scope="col">Status</th>
                                                    {/* <th scope="col">Action</th> */}
                                                </tr>
                                            </thead>
                                            <tbody id="tBdy">
                                                {tableData}
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className='row align-items-center mt-3'>
                                        {
                                            hideCollectButton ?
                                                <div className='col-12 text-right'>
                                                    <b>Total Test:</b> {itemCount}
                                                </div>
                                                :
                                                <div className='col-12 text-right'>
                                                    <b>Total Test:</b> {itemCount}
                                                    {isSaving ?
                                                        <LoadingButton variantName='primary ml-2' cssName="" /> :
                                                        <Button variant="primary ml-2" type='submit' onClick={handleSave}>Collect</Button>
                                                    }
                                                </div>
                                        }
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </div>
                <div className='d-lg-none'>
                    <div className='row align-items-center'>
                        <div className='col-md-7 order-2 order-md-1'>
                            <p><b>Name:</b>{patientName}, <b>Age:</b> {patientAge} Years, <b>Visit No:</b> {visitNo}</p>
                        </div>
                        <div className='col-md-5 order-1 order-md-2 text-right'>
                            <Button className='mb-2 mr-3' variant="primary" onClick={openSaveRequiredFieldModal}>Upload Document</Button>
                            <Button className='mb-2 mr-3' variant="primary" onClick={openRemarksModal}>Add/View Remarks</Button>
                            <Button className='mb-2' variant="secondary" onClick={backHandler}>Back</Button>
                        </div>
                        {/* <div className='col-md-2 text-right order-1 order-md-2 mb-4 mb-md-0'>
                            <Button variant="primary" onClick={openSaveRequiredFieldModal}>Upload Document</Button>
                        </div>
                        <div className='col-md-2 text-right order-1 order-md-2 mb-4 mb-md-0'>
                            <Button variant="primary" onClick={openRemarksModal}>Add/View Remarks</Button>
                        </div>
                        <div className='col-md-1 text-right order-1 order-md-2 mb-4 mb-md-0'>
                            <Button variant="secondary" onClick={backHandler}>Back</Button>
                        </div> */}
                    </div>
                    {
                        tableData.length > 0 ?
                            <>
                                <Table className='colorCodeTB' striped>
                                    <tbody>
                                        {mobiletableData}
                                    </tbody>
                                </Table>
                                <div className='row align-items-center mt-3'>
                                    {
                                        hideCollectButton ?
                                            <div className='col-12 text-right'>
                                                <b>Total Test:</b> {itemCount}
                                            </div>
                                            :
                                            <div className='col-12 text-right'>
                                                <b>Total Test:</b> {itemCount}
                                                {isSaving ?
                                                    <LoadingButton variantName='primary ml-2' cssName="" /> :
                                                    <Button variant="primary ml-2" type='submit' onClick={handleSave}>Collect</Button>
                                                }
                                            </div>
                                    }
                                </div>
                            </>
                            :
                            <Row>
                                <Col md={12}>
                                    <Card className='routeCard'>
                                        <Card.Body>
                                            <h5 className="text-center mb-0">No data available</h5>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>}
                </div>
            </Container>

            {
                openmodal &&
                <Modal isOpen={openmodal} centered={true} size="sm">
                    <ModalHeader toggle={openBarCodeModal} close={closeBtn}> Bar Code Reader
                    </ModalHeader>
                    <ModalBody>
                        <Html5QrcodePlugin
                            fps={10}
                            qrbox={250}
                            disableFlip={false}
                            qrCodeSuccessCallback={onNewScanResult}
                        />
                        <div className="mt-4 text-center">
                            <Button variant='secondary' onClick={openBarCodeModal}>Cancel</Button>
                        </div>
                    </ModalBody>
                </Modal>
            }
            <Modal isOpen={remarksModal} toggle={openRemarksModal} centered={true} size="lg">
                <ModalHeader toggle={openRemarksModal}>Add/View Remarks</ModalHeader>
                <ModalBody className='modalvariation'>
                    {
                        viewremarks == [] || viewremarks == null || viewremarks == undefined || viewremarks.length == 0 ? null :
                            viewremarks.map((d) =>
                                <Row>
                                    <Col md={12}>
                                        <Card className='routeCard'>
                                            <Card.Header>
                                                <Row className='align-items-center'>
                                                    <Col xs={6}>
                                                        <h3 className='routeSN'>{d.IsLISBSLFlag}</h3>
                                                    </Col>
                                                </Row>
                                            </Card.Header>
                                            <Card.Body>
                                                <Row>
                                                    <Col xs={12}>
                                                        <p><b>Remarks:</b> {d.Remarks}</p>
                                                        <p><b>DateTime:</b> {d.RemarksDateTime}</p>
                                                    </Col>
                                                </Row>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                </Row>
                            )
                    }
                    <Row>
                        <Col md={12} className='mt-5'>
                            <Form.Group>
                                <Form.Label>Remarks <span className='text-red'>(Max length 500 characters allowed)</span></Form.Label>
                                <Form.Control as="textarea" rows={3} ref={remarks} maxLength={500} id="remarksnew" name="remarks" />
                            </Form.Group>
                        </Col>
                        <Col md={12} className='mt-2 text-center'>
                            {isbuttonLoading ? <LoadingButton variantName='primary' cssName="" /> : <Button variant='primary' className="mr-2" onClick={SaveRemarks}>Save</Button>}
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>
            {
                viewreqfieldsModal &&
                <Modal isOpen={viewreqfieldsModal} toggle={openRequiredFieldModal} centered={true} size="lg">
                    <ModalHeader toggle={openRequiredFieldModal}>View/Upload Documents</ModalHeader>
                    <ModalBody className='modalvariation'>
                        {
                            tabledocdata == [] || tabledocdata == null || tabledocdata.length == 0 ? null :
                                <Row>
                                    <Col md={12}>
                                        <Card>
                                            <Card.Header>
                                                <Row className='align-items-center'>
                                                    <Col xs={6}>
                                                        <h3 className='routeSN'>View Documents</h3>
                                                    </Col>
                                                </Row>
                                            </Card.Header>
                                            <Card.Body>
                                                <DataTables
                                                    keyField='RowId'
                                                    tableData={tabledocdata}
                                                    columns={docColumns}
                                                    noDataIndication={NoRecordFounds}
                                                    pagination={paginationFactory(docPaginationOptions)} />

                                            </Card.Body>
                                        </Card>
                                    </Col>
                                </Row>
                        }
                        <Row>
                            <Col md={12}>
                                <Card>
                                    <Card.Header>
                                        <Row className='align-items-center'>
                                            <Col xs={6}>
                                                <h3 className='routeSN'>Upload Documents</h3>
                                            </Col>
                                        </Row>
                                    </Card.Header>
                                    <Card.Body>
                                        <Form.Row>
                                            <Col md={4}>
                                                <Form.Group>
                                                    <Form.Label>Document Type <span className='text-red'>*</span></Form.Label>
                                                    <Form.Control as="select" ref={doctypee} onChange={(e) => { ChangeDocType(e) }}>
                                                        <option key="0" value="0">---Select---</option>
                                                        {documenttype}
                                                    </Form.Control>
                                                </Form.Group>
                                            </Col>
                                            {
                                                doctype == "0" ? null :
                                                    <Col md={4}>
                                                        <Form.Group>
                                                            <Form.Label>{doctypename} <span className='text-red'>*</span></Form.Label>
                                                            <div><input type="file" id='up_prescription' onChange={(e) => UploadPrescription(e)} /></div>
                                                        </Form.Group>
                                                    </Col>
                                            }
                                        </Form.Row>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12}>
                                {
                                    prescriptiondocument == "" || prescriptiondocument == null ? null :
                                        <Row>
                                            <Col md={12}>
                                                <Card>
                                                    <Card.Header>
                                                        <Row className='align-items-center'>
                                                            <Col xs={6}>
                                                                <h3 className='routeSN'>View Uploaded Documents</h3>
                                                            </Col>
                                                        </Row>
                                                    </Card.Header>
                                                    <Card.Body>
                                                        <DataTables
                                                            keyField='RowId'
                                                            tableData={tabledocdata1}
                                                            columns={docColumns1}
                                                            noDataIndication={NoRecordFounds}
                                                            pagination={paginationFactory(docPaginationOptions1)} />
                                                    </Card.Body>
                                                </Card>
                                            </Col>
                                        </Row>
                                }
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12} className='text-center'>
                                <Button variant='secondary' className="mr-2" onClick={openRequiredFieldModal}>Cancel</Button>
                                {
                                    prescriptiondocument == "" ? null :
                                        <>
                                            {
                                                isButtonAllLoading ?
                                                    <LoadingButton variantName='primary' cssName="" /> :
                                                    <Button variant='primary' type='submit' onClick={(e) => SaveRequiredFields()}>Save</Button>
                                            }
                                        </>
                                }
                            </Col>
                        </Row>
                    </ModalBody>
                </Modal>
            }
        </>
    )
}

export default SampleDetail