import React, { useEffect, useState } from 'react';
import { useLocation } from "react-router-dom";
import SweetAlert from 'sweetalert2';
import { FrontDesk_URL, CallBack_URL } from '../../constant';
import axios from 'axios';
import { Container, Row, Col, Card, Form, Button, Table } from 'react-bootstrap';
import Created from '../../assets/images/progress.svg';
import Paid from '../../assets/images/success.svg';
import Cancelled from '../../assets/images/cancelled.svg';
import Expired from '../../assets/images/expired.svg';

const Transaction = () => {
    const location = useLocation();
    const [paymentstatus, setPaymentStatus] = useState("created");
    useEffect(() => {
        const params = new URLSearchParams(location.search);
        var paymentid = params.get('razorpay_payment_id');
        var paymentlinkid = params.get('razorpay_payment_link_id');
        var paymentlinkreferenceid = params.get('razorpay_payment_link_reference_id');
        var paymentstatus = params.get('razorpay_payment_link_status');
        var paymentsignature = params.get('razorpay_signature');
        if (paymentid == null || paymentid == undefined || paymentid == "") { paymentid = "0" }
        if (paymentlinkid == null || paymentlinkid == undefined || paymentlinkid == "") { paymentlinkid = "0" }
        if (paymentlinkreferenceid == null || paymentlinkreferenceid == undefined || paymentlinkreferenceid == "") { paymentlinkreferenceid = "0" }
        if (paymentstatus == null || paymentstatus == undefined || paymentstatus == "") { paymentstatus = "0" }
        if (paymentsignature == null || paymentsignature == undefined || paymentsignature == "") { paymentsignature = "0" }
        UpdatePaymentLinkDetails(paymentid, paymentlinkid, paymentlinkreferenceid, paymentstatus, paymentsignature);
    }, [])

    const UpdatePaymentLinkDetails = async (paymentid, paymentlinkid, paymentlinkreferenceid, paymentstatus, paymentsignature) => {
        setPaymentStatus(paymentstatus);
        // paymentstatus = 0 means Created,1 means paid,2 means Cancelled,3 means Expired
        const requestOptions = {
            PaymentId: paymentid, PaymentLinkId: paymentlinkid, PaymentLinkReferenceId: paymentlinkreferenceid, PaymentStatus: paymentstatus, PaymentSignature: paymentsignature
        };
        let url = FrontDesk_URL + 'accounts/updatepaymentlinkdetails'
        await axios.post(url, requestOptions).then((response) => {
            const data = response.data;
            if (data.Success) {
                if (data.Data == "-1001") {
                    SweetAlert.fire("Currently don't have permission to access this feature. Please contact your admin.");
                }
                else {
                    if (paymentstatus.toLowerCase() == "paid") {
                        setPaymentStatus("paid");
                        // SweetAlert.fire({
                        //     title: 'Success',
                        //     text: "Payment Successful",
                        //     icon: 'success',
                        //     showCancelButton: false,
                        //     confirmButtonText: 'Ok',
                        //     reverseButtons: true
                        // }).then((result) => {
                        //     if (result.value) {
                        //         window.location.replace(CallBack_URL);
                        //     } else {
                        //         window.location.replace(CallBack_URL);
                        //     }
                        // })
                    }
                    else if (paymentstatus.toLowerCase() == "cancelled") {
                        setPaymentStatus("cancelled");
                        // SweetAlert.fire({
                        //     title: 'Cancelled',
                        //     text: "Payment Cancelled",
                        //     icon: 'error',
                        //     showCancelButton: false,
                        //     confirmButtonText: 'Ok',
                        //     reverseButtons: true
                        // }).then((result) => {
                        //     if (result.value) {
                        //         window.location.replace(CallBack_URL);
                        //     } else {
                        //         window.location.replace(CallBack_URL);
                        //     }
                        // })
                    }
                    else if (paymentstatus.toLowerCase() == "expired") {
                        setPaymentStatus("expired");
                        // SweetAlert.fire({
                        //     title: 'Expired',
                        //     text: "Payment time expired. Please try again",
                        //     icon: 'error',
                        //     showCancelButton: false,
                        //     confirmButtonText: 'Ok',
                        //     reverseButtons: true
                        // }).then((result) => {
                        //     if (result.value) {
                        //         window.location.replace(CallBack_URL);
                        //     } else {
                        //         window.location.replace(CallBack_URL);
                        //     }
                        // })
                    }
                    else if (paymentstatus.toLowerCase() == "created") {
                        setPaymentStatus("created");
                        // SweetAlert.fire({
                        //     title: 'Pending',
                        //     text: "Payment still pending, Please try again",
                        //     icon: 'warning',
                        //     showCancelButton: false,
                        //     confirmButtonText: 'Ok',
                        //     reverseButtons: true
                        // }).then((result) => {
                        //     if (result.value) {
                        //         window.location.replace(CallBack_URL);
                        //     } else {
                        //         window.location.replace(CallBack_URL);
                        //     }
                        // })
                    }
                }
                setTimeout(() => {
                    window.location.replace(CallBack_URL);
                }, 2000);
            }
            else {
                if (data.Data != null) {
                    SweetAlert.fire({
                        title: 'Check logs!',
                        text: data.Data,
                        icon: 'error',
                        showCancelButton: false,
                        confirmButtonText: 'Ok',
                        reverseButtons: true
                    }).then((result) => {
                        if (result.value) {
                            window.location.replace(CallBack_URL);
                        } else {
                            window.location.replace(CallBack_URL);
                        }
                    })
                }
                else {
                    SweetAlert.fire({
                        title: 'API Error!',
                        text: "Some error occured! Please try after sometime.",
                        icon: 'error',
                        showCancelButton: false,
                        confirmButtonText: 'Ok',
                        reverseButtons: true
                    }).then((result) => {
                        if (result.value) {
                            window.location.replace(CallBack_URL);
                        } else {
                            window.location.replace(CallBack_URL);
                        }
                    })
                }
                setTimeout(() => {
                    window.location.replace(CallBack_URL);
                }, 2000);
            }
        }).catch(function (error) { if (error.response) { ErrorFunction(error.response.status); } });
    }
    const ErrorFunction = async (ErrorCode) => {
        if (ErrorCode == "400")
            SweetAlert.fire({ title: "Error 400", text: "Bad request", icon: "error" })
        else if (ErrorCode == "401")
            SweetAlert.fire({ title: "Error 401", text: "Unauthorized", icon: "error" })
        else if (ErrorCode == "403")
            SweetAlert.fire({ title: "Error 403", text: "Forbidden", icon: "error" })
        else if (ErrorCode == "404")
            SweetAlert.fire({ title: "Error 404", text: "Page not found", icon: "error" })
        else if (ErrorCode == "500")
            SweetAlert.fire({ title: "Error 500", text: "Internal Server Error", icon: "error" })
        else if (ErrorCode == "503")
            SweetAlert.fire({ title: "Error 503", text: "Service Unavailable", icon: "error" })
    }
    return (
        <>
            {
                paymentstatus.toLowerCase() == "created" ?
                    <div className='paymentProgress'>
                        <Row>
                            <Col md={12}>
                                <Card>
                                    <Card.Body>
                                        <div className='mb-3'>
                                            <img src={Created} alt='...' style={{ width: '100px', height: '100px' }} />
                                        </div>
                                        <h2>Transaction in Progress</h2>
                                        <p className='mb-0'>Please don't refresh or close this window</p>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </div> :
                    paymentstatus.toLowerCase() == "paid" ?
                        <div className='paymentProgress'>
                            <Row>
                                <Col md={12}>
                                    <Card>
                                        <Card.Body>
                                            <div className='mb-3'>
                                                <img src={Paid} alt='...' style={{ width: '100px', height: '100px' }} />
                                            </div>
                                            <h2>Transaction Successful</h2>
                                            <p className='mb-0'>Please don't refresh or close this window.It will redirect automatically</p>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        </div> :
                        paymentstatus.toLowerCase() == "cancelled" ?
                            <div className='paymentProgress'>
                                <Row>
                                    <Col md={12}>
                                        <Card>
                                            <Card.Body>
                                                <div className='mb-3'>
                                                    <img src={Cancelled} alt='...' style={{ width: '100px', height: '100px' }} />
                                                </div>
                                                <h2>Transaction Cancelled</h2>
                                                <p className='mb-0'>Please don't refresh or close this window.It will redirect automatically</p>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                </Row>
                            </div> :
                            paymentstatus.toLowerCase() == "expired" ?
                                <div className='paymentProgress'>
                                    <Row>
                                        <Col md={12}>
                                            <Card>
                                                <Card.Body>
                                                    <div className='mb-3'>
                                                        <img src={Expired} alt='...' style={{ width: '100px', height: '100px' }} />
                                                    </div>
                                                    <h2>Transaction Expired</h2>
                                                    <p className='mb-0'>Please don't refresh or close this window.It will redirect automatically</p>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    </Row>
                                </div> :
                                null
            }

        </>



    )
}

export default Transaction