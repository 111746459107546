import React, { useState, useEffect, useRef } from 'react';
import { Container, Row, Col, Card, Form, Button } from 'react-bootstrap';
import Breadcrumb from '../../components/common/breadcrumb';
import { Spinner } from 'react-bootstrap';
import DataTables from '../commonComponent/DataTable/DataTables';
import NoRecordFounds from '../commonComponent/DataTable/NoRecordFounds';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { FontSize, FrontDesk_URL } from '../../constant';
import axios from 'axios';
import SweetAlert from 'sweetalert2';
import ToolTip from '../UI/ToolTips/ToolTip';
import InputGroup from 'react-bootstrap/InputGroup';
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import LoadingButton from '../commonComponent/LoadingButton';

var dayendrid = "0";
var dayendrun = "";
var dayendrue = "";
const SettleDayEnd = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [defaultDate, setDefaultDate] = useState("");
    const [table, setTable] = useState([]);
    const [adhoctable, setAdhocTable] = useState([]);
    const [crntPageNO, setCrntPageNO] = useState(0);
    const [crntPageNO1, setCrntPageNO1] = useState(0);
    const [isRejectReport, setIsRejectReport] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    let fromdate = useRef();
    let todate = useRef();
    let rejectreason = useRef();
    useEffect(() => {
        const today = new Date();
        const date = today.setDate(today.getDate());
        const defaultValue = new Date(date).toISOString().split('T')[0];
        setDefaultDate(defaultValue);
        GetSettleDeyEndDetails(defaultValue, defaultValue);
    }, []);
    const DisableTypingkey = (e) => {
        e.preventDefault();
        return false;
    }
    const PaginationOptions = {
        sizePerPage: 25, showTotal: false, firstPageText: 'First', prePageText: 'Back', nextPageText: 'Next', lastPageText: 'Last',
        onPageChange: (page, sizePerPage) => { setCrntPageNO(((page - 1) * sizePerPage)); }
    };
    const PaginationOptionsadhoc = {
        sizePerPage: 25, showTotal: false, firstPageText: 'First', prePageText: 'Back', nextPageText: 'Next', lastPageText: 'Last',
        onPageChange: (page, sizePerPage) => { setCrntPageNO1(((page - 1) * sizePerPage)); }
    };
    const idFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                <span>{crntPageNO + rowIndex + 1}</span>
            </div>
        </React.Fragment>
    }
    const idadhocFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                <span>{crntPageNO1 + rowIndex + 1}</span>
            </div>
        </React.Fragment>
    }
    const statusFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                {
                    rowData.ReconcileStatus == "1" || rowData.ReconcileStatus == "True" ?
                        <span>Approved by {rowData.ReconcileBy} on {rowData.ReconcileDateTime}</span> :
                        rowData.ReconcileStatus == "2" ?
                            <span>Rejected by {rowData.ReconcileBy} on {rowData.ReconcileDateTime}</span> :
                            rowData.ReconcileStatus == "0" || rowData.ReconcileStatus == "False" ?
                                <>
                                    <ToolTip title="Reconcile" placement="top" linkId={"Rejcon" + rowIndex} faName="fa fa-check-square-o" onClick={(e) => ConfirmsettleDayEndAmount(rowData.DayEndRequestId, "1", rowData.RequestedUserName, rowData.RequestedUserEmail)}></ToolTip>
                                    <span>&nbsp;&nbsp;</span>
                                    <ToolTip title="Reject" placement="top" linkId={"Reject" + rowIndex} faName="fa fa-close mr-2" onClick={(e) => ConfirmsettleDayEndAmount(rowData.DayEndRequestId, "2", rowData.RequestedUserName, rowData.RequestedUserEmail)}></ToolTip>
                                </> : null
                }
            </div>
        </React.Fragment>
    }
    const columns = [
        { dataField: '#', text: '#', editable: false, formatter: idFormatter, headerStyle: (colum, colIndex) => { return { width: '5%' }; } },
        { dataField: 'CashReceived', text: 'Cash Rcvd', editable: false, headerStyle: (colum, colIndex) => { return { fontSize: '12px',width:'5%' }; } },
        { dataField: 'POS', text: `POS Rcvd`, editable: false, headerStyle: (colum, colIndex) => { return { fontSize: '12px',width:'5%' }; } },
        { dataField: 'Paytm', text: 'Paytm Rcvd', editable: false, headerStyle: (colum, colIndex) => { return { fontSize: '12px' }; } },
        { dataField: 'Bharatpay', text: 'Bharatpay Rcvd', editable: false, headerStyle: (colum, colIndex) => { return { fontSize: '12px' }; } },
        { dataField: 'Phonepe', text: 'Axis Soundbox Rcvd', editable: false, headerStyle: (colum, colIndex) => { return { fontSize: '12px' }; } },
        { dataField: 'Creditcard', text: 'Creditcard Rcvd', editable: false, headerStyle: (colum, colIndex) => { return { fontSize: '12px' }; } },
        { dataField: 'Debitcard', text: 'Debitcard Rcvd', editable: false, headerStyle: (colum, colIndex) => { return { fontSize: '12px' }; } },
        { dataField: 'Gpay', text: 'Gpay Rcvd', editable: false, headerStyle: (colum, colIndex) => { return { fontSize: '12px',width:'5%' }; } },
        { dataField: 'Online', text: 'Online Rcvd', editable: false, headerStyle: (colum, colIndex) => { return { fontSize: '12px' }; } },
        { dataField: 'Razorpay', text: 'Razorpay Rcvd', editable: false, headerStyle: (colum, colIndex) => { return { fontSize: '12px' }; } },
        { dataField: 'TotalAmount', text: 'Total Amount', editable: false, headerStyle: (colum, colIndex) => { return { fontSize: '12px' }; } },
        { dataField: 'TransferrableLeftAmount', text: 'Cash Receivable Amount', editable: false, headerStyle: (colum, colIndex) => { return { fontSize: '12px' }; } },
        { dataField: 'RequestedUserName', text: 'Requested User Name', editable: false, headerStyle: (colum, colIndex) => { return { fontSize: '12px',width:'5%' }; } },
        { dataField: 'RequestedUserTime', text: 'Requested Time', editable: false, headerStyle: (colum, colIndex) => { return { fontSize: '12px',width:'5%' }; } },
        { dataField: 'RejectReason', text: 'Reject Reason', editable: false, headerStyle: (colum, colIndex) => { return { fontSize: '12px' }; } },
        { dataField: 'ReconcileStatus', text: 'Reconcile Status', editable: false, formatter: statusFormatter, headerStyle: (colum, colIndex) => { return { fontSize: '12px' }; } }
    ];
    const rowStyle2 = (row, rowIndex) => {
        const style = {};
        if (row.ReconcileStatus === "-1") { style.backgroundColor = "#ff5c62"; }
        return style;
    };
    const adhoccolumns = [
        { dataField: '#', text: '#', editable: false, formatter: idadhocFormatter, headerStyle: (colum, colIndex) => { return { width: '5%' }; } },
        { dataField: 'Name', text: 'Transferred authorizer', editable: false },
        { dataField: 'TrfApprAmount', text: 'Transferred amount', editable: false },
        { dataField: 'AddedBy', text: 'Transferred by', editable: false },
        { dataField: 'AddedOn', text: 'Transferred date', editable: false },
        { dataField: 'ReConsileBy', text: 'Reconciled by', editable: false },
        { dataField: 'ReConsileOn', text: 'Reconciled date', editable: false }
    ];
    const GetSettleDeyEndDetails = async (fromdate, todate) => {
        setIsLoading(true);
        try {
            let url = FrontDesk_URL + 'accounts/getsettledayeneddetails/' + localStorage.getItem('LoggedInUser') + '/' + fromdate.toString() + '/' + todate.toString()
            await axios.get(url).then((response) => {
                const data = response.data;
                setIsLoading(false);
                if (data.Success) {
                    if (data.Data.seDetails == null || data.Data.seDetails.length == 0) { setTable([]); }
                    else { setTable(data.Data.seDetails) }
                    if (data.Data.adhDetails == null || data.Data.adhDetails.length == 0) { setAdhocTable([]); }
                    else { setAdhocTable(data.Data.adhDetails) }
                }
                else { setTable([]); }
            }).catch(function (error) { setIsLoading(false); if (error.response) { ErrorFunction(error.response.status); } });
        }
        catch (error) { setIsLoading(false); SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    }
    const handleSearch = async () => {
        if (fromdate.current.value == "" || fromdate.current.value == undefined || fromdate.current.value == null) {
            SweetAlert.fire("From date is mandatory.");
            return false;
        }
        if (todate.current.value == "" || todate.current.value == undefined || todate.current.value == null) {
            SweetAlert.fire("To date is mandatory.");
            return false;
        }
        if (todate.current.value > defaultDate) {
            SweetAlert.fire("To date cannot be greater than today date.");
            return false;
        }
        if (todate.current.value < fromdate.current.value) {
            SweetAlert.fire("To date cannot be less than from date.");
            return false;
        }
        GetSettleDeyEndDetails(fromdate.current.value, todate.current.value);
    }
    const ConfirmsettleDayEndAmount = (DayEndRequestId, isReconcile, RequestedUserName, RequestedUserEmail) => {
        dayendrid = "0";
        dayendrun = "";
        dayendrue = "";
        if (isReconcile == "1") {
            SweetAlert.fire({
                title: "Are you sure, you want to reconcile?",
                text: "Once you reconcile, user day will end automatically for selected date by user",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Ok',
                cancelButtonText: 'Cancel',
                reverseButtons: true
            })
                .then((result) => {
                    if (result.value) { ReconcileDayEndAmount(DayEndRequestId, isReconcile, RequestedUserName, RequestedUserEmail); }
                });
        }
        else { dayendrid = DayEndRequestId; dayendrun = RequestedUserName; dayendrue = RequestedUserEmail; openRejectModal(); }
    }
    const ReconcileDayEndAmount = async (DayEndRequestId, isReconcile, RequestedUserName, RequestedUserEmail) => {
        if (DayEndRequestId == "0" && isReconcile == "2") {
            SweetAlert.fire("Some value is missing. Please contact support");
            return false;
        }
        const requestOptions = {
            DayEndRequestId: DayEndRequestId, CentreId: localStorage.getItem('CentreSNo'), LoginId: localStorage.getItem('LoggedInUser'), Status: isReconcile,
            RejectReason: isReconcile == "1" ? "" : (rejectreason.current.value == undefined || rejectreason.current.value == null || rejectreason.current.value == "" ? "" : rejectreason.current.value),
            RequestedUserName: RequestedUserName, RequestedUserEmail: RequestedUserEmail, ApproverName: localStorage.getItem('UserName')
        };
        let url = FrontDesk_URL + 'accounts/ReconcileDayEndAmount'
        await axios.post(url, requestOptions).then((response) => {
            const data = response.data;
            if (data.Success) {
                if (isReconcile === "1") { SweetAlert.fire({ text: "Reconciled successfully", icon: "success" }); }
                else { SweetAlert.fire({ text: "Rejected successfully", icon: "success" }); openRejectModal(); }
                GetSettleDeyEndDetails(fromdate.current.value, todate.current.value);
            }
            else {
                if (data.ErrorList[0].errorCode == -2000 || data.Data == -2000) {
                    SweetAlert.fire({ title: "API Runtime Exception", text: "Some error occured! Please try after sometime.", icon: "error" })
                }
                else if (data.ErrorList[0].errorCode == -2002) {
                    SweetAlert.fire({
                        title: 'Already reconciled',
                        text: "Already reconciled",
                        icon: 'warning',
                        showCancelButton: false,
                        confirmButtonText: 'Ok',
                        reverseButtons: true
                    }).then((result) => { if (result.value) { window.location.reload(false); } else { window.location.reload(false); } })
                }
                else { SweetAlert.fire({ title: "Application Exception", text: "Some error occured! Please try after sometime.", icon: "error" }) }
            }
        }).catch(function (error) { if (error.response) { ErrorFunction(error.response.status); } });
    }
    const CallRejectFunction = () => {
        SweetAlert.fire({
            title: "Are you sure, you want to reject?",
            text: "Once you reject, user day end will stop automatically for selected date by user",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Ok',
            cancelButtonText: 'Cancel',
            reverseButtons: true
        })
            .then((result) => {
                if (result.value) { ReconcileDayEndAmount(dayendrid, "2", dayendrun, dayendrue); }
            });
    }
    const openRejectModal = () => { setIsRejectReport(!isRejectReport); }
    const ErrorFunction = async (ErrorCode) => {
        if (ErrorCode == "400") { SweetAlert.fire({ title: "Error 400", text: "Bad request", icon: "error" }); }
        else if (ErrorCode == "401") { SweetAlert.fire({ title: "Error 401", text: "Unauthorized", icon: "error" }); }
        else if (ErrorCode == "403") { SweetAlert.fire({ title: "Error 403", text: "Forbidden", icon: "error" }); }
        else if (ErrorCode == "404") { SweetAlert.fire({ title: "Error 404", text: "Page not found", icon: "error" }); }
        else if (ErrorCode == "500") { SweetAlert.fire({ title: "Error 500", text: "Internal Server Error", icon: "error" }); }
        else if (ErrorCode == "503") { SweetAlert.fire({ title: "Error 503", text: "Service Unavailable", icon: "error" }); }
    }
    return (
        <>
            <Breadcrumb title="Settle Day End" parent="Accounts" />
            <Container fluid>
                <Row>
                    <Col md={12}>
                        <Card>
                            <Card.Body>
                                <Form>
                                    <Form.Row>
                                        <Col md={3}>
                                            <Form.Group>
                                                <InputGroup className="mb-5">
                                                    <Form.Label className='raFilter'>From Date : &nbsp;</Form.Label>
                                                    <Form.Control ref={fromdate} type="date" onKeyPress={(e) => DisableTypingkey(e)} defaultValue={defaultDate} />
                                                </InputGroup>
                                            </Form.Group>
                                        </Col>
                                        <Col md={3}>
                                            <Form.Group>
                                                <InputGroup className="mb-5">
                                                    <Form.Label className='raFilter'>To Date : &nbsp;</Form.Label>
                                                    <Form.Control ref={todate} type="date" onKeyPress={(e) => DisableTypingkey(e)} defaultValue={defaultDate} />
                                                </InputGroup>
                                            </Form.Group>
                                        </Col>
                                        <Col md={3}>
                                            <Button variant='primary' onClick={(e) => handleSearch(e)}>Search</Button>
                                        </Col>
                                    </Form.Row>
                                    {
                                        table == [] || table == null ? null :
                                            <Form.Row style={{ marginTop: 50 }} className='summaryTableClass'>
                                                <Col md={12}>
                                                    <h6>Reconciliation List</h6>
                                                    <div style={{ color: "#ff5c62", float: 'right' }}>Before day end adjustment&nbsp;<span style={{ border: "1px solid #ff5c62", padding: "0px 54px", background: "#ff5c62" }}></span></div>
                                                    {
                                                        isLoading ? <div className='text-center'> <Spinner animation="border" size='sm' /></div> :
                                                            <div className="table-responsive mt15">
                                                                <DataTables keyField='DayEndRequestId' tableData={table} columns={columns} noDataIndication={NoRecordFounds}
                                                                    pagination={paginationFactory(PaginationOptions)} rowStyle={rowStyle2} />
                                                            </div>
                                                    }
                                                </Col>
                                            </Form.Row>
                                    }
                                    {
                                        adhoctable == [] || adhoctable == null ? null :
                                            <Form.Row style={{ marginTop: 50 }}>
                                                <Col md={12}>
                                                    <h6>Succesfully transferred amount</h6>
                                                    {
                                                        isLoading ? <div className='text-center'> <Spinner animation="border" size='sm' /></div> :
                                                            <div className="table-responsive mt15">
                                                                <DataTables keyField='CashFlowId' tableData={adhoctable} columns={adhoccolumns} noDataIndication={NoRecordFounds}
                                                                    pagination={paginationFactory(PaginationOptionsadhoc)} />
                                                            </div>
                                                    }
                                                </Col>
                                            </Form.Row>
                                    }
                                </Form>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <Modal isOpen={isRejectReport} toggle={openRejectModal} centered={true}>
                <ModalHeader toggle={openRejectModal}>Reject reason</ModalHeader>
                <ModalBody>
                    <Form.Row className='justify-content-left mt-4'>
                        <Col md={12} lg={12}>
                            <Form.Group>
                                <Form.Label>Reject reason <span className='text-red'>* (250 characters allowed)</span></Form.Label>
                                <Form.Control as="textarea" rows={3} name="refundreason" maxLength={250} ref={rejectreason} />
                            </Form.Group>
                        </Col>
                        <Col md={12} className='text-center'>
                            {isSaving ?
                                <LoadingButton variantName='primary' cssName="mr-2" /> :
                                <>
                                    <Button variant='primary' type='submit' className="mr-2" onClick={CallRejectFunction}>Reject</Button>
                                </>
                            }
                        </Col>
                    </Form.Row>
                </ModalBody>
            </Modal>
        </>
    )
}

export default SettleDayEnd