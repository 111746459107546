import React, { useState, useEffect, useRef } from 'react';
import { Container, Row, Col, Card, Form, Button, Table } from 'react-bootstrap';
import Pusher from 'pusher-js';
import { FrontDesk_URL } from '../../constant';
import axios from 'axios';
import SweetAlert from 'sweetalert2';
import { Spinner } from 'react-bootstrap';
import DataTables from '../commonComponent/DataTable/DataTables';
import NoRecordFounds from '../commonComponent/DataTable/NoRecordFounds';

const UserDetails = ({ history }) => {
    const [genders, setGenders] = useState(0);
    const [statesList, setStatesList] = useState([]);
    const [districtsList, setDistrictsList] = useState([]);
    const [patientMobile, setPatientMobile] = useState("");
    const [title, setTitle] = useState("");
    const [patientName, setPatientName] = useState("");
    const [gender, setGender] = useState("");
    const [dob, setDOB] = useState("");
    const [year, setYear] = useState("");
    const [month, setMonth] = useState("");
    const [day, setDay] = useState("");
    const [age, setAge] = useState("");
    const [pinCode, setPincode] = useState("");
    const [state, setState] = useState("");
    const [district, setDistrict] = useState("");
    //const [houseNo, setHouseNo] = useState("");
    const [address, setAddress] = useState("");
    const [referringDoctor, setReferringDoctor] = useState("");
    const [otherDoctor, setOtherDoctor] = useState("");
    const [email, setEmail] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [table, setTable] = useState([]);
    const [totalamt, setTotalAmt] = useState(0);
    useEffect(() => {
        if (localStorage.UserName == null || localStorage.UserName == "") {
            history.push(`${process.env.PUBLIC_URL}/login`);
        }
        else {
            GetGenders();
            GetStates("1");
        }
    }, []);

    const GetGenders = async () => {
        try {
            let url = FrontDesk_URL + 'master/GetGenderType'
            await axios.get(url).then((response) => {
                const data = response.data;
                if (data.Success) { setGenders(data.Data.map((d) => (<option key={d.GenderId} value={d.GenderId}>{d.GenderName}</option>))); }
            }).catch(function (error) { });
        }
        catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    }
    const GetStates = async (id) => {
        try {
            let url = FrontDesk_URL + 'master/StateList'
            await axios.get(url).then((response) => {
                const data = response.data;
                if (data.Success) { setStatesList(data.Data.map((d) => (<option key={d.SNo} value={d.SNo}>{d.StateName}</option>))); }
            }).catch(function (error) { });
        }
        catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    }
    const GetDistricts = async (id) => {
        try {
            let url = FrontDesk_URL + 'master/getdistricts/' + id;
            await axios.get(url).then((response) => {
                const data = response.data;
                if (data.Success) { setDistrictsList(data.Data.map((d) => (<option key={d.Id} value={d.Id}>{d.Value}</option>))); }
            }).catch(function (error) { });
        }
        catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    }
    useEffect(() => {
        Pusher.logToConsole = true;
        const pusher = new Pusher("62fd34c1ed94876f2390", {
            cluster: "ap2",
        });
        const channel = pusher.subscribe("my-channel");
        channel.bind(
            "my-event",
            function (data) {
                var currentInfo = JSON.parse(data.message);
                console.log(currentInfo);
                if (currentInfo.CentreId === localStorage.CentreSNo && currentInfo.UserId === localStorage.LoggedInUser) {
                    if (currentInfo.IsClear === "NO") {
                        GetDistricts(currentInfo.StateId);
                        setPatientMobile(currentInfo.MobileNo);
                        setTitle(currentInfo.Title);
                        setPatientName(currentInfo.PatientName);
                        setGender(currentInfo.Gender);
                        setDOB(currentInfo.DOB);
                        setAge(currentInfo.Age);
                        setYear((currentInfo.Year == "" || currentInfo.Year == null || currentInfo.Year == undefined) ? "0" : currentInfo.Year);
                        setMonth((currentInfo.Month == "" || currentInfo.Month == null || currentInfo.Month == undefined) ? "0" : currentInfo.Month);
                        setDay((currentInfo.Day == "" || currentInfo.Day == null || currentInfo.Day == undefined) ? "0" : currentInfo.Day);
                        setPincode(currentInfo.PinCode);
                        setState(currentInfo.StateId);
                        setDistrict(currentInfo.DistrictId);
                        //setHouseNo(currentInfo.HouseNo);
                        setAddress(currentInfo.Address);
                        if (currentInfo.RefreingDr === "") { currentInfo.RefreingDr = "Self"; }
                        setReferringDoctor(currentInfo.RefreingDr);
                        setOtherDoctor(currentInfo.OtherDr);
                        setEmail(currentInfo.EmailID);
                        setTable(JSON.parse(currentInfo.ItemDetails));
                        setTotalAmt(currentInfo.TotalAmount);
                    }
                    else {
                        setPatientMobile("");
                        setTitle("Mr.");
                        setPatientName("");
                        setGender("1");
                        setDOB("");
                        setAge("");
                        setYear("");
                        setMonth("");
                        setDay("");
                        setPincode("");
                        setState("");
                        setDistrict("");
                        //setHouseNo("");
                        setAddress("");
                        setReferringDoctor("");
                        setOtherDoctor("");
                        setEmail("");
                        setTable([]);
                        setTotalAmt(0);
                    }
                }
            },
            channel.unbind()
        );
        return () => {
            pusher.unsubscribe("my-channel");
            pusher.disconnect();
        };
    }, []);
    // var pusher = new Pusher('62fd34c1ed94876f2390', { cluster: 'ap2' });
    // var channel = pusher.subscribe('my-channel');
    // channel.bind('my-event', function (data) {
    //     //alert(data);
    //     setIsLoading(true);
    //     var currentInfo = JSON.parse(data.message);
    //     console.log(currentInfo);
    //     if (currentInfo.CentreId === localStorage.CentreSNo && currentInfo.UserId === localStorage.LoggedInUser) {
    //         if (currentInfo.IsClear === "NO") {
    //             GetDistricts(currentInfo.StateId);
    //             setPatientMobile(currentInfo.MobileNo);
    //             setTitle(currentInfo.Title);
    //             setPatientName(currentInfo.PatientName);
    //             setGender(currentInfo.Gender);
    //             setDOB(currentInfo.DOB);
    //             setAge(currentInfo.Age);
    //             setPincode(currentInfo.PinCode);
    //             setState(currentInfo.StateId);
    //             setDistrict(currentInfo.DistrictId);
    //             setHouseNo(currentInfo.HouseNo);
    //             setAddress(currentInfo.Address);
    //             if (currentInfo.RefreingDr === "") { currentInfo.RefreingDr = "Self"; }
    //             setReferringDoctor(currentInfo.RefreingDr);
    //             setOtherDoctor(currentInfo.OtherDr);
    //             setEmail(currentInfo.EmailID);
    //             setTable(JSON.parse(currentInfo.ItemDetails));
    //             setTotalAmt(currentInfo.TotalAmount);
    //         }
    //         else {
    //             setPatientMobile("");
    //             setTitle("Mr.");
    //             setPatientName("");
    //             setGender("1");
    //             setDOB("");
    //             setAge("");
    //             setPincode("");
    //             setState("");
    //             setDistrict("");
    //             setHouseNo("");
    //             setAddress("");
    //             setReferringDoctor("");
    //             setOtherDoctor("");
    //             setEmail("");
    //             setTable([]);
    //             setTotalAmt(0);
    //         }
    //     }
    //     setIsLoading(false);
    // });
    const idFormatter = (cellData, rowData, rowIndex, extraData) => {
        //console.log(JSON.stringify(rowData));
        return <React.Fragment>
            <div>
                <span>{rowIndex + 1}</span>
            </div>
        </React.Fragment>
    }
    const columns = [
        { dataField: '#', text: '#', editable: false, formatter: idFormatter, headerStyle: (colum, colIndex) => { return { width: '5%' }; } },
        { dataField: 'Code', text: 'Code', editable: false },
        { dataField: 'Item', text: 'Item', editable: false },
        { dataField: 'Modality', text: 'Department', editable: false },
        { dataField: 'DeliveryDate', text: 'DeliveryDate', editable: false },
        { dataField: 'Precautions', text: 'Precautions', editable: false }
    ];

    return (
        <>
            <Container fluid>
                <Row>
                    <Col md={12}>
                        <Card>
                            <Card.Header className='pb-0'>
                                <div style={{ marginTop: '20px', textAlign: 'right' }}><h4>Welcome {localStorage.UserName}</h4></div>
                                <Card.Title>Demographic Details
                                </Card.Title>
                            </Card.Header>
                            <Card.Body style={{ paddingTop: '0px' }}>
                                <Form.Row>
                                    <Col md={3}>
                                        <Form.Group>
                                            <Form.Label>Patient Mobile</Form.Label>
                                            <Form.Control type="text" name="mobileNo" value={patientMobile} disabled={true} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={3}>
                                        <Form.Group>
                                            <Form.Label>Title</Form.Label>
                                            <Form.Control as="select" name="title" value={title} disabled={true} >
                                                <option value="Mr.">Mr.</option>
                                                <option value="Mrs.">Mrs.</option>
                                                <option value="Miss.">Miss.</option>
                                                <option value="Baby.">Baby.</option>
                                                <option value="Baba.">Baba.</option>
                                                <option value="Master.">Master.</option>
                                                <option value="Dr.">Dr.</option>
                                                <option value="B/O.">B/O.</option>
                                                <option value="Ms.">Ms.</option>
                                                <option value="C/O.">C/O.</option>
                                                <option value="Mx.">Mx.</option>
                                            </Form.Control>
                                        </Form.Group>
                                    </Col>
                                    <Col md={3}>
                                        <Form.Group>
                                            <Form.Label>Patient Name</Form.Label>
                                            <Form.Control type="text" name="pName" value={patientName} disabled={true} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={3}>
                                        <Form.Group>
                                            <Form.Label>Gender</Form.Label>
                                            <Form.Control as="select" name="gender" value={gender} disabled={true} >
                                                {genders}
                                            </Form.Control>
                                        </Form.Group>
                                    </Col>
                                    <Col md={3}>
                                        <Form.Group>
                                            <Form.Label>DOB (DD-MM-YYYY)</Form.Label>
                                            <Form.Control type="text" name="pDOB" value={dob} disabled={true} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={3}>
                                        <Form.Group>
                                            <Form.Label>Year</Form.Label>
                                            <Form.Control type="tel" name="year" value={year} disabled={true} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={3}>
                                        <Form.Group>
                                            <Form.Label>Month</Form.Label>
                                            <Form.Control type="tel" name="month" value={month} disabled={true} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={3}>
                                        <Form.Group>
                                            <Form.Label>Day</Form.Label>
                                            <Form.Control type="tel" name="day" value={day} disabled={true} />
                                        </Form.Group>
                                    </Col>
                                    {/* <Col md={3}>
                                        <Form.Group>
                                            <Form.Label>Age</Form.Label>
                                            <Form.Control type="text" name="pAge" value={age} disabled={true} />
                                        </Form.Group>
                                    </Col> */}
                                    <Col md={3}>
                                        <Form.Group>
                                            <Form.Label>Pin Code</Form.Label>
                                            <Form.Control type="text" name="pPincode" value={pinCode} disabled={true} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={3}>
                                        <Form.Group>
                                            <Form.Label>State</Form.Label>
                                            <Form.Control as="select" name="locStateId" id="locStateId" value={state} disabled={true}>
                                                <option value="">---Select---</option>
                                                {statesList}
                                            </Form.Control>
                                        </Form.Group>
                                    </Col>
                                    <Col md={3}>
                                        <Form.Group>
                                            <Form.Label>District</Form.Label>
                                            <Form.Control as="select" name="locDistrictId" value={district} disabled={true}>
                                                <option value="">---Select---</option>
                                                {districtsList}
                                            </Form.Control>
                                        </Form.Group>
                                    </Col>
                                    {/* <Col md={3}>
                                        <Form.Group>
                                            <Form.Label>House No.</Form.Label>
                                            <Form.Control type="text" name="pHouseNo" value={houseNo} disabled={true} />
                                        </Form.Group>
                                    </Col> */}
                                    <Col md={3}>
                                        <Form.Group>
                                            <Form.Label>Address</Form.Label>
                                            <Form.Control type="text" name="pAddress" value={address} disabled={true} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={3}>
                                        <Form.Group>
                                            <Form.Label>Referring Doctor</Form.Label>
                                            <Form.Control type="text" name="pReferringDoctor" value={referringDoctor} disabled={true} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={3}>
                                        <Form.Group>
                                            <Form.Label>Other Doctor</Form.Label>
                                            <Form.Control type="text" name="pOtherDoctor" value={otherDoctor} disabled={true} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={3}>
                                        <Form.Group>
                                            <Form.Label>Email Id</Form.Label>
                                            <Form.Control type="email" name="pEmail" value={email} disabled={true} />
                                        </Form.Group>

                                    </Col>
                                </Form.Row>
                                <div className='text-right'>
                                    <b>Total Amount:</b> <span className='text-danger mr-2'><i className='fa fa-inr'></i>{totalamt}</span>
                                </div>
                                <div className="table-responsive mt15">
                                    <DataTables
                                        keyField='ItemSNo'
                                        tableData={table}
                                        columns={columns}
                                        noDataIndication={NoRecordFounds}
                                    />
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default UserDetails