import React from "react";
import { Container, Row, Col, Card, Form, Button, Table } from 'react-bootstrap';

class ConsentFormforHIVTest extends React.Component {

    render() {

        const pdfProps = this.props;
        const patientName = pdfProps.PName;
        const age = pdfProps.Age;
        const gender = pdfProps.Gender;
        const refDoctor = pdfProps.RefDoctor;
        console.log(pdfProps);
        return (
            <>
                <div className="formWrapper">
                    <div className="header">
                        <div className="logo"> <img src="/static/media/logo-atulaya.fbfb36f9.png" alt="thumb" /> </div>
                        <div className="headinginnersec">
                            <h1> CONSENT FOR HIV TEST </h1>
                        </div>
                    </div>
                    <div className="main">
                        <div className="row mb-2">
                            <div className="col-sm-6"> Patient's Name: <span className="dotedclass"><b>{patientName}</b></span></div>
                            <div className="col-sm-6"> Specimen Collected by: <span className="dotedclass"></span></div>
                        </div>
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <div className="float-left w-25"> Age:  <span className="dotedclass"><b>{age}</b></span></div>
                                <div className="float-right w-75"> Gender:  <span className="dotedclass"><b>{gender}</b></span></div>
                            </div>
                            <div className="col-sm-6"> Name of Sub Centre / Labs:  <span className="dotedclass"></span></div>
                        </div>
                        <div className="row mb-2">
                            <div className="col-sm-6"> Referred by: Dr.   <span className="dotedclass"><b>{refDoctor}</b></span></div>
                            <div className="col-sm-6"> Date & Time of collection:  <span className="dotedclass"></span></div>
                        </div>
                        <div className="row mb-2">
                            <div className="col-sm-6"> Tel/Fax:   <span className="dotedclass"></span></div>
                            <div className="col-sm-6"> Pervious MR No. (if any):  <span className="dotedclass"></span></div>
                        </div>
                        <div className="row mt-3 mb-3">
                            <div className="col-sm-12"> <b>Consent :</b></div>
                            <div className="col-sm-12">I, the undersigned agree to get my blood tested for HIV Antibodies.The significance and relevant information of this test(Pre-Test Counselling) have ben provided to me.</div>
                        </div>
                        <div className="row mt-3 mb-3">
                            <div className="col-sm-12"> <b> Result: </b> </div>
                            <div className="col-sm-12"> I undrerstand that my result will be kept confidential and authorize the following person/agency to collect my report.</div>
                            <div className="col-sm-12"> Self <span className="checkboxclass"></span>&nbsp;&nbsp;&nbsp;&nbsp; Ref. Doctor <span className="checkboxclass"></span> &nbsp;&nbsp;&nbsp;&nbsp; Ref.Agency <span className="checkboxclass"></span> &nbsp;&nbsp;&nbsp;&nbsp; Relative <span className="checkboxclass"></span></div>
                            <div className="col-sm-12">Print my name on the report  &nbsp;&nbsp; Yes <span className="checkboxclass"></span> &nbsp;&nbsp; No <span className="checkboxclass"></span></div>
                        </div>
                        <div className="row mt-2 mb-2">
                            <div className="col-sm-12"> Post Test Counselling will be given by my attending Doctor.</div>
                        </div>
                        <div className="row mt-2 mb-2">
                            <div className="col-sm-12"> Signature of patient: &nbsp;&nbsp; <span className="dotedclass"></span> </div>
                        </div>
                        <div className="row mt-2 mb-2">
                            <div className="col-sm-12 text-center"><b>GENERAL INFORMATION ON HIV </b></div>
                            <div className="col-sm-12">HIV or Human Immunodeficiency Virus is the causative agent of AIDS (Acquired immune Deficiency Syndrome). There are two forms of HIV VIT115 HIV-1 & HIV-2.
                                However HIV-1 is the predominant agent of HIV infection worldwide. HIV disease is chfacterited by progressive immunodediciency associated with quiitative depletion of COS+ T cells.
                                Advanced HIV disease is referred to as the Acquired immunodeficiency Syndrom or AIDS. </div>
                        </div>
                        <div className="row mt-2 mb-2">
                            <div className="col-sm-12 "><b>Mode of transmission</b></div>
                            <div className="col-sm-12">• Sexual contact both homosexual and heterosexual </div>
                            <div className="col-sm-12">• Contaminated blood or blood products including contaminated injection and cosmetic equipment </div>
                            <div className="col-sm-12">• infected mothers to Infants Intrapartum, perinatally and breast milk  </div>
                        </div>
                        <div className="row mt-2 mb-2">
                            <div className="col-sm-12 "><b>Not Transmitted by:</b></div>
                            <div className="col-sm-12">• Kissing and hugging </div>
                            <div className="col-sm-12">• Holding hands</div>
                            <div className="col-sm-12">• Sharing drinking or eating untensils</div>
                            <div className="col-sm-12">• Uving in a house with an HIV positive person</div>
                            <div className="col-sm-12"> • Toilet seats</div>
                            <div className="col-sm-12"> • Mosquitoes or any other not human organism</div>
                        </div>
                        <div className="row mt-2 mb-2">
                            <div className="col-sm-12 "><b> Diagnosis:</b></div>
                            <div className="col-sm-12 "><b> 1. Demonstration of anti-bodies to HIV</b></div>
                            <div className="col-sm-12"><b>EISA </b>: HIV antibodies generally appear In the circulation 4-8 weeks after Infection standard
                                test is the ELISA test with sensitivity of over 99.5%. This Western Blot Most commonly used confirmatory test</div>
                            <div className="col-sm-12 "><b> 2. Direct detection of HIV RNA (antigen)</b></div>
                            <div className="col-sm-12"> Measurement of HIV RNA overt time has been of great value in delineating the realtionshlp between levels of virus and restes of diseases progresson, rates fo viral replication and the time to develop and antiviral 
                                drug resistance. Measurements should be made at approximately every 6 months and frequently Ina setting of changes of antiretrovIral therapy. A level of >20,000 copies/mi is an Indication
                                for anthetrovIral therapy. HIV PCR: Gold Standard for diagnosis of HIV infection in at-risk neonates. It is positive In 9896 of patients detecting down to 40 copies/ml of HIV RNA.
                                The only test that can diagnose the HIV infection In Window Period.</div>
                        </div>
                        <div className="row mt-2 mb-2">
                            <div className="col-sm-12 "><b>Window Period</b></div>
                            <div className="col-sm-12 ">This is the period from the time of initial infection to the production of HIV antibodies in the blood. It Is known as seroconversion and woes from 3-12 weeks.
                                The gap between infection and seroconversion is termed as the window period. During this time the Infectious virus Is present in the body although the rewIts form the antibody will be
                                negative. hence aretest 6 months later is recommended.</div>
                        </div>
                        <div className="row mt-2 mb-2">
                            <div className="col-sm-12 "><b>Monitoring of Therapy</b></div>
                            <div className="col-sm-12 ">CD4 & CD8 T cell percent and absolute counts Correlate with disease progression, a fail in CD4 and a rise in CD8 counts occur during the course of the disease. HIV DNA : Quriatitation of the virus Is another valuable method for monitoring disease progression and therapy. This test detects down to SO coples/mi or HIV RNA.</div>
                        </div>
                        <div className="row mt-2 mb-2">
                            <div className="col-sm-12 "><b>Incubation Period :</b></div>
                            <div className="col-sm-12 ">A person may carry the HIV virus for a span of 10 years before progression to full blown AIDS. During this period the person shows no signs and symptoms of the underlying disease
                                but is capable of transmitting infection.</div>
                        </div>
                    </div>
                </div >
            </>
        );
    }
}

export default ConsentFormforHIVTest;