import React, { useEffect, useState } from 'react';
import Breadcrumb from '../../components/common/breadcrumb';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Container, Row, Col, Card, Form, Button, Table } from 'react-bootstrap';
import { useFormik } from 'formik';
import SweetAlert from 'sweetalert2';
import { FrontDesk_URL } from '../../constant';
import axios from 'axios';
import { Spinner } from 'react-bootstrap';
import LoadingButton from '../commonComponent/LoadingButton';

const Settings = () => {
    const [modal, setModal] = useState();
    const toggleModal = () => { setModal(!modal) }
    const [errorModal, setErrorModal] = useState();
    const errorHandler = () => { setModal(true); setErrorModal(true); }
    const [isLoading, setIsLoading] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    useEffect(() => { GetSettings(); }, []);
    const GetSettings = async () => {
        try {
            setIsLoading(true);
            let url = FrontDesk_URL + 'master/getcentresettings/' + localStorage.getItem('CentreSNo');
            await axios.get(url).then((response) => {
                const data = response.data;
                if (data.Success) {
                    const settingInfo = data.Data;
                    let hideBookingAmount = false; if (settingInfo.HideBookingAmount === "True") { hideBookingAmount = true; } formik.values.hideBookingAmount = hideBookingAmount;
                    let showBalanceAmount = false; if (settingInfo.ShowBalanceAmount === "True") { showBalanceAmount = true; } formik.values.showBalanceAmount = showBalanceAmount;
                    let hideReceiptRate = false; if (settingInfo.HideReceiptRate === "True") { hideReceiptRate = true; } formik.values.hideReceiptRate = hideReceiptRate;
                    let batchRequired = false; if (settingInfo.BatchRequired === "True") { batchRequired = true; } formik.values.batchRequired = batchRequired;
                    let showMRPBill = false; if (settingInfo.ShowMRPBill === "True") { showMRPBill = true; } formik.values.showMRPBill = showMRPBill;
                    let printDepartmentSlip = false; if (settingInfo.PrintDepartmentSlip === "True") { printDepartmentSlip = true; } formik.values.printDepartmentSlip = printDepartmentSlip;
                    let showPROInRegistration = false; if (settingInfo.ShowPROInRegistration === "True") { showPROInRegistration = true; } formik.values.showPROInRegistration = showPROInRegistration;
                    let showCentreDetailOnReport = false; if (settingInfo.ShowCentreDetailOnReport === "True") { showCentreDetailOnReport = true; } formik.values.showCentreDetailOnReport = showCentreDetailOnReport;
                    let hideFooterOnWithoutLetterheadReport = false; if (settingInfo.HideFooterOnWithoutLetterheadReport === "True") { hideFooterOnWithoutLetterheadReport = true; } formik.values.hideFooterOnWithoutLetterheadReport = hideFooterOnWithoutLetterheadReport;
                    setIsLoading(false);
                }
            }).catch(function (error) { setIsLoading(false); });
        }
        catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); setIsLoading(false); }
    }
    const formik = useFormik({
        initialValues: {
            hideBookingAmount: false, showBalanceAmount: false, hideReceiptRate: false, batchRequired: false, showMRPBill: false, printDepartmentSlip: false,
            showPROInRegistration: false, showCentreDetailOnReport: false, hideFooterOnWithoutLetterheadReport: false,
        },
        onSubmit: values => {
            setModal(false);
            setErrorModal(false);
            UpdateSettings(values);
        },
    });
    const UpdateSettings = async (values) => {
        setIsSaving(true);
        var hideBookingAmount = "0"; if (values.hideBookingAmount === true) { hideBookingAmount = "1"; }
        var showBalanceAmount = "0"; if (values.showBalanceAmount === true) { showBalanceAmount = "1"; }
        var hideReceiptRate = "0"; if (values.hideReceiptRate === true) { hideReceiptRate = "1"; }
        var batchRequired = "0"; if (values.batchRequired === true) { batchRequired = "1"; }
        var showMRPBill = "0"; if (values.showMRPBill === true) { showMRPBill = "1"; }
        var printDepartmentSlip = "0"; if (values.printDepartmentSlip === true) { printDepartmentSlip = "1"; }
        var showPROInRegistration = "0"; if (values.showPROInRegistration === true) { showPROInRegistration = "1"; }
        var showCentreDetailOnReport = "0"; if (values.showCentreDetailOnReport === true) { showCentreDetailOnReport = "1"; }
        var hideFooterOnWithoutLetterheadReport = "0"; if (values.hideFooterOnWithoutLetterheadReport === true) { hideFooterOnWithoutLetterheadReport = "1"; }
        const requestOptions = {
            CentreId: localStorage.getItem('CentreSNo'),
            HideBookingAmount: hideBookingAmount,
            ShowBalanceAmount: showBalanceAmount,
            HideReceiptRate: hideReceiptRate,
            BatchRequired: batchRequired,
            ShowMRPBill: showMRPBill,
            PrintDepartmentSlip: printDepartmentSlip,
            ShowPROInRegistration: showPROInRegistration,
            ShowCentreDetailOnReport: showCentreDetailOnReport,
            HideFooterOnWithoutLetterheadReport: hideFooterOnWithoutLetterheadReport,
            UpdatedBy: localStorage.LoggedInUser
        };
        let url = FrontDesk_URL + 'master/setcentresettings'
        await axios.post(url, requestOptions).then((response) => {
            const data = response.data;
            if (data.Success) {
                SweetAlert.fire({ title: "Success", text: "Data saved successfully", icon: "success" });
                // formik.resetForm();
                // GetSettings();
                setIsSaving(false);
            }
            else { SweetAlert.fire({ title: "Application Exception", text: "Some error occured! Please try after sometime", icon: "error" }); setIsSaving(false); }
        }).catch(function (error) { setIsSaving(false); });
    }
    return (
        <React.Fragment>
            <Breadcrumb title="Centre Settings" parent="Settings" />
            <Container fluid>
                {
                    isLoading ?
                        <div className='text-center'> <Spinner animation="border" size='sm' /></div> :
                        <>
                            <Form onSubmit={formik.handleSubmit}>
                                <Row>
                                    <Col md={12}>
                                        <Card>
                                            <Card.Header className='pb-0'>
                                                <Card.Title>Change Settings</Card.Title>
                                            </Card.Header>
                                            <Card.Body>
                                                <Form.Row>
                                                    <Col md={12}>
                                                        <Form.Group className='animate-chk m-checkbox-inline'>
                                                            <Form.Label>
                                                                <input name="hideBookingAmount" className="checkbox_animated" type="checkbox" checked={formik.values.hideBookingAmount} onChange={formik.handleChange} value={formik.values.hideBookingAmount} />
                                                                Hide Amount In Booking
                                                            </Form.Label>
                                                            <Form.Label>
                                                                <input name="showBalanceAmount" className="checkbox_animated" type="checkbox" checked={formik.values.showBalanceAmount} onChange={formik.handleChange} value={formik.values.showBalanceAmount} />
                                                                Show Balance Amount
                                                            </Form.Label>
                                                            <Form.Label>
                                                                <input name="hideReceiptRate" className="checkbox_animated" type="checkbox" checked={formik.values.hideReceiptRate} onChange={formik.handleChange} value={formik.values.hideReceiptRate} />
                                                                Hide Receipt Rate
                                                            </Form.Label>
                                                            <Form.Label>
                                                                <input name="batchRequired" className="checkbox_animated" type="checkbox" checked={formik.values.batchRequired} onChange={formik.handleChange} value={formik.values.batchRequired} />
                                                                Batch Required
                                                            </Form.Label>

                                                            <Form.Label>
                                                                <input name="showMRPBill" className="checkbox_animated" type="checkbox" checked={formik.values.showMRPBill} onChange={formik.handleChange} value={formik.values.showMRPBill} />
                                                                Show MRP Bill
                                                            </Form.Label>
                                                            <Form.Label>
                                                                <input name="printDepartmentSlip" className="checkbox_animated" type="checkbox" checked={formik.values.printDepartmentSlip} onChange={formik.handleChange} value={formik.values.printDepartmentSlip} />
                                                                Print Department Slip
                                                            </Form.Label>
                                                            <Form.Label>
                                                                <input name="showPROInRegistration" className="checkbox_animated" type="checkbox" checked={formik.values.showPROInRegistration} onChange={formik.handleChange} value={formik.values.showPROInRegistration} />
                                                                Show PRO in Registration
                                                            </Form.Label>
                                                            <Form.Label>
                                                                <input name="showCentreDetailOnReport" className="checkbox_animated" type="checkbox" checked={formik.values.showCentreDetailOnReport} onChange={formik.handleChange} value={formik.values.showCentreDetailOnReport} />
                                                                Show Centre detail on Report
                                                            </Form.Label>
                                                            <Form.Label>
                                                                <input name="hideFooterOnWithoutLetterheadReport" className="checkbox_animated" type="checkbox" checked={formik.values.hideFooterOnWithoutLetterheadReport} onChange={formik.handleChange} value={formik.values.hideFooterOnWithoutLetterheadReport} />
                                                                Hide Footer on Without Letterhead Report
                                                            </Form.Label>
                                                        </Form.Group>
                                                    </Col>
                                                </Form.Row>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                </Row>
                                <Row className='mt15'>
                                    <Col md={12} className='text-center'>
                                        {isSaving ? <LoadingButton variantName='primary' cssName="" /> : <Button variant='primary' type='submit' onClick={errorHandler}>Update Settings</Button>}
                                    </Col>
                                </Row>
                            </Form>
                        </>
                }
                {/******** Modal Popups ******* */}
                {errorModal &&
                    <Modal isOpen={modal} toggle={toggleModal} centered={true}>
                        <ModalHeader toggle={toggleModal}>Errors</ModalHeader>
                        <ModalBody>
                            <p>{formik.touched.name && formik.errors.name ? (<div className='text-required'>{formik.errors.name}</div>) : null}</p>
                            <p>{formik.touched.email && formik.errors.email ? (<div className='text-required'>{formik.errors.email}</div>) : null}</p>
                            <p>{formik.touched.mobileNo && formik.errors.mobileNo ? (<div className='text-required'>{formik.errors.mobileNo}</div>) : null}</p>
                            <p>{formik.touched.password && formik.errors.password ? (<div className='text-required'>{formik.errors.password}</div>) : null}</p>
                            <p>{formik.touched.accessGroupId && formik.errors.accessGroupId ? (<div className='text-required'>{formik.errors.accessGroupId}</div>) : null}</p>
                        </ModalBody>
                        <ModalFooter>
                            <Button variant="secondary" onClick={toggleModal}>Cancel</Button>
                            <Button variant="primary" onClick={toggleModal}>Ok</Button>
                        </ModalFooter>
                    </Modal>
                }
            </Container>
        </React.Fragment >
    )
}

export default Settings;