import React, { useEffect, useState, useRef } from 'react';
import { Container, Row, Col, Card, Form, Button } from 'react-bootstrap';
import Breadcrumb from '../components/common/breadcrumb';
import SweetAlert from 'sweetalert2';
import axios from 'axios';
import { Color, FrontDesk_URL } from '../constant';

const Dashboard = () => {
    const [userName, setuserName] = useState(localStorage.UserName == undefined ? "" : localStorage.UserName);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const centreId = localStorage.CentreSNo == undefined ? "0" : localStorage.CentreSNo;
        if (centreId != "0") { GetSettings(centreId); }
    }, []);
    const GetSettings = async (cId) => {
        try {
            let url = FrontDesk_URL + 'master/getcentresettings/' + cId + '/' + localStorage.LoggedInUser;
            await axios.get(url).then((response) => {
                const data = response.data;
                if (data.Success) {
                    const settingInfo = data.Data;
                    let hideBookingAmount = false; if (settingInfo.HideBookingAmount === "True") { hideBookingAmount = true; }
                    let showBalanceAmount = false; if (settingInfo.ShowBalanceAmount === "True") { showBalanceAmount = true; }
                    let hideReceiptRate = false; if (settingInfo.HideReceiptRate === "True") { hideReceiptRate = true; }
                    let batchRequired = false; if (settingInfo.BatchRequired === "True") { batchRequired = true; }
                    let showMRPBill = false; if (settingInfo.ShowMRPBill === "True") { showMRPBill = true; }
                    let printDepartmentSlip = false; if (settingInfo.PrintDepartmentSlip === "True") { printDepartmentSlip = true; }
                    let showPROInRegistration = false; if (settingInfo.ShowPROInRegistration === "True") { showPROInRegistration = true; }
                    let showCentreDetailOnReport = false; if (settingInfo.ShowCentreDetailOnReport === "True") { showCentreDetailOnReport = true; }
                    let hideFooterOnWithoutLetterheadReport = false; if (settingInfo.HideFooterOnWithoutLetterheadReport === "True") { hideFooterOnWithoutLetterheadReport = true; }
                    localStorage.removeItem('CreditLimit');
                    localStorage.setItem('CentreTypeId', settingInfo.CentreTypeId);
                    localStorage.setItem('PartnerTypeId', settingInfo.PartnerTypeId);
                    localStorage.setItem('HideBookingAmount', hideBookingAmount);
                    localStorage.setItem('ShowBalanceAmount', showBalanceAmount);
                    localStorage.setItem('HideReceiptRate', hideReceiptRate);
                    localStorage.setItem('BatchRequired', batchRequired);
                    localStorage.setItem('ShowMRPBill', showMRPBill);
                    localStorage.setItem('PrintDepartmentSlip', printDepartmentSlip);
                    localStorage.setItem('ShowPROInRegistration', showPROInRegistration);
                    localStorage.setItem('HideFooterOnWithoutLetterheadReport', hideFooterOnWithoutLetterheadReport);
                    localStorage.setItem('UHIDAbbreviation', settingInfo.UHIDAbbreviation);
                    localStorage.setItem('SAPCode', settingInfo.SAPCode);
                    localStorage.setItem('PaymentModeId', settingInfo.PaymentModeId);
                    localStorage.setItem('OtherLabRefNo', settingInfo.OtherLabRefNo);
                    localStorage.setItem('ReportEmail', settingInfo.ReportEmail);
                    localStorage.setItem('InvoiceEmail', settingInfo.InvoiceEmail);
                    localStorage.setItem('CreditLimit', settingInfo.CreditLimit);
                    localStorage.setItem('ReportingLimit', settingInfo.ReportingLimit);
                    localStorage.setItem('BookingLimit', settingInfo.BookingLimit);
                    localStorage.setItem('ParentCentreName', settingInfo.ParentCentreName);
                    localStorage.setItem('MinBookingCash', settingInfo.MinBookingCash);
                    localStorage.setItem('CentreDiscountLimit', settingInfo.CentreDiscountLimit);
                    localStorage.setItem('DashboardMessage', (settingInfo.DashboardMessage == null || settingInfo.DashboardMessage == undefined || settingInfo.DashboardMessage == "") ? "" : settingInfo.DashboardMessage);
                }
            }).catch(function (error) { setIsLoading(false); });
        }
        catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); setIsLoading(false); }
    }
    const ErrorFunction = async (ErrorCode) => {
        if (ErrorCode == "400")
            SweetAlert.fire({ title: "Error 400", text: "Bad request", icon: "error" })
        else if (ErrorCode == "401")
            SweetAlert.fire({ title: "Error 401", text: "Unauthorized", icon: "error" })
        else if (ErrorCode == "403")
            SweetAlert.fire({ title: "Error 403", text: "Forbidden", icon: "error" })
        else if (ErrorCode == "404")
            SweetAlert.fire({ title: "Error 404", text: "Page not found", icon: "error" })
        else if (ErrorCode == "500")
            SweetAlert.fire({ title: "Error 500", text: "Internal Server Error", icon: "error" })
        else if (ErrorCode == "503")
            SweetAlert.fire({ title: "Error 503", text: "Service Unavailable", icon: "error" })
    }

    return (
        <React.Fragment>
            <Breadcrumb title="Dashboard" parent="FrontDesk" />
            <Container fluid>
                <Row>
                    <Col md={12}>
                        <Card>
                            {
                                localStorage.getItem('DashboardMessage') == "" ? null :
                                    <Card.Header className='pb-0'>
                                        <Card.Title className='text-center'> <span className='blink blink-new1'>{localStorage.getItem('DashboardMessage')}</span> </Card.Title>
                                    </Card.Header>
                            }
                            <Card.Body>
                                <Form.Row className="justify-content-center">
                                    <Col lg={12} md={6}>
                                        <div class="text-center mt175" style={{ height: "70vh" }}>
                                            {/* <h5 style={{ marginBottom: 40 }} className='blink'> Rates has been updated</h5> */}
                                            <h1> Welcome {userName} ({localStorage.getItem('CentreName')})
                                                {
                                                    FrontDesk_URL === "https://devfrontdeskapi.atulaya.com/api/" ? <b style={{ color: 'red' }}>(UAT Env)</b> : null
                                                }
                                            </h1>
                                        </div>
                                    </Col>
                                </Form.Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>

            </Container>
        </React.Fragment>
    )
}


export default Dashboard;