import React, { useEffect } from 'react'

const CustomerVerification = () => {
    useEffect(() => {
        window.open(`${process.env.PUBLIC_URL}/front-office/details`, '_blank');
    }, [])
    return (
        <></>
    )
}

export default CustomerVerification