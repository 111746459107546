import React from "react";
import { Container, Row, Col, Card, Form, Button, Table } from 'react-bootstrap';

class ConsentFormCytopathologyRequis extends React.Component {

    render() {

        const pdfProps = this.props;
        const patientName = pdfProps.PName;
        const age = pdfProps.Age;
        const gender = pdfProps.Gender;
        const refDoctor = pdfProps.RefDoctor;
        console.log(pdfProps);
        return (
            <>
                <div className="formWrapper">
                    <div className="header">
                        <div className="logo"> <img src="/static/media/logo-atulaya.fbfb36f9.png" alt="thumb" /> </div>
                        <div className="headinginnersec">
                            <h1> CYTOPATHOLOGY REQUISITION FORM </h1>
                        </div>
                    </div>
                    <div className="main">
                        <div className="row mb-2">
                            <div className="col-sm-6"> Patient's Name: <span className="dotedclass"><b>{patientName}</b></span></div>
                            <div className="col-sm-6"> Specimen Collected by: <span className="dotedclass"></span></div>
                        </div>
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <div className="float-left w-25"> Age:  <span className="dotedclass"><b>{age}</b></span></div>
                                <div className="float-right w-75"> Gender:  <span className="dotedclass"><b>{gender}</b></span></div>
                            </div>
                            <div className="col-sm-6"> Name of Sub Centre / Labs:  <span className="dotedclass"></span></div>
                        </div>
                        <div className="row mb-2">
                            <div className="col-sm-6"> Referred by: Dr.   <span className="dotedclass"><b>{refDoctor}</b></span></div>
                            <div className="col-sm-6"> Date & Time of collection:  <span className="dotedclass"></span></div>
                        </div>
                        <div className="row mb-2">
                            <div className="col-sm-6"> Tel/Fax:   <span className="dotedclass"></span></div>
                            <div className="col-sm-6"> Pervious MR No. (if any):  <span className="dotedclass"></span></div>
                        </div>
                        <div className="row mt-3 mb-3">
                            <div className="col-sm-12"> <b>Non Gynaecological Specimens -:</b>FNAC (Fine Needle Aspiration Cytology),Body,Fluids,Washing,Brushing,Sputum,Urine,etc.</div>
                        </div>
                        <div className="row mt-3 mb-3">
                            <div className="col-sm-12"> <b> Specimen: </b> </div>
                            <div className="col-sm-12"> <b> Fluids: </b> </div>
                            <div className="col-sm-12"> Ascitic/Peritoneal <span className="checkboxclass"></span> &nbsp;&nbsp; Pleural <span className="checkboxclass"></span> &nbsp;&nbsp; CSF <span className="checkboxclass"></span> &nbsp;&nbsp; Urine <span className="checkboxclass"></span></div>
                            <div className="col-sm-12"> Bronchial Washings <span className="checkboxclass"></span> &nbsp;&nbsp; Sputums <span className="checkboxclass"></span> &nbsp;&nbsp; others <span className="checkboxclass"></span></div>
                        </div>

                        <div className="row mt-2 mb-2">
                            <div className="col-sm-5"> Discharge Site &nbsp;&nbsp; <span className="dotedclass"></span> </div>
                        </div>
                        <div className="row mt-3 mb-3">
                            <div className="col-sm-6"> <b>FNAC</b> Site of Specimen&nbsp;&nbsp; <span className="dotedclass"></span> </div>
                        </div>
                        <div className="row mt-3 mb-3">
                            <div className="col-sm-12"> Clinical History  and radiological findings,etc&nbsp;&nbsp; <span className="dotedclass"></span></div>
                            <div className="col-sm-12"> <span className="dotedclassnew"></span> </div>
                            <div className="col-sm-12"> <span className="dotedclassnew"></span> </div>
                        </div>
                        <div className="row mt-3 mb-3">
                            <div className="col-sm-12"> <b>(Gynaecological Specimens - Conventional Pap Smear and Liquid Based Cytology) </b></div>
                            <div className="col-sm-12"><b>Specimen Submitted:</b></div>
                            <div className="col-sm-12"> Conventional Pap Smear <span className="checkboxclass"></span> &nbsp;&nbsp; Liquid Based Cytology(LBC) <span className="checkboxclass"></span> &nbsp;&nbsp; Clinical Feactures : Normal <span className="checkboxclass"></span></div>
                            <div className="col-sm-12">Post Menopausal <span className="checkboxclass"></span>Suspicious Lesions <span className="checkboxclass"></span> &nbsp;&nbsp; Other <span className="checkboxclass"></span> &nbsp;&nbsp; Discharge<span className="checkboxclass"></span></div>
                        </div>
                        <div className="row mt-3 mb-3">
                            <div className="col-sm-12">P/S & P/V Findings :</div>
                        </div>
                        <div className="row mt-3 mb-3">
                            <div className="col-sm-12">L.M.P :&nbsp;&nbsp;<span className="dotedclass"></span></div>
                        </div>
                        <div className="row mt-3 mb-3">
                            <div className="col-sm-12">Pervious Cytology:<span className="dotedclass"></span></div>
                        </div>
                        <div className="row mt-3 mb-3">
                            <div className="col-sm-12"><span className="dotedclass"></span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span className="dotedclass"></span></div>
                            <div className="col-sm-12">Name & Signature of Patient/Guardian&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Name & Signature of Requisitioner</div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default ConsentFormCytopathologyRequis;