import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import '../src/pages/layout.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';

import { firebase_app, auth0 } from './data/config';
import { configureFakeBackend, authHeader, handleResponse } from "./services/fack.backend";
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';

// ** Import custom components for redux **
import { Provider } from 'react-redux';
import store from './store';
import App from "./components/app";
import { Auth0Provider } from '@auth0/auth0-react'

// Import custom Components 
import Dashboard from './pages/Dashboard';
import ReceivePayment from './pages/workOrder/ReceivePayment';
import Registration from './pages/workOrder/Registration';
import SampleCollection from './pages/sampleCollection/SampleCollection';
import SampleDetail from './pages/sampleCollection/SampleDetail';
import AccessDenied from './pages/AccessDenied';
import Users from './pages/userManagement/Users';
import UserInfo from './pages/userManagement/UserInfo';
import Batches from './pages/batchManagement/Batches';
import BatchDetails from './pages/batchManagement/BatchDetails';
import BatchConfirmation from './pages/batchManagement/BatchConfirmation';
import UserDetails from './pages/frontOffice/UserDetails';
import Settings from './pages/settings/Settings';
import TestStatus from './pages/Lab_Report/TestStatus';
import AdvancePayment from './pages/account/AdvancePayment';
import PdfPrintReceipt from './pages/frontOffice/PdfPrintReceipt';
import PrintReceipt from './pages/frontOffice/PrintReceipt';
import RISOrders from './pages/RIS/RISOrders';
import PrintDepartmentSlip from './pages/frontOffice/PrintDepartmentSlip';
import PrintStickerdetails from './pages/frontOffice/PrintStickerdetails';
import RefundRequest from './pages/workOrder/RefundRequest';
import RefundApproval from './pages/workOrder/RefundApproval';
import ReleaseRefund from './pages/workOrder/ReleaseRefund';
import Appointments from './pages/onlineOrders/Appointments';
import ReportOverview from './pages/Lab_Report/ReportOverview';
import MyRoutes from './pages/Phlebo/MyRoutes';
import RouteInfo from './pages/Phlebo/RouteInfo';
import NewOrder from './pages/Phlebo/NewOrder';
import DebitAndCreditNote from './pages/reports/DebitAndCreditNote';
import RefundStatus from './pages/reports/RefundStatus';
import B2BInvoices from './pages/reports/B2BInvoices';
import DCR from './pages/reports/Dcr';
import BillsPending from './pages/reports/BillsPending';
import UnpaidBills from './pages/reports/UnpaidBills';
import UnapprovedDiscount from './pages/reports/UnapprovedDiscount';
import UnapprovedCredit from './pages/reports/UnapprovedCredit';
import DCRReports from './pages/reports/DCRReports';
import Transaction from './pages/account/Transaction';
import Help from './pages/help/details';
// pages 
import Login from './pages/login';
import Signup from './pages/signup';
import UnlockUser from './pages/unlockUser';
import ForgetPwd from './pages/forgetPwd';
import ResetPwd from './pages/resetPwd';
import ComingSoon from './pages/comingsoon';
import Maintenance from './pages/maintenance';
import Error400 from './pages/errors/error400';
import Error401 from './pages/errors/error401';
import Error403 from './pages/errors/error403';
import Error404 from './pages/errors/error404';
import Error500 from './pages/errors/error500';
import Error503 from './pages/errors/error503';
import Signin from './auth/signin';
import Callback from './auth/callback'
import SaleRegister from './pages/workOrder/SaleRegister';
import DayEndReport from './pages/account/DayEndReport';
import CashFlow from './pages/account/CashFlow';
import Ledgers from './pages/account/Ledgers';
import LedgersNew from './pages/account/LedgersNew';
import ServiceWiseCollections from './pages/reports/ServiceWiseCollections';
import UserwiseCollections from './pages/reports/UserwiseCollections';
import ModewiseCollections from './pages/reports/ModewiseCollections';
import POSFlow from './pages/account/POSFlow';
import SettleDayEnd from './pages/account/SettleDayEnd';
import Printb2bInvoice from './pages/frontOffice/Printb2bInvoice';
import CustomerVerification from './pages/workOrder/CustomerVerification';
import CorporateInvoice from './pages/workOrder/CorporateInvoice';
import PrintCyclotronInvoice from './pages/pdfPrints/PrintCyclotronInvoice';
import ManageBulkInvoice from './pages/workOrder/BulkInvoice';
import SaleTrendvsVisitTrend from './pages/reports/SaleTrendvsVisitTrend';
import BusinessData from './pages/reports/BusinessData';
import SweetAlert from 'sweetalert2';

configureFakeBackend();
const Root = () => {
    const UserAccessKey = localStorage.UserAccessKey == undefined ? "" : localStorage.UserAccessKey;
    //const location = useLocation();
    let params = (new URL(window.location)).searchParams;
    let AccessKeyCust = params.get("Loginkey");
    const Session = localStorage.getItem('Session');

    useEffect(() => {
        document.onclick = function (event) {
            // if (localStorage.getItem("UserAccessKey") == null || localStorage.getItem("UserAccessKey") == "" || localStorage.getItem("UserAccessKey") == undefined) { }
            // else {
            //     localStorage.clear();
            //     window.location.assign(`${process.env.PUBLIC_URL}/login`);
            // }
            const ddd = window.location.href;
            var n = ddd.lastIndexOf('/');
            var result = ddd.substring(n + 1);
            const today = new Date();
            const date = today.setDate(today.getDate());
            const defaultValue = new Date(date).toISOString().split('T')[0] // yyyy-mm-dd
            if (result != 'login' && (localStorage.getItem("LoginDate") == null || localStorage.getItem("LoginDate") == "" || localStorage.getItem("LoginDate") == undefined || localStorage.getItem("LoginDate") != defaultValue)) {
                SweetAlert.fire({
                    title: 'Session Expired!',
                    text: "Please login again",
                    icon: 'warning',
                    showCancelButton: false,
                    confirmButtonText: 'Ok',
                    reverseButtons: true
                }).then((result) => {
                    if (result.value) {
                        localStorage.clear();
                        window.location.assign(`${process.env.PUBLIC_URL}/login`);
                    }
                    else {
                        localStorage.clear();
                        window.location.assign(`${process.env.PUBLIC_URL}/login`);
                    }
                })
            }
        };
        window.addEventListener("load", function () {
            const cccc = window.location.href;
            var ncc = cccc.lastIndexOf('/');
            var result = cccc.substring(ncc + 1);
            const today = new Date();
            const date = today.setDate(today.getDate());
            const defaultValue = new Date(date).toISOString().split('T')[0] // yyyy-mm-dd
            if (result != 'login' && (localStorage.getItem("LoginDate") == null || localStorage.getItem("LoginDate") == "" || localStorage.getItem("LoginDate") == undefined || localStorage.getItem("LoginDate") != defaultValue)) {
                SweetAlert.fire({
                    title: 'Session Expired!',
                    text: "Please login again",
                    icon: 'warning',
                    showCancelButton: false,
                    confirmButtonText: 'Ok',
                    reverseButtons: true
                }).then((result) => {
                    if (result.value) {
                        localStorage.clear();
                        window.location.assign(`${process.env.PUBLIC_URL}/login`);
                    }
                    else {
                        localStorage.clear();
                        window.location.assign(`${process.env.PUBLIC_URL}/login`);
                    }
                })
            }
        });
        // const pageAccessedByReload = (
        //     (window.performance.navigation && window.performance.navigation.type === 1) ||
        //     window.performance
        //         .getEntriesByType('navigation')
        //         .map((nav) => nav.type)
        //         .includes('reload')
        // );
        // if (pageAccessedByReload === true) {
        //     const ccc = window.location.href;
        //     var nc = ccc.lastIndexOf('/');
        //     var result = ccc.substring(nc + 1);
        //     const today = new Date();
        //     const date = today.setDate(today.getDate());
        //     const defaultValue = new Date(date).toISOString().split('T')[0] // yyyy-mm-dd
        //     if (result != 'login' && (localStorage.getItem("LoginDate") == null || localStorage.getItem("LoginDate") == "" || localStorage.getItem("LoginDate") == undefined || localStorage.getItem("LoginDate") != defaultValue)) {
        //         SweetAlert.fire({
        //             title: 'Session Expired!',
        //             text: "Please login again",
        //             icon: 'warning',
        //             showCancelButton: false,
        //             confirmButtonText: 'Ok',
        //             reverseButtons: true
        //         }).then((result) => {
        //             if (result.value) {
        //                 localStorage.clear();
        //                 window.location.assign(`${process.env.PUBLIC_URL}/login`);
        //             }
        //             else {
        //                 localStorage.clear();
        //                 window.location.assign(`${process.env.PUBLIC_URL}/login`);
        //             }
        //         })
        //     }
        // }
        window.addEventListener('storage', () => {
            if (UserAccessKey != "" && UserAccessKey != window.localStorage.getItem("UserAccessKey")) {
                console.log('reload');
                window.location.assign(`/dashboard`);
            }
            console.log('ppp' + UserAccessKey);
            console.log('TEST---ssAfeter' + window.localStorage.getItem("UserAccessKey"));
        });
        // eslint-disable-next-line
    }, []);

    return (
        <div className="App">
            <Provider store={store}>
                <BrowserRouter basename={`/`}>
                    <Switch>
                        <Route path={`${process.env.PUBLIC_URL}/login`} component={Signin} />
                        <Route path={`${process.env.PUBLIC_URL}/pages/login`} component={Login} />
                        <Route path={`${process.env.PUBLIC_URL}/pages/signup`} component={Signup} />
                        <Route path={`${process.env.PUBLIC_URL}/pages/unlockUser`} component={UnlockUser} />
                        <Route path={`${process.env.PUBLIC_URL}/pages/forgetPwd`} component={ForgetPwd} />
                        <Route path={`${process.env.PUBLIC_URL}/pages/resetPwd`} component={ResetPwd} />
                        <Route path={`${process.env.PUBLIC_URL}/pages/comingsoon`} component={ComingSoon} />
                        <Route path={`${process.env.PUBLIC_URL}/pages/maintenance`} component={Maintenance} />
                        <Route path={`${process.env.PUBLIC_URL}/pages/errors/error400`} component={Error400} />
                        <Route path={`${process.env.PUBLIC_URL}/pages/errors/error401`} component={Error401} />
                        <Route path={`${process.env.PUBLIC_URL}/pages/errors/error403`} component={Error403} />
                        <Route path={`${process.env.PUBLIC_URL}/pages/errors/error404`} component={Error404} />
                        <Route path={`${process.env.PUBLIC_URL}/pages/errors/error500`} component={Error500} />
                        <Route path={`${process.env.PUBLIC_URL}/pages/errors/error503`} component={Error503} />
                        <Route path={`${process.env.PUBLIC_URL}/callback`} render={() => <Callback />} />
                        <Route path={`${process.env.PUBLIC_URL}/accessDenied`} caseSensitive={false} component={AccessDenied} />
                        <Route path={`${process.env.PUBLIC_URL}/front-office/details`} caseSensitive={false} component={UserDetails} />
                        <Route path={`${process.env.PUBLIC_URL}/front-office/receipt`} caseSensitive={false} component={PrintReceipt} />
                        <Route path={`${process.env.PUBLIC_URL}/front-office/pdfreceipt`} caseSensitive={false} component={PdfPrintReceipt} />
                        <Route path={`${process.env.PUBLIC_URL}/front-office/printdepartmentslip`} caseSensitive={false} component={PrintDepartmentSlip} />
                        <Route path={`${process.env.PUBLIC_URL}/front-office/printstickerdetails`} caseSensitive={false} component={PrintStickerdetails} />
                        <Route exact path={`${process.env.PUBLIC_URL}/front-office/printb2binvoice`} caseSensitive={false} component={Printb2bInvoice} />
                        <Route path={`${process.env.PUBLIC_URL}/pdfPrints/PrintCyclotronInvoice`} caseSensitive={false} component={PrintCyclotronInvoice} />
                        {Session == "Active" ?
                            <App>
                                <Route exact path={`${process.env.PUBLIC_URL}/`} render={() => {
                                    return (<Redirect to={`${process.env.PUBLIC_URL}/dashboard`} />)
                                }} />
                                <Route exact path={`${process.env.PUBLIC_URL}/registration`} caseSensitive={false} component={Registration} />
                                <Route exact path={`${process.env.PUBLIC_URL}/sample-collection/list`} caseSensitive={false} component={SampleCollection} />
                                <Route exact path={`${process.env.PUBLIC_URL}/sample-collection/details`} caseSensitive={false} component={SampleDetail} />
                                <Route exact path={`${process.env.PUBLIC_URL}/users/list`} caseSensitive={false} component={Users} />
                                <Route exact path={`${process.env.PUBLIC_URL}/users/details`} caseSensitive={false} component={UserInfo} />
                                <Route path={`${process.env.PUBLIC_URL}/dashboard`} caseSensitive={false} component={Dashboard} />
                                <Route path={`${process.env.PUBLIC_URL}/batch-management/list`} caseSensitive={false} component={Batches} />
                                <Route path={`${process.env.PUBLIC_URL}/batch-management/details`} caseSensitive={false} component={BatchDetails} />
                                <Route path={`${process.env.PUBLIC_URL}/batch-management/confirm`} caseSensitive={false} component={BatchConfirmation} />
                                <Route path={`${process.env.PUBLIC_URL}/lab-report/teststatus`} caseSensitive={false} component={TestStatus} />
                                <Route path={`${process.env.PUBLIC_URL}/account/advancepayment`} caseSensitive={false} component={AdvancePayment} />
                                <Route exact path={`${process.env.PUBLIC_URL}/saleregister`} caseSensitive={false} component={SaleRegister} />
                                <Route path={`${process.env.PUBLIC_URL}/settings`} caseSensitive={false} component={Settings} />
                                <Route path={`${process.env.PUBLIC_URL}/ris/orders`} caseSensitive={false} component={RISOrders} />
                                <Route path={`${process.env.PUBLIC_URL}/accounts/dayendreport`} caseSensitive={false} component={DayEndReport} />
                                <Route path={`${process.env.PUBLIC_URL}/accounts/cashflow`} caseSensitive={false} component={CashFlow} />
                                <Route path={`${process.env.PUBLIC_URL}/accounts/posflow`} caseSensitive={false} component={POSFlow} />
                                <Route path={`${process.env.PUBLIC_URL}/accounts/settledayend`} caseSensitive={false} component={SettleDayEnd} />
                                <Route path={`${process.env.PUBLIC_URL}/accounts/ledgers`} caseSensitive={false} component={Ledgers} />
                                <Route path={`${process.env.PUBLIC_URL}/accounts/ledgersnew`} caseSensitive={false} component={LedgersNew} />
                                <Route path={`${process.env.PUBLIC_URL}/reports/DebitAndCreditNote`} caseSensitive={false} component={DebitAndCreditNote} />
                                <Route path={`${process.env.PUBLIC_URL}/reports/RefundStatus`} caseSensitive={false} component={RefundStatus} />
                                <Route exact path={`${process.env.PUBLIC_URL}/refund-request`} caseSensitive={false} component={RefundRequest} />
                                <Route exact path={`${process.env.PUBLIC_URL}/refund-approval`} caseSensitive={false} component={RefundApproval} />
                                <Route exact path={`${process.env.PUBLIC_URL}/online/appointments`} caseSensitive={false} component={Appointments} />
                                <Route exact path={`${process.env.PUBLIC_URL}/lab-report/overview`} caseSensitive={false} component={ReportOverview} />
                                <Route exact path={`${process.env.PUBLIC_URL}/phlebo/myroutes`} caseSensitive={false} component={MyRoutes} />
                                <Route exact path={`${process.env.PUBLIC_URL}/phlebo/routeinfo`} caseSensitive={false} component={RouteInfo} />
                                <Route exact path={`${process.env.PUBLIC_URL}/phlebo/neworder`} caseSensitive={false} component={NewOrder} />
                                <Route exact path={`${process.env.PUBLIC_URL}/phlebo/accession`} caseSensitive={false} component={SampleCollection} />
                                <Route exact path={`${process.env.PUBLIC_URL}/phlebo/dayend`} caseSensitive={false} component={DayEndReport} />
                                <Route exact path={`${process.env.PUBLIC_URL}/reports/servicewisecollection`} caseSensitive={false} component={ServiceWiseCollections} />
                                <Route exact path={`${process.env.PUBLIC_URL}/reports/modewisecollection`} caseSensitive={false} component={ModewiseCollections} />
                                <Route exact path={`${process.env.PUBLIC_URL}/reports/userwiseCollection`} caseSensitive={false} component={UserwiseCollections} />
                                <Route exact path={`${process.env.PUBLIC_URL}/reports/b2binvoices`} caseSensitive={false} component={B2BInvoices} />
                                <Route exact path={`${process.env.PUBLIC_URL}/reports/businessdata`} caseSensitive={false} component={BusinessData} />
                                <Route exact path={`${process.env.PUBLIC_URL}/corporate-invice`} caseSensitive={false} component={CorporateInvoice} />
                                <Route exact path={`${process.env.PUBLIC_URL}/customerverification`} caseSensitive={false} component={CustomerVerification} />
                                <Route exact path={`${process.env.PUBLIC_URL}/Bulkinvoice`} caseSensitive={false} component={ManageBulkInvoice} />
                                <Route exact path={`${process.env.PUBLIC_URL}/reports/dcr`} caseSensitive={false} component={DCR} />
                                <Route exact path={`${process.env.PUBLIC_URL}/reports/billspendingordersgenerated`} caseSensitive={false} component={BillsPending} />
                                <Route exact path={`${process.env.PUBLIC_URL}/reports/unpaidbills`} caseSensitive={false} component={UnpaidBills} />
                                <Route exact path={`${process.env.PUBLIC_URL}/reports/unapproveddiscount`} caseSensitive={false} component={UnapprovedDiscount} />
                                <Route exact path={`${process.env.PUBLIC_URL}/reports/unapprovedcredit`} caseSensitive={false} component={UnapprovedCredit} />
                                <Route exact path={`${process.env.PUBLIC_URL}/reports/dcrreports`} caseSensitive={false} component={DCRReports} />
                                <Route exact path={`${process.env.PUBLIC_URL}/reports/saletrendVsvisittrend`} caseSensitive={false} component={SaleTrendvsVisitTrend} />
                                <Route path={`${process.env.PUBLIC_URL}/account/transaction`} caseSensitive={false} component={Transaction} />
                                <Route path={`${process.env.PUBLIC_URL}/help/details`} caseSensitive={false} component={Help} />
                                <Route exact path={`${process.env.PUBLIC_URL}/release-refund`} caseSensitive={false} component={ReleaseRefund} />
                            </App>
                            :
                            <Redirect exact to={`${process.env.PUBLIC_URL}/login`} caseSensitive={false} />
                        }
                    </Switch>
                </BrowserRouter>
            </Provider>
        </div>
    );
}

ReactDOM.render(<Root />, document.getElementById('root'));

serviceWorker.unregister();