import React, { useState, useEffect, useRef } from 'react';
import { Container, Row, Col, Card, Form, Button, Table } from 'react-bootstrap';
import SweetAlert from 'sweetalert2';
import axios from 'axios';
import { FrontDesk_URL } from '../../constant';

const PrintDepartmentSlip = () => {

    const [Name, setName] = useState("");
    const [VisitNo, setVisitno] = useState("");
    const [Age, setAge] = useState("");
    const [RegDate, setRegDate] = useState("");
    const [Gender, setGender] = useState("");
    const [MobileNo, setMobileNo] = useState("");
    const [UHID, setUHID] = useState("");
    const [Center, setCenter] = useState("");
    const [Address, setAddress] = useState("");
    const [ReferralDoc, setReferralDoc] = useState("");
    const [patientdepartmemtslip, setpatientdepartmemtslip] = useState([]);

    useEffect(() => { PrintDepartmentSlip(); }, [])

    const PrintDepartmentSlip = async () => {
        try {
            let url = FrontDesk_URL + 'master/getDepartmentSlip/' + localStorage.getItem('LoggedInUser') + '/' + localStorage.getItem('vId') + '/' + localStorage.getItem('CentreSNo')
            await axios.get(url).then((response) => {
                const data = response.data;
                if (data.Success) {
                    setName(data.Data.Name);
                    setVisitno(data.Data.Visitno);
                    setAge(data.Data.PatientDOB);
                    setRegDate(data.Data.VisitDate);
                    setGender(data.Data.PatientGender);
                    setMobileNo(data.Data.PatientMobile);
                    setUHID(data.Data.PatientUHID);
                    setCenter(data.Data.CentreName);
                    setAddress(data.Data.Address);
                    setReferralDoc(data.Data.ReferedBy);
                    setpatientdepartmemtslip(data.Data.objDepartmentslipList);
                }
                else {
                    setName("");
                    setVisitno("");
                    setAge("");
                    setRegDate("");
                    setGender("");
                    setMobileNo("");
                    setUHID("");
                    setCenter("");
                    setAddress("");
                    setReferralDoc("");
                }
            }).catch(function (error) {
                if (error.response) {
                    ErrorFunction(error.response.status);
                }
            });
        }
        catch (error) {
            SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" });
        }
    }

    const ErrorFunction = async (ErrorCode) => {
        if (ErrorCode == "400")
            SweetAlert.fire({ title: "Error 400", text: "Bad request", icon: "error" })
        else if (ErrorCode == "401")
            SweetAlert.fire({ title: "Error 401", text: "Unauthorized", icon: "error" })
        else if (ErrorCode == "403")
            SweetAlert.fire({ title: "Error 403", text: "Forbidden", icon: "error" })
        else if (ErrorCode == "404")
            SweetAlert.fire({ title: "Error 404", text: "Page not found", icon: "error" })
        else if (ErrorCode == "500")
            SweetAlert.fire({ title: "Error 500", text: "Internal Server Error", icon: "error" })
        else if (ErrorCode == "503")
            SweetAlert.fire({ title: "Error 503", text: "Service Unavailable", icon: "error" })
    }

    return (
        <>
            <Container fluid>
                <Row>
                    <Col md={12}>
                        <Card>
                            <Card.Body>
                                <div className="main departresp">
                                <div className="row mb-2">
                                    <div className='col-md-12 text-center'>
                                        <h1> Department Slip </h1>
                                    </div>
                                </div>
                                    <div className="row mb-2">
                                        <div className="col-sm-6"> Patient Name: <span className="dotedclass"><b>{Name}</b></span></div>
                                        <div className="col-sm-6"> Visit No: <span className="dotedclass"><b>{VisitNo}</b></span></div>
                                    </div>
                                    <div className="row mb-2">
                                        <div className="col-sm-6"> Age:  <span className="dotedclass"><b>{Age}</b></span></div>
                                        <div className="col-sm-6"> Reg Date:  <span className="dotedclass"><b>{RegDate}</b></span></div>
                                    </div>
                                    <div className="row mb-2">
                                        <div className="col-sm-6"> Gender:  <span className="dotedclass"><b>{Gender}</b></span></div>
                                        <div className="col-sm-6"> MobileNo:  <span className="dotedclass"><b>{MobileNo}</b></span></div>
                                    </div>
                                    <div className="row mb-2">
                                        <div className="col-sm-6"> UHID:   <span className="dotedclass"><b>{UHID}</b></span></div>
                                        <div className="col-sm-6"> Center:  <span className="dotedclass"><b>{Center}</b></span></div>
                                    </div>
                                    <div className="row mb-2">
                                        <div className="col-sm-6"> Address: <span className="dotedclass"><b>{Address}</b></span></div>
                                        <div className="col-sm-6"> Referral Doctor: <span className="dotedclass"><b>{ReferralDoc}</b></span></div>
                                    </div>
                                    <table class="table table-bordered">
                                        <thead>
                                            <tr>
                                                <th scope="col"> #</th>
                                                <th scope="col"> Department </th>
                                                <th scope="col"> Sample Type </th>
                                                <th scope="col"> Investigation </th>
                                                <th scope="col"> Status </th>
                                                <th scope="col"> Sign </th>
                                                <th scope="col"> Name </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                patientdepartmemtslip != [] ?
                                                    <>
                                                        {
                                                            patientdepartmemtslip.map((d, index) =>
                                                                <tr>
                                                                    <td>{index + 1}</td>
                                                                    <td>{d.Department}</td>
                                                                    <td>{d.SampleType}</td>
                                                                    <td>{d.ServiceName}</td>
                                                                    <td>{d.Status}</td>
                                                                    <td>{d.Sign}</td>
                                                                    <td>{d.Name}</td>
                                                                </tr>
                                                            )
                                                        }
                                                    </>
                                                    : null
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default PrintDepartmentSlip