import React, { useEffect, useState, useRef } from 'react';
import Breadcrumb from '../../components/common/breadcrumb';
import { FrontDesk_URL } from '../../constant';
import { Container, Row, Col, Card, Form, Button } from 'react-bootstrap';
import DynamicDataTable from '../commonComponent/DataTable/DynamicDataTable';
import NoRecordFounds from '../commonComponent/DataTable/NoRecordFounds';
import axios from 'axios';
import SweetAlert from 'sweetalert2';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { CSVLink } from "react-csv";
import * as XLSX from 'xlsx';
import InputGroup from 'react-bootstrap/InputGroup';

const DebitAndCreditNote = () => {

    let fromDate = useRef();
    let toDate = useRef();
    let refNotetype = useRef();
    let refLedgerNames = useRef();

    const [tableData, setTableData] = useState({
        PageNo: 1,
        PageSize: 100,
        TotalRecord: 0,
        Rows: []

    });
    const [crntPageNO, setCrntPageNO] = useState(0);
    const [downloadvisible, setDownloadVisible] = useState(false);
    const [CSVData, setCSVData] = useState([]);
    const [defaultDate, setDefaultDate] = useState("");
    const [NoteType, setNoteType] = useState([]);



    useEffect(() => {
        const today = new Date();
        const date = today.setDate(today.getDate());
        const defaultValue = new Date(date).toISOString().split('T')[0] // yyyy-mm-dd
        setDefaultDate(defaultValue);
        BindGrid(defaultValue, defaultValue, tableData.PageNo, tableData.PageSize);
        GetDebitCreditNote();
    }, []);
    const BindGrid = async (fromDateSelected, toDateSelected, PageNo, PageSize) => {
        const ObjServiceCollection = {
            UserId: localStorage.getItem('LoggedInUser'),
            FromDate: fromDateSelected,
            ToDate: toDateSelected,
            PageNo: `${PageNo}`,
            PageSize: `${PageSize}`,
            IsExcelD: `${0}`,
            IsNoteType: refNotetype.current.value,
            LedgerId:localStorage.getItem('LoggedInUser'),
        };
        try {
            let url = FrontDesk_URL + 'reports/GetCreditAndDebitNoteReport'
            await axios.post(url, ObjServiceCollection).then((response) => {
                const data = response.data;
                if (data.Success) {
                    if (data.Data != null) {
                        if (data.Data.RptCreditAndDebitNoteList != null && data.Data.RptCreditAndDebitNoteList.length > 0) {
                            setTableData({
                                ...tableData,
                                PageSize: Number(PageSize),
                                TotalRecord: Number(data.Data.TotalRecord),
                                Rows: data.Data.RptCreditAndDebitNoteList
                            });
                        }

                        //setTable(data.Data);
                    }
                    else { setTableData({ PageNo: 1, PageSize: 100, TotalRecord: 0, Rows: []  }); }
                }
                 else{
                    setTableData({ PageNo: 1, PageSize: 100, TotalRecord: 0, Rows: []  }); 
                 }
            }).catch(function (error) {   setTableData({ PageNo: 1, PageSize: 100, TotalRecord: 0, Rows: []  }); });
        }
        catch (error) {
            
            setTableData({ PageNo: 1, PageSize: 100, TotalRecord: 0, Rows: []  });
            
            SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    }

    const GetDebitCreditNote = async () => {
        try {
            let url = FrontDesk_URL + 'master/getNotetypeslist'
            await axios.get(url).then((response) => {
                const data = response.data;
                if (data.Success) { setNoteType(data.Data.map((d) => (<option key={d.NoteTypeId} value={d.NoteTypeId}>{d.NoteType}</option>))); }
            }).catch(function (error) { });

        }
        catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    }

    

    const GetReportData = async (fromDateSelected, toDateSelected, PageNo, PageSize) => {
        setDownloadVisible(false);
        const ObjServiceCollection = {
            UserId: localStorage.getItem('LoggedInUser'),
            FromDate: fromDateSelected,
            ToDate: toDateSelected,
            PageNo: `${PageNo}`,
            PageSize: `${PageSize}`,
            IsExcelD: `${1}`,
            IsNoteType: refNotetype.current.value,
            LedgerId: localStorage.getItem('LoggedInUser'),
        };
        try {
            let url = FrontDesk_URL + 'reports/GetCreditAndDebitNoteReport'
            await axios.post(url, ObjServiceCollection).then((response) => {
                const data = response.data;
                if (data.Success) {
                    if (data.Data != null && data.Data.RptCreditAndDebitNoteList.length > 0) {
                        setDownloadVisible(true);
                        setCSVData(data.Data.RptCreditAndDebitNoteList);
                        document.getElementById("csvdownload").click();
                    }
                    else { setCSVData([]); }
                }
            }).catch(function (error) { });
        }
        catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    }

    const paginationOptions = {
        totalSize: tableData.TotalRecord,
        sizePerPage: tableData.PageSize,
        showTotal: true,
        firstPageText: 'First',
        prePageText: 'Back',
        nextPageText: 'Next',
        lastPageText: 'Last',
        onPageChange: (page, sizePerPage) => { setCrntPageNO(((page - 1) * sizePerPage)); BindGrid(fromDate.current.value, toDate.current.value, page, sizePerPage); },
        onSizePerPageChange: (sizePerPage, page, event) => { BindGrid(fromDate.current.value, toDate.current.value, page, sizePerPage); document.getElementsByClassName('dropdown-menu show')[0].classList.remove('show'); },
    };
    const idFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                <span>{crntPageNO + rowIndex + 1}</span>
            </div>
        </React.Fragment>
    }
    const columns = [{ dataField: '#', text: '#', editable: false, formatter: idFormatter, headerStyle: (colum, colIndex) => { return { width: '5%' }; } },
    { dataField: 'DebitCreditNoteNo', text: 'Debit Credit NoteNo', editable: false },
    { dataField: 'CompanyName', text: 'Company Name', editable: false },
    { dataField: 'NoteType', text: 'Note Type', editable: false },
    { dataField: 'Amount', text: 'Amount', editable: false },
    { dataField: 'NoteTypeName', text: 'NoteType Name', editable: false },
    { dataField: 'NoteTime', text: 'NoteTime', editable: false },
    { dataField: 'NoteAddedBy', text: 'Note Added By', editable: false },
    { dataField: 'LedgerHolderName', text: 'Ledger Holder Name', editable: false },
    { dataField: 'Narration', text: 'Narration', editable: false }
    ];

    const handleSearch = async () => {

        BindGrid(fromDate.current.value, toDate.current.value, tableData.PageNo, tableData.PageSize);
    }
    const handleDownload = async () => {
        GetReportData(fromDate.current.value, toDate.current.value, tableData.PageNo, tableData.PageSize);
    }


    return (
        <React.Fragment>
            <Breadcrumb title="Debit And Credit Note" parent="Reports" />
            <Container fluid>
                <Row className='mt25'>
                    <Col md={12}>
                        <Card>
                            <Card.Body>
                                <Form.Row>
                                    <Col md={4}>
                                        <Form.Group>
                                            <Form.Label>From Date</Form.Label>
                                            <InputGroup className="mb-3 d-flex justify-content-start">
                                                <Form.Control type="date" id="fromDate" name="fromDate" ref={fromDate} defaultValue={defaultDate} />
                                            </InputGroup>
                                        </Form.Group>
                                    </Col>
                                    <Col md={4}>
                                        <Form.Group>
                                            <Form.Label>To Date</Form.Label>
                                            <InputGroup className="mb-3 d-flex justify-content-start">
                                                <Form.Control type="date" id="toDate" name="toDate" ref={toDate} defaultValue={defaultDate} />
                                            </InputGroup>
                                        </Form.Group>
                                    </Col>
                                    <Col md={4}>
                                        <Form.Group>
                                            <Form.Label>Note Type</Form.Label>
                                            <Form.Control as="select" name="Notetype" ref={refNotetype} >
                                                <option key="0" value="0">---Select All---</option>
                                                {NoteType}
                                            </Form.Control>
                                        </Form.Group>
                                    </Col>


                                    <Col md={12} className='text-center __center__buttonsec'>
                                        <Form.Group>
                                            <Button variant='secondary' onClick={handleSearch}>Search</Button>
                                            <Button className='ml-2' variant='primary' onClick={handleDownload}>Download CSV</Button>
                                        </Form.Group>
                                    </Col>

                                    <Col lg={12} md={12} className='TableDynamic'>
                                        {
                                            <DynamicDataTable
                                                keyField='Id'
                                                tableData={tableData.Rows}
                                                columns={columns}
                                                noDataIndication={NoRecordFounds}
                                                pagination={paginationOptions ? paginationFactory(paginationOptions) : null}
                                            />
                                        }
                                    </Col>
                                </Form.Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                {
                    downloadvisible &&
                    <CSVLink data={CSVData} filename="DebitAndCreditReport.csv" target="_blank" id="csvdownload"></CSVLink>
                }
            </Container>
        </React.Fragment>
    )
}

export default DebitAndCreditNote