import React, { useEffect, useState, useRef } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { useHistory } from 'react-router';
import { Container, Row, Col, Card, Form, Button, Table } from 'react-bootstrap';
import Breadcrumb from '../../components/common/breadcrumb';
import SweetAlert from 'sweetalert2';
import { FrontDesk_URL } from '../../constant';
import axios from 'axios';
import { Spinner } from 'react-bootstrap';
import LoadingButton from "../commonComponent/LoadingButton";
import InputGroup from 'react-bootstrap/InputGroup';
import DataTables from '../commonComponent/DataTable/DataTables';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { useFormik } from 'formik';
import * as Yup from 'yup';

var glbRecords = [];
var glbColors = [];
let totalCount = 0;
var arrglbl = [];
const BatchDetails = () => {
    const history = useHistory();
    const [labsList, setLabsList] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [isSaving, setIsSaving] = useState(false);
    const [colorTable, setColorTable] = useState([]);
    const [changeState, setChangeState] = useState(false);
    const [crntPageNO, setCrntPageNO] = useState(0);
    const [modal, setModal] = useState();
    const toggleModal = () => { setModal(!modal) }
    const [errorModal, setErrorModal] = useState();
    const errorHandler = () => { setModal(true); setErrorModal(true); }
    let textSearch = useRef();
    const [hasPendingBarcode, setHasPendingBarcode] = useState(false);
    const [localbatchid, setLocalBatchId] = useState(localStorage.getItem('bId') === null ? "0" : localStorage.getItem('bId'));
    const [batchnumber, setBatchNumber] = useState("0");
    const [lastbatchid, setLastBatchId] = useState("0");

    useEffect(() => {
        GetLabs(); GetVialColors(); GetBatchDetails();
        return () => {
            setBatchNumber("0");
        }
    }, []);
    useEffect(() => { if (changeState === true) { setTableData(glbRecords); } }, [changeState]);
    useEffect(() => { setChangeState(false); }, [tableData]);



    const backHandler = (event) => { event.preventDefault(); history.push('/batch-management/list'); }
    const GetLabs = async () => {
        try {
            let url = FrontDesk_URL + 'batchmanagement/getprocessinglabbycentreid/' + localStorage.LoggedInUser + "/" + localStorage.getItem('CentreSNo')
            await axios.get(url).then((response) => {
                const data = response.data;
                if (data.Success) { setLabsList(data.Data.map((d) => (<option key={d.Id} value={d.Id}>{d.Name}</option>))); }
            }).catch(function (error) { });
        }
        catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    }
    const GetVialColors = async () => {
        try {
            let url = FrontDesk_URL + 'batchmanagement/getsampletypecolors/' + localStorage.LoggedInUser + "/" + localStorage.getItem('CentreSNo')
            await axios.get(url).then((response) => {
                const data = response.data;
                if (data.Success) {
                    if (data.Data !== null) {
                        glbColors = data.Data;
                        setColorTable(glbColors.map((d) => (
                            <div className='colorBox' style={{ 'backgroundColor': `${d.Color}` }} >{d.Count}</div>
                        )));
                    }
                }
            }).catch(function (error) { });
        }
        catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    }
    const GetBatchDetails = async () => {
        setIsLoading(true);
        totalCount = 0;
        var bId = localStorage.getItem('bId');
        if (bId === null) { bId = "0"; }
        let url = FrontDesk_URL + 'batchmanagement/getbatcheditdetails/' + localStorage.LoggedInUser + "/" + localStorage.getItem('CentreSNo') + "/" + bId;
        await axios.get(url).then((response) => {
            const data = response.data;
            if (data.Success) {
                if (data.Data !== null) {
                    var batchInfo = data.Data;
                    if (bId !== "0") {
                        formik.values.batchId = batchInfo.BatchId;
                        setBatchNumber(batchInfo.BatchNo);
                        //formik.values.batchNo = batchInfo.BatchNo;
                        formik.values.destinationCentreId = batchInfo.DestinationCentreId;
                        totalCount = parseInt(batchInfo.VialsQty);
                        setLastBatchId("0");
                    }
                    else {
                        setBatchNumber("0");
                        setLastBatchId(batchInfo.BatchId);
                    }
                    var barCodesInfo = batchInfo.BatchBarcodes;
                    if (barCodesInfo.length > 0) {
                        setHasPendingBarcode(true);
                        for (var i in barCodesInfo) {
                            var barCode = barCodesInfo[i];
                            if (barCode.IsSelected === "1") {
                                for (var key in glbColors) {
                                    if (glbColors[key].Color === barCode.VialColor) {
                                        var currentCount = parseInt(barCode.VialQty);
                                        glbColors[key].Count = (parseInt(glbColors[key].Count) + currentCount).toString();
                                        break;
                                    }
                                }
                            }
                        }
                        setColorTable(glbColors.map((d) => (<div className='colorBox' style={{ 'backgroundColor': `${d.Color}` }} >{d.Count}</div>)));
                        glbRecords = barCodesInfo;
                        setTableData(barCodesInfo);
                    }
                }
                setIsLoading(false);
            }
            else { SweetAlert.fire({ title: "Application Exception", text: "Some error occured! Please try after sometime", icon: "error" }); setIsLoading(false); }
        }).catch(function (error) { if (error.response) { ErrorFunction(error.response.status); } setIsLoading(false); });
    }
    const idFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                <span>{crntPageNO + rowIndex + 1}</span>
            </div>
        </React.Fragment>
    }
    const actionFormatter = (cellData, rowData, rowIndex, extraData) => {
        var boolValue = true;
        if (rowData.IsSelected === "0") { boolValue = false; }
        return <React.Fragment>
            <div>
                <input className="checkbox_animated" type="checkbox" defaultChecked={boolValue} onClick={(e) => { handleEditCheckbox(e, rowData); }} />
            </div>
        </React.Fragment>
    }
    const handleEditCheckbox = (e, rawData) => {
        const checked = e.target.checked;
        if (checked) {
            rawData.IsSelected = "1";
            for (var key in glbColors) {
                if (glbColors[key].Color === rawData.VialColor) {
                    var currentCount = parseInt(rawData.VialQty);
                    glbColors[key].Count = (parseInt(glbColors[key].Count) + currentCount).toString();
                    totalCount += currentCount;
                    break;
                }
            }
        } else {
            rawData.IsSelected = "0";
            for (var key in glbColors) {
                if (glbColors[key].Color === rawData.VialColor) {
                    glbColors[key].Count = (parseInt(glbColors[key].Count) - parseInt(rawData.VialQty)).toString();
                    totalCount -= parseInt(rawData.VialQty);
                    break;
                }
            }
        }
        setColorTable(glbColors.map((d) => (<div className='colorBox' style={{ 'backgroundColor': `${d.Color}` }} >{d.Count}</div>)));
    };
    const columns = [
        { dataField: '#', text: '#', editable: false, formatter: idFormatter, headerStyle: (colum, colIndex) => { return { width: '5%' }; } },
        { dataField: 'Barcode', text: 'Barcode', editable: false },
        { dataField: 'VialQty', text: 'Vial Qty', editable: false },
        { dataField: 'PatientName', text: 'Patient Name', editable: false },
        { dataField: 'SampleTypeName', text: 'Sample Type', editable: false },
        { dataField: 'InvestigationName', text: 'Tests', editable: false },
        { dataField: 'Action', text: 'Action', editable: false, formatter: actionFormatter }
    ];
    const paginationOptions = { sizePerPage: 10, showTotal: false, firstPageText: 'First', prePageText: 'Back', nextPageText: 'Next', lastPageText: 'Last', onPageChange: (page, sizePerPage) => { setCrntPageNO(((page - 1) * sizePerPage)); } };
    const handleSearch = async () => {
        arrglbl = [];
        if (textSearch.current.value.toLowerCase() === "") {
            setTableData(glbRecords);
        }
        else {
            for (var key in glbRecords) {
                if (glbRecords[key].Barcode.toLowerCase() === textSearch.current.value.toLowerCase() && glbRecords[key].IsSelected === "0") {
                    for (var i in glbColors) {
                        if (glbColors[i].Color === glbRecords[key].VialColor) {
                            glbColors[i].Count = (parseInt(glbColors[i].Count) + parseInt(glbRecords[key].VialQty)).toString();
                            //totalCount += parseInt(glbRecords[key].VialQty);
                            break;
                        }
                    }
                    setColorTable(glbColors.map((d) => (<div className='colorBox' style={{ 'backgroundColor': `${d.Color}` }} >{d.Count}</div>)));
                    break;
                }
            }
            //setTableData(glbRecords.find(x => x.Barcode.toLowerCase() == textSearch.current.value.toLowerCase()).IsSelected = "1");
            arrglbl = glbRecords;
            setTableData(glbRecords.filter((x) => x.Barcode.toLowerCase() == textSearch.current.value.toLowerCase()));
            //setChangeState(true);
            //textSearch.current.value = "";
        }
    }
    const formik = useFormik({
        initialValues: { batchId: '0', batchNo: '', destinationCentreId: '' },
        validationSchema: Yup.object({
            // batchNo: Yup.string().required('Batch No is required'), 
            destinationCentreId: Yup.string().required('Transferred to is required')
        }),
        onSubmit: values => {
            setModal(false);
            setErrorModal(false);
            CreateBatch(values);
        },
    });
    const CreateBatch = async (values) => {
        var batchno = "";
        if (batchnumber === "0") {
            //const randomstring = Math.random();
            //const randomsplitstring = randomstring.toString().split(".")[1].slice(0, 4);
            // batchno = localStorage.CentreCode + randomsplitstring + parseFloat(parseFloat(lastbatchid)+ 1);
            batchno = localStorage.CentreCode + parseFloat(parseFloat(lastbatchid) + 1);
        }
        else { batchno = batchnumber; }
        var arrayBarcode = [];
        for (var key in tableData) { if (tableData[key].IsSelected === "1") { arrayBarcode.push({ Barcode: tableData[key].Barcode }); } }
        if (arrglbl == [] || arrglbl === null || arrglbl.length == 0) { }
        else { for (var key in arrglbl) { if (arrglbl[key].IsSelected === "1") { arrayBarcode.push({ Barcode: arrglbl[key].Barcode }); } } }
        if (arrayBarcode.length === 0) { SweetAlert.fire({ title: "Validation", text: "Please select atleast on barcode.", icon: "error" }); return; }
        const requestOptions = {
            BatchId: values.batchId,
            BatchNo: batchno,//values.batchNo,
            DestinationId: values.destinationCentreId,
            cId: localStorage.getItem('CentreSNo'),
            uId: localStorage.LoggedInUser,
            Barcodes: arrayBarcode,
            TotalVials: totalCount.toString()
        };
        console.log(requestOptions);
        setIsSaving(true);
        let url = FrontDesk_URL + 'batchmanagement/setbatches'
        await axios.post(url, requestOptions).then((response) => {
            const data = response.data;
            setIsSaving(false);
            if (data.Success) {
                arrglbl = [];
                SweetAlert.fire({ title: "Success", text: "Data saved successfully", icon: "success" });
                history.push({ pathname: `${process.env.PUBLIC_URL}/batch-management/list`, search: `` });
            }
            else { SweetAlert.fire({ title: "Application Exception", text: "Some error occured! Please try after sometime", icon: "error" }); setIsSaving(false); }
        }).catch(function (error) { setIsSaving(false); });
    }

    const ErrorFunction = async (ErrorCode) => {
        if (ErrorCode == "400")
            SweetAlert.fire({ title: "Error 400", text: "Bad request", icon: "error" })
        else if (ErrorCode == "401")
            SweetAlert.fire({ title: "Error 401", text: "Unauthorized", icon: "error" })
        else if (ErrorCode == "403")
            SweetAlert.fire({ title: "Error 403", text: "Forbidden", icon: "error" })
        else if (ErrorCode == "404")
            SweetAlert.fire({ title: "Error 404", text: "Page not found", icon: "error" })
        else if (ErrorCode == "500")
            SweetAlert.fire({ title: "Error 500", text: "Internal Server Error", icon: "error" })
        else if (ErrorCode == "503")
            SweetAlert.fire({ title: "Error 503", text: "Service Unavailable", icon: "error" })
    }

    return (
        <>
            <Breadcrumb title="Create/Update" parent="Batch Management" />
            <Container fluid>
                <Form onSubmit={formik.handleSubmit}>
                    <Row>
                        <Col md={12}>
                            <Card>
                                <Card.Header className='pb-0'>
                                    <Card.Title>Batch Detail</Card.Title>
                                </Card.Header>
                                <Card.Body>
                                    <Form.Row>
                                        <Col md={3}>
                                            <Form.Group>
                                                <Form.Label>Transferred To<span className='text-required'>*</span></Form.Label>
                                                <Form.Control as="select" name="destinationCentreId" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.destinationCentreId}>
                                                    <option value="">---Select---</option>
                                                    {labsList}
                                                </Form.Control>
                                                {formik.touched.destinationCentreId && formik.errors.destinationCentreId ? (<div className='text-required'>{formik.errors.destinationCentreId}</div>) : null}
                                            </Form.Group>
                                        </Col>
                                        <Col md={12}>
                                            <Form.Group>
                                                <Form.Label>Total Vials {totalCount}:</Form.Label>
                                                {colorTable}
                                            </Form.Group>
                                        </Col>
                                        {
                                            localbatchid !== "0" ?
                                                <>
                                                    <Col md={3}>
                                                        <Form.Group>
                                                            <Form.Label><b>Batch No :</b></Form.Label>
                                                            &nbsp;{batchnumber}
                                                        </Form.Group>
                                                    </Col>
                                                </> : null
                                        }
                                    </Form.Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <Card>
                                <Card.Header className='pb-0'>
                                    <Card.Title>Pending Barcodes</Card.Title>
                                </Card.Header>
                                <Card.Body>
                                    <Form.Row className="justify-content-center">
                                        <Col lg={4} md={6}>
                                            {
                                                hasPendingBarcode ? <Form.Group>
                                                    <InputGroup className="mb-3">
                                                        <Form.Control onChange={() => handleSearch()} placeholder="Filter by Barcode..." ref={textSearch} />
                                                        <InputGroup.Append>
                                                            <Button variant="secondary"><i className='fa fa-search'></i></Button>
                                                        </InputGroup.Append>
                                                    </InputGroup>
                                                </Form.Group>
                                                    : null
                                            }
                                        </Col>
                                    </Form.Row>
                                    {
                                        isLoading ?
                                            <div className='text-center'> <Spinner animation="border" size='sm' /></div> :
                                            <>
                                                <DataTables keyField='Barcode' tableData={tableData} columns={columns} pagination={paginationFactory(paginationOptions)} />
                                            </>
                                    }
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    {
                        hasPendingBarcode ?
                            <Row>
                                <Col md={12} className='text-center'>
                                    <Button variant='secondary' className="mr-2" onClick={backHandler} >Back</Button>
                                    {isSaving ? <LoadingButton variantName='primary' cssName="mr-2" /> : <Button variant='primary' type='submit' className="mr-2" onClick={errorHandler}>Create Batch</Button>}
                                </Col>
                            </Row>
                            : null
                    }
                </Form>
                {errorModal &&
                    <Modal isOpen={modal} toggle={toggleModal} centered={true}>
                        <ModalHeader toggle={toggleModal}>Errors</ModalHeader>
                        <ModalBody>
                            {/* <p>{formik.touched.batchNo && formik.errors.batchNo ? (<div className='text-required'>{formik.errors.batchNo}</div>) : null}</p> */}
                            <p>{formik.touched.destinationCentreId && formik.errors.destinationCentreId ? (<div className='text-required'>{formik.errors.destinationCentreId}</div>) : null}</p>
                        </ModalBody>
                        <ModalFooter>
                            <Button variant="secondary" onClick={toggleModal}>Cancel</Button>
                            <Button variant="primary" onClick={toggleModal}>Ok</Button>
                        </ModalFooter>
                    </Modal>
                }
            </Container>
        </>
    )
}

export default BatchDetails