import React, { useEffect, useState, useRef } from 'react';
import Breadcrumb from '../../components/common/breadcrumb';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Container, Row, Col, Card, Form, Button, Table } from 'react-bootstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useHistory } from "react-router-dom";
import SweetAlert from 'sweetalert2';
import { FrontDesk_URL } from '../../constant';
import axios from 'axios';
import { Spinner } from 'react-bootstrap';
import LoadingButton from '../commonComponent/LoadingButton';

var glblArr = [];
const UserInfo = (id) => {
    const history = useHistory();
    const [modal, setModal] = useState();
    const toggleModal = () => { setModal(!modal) }
    const [errorModal, setErrorModal] = useState();
    const errorHandler = () => { setModal(true); setErrorModal(true); }
    const [isLoading, setIsLoading] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [accessGroupList, setAccessGroupList] = useState();
    const [ViewAccessGroups, SetViewAccessGroups] = useState(null);

    useEffect(() => {
        GetAccesGroups();
        GetUserDetails();
    }, []);
    const GetAccesGroups = async () => {
        try {
            let url = FrontDesk_URL + 'users/getdefaultaccessgrouplist/2'
            await axios.get(url).then((response) => {
                const data = response.data;
                if (data.Success) { setAccessGroupList(data.Data.map((d) => (<option key={d.Id} value={d.Id}>{d.Value}</option>))); }
            }).catch(function (error) { });
        }
        catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    }
    const GetUserDetails = async () => {
        try {
            if (localStorage.getItem('uId') !== null) {
                setIsLoading(true);
                formik.values.userId = localStorage.getItem('uId');
                let url = FrontDesk_URL + 'users/getcentrepartneruserdetails/' + localStorage.getItem('CentreSNo') + '/' + localStorage.getItem('uId');
                await axios.get(url).then((response) => {
                    const data = response.data;
                    if (data.Success) {
                        const userInfo = data.Data;
                        formik.values.userId = userInfo.UserId;
                        formik.values.name = userInfo.Name;
                        formik.values.email = userInfo.Email;
                        formik.values.mobileNo = userInfo.MobileNo;
                        formik.values.password = userInfo.Password;
                        formik.values.accessGroupId = userInfo.AccessGroupId;
                        let userStatus = false; if (userInfo.IsActive === "True") { userStatus = true; } formik.values.isActive = userStatus;
                        let hideBookingAmount = false; if (userInfo.HideBookingAmount === "True") { hideBookingAmount = true; } formik.values.hideBookingAmount = hideBookingAmount;
                        let showBalanceAmount = false; if (userInfo.ShowBalanceAmount === "True") { showBalanceAmount = true; } formik.values.showBalanceAmount = showBalanceAmount;
                        let hideReceiptRate = false; if (userInfo.HideReceiptRate === "True") { hideReceiptRate = true; } formik.values.hideReceiptRate = hideReceiptRate;
                        let batchRequired = false; if (userInfo.BatchRequired === "True") { batchRequired = true; } formik.values.batchRequired = batchRequired;
                        let showMRPBill = false; if (userInfo.ShowMRPBill === "True") { showMRPBill = true; } formik.values.showMRPBill = showMRPBill;
                        let printDepartmentSlip = false; if (userInfo.PrintDepartmentSlip === "True") { printDepartmentSlip = true; } formik.values.printDepartmentSlip = printDepartmentSlip;
                        let showPROInRegistration = false; if (userInfo.ShowPROInRegistration === "True") { showPROInRegistration = true; } formik.values.showPROInRegistration = showPROInRegistration;
                        let showCentreDetailOnReport = false; if (userInfo.ShowCentreDetailOnReport === "True") { showCentreDetailOnReport = true; } formik.values.showCentreDetailOnReport = showCentreDetailOnReport;
                        let hideFooterOnWithoutLetterheadReport = false; if (userInfo.HideFooterOnWithoutLetterheadReport === "True") { hideFooterOnWithoutLetterheadReport = true; } formik.values.hideFooterOnWithoutLetterheadReport = hideFooterOnWithoutLetterheadReport;
                        setIsLoading(false);
                        BindAccessGroupUID(userInfo.AccessGroupId);
                    }
                }).catch(function (error) { setIsLoading(false); });
            }
        }
        catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); setIsLoading(false); }
    }
    const formik = useFormik({
        initialValues: {
            userId: '0', name: '', email: '', mobileNo: '', password: '', accessGroupId: '', isActive: false, hideBookingAmount: false,
            showBalanceAmount: false, hideReceiptRate: false, batchRequired: false, showMRPBill: false, printDepartmentSlip: false,
            showPROInRegistration: false, showCentreDetailOnReport: false, hideFooterOnWithoutLetterheadReport: false,
        },
        validationSchema: Yup.object({
            name: Yup.string().max(100, 'User name must be 100 characters or less').required('User name is required'),
            email: Yup.string().email('Invalid email address').required('Email is required'),
            mobileNo: Yup.string().max(10, 'Mobile No must be  in 10 digits').min(10, 'Mobile No must be  in 10 digits').required('Mobile No is required'),
            password: Yup.string().min(6, 'Password must be  in atleast 6 characters').required('Password is required'),
            accessGroupId: Yup.string().required('Access group is required'),
        }),
        onSubmit: values => {
            setModal(false);
            setErrorModal(false);
            AddUser(values);
        },
    });
    const AddUser = async (values) => {
        setIsSaving(true);
        var userStatus = "0"; if (values.isActive === true) { userStatus = "1"; }
        var hideBookingAmount = "0"; if (values.hideBookingAmount === true) { hideBookingAmount = "1"; }
        var showBalanceAmount = "0"; if (values.showBalanceAmount === true) { showBalanceAmount = "1"; }
        var hideReceiptRate = "0"; if (values.hideReceiptRate === true) { hideReceiptRate = "1"; }
        var batchRequired = "0"; if (values.batchRequired === true) { batchRequired = "1"; }
        var showMRPBill = "0"; if (values.showMRPBill === true) { showMRPBill = "1"; }
        var printDepartmentSlip = "0"; if (values.printDepartmentSlip === true) { printDepartmentSlip = "1"; }
        var showPROInRegistration = "0"; if (values.showPROInRegistration === true) { showPROInRegistration = "1"; }
        var showCentreDetailOnReport = "0"; if (values.showCentreDetailOnReport === true) { showCentreDetailOnReport = "1"; }
        var hideFooterOnWithoutLetterheadReport = "0"; if (values.hideFooterOnWithoutLetterheadReport === true) { hideFooterOnWithoutLetterheadReport = "1"; }
        const requestOptions = {
            CentreId: localStorage.getItem('CentreSNo'),
            UserId: values.userId,
            Name: values.name,
            Email: values.email,
            MobileNo: values.mobileNo,
            Password: values.password,
            AccessGroupId: values.accessGroupId,
            IsActive: userStatus,
            HideBookingAmount: hideBookingAmount,
            ShowBalanceAmount: showBalanceAmount,
            HideReceiptRate: hideReceiptRate,
            BatchRequired: batchRequired,
            ShowMRPBill: showMRPBill,
            PrintDepartmentSlip: printDepartmentSlip,
            ShowPROInRegistration: showPROInRegistration,
            ShowCentreDetailOnReport: showCentreDetailOnReport,
            HideFooterOnWithoutLetterheadReport: hideFooterOnWithoutLetterheadReport,
            AddedBy: localStorage.LoggedInUser,
        };
        console.log(requestOptions);
        let url = FrontDesk_URL + 'users/setpartneruser'
        await axios.post(url, requestOptions).then((response) => {
            const data = response.data;
            if (data.Success) {
                SweetAlert.fire({ title: "Success", text: "Data saved successfully", icon: "success" });
                glblArr = [];
                formik.resetForm();
                localStorage.removeItem('uId');
                history.push({ pathname: `${process.env.PUBLIC_URL}/users/list`, search: `` });
            }
            else { SweetAlert.fire({ title: "Application Exception", text: "Some error occured! Please try after sometime", icon: "error" }); setIsSaving(false); }
        }).catch(function (error) { setIsSaving(false); });
    }
    const cancelHandler = async () => {
        localStorage.removeItem('uId');
        history.push({ pathname: `${process.env.PUBLIC_URL}/users/list`, search: `` });
    }
    async function BindAccessGroupUID(PageAccessGroupId) {
        try {
            let url = FrontDesk_URL + 'users/getpageAccessGroupByAcesId';
            let options = {
                method: 'POST', url: url, headers: { 'Accept': 'application/json', 'Content-Type': 'application/json;charset=UTF-8' },
                data: { PageAccessGroupId: `${PageAccessGroupId}` }
            };
            let response = await axios(options);
            let responseOK = response && response.status == 200;
            if (responseOK) {
                let data = response.data;
                if (data.Success == true) {
                    let ds = data.Data;
                    if (ds != null) {
                        await SetViewAccessGroups(ds);
                    }
                }
                else { SetViewAccessGroups(null); let Errtext = <p><div className='text-required'> no record found </div></p>; }
            }
            else {
                setErrorModal(false);
                SetViewAccessGroups(null);
                SweetAlert.fire('There is some issue at server. We already know about it and working hard to get it fixed as soon as possible.'); setIsLoading(false);
            }
        } catch (error) {
            if (error.response) { SetViewAccessGroups(null); setErrorModal(false); ErrorFunction(error.response.status); }
        }
    }
    const ErrorFunction = async (ErrorCode) => {
        if (ErrorCode == "400") SweetAlert.fire({ title: "Error 400", text: "Bad request", icon: "error" })
        else if (ErrorCode == "401") SweetAlert.fire({ title: "Error 401", text: "Unauthorized", icon: "error" })
        else if (ErrorCode == "403") SweetAlert.fire({ title: "Error 403", text: "Forbidden", icon: "error" })
        else if (ErrorCode == "404") SweetAlert.fire({ title: "Error 404", text: "Page not found", icon: "error" })
        else if (ErrorCode == "500") SweetAlert.fire({ title: "Error 500", text: "Internal Server Error", icon: "error" })
        else if (ErrorCode == "503") SweetAlert.fire({ title: "Error 503", text: "Service Unavailable", icon: "error" })
    }
    return (
        <React.Fragment>
            <Breadcrumb title="User Master" parent="Users" />
            <Container fluid>
                {
                    isLoading ?
                        <div className='text-center'> <Spinner animation="border" size='sm' /></div> :
                        <>
                            <Form onSubmit={formik.handleSubmit}>
                                <Row>
                                    <Col md={12}>
                                        <Card>
                                            <Card.Header className='pb-0'>
                                                <Card.Title>User Details</Card.Title>
                                            </Card.Header>
                                            <Card.Body>
                                                <Form.Row>
                                                    <Col md={3}>
                                                        <Form.Group>
                                                            <Form.Label>Name<span className='text-required'>*</span></Form.Label>
                                                            <Form.Control type="text" name="name" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.name} />
                                                            {formik.touched.name && formik.errors.name ? (<div className='text-required'>{formik.errors.name}</div>) : null}
                                                        </Form.Group>
                                                    </Col>
                                                    <Col md={2}>
                                                        <Form.Group>
                                                            <Form.Label>Mobile<span className='text-required'>*</span></Form.Label>
                                                            <Form.Control type="text" name="mobileNo" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.mobileNo} />
                                                            {formik.touched.mobileNo && formik.errors.mobileNo ? (<div className='text-required'>{formik.errors.mobileNo}</div>) : null}
                                                        </Form.Group>
                                                    </Col>
                                                    <Col md={3}>
                                                        <Form.Group>
                                                            <Form.Label>Email<span className='text-required'>*</span></Form.Label>
                                                            <Form.Control type="email" name="email" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.email} />
                                                            {formik.touched.email && formik.errors.email ? (<div className='text-required'>{formik.errors.email}</div>) : null}
                                                        </Form.Group>
                                                    </Col>
                                                    <Col md={2}>
                                                        <Form.Group>
                                                            <Form.Label>Password<span className='text-required'>*</span></Form.Label>
                                                            <Form.Control type="text" name="password" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.password} />
                                                            {formik.touched.password && formik.errors.password ? (<div className='text-required'>{formik.errors.password}</div>) : null}
                                                        </Form.Group>
                                                    </Col>
                                                    <Col md={2}>
                                                        <Form.Group>
                                                            <Form.Label>Access Group<span className='text-required'>*</span></Form.Label>
                                                            <Form.Control as="select" name="accessGroupId" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.accessGroupId} onChangeCapture={(e) => BindAccessGroupUID(e.target.value)}>
                                                                <option value="">---Select---</option>
                                                                {accessGroupList}
                                                            </Form.Control>
                                                            {formik.touched.accessGroupId && formik.errors.accessGroupId ? (<div className='text-required'>{formik.errors.accessGroupId}</div>) : null}
                                                        </Form.Group>
                                                    </Col>
                                                </Form.Row>
                                                {
                                                    ViewAccessGroups !== null ?
                                                        <>
                                                            <Form.Row className="mt-4">
                                                                <Col md={12} className="mb-2">
                                                                    <h6>Access Group Details</h6>
                                                                </Col>
                                                                {ViewAccessGroups.map((item) => {
                                                                    return (
                                                                        <>
                                                                            <Col lg={3} md={3}>
                                                                                <span className="form-control-plaintext" >{item.GroupPageNames == '' ? 'N/A' : item.GroupPageNames}</span>
                                                                            </Col>
                                                                        </>
                                                                    )

                                                                })}
                                                            </Form.Row>
                                                        </> :
                                                        null
                                                }
                                                <Form.Row className="mt-4">
                                                    <Col md={12} className="mb-2">
                                                        <h6>Other Settings</h6>
                                                    </Col>
                                                    <Col md={12}>
                                                        <Form.Group className='animate-chk m-checkbox-inline'>
                                                            <Form.Label>
                                                                <input name="showMRPBill" className="checkbox_animated" type="checkbox" checked={formik.values.showMRPBill} onChange={formik.handleChange} value={formik.values.showMRPBill} />
                                                                Show MRP Bill
                                                            </Form.Label>
                                                            <Form.Label>
                                                                <input name="printDepartmentSlip" className="checkbox_animated" type="checkbox" checked={formik.values.printDepartmentSlip} onChange={formik.handleChange} value={formik.values.printDepartmentSlip} />
                                                                Print Department Slip
                                                            </Form.Label>
                                                            <Form.Label>
                                                                <input name="showPROInRegistration" className="checkbox_animated" type="checkbox" checked={formik.values.showPROInRegistration} onChange={formik.handleChange} value={formik.values.showPROInRegistration} />
                                                                Show PRO in Registration
                                                            </Form.Label>
                                                            <Form.Label>
                                                                <input name="showCentreDetailOnReport" className="checkbox_animated" type="checkbox" checked={formik.values.showCentreDetailOnReport} onChange={formik.handleChange} value={formik.values.showCentreDetailOnReport} />
                                                                Show Centre detail on Report
                                                            </Form.Label>
                                                            <Form.Label>
                                                                <input name="hideFooterOnWithoutLetterheadReport" className="checkbox_animated" type="checkbox" checked={formik.values.hideFooterOnWithoutLetterheadReport} onChange={formik.handleChange} value={formik.values.hideFooterOnWithoutLetterheadReport} />
                                                                Hide Footer on Without Letterhead Report
                                                            </Form.Label>
                                                        </Form.Group>
                                                    </Col>
                                                </Form.Row>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                </Row>
                                <Row className='mt15'>
                                    <Col md={12} className='text-center'>
                                        <Button variant='secondary' className="mr-2" onClick={cancelHandler}>Cancel</Button>
                                        {isSaving ? <LoadingButton variantName='primary' cssName="" /> : <Button variant='primary' type='submit' onClick={errorHandler}>Save</Button>}
                                    </Col>
                                </Row>
                            </Form>
                        </>
                }
                {/******** Modal Popups ******* */}
                {errorModal &&
                    <Modal isOpen={modal} toggle={toggleModal} centered={true}>
                        <ModalHeader toggle={toggleModal}>Errors</ModalHeader>
                        <ModalBody>
                            <p>{formik.touched.name && formik.errors.name ? (<div className='text-required'>{formik.errors.name}</div>) : null}</p>
                            <p>{formik.touched.email && formik.errors.email ? (<div className='text-required'>{formik.errors.email}</div>) : null}</p>
                            <p>{formik.touched.mobileNo && formik.errors.mobileNo ? (<div className='text-required'>{formik.errors.mobileNo}</div>) : null}</p>
                            <p>{formik.touched.password && formik.errors.password ? (<div className='text-required'>{formik.errors.password}</div>) : null}</p>
                            <p>{formik.touched.accessGroupId && formik.errors.accessGroupId ? (<div className='text-required'>{formik.errors.accessGroupId}</div>) : null}</p>
                        </ModalBody>
                        <ModalFooter>
                            <Button variant="secondary" onClick={toggleModal}>Cancel</Button>
                            <Button variant="primary" onClick={toggleModal}>Ok</Button>
                        </ModalFooter>
                    </Modal>
                }
            </Container>
        </React.Fragment >
    )
}

export default UserInfo;