import React, { useState, Fragment, useEffect, useCallback, useReducer, useRef } from 'react';
import { MENUITEMS } from '../../../components/common/sidebar-component/menu';
import { Link } from 'react-router-dom';
import { Search } from 'react-feather';
import { select } from '@redux-saga/core/effects';

var items = [];
const SearchHeader = () => {
    // const mainmenu = MENUITEMS;
    const [searchValue, setsearchValue] = useState('');
    const [searchOpen, setsearchOpen] = useState(false);
    const [viewicon, setViewIcon] = useState(false);
    const mainmenu = JSON.parse(localStorage.getItem('CentreInfo') !== null ? localStorage.getItem('CentreInfo') : null);
    const escFunction = useCallback((event) => {
        if (event.keyCode === 27) {
            //Do whatever when esc is pressed
            setsearchOpen(false)
            setsearchValue('')
        }
    }, []);
    let searchinner = useRef();
    useEffect(() => {
        document.addEventListener("keydown", escFunction, false);
        return () => { document.removeEventListener("keydown", escFunction, false); };
    });

    const handleSearchKeyword = (keyword) => {
        setViewIcon(true);
        items = [];
        if (keyword === "") {
            addFix();
            setsearchValue(mainmenu);
            searchinner.current.value = "";
            document.getElementById("errormsg").style.display = "none";
        }
        else {
            keyword ? addFix() : removeFix()
            setsearchValue(keyword)
            // eslint-disable-next-line
            mainmenu.filter(menuItems => {
                if (menuItems.CentreName.toLowerCase().includes(keyword.toLowerCase())) { items.push(menuItems); }
                checkSearchResultEmpty(items)
                setsearchValue(items);
            });
            if (items.length === 0) { document.getElementById("errormsg").style.display = "block"; }
            else { document.getElementById("errormsg").style.display = "none"; }
        }
    }

    const checkSearchResultEmpty = (items) => {
        if (!items.length) { document.querySelector(".empty-menu").classList.add('is-open'); }
        else { document.querySelector(".empty-menu").classList.remove('is-open'); }
    }
    const addFix = () => {
        document.querySelector(".Typeahead-menu").classList.add('is-open');
        document.body.classList.add("offcanvas");
    }
    const removeFix = (cNme, cId, cCode) => {
        setsearchValue('')
        document.querySelector(".Typeahead-menu").classList.remove('is-open');
        document.body.classList.remove("offcanvas");
        if (cId != undefined) {
            localStorage.setItem('CentreSNo', cId);
            localStorage.setItem('CentreName', cNme);
            const ds = JSON.parse(localStorage.UserSourceMandatory);
            for (var key in ds) {
                if (ds[key].CentreId === cId) { localStorage.setItem('IsSourceMandatory', ds[key].IsSourceMandatory) }
            }
            localStorage.setItem('CentreCode', cCode);
            window.location.assign(`${process.env.PUBLIC_URL}/dashboard`);
        }
    }

    const toggleBtn = () => {
        if (searchOpen) {
            setsearchOpen(!searchOpen);
            document.querySelector('.searchIcon').classList.add('open');
        } else {
            setsearchOpen(!searchOpen);
            document.querySelector('.searchIcon').classList.remove('open');
        }
    }
    const RemoveData = () => {
        setViewIcon(false);
        removeFix();
        searchinner.current.value = "";
        document.getElementById("errormsg").style.display = "none";
    }

    return (
        <Fragment>
            <div>
                <form className="form-inline search-form">
                    <div className="form-group">
                        <input
                            className="form-control-plaintext searchIcon"
                            type="text"
                            placeholder={localStorage.CentreName}
                            defaultValue={searchValue}
                            onChange={(e) => handleSearchKeyword(e.target.value)}
                            onClick={(e) => handleSearchKeyword("")}
                            ref={searchinner}
                        />

                        <span className="d-sm-none mobile-search" onClick={toggleBtn}>
                            <Search />
                        </span>

                        <div className="Typeahead-menu custom-scrollbar" id="search-outer">
                            {searchValue ?
                                searchValue.map((data, index) => {
                                    return (
                                        <div className="ProfileCard u-cf" key={index}>
                                            {/* <div className="ProfileCard-avatar">
                                                <data.icon />
                                            </div> */}
                                            <div className="ProfileCard-details">
                                                <div className="ProfileCard-realName">
                                                    <Link
                                                        //to={`${process.env.PUBLIC_URL}${data.path}`}
                                                        className="realname"
                                                        // onClick={removeFix}
                                                        onClick={(e) => removeFix(data.CentreName, data.CentreId, data.CentreCode)}
                                                    >
                                                        {data.CentreName}
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }) : ''
                            }
                        </div>
                        <div className="Typeahead-menu empty-menu" id="errormsg">
                            <div className="tt-dataset tt-dataset-0">
                                <div className="EmptyMessage">
                                    {"Opps!! There are no result found."}
                                </div>
                            </div>
                        </div>
                        {
                            viewicon === true ?
                                <div className='searchCloseIcon'><i className='fa fa-remove' id="iconhide" onClick={(e) => RemoveData()}></i></div>
                                :
                                null
                        }

                    </div>
                </form>
            </div>
        </Fragment>
    );
};

export default SearchHeader;