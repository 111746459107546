import React, { useEffect, useState, useRef } from 'react';
import Breadcrumb from '../../components/common/breadcrumb';
import { FrontDesk_URL } from '../../constant';
import axios from 'axios';
import SweetAlert from 'sweetalert2';
import { Spinner } from 'react-bootstrap';
import DataTables from '../commonComponent/DataTable/DataTables';
import NoRecordFounds from '../commonComponent/DataTable/NoRecordFounds';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Container, Row, Col, Card, Form, Button } from 'react-bootstrap';
import Select from 'react-select';
import { CSVLink } from "react-csv";
import ToolTip from '../commonComponent/ToolTip';

const ReleaseRefund = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [crntPageNO, setCrntPageNO] = useState(0);
    const [table, setTable] = useState([]);
    const [defaultDate, setDefaultDate] = useState("");
    const [typeSelected, setTypeSelected] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [isButton, setIsButton] = useState(false);
    const [downloadvisible, setDownloadVisible] = useState(false);
    const [CSVData, setCSVData] = useState([]);
    const [loginuserid, setLoginUserId] = useState(localStorage.LoggedInUser == undefined || localStorage.LoggedInUser == null ? "" : localStorage.LoggedInUser);
    const [logincentreid, setLoginCentreId] = useState(localStorage.CentreSNo == undefined || localStorage.CentreSNo == null ? "" : localStorage.CentreSNo);

    let fromDate = useRef();
    let toDate = useRef();
    let visitno = useRef();
    useEffect(() => {
        const today = new Date();
        const date = today.setDate(today.getDate());
        const defaultValue = new Date(date).toISOString().split('T')[0] // yyyy-mm-dd
        setDefaultDate(defaultValue);
        GetApproverNames("1", "0");
        GetApporvedRefundDetails(loginuserid, logincentreid, defaultValue, defaultValue, "0", "1");
    }, [])
    const GetApproverNames = async (lType, rId) => {
        var glblArr1 = [];
        setIsButton(true);
        try {
            let url = FrontDesk_URL + 'accounts/getledgersname/' + lType + '/' + rId
            await axios.get(url).then((response) => {
                const data = response.data;
                setIsButton(false);
                if (data.Success) {
                    let ds = data.Data;
                    if (ds != null) {
                        var itmData = data.Data;
                        itmData.map((option) => { return glblArr1.push({ 'value': option.Id, 'label': option.Value, 'disabled': false }); });
                        setTableData(glblArr1);
                    }
                    else { setTableData([]); }
                }
                else {
                    setTableData([]);
                }
            }).catch(function (error) { setIsButton(false); if (error.response) { ErrorFunction(error.response.status); } });
        }
        catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    }
    const PaginationOptions = {
        sizePerPage: 10,
        showTotal: false,
        firstPageText: 'First',
        prePageText: 'Back',
        nextPageText: 'Next',
        lastPageText: 'Last',
        onPageChange: (page, sizePerPage) => { setCrntPageNO(((page - 1) * sizePerPage)); }
    };
    const idFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                <span>{crntPageNO + rowIndex + 1}</span>
            </div>
        </React.Fragment>
    }
    const actionFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                {(rowData.ReconcileStatus == "0" || rowData.ReconcileStatus == "False") ?
                    <>
                        <ToolTip title="Reconcile" placement="top" linkId={`Reconcile${rowData.Id}`} faName="fa fa-check-square-o" onClick={(e) => ReconcileCheck(rowData.Id, "1")} />
                        {/* &nbsp; <ToolTip title="Reject" placement="top" linkId={`Reject${rowData.Id}`} faName="fa fa-close" onClick={(e) => ReconcileCheck(rowData.Id, "2")} /> */}
                    </>
                    :
                    (rowData.ReconcileStatus == "2") ?
                        <span>Rejected</span>
                        :
                        <span>Approved</span>}
            </div>
        </React.Fragment>
    }
    const statusFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                {(rowData.ReconcileStatus == "0" || rowData.ReconcileStatus == "False") ? <span>Pending</span> : (rowData.ReconcileStatus == "2") ? <span>Rejected</span> : <span>Approved</span>}
            </div>
        </React.Fragment>
    }
    const columns = [
        { dataField: '#', text: '#', editable: false, formatter: idFormatter, headerStyle: (colum, colIndex) => { return { width: '5%' }; } },
        { dataField: 'VisitingCode', text: 'Visit No', editable: false, headerStyle: (colum, colIndex) => { return { fontSize: '12px', width: '7%' }; } },
        { dataField: 'PatientName', text: 'Patient Name', editable: false, headerStyle: (colum, colIndex) => { return { fontSize: '12px', width: '7%' }; } },
        { dataField: 'MobileNo', text: 'Mobile No', editable: false, headerStyle: (colum, colIndex) => { return { fontSize: '12px', width: '7%' }; } },
        { dataField: 'InvoiceNo', text: 'Invoice No', editable: false, headerStyle: (colum, colIndex) => { return { fontSize: '12px', width: '7%' }; } },
        { dataField: 'Amount', text: 'Amount', editable: false, headerStyle: (colum, colIndex) => { return { fontSize: '12px', width: '7%' }; } },
        { dataField: 'RefundReason', text: 'Refund Reason', editable: false, headerStyle: (colum, colIndex) => { return { fontSize: '12px', width: '8%' }; } },
        { dataField: 'EntryDateTime', text: 'Entry Date Time', editable: false, headerStyle: (colum, colIndex) => { return { fontSize: '12px', width: '9%' }; } },
        { dataField: 'ApprovedBy', text: 'Approved By', editable: false, headerStyle: (colum, colIndex) => { return { fontSize: '12px', width: '7%' }; } },
        { dataField: 'ApprovedTime', text: 'Approved Time', editable: false, headerStyle: (colum, colIndex) => { return { fontSize: '12px', width: '8%' }; } },
        { dataField: 'ReconcileApprovedRefundBy', text: 'Reconcile By', editable: false, headerStyle: (colum, colIndex) => { return { fontSize: '12px', width: '8%' }; } },
        { dataField: 'ReconcileApprovedRefundTimeStamp', text: 'Reconcile TimeStamp', editable: false, headerStyle: (colum, colIndex) => { return { fontSize: '12px', width: '12%' }; } },
        { dataField: 'actions', isDummyField: true, text: 'Actions', editable: false, formatter: actionFormatter, headerStyle: (colum, colIndex) => { return { fontSize: '12px', width: '5%' }; } }
    ];
    const GetApporvedRefundDetails = async (loginid, centreid, fromdate, todate, visitnoflag, flag) => {
        setDownloadVisible(false);
        setIsLoading(true);
        try {
            let url = FrontDesk_URL + 'accounts/getapprovedrefund/' + loginid + '/' + centreid + '/' + fromdate + '/' + todate + '/' + visitnoflag + '/' + flag
            await axios.get(url).then((response) => {
                const data = response.data;
                setIsLoading(false);
                if (data.Success) {
                    if (data.Data === null) { setTable([]); setCSVData([]); }
                    else {
                        if (flag == "2") { setDownloadVisible(true); setCSVData(data.Data); document.getElementById("csvdownload").click(); }
                        else { setTable(data.Data); }
                    }
                }
                else {
                    setTable([]); setCSVData([]);
                }
            }).catch(function (error) { setIsLoading(false); if (error.response) { setTable([]); setCSVData([]); ErrorFunction(error.response.status); } });
        }
        catch (error) { setIsLoading(false); SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    }
    const ReconcileCheck = async (Id, Flag) => {
        if (Flag === "1") {
            SweetAlert.fire({
                title: 'You are going to reconcile a record. Are you sure?',
                text: "Once reconciled, you will not be able to change!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Ok',
                cancelButtonText: 'Cancel',
                reverseButtons: true
            }).then((result) => { if (result.value) { ReconsileApprovedRefundAmount(Id, Flag); } else { SweetAlert.fire('Not reconciled') } })
        }
        else {
            SweetAlert.fire({
                title: 'You are going to reject a record. Are you sure?',
                text: "Once rejected, you will not be able to change!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Ok',
                cancelButtonText: 'Cancel',
                reverseButtons: true
            }).then((result) => { if (result.value) { ReconsileApprovedRefundAmount(Id, Flag); } else { SweetAlert.fire('Not rejected') } })
        }
    }
    const ReconsileApprovedRefundAmount = async (Id, Flag) => {
        const requestOptions = {
            Id: Id,
            LoginId: localStorage.getItem('LoggedInUser'),
            Status: Flag
        };
        let url = FrontDesk_URL + 'accounts/ReconsileApprovedRefundAmount'
        await axios.post(url, requestOptions).then((response) => {
            const data = response.data;
            if (data.Success) {
                if (Flag === "1") { SweetAlert.fire({ text: "Reconciled", icon: "success" }); }
                else { SweetAlert.fire({ text: "Rejected", icon: "error" }); }
                const today = new Date();
                const date = today.setDate(today.getDate());
                const defaultValue = new Date(date).toISOString().split('T')[0] // yyyy-mm-dd
                setDefaultDate(defaultValue);
                setTypeSelected([]);
                fromDate.current.value = defaultValue;
                toDate.current.value = defaultValue;
                GetApporvedRefundDetails(loginuserid, logincentreid, defaultValue, defaultValue, "0", "1");
            }
            else {
                if (data.ErrorList[0].errorCode == -2000 || data.Data == -2000) { SweetAlert.fire({ title: "API Runtime Exception", text: "Some error occured! Please try after sometime.", icon: "error" }) }
                else if (data.ErrorList[0].errorCode == -1 || data.Data == -1) {
                    SweetAlert.fire({
                        title: 'Already reconciled',
                        text: "This has been already reconciled",
                        icon: 'warning',
                        showCancelButton: false,
                        confirmButtonText: 'Ok',
                        reverseButtons: true
                    }).then((result) => { if (result.value) { window.location.reload(false); } else { window.location.reload(false); } })
                }
                else if (data.ErrorList[0].errorCode == -2001 || data.Data == -2001) {
                    SweetAlert.fire({
                        title: 'Insufficient amount',
                        text: "Refund amount is not available in your current balance",
                        icon: 'warning',
                        showCancelButton: false,
                        confirmButtonText: 'Ok',
                        reverseButtons: true
                    }).then((result) => { if (result.value) { window.location.reload(false); } else { window.location.reload(false); } })
                }
                else { SweetAlert.fire({ title: "Application Exception", text: "Some error occured! Please try after sometime.", icon: "error" }) }
            }
        }).catch(function (error) {
            if (error.response) { ErrorFunction(error.response.status); }
        });
    }
    const ErrorFunction = async (ErrorCode) => {
        if (ErrorCode == "400")
            SweetAlert.fire({ title: "Error 400", text: "Bad request", icon: "error" })
        else if (ErrorCode == "401")
            SweetAlert.fire({ title: "Error 401", text: "Unauthorized", icon: "error" })
        else if (ErrorCode == "403")
            SweetAlert.fire({ title: "Error 403", text: "Forbidden", icon: "error" })
        else if (ErrorCode == "404")
            SweetAlert.fire({ title: "Error 404", text: "Page not found", icon: "error" })
        else if (ErrorCode == "500")
            SweetAlert.fire({ title: "Error 500", text: "Internal Server Error", icon: "error" })
        else if (ErrorCode == "503")
            SweetAlert.fire({ title: "Error 503", text: "Service Unavailable", icon: "error" })
    }
    const handleSearch = async () => {
        var fdate = (fromDate.current.value == "" || fromDate.current.value == null || fromDate.current.value == undefined) ? '0' : fromDate.current.value
        var tdate = (toDate.current.value == "" || toDate.current.value == null || toDate.current.value == undefined) ? '0' : toDate.current.value
        if (fdate == '0' && tdate != '0') { SweetAlert.fire("From date is mandatory"); return false; }
        if (fdate != '0' && tdate == '0') { SweetAlert.fire("To date is mandatory"); return false; }
        // if (typeSelected.length > 0) {
        if (visitno.current == undefined || visitno.current == null || visitno.current == "" || visitno.current.value == undefined || visitno.current.value == null || visitno.current.value == "") {
            GetApporvedRefundDetails(loginuserid, logincentreid, fdate, tdate, "0", "1");
        }
        else {
            GetApporvedRefundDetails(loginuserid, logincentreid, fdate, tdate, visitno.current.value, "1");
        }
    }
    const TypeChangeHandler = (options) => {
        var typearr = [];
        typearr.push({
            value: options.value,
            label: options.label
        });
        setTypeSelected(typearr);
    }
    const handleDownload = async () => {
        var fdate = (fromDate.current.value == "" || fromDate.current.value == null || fromDate.current.value == undefined) ? '0' : fromDate.current.value
        var tdate = (toDate.current.value == "" || toDate.current.value == null || toDate.current.value == undefined) ? '0' : toDate.current.value
        if (fdate == '0' && tdate != '0') { SweetAlert.fire("From date is mandatory"); return false; }
        if (fdate != '0' && tdate == '0') { SweetAlert.fire("To date is mandatory"); return false; }
        // if (typeSelected.length > 0) {
        if (visitno.current == undefined || visitno.current == null || visitno.current == "" || visitno.current.value == undefined || visitno.current.value == null || visitno.current.value == "") {
            GetApporvedRefundDetails(loginuserid, logincentreid, fdate, tdate, "0", "2");
        }
        else {
            GetApporvedRefundDetails(loginuserid, logincentreid, fdate, tdate, visitno.current.value, "2");
        }
    }
    return (
        <>
            <Breadcrumb title="Release Refund" parent="Accounts" />
            <Container fluid>
                <Row>
                    <Col md={12}>
                        <Card>
                            <Card.Body>
                                <Form.Row>
                                    <Col md={4}>
                                        <Form.Group className='animate-chk m-checkbox-inline center__type mt-15'>
                                            <Form.Label>Visit No</Form.Label>
                                            {/* {isButton ? <Spinner animation="border" size='sm' /> : null}
                                            <Select onChange={TypeChangeHandler}
                                                options={tableData}
                                                value={typeSelected} /> */}
                                            <Form.Control type="text" id="visitno" name="visitno" ref={visitno} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={4}>
                                        <Form.Group>
                                            <Form.Label>From Date</Form.Label>
                                            <Form.Control type="date" id="date1" name="date1" ref={fromDate} defaultValue={defaultDate} max={defaultDate} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={4}>
                                        <Form.Group>
                                            <Form.Label>To Date</Form.Label>
                                            <Form.Control type="date" id="date2" name="date2" ref={toDate} defaultValue={defaultDate} max={defaultDate} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={12} className='text-center __center__buttonsec'>
                                        <Button variant='secondary' className="mr-2" onClick={handleSearch}>Search</Button>
                                        <Button variant='primary' className="mr-2" onClick={handleDownload}>Download</Button>
                                    </Col>
                                </Form.Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <Card>
                            <Card.Header className='pb-0'>
                                <Card.Title>Approved Refund Details</Card.Title>
                            </Card.Header>
                            <Card.Body>
                                {
                                    isLoading ?
                                        <div className='text-center'> <Spinner animation="border" size='sm' /></div> :
                                        // <div className="table-responsive mt15">
                                        <DataTables
                                            keyField='Id'
                                            tableData={table}
                                            columns={columns}
                                            noDataIndication={NoRecordFounds}
                                            pagination={paginationFactory(PaginationOptions)}
                                        />
                                    // </div>
                                }
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                {
                    downloadvisible &&
                    <CSVLink data={CSVData} filename="Approved-Refund-List.csv" target="_blank" id="csvdownload"></CSVLink>
                }
            </Container>
        </>
    )
}
export default ReleaseRefund;