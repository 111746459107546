import React from "react";
import { Container, Row, Col, Card, Form, Button, Table } from 'react-bootstrap';
import Alert from "react-bootstrap/Alert";
import DataTables from '../commonComponent/DataTable/DataTables';

class CustomerCollectionReport extends React.Component {

    render() {
        const columns = [
            { dataField: 'ReceiptNo', text: '#', editable: false },
            { dataField: 'ReceiptDate', text: 'Receipt Date', editable: false },
            { dataField: 'BillNo', text: 'Bill No', editable: false },
            { dataField: 'BillDate', text: 'Bill Date', editable: false },
            // { dataField: 'VisitingCode', text: 'Visiting Id', editable: false },
            { dataField: 'PatientName', text: 'Patient Name', editable: false },
            { dataField: 'PaidAmount', text: 'Paid Amount', editable: false },
            { dataField: 'ISRefund', text: 'IS Refund', editable: false }
        ];
        const pdfProps = this.props;
        const apiData = pdfProps.APIData;
        return (
            <>
                <Container fluid>
                    <Row>
                        <Col md={12}>
                            <Card>
                                <Card.Body>
                                    <Form>
                                        <Form.Row>
                                            <Col md={12}>
                                                <h6 className='text-red'>Summary</h6>
                                                <Alert variant="dark" className="text-center">
                                                    <ul className="list-inline pointsInline">
                                                        <li className="list-inline-item">Name: <strong>{apiData.UserName}</strong></li>
                                                        <li className="list-inline-item">Total Sale: <strong>{apiData.Total}</strong></li>
                                                        <li className="list-inline-item">Cash: <strong>{apiData.Cash}</strong></li>
                                                        <li className="list-inline-item">Paytm: <strong>{apiData.Paytm}</strong></li>
                                                        <li className="list-inline-item">Bharat Pay: <strong>{apiData.BharatPay}</strong></li>
                                                        <li className="list-inline-item">Axis Soundbox: <strong>{apiData.PhonePe}</strong></li>
                                                        <li className="list-inline-item">Credit Card: <strong>{apiData.CreditCard}</strong></li>
                                                        <li className="list-inline-item">Debit Card: <strong>{apiData.DebitCard}</strong></li>
                                                        <li className="list-inline-item">GPay: <strong>{apiData.GPay}</strong></li>
                                                        <li className="list-inline-item">Online: <strong>{apiData.Online}</strong></li>
                                                        <li className="list-inline-item">POS: <strong>{apiData.POS}</strong></li>
                                                        <li className="list-inline-item">Razor Pay: <strong>{apiData.RazorPay}</strong></li>
                                                    </ul>
                                                </Alert>
                                            </Col>
                                        </Form.Row>
                                        {apiData.CashCollectionList.length > 0 ?
                                            <Form.Row className='mt15'>
                                                <Col md={12}>
                                                    <h6 className='text-red'>Cash Details</h6>
                                                    {<DataTables keyField='id' tableData={apiData.CashCollectionList} columns={columns} />}
                                                </Col>
                                            </Form.Row>
                                            : null}
                                        {apiData.PaytmCollectionList.length > 0 ?
                                            <Form.Row className='mt15'>
                                                <Col md={12}>
                                                    <h6 className='text-red'>Paytm Details</h6>
                                                    {<DataTables keyField='id' tableData={apiData.PaytmCollectionList} columns={columns} />}
                                                </Col>
                                            </Form.Row>
                                            : null}
                                        {apiData.BharatPayCollectionList.length > 0 ?
                                            <Form.Row className='mt15'>
                                                <Col md={12}>
                                                    <h6 className='text-red'>Bharat Pay Details</h6>
                                                    {<DataTables keyField='id' tableData={apiData.BharatPayCollectionList} columns={columns} />}
                                                </Col>
                                            </Form.Row>
                                            : null}
                                        {apiData.PhonePeCollectionList.length > 0 ?
                                            <Form.Row className='mt15'>
                                                <Col md={12}>
                                                    <h6 className='text-red'>Axis Soundbox Details</h6>
                                                    {<DataTables keyField='id' tableData={apiData.PhonePeCollectionList} columns={columns} />}
                                                </Col>
                                            </Form.Row>
                                            : null}
                                        {apiData.CreditCardCollectionList.length > 0 ?
                                            <Form.Row className='mt15'>
                                                <Col md={12}>
                                                    <h6 className='text-red'>Credit Card Details</h6>
                                                    {<DataTables keyField='id' tableData={apiData.CreditCardCollectionList} columns={columns} />}
                                                </Col>
                                            </Form.Row>
                                            : null}
                                        {apiData.DebitCardCollectionList.length > 0 ?
                                            <Form.Row className='mt15'>
                                                <Col md={12}>
                                                    <h6 className='text-red'>Debit Card Details</h6>
                                                    {<DataTables keyField='id' tableData={apiData.DebitCardCollectionList} columns={columns} />}
                                                </Col>
                                            </Form.Row>
                                            : null}
                                        {apiData.GPayCollectionList.length > 0 ?
                                            <Form.Row className='mt15'>
                                                <Col md={12}>
                                                    <h6 className='text-red'>GPay Details</h6>
                                                    {<DataTables keyField='id' tableData={apiData.GPayCollectionList} columns={columns} />}
                                                </Col>
                                            </Form.Row>
                                            : null}
                                        {apiData.OnlineCollectionList.length > 0 ?
                                            <Form.Row className='mt15'>
                                                <Col md={12}>
                                                    <h6 className='text-red'>Online Details</h6>
                                                    {<DataTables keyField='id' tableData={apiData.OnlineCollectionList} columns={columns} />}
                                                </Col>
                                            </Form.Row>
                                            : null}
                                        {apiData.POSCollectionList.length > 0 ?
                                            <Form.Row className='mt15'>
                                                <Col md={12}>
                                                    <h6 className='text-red'>POS Details</h6>
                                                    {<DataTables keyField='id' tableData={apiData.POSCollectionList} columns={columns} />}
                                                </Col>
                                            </Form.Row>
                                            : null}
                                        {apiData.RazorPayCollectionList.length > 0 ?
                                            <Form.Row className='mt15'>
                                                <Col md={12}>
                                                    <h6 className='text-red'>RazorPay Details</h6>
                                                    {<DataTables keyField='id' tableData={apiData.RazorPayCollectionList} columns={columns} />}
                                                </Col>
                                            </Form.Row>
                                            : null}
                                    </Form>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </>
        );
    }
}

export default CustomerCollectionReport;