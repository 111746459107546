import React from 'react'
import { Bold } from 'react-feather';
import { Link } from "react-router-dom";
import { UncontrolledTooltip } from 'reactstrap';

const ViewPackageToolTip = (props) => {
  return (
    <>
      <Link to="#" onClick={props.onClick} className="text-dark"><i class="fa fa-info-circle mr-2" style={{fontWeight:Bold}} id={`View${props.id}`} aria-hidden="true"></i></Link>
      <UncontrolledTooltip placement={props.placement} target={`View${props.id}`}>
        {props.title}
      </UncontrolledTooltip>
    </>
  )
}

export default ViewPackageToolTip