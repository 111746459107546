import React, { useEffect, useState, useRef } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { useHistory } from 'react-router';
import { Container, Row, Col, Card, Form, Button, Table } from 'react-bootstrap';
import Breadcrumb from '../../components/common/breadcrumb';
import SweetAlert from 'sweetalert2';
import { FrontDesk_URL } from '../../constant';
import axios from 'axios';
import { Spinner } from 'react-bootstrap';
import LoadingButton from "../commonComponent/LoadingButton";
import DataTables from '../commonComponent/DataTable/DataTables';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import ToolTip from '../commonComponent/ToolTip';

var glbRecords = [];
var glbColors = [];
let totalCount = 0;
var glblinvestigationid = "";
const BatchConfirmation = () => {
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [isSaving, setIsSaving] = useState(false);
    const [colorTable, setColorTable] = useState([]);
    const [modal, setModal] = useState();
    const toggleModal = () => { setModal(!modal) }
    const [errorModal, setErrorModal] = useState();
    const errorHandler = () => { setModal(true); setErrorModal(true); }
    const [batchNo, setBatchNo] = useState('');
    const [centreName, setCentreName] = useState('');
    const [batchStatus, setBatchStatus] = useState('');
    const [fieldBoy, setFieldBoy] = useState('0');
    const [courierDetail, setCourierDetail] = useState('');
    const [docketNo, setDocketNo] = useState('');
    const [hideButton, setHideButton] = useState(false);
    const [fieldBoysList, setFieldBoysList] = useState([]);
    const [transferBatch, setTransferBatch] = useState(false);
    const [rejectReasons, setRejectReasons] = useState([]);
    const [rejectModal, setRejectModal] = useState();
    const [batchTransferred, setBatchTransferred] = useState(false);
    const [crntPageNO, setCrntPageNO] = useState(0);
    const openModal = () => { setRejectModal(!rejectModal); };

    useEffect(() => { GetBatchDetails(); GetRejectionReasons(); GetFieldBoys(); }, []);

    const GetRejectionReasons = async () => {
        try {
            let url = FrontDesk_URL + 'batchmanagement/getrejectionreasons/' + localStorage.LoggedInUser + "/" + localStorage.getItem('CentreSNo')
            await axios.get(url).then((response) => {
                const data = response.data;
                if (data.Success) { setRejectReasons(data.Data.map((d) => (<option key={d.Id} value={d.Id}>{d.Value}</option>))); }
            }).catch(function (error) { });
        }
        catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    }
    const backHandler = (event) => { event.preventDefault(); history.push('/batch-management/list'); }
    const GetBatchDetails = async () => {
        setIsLoading(true);
        totalCount = 0;
        var bId = localStorage.getItem('bId');
        if (bId === null) { bId = "0"; }
        let url = FrontDesk_URL + 'batchmanagement/getbatchconfirmdetails/' + localStorage.LoggedInUser + "/" + localStorage.getItem('CentreSNo') + "/" + bId;
        await axios.get(url).then((response) => {
            const data = response.data;
            if (data.Success) {
                if (data.Data !== null) {
                    var batchInfo = data.Data;
                    if (bId !== "0") {
                        transferFormik.values.batchId = batchInfo.BatchId;
                        transferFormik.values.fieldBoyId = batchInfo.FieldBoyId;
                        transferFormik.values.courierDetail = batchInfo.CourierDetail;
                        transferFormik.values.docketNo = batchInfo.DocketNo;
                        setBatchNo(batchInfo.BatchNo);
                        totalCount = parseInt(batchInfo.VialsQty);
                        setCentreName(batchInfo.CentreName);
                        setFieldBoy(batchInfo.FieldBoy);
                        setCourierDetail(batchInfo.CourierDetail);
                        setDocketNo(batchInfo.DocketNo);
                        if (batchInfo.Status === "1") { setBatchStatus("Pending Transfer"); }
                        else if (batchInfo.Status === "2") { setBatchStatus("Batch Transferred"); setHideButton(true); setBatchTransferred(true); }
                        else if (batchInfo.Status === "3") { setBatchStatus("Batch Received At Lab"); setHideButton(true); setBatchTransferred(true); }
                        else { setBatchStatus("Batch Transferred"); setHideButton(true); setBatchTransferred(true); }
                    }
                    var barCodesInfo = batchInfo.BatchBarcodes;
                    if (barCodesInfo.length > 0) {
                        glbRecords = barCodesInfo;
                        setTableData(barCodesInfo);
                    }
                }
                setIsLoading(false);
            }
            else { SweetAlert.fire({ title: "Application Exception", text: "Some error occured! Please try after sometime", icon: "error" }); setIsLoading(false); }
        }).catch(function (error) { if (error.response) { ErrorFunction(error.response.status); } setIsLoading(false); });
    }
    const idFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                <span>{crntPageNO + rowIndex + 1}</span>
            </div>
        </React.Fragment>
    }
    const actionFormatter = (cellData, rowData, rowIndex, extraData) => {
        var boolValue = true;
        if (rowData.IsSelected === "0") { boolValue = false; }
        return <React.Fragment>
            <div>
                <input className="checkbox_animated" type="checkbox" defaultChecked={boolValue} onClick={(e) => { handleEditCheckbox(e, rowData); }} />
                {rowData.IsRejected === "1" ? <ToolTip title="Rejected" placement="top" linkId={"DV" + rowIndex} faName="fa fa-close mr-2"></ToolTip>
                    : <ToolTip title="Reject Sample" placement="top" onClick={() => popupHandler(rowData)} linkId="InvestigationId" faName="fa fa-ban mr-2"></ToolTip>
                }
            </div>
        </React.Fragment>
    }
    const readOnlyActionFormatter = (cellData, rowData, rowIndex, extraData) => {
        var boolValue = true;
        if (rowData.IsSelected === "0") { boolValue = false; }
        return <React.Fragment>
            <div>
                {rowData.IsRejected === "1" ? <ToolTip title="Rejected" placement="top" linkId={"DV" + rowIndex} faName="fa fa-close mr-2"></ToolTip> : null}
            </div>
        </React.Fragment>
    }
    const handleEditCheckbox = (e, rawData) => {
        const checked = e.target.checked;
        if (checked) {
            var addIncrement = true;
            for (var i in glbRecords) {
                var currentData = glbRecords[i];
                if (currentData.VialColor === rawData.VialColor) { if (currentData.IsSelected === "1") { addIncrement = false; break; } }
            }
            rawData.IsSelected = "1";
            if (addIncrement) { totalCount += parseInt(rawData.VialQty); }
        } else {
            rawData.IsSelected = "0";
            var minusIncrement = true;
            for (var i in glbRecords) {
                var currentData = glbRecords[i];
                if (currentData.VialColor === rawData.VialColor) {
                    if (currentData.IsSelected === "1") {
                        minusIncrement = false; break;
                    }
                }
            }
            if (minusIncrement) { totalCount -= parseInt(rawData.VialQty); }
        }
        if (totalCount === 0) { setHideButton(true); } else { setHideButton(false); }
        setColorTable(glbColors.map((d) => (<div className='colorBox' style={{ 'backgroundColor': `${d.Color}` }} >{d.Count}</div>)));
    };
    const columns =
        transferBatch === true || batchTransferred == true ?
            [
                { dataField: '#', text: '#', editable: false, formatter: idFormatter, headerStyle: (colum, colIndex) => { return { width: '5%' }; } },
                { dataField: 'Barcode', text: 'Barcode', editable: false },
                { dataField: 'VialQty', text: 'Vial Qty', editable: false },
                { dataField: 'PatientName', text: 'Patient Name', editable: false },
                { dataField: 'SampleTypeName', text: 'Sample Type', editable: false },
                { dataField: 'InvestigationName', text: 'Tests', editable: false },
                // { dataField: 'IsRejected', text: 'Rejected', editable: false }
                { dataField: 'Action', text: 'Action', editable: false, formatter: readOnlyActionFormatter }
            ] :
            [
                { dataField: '#', text: '#', editable: false, formatter: idFormatter, headerStyle: (colum, colIndex) => { return { width: '5%' }; } },
                { dataField: 'Barcode', text: 'Barcode', editable: false },
                { dataField: 'VialQty', text: 'Vial Qty', editable: false },
                { dataField: 'PatientName', text: 'Patient Name', editable: false },
                { dataField: 'SampleTypeName', text: 'Sample Type', editable: false },
                { dataField: 'InvestigationName', text: 'Tests', editable: false },
                // { dataField: 'IsRejected', text: 'Rejected', editable: false },
                { dataField: 'Action', text: 'Action', editable: false, formatter: actionFormatter }
            ];
    const paginationOptions = { sizePerPage: 10, showTotal: false, firstPageText: 'First', prePageText: 'Back', nextPageText: 'Next', lastPageText: 'Last', onPageChange: (page, sizePerPage) => { setCrntPageNO(((page - 1) * sizePerPage)); } };
    const GetFieldBoys = async () => {
        try {
            let url = FrontDesk_URL + 'batchmanagement/getfieldboys/' + localStorage.LoggedInUser + "/" + localStorage.getItem('CentreSNo')
            await axios.get(url).then((response) => {
                const data = response.data;
                if (data.Success) { setFieldBoysList(data.Data.map((d) => (<option key={d.Id} value={d.Id}>{d.Value}</option>))); }
            }).catch(function (error) { });
        }
        catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    }
    const transferFormik = useFormik({
        initialValues: { batchId: '0', batchNo: '', fieldBoyId: '', courierDetail: '', docketNo: '' },
        validationSchema: Yup.object({
            fieldBoyId: Yup.string().required('Field boy is required')
            //,docketNo: Yup.string().required('Docket No is required')
        }),
        onSubmit: values => { setModal(false); setErrorModal(false); TransferBatch(values); },
    });
    const TransferBatch = async (values) => {
        if (values.fieldBoyId === '0' || values.fieldBoyId === '') {
            SweetAlert.fire("Field boy is required");
            return false;
        }
        if (values.fieldBoyId === '1' && values.docketNo === '') {
            SweetAlert.fire("Docket No is required");
            return false;
        }
        var bId = localStorage.getItem('bId');
        if (bId === null) { bId = "0"; }
        var arrayBarcode = [];
        for (var key in tableData) {
            if (tableData[key].IsSelected === "1") {
                arrayBarcode.push({
                    Barcode: tableData[key].Barcode,
                    InvestigationId: tableData[key].InvestigationId,
                    IsRejected: tableData[key].IsRejected,
                    RejectReasonId: tableData[key].RejectReasonId
                });
            }
        }
        if (arrayBarcode.length === 0) { SweetAlert.fire({ title: "Validation", text: "Please select atleast on barcode.", icon: "error" }); return; }
        setIsSaving(true);
        const requestOptions = {
            BatchId: bId,
            FieldBoyId: values.fieldBoyId,
            CourierDetail: values.courierDetail,
            DocketNo: values.docketNo,
            cId: localStorage.getItem('CentreSNo'),
            uId: localStorage.LoggedInUser,
            Barcodes: arrayBarcode,
            TotalVials: totalCount.toString()
        };
        console.log(requestOptions);
        let url = FrontDesk_URL + 'batchmanagement/dispatchbatch'
        await axios.post(url, requestOptions).then((response) => {
            const data = response.data;
            if (data.Success) {
                SweetAlert.fire({ title: "Success", text: "Data saved successfully", icon: "success" });
                history.push({ pathname: `${process.env.PUBLIC_URL}/batch-management/list`, search: `` });
            }
            else { SweetAlert.fire({ title: "Application Exception", text: "Some error occured! Please try after sometime", icon: "error" }); setIsSaving(false); }
        }).catch(function (error) { setIsSaving(false); });
    }
    const rejectFormik = useFormik({
        initialValues: { investigationId: '0', isRejected: '0', rejectedReasonId: '' },
        validationSchema: Yup.object({ rejectedReasonId: Yup.string().required('Reject reason is required') }),
        onSubmit: values => { setModal(false); setErrorModal(false); SetRejected(values); }
    });

    const SetRejected = async (values) => {
        glbRecords = tableData;
        setTableData([]);
        for (var i in glbRecords) {
            var recordInfo = glbRecords[i];
            if (recordInfo.InvestigationId === glblinvestigationid) {
                if (values.rejectedReasonId === "0") { recordInfo.IsRejected = "0"; recordInfo.RejectReasonId = "0"; }
                else { recordInfo.IsRejected = "1"; recordInfo.RejectReasonId = values.rejectedReasonId; }
            }
        }
        setRejectModal(false);
        setTableData(glbRecords);
    }

    const popupHandler = async (rawData) => {
        rejectFormik.resetForm();
        glblinvestigationid = rawData.InvestigationId;
        rejectFormik.values.investigationId = rawData.InvestigationId;
        rejectFormik.values.isRejected = rawData.IsRejected;
        if (rawData.RejectReasonId === "0") { rejectFormik.values.rejectedReasonId = ""; } else { rejectFormik.values.rejectedReasonId = rawData.RejectReasonId; }
        console.log(rejectFormik.values.investigationId);
        openModal();
    }
    const ErrorFunction = async (ErrorCode) => {
        if (ErrorCode == "400")
            SweetAlert.fire({ title: "Error 400", text: "Bad request", icon: "error" })
        else if (ErrorCode == "401")
            SweetAlert.fire({ title: "Error 401", text: "Unauthorized", icon: "error" })
        else if (ErrorCode == "403")
            SweetAlert.fire({ title: "Error 403", text: "Forbidden", icon: "error" })
        else if (ErrorCode == "404")
            SweetAlert.fire({ title: "Error 404", text: "Page not found", icon: "error" })
        else if (ErrorCode == "500")
            SweetAlert.fire({ title: "Error 500", text: "Internal Server Error", icon: "error" })
        else if (ErrorCode == "503")
            SweetAlert.fire({ title: "Error 503", text: "Service Unavailable", icon: "error" })
    }

    return (
        <>
            <Breadcrumb title="Create/Update" parent="Batch Management" />
            <Container fluid>
                <Form onSubmit={transferFormik.handleSubmit}>
                    <Row>
                        <Col md={12}>
                            <Card>
                                <Card.Header className='pb-0'>
                                    <Card.Title>Batch Detail</Card.Title>
                                </Card.Header>
                                <Card.Body>
                                    <Form.Row>
                                        <Col md={2}>
                                            <Form.Group>
                                                <Form.Label><b>Batch No.:</b></Form.Label> &nbsp;
                                                <Form.Label>{batchNo}</Form.Label>
                                            </Form.Group>
                                        </Col>
                                        <Col md={2}>
                                            <Form.Group>
                                                <Form.Label><b>Total Vials:</b></Form.Label> &nbsp;
                                                <Form.Label>{totalCount}</Form.Label>
                                            </Form.Group>
                                        </Col>
                                        <Col md={2}>
                                            <Form.Group>
                                                <Form.Label><b>Status:</b></Form.Label> &nbsp;
                                                <Form.Label>{batchStatus}</Form.Label>
                                            </Form.Group>
                                        </Col>
                                        <Col md={4}>
                                            <Form.Group>
                                                <Form.Label><b>Transferred To:</b></Form.Label> &nbsp;
                                                <Form.Label>{centreName}</Form.Label>
                                            </Form.Group>
                                        </Col>
                                        <Col md={2}>
                                            <Form.Group className="text-right">
                                                <Button variant='secondary' className="mr-2" onClick={backHandler} >Back</Button>
                                            </Form.Group>
                                        </Col>
                                        {
                                            batchTransferred === true ?
                                                <>
                                                    <Col md={2}>
                                                        <Form.Group>
                                                            <Form.Label><b>FieldBoy:</b></Form.Label> &nbsp;
                                                            <Form.Label>{fieldBoy}</Form.Label>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col md={2}>
                                                        <Form.Group>
                                                            <Form.Label><b>Docket No.:</b></Form.Label> &nbsp;
                                                            <Form.Label>{docketNo}</Form.Label>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col md={6}>
                                                        <Form.Group>
                                                            <Form.Label><b>Courier Detail:</b></Form.Label> &nbsp;
                                                            <Form.Label>{courierDetail}</Form.Label>
                                                        </Form.Group>
                                                    </Col>
                                                </>
                                                : null
                                        }
                                    </Form.Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <Card>
                                <Card.Header className='pb-0'>
                                    <Card.Title>Existing Samples</Card.Title>
                                </Card.Header>
                                <Card.Body>
                                    {
                                        isLoading ?
                                            <div className='text-center'> <Spinner animation="border" size='sm' /></div> :
                                            <>
                                                <DataTables keyField='RowId' tableData={tableData} columns={columns} pagination={paginationFactory(paginationOptions)} />
                                            </>
                                    }
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    {batchTransferred === false ?
                        <Row>
                            <Col md={12}>
                                <Card>
                                    <Card.Header className='pb-0'>
                                        <Card.Title>Dispatch Details</Card.Title>
                                    </Card.Header>
                                    <Card.Body>
                                        <Form.Row>
                                            <Col md={4}>
                                                <Form.Group>
                                                    <Form.Label>Field Boy<span className='text-required'>*</span></Form.Label>
                                                    <Form.Control as="select" name="fieldBoyId" onChange={transferFormik.handleChange} onBlur={transferFormik.handleBlur} value={transferFormik.values.fieldBoyId} >
                                                        <option value=''>---Select---</option>
                                                        {fieldBoysList}
                                                    </Form.Control>
                                                    {transferFormik.touched.fieldBoyId && transferFormik.errors.fieldBoyId ? (<div className='text-required'>{transferFormik.errors.fieldBoyId}</div>) : null}
                                                </Form.Group>
                                            </Col>
                                            <Col md={4}>
                                                <Form.Group>
                                                    <Form.Label>Courier Detail</Form.Label>
                                                    <Form.Control type="text" name="courierDetail" onChange={transferFormik.handleChange} onBlur={transferFormik.handleBlur} value={transferFormik.values.courierDetail} />
                                                </Form.Group>
                                            </Col>
                                            <Col md={4}>
                                                <Form.Group>
                                                    <Form.Label>Docket No</Form.Label>
                                                    <Form.Control type="text" name="docketNo" onChange={transferFormik.handleChange} onBlur={transferFormik.handleBlur} value={transferFormik.values.docketNo} />
                                                    {/* {transferFormik.touched.docketNo && transferFormik.errors.docketNo ? (<div className='text-required'>{transferFormik.errors.docketNo}</div>) : null} */}
                                                </Form.Group>
                                            </Col>
                                        </Form.Row>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row> : null
                    }
                    {batchTransferred === false ?
                        <Row>
                            <Col md={12} className='text-center'>
                                {isSaving ? <LoadingButton variantName='primary' cssName="mr-2" /> : <Button variant='primary' type='submit' className="mr-2" onClick={errorHandler}>Transfer Batch</Button>}
                            </Col>
                        </Row>
                        : null
                    }
                </Form>
                {rejectModal &&
                    <Modal isOpen={rejectModal} toggle={openModal} centered={true} size="lg">
                        <ModalHeader toggle={openModal}>Reject Reason</ModalHeader>
                        <ModalBody>
                            <Form onSubmit={rejectFormik.handleSubmit}>
                                <Row>
                                    <Col md={12}>
                                        <Card>
                                            <Card.Body>
                                                <Form.Row>
                                                    <Col md={12}>
                                                        <Form.Group>
                                                            <Form.Label>Reject Reason<span className='text-required'>*</span></Form.Label>
                                                            <Form.Control as="select" name="rejectedReasonId" onChange={rejectFormik.handleChange} onBlur={rejectFormik.handleBlur} value={rejectFormik.values.rejectedReasonId}>
                                                                <option value="">---Select---</option>
                                                                {rejectReasons}
                                                            </Form.Control>
                                                            {rejectFormik.touched.rejectedReasonId && rejectFormik.errors.rejectedReasonId ? (<div className='text-required'>{rejectFormik.errors.rejectedReasonId}</div>) : null}
                                                        </Form.Group>
                                                    </Col>
                                                </Form.Row>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12} className='text-center'>
                                        {isSaving ? <LoadingButton variantName='primary' cssName="mr-2" /> : <Button variant='primary' type='submit' className="mr-2" onClick={errorHandler}>Submit</Button>}
                                    </Col>
                                </Row>
                            </Form>
                        </ModalBody>
                    </Modal>
                }
                {errorModal &&
                    <Modal isOpen={modal} toggle={toggleModal} centered={true}>
                        <ModalHeader toggle={toggleModal}>Errors</ModalHeader>
                        <ModalBody>
                            <p>{transferFormik.touched.fieldBoyId && transferFormik.errors.fieldBoyId ? (<div className='text-required'>{transferFormik.errors.fieldBoyId}</div>) : null}</p>
                            {/* <p>{transferFormik.touched.docketNo && transferFormik.errors.docketNo ? (<div className='text-required'>{transferFormik.errors.docketNo}</div>) : null}</p> */}
                            <p>{rejectFormik.touched.rejectedReasonId && rejectFormik.touched.rejectedReasonId ? (<div className='text-required'>{rejectFormik.errors.rejectedReasonId}</div>) : null}</p>
                        </ModalBody>
                        <ModalFooter>
                            <Button variant="secondary" onClick={toggleModal}>Cancel</Button>
                            <Button variant="primary" onClick={toggleModal}>Ok</Button>
                        </ModalFooter>
                    </Modal>
                }
            </Container>
        </>
    )
}

export default BatchConfirmation