import React, { useEffect, useState, useRef } from 'react';
import { Container, Row, Col, Card, Form, Button } from 'react-bootstrap';
import SweetAlert from 'sweetalert2';
import { Image_Path, FrontDesk_URL } from '../../constant';
import axios from 'axios';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import LoadingButton from '../commonComponent/LoadingButton';

var glblimgname = "";
const POSFlowDetails = (props) => {
    const [isButtonLoading, setIsButtonLoading] = useState(false);
    const [bankname, setBankName] = useState([]);
    const [imagepath, setImagePath] = useState('');
    const [invalidfiletype, setInvalidFileType] = useState(false);
    let refno = useRef();

    useEffect(() => { GetFinancePersonOrBankName(); }, [])
    useEffect(() => {
        glblimgname = "";
        bankFormik.values.bankamount = parseFloat(props.holdamount).toFixed(2);
    }, [props]);

    const bankFormik = useFormik({
        initialValues: { transfertobankname: '', bankamount: '', refno: '' },
        validationSchema: Yup.object({
            transfertobankname: Yup.string().required('Bank name is required'),
            bankamount: Yup.number().required('Amount is required').typeError('Amount must be a number')
        }),
        onSubmit: values => { SavePOSFlowDetails(values); }
    });

    const GetFinancePersonOrBankName = async () => {
        try {
            let url = FrontDesk_URL + 'master/GetFinancePersonORBankName/2/' + localStorage.getItem('LoggedInUser')
            await axios.get(url).then((response) => {
                const data = response.data;
                if (data.Success) {
                    setBankName(data.Data.map((d) => (<option key={d.Id} value={d.Id}>{d.Value}</option>)))
                }
            }).catch(function (error) { if (error.response) { ErrorFunction(error.response.status); } });
        }
        catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    }

    const ClearData = async () => {
        bankFormik.resetForm();
        setImagePath(""); glblimgname = ""; refno.current.value = "";
        { props.allowPOSTransfer(false); }
    }
    const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
    const UploadBankReceipt = async (e, folderName) => {
        glblimgname = "";
        const arryVal = e.target.files[0].name.split(".");
        const fileExtn = arryVal[arryVal.length - 1];

        if (fileExtn.toLowerCase() === "jpg" || fileExtn.toLowerCase() === "jpeg" || fileExtn.toLowerCase() === "png" ||
            fileExtn.toLowerCase() === "gif" || fileExtn.toLowerCase() === "svg" || fileExtn.toLowerCase() === "pdf") { setInvalidFileType(false); }
        else { SweetAlert.fire("Invalid file type!"); setInvalidFileType(true); setImagePath(''); return false; }
        var base64 = await toBase64(e.target.files[0]);
        var base64img = base64.split("base64,");
        const formData = new FormData();
        formData.append('Image', base64img[1]);
        formData.append('FolderName', folderName);
        const randomImageId = Math.random();
        var imgName = randomImageId + folderName + "." + fileExtn;
        formData.append('ImageName', imgName);
        let url = FrontDesk_URL + 'master/UploadImage_Root/'
        await axios.post(url, formData, {
            headers: { "Content-type": "multipart/form-data", },
        }).then((response) => {
            const data = response.data;
            if (data.Success) {
                const finalimg = Image_Path + folderName + "/" + imgName;
                setImagePath(finalimg);
                glblimgname = imgName;
            } else { SweetAlert.fire({ title: "Upload failed", text: "Unable to upload document due to " + data.Data + ".Please retry again", icon: "error" }) }
        }).catch(function (error) { if (error.response) { } });
    }
    const SavePOSFlowDetails = async (values) => {
        if (invalidfiletype === true) { SweetAlert.fire({ title: "Validation Failed!", text: "Please upload valid file type.", icon: "error" }); return false; }
        else if (imagepath === "") { SweetAlert.fire({ title: "Validation Failed!", text: "Bank receipt is required.", icon: "error" }); return false; }
        setIsButtonLoading(true);
        const requestOptions = {
            transferto: values.transfertobankname,
            amount: values.bankamount.toString() === "" ? "0" : values.bankamount.toString(),
            imagename: glblimgname,
            remarksORreference: values.refno === undefined ? "" : values.refno,
            CentreId: localStorage.CentreSNo,
            LoginUser: localStorage.LoggedInUser
        };
        let url = FrontDesk_URL + 'master/saveposflowdetails'
        await axios.post(url, requestOptions).then((response) => {
            setIsButtonLoading(false);
            const data = response.data;
            if (data.Success) {
                SweetAlert.fire({ text: "Amount successfully transferred for reconcilation", icon: "success" });
                ClearData();
                window.location.reload(false);
            }
            else {
                if (data.ErrorList[0].errorCode == -2000 || data.Data == -2000) { SweetAlert.fire({ title: "API Runtime Exception", text: "Some error occured! Please try after sometime.", icon: "error" }) }
                else if (data.ErrorList[0].errorCode == -1 || data.Data == -1) {
                    SweetAlert.fire({
                        title: 'Already transferred',
                        text: "Already transferred",
                        icon: 'warning',
                        showCancelButton: false,
                        confirmButtonText: 'Ok',
                        reverseButtons: true
                    }).then((result) => { if (result.value) { window.location.reload(false); } else { window.location.reload(false); } })
                }
                else { SweetAlert.fire({ title: "Application Exception", text: "Some error occured! Please try after sometime.", icon: "error" }) }
            }
        }).catch(function (error) {
            setIsButtonLoading(false);
            if (error.response) { ErrorFunction(error.response.status); }
        });
    }
    const ErrorFunction = async (ErrorCode) => {
        if (ErrorCode == "400") { SweetAlert.fire({ title: "Error 400", text: "Bad request", icon: "error" }); }
        else if (ErrorCode == "401") { SweetAlert.fire({ title: "Error 401", text: "Unauthorized", icon: "error" }); }
        else if (ErrorCode == "403") { SweetAlert.fire({ title: "Error 403", text: "Forbidden", icon: "error" }); }
        else if (ErrorCode == "404") { SweetAlert.fire({ title: "Error 404", text: "Page not found", icon: "error" }); }
        else if (ErrorCode == "500") { SweetAlert.fire({ title: "Error 500", text: "Internal Server Error", icon: "error" }); }
        else if (ErrorCode == "503") { SweetAlert.fire({ title: "Error 503", text: "Service Unavailable", icon: "error" }); }
    }
    return (
        <>
            <Row>
                <Col md={12}>
                    <Card>
                        <Card.Body>
                            <Form>
                                <Form.Row>
                                    <Col md={3}>
                                        <Form.Group>
                                            <Form.Label>Select Bank<span className='text-red'>*</span></Form.Label>
                                            <Form.Control as="select" name="transfertobankname" value={bankFormik.values.transfertobankname} onChange={bankFormik.handleChange} onBlur={bankFormik.handleBlur}>
                                                <option key="" value="">---Select---</option>
                                                {bankname}
                                            </Form.Control>
                                            {bankFormik.touched.transfertobankname && bankFormik.errors.transfertobankname ? (<div className='text-required'>{bankFormik.errors.transfertobankname}</div>) : null}
                                        </Form.Group>
                                    </Col>
                                    <Col md={3}>
                                        <Form.Group>
                                            <Form.Label>Amount in Rs.<span className='text-red'>*</span></Form.Label>
                                            <Form.Control type="text" name="bankamount" value={bankFormik.values.bankamount} onChange={bankFormik.handleChange} onBlur={bankFormik.handleBlur} />
                                            {bankFormik.touched.bankamount && bankFormik.errors.bankamount ? (<div className='text-required'>{bankFormik.errors.bankamount}</div>) : null}
                                        </Form.Group>
                                    </Col>
                                    <Col md={3}>
                                        <Form.Group>
                                            <Form.Label>Referrence No</Form.Label>
                                            <Form.Control type="text" ref={refno} name="refno" value={bankFormik.values.refno} onChange={bankFormik.handleChange} onBlur={bankFormik.handleBlur} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={3}>
                                        <Form.Group>
                                            <Form.Label>Bank Receipt<span className='text-red'>*</span></Form.Label>
                                            <div><input type="file" id="bankreceipt" onChange={(e) => UploadBankReceipt(e, "bankreceipt")} accept="image/*" /></div>
                                            {imagepath === "" ? null : <img src={imagepath} width="80"></img>}
                                        </Form.Group>
                                    </Col>
                                </Form.Row>
                                <Row>
                                    <Col md={12} className='text-center'>
                                        <Button variant='secondary' className="mr-2" onClick={() => ClearData()}>Cancel</Button>
                                        {isButtonLoading ? <LoadingButton variantName='primary' cssName="" /> : <Button variant='primary' type='submit' onClick={bankFormik.handleSubmit}>Save</Button>}
                                    </Col>
                                </Row>
                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </>
    )
}

export default POSFlowDetails;