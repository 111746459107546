import React, { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router';
import { Container, Row, Col, Card, Form, Button, Table } from 'react-bootstrap';
import Breadcrumb from '../../components/common/breadcrumb';
import paginationFactory from 'react-bootstrap-table2-paginator';
import DataTables from '../commonComponent/DataTable/DataTables';
import SweetAlert from 'sweetalert2';
import { FrontDesk_URL } from '../../constant';
import axios from 'axios';
import { Spinner } from 'react-bootstrap';
import ToolTip from '../commonComponent/ToolTip';

const Batches = () => {
    let batchNo = useRef();
    let fromDate = useRef();
    let toDate = useRef();
    const [isLoading, setIsLoading] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [crntPageNO, setCrntPageNO] = useState(0);
    const history = useHistory();
    const [isBatchRequired, setBatchRequired] = useState(false);

    const idFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                <span>{crntPageNO + rowIndex + 1}</span>
            </div>
        </React.Fragment>
    }
    const actionFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                {rowData.Status !== "2" && rowData.Status !== "3" ?
                    <>
                        <ToolTip title="Edit Batch" placement="top" onClick={() => editHandler(rowData.BatchId, '1')} linkId="EditBatch" faName="fa fa-pencil mr-2"></ToolTip>
                        <ToolTip title="Transfer Batch" placement="top" onClick={() => editHandler(rowData.BatchId, '2')} linkId="TransferBatch" faName="fa fa-exchange mr-2"></ToolTip>
                    </>
                    :
                    <ToolTip title="Batch Info" placement="top" onClick={() => editHandler(rowData.BatchId)} linkId="Id" faName="fa fa-info-circle mr-2"></ToolTip>
                }
            </div>
        </React.Fragment>
    }
    const newHandler = (vId) => {
        localStorage.removeItem('bId');
        history.push('/batch-management/details');
    }
    const columns = [
        { dataField: '#', text: '#', editable: false, formatter: idFormatter, headerStyle: (colum, colIndex) => { return { width: '5%' }; } },
        { dataField: 'BatchNo', text: 'Batch No.', editable: false },
        { dataField: 'BatchDate', text: 'Date', editable: false },
        { dataField: 'DestinationCentre', text: 'Destination Centre', editable: false },
        { dataField: 'FieldBoyName', text: 'Field Boy', editable: false },
        { dataField: 'VialsQty', text: 'Vials Qty', editable: false },
        { dataField: 'CourierDetail', text: 'Courier Detail', editable: false },
        { dataField: 'DocketNo', text: 'Docket No', editable: false },
        { dataField: 'CurrentStatus', text: 'Status', editable: false },
        { dataField: '', text: 'Action', formatter: actionFormatter, editable: false }
    ];
    const paginationOptions = { sizePerPage: 10, showTotal: false, firstPageText: 'First', prePageText: 'Back', nextPageText: 'Next', lastPageText: 'Last',onPageChange: (page, sizePerPage) => { setCrntPageNO(((page - 1) * sizePerPage)); } };
    useEffect(() => { BindGrid("", "", ""); }, []);
    const handleSearch = async () => {
        BindGrid(batchNo.current.value, fromDate.current.value, toDate.current.value);
    }
    const BindGrid = async (bNo, fDte, tDte) => {
        setIsLoading(true);
        const requestOptions = { BatchNo: bNo, Status: "0", FromDate: fDte, ToDate: tDte, uId: localStorage.LoggedInUser, cId: localStorage.getItem('CentreSNo') };
        let url = FrontDesk_URL + 'batchmanagement/getbatcheslist'
        await axios.post(url, requestOptions).then((response) => {
            const data = response.data;
            if (data.Success) {
                const batchData = data.Data;
                console.log(batchData);
                if (batchData.BatchRequired === "True") { setBatchRequired(true); } else { setBatchRequired(false); }
                if (data.Data !== null) { setTableData(batchData.BatchList); } else { setTableData([]); }
                setIsLoading(false);
            }
            else { SweetAlert.fire({ title: "Application Exception", text: "Some error occured! Please try after sometime", icon: "error" }); setIsLoading(false); }
        }).catch(function (error) { if (error.response) { ErrorFunction(error.response.status); } setIsLoading(false); });
    }
    const editHandler = async (batchId, callType) => {
        localStorage.setItem('bId', batchId);
        if (callType === "1") { history.push({ pathname: `${process.env.PUBLIC_URL}/batch-management/details`, search: `` }); }
        else { history.push({ pathname: `${process.env.PUBLIC_URL}/batch-management/confirm`, search: `` }); }
    }
    const ErrorFunction = async (ErrorCode) => {
        if (ErrorCode == "400")
            SweetAlert.fire({ title: "Error 400", text: "Bad request", icon: "error" })
        else if (ErrorCode == "401")
            SweetAlert.fire({ title: "Error 401", text: "Unauthorized", icon: "error" })
        else if (ErrorCode == "403")
            SweetAlert.fire({ title: "Error 403", text: "Forbidden", icon: "error" })
        else if (ErrorCode == "404")
            SweetAlert.fire({ title: "Error 404", text: "Page not found", icon: "error" })
        else if (ErrorCode == "500")
            SweetAlert.fire({ title: "Error 500", text: "Internal Server Error", icon: "error" })
        else if (ErrorCode == "503")
            SweetAlert.fire({ title: "Error 503", text: "Service Unavailable", icon: "error" })
    }
    return (
        <>
            <Breadcrumb title="List" parent="Batch Management" />
            <Container fluid>
                {
                    isBatchRequired ? <>
                        <Row>
                            <Col md={12}>
                                <Card>
                                    <Card.Body>
                                        <Form>
                                            <Form.Row>
                                                <Col md={3}>
                                                    <Form.Group>
                                                        <Form.Label>Batch No.:</Form.Label>
                                                        <Form.Control type='text' ref={batchNo} />
                                                    </Form.Group>
                                                </Col>
                                                <Col md={3}>
                                                    <Form.Group>
                                                        <Form.Label>Date From</Form.Label>
                                                        <Form.Control type="date" id="date1" name="date1" ref={fromDate} />
                                                    </Form.Group>
                                                </Col>
                                                <Col md={3}>
                                                    <Form.Group>
                                                        <Form.Label>Date To</Form.Label>
                                                        <Form.Control type="date" id="date2" name="date2" ref={toDate} />
                                                    </Form.Group>
                                                </Col>
                                                <Col md={3} className='text-center mt25'>
                                                    <Button variant='secondary' className="mr-2" onClick={handleSearch} >Search</Button>
                                                    <Button variant='primary' type='submit' onClick={newHandler}>Create Batch</Button>
                                                </Col>
                                            </Form.Row>
                                        </Form>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12}>
                                <Card>
                                    <Card.Header className='pb-0'>
                                        <Card.Title>Batch List</Card.Title>
                                    </Card.Header>
                                    <Card.Body>
                                        {
                                            isLoading ?
                                                <div className='text-center'> <Spinner animation="border" size='sm' /></div> :
                                                <>
                                                    <DataTables keyField='BatchId' tableData={tableData} columns={columns} pagination={paginationFactory(paginationOptions)} />
                                                </>
                                        }
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </> :
                        <Row>
                            <Col md={12}>
                                <Card>
                                    <Card.Header className='pb-0'>
                                        <Card.Title>Batch creation not required for this centre. If required then please ask atulaya team to enable it.</Card.Title>
                                    </Card.Header>
                                </Card>
                            </Col>
                        </Row>
                }
            </Container>
        </>
    )
}

export default Batches