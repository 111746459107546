import React, { useEffect, useState, useRef } from 'react';
import Breadcrumb from '../../components/common/breadcrumb';
import { FrontDesk_URL } from '../../constant';
import { Container, Row, Col, Card, Form, Button } from 'react-bootstrap';
import DynamicDataTable from '../commonComponent/DataTable/DynamicDataTable';
import NoRecordFounds from '../commonComponent/DataTable/NoRecordFounds';
import axios from 'axios';
import SweetAlert from 'sweetalert2';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { CSVLink } from "react-csv";
import * as XLSX from 'xlsx';
import ToolTip from '../commonComponent/ToolTip';
import InputGroup from 'react-bootstrap/InputGroup';
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Spinner } from 'react-bootstrap';
import DataTables from '../commonComponent/DataTable/DataTables';
var glbRecords = [];
var glbTestRecords = [];
const RefundStatus = () => {

    let fromDate = useRef();
    let toDate = useRef();
    let refStatustype = useRef();

    const [tableData, setTableData] = useState({
        PageNo: 1,
        PageSize: 100,
        TotalRecord: 0,
        Rows: []

    });
    const [crntPageNO, setCrntPageNO] = useState(0);
    const [downloadvisible, setDownloadVisible] = useState(false);
    const [CSVData, setCSVData] = useState([]);
    const [defaultDate, setDefaultDate] = useState("");
 
    const [isLoading, setIsLoading] = useState(false);
    const [testsModal, setTestsModal] = useState();
    const [popupTableData, setPopupTableData] = useState([]);
    const [visitsTableData, setVisitsTableData] = useState([]);
    const [patientName, setPatientName] = useState();
    const [patientAge, setPatientAge] = useState();
    const [visitNo, setVisitNo] = useState();
    const openModal = () => { setTestsModal(!testsModal); };
    const [modal, setModal] = useState();
    const [refundAmount, setRefundAmount] = useState(0.00);
    const [enableRefund, setEnableRefund] = useState(false);
    const [ORgRefundAfStatusList, SetORgRefundAfStatusList] = useState([]);

    useEffect(() => {
        const CentreTypeL = [];
        const today = new Date();
        const date = today.setDate(today.getDate());
        const defaultValue = new Date(date).toISOString().split('T')[0] // yyyy-mm-dd
        setDefaultDate(defaultValue);
        BindGrid(defaultValue, defaultValue, tableData.PageNo, tableData.PageSize);
      //  GetBindCenterAllLists(CentreTypeL);
    }, []);

    const BindGrid = async (fromDateSelected, toDateSelected, PageNo, PageSize) => {
        const ObjServiceCollection = {
            UserId: localStorage.getItem('LoggedInUser'),
            FromDate: fromDateSelected,
            ToDate: toDateSelected,
            PageNo: `${PageNo}`,
            PageSize: `${PageSize}`,
            IsExcelD: `${0}`,
            RefundStatus: refStatustype.current.value,
            CentreId:localStorage.CentreSNo,
            LoginId:localStorage.LoggedInUser
            //objtblCentreIds: LGlCentreTypeoption,
        };
        try {
            let url = FrontDesk_URL + 'reports/GetRefundStatusReport'
            await axios.post(url, ObjServiceCollection).then((response) => {
                const data = response.data;
                if (data.Success) {
                    if (data.Data != null) {
                        if (data.Data.RptRefundStatusList != null && data.Data.RptRefundStatusList.length > 0) {
                            setTableData({
                                ...tableData,
                                PageSize: Number(PageSize),
                                TotalRecord: Number(data.Data.TotalRecord),
                                Rows: data.Data.RptRefundStatusList
                            });
                        }
                        else { setTableData({ PageNo: 1, PageSize: 100, TotalRecord: 0, Rows: []  }); }
                        //setTable(data.Data);
                    }
                    else { setTableData({ PageNo: 1, PageSize: 100, TotalRecord: 0, Rows: []  }); }
                }
            }).catch(function (error) { setTableData({ PageNo: 1, PageSize: 100, TotalRecord: 0, Rows: []  }); });
        }
        catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    }


    const idFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                <span>{crntPageNO + rowIndex + 1}</span>
            </div>
        </React.Fragment>
    }
    const GetVisits = async (rwData) => {
      
        console.log(rwData);
        setPatientName(rwData.Patient);
        setPatientAge(rwData.Age);
        if (rwData.Status == "Approved") {  GetRefundOrgDetails(rwData.VisitId); }
        else{
            SetORgRefundAfStatusList([]);
        }
        GetTestsList(rwData.Id, rwData.VisitId, rwData.InvoiceNo);
    }
    const GetRefundOrgDetails = async (VisitingCODE) => {
        const ObjServiceCollection = {
            VisitingCode:`${VisitingCODE}`
        };
        let url =FrontDesk_URL + 'reports/GETRefundOrgDetails'
        await axios.post(url,ObjServiceCollection).then((response) => {
            const data = response.data;
            if (data.Success) {
                if (data.Data != null && data.Data.length>0) {
                    SetORgRefundAfStatusList(data.Data);
                     console.log(data.Data);
                }
                 else{
                    SetORgRefundAfStatusList([]);
                 }
            }
            else { 
                SetORgRefundAfStatusList([]);
                APIError(data.Data, data.ErrorList.length === 0 ? "" : data.ErrorList[0].errorMsg); }
        }).catch(function (error) { 
            SetORgRefundAfStatusList([]);
            if (error.response) { AxiosError(error.response.status); } });
    }
    const testsColumns = [
        { dataField: '#', text: '#', editable: false, formatter: idFormatter, headerStyle: (colum, colIndex) => { return { width: '7%' }; } },
        { dataField: 'ItemCode', text: 'Test Code', editable: false },
        { dataField: 'Investigation', text: 'Test Name', editable: false },
        { dataField: 'ItemMRP', text: 'MRP', editable: false },
        { dataField: 'Discount', text: 'Discount', editable: false },
        { dataField: 'ItemRate', text: 'Rate', editable: false },
    ];
    const GetTestsList = async (id, visitcode, InvoiceNo) => {
        setTestsModal(false);
        setVisitNo(visitcode);
        // setIsLoading(true);
        let url = FrontDesk_URL + 'accounts/getrefundrequesteditems/' + id;
        await axios.get(url).then((response) => {
            const data = response.data;
            if (data.Success) {
                if (data.Data != null) {
                    glbTestRecords = data.Data;
                    setVisitsTableData(glbTestRecords);
                    var refndAmount = 0;
                    for (var x in glbTestRecords) {
                        if (glbTestRecords[x].IsRefunded == "False") {
                            refndAmount = parseFloat(refndAmount) + parseFloat(glbTestRecords[x].ItemRate);
                        }
                    }
                    setRefundAmount(refndAmount);
                    openModal();
                    // setTimeout(() => { openModal(); }, 100);
                }
                // setIsLoading(false);
            }
            else { APIError(data.Data, data.ErrorList.length === 0 ? "" : data.ErrorList[0].errorMsg); }
        }).catch(function (error) { if (error.response) { AxiosError(error.response.status); } });
    }



    const GetReportData = async (fromDateSelected, toDateSelected, PageNo, PageSize) => {
        setDownloadVisible(false);
        const ObjServiceCollection = {
            UserId: localStorage.getItem('LoggedInUser'),
            FromDate: fromDateSelected,
            ToDate: toDateSelected,
            PageNo: `${PageNo}`,
            PageSize: `${PageSize}`,
            IsExcelD: `${1}`,
            RefundStatus: refStatustype.current.value,
            CentreId:localStorage.CentreSNo,
            LoginId:localStorage.LoggedInUser
        };
        try {
            let url = FrontDesk_URL + 'reports/GetRefundStatusReport'
            await axios.post(url, ObjServiceCollection).then((response) => {
                const data = response.data;
                if (data.Success) {
                    if (data.Data != null && data.Data.RptRefundStatusList.length > 0) {
                        setDownloadVisible(true);
                        setCSVData(data.Data.RptRefundStatusList);
                        document.getElementById("csvdownload").click();
                    }
                    else { setCSVData([]); }
                }
            }).catch(function (error) { });
        }
        catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    }

    const paginationOptions = {
        totalSize: tableData.TotalRecord,
        sizePerPage: tableData.PageSize,
        showTotal: true,
        firstPageText: 'First',
        prePageText: 'Back',
        nextPageText: 'Next',
        lastPageText: 'Last',
        onPageChange: (page, sizePerPage) => { setCrntPageNO(((page - 1) * sizePerPage)); BindGrid(fromDate.current.value, toDate.current.value, page, sizePerPage); },
        onSizePerPageChange: (sizePerPage, page, event) => { BindGrid(fromDate.current.value, toDate.current.value, page, sizePerPage); document.getElementsByClassName('dropdown-menu show')[0].classList.remove('show'); },
    };
   
    const actionFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                {
                    (rowData.StatusCheck == "True" || rowData.StatusCheck == "1") ? null :
                        <ToolTip title="Check Visits" placement="top" onClick={() => GetVisits(rowData)} linkId="View" faName="fa fa-eye mr-2"></ToolTip>
                }
            </div>
        </React.Fragment>
    }
    const columns = [{ dataField: '#', text: '#', editable: false, formatter: idFormatter, headerStyle: (colum, colIndex) => { return { width: '5%' }; } },
    { dataField: 'VisitId', text: 'VisitId', editable: false },
    { dataField: 'InvoiceNo', text: 'Invoice No.', editable: false },
    { dataField: 'Patient', text: 'Patient', editable: false },
    { dataField: 'Mobile', text: 'Mobile No.', editable: false },
    { dataField: 'ClientCode', text: 'Client Code', editable: false },
    { dataField: 'ClientName', text: 'Client Name', editable: false },
    { dataField: 'Status', text: 'Status', editable: false },
    { dataField: 'actions', text: 'Actions', formatter: actionFormatter, editable: false, headerStyle: (colum, colIndex) => { return { width: '7%' }; } }
    ];

    

    const handleSearch = async () => {
        BindGrid(fromDate.current.value, toDate.current.value, tableData.PageNo, tableData.PageSize);
    }
    const handleDownload = async () => {
        GetReportData(fromDate.current.value, toDate.current.value, tableData.PageNo, tableData.PageSize);
    }

    const AxiosError = async (ErrorCode) => {
        if (ErrorCode === 400) { SweetAlert.fire({ title: "Error 400", text: "Bad request", icon: "error" }) }
        else if (ErrorCode === 401) { SweetAlert.fire({ title: "Error 401", text: "Unauthorized", icon: "error" }) }
        else if (ErrorCode === 403) { SweetAlert.fire({ title: "Error 403", text: "Forbidden", icon: "error" }) }
        else if (ErrorCode === 404) { SweetAlert.fire({ title: "Error 404", text: "Page not found", icon: "error" }) }
        else if (ErrorCode === 500) { SweetAlert.fire({ title: "Error 500", text: "Internal Server Error", icon: "error" }) }
        else if (ErrorCode === 503) { SweetAlert.fire({ title: "Error 503", text: "Service Unavailable", icon: "error" }) }
    }
    const APIError = async (ErrorCode, ErrorMsg) => {
        if (ErrorCode === "-1") { SweetAlert.fire({ title: "Data unavailable", text: "No record found", icon: "error" }) }
        else if (ErrorCode === "-2") { SweetAlert.fire({ title: "API Server Error", text: ErrorMsg, icon: "error" }); }
        else if (ErrorCode === "-3") { SweetAlert.fire({ title: "Runtime Exception", text: "Some error occured! Please try after sometime.", icon: "error" }) }
        else if (ErrorCode === "-4") { SweetAlert.fire({ title: "Wrong Credentials", text: "Please enter correct credentials", icon: "error" }) }
        else if (ErrorCode === "-5") { SweetAlert.fire({ title: "Oops", text: "Data could not be saved, Please try again later", icon: "error" }) }
        else if (ErrorCode === "-6") { SweetAlert.fire({ title: "Failed", text: "Check DB Logs", icon: "error" }) }
        else if (ErrorCode === "-7") { SweetAlert.fire({ title: "Duplicate", text: "Template already exists", icon: "error" }) }
        else if (ErrorCode === "-8") { SweetAlert.fire({ title: "Data unavailable", text: "No record found to import", icon: "error" }) }
        else if (ErrorCode === "-9") { SweetAlert.fire({ title: "LDAP authentication failed", text: ErrorMsg, icon: "error" }) }
        else { SweetAlert.fire({ title: "Application Exception", text: "Some error occured! Please try after sometime", icon: "error" }) }
    }
    return (
        <React.Fragment>
             <Breadcrumb title="Refund Status" parent="Reports" /> 
            <Container fluid>
                <Row className='mt25'>
                    <Col md={12}>
                        <Card>
                            <Card.Body>
                                <Form.Row>
                                    <Col md={4}>
                                        <Form.Group>
                                            <Form.Label>From Date</Form.Label>
                                            <InputGroup className="mb-3 d-flex justify-content-start">
                                                <Form.Control type="date" id="fromDate" name="fromDate" ref={fromDate} defaultValue={defaultDate} />
                                            </InputGroup>
                                        </Form.Group>
                                    </Col>
                                    <Col md={4}>
                                        <Form.Group>
                                            <Form.Label>To Date</Form.Label>
                                            <InputGroup className="mb-3 d-flex justify-content-start">
                                                <Form.Control type="date" id="toDate" name="toDate" ref={toDate} defaultValue={defaultDate} />
                                            </InputGroup>
                                        </Form.Group>
                                    </Col>
                                    <Col md={4}>
                                        <Form.Group>
                                            <Form.Label>Status</Form.Label>
                                            <Form.Control as="select" name="Status" id="RefStatus" ref={refStatustype} >
                                                <option key="-1" value="-1">---Select All---</option>
                                                <option key="1" value="1">Approved</option>
                                                <option key="2" value="2">Rejected</option>
                                                <option key="0" value="0">Pending</option>
                                            </Form.Control>
                                        </Form.Group>
                                    </Col>
                                   
                                     <Col md={12} className='text-center __center__buttonsec'>
                                        <Form.Group>
                                        <Button variant='secondary' onClick={handleSearch}>Search</Button>
                                        <Button className='ml-2' variant='primary' onClick={handleDownload}>Download CSV</Button>
                                     </Form.Group>
                                    </Col>
                                    <Col lg={12} md={12} className='TableDynamic'>
                                        {
                                            <DynamicDataTable
                                                keyField='Id'
                                                tableData={tableData.Rows}
                                                columns={columns}
                                                noDataIndication={NoRecordFounds}
                                                pagination={paginationOptions ? paginationFactory(paginationOptions) : null}
                                            />
                                        }
                                    </Col>
                                </Form.Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                {
                    downloadvisible &&
                    <CSVLink data={CSVData} filename="RefundStatusReport.csv" target="_blank" id="csvdownload"></CSVLink>
                }

{testsModal &&
                    <Modal isOpen={testsModal} toggle={openModal} centered={true} size="lg">
                        <ModalHeader toggle={openModal}>Refund Status List</ModalHeader>
                        <ModalBody>
                            <>
                                <Row>
                                    <Col md={12}>
                                        <Card>
                                            <Card.Body>
                                                <div className='row align-items-center'>
                                                    <div className='col-md-10 order-2 order-md-1'>
                                                        <p><b>Name:</b>{patientName}, <b>Visit Id:</b> {visitNo}</p>
                                                    </div>
                                                </div>
                                                {ORgRefundAfStatusList!=null && ORgRefundAfStatusList.length>0?
                                              <>
                                          <div className="table-responsive mb15">
                  <table className="table">
                 <thead className="thead-light">
                     <tr>
                         <th scope="col">#</th>
                         <th scope="col">Visting Code</th>
                         <th scope="col">Invoice No</th>
                         <th scope="col">Order No</th>
                         <th scope="col">Order Date</th>
                         <th scope="col">Billing Type</th>
                         <th scope="col">Paid Amount</th>
                     </tr>
                 </thead>
                 <tbody>
                     {

                      (ORgRefundAfStatusList==null || ORgRefundAfStatusList==undefined ||  ORgRefundAfStatusList=='')
                      ?<tr><td colSpan={7} style={{ textAlign: 'center', color: 'red' }}>
                      <h5>No record Found</h5>
                      </td></tr>:ORgRefundAfStatusList.map((tb,index) => {
                          return (
                              <>
                                  <tr key={tb.VistingCode}>
                                      <th>{parseInt(index+1)}</th>
                                      <td>{tb.VistingCode}</td>
                                      <td>{tb.InvoiceNo}</td>
                                      <td>{tb.OrderNo}</td>
                                      <td>{tb.OrderDate}</td>
                                      <td>{tb.BillingType}</td>
                                      <td>{tb.PaidAmount}</td>
                                  </tr>
                              </>
                          )
                      })}
               

                 </tbody>
             </table>
         </div></>:null
                         }

                                                {
                                                    isLoading ?
                                                        <div className='text-center'> <Spinner animation="border" size='sm' /></div> :
                                                        <>
                                                            <DataTables keyField='id' tableData={visitsTableData} columns={testsColumns} />
                                                        </>
                                                }
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                </Row>
                                
                            </>
                            :
                            <Row>
                            </Row>
                        </ModalBody>
                    </Modal>
                }
            </Container>
        </React.Fragment>
    )
}

export default RefundStatus