import React, { useEffect, useState, useRef } from 'react';
import Breadcrumb from '../../components/common/breadcrumb';
import { FrontDesk_URL } from '../../constant';
import { Container, Row, Col, Card, Form, Button } from 'react-bootstrap';
import DataTables from '../commonComponent/DataTable/DataTables';
import NoRecordFounds from '../commonComponent/DataTable/NoRecordFounds';
import axios from 'axios';
import SweetAlert from 'sweetalert2';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { CSVLink } from "react-csv";
import * as XLSX from 'xlsx';
import InputGroup from 'react-bootstrap/InputGroup';
import { MultiSelect } from 'react-multi-select-component';
//import '../../../pages/frontOffice/react-multiselect.css';
import Select from 'react-select';
import moment from 'moment';
let GlbExpFilenameDownload="Cumulative_ClientWiseReport.csv";
const BusinessData = () => {
    //const [LoginId, setLoginId] = useState(localStorage.LoggedInUser == undefined ? "" : localStorage.LoggedInUser);
    // alert(localStorage.getItem('LoggedInUser'));
    let CurrentDateTime = moment()
    .format('YYYY_MM_DD|hh_mm_ss_a');
    let CurrentDateUSe = moment()
      .format('YYYY-MM-DD');
      let CurrentMonthUse = moment()
      .format('MMMM-YYYY');
      let TodayUse = moment()
      .format('YYYY_MMM_DD');
    let fromDate = useRef();
    let toDate = useRef();
    const [statesList, setStatesList] = useState([]);
    const [RegionsList, setRegionsList] = useState([]);
    const [CentreType, setCentreType] = useState([]);
    const [table, setTable] = useState([]);
    const [crntPageNO, setCrntPageNO] = useState(0);
    const [downloadvisible, setDownloadVisible] = useState(false);
    const [BusinessDatalistCSV, setBusinessDatalistCSV] = useState([]);
    const[ReportOption,SetReportOption]=useState("1");
    const[ExpFilenameDownload,SetExpFilenameDownload]=useState("Cumulative_ClientWiseReport.csv");
    const today = new Date();
    const date = today.setDate(today.getDate());
    const defaultValue = new Date(date).toISOString().split('T')[0] // yyyy-mm-dd
    const [defaultDate, setDefaultDate] = useState(defaultValue);
    let refstate = useRef();
    let refregion = useRef();
    let refcentertype = useRef();
    const[ReportType,SetReportType]=useState("1");
    const[DateFilter,SetDateFilter]=useState("1");
    const [DateVisible, setDateVisible] = useState(true);
    const [MonthDateWise, SetMonthDateWise] = useState(true);
    const [SelectCentreDDL, SetSelectCentreDDL] = useState([]);
    const [SelectCentres, SetSelectCentres] = useState([]);
    const [LGlCentreTypeoption, SetLGlCentreTypeoption] = useState([]);
    const [SelectBusinessUNTDDL, SetSelectBusinessUNTDDL] = useState([]);
    const [SelectBusinessType, SetSelectBusinessType] = useState([]);
    const [LGlSelectBusUNTDDLTypeoption, SetLGlSelectBusUNTDDLTypeoption] = useState([]);
    const [SelectCentreTypeDDL, SetSelectCentreTypeDDL] = useState([]);
    const [SelectCentreType, SetSelectCentreType] = useState([]);
    const [LGlSelectCentreTypeoption, SetLGlSelectCentreTypeoption] = useState([]);
    const [SelectDepartmentDDL, SetSelectDepartmentDDL] = useState([]);
    const [SelectDepartment, SetSelectDepartment] = useState([]);
    const [LGlSelectDepartmentTypeoption, SetLGlSelectDepartmentTypeoption] = useState([]);
    const [SelectTestDDL, SetSelectTestDDL] = useState([]);
    const [SelectTest, SetSelectTest] = useState([]);
    const [LGlSelectTestTypeoption, SetLGlSelectTestTypeoption] = useState([]);
    const [TestWise, SetTestWise] = useState(false);
    const [DoctorRefWise, SetDoctorRefWise] = useState(false);
    const[GroupOptions,SetGroupOptions]=useState(false);
    const[GroupOptChecked,SetGroupOptChecked]=useState(false);
    const[VisitVal,SetVisitVal]=useState("0");
    useEffect(() => {
        const CentreTypeL = [];
        GetBindCenterAllLists(CentreTypeL);
        GetBusinessList();
        GetCentreType();
        GetDepartmentList();
        GetBindTestAllLists(CentreTypeL);
    }, []);

    const GetBusinessClientCumulativeCSV = async () => {
        const  Objbusiness= {
            FromDate:DateFilter=="1"?fromDate.current.value:'',
            ToDate:DateFilter=="1"?toDate.current.value:'',
            UserID:localStorage.getItem('LoggedInUser'),
            DateFilterFlag:DateFilter,
            objtblTagBusinessIds:LGlSelectBusUNTDDLTypeoption,
            objtblCentreIds:LGlCentreTypeoption,
            objtblDepartmentIDs:LGlSelectDepartmentTypeoption,
            objtblTestIDs:LGlSelectTestTypeoption,
            VisitTypeID:VisitVal   
        };
        try {
            let url = FrontDesk_URL + 'reports/GETCumulativeClientWiseReports'
            await axios.post(url,Objbusiness).then((response) => {
                const data = response.data;
                if (data.Success) {
                    if (data.Data != null  && data.Data.length>0 ) {
                        setDownloadVisible(true);
                        var newarr = [];
                        // for (var key in data.Data) {
                        //     newarr.push({
                        //         CentreName: data.Data[key].CentreName,
                        //         RateTemplateName: data.Data[key].RateTemplateName,
                        //         FromDate: data.Data[key].FromDate,
                        //         ToDate: data.Data[key].ToDate,
                        //         IsActive: data.Data[key].IsActive,
                        //         IsDefaultTemplate: data.Data[key].IsDefaultTemplate,
                        //         ActiveDays: data.Data[key].ActiveDays
                        //     });
                        // }
                        //setCentreRatelistCSV(newarr);
                        setBusinessDatalistCSV(data.Data);
                        document.getElementById("csvdownload").click();
                    }
                    else { setDownloadVisible(false);
                        SweetAlert.fire({ title: "Warning", text: "no records found", icon: "info" });
                    }
                }
            }).catch(function (error) { });
        }
        catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    }
    const GetBusinessDepartmentCumulativeCSV = async () => {
        const  Objbusiness= {
            FromDate:DateFilter=="1"?fromDate.current.value:'',
            ToDate:DateFilter=="1"?toDate.current.value:'',
            UserID:localStorage.getItem('LoggedInUser').toString(),
            DateFilterFlag:DateFilter,
            objtblTagBusinessIds:LGlSelectBusUNTDDLTypeoption,
            objtblCentreIds:LGlCentreTypeoption,
            objtblDepartmentIDs:LGlSelectDepartmentTypeoption,
            objtblTestIDs:LGlSelectTestTypeoption,
            VisitTypeID:VisitVal,
            ClientWise:GroupOptChecked==true?"1":"0"
        };
        try {
            let url = FrontDesk_URL + 'reports/GETCumulativeDepartmentWiseReports'
            await axios.post(url,Objbusiness).then((response) => {
                const data = response.data;
                if (data.Success) {
                    if (data.Data != null && data.Data.length>0) {
                        setDownloadVisible(true);
                        setBusinessDatalistCSV(data.Data);
                        document.getElementById("csvdownload").click();
                    }
                    else { setDownloadVisible(false);
                        SweetAlert.fire({ title: "Warning", text: "no records found", icon: "info" });
                    }
                }
            }).catch(function (error) { });
        }
        catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    }
    const GetBusinessDoctorRefCumulativeCSV = async () => {
        const  Objbusiness= {
            FromDate:DateFilter=="1"?fromDate.current.value:'',
            ToDate:DateFilter=="1"?toDate.current.value:'',
            UserID:localStorage.getItem('LoggedInUser').toString(),
            DateFilterFlag:DateFilter,
            objtblTagBusinessIds:LGlSelectBusUNTDDLTypeoption,
            objtblCentreIds:LGlCentreTypeoption,
            objtblDepartmentIDs:LGlSelectDepartmentTypeoption,
            objtblTestIDs:LGlSelectTestTypeoption,
            VisitTypeID:VisitVal
        };
        try {
            let url = FrontDesk_URL + 'reports/GETCumulativeDoctorRefWiseReports'
            await axios.post(url,Objbusiness).then((response) => {
                const data = response.data;
                if (data.Success) {
                    if (data.Data != null && data.Data.length>0) {
                        setDownloadVisible(true);
                        setBusinessDatalistCSV(data.Data);
                        document.getElementById("csvdownload").click();
                    }
                    else { setDownloadVisible(false);
                        SweetAlert.fire({ title: "Warning", text: "no records found", icon: "info" });
                    }
                }
            }).catch(function (error) { });
        }
        catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    }
    const GetBusinessTestCumulativeCSV = async () => {
        const  Objbusiness= {
            FromDate:DateFilter=="1"?fromDate.current.value:'',
            ToDate:DateFilter=="1"?toDate.current.value:'',
            UserID:localStorage.getItem('LoggedInUser').toString(),
            DateFilterFlag:DateFilter,
            objtblTagBusinessIds:LGlSelectBusUNTDDLTypeoption,
            objtblCentreIds:LGlCentreTypeoption,
            objtblDepartmentIDs:LGlSelectDepartmentTypeoption,
            objtblTestIDs:LGlSelectTestTypeoption,
            VisitTypeID:VisitVal,
            ClientWise:GroupOptChecked==true?"1":"0"
        };
        try {
            let url = FrontDesk_URL + 'reports/GETCumulativeTestWiseReports'
            await axios.post(url,Objbusiness).then((response) => {
                const data = response.data;
                if (data.Success) {
                    if (data.Data != null && data.Data.length>0) {
                        setDownloadVisible(true);
                        setBusinessDatalistCSV(data.Data);
                        document.getElementById("csvdownload").click();
                    }
                    else { setDownloadVisible(false);
                        SweetAlert.fire({ title: "Warning", text: "no records found", icon: "info" });
                    }
                }
            }).catch(function (error) { });
        }
        catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    }
    const GetBusinessMonthDateClientCSV = async () => {
        const UserID = localStorage.getItem('LoggedInUser').toString();
        const  Objbusiness= {
            FromDate:fromDate.current.value,
            ToDate:toDate.current.value,
            UserID:localStorage.getItem('LoggedInUser').toString(),
            MonthDateWise:ReportType=="2"?"2":"1",
            objtblTagBusinessIds:LGlSelectBusUNTDDLTypeoption,
            objtblCentreIds:LGlCentreTypeoption,
            objtblDepartmentIDs:LGlSelectDepartmentTypeoption,
            objtblTestIDs:LGlSelectTestTypeoption,
            VisitTypeID:VisitVal   
        };
        try {
            let url = FrontDesk_URL + 'reports/GETBindMonthDateClientWiseReports'
            await axios.post(url,Objbusiness).then((response) => {
                const data = response.data;
                if (data.Success) {
                    if (data.Data != null  && data.Data.length>0 ) {
                        
                         if(data.Data=="1")
                         {
                            setDownloadVisible(false);
                            SweetAlert.fire({ title: "Warning", text: "Date Wise Trend(Not more than 31 days)", icon: "info" });

                         }
                       else  if(data.Data=="2")
                         {
                            setDownloadVisible(false);
                            SweetAlert.fire({ title: "Warning", text: "Month Wise Trend(Not more than 12 month)", icon: "info" });

                         }
                         else  if(data.Data=="3")
                         {
                            setDownloadVisible(false);
                            SweetAlert.fire({ title: "Warning", text: "Please choose vaild from date and to date", icon: "info" });

                         }
                          else{
                        setDownloadVisible(true);
                        var newarr = [];
                        // for (var key in data.Data) {
                        //     newarr.push({
                        //         CentreName: data.Data[key].CentreName,
                        //         RateTemplateName: data.Data[key].RateTemplateName,
                        //         FromDate: data.Data[key].FromDate,
                        //         ToDate: data.Data[key].ToDate,
                        //         IsActive: data.Data[key].IsActive,
                        //         IsDefaultTemplate: data.Data[key].IsDefaultTemplate,
                        //         ActiveDays: data.Data[key].ActiveDays
                        //     });
                        // }
                        //setCentreRatelistCSV(newarr);
                        setBusinessDatalistCSV(data.Data);
                        document.getElementById("csvdownload").click();
                    }
                    }
                    else { setDownloadVisible(false);
                        SweetAlert.fire({ title: "Warning", text: "no records found", icon: "info" });
                    }
                }
            }).catch(function (error) { });
        }
        catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    }
    const GetBusDepartmentMonthDateClientCSV = async () => {
        const  Objbusiness= {
            FromDate:fromDate.current.value,
            ToDate:toDate.current.value,
            UserID:localStorage.getItem('LoggedInUser').toString(),
            MonthDateWise:ReportType=="2"?"2":"1",
            objtblTagBusinessIds:LGlSelectBusUNTDDLTypeoption,
            objtblCentreIds:LGlCentreTypeoption,
            objtblDepartmentIDs:LGlSelectDepartmentTypeoption,
            objtblTestIDs:LGlSelectTestTypeoption,
            VisitTypeID:VisitVal,
            ClientWise:GroupOptChecked==true?"1":"0"   
        };
        try {
            let url = FrontDesk_URL + 'reports/GETBindDepartmentMonthDateClientWiseReports'
            await axios.post(url,Objbusiness).then((response) => {
                const data = response.data;
                if (data.Success) {
                    if (data.Data != null  && data.Data.length>0 ) {
                        
                         if(data.Data=="1")
                         {
                            setDownloadVisible(false);
                            SweetAlert.fire({ title: "Warning", text: "Date Wise Trend(Not more than 31 days)", icon: "info" });

                         }
                       else  if(data.Data=="2")
                         {
                            setDownloadVisible(false);
                            SweetAlert.fire({ title: "Warning", text: "Month Wise Trend(Not more than 12 month)", icon: "info" });

                         }
                         else  if(data.Data=="3")
                         {
                            setDownloadVisible(false);
                            SweetAlert.fire({ title: "Warning", text: "Please choose vaild from date and to date", icon: "info" });

                         }
                          else{
                        setDownloadVisible(true);
                        setBusinessDatalistCSV(data.Data);
                        document.getElementById("csvdownload").click();
                    }
                    }
                    else { setDownloadVisible(false);
                        SweetAlert.fire({ title: "Warning", text: "no records found", icon: "info" });
                    }
                }
            }).catch(function (error) { });
        }
        catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    }
    
      const GetBusTestWiseDateClientCSV = async () => {
        const  Objbusiness= {
            FromDate:fromDate.current.value,
            ToDate:toDate.current.value,
            UserID:localStorage.getItem('LoggedInUser').toString(),
            MonthDateWise:ReportType=="2"?"2":"1",
            objtblTagBusinessIds:LGlSelectBusUNTDDLTypeoption,
            objtblCentreIds:LGlCentreTypeoption,
            objtblDepartmentIDs:LGlSelectDepartmentTypeoption,
            objtblTestIDs:LGlSelectTestTypeoption,
            VisitTypeID:VisitVal,
            ClientWise:GroupOptChecked==true?"1":"0"   
        };
        try {
            let url = FrontDesk_URL + 'reports/GETBindTestWiseMonthDateClientWiseReports'
            await axios.post(url,Objbusiness).then((response) => {
                const data = response.data;
                if (data.Success) {
                    if (data.Data != null  && data.Data.length>0 ) {
                        
                         if(data.Data=="1")
                         {
                            setDownloadVisible(false);
                            SweetAlert.fire({ title: "Warning", text: "Date Wise Trend(Not more than 31 days)", icon: "info" });

                         }
                       else  if(data.Data=="2")
                         {
                            setDownloadVisible(false);
                            SweetAlert.fire({ title: "Warning", text: "Month Wise Trend(Not more than 12 month)", icon: "info" });

                         }
                         else  if(data.Data=="3")
                         {
                            setDownloadVisible(false);
                            SweetAlert.fire({ title: "Warning", text: "Please choose vaild from date and to date", icon: "info" });

                         }
                          else{
                        setDownloadVisible(true);
                        setBusinessDatalistCSV(data.Data);
                        document.getElementById("csvdownload").click();
                    }
                    }
                    else { setDownloadVisible(false);
                        SweetAlert.fire({ title: "Warning", text: "no records found", icon: "info" });
                    }
                }
            }).catch(function (error) { });
        }
        catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    }
    const GetBusDoctorRefDateClientCSV = async () => {
        const  Objbusiness= {
            FromDate:fromDate.current.value,
            ToDate:toDate.current.value,
            UserID:localStorage.getItem('LoggedInUser').toString(),
            MonthDateWise:ReportType=="2"?"2":"1",
            objtblTagBusinessIds:LGlSelectBusUNTDDLTypeoption,
            objtblCentreIds:LGlCentreTypeoption,
            objtblDepartmentIDs:LGlSelectDepartmentTypeoption,
            objtblTestIDs:LGlSelectTestTypeoption,
            VisitTypeID:VisitVal   
        };
        try {
            let url = FrontDesk_URL + 'reports/GETBindDoctorRefMonthDateClientWiseReports'
            await axios.post(url,Objbusiness).then((response) => {
                const data = response.data;
                if (data.Success) {
                    if (data.Data != null  && data.Data.length>0 ) {
                        
                         if(data.Data=="1")
                         {
                            setDownloadVisible(false);
                            SweetAlert.fire({ title: "Warning", text: "Date Wise Trend(Not more than 31 days)", icon: "info" });

                         }
                       else  if(data.Data=="2")
                         {
                            setDownloadVisible(false);
                            SweetAlert.fire({ title: "Warning", text: "Month Wise Trend(Not more than 12 month)", icon: "info" });

                         }
                         else  if(data.Data=="3")
                         {
                            setDownloadVisible(false);
                            SweetAlert.fire({ title: "Warning", text: "Please choose vaild from date and to date", icon: "info" });

                         }
                          else{
                        setDownloadVisible(true);
                        setBusinessDatalistCSV(data.Data);
                        document.getElementById("csvdownload").click();
                    }
                    }
                    else { setDownloadVisible(false);
                        SweetAlert.fire({ title: "Warning", text: "no records found", icon: "info" });
                    }
                }
            }).catch(function (error) { });
        }
        catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    }
    const handleSearchList = async () => {
        if(SelectCentres == null || SelectCentres.length == 0)
        {
            SweetAlert.fire({ title: "Warning", text: "client selection is required", icon: "info" });
        }
         else{
            //  FromDate:DateFilter=="1"?fromDate.current.value:'',
            //     ToDate:DateFilter=="1"?toDate.current.value:'',

                let filenameCust="";
                 if(DateFilter=="1")
                 {
                    let  dateFull='From_'+moment(fromDate.current.value).format('DD_MMM_YYYY')+'_'+'TO_'+moment(toDate).format('DD_MMM_YYYY')+'|Print_'+CurrentDateTime;
                    filenameCust= dateFull;
                 }
                  else if(DateFilter=="2")
                  {
                    //Currentmonth
                    let  dateFull=CurrentMonthUse+'|Print_'+CurrentDateTime;
                    filenameCust= dateFull;

                  }
                  else if(DateFilter=="3")
                  {
                    //Today
                    let  dateFull=TodayUse+'|Print_'+CurrentDateTime;
                    filenameCust= dateFull;

                  }
             if (ReportType=="1")
             {
            if(ReportOption=="1")
            {
               SetExpFilenameDownload("Cumulative_ClientWiseReport"+filenameCust+".csv");
               GetBusinessClientCumulativeCSV();
            }
            else if(ReportOption=="2")
            { 
               SetExpFilenameDownload("Cumulative_DepartmentWiseReport"+filenameCust+".csv");
               GetBusinessDepartmentCumulativeCSV();
            }
            else if(ReportOption=="3")
            {
               SetExpFilenameDownload("Cumulative_TestWiseReport"+filenameCust+".csv");
               GetBusinessTestCumulativeCSV();
            }
            else if(ReportOption=="4")
            {
               SetExpFilenameDownload("Cumulative_DoctorRefWiseReport"+filenameCust+".csv");
               GetBusinessDoctorRefCumulativeCSV();
            }
            }
              else  if(ReportType=="2")
              {
                if(ReportOption=="1")
                {
                   SetExpFilenameDownload("MonthWise_ClientWiseReport"+filenameCust+".csv");
                   GetBusinessMonthDateClientCSV();
                }
                else if(ReportOption=="2")
                { 
                   SetExpFilenameDownload("MonthWise_DepartmentWiseReport"+filenameCust+".csv");
                   GetBusDepartmentMonthDateClientCSV();
                }
                else if(ReportOption=="3")
                {
                   SetExpFilenameDownload("MonthWise_TestWiseReport"+filenameCust+".csv");
                   GetBusTestWiseDateClientCSV();
                }
                else if(ReportOption=="4")
                {
                   SetExpFilenameDownload("MonthWise_DoctorRefWiseReport"+filenameCust+".csv");
                   GetBusDoctorRefDateClientCSV();
                }
              }
               else{
                if(ReportOption=="1")
                {
                   SetExpFilenameDownload("DateWise_ClientWiseReport"+filenameCust+".csv");
                   GetBusinessMonthDateClientCSV();
                }
                else if(ReportOption=="2")
                { 
                   SetExpFilenameDownload("DateWise_DepartmentWiseReport"+filenameCust+".csv");
                   GetBusDepartmentMonthDateClientCSV();
                }
                else if(ReportOption=="3")
                {
                   SetExpFilenameDownload("DateWise_TestWiseReport"+filenameCust+".csv");
                   GetBusTestWiseDateClientCSV();
                }
                else if(ReportOption=="4")
                {
                   SetExpFilenameDownload("DateWise_DoctorRefWiseReport"+filenameCust+".csv");
                   GetBusDoctorRefDateClientCSV();
                }
               }
         }
        
          
       
        //GetTemplateListCSV(refstate.current.value, refregion.current.value, refcentertype.current.value);
    }
    const handleReportOptionRadioChange = async (e) => { 
        SetSelectTest([]); 
        SetLGlSelectTestTypeoption([]);
        SetSelectDepartment([]);
        SetLGlSelectDepartmentTypeoption([]);
            SetGroupOptChecked(false);
            GetBindTestAllLists([]);
         const val=e.target.value;
          if(val=="3")
          {
            SetTestWise(true);
          }
           else{
            SetTestWise(false);
           }
           if(val=="1"|| val=="4")
           {
            SetGroupOptions(false);
            SetDoctorRefWise(false);
           }
            else{
                SetGroupOptions(true);
                SetDoctorRefWise(true);
            }

        SetReportOption(e.target.value); 
       
       
        // BindGrid(e.target.value);
     }
      const handleReportTypeRadioChange=async(e)=>{
        const val=e.target.value;
        document.getElementById("datefilter0").click();
        if(val=="2"|| val=="3" )
         {
            SetMonthDateWise(false);
         }
          else{
            SetMonthDateWise(true);
          }
        SetReportType(e.target.value);
      }
      const handleDateFilterRadioChange=async(e)=>{
         const val=e.target.value;
        SetDateFilter(val);
     if(val=="1")
      {
        setDateVisible(true);
      }
       else{
        setDateVisible(false);
       }       
      }
      const  GetBindCenterAllLists= async (TypeOptL) => {
        const  filterCentreTypeOpsList=TypeOptL;
        const UserID = localStorage.getItem('LoggedInUser').toString();
        const  ObjDataCentrelList= {
            filterCentreTypeOpsList,
            UserID
        };
        try {
            //let url = FrontDesk_URL + 'master/getBindAllCentreLists'
            let url = FrontDesk_URL + 'master/getBindAllCentreLists_UserWise'
            //await axios.post(url,ObjFilterCentye).then((response) => {
            await axios.post(url,ObjDataCentrelList).then((response) => {
            //await axios.post(url,ObjDataCentrelList).then((response) => {
                const data = response.data;
                if (data.Success) {
                    if (data.Data != null) {
                         let ds=data.Data;
                         const TypeOptionDs1 = [];
                        ds.map((option) => {
                            const TypeOptions1 = {
                                'CentreId': option.CentreId,
                                'label': option.CentreName,
                                'value': option.CentreId,
                                'CenterTypeId': option.CenterTypeId,
                                'disabled': false
                            }
                            return TypeOptionDs1.push(TypeOptions1);
                        });
                        SetSelectCentreDDL(TypeOptionDs1); 
                       
                    }
                    else {  SetSelectCentreDDL([]); }
                }
            }).catch(function (error) {
                //ErrorFunction(error.response.status);
                SetSelectCentreDDL([]);
             });
        }
        catch (error) {
            SetSelectCentreDDL([]);
            SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    }
   
    const CentreHandler = (options) => {

        SetSelectCentres(options);
        const TypeOptL = [];
        if (options != null && options.length > 0) {
            options.map((item) => {
                const TypeOptrows = {
                    'CentreId': item.CentreId
                }
                return TypeOptL.push(TypeOptrows);
            });
          SetLGlCentreTypeoption(TypeOptL);

        }
         else{
            SetLGlCentreTypeoption([]);  
         }
    }
    const GetBusinessList = async () => {

        try {
            let url = FrontDesk_URL + 'master/getlabslist_UserWise/' + localStorage.getItem('LoggedInUser')
            await axios.get(url).then((response) => {
                const data = response.data;
                if (data.Success) {
                    if (data.Data != null) {
                        let ds=data.Data;
                        const TypeOptionDs1 = [];
                       ds.map((option) => {
                           const TypeOptions1 = {
                            'CentreId': option.Id,
                            'label': option.Name,
                            'value': option.Id,
                            'disabled': false
                           }
                           return TypeOptionDs1.push(TypeOptions1);
                       });
                       SetSelectBusinessUNTDDL(TypeOptionDs1); 
                      
                   }
                   else {  SetSelectBusinessUNTDDL([]); }
                }
            }).catch(function (error) { });
        }
        catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    }

    const  BusinessTabHandler = (options) => {
        SetSelectBusinessType(options);
            const TypeOptL = [];
            if (options != null && options.length > 0) {
                options.map((item) => {
                    const TypeOptrows = {
                        'TagBusinessId': item.CentreId
                    }
                    return TypeOptL.push(TypeOptrows);
                });
                SetLGlSelectBusUNTDDLTypeoption(TypeOptL);
            }
            else{
                SetLGlSelectBusUNTDDLTypeoption([]);
            }
        }
       
    const GetCentreType = async () => {
            try {
                let url = FrontDesk_URL + 'master/GetPartnerTypeList_UserWise/' + + localStorage.getItem('LoggedInUser')
                await axios.get(url).then((response) => {
                    const data = response.data;
                    if (data.Success) { 
                        if (data.Data != null) {
                            let ds=data.Data;
                            const TypeOptionDs1 = [];
                           ds.map((option) => {
                               const TypeOptions1 = {
                                   'CenterTypeId': option.Id,
                                   'label': option.Name,
                                   'value': option.Id,
                                   'disabled': false
                               }
                               return TypeOptionDs1.push(TypeOptions1);
                           });
                           SetSelectCentreTypeDDL(TypeOptionDs1); 
                          
                       }
                       else {  SetSelectCentreTypeDDL([]); } }
                }).catch(function (error) { });
            }
            catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
        }
        const CentreTypeHandler = (options) => {
            SetSelectCentres([]);
            SetLGlCentreTypeoption([]);
            SetSelectCentreType(options);
            const TypeOptL = [];
            if (options != null && options.length > 0) {
                options.map((item) => {
                    const TypeOptrows = {
                        'CentreTypeId': item.CenterTypeId
                    }
                    return TypeOptL.push(TypeOptrows);
                });
              SetLGlSelectCentreTypeoption(TypeOptL);
              GetBindCenterAllLists(TypeOptL);
            }
            else{
                SetLGlSelectCentreTypeoption([]);
                GetBindCenterAllLists(TypeOptL); 
            }
        }
        const GetDepartmentList = async () => {
            try {
                let url = FrontDesk_URL + 'master/getBindAllDepartmentLists'
                await axios.get(url).then((response) => {
                    const data = response.data;
                    if (data.Success) { 
                        if (data.Data != null) {
                            let ds=data.Data;
                            const TypeOptionDs1 = [];
                           ds.map((option) => {
                               const TypeOptions1 = {
                                   'DeptId': option.DeptId,
                                   'label': option.DepartmentName,
                                   'value': option.DeptId,
                                   'Description':option.Description,
                                   'disabled': false
                               }
                               return TypeOptionDs1.push(TypeOptions1);
                           });
                           SetSelectDepartmentDDL(TypeOptionDs1); 
                          
                       }
                       else {  SetSelectDepartmentDDL([]); } }
                }).catch(function (error) { });
            }
            catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
        }
        const DepartmentHandler = (options) => {
            SetSelectTest([]); 
            SetLGlSelectTestTypeoption([]);
            SetSelectDepartment(options);
            const TypeOptL = [];
            if (options != null && options.length > 0) {
                options.map((item) => {
                    const TypeOptrows = {
                        'SubCategoryId': item.DeptId
                    }
                    return TypeOptL.push(TypeOptrows);
                });
              SetLGlSelectDepartmentTypeoption(TypeOptL);
              GetBindTestAllLists(TypeOptL);
            }
             else{
                SetLGlSelectDepartmentTypeoption([]);
                GetBindTestAllLists(TypeOptL);
             }
        }
        const  GetBindTestAllLists= async (DepartmentList) => {
            const  ObjFilterCentye=DepartmentList;
            try {
                let url = FrontDesk_URL + 'master/getBindAllTestLists'
                await axios.post(url,ObjFilterCentye).then((response) => {
                    const data = response.data;
                    if (data.Success) {
                        if (data.Data != null) {
                             let ds=data.Data;
                             const TypeOptionDs1 = [];
                            ds.map((option) => {
                                const TypeOptions1 = {
                                    'TestId': option.TestId,
                                    'label': option.TestName,
                                    'value': option.TestId,
                                    'DeptId': option.DeptId,
                                    'IsPackage':option.IsPackage,
                                    'disabled': false
                                }
                                return TypeOptionDs1.push(TypeOptions1);
                            });
                            SetSelectTestDDL(TypeOptionDs1); 
                           
                        }
                        else { SetSelectTestDDL([]); }
                    }
                }).catch(function (error) {
                    //ErrorFunction(error.response.status);
                    SetSelectTestDDL([]);
                 });
            }
            catch (error) {
                SetSelectTestDDL([]);
                SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
        }
        const TestAllHandler = (options) => {

            SetSelectTest(options);
            const TypeOptL = [];
            if (options != null && options.length > 0) {
                options.map((item) => {
                    const TypeOptrows = {
                        'TestID': item.TestId,
                        'IsPackage':item.IsPackage
                    }
                    return TypeOptL.push(TypeOptrows);
                });
              SetLGlSelectTestTypeoption(TypeOptL);
    
            }
             else{
                SetLGlSelectTestTypeoption([]);   
             }
        }
         const groupingoptionHandler=async(e)=>{
            const val=e.target.checked;
            SetGroupOptChecked(val);
         }
          const VisittypeOnChange=async(e)=>{
            const val=e.target.value;
            SetVisitVal(val);
          }
    return (
        <React.Fragment>
            <Breadcrumb title="Business Data" parent="Reports" />
            <Container fluid>
                 <Row className='mt25'>
                    <Col md={12}>
                        <Card>
                            <Card.Body>
                            <Form.Row>
                                    <Col md={6}>
                                        <Form.Label className='mb-4'><b>Report Type</b></Form.Label>
                                        <Form.Group className='animate-chk m-checkbox-inline center__type mt-15'>
                                            <Form.Label for="reportType0">
                                                <input class="radio_animated" type="radio" id='reportType0' name="rdooreporttype-ani" defaultChecked onChange={handleReportTypeRadioChange} value='1' />Cumulative
                                            </Form.Label>
                                            <Form.Label for="reportType1">
                                                <input class="radio_animated" type="radio" id='reportType1' name="rdooreporttype-ani" onChange={handleReportTypeRadioChange} value='2' />Month Wise Trend
                                            </Form.Label>
                                            <Form.Label for="reportType2">
                                                <input class="radio_animated" type="radio" id='reportType2' name="rdooreporttype-ani" onChange={handleReportTypeRadioChange} value='3' />Date Wise Trend
                                            </Form.Label>
                                        </Form.Group>
                                    </Col>
                                    <Col md={2} className='text-center __center__buttonsec mt15'>
                                    <Form.Group>
                                            <Form.Label>Visit Type</Form.Label>
                                            <Form.Control as="select"  name="dlistvisittype"  id="dlistvisittype"   onChange={VisittypeOnChange}  value={VisitVal} >
                                                <option key="0" value="0">---All---</option>
                                                <option key="1" value="1">Center Visit</option>
                                                <option key="2" value="2">Home Collection</option>
                                            </Form.Control>
                                        </Form.Group>
                                    </Col>
                                </Form.Row>
                                <Form.Row>
                                    <Col md={12}>
                                   <div className='mb-2'><b>Date Filter</b></div>
                                      
                                    </Col>
                                </Form.Row>
                                <Form.Row>
                                    <Col md={6}>
                                        <Form.Group className='animate-chk m-checkbox-inline center__type mt-15'>
                                            <Form.Label for="datefilter0">
                                                <input class="radio_animated" type="radio" id='datefilter0' name="rdoo-datefilter" defaultChecked onChange={handleDateFilterRadioChange} value='1' />Date
                                            </Form.Label>
                                            {MonthDateWise && <><Form.Label for="datefilter1">
                                                <input class="radio_animated" type="radio" id='datefilter1' name="rdoo-datefilter" onChange={handleDateFilterRadioChange} value='2' />Current Month
                                            </Form.Label>
                                            <Form.Label for="datefilter2">
                                                <input class="radio_animated" type="radio" id='datefilter2' name="rdoo-datefilter" onChange={handleDateFilterRadioChange} value='3' />Today
                                            </Form.Label></>}
                                            
                                        </Form.Group>
                                    </Col>
                                </Form.Row>
                                {DateVisible && <Form.Row>
                          
                          <Col md={3}>
                              <Form.Group>
                                  <Form.Label>From Date</Form.Label>
                                  <InputGroup className="mb-3 d-flex justify-content-start">
                                      <Form.Control type="date" id="fromDate" name="fromDate" ref={fromDate} defaultValue={defaultDate} />
                                  </InputGroup>
                              </Form.Group>
                          </Col>
                          <Col md={3}>
                              <Form.Group>
                                  <Form.Label>To Date</Form.Label>
                                  <InputGroup className="mb-3 d-flex justify-content-start">
                                      <Form.Control type="date" id="toDate" name="toDate" ref={toDate} defaultValue={defaultDate} />
                                  </InputGroup>
                              </Form.Group>
                          </Col>
                      </Form.Row> }
                      <Form.Row>
                                    <Col md={12}>
                                   <div className='mb-2'><b>Client Detail</b></div>
                                    </Col>
                                </Form.Row>
                                <Form.Row className="justify-content-left">
                                 <Col md={6} lg={6}>
                             <Form.Group>
                                <Form.Label>Tag Business Unit</Form.Label>
                                <div className='txtMultiSelect4'>
                                <MultiSelect id="ddlbussSelect" name="ddlbussSelect"  
                                    options={SelectBusinessUNTDDL}
                                    value={SelectBusinessType}
                                    onChange={BusinessTabHandler}
                                    labelledBy="Select" 
                                    
                                /></div>
                            </Form.Group>
                        </Col>
                        <Col md={4} lg={4}>
                             <Form.Group>
                                <Form.Label>Centre Type</Form.Label>
                                <div className='txtMultiSelect5'>
                                <MultiSelect id="CentreTypeSelect" name="CentreTypeSelect"
                                    options={SelectCentreTypeDDL}
                                    value={SelectCentreType}
                                    onChange={CentreTypeHandler}
                                    labelledBy="Select" 
                                /></div>
                            </Form.Group>
                        </Col>
                          </Form.Row>
                                <Form.Row className="justify-content-left">
                                 <Col md={6} lg={6}>
                             <Form.Group>
                                <Form.Label>Client<span className='text-required'>* required</span></Form.Label>
                                <div className='txtMultiSelect1'>
                                <MultiSelect id="CentreSelect" name="CentreSelect"
                                    options={SelectCentreDDL}
                                    value={SelectCentres}
                                    onChange={CentreHandler}
                                    labelledBy="Select"
                                /></div>
                                {/* {(SelectCentres == null || SelectCentres.length == 0)? (<div className='text-required'>{"Centre Selection is Required"}</div>) : null} */}
                            </Form.Group>
                        </Col>
                          </Form.Row>
                               
                            {/* <Form.Row>
                            <Col md={4}>
                                       
                                        <Form.Group className='animate-chk m-checkbox-inline center__type mt-15 mb-2'>
                                            <Form.Label for="reportType0">
                                                <input class="radio_animated" type="radio" id='reportType0' name="rdooreporttype-ani" defaultChecked onChange={handleReportTypeRadioChange} value='1' />Cumulative
                                            </Form.Label>
                                            </Form.Group>
                                            <Form.Group className='mb-2'>
                                            <Form.Label for="reportType1">
                                                <input class="radio_animated" type="radio" id='reportType1' name="rdooreporttype-ani" onChange={handleReportTypeRadioChange} value='2' />Month Wise Trend
                                            </Form.Label>
                                            </Form.Group>
                                            <Form.Group className='mb-2'>
                                            <Form.Label for="reportType2">
                                                <input class="radio_animated" type="radio" id='reportType2' name="rdooreporttype-ani" onChange={handleReportTypeRadioChange} value='3' />Date Wise Trend
                                            </Form.Label>
                                            </Form.Group>
                                       
                                    </Col>
                                    <Col md={3}>
                                        <Form.Group>
                                            <Form.Label>From Date</Form.Label>
                                            <InputGroup className="mb-3 d-flex justify-content-start">
                                                <Form.Control type="date" id="fromDate" name="fromDate" ref={fromDate} defaultValue={defaultDate} />
                                            </InputGroup>
                                        </Form.Group>
                                    </Col>
                                    <Col md={3}>
                                        <Form.Group>
                                            <Form.Label>To Date</Form.Label>
                                            <InputGroup className="mb-3 d-flex justify-content-start">
                                                <Form.Control type="date" id="toDate" name="toDate" ref={toDate} defaultValue={defaultDate} />
                                            </InputGroup>
                                        </Form.Group>
                                    </Col>
                                </Form.Row> */}
                            <Form.Row>
                                    <Col md={8}>
                                        <Form.Label className='mb-4'><b>Report Option</b></Form.Label>
                                        <Form.Group className='animate-chk m-checkbox-inline center__type mt-15'>
                                            <Form.Label for="reportOption0">
                                                <input class="radio_animated" type="radio" id='reportOption0' name="rdoo-ani" defaultChecked onChange={handleReportOptionRadioChange} value='1' />Client Wise
                                            </Form.Label>
                                            <Form.Label for="reportOption1">
                                                <input class="radio_animated" type="radio" id='reportOption1' name="rdoo-ani" onChange={handleReportOptionRadioChange} value='2' />Department Wise
                                            </Form.Label>
                                            <Form.Label for="reportOption2">
                                                <input class="radio_animated" type="radio" id='reportOption2' name="rdoo-ani" onChange={handleReportOptionRadioChange} value='3' />Test Wise
                                            </Form.Label>
                                            <Form.Label for="reportOption3">
                                                <input class="radio_animated" type="radio" id='reportOption3' name="rdoo-ani" onChange={handleReportOptionRadioChange} value='4' />Doctor Refer Wise
                                            </Form.Label>
                                        </Form.Group>
                                    </Col>
                                </Form.Row>
                                {DoctorRefWise &&<Form.Row className="justify-content-left">
                                 <Col md={4} lg={4}>
                             <Form.Group>
                                <Form.Label>Department</Form.Label>
                                <div className='txtMultiSelect2'><MultiSelect id="DepartmentSelect" name="DepartmentSelect"
                                    options={SelectDepartmentDDL}
                                    value={SelectDepartment}
                                    onChange={DepartmentHandler}
                                    labelledBy="Select"
                                /></div>
                            </Form.Group>
                        </Col>
                        {TestWise && <Col md={4} lg={4}>
                             <Form.Group>
                                <Form.Label>Test</Form.Label>
                                <div className='txtMultiSelect3'>
                                <MultiSelect id="TestSelect" name="TestSelect"
                                    options={SelectTestDDL}
                                    value={SelectTest}
                                    onChange={TestAllHandler}
                                    labelledBy="Select"
                                /></div>
                            </Form.Group>
                        </Col>}
                       
                          </Form.Row>}
                          {GroupOptions &&  <Form.Row>
                                    <Col md={8}>
                                        <Form.Label className='mb-4'><b>Grouping Option</b></Form.Label>
                                        <Form.Group className='animate-chk m-checkbox-inline center__type mt-15'>
                                            <Form.Label for="groupingoption">
                                            <input name="groupingoption"  id="groupingoption" className="checkbox_animated" type="checkbox" checked={GroupOptChecked} onChange={groupingoptionHandler} value={1} />
                                            Client Wise Grouping
                                            </Form.Label>
                                        </Form.Group>
                                    </Col>
                                </Form.Row>}
                         
                                <Form.Row>
                                    {/* <Col md={4}>
                                        <Form.Group>
                                            <Form.Label>State</Form.Label>
                                            <Form.Control as="select" ref={refstate} name="centerstate" onChange={StateChangeHandler}>
                                                <option key="0" value="0">---Select---</option>
                                                {statesList}
                                            </Form.Control>
                                        </Form.Group>
                                    </Col>
                                    <Col md={4}>
                                        <Form.Group>
                                            <Form.Label>Region</Form.Label>
                                            <Form.Control as="select" ref={refregion} name="centerregion" onChange={RegionChangeHandler}>
                                                <option key="0" value="0">---Select---</option>
                                                {RegionsList}
                                            </Form.Control>
                                        </Form.Group>
                                    </Col>
                                    <Col md={4}>
                                        <Form.Group>
                                            <Form.Label>Centre Type</Form.Label>
                                            <Form.Control as="select" name="centertype" ref={refcentertype} onChange={(e) => CallRateTemplate(e)}>
                                                <option key="0" value="0">---Select---</option>
                                                {CentreType}
                                            </Form.Control>
                                        </Form.Group>
                                    </Col> */}
                                    <Col md={12} className='text-center __center__buttonsec mb-4 mt25'>
                                        <Button variant='primary' onClick={handleSearchList} filename={"CentreRateTemplates.csv"} target="_blank" >Download CSV</Button>
                                    </Col>
                                    {/* <Col lg={12} md={12}>
                                        {
                                            <DataTables
                                                keyField='Id'
                                                tableData={table}
                                                columns={columns}
                                                noDataIndication={NoRecordFounds}
                                                pagination={paginationFactory(paginationOptions)}
                                            />
                                        }
                                    </Col> */}
                                </Form.Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row> 
                {
                    downloadvisible &&
                    <CSVLink data={BusinessDatalistCSV} filename={ExpFilenameDownload} target="_blank" id="csvdownload"></CSVLink>
                }
            </Container>
        </React.Fragment>
    )
}

export default BusinessData;