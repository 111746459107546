import React, { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router';
import { Container, Row, Col, Card, Form, Button, Table } from 'react-bootstrap';
import Breadcrumb from '../../components/common/breadcrumb';
import ViewToolTip from '../UI/ToolTips/ViewToolTip';
// import UploadToolTip from '../UI/ToolTips/UploadToolTip';
import DynamicDataTables from '../commonComponent/DataTable/DynamicDataTable';
import LoadingButton from '../commonComponent/LoadingButton';
import SweetAlert from 'sweetalert2';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { FrontDesk_URL, RazorPay_URL, Transaction_URL } from '../../constant';
import axios from 'axios';
import { Spinner } from 'react-bootstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import moment from 'moment';
import { CSVLink } from "react-csv";
import * as XLSX from 'xlsx';
import Select from 'react-select';

let globalPaymentModeText = "";
let GlobalOptionAdvc = "";
let GlobalCentreId = "";
const AdvancePayment = () => {
    const today = new Date();
    const date = today.setDate(today.getDate());
    const defaultValue = new Date(date).toISOString().split('T')[0];
    const [modal, setModal] = useState();
    const [errorModal, setErrorModal] = useState();
    let fromDate = useRef("");
    let paymentDate = useRef();
    const [isLoading, setIsLoading] = useState(false);
    const [PaymentModeDLL, SetPaymentModeDLL] = useState([]);
    const [PaymentModeVal, SetPaymentModeVal] = useState("");
    const [BankDLL, SetBankDLL] = useState([]);
    const [BankVal, SetBankVal] = useState([]);
    const [LoginId, setLoginId] = useState(localStorage.LoggedInUser == undefined || localStorage.LoggedInUser == null ? "" : localStorage.LoggedInUser);
    const [CentreDDL, SetCentreDDL] = useState([]);
    const [IsButtonSubmitNew, SetIsButtonSubmitNew] = useState(false);
    const [ValidDateF, SetValidDateF] = useState("");
    const [IsShowDateCand, SetIsShowDateCand] = useState(false);
    const [ShowValidDateF, SetShowValidDateF] = useState("");
    const [CustrizeNo, SetCustrizeNo] = useState("");
    const [SelectedCenter, SetSelectedCenter] = useState("");
    const [hideadvancepayment, setHideAdvancePayment] = useState(false);
    const [downloadvisible, setDownloadVisible] = useState(false);
    const [advancepaymentdetails, setAdvancePaymentDetails] = useState([]);
    const [centreid, setCentreId] = useState(localStorage.CentreSNo == undefined || localStorage.CentreSNo == null ? "" : localStorage.CentreSNo);
    const [email, setEmail] = useState(localStorage.EmailId == null || localStorage.EmailId == undefined ? "" : localStorage.EmailId);
    const [centreusername, setCentreUserName] = useState(localStorage.CentreName == null || localStorage.CentreName == undefined ? "" : localStorage.CentreName + ' / ' + localStorage.UserName == null || localStorage.UserName == undefined ? "" : localStorage.UserName);
    const [mobileno, setMobileNo] = useState(localStorage.MobileNo == null || localStorage.MobileNo == undefined ? "" : localStorage.MobileNo);
    const [isButtonLoading, setIsButtonLoading] = useState(false);
    const [typeSelected, setTypeSelected] = useState([]);
    const history = useHistory();
    const [pagehelper, setPageHelper] = useState({
        PageNo: 1,
        PageSize: 10,
        TotalRecord: 0,
        TotalApprovedAmt: 0,
        TotalPendingAmt: 0,
        AvailableBalance: 0,
        dsAdvancePaymentDlists: []
    });
    const [crntPageNO, setCrntPageNO] = useState(0);

    const PaymentFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                <p><strong>Payment Mode:</strong> {rowData.Payment}</p>
                <p><strong>Amount:</strong> {rowData.DepositAmount}</p>
                <p><strong>Deposit By:</strong> {rowData.UserName}</p>
                <p><strong>Deposit Date:</strong> {rowData.CustDepositDate}</p>
                <p><strong>Entry Date:</strong> {rowData.CustEntryDate}</p>
                <p><strong>Payment Status:</strong> {rowData.PaymentStatus}</p>
            </div>
        </React.Fragment>
    }
    const AdvPaymentFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                <p><strong>Bank Name:</strong> {rowData.Bank == '' ? 'N/A' : rowData.Bank}</p>
                <p><strong>Payment Mode No:</strong> {rowData.PaymentModeNo == '' ? 'N/A' : rowData.PaymentModeNo}</p>
                <p><strong>Payment Mode Date:</strong> {rowData.CustPaymentDate == '' ? 'N/A' : rowData.CustPaymentDate}</p>
            </div>
        </React.Fragment>
    }
    const paginationOptions = {
        totalSize: pagehelper.TotalRecord,
        sizePerPage: pagehelper.PageSize,
        showTotal: true,
        firstPageText: 'First',
        prePageText: 'Back',
        nextPageText: 'Next',
        lastPageText: 'Last',
        onPageChange: (page, sizePerPage) => {
            setCrntPageNO(((page - 1) * sizePerPage));
            //BindGrid(1, 10, GlobalOptionAdvc);
            BindGrid(page, sizePerPage, GlobalOptionAdvc);
        },
        onSizePerPageChange: (sizePerPage, page, event) => {
            //BindGrid(1, 10, GlobalOptionAdvc);
            BindGrid(page, sizePerPage, GlobalOptionAdvc);
            document.getElementsByClassName('dropdown-menu show')[0].classList.remove('show')
        }
    };
    const idFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                <span>{crntPageNO + rowIndex + 1}</span>
            </div>
        </React.Fragment>
    }
    const columns = [
        // { dataField: '#', text: '#', formatter: (cell, row, rowIndex) => { let rowNumber = (PageHelper.PageNo - 1) * PageHelper.PageSize + (rowIndex + 1); return <span>{rowNumber}</span>; }, headerStyle: (colum, colIndex) => { return { width: '5%' }; } },
        { dataField: '#', text: '#', editable: false, formatter: idFormatter, headerStyle: (colum, colIndex) => { return { width: '5%' }; } },
        { text: 'Payment Detail', editable: false, formatter: PaymentFormatter, headerStyle: (colum, colIndex) => { return { width: '22%' }; } },
        { text: 'Payment Advc. Detail', editable: false, formatter: AdvPaymentFormatter, headerStyle: (colum, colIndex) => { return { width: '22%' }; } },
        { dataField: 'Remarks', text: 'Remarks', editable: false },
        { dataField: 'RejectReason', text: 'Reject Reason', editable: false }

    ];
    useEffect(() => {
        GetSettings(localStorage.CentreSNo, localStorage.LoggedInUser);
        GetPaymentmode();
        GetBankLists();
        GlobalCentreId = localStorage.CentreSNo;
        SetSelectedCenter(GlobalCentreId);
        BindGrid(1, 10, "1");
        // GetCentresList();
    }, []);
    const GetSettings = async (cId, uId) => {
        try {
            let url = FrontDesk_URL + 'master/getcentresettings/' + cId + '/' + uId;
            await axios.get(url).then((response) => {
                const data = response.data;
                if (data.Success) {
                    const settingInfo = data.Data;
                    if (settingInfo.ParentCentreName === "") { setHideAdvancePayment(false); } else { setHideAdvancePayment(true); }
                }
            }).catch(function (error) { setIsLoading(false); });
        }
        catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); setIsLoading(false); }
    }
    const BindGrid = async (PageNo, PageSize, PaymentStatusId) => {
        setIsLoading(true);
        try {

            let url = FrontDesk_URL + 'master/GETAdvancePaymentDetails'
            let options = {
                method: 'POST',
                url: url,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json;charset=UTF-8'
                },
                data: { PageNo: `${PageNo}`, PageSize: `${PageSize}`, PaymentStatusId: `${PaymentStatusId}`, CentreId: `${localStorage.CentreSNo}` }
            };
            let response = await axios(options);
            let responseOK = response && response.status == 200;
            if (responseOK) {
                let data = response.data;

                // let data = await response.data;
                if (data.Success == true) {
                    let ds = data.Data;
                    if (ds != null) {
                        await setPageHelper({
                            ...pagehelper,
                            PageNo: Number(PageNo),
                            PageSize: Number(PageSize),
                            TotalRecord: Number(ds.TotalRecord),
                            TotalApprovedAmt: Number(ds.TotalApprovedAmt),
                            TotalPendingAmt: Number(ds.TotalPendingAmt),
                            AvailableBalance: Number(ds.AvailableBalance),
                            dsAdvancePaymentDlists: ds.ObjBindAdvancePaymentList == null ? [] : ds.ObjBindAdvancePaymentList
                        });
                        setIsLoading(false);
                    }
                    else { setPageHelper([]); }
                }
                else {
                    let Errtext = <p><div className='text-required'> no record found </div></p>;
                    console.log('no record found');
                    setIsLoading(false);
                }

            }
            else { SweetAlert.fire({ title: "Application Exception", text: "Some error occured! Please try after sometime", icon: "error" }); setIsLoading(false); }
            // return data;
        } catch (error) {
            if (error.response) {
                ErrorFunction(error.response.status);
                setIsLoading(false);
            }

        }
    }
    const ErrorFunction = async (ErrorCode) => {
        if (ErrorCode == "400")
            SweetAlert.fire({ title: "Error 400", text: "Bad request", icon: "error" })
        else if (ErrorCode == "401")
            SweetAlert.fire({ title: "Error 401", text: "Unauthorized", icon: "error" })
        else if (ErrorCode == "403")
            SweetAlert.fire({ title: "Error 403", text: "Forbidden", icon: "error" })
        else if (ErrorCode == "404")
            SweetAlert.fire({ title: "Error 404", text: "Page not found", icon: "error" })
        else if (ErrorCode == "500")
            SweetAlert.fire({ title: "Error 500", text: "Internal Server Error", icon: "error" })
        else if (ErrorCode == "503")
            SweetAlert.fire({ title: "Error 503", text: "Service Unavailable", icon: "error" })
    }
    const GetCentresList = async () => {
        SetCentreDDL(<option key={localStorage.CentreSNo == undefined ? "" : localStorage.CentreSNo} value={localStorage.CentreSNo == undefined ? "" : localStorage.CentreSNo}>{localStorage.CentreName == undefined ? "" : localStorage.CentreName}</option>);
    }
    const GetBankLists = async () => {
        var glblArr1 = [];
        try {
            let url = FrontDesk_URL + 'master/BankLists'
            await axios.get(url).then((response) => {
                const data = response.data;
                if (data.Success) { 
                    let ds = data.Data;
                    if (ds != null) {
                        ds.map((option) => { return glblArr1.push({ 'value': option.SNo, 'label': option.Bank, 'disabled': false }); });
                        SetBankDLL(glblArr1);
                    }
                    else {
                        SetBankDLL([]);
                    }
                    //SetBankDLL(data.Data.map((d) => (<option key={d.SNo} value={d.SNo}>{d.Bank}</option>))); 
                }
            }).catch(function (error) { });
        }
        catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    }
    const GetPaymentmode = async () => {
        try {
            let url = FrontDesk_URL + 'master/GetPaymentModesAdvance'
            await axios.get(url).then((response) => {
                const data = response.data;
                if (data.Success) { SetPaymentModeDLL(data.Data.map((d) => (<option key={d.SNo} value={d.SNo}>{d.PaymentMode}</option>))); }
            }).catch(function (error) { });
        }
        catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    }
    /********** Modals  ***********/

    const toggleModal = () => {
        setModal(!modal)
    }

    const errorHandler = async () => {
        setErrorModal(true);
        setModal(true);
        if (fromDate.current != undefined && fromDate.current != "") {
            SetValidDateF(fromDate.current.value);
        }
        else {
            SetValidDateF("");
        }
        if (paymentDate.current != undefined && paymentDate.current != "") {
            SetShowValidDateF(paymentDate.current.value);
        }
        else {
            SetShowValidDateF("");
        }
        SetIsButtonSubmitNew(true);
        formik.handleSubmit();
    }
    /********** Validations  ***********/
    const formik = useFormik({
        initialValues: {
            aymentmode: '0',
            advanceamt: '',
            txtremarks: ''

            // callType: '',
        },
        enableReinitialize: true,
        validationSchema: Yup.object({
            // email: Yup.string().email('Invalid email address').required('Required'),
            // Paymentmode: Yup.string().transform(v => v=== '0' ? '' : v)
            // .required('Payment Mode Selection is Required'),
            advanceamt: Yup.string().required('Advance Amount is Required'),
            txtremarks: Yup.string().required("Remarks is Required"),
            // Clientname: Yup.string().transform(v => v=== '0' ? '' : v)
            // .required('Client Name is Required'),

        }),
        isSubmitting: true,
        onSubmit: async (values, actions) => {
            try {

                if (IsShowDateCand == true && BankVal == "" && IsButtonSubmitNew == true) {
                    setModal(true); setErrorModal(true);
                }
                else if (IsShowDateCand == true && CustrizeNo == "" && IsButtonSubmitNew == true) {
                    setModal(true); setErrorModal(true);
                }
                else if (IsShowDateCand == true && (ShowValidDateF == "" || moment(ShowValidDateF, 'YYYY-MM-DD').isValid() == false)) {
                    setModal(true); setErrorModal(true);
                }
                else {
                    SetIsButtonSubmitNew(false);
                    setModal(false);
                    let url = FrontDesk_URL + 'master/SETAdvancePaymentDetails';

                    let options = {
                        method: 'POST',
                        url: url,
                        headers: { 'Accept': 'application/json', 'Content-Type': 'application/json;charset=UTF-8' },
                        data: {
                            CentreId: `${SelectedCenter}`, PaymentId: `${PaymentModeVal}`, DepositDate: `${ValidDateF}`, DepositAmount: `${values.advanceamt}`, Remarks: `${values.txtremarks}`, BankId: `${BankVal}`, PaymentModeNo: `${CustrizeNo}`, PaymentDate: `${ShowValidDateF}`
                            , AddedBy: `${LoginId}`
                        }
                    };

                    let response = await axios(options);
                    let responseOK = response && response.status == 200;
                    if (responseOK) {
                        let data = response.data;
                        // let data = await response.data;
                        if (data.Success == true && data.Data > "0") {
                            formik.resetForm();
                            SweetAlert.fire({ title: "Success!", text: "Advance Payment has been Saved!", icon: "success" });
                            setErrorModal(false);
                            BindGrid(1, 10, "1")
                        }
                        else {
                            if (data.Data == "-2000") { SweetAlert.fire({ title: "Error", text: "Max limit exceeded", icon: "error" }); }
                            else {
                                if (data.ErrorList != null && data.ErrorList.length > 0) {
                                    let Errtext = <p><div className='text-required'>{data.ErrorList[0].errorMsg} </div></p>;
                                    SweetAlert.fire({ title: "Error", text: data.ErrorList[0].errorMsg, icon: "error" });
                                }
                            }
                        }
                    }
                    else { console.log('no record found'); }
                }
            }

            catch (error) {
                console.log(error.message);
                let Errtext = <p><div className='text-required'>You may not be connected to a network or Unable to connect to a server</div></p>;
                SweetAlert.fire({ title: "Error", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" });
            }
            setTimeout(() => {

                actions.setSubmitting(false);
            }, 1000);
        },
    });
    const AdvanceChkHandler = async (e) => {
        const value = e.target.value;
        GlobalOptionAdvc = value;
        const isChecked = e.target.checked;
        if (isChecked == true) {
            BindGrid(1, 10, GlobalOptionAdvc)
        }
    }
    const bankHandler = async (e) => {
        const value = e.target.value;
        SetBankVal(value);
    }
    const PaymentModeHandler = async (e) => {
        formik.resetForm();
        SetBankVal([]);
        SetCustrizeNo("");
        const value = e.target.value;
        globalPaymentModeText = e.target.options[e.target.selectedIndex].text;
        SetPaymentModeVal(value);
        if (value == "3" || value == "7" || value == "8" || value == "9" || value == "10" || value == "11") {
            setTypeSelected([]);
            SetIsShowDateCand(true);
        }
        else {
            setTypeSelected([]);
            SetIsShowDateCand(false);
        }
    }
    const CustrizeHandler = async (e) => {
        const val = e.target.value;
        SetCustrizeNo(val);
    }
    const DownloadAdvanceStatement = async () => {
        setIsLoading(true);
        try {
            let url = FrontDesk_URL + 'accounts/getadvancepaymentstatement/' + (GlobalOptionAdvc == "" ? "1" : GlobalOptionAdvc) + '/' + `${localStorage.CentreSNo}`
            await axios.get(url).then((response) => {
                const data = response.data;
                setIsLoading(false);
                if (data.Success) {
                    if (data.Data == null) { setDownloadVisible(false); setIsLoading(false); setAdvancePaymentDetails([]); }
                    else { setDownloadVisible(true); setAdvancePaymentDetails(data.Data); document.getElementById("csvdownload").click(); }
                }
                else { setDownloadVisible(false); setIsLoading(false); setAdvancePaymentDetails([]); }
            }).catch(function (error) { setIsLoading(false); if (error.response) { ErrorFunction(error.response.status); } });
        } catch (error) { setIsLoading(false); SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    }
    const GetReferenceLink = async () => {
        if (formik.values.advanceamtqr == "" || formik.values.advanceamtqr == null || formik.values.advanceamtqr == undefined) {
            SweetAlert.fire("Please enter advance amount");
            return false;
        }
        if (formik.values.txtremarksqr == "" || formik.values.txtremarksqr == null || formik.values.txtremarksqr == undefined) {
            SweetAlert.fire("Please enter remarks");
            return false;
        }
        var grandtotalamount = formik.values.advanceamtqr;
        var unixtimstamp = Math.floor(new Date().getTime() * 1000);
        var description = formik.values.txtremarksqr;
        setIsButtonLoading(true);
        try {
            let url = FrontDesk_URL + 'accounts/getqrpaymenturl/' + grandtotalamount.toString() + '/' + unixtimstamp.toString() + '/' + description + '/' + (centreusername == "" ? "0" : centreusername) + '/' + (mobileno == "" ? "0" : mobileno) + '/' + (email == "" ? "0" : email) + '/' + centreid + '/' + LoginId
            await axios.get(url).then((response) => {
                const data = response.data;
                setIsButtonLoading(false);
                if (data.Success) {
                    if (data.Data == null) { SweetAlert.fire("Some error occured! Please try again after sometime"); }
                    else {
                        if (data.Data == "-1001") {
                            SweetAlert.fire("Currently don't have permission to access this feature. Please contact your admin.");
                        }
                        else {
                            CallExternalPaymentLink(data.Data.ReferenceId, data.Data.PaymentKey, grandtotalamount, unixtimstamp, description);
                        }
                    }
                }
                else { setIsButtonLoading(false); SweetAlert.fire("Error Code: " + data.Data + " Check db logs!"); }
            }).catch(function (error) { setIsButtonLoading(false); if (error.response) { ErrorFunction(error.response.status); } });
        } catch (error) { setIsButtonLoading(false); SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    }
    const CallExternalPaymentLink = async (refId, paymentKey, amount, expiry, description) => {
        amount = parseFloat(amount) * 100;
        try {
            let url = FrontDesk_URL + 'accounts/callexternalpaymentlink/' + refId + '/' + amount.toString() + '/' + expiry.toString() + '/' + description + '/' + (centreusername == "" ? "0" : centreusername) + '/' + (mobileno == "" ? "0" : mobileno) + '/' + (email == "" ? "0" : email)
            await axios.get(url).then((response) => {
                const data = response.data;
                setIsButtonLoading(false);
                if (data.Success) {
                    if (data.Data == "-1001") { SweetAlert.fire("Currently don't have permission to access this feature. Please contact your admin."); }
                    else { window.open(data.Data, "_blank"); }
                }
                else {
                    setIsButtonLoading(false);
                    if (data.Data == "-2") { SweetAlert.fire("No payment link generated.Please try again"); }
                    else { SweetAlert.fire("Error : " + data.Data + " Check logs!"); }
                }
            }).catch(function (error) { setIsButtonLoading(false); if (error.response) { ErrorFunction(error.response.status); } });
        } catch (error) { setIsButtonLoading(false); SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
        // try {
        //     const myHeaders = new Headers();
        //     myHeaders.append("Content-Type", "application/json");
        //     myHeaders.append("Access-Control-Allow-Origin", "*");
        //     myHeaders.append("Access-Control-Allow-Methods", "DELETE, POST, GET, OPTIONS");
        //     myHeaders.append("Access-Control-Allow-Headers", "Content-Type, Authorization, X-Requested-With");
        //     myHeaders.append("Authorization", paymentKey);
        //     const raw = JSON.stringify({
        //         "upi_link": "true",
        //         "amount": parseFloat(amount) * 100,
        //         "currency": "INR",
        //         "accept_partial": false,
        //         "expire_by": expiry,
        //         "reference_id": refId,
        //         "description": description,
        //         "customer": {
        //             "name": centreusername,
        //             "contact": mobileno,
        //             "email": email
        //         },
        //         "notify": {
        //             "sms": true,
        //             "email": true
        //         },
        //         "reminder_enable": true,
        //         "callback_url": Transaction_URL,
        //         "callback_method": "get"
        //     });

        //     const requestOptions = {
        //         method: "POST",
        //         headers: myHeaders,
        //         body: raw,
        //         redirect: "follow"
        //     };
        //     fetch(RazorPay_URL, requestOptions)
        //         .then((response) => response.text())
        //         .then((result) => {
        //             var ds = JSON.parse(result);
        //             console.log(ds);
        //             window.open(ds.short_url, "_blank");
        //         })
        //         .catch((error) => {
        //             SweetAlert.fire("Some error occured while calling razor api. Check browser console logs")
        //             console.log(error);
        //         });
        // }
        // catch (error) {
        //     SweetAlert.fire("Some error occured while calling razor api. Check browser console logs")
        //     console.log(error);
        //     console.log(JSON.stringify(error));
        // }
    }
    const TypeChangeHandler = (options) => { setTypeSelected(options.value); SetBankVal(options.value);  }
    return (
        <>
            <Breadcrumb title="Advance Payment" parent="Account" />
            <Container fluid>
                {
                    hideadvancepayment === false ?
                        <>
                            <Row>
                                <Col md={12}>
                                    <Card>
                                        <Card.Header className='pb-0'>
                                            <Card.Title>Pay Using QR Code <span className='badge blink blink-new'>NEW</span> </Card.Title>
                                        </Card.Header>
                                        <Card.Body>
                                            <Form>
                                                <Form.Row>
                                                    <Col md={2}>
                                                        <Form.Group>
                                                            <Form.Label>Advance Amount<span className='text-red'>*</span></Form.Label>
                                                            <Form.Control type="number" id="advanceamtqr" name="advanceamtqr" onChange={formik.handleChange}
                                                                onBlur={formik.handleBlur}
                                                                value={formik.values.advanceamtqr} />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col md={3}>
                                                        <Form.Group>
                                                            <Form.Label>Remarks<span className='text-red'>*</span></Form.Label>
                                                            <Form.Control type="text" id="txtremarksqr" name="txtremarksqr" onChange={formik.handleChange}
                                                                onBlur={formik.handleBlur}
                                                                value={formik.values.txtremarksqr} />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col md={2} className='text-center mt-4'>
                                                        {isButtonLoading ? <LoadingButton variantName='primary' cssName="" /> :
                                                            <Button variant='primary' onClick={GetReferenceLink} className='mt-1'>Pay Using QR</Button>
                                                        }
                                                    </Col>
                                                </Form.Row>
                                                <Form.Row className='justify-content-center mt25' >
                                                </Form.Row>
                                            </Form>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <Card>
                                        <Card.Header className='pb-0'>
                                            <Card.Title>Other Payment Modes</Card.Title>
                                        </Card.Header>
                                        <Card.Body>
                                            <Form>
                                                <Form.Row>
                                                    <Col md={2}>
                                                        <Form.Group>
                                                            <Form.Label>Payment Mode<span className='text-red'>*</span></Form.Label>
                                                            <Form.Control as="select" id="Paymentmode" name="Paymentmode" value={PaymentModeVal} onChange={PaymentModeHandler}>
                                                                <option value="0" selected>Select</option>
                                                                {PaymentModeDLL}
                                                            </Form.Control>
                                                            {PaymentModeVal == "" && IsButtonSubmitNew == true ? (<div className='text-required'>{"Payment Mode is Required"}</div>) : null}
                                                        </Form.Group>
                                                    </Col>
                                                    <Col md={2}>
                                                        <Form.Group>
                                                            <Form.Label>Deposit Date</Form.Label>
                                                            <Form.Control type="date" id="date1" name="date1" defaultValue={defaultValue} min={new Date().toISOString().split('T')[0]} ref={fromDate} />
                                                            {(ValidDateF == "" || moment(ValidDateF, 'YYYY-MM-DD').isValid() == false) && IsButtonSubmitNew == true ? <div className='text-required'>Valid Deposit Date Required</div> : null}

                                                        </Form.Group>
                                                    </Col>
                                                    <Col md={2}>
                                                        <Form.Group>
                                                            <Form.Label>Advance Amount<span className='text-red'>*</span></Form.Label>
                                                            <Form.Control type="number" id="advanceamt" name="advanceamt" onChange={formik.handleChange}
                                                                onBlur={formik.handleBlur}
                                                                value={formik.values.advanceamt} />
                                                            {formik.touched.advanceamt && formik.errors.advanceamt ? (
                                                                <div className='text-required'>{formik.errors.advanceamt}</div>
                                                            ) : null}
                                                        </Form.Group>
                                                    </Col>
                                                    {PaymentModeVal != "" && (PaymentModeVal == "3" || PaymentModeVal == "7"

                                                        || PaymentModeVal == "8" || PaymentModeVal == "9" || PaymentModeVal == "10" || PaymentModeVal == "11") ?
                                                        <>
                                                            <Col md={2}>
                                                                <Form.Group>
                                                                    <Form.Label>Bank Name</Form.Label>
                                                                    {/* <Form.Control as="select" id="bankname" name="bankname" onChange={bankHandler} value={BankVal} >
                                                                        <option value="0" selected>Select</option>
                                                                        {BankDLL}
                                                                    </Form.Control>
                                                                    {IsShowDateCand == true && BankVal == "" && IsButtonSubmitNew == true ? <div className='text-required'>Bank Name is Required</div> : null} */}
                                                                    <Select onChange={TypeChangeHandler}
                                                                        options={BankDLL}
                                                                        value={BankDLL.filter(function (x) { return x.value === typeSelected; })} />
                                                                </Form.Group>
                                                            </Col><Col md={2}>
                                                                <Form.Group>
                                                                    <Form.Label>{globalPaymentModeText} No.</Form.Label>
                                                                    <Form.Control type="text" id="txtcustrizeNo" name="txtcustrizeNo" onChange={CustrizeHandler}

                                                                        value={CustrizeNo} />
                                                                    {IsShowDateCand == true && CustrizeNo == "" && IsButtonSubmitNew == true ? <div className='text-required'>{globalPaymentModeText} No. is Required</div> : null}
                                                                </Form.Group>
                                                            </Col><Col md={2}>
                                                                <Form.Group>
                                                                    <Form.Label>{globalPaymentModeText} Date</Form.Label>
                                                                    <Form.Control type="date" id="datePayment" name="datepayment" ref={paymentDate} defaultValue={defaultValue} />
                                                                    {IsShowDateCand == true && (ShowValidDateF == "" || moment(ShowValidDateF, 'YYYY-MM-DD').isValid() == false) && IsButtonSubmitNew == true ? <div className='text-required'>Valid {globalPaymentModeText} Date is Required</div> : null}
                                                                </Form.Group>
                                                            </Col></> : null}
                                                    <Col md={3}>
                                                        <Form.Group>
                                                            <Form.Label>Remarks<span className='text-red'>*</span></Form.Label>
                                                            <Form.Control type="text" id="txtremarks" name="txtremarks" onChange={formik.handleChange}
                                                                onBlur={formik.handleBlur}
                                                                value={formik.values.txtremarks} />
                                                            {formik.touched.txtremarks && formik.errors.txtremarks ? (
                                                                <div className='text-required'>{formik.errors.txtremarks}</div>
                                                            ) : null}
                                                        </Form.Group>
                                                    </Col>
                                                    <Col md={2} className='text-center mt-4'>
                                                        <Button variant='primary' onClick={errorHandler} className='mt-1'>Add Payment</Button>
                                                    </Col>
                                                </Form.Row>
                                                <Form.Row className='justify-content-center mt25' >
                                                </Form.Row>
                                            </Form>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <Card>
                                        <Card.Header className='pb-0'>
                                            <Card.Title>Advance Payment Detail</Card.Title>
                                        </Card.Header>
                                        <Card.Body>
                                            <Form>
                                                <Form.Row >
                                                    <Col md={4}>
                                                        <div>
                                                            <Form.Group className='advancepaymentradio'>
                                                                <Form.Label><strong>Filter Data:</strong></Form.Label>
                                                                <Form.Label>
                                                                    <input name="chkAdvance" defaultChecked={true} type="radio"
                                                                        id="chkAdvance" onChange={AdvanceChkHandler} value={1} />
                                                                    Pending
                                                                </Form.Label>
                                                                &nbsp;  &nbsp;
                                                                <Form.Label>

                                                                    <input name="chkAdvance" type="radio"
                                                                        id="chkAdvance" onChange={AdvanceChkHandler} value={2} />
                                                                    Approved
                                                                </Form.Label>
                                                                &nbsp;   &nbsp;
                                                                <Form.Label>
                                                                    <input name="chkAdvance" type="radio"
                                                                        id="chkAdvance" onChange={AdvanceChkHandler} value={3} />
                                                                    Rejected
                                                                </Form.Label>
                                                                &nbsp;   &nbsp;
                                                                <Form.Label>
                                                                    <input name="chkAdvance" type="radio"
                                                                        id="chkAdvance" onChange={AdvanceChkHandler} value={4} />
                                                                    All
                                                                </Form.Label>
                                                            </Form.Group>
                                                        </div>
                                                    </Col>
                                                    <Col md={4} className='text-center  mb-4 mb-lg-0'>
                                                        <Button variant='secondary' onClick={(e) => { e.preventDefault(); window.location.reload(false) }} ><i className='fa fa-refresh mr-1'></i> Refresh</Button>
                                                    </Col>
                                                    <Col md={4} className='text-right'>
                                                        <Form.Label><strong>Available Balance</strong> :{pagehelper.AvailableBalance == '' ? '0.00' : parseFloat(pagehelper.AvailableBalance).toFixed(2)}</Form.Label>
                                                        {/* <Form.Label className='ml-4'><strong>Approved Total</strong> :{pagehelper.TotalApprovedAmt == '' ? '0.00' : parseFloat(pagehelper.TotalApprovedAmt).toFixed(2)}</Form.Label> */}
                                                        <Form.Label className='ml-4'><strong>Pending Total</strong> :{pagehelper.TotalPendingAmt == '' ? '0.00' : parseFloat(pagehelper.TotalPendingAmt).toFixed(2)}</Form.Label>
                                                    </Col>
                                                </Form.Row>
                                                {
                                                    localStorage.CentreSNo == "" || localStorage.CentreSNo == "0" ? null :
                                                        <Form.Row>
                                                            <Col md={4}></Col>
                                                            <Col className='text-right' md={8}>
                                                                <Button variant='link' className="mr-2 btn-downloadExcel mb-2" onClick={() => DownloadAdvanceStatement()} filename={"AdvancePayment.csv"} target="_blank"><i className='fa fa-file-excel-o'></i> Download Statement</Button>
                                                            </Col>
                                                        </Form.Row>
                                                }
                                            </Form>
                                            {
                                                isLoading ?
                                                    <div className='text-center'> <Spinner animation="border" size='sm' /></div> :
                                                    <>
                                                        <DynamicDataTables keyField="AdvancePaymentId" tableData={pagehelper.dsAdvancePaymentDlists} columns={columns} pagination={paginationOptions ? paginationFactory(paginationOptions) : null} />
                                                    </>
                                            }
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        </> :
                        <>
                            <Row>
                                <Col md={12}>
                                    <Card>
                                        <Card.Header className='pb-0 text-center'>
                                            <Card.Title>You are not authorised for this service.</Card.Title>
                                        </Card.Header>
                                    </Card>
                                </Col>
                            </Row>
                        </>
                }
                {
                    downloadvisible &&
                    <CSVLink data={advancepaymentdetails} filename="AdvancePayment.csv" target="_blank" id="csvdownload"></CSVLink>
                }
            </Container>

            {errorModal &&
                <Modal isOpen={modal} toggle={toggleModal} centered={true}>
                    <ModalHeader toggle={toggleModal}>Errors</ModalHeader>
                    <ModalBody>

                        <p>
                            {(SelectedCenter == "" || SelectedCenter == "0") && IsButtonSubmitNew == true ? (<div className='text-required'>{"Client Name is Required"}</div>) : null} </p>
                        <p>{PaymentModeVal == "" && IsButtonSubmitNew == true ? (<div className='text-required'>{"Payment Mode is Required"}</div>) : null}</p>
                        <p> {formik.touched.advanceamt && formik.errors.advanceamt ? (
                            <div className='text-required'>{formik.errors.advanceamt}</div>
                        ) : null} </p>

                        <p> {formik.touched.txtremarks && formik.errors.txtremarks ? (
                            <div className='text-required'>{formik.errors.txtremarks}</div>
                        ) : null} </p>
                        <p> {(ValidDateF == "" || moment(ValidDateF, 'YYYY-MM-DD').isValid() == false) && IsButtonSubmitNew == true ? <div className='text-required'>Valid Deposit Date Required</div> : null} </p>
                        <p>{IsShowDateCand == true && BankVal == "" && IsButtonSubmitNew == true ? <div className='text-required'>Bank Name is Required</div> : null}</p>
                        <p> {IsShowDateCand == true && CustrizeNo == "" && IsButtonSubmitNew == true ? <div className='text-required'>{globalPaymentModeText} No. is Required</div> : null}</p>
                        <p> {IsShowDateCand == true && (ShowValidDateF == "" || moment(ShowValidDateF, 'YYYY-MM-DD').isValid() == false) && IsButtonSubmitNew == true ? <div className='text-required'>Valid {globalPaymentModeText} Date Required</div> : null}</p>

                    </ModalBody>
                    <ModalFooter>
                        <Button variant="secondary" onClick={toggleModal}>Cancel</Button>
                        <Button variant="primary" onClick={toggleModal}>Ok</Button>
                    </ModalFooter>

                </Modal>
            }
        </>
    )
}
export default AdvancePayment;