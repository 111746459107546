import React from "react";
import { Container, Row, Col, Card, Form, Button, Table } from 'react-bootstrap';

class ConsentFormFNAC extends React.Component {

  render() {
   
    const pdfProps = this.props;
    const patientName = pdfProps.PName;
    const age = pdfProps.Age;
    const gender = pdfProps.Gender;
    const refDoctor = pdfProps.RefDoctor;
    console.log(pdfProps);
    return (
      <>
        <table className="table" style={{ margin: '10px' }}>
          <tbody>
            <tr>
              <td style={{ border: 'none' }}>
                <img src="/static/media/logo-atulaya.fbfb36f9.png" alt="thumb" />
              </td>
            </tr>
            <tr><td style={{ border: 'none' }}></td></tr>
            <tr><td style={{ border: 'none' }}></td></tr>
            <tr><td style={{ border: 'none', textAlign: 'center' }}><b>CONSENT FORM FOR FNAC</b></td></tr>
            <tr><td style={{ border: 'none' }}></td></tr>
            <tr>
              <td style={{ border: 'none' }}>
                <table className="table">
                  <tr>
                    <td style={{ border: 'none' }}>Patient's Name: <b>{patientName}</b></td>
                    <td style={{ border: 'none' }}>Age: <b>{age}</b></td>
                    <td style={{ border: 'none' }}>Gender: <b>{gender}</b></td>
                    <td style={{ border: 'none' }}>Referred by: <b>{refDoctor}</b></td>
                  </tr>
                </table>
              </td>
            </tr>
            <tr>
              <td style={{ border: 'none' }}>
                <table className="table">
                  <tr>
                    <td style={{ border: 'none' }}>Specimen Collected by: <b>.........................................</b></td>
                    <td style={{ border: 'none' }}>Name of Sub Centre / Lab: <b>.........................................</b></td>
                  </tr>
                </table>
              </td>
            </tr>
            <tr>
              <td style={{ border: 'none' }}>
                <table className="table">
                  <tr>
                    <td style={{ border: 'none' }}>Tel/Fax: <b>.........................................</b></td>
                    <td style={{ border: 'none' }}>Pervious MR No. (if any) <b>.........................................</b></td>
                    <td style={{ border: 'none' }}>Date &amp; Time of collection: <b>.........................................</b></td>
                  </tr>
                </table>
              </td>
            </tr>
            <tr>
              <td style={{ border: 'none' }}>I ..<b>{patientName}</b>.. hereby give my informed consent for performing FNAC (Fine Needle Aspiration Cytology), the risks and complications of which have been explained to me. The nature and purpose of this procedure has been explained to me and I understand that there will be an insertion of a needle into my body so that tissue and or fluid can be removed. The risks of injury, infection, bleeding and other complications, despite precautions, have been explained to me. All questions that I may have in reference to this procedure and the associated risks have been explained to my satisfaction.</td>
            </tr>
            <tr><td style={{ border: 'none' }}></td></tr>
            <tr><td style={{ border: 'none' }}></td></tr>
            <tr>
              <td style={{ border: 'none' }}>
                <table className="table">
                  <tr>
                    <td style={{ border: 'none' }}>.........................................................................................................................................</td>
                    <td style={{ border: 'none', textAlign: 'right' }}>......................................</td>
                  </tr>
                </table>
              </td>
            </tr>
            <tr>
              <td style={{ border: 'none' }}>
                <table className="table">
                  <tr>
                    <td style={{ border: 'none' }}><b>Signature of Patient or Guardian or Person Authorized to consent for the Patient</b></td>
                    <td style={{ border: 'none', textAlign: 'right' }}><b>Date</b></td>
                  </tr>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
      </>
    );
  }
}

export default ConsentFormFNAC;