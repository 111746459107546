const config = {
    defaults: {
        namespace: ''
    },
    FrontDesk_URL_Start:process.env.REACT_APP_FrontDesk_URL_Start,
    Admin_URL_Start:process.env.REACT_APP_Admin_URL_Start,
    Image_URL_Start:process.env.REACT_APP_Image_URL_Start,
    CallCentre_URL_Start:process.env.REACT_APP_CallCentre_URL_Start,
    PineLabs_URL_Start:process.env.REACT_APP_PineLabs_URL_Start,
    RazorPay_URL_Start:process.env.REACT_APP_RazorPay_URL_Start,
    Transaction_URL_Start:process.env.REACT_APP_Transaction_URL_Start,
    CallBack_URL_Start:process.env.REACT_APP_CallBack_URL_Start,
}

export default config;