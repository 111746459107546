import React, { useEffect, useState, useRef } from 'react';
import { Container, Row, Col, Card, Form, Button } from 'react-bootstrap';
import Breadcrumb from '../../components/common/breadcrumb';
import { useHistory } from 'react-router';
import AppointmentDetails from '../commonComponent/Phlebo/AppointmentDetails';
import classes from '../commonComponent/Phlebo/CartSummary.module.css';
import CartSummaryPhlebo from '../commonComponent/Phlebo/CartSummaryPhlebo';
import Order from '../commonComponent/Phlebo/Order';
import OrderSummary from '../commonComponent/Phlebo/OrderSummary';
import LoadingButton from '../commonComponent/Phlebo/LoadingButton';
import { FrontDesk_URL, CallCentre_URL } from '../../constant/index';
import axios from 'axios';
import Swal from 'sweetalert2';
import useRazorpay from "react-razorpay";
import DataTables from '../commonComponent/DataTable/DataTables';
import NoRecordFounds from '../commonComponent/DataTable/NoRecordFounds';
import DeleteToolTip from '../UI/ToolTips/DeleteToolTip';

var razorpaykey = "";
var glblPaymentModesList = [];
var glblCheckboxList = [];
var grandtotalamount = 0;
var glbldoctext = [];
var paymentModeIdSetting = "";
var glblPaymentsList = [];
var discoutamount = 0;
var glblDiscountAuthorizer = [];
var glblCreditAuthorizer = [];
var itemdetails = [];
var glblitemstore = [];
var discountpatientTotalAmt = 0;
const RouteInfo = () => {
    const history = useHistory();
    const [appointmentId, setAppointmentId] = useState("0");
    const [isLoading, setIsLoading] = useState(false);
    const [allpatientname, setAllPatientName] = useState([]);
    const [orderDetails, setOrderDetails] = useState([]);
    const [allitemdetails, setAllItemDetails] = useState([]);
    const [isButtonLoading, setIsButtonLoading] = useState(false);
    const [addressdetailid, setAddressDetailId] = useState("0");
    const [carthomeaddress, setCartHomeAddress] = useState([]);
    const [finaldatetime, setFinalDateTime] = useState();
    const [reviewstatus, setReviewStatus] = useState(true);
    const [statevalues, setStateValues] = useState([]);
    const [allvisitids, setAllVisitIds] = useState("");
    const [loginid, setLoginId] = useState(localStorage.getItem("PatientId"));
    const [tableColorData, setTableColorData] = useState([]);
    const [paymentmode, setPaymentMode] = useState([]);
    const [paymenttable, setPaymentTable] = useState([]);
    const [discountauthorizerdetails, setDiscountAuthorizerDetails] = useState({});
    const [creditauthorizerdetails, setCreditAuthorizerDetails] = useState({});
    const [visitingid, setVisitingId] = useState(0);
    const [paymentModeId, setPaymentModeId] = useState("0");
    const [storeitem, setStoreItem] = useState([]);
    const [paidamt, setPaidAmt] = useState(0);
    const [totalamt, setTotalAmt] = useState(0);
    const [dueamt, setDueAmt] = useState(0);
    const [discamt, setDiscAmt] = useState(0);
    const [credamt, setCredAmt] = useState(0);
    const [parentcentreid, setParentCentreId] = useState("0");
    const [Percentagecheckbox, setPercentagecheckbox] = useState("0");
    const [centreDiscountLimit, setCentreDiscountLimit] = useState(0);
    const [disablePayMode, setDisablePayMode] = useState(false);
    const [discountAuthorizers, setDiscountAuthorizers] = useState([]);
    const [creditAuthorizers, setCreditAuthorizers] = useState([]);
    const [hideAddPayButton, setHideAddPayButton] = useState(false);
    const [hideshowpaymentbutton, setHideShowPaymentButton] = useState(false);
    const [CreditLimitSetting, setCreditLimitSetting] = useState(0);
    const [paymentChannels, setPaymentChannels] = useState([]);
    const [paitientdiscount, setPaitientDiscount] = useState([]);
    const [disablePayAmount, setdisablePayAmount] = useState(false);
    const [channelid, setChannelId] = useState("0");
    const [loginuserid, setLoginUserId] = useState(localStorage.LoggedInUser == undefined || localStorage.LoggedInUser == null ? "" : localStorage.LoggedInUser);
    const [logincentreid, setLoginCentreId] = useState(localStorage.CentreId == undefined || localStorage.CentreId == null ? "" : localStorage.CentreId);
    const [glblrefno, setGlblRefNo] = useState("");
    const [mobilenumber, setMobileNumber] = useState("0");

    let paymentmodedetail = useRef();
    let paymentamountdetail = useRef();
    let paymentChannelId = useRef();
    let paymentReferenceNo = useRef();
    let posMachineId = useRef();
    let authorizerId = useRef();
    let concessionReason = useRef();

    let day = useRef();
    let month = useRef();
    let year = useRef();
    let refcancelreason = useRef();
    const Razorpay = useRazorpay();
    let paymode = useRef();
    const childCompRef = useRef();
    const onChangeSomeState = (discountPaitent) => {
        setPaitientDiscount(discountPaitent);
        var discountAmt = 0;
        for (var i = 0; i < discountPaitent.length; i++) {
            discountAmt += parseFloat(discountPaitent[i].discountAmt);
        }
        if (discountAmt > 0) {
            paymentmodedetail.current.value = 14;
            ChangePaymentMode(paymentmodedetail.current.value);
            paymentamountdetail.current.value = discountAmt;
        }
        // var string = [];
        // for (var i in glblPaymentModesList) {
        //     string.push(<option key={glblPaymentModesList[i].SNo} value={glblPaymentModesList[i].SNo}>{glblPaymentModesList[i].PaymentMode}</option>);
        // }
        // setPaymentMode([]);
        // setPaymentMode(string);
        // setPaymentTable([]);
        // glblPaymentsList = [];
        // setPaidAmt(0);
        // setDiscAmt(0);
        // setCredAmt(0);
        // setPaitientDiscount(discountPaitent);
        // var discountAmt = 0;
        // for (var i = 0; i < discountPaitent.length; i++) {
        //     discountAmt += parseFloat(discountPaitent[i].discountAmt);
        // }
        // if (discountAmt > 0) {
        //     paymentmodedetail.current.value = "14";
        //     ChangePaymentMode("14");
        //     paymentamountdetail.current.value = discountAmt;
        // }
        // paymentamountdetail.current.value = discountAmt;
        // discountpatientTotalAmt = discountAmt;
    };
    useEffect(() => {
        localStorage.removeItem('RouteId');
        glblitemstore = [];
        setStoreItem([]);
        glblPaymentsList = [];
        if (localStorage.getItem("aId") !== null) {
            setHideAddPayButton(false);
            GetSettings(localStorage.getItem("CentreId"), localStorage.LoggedInUser);
            setAppointmentId(localStorage.getItem("aId"));
            GetUserAppointments(localStorage.getItem("aId"));
            GetUserSampleCount(localStorage.getItem("aId"));
            GetPaymentMode("1");
            setDiscountAuthorizerDetails({});
            setCreditAuthorizerDetails({});
            GetAuthorizer("1");
            GetAuthorizer("2");
            GetPaymentChannels_Phlebo();
        }
    }, []);
    // useEffect(() => {
    //     var string = [];
    //     for (var i in glblPaymentModesList) {
    //         string.push(<option key={glblPaymentModesList[i].SNo} value={glblPaymentModesList[i].SNo}>{glblPaymentModesList[i].PaymentMode}</option>);
    //     }
    //     setPaymentMode([]);
    //     setPaymentMode(string);       
    // }, [onChangeSomeState]);
    useEffect(() => {
        if (paidamt == "0" && discamt == "0" && credamt == "0") { setDueAmt(parseFloat(totalamt).toFixed(2)) }
        else if (paidamt == "0" && discamt == "0" && credamt != "0") { setDueAmt(parseFloat(totalamt - credamt).toFixed(2)); }
        else if (paidamt == "0" && discamt != "0" && credamt == "0") { setDueAmt(parseFloat(totalamt - discamt).toFixed(2)); }
        else if (paidamt != "0" && discamt == "0" && credamt == "0") { setDueAmt(parseFloat(totalamt - paidamt).toFixed(2)); }
        else if (paidamt != "0" && discamt != "0" && credamt == "0") { setDueAmt(parseFloat(totalamt - (paidamt + discamt)).toFixed(2)); }
        else if (paidamt != "0" && discamt == "0" && credamt != "0") { setDueAmt(parseFloat(totalamt - (paidamt + credamt)).toFixed(2)); }
        else if (paidamt == "0" && discamt != "0" && credamt != "0") { setDueAmt(parseFloat(totalamt - (discamt + credamt)).toFixed(2)); }
        else if (paidamt != "0" && discamt != "0" && credamt != "0") { setDueAmt(parseFloat(totalamt - (paidamt + (discamt + credamt))).toFixed(2)); }
        totalamt > 0 && totalamt === paidamt ? setHideAddPayButton(true) : setHideAddPayButton(false);
    }, [paidamt, discamt, credamt]);

    const GetUserAppointments = async (AppId) => {
        const requestOptions = {
            CentreId: localStorage.getItem("CentreId"),
            LoginId: localStorage.getItem("LoggedInUser"),
            AppointmentId: AppId,
            ModuleID: "2"
        };
        setIsLoading(true);
        let url = CallCentre_URL + 'Cart/getuserappointments'
        await axios.post(url, requestOptions).then((response) => {
            const data = response.data;
            setIsLoading(false);
            if (data.Success) {
                if (data.Data !== null) {
                    var IsOldVisit = "0";
                    var storetotalamt = 0;
                    var storedueamt = 0;
                    setOrderDetails(data.Data);
                    for (var x in data.Data) {
                        if (data.Data[x].ItemMasterId !== "0" && IsOldVisit === "0") {
                            IsOldVisit = "1"
                        }
                    }
                    if (IsOldVisit === "1") {
                        var patientname = [];
                        var visitids = "";
                        for (var n in data.Data) {
                            if (data.Data[n].ItemMasterId !== "0") {
                                patientname.push({
                                    PatientId: data.Data[n].PatientId,
                                    PatientName: data.Data[n].PatientName,
                                    Age: data.Data[n].PatientAge,
                                    Gender: data.Data[n].GenderName,
                                    VisitingId: data.Data[n].VisitingId
                                })
                                if (data.Data[n].VisitingId != "0") { visitids += data.Data[n].VisitingId + "," }
                            }
                        }
                        setAllVisitIds(visitids);
                        const unique = [...new Map(patientname.map((m) => [m.PatientId, m])).values()];
                        patientname = unique;
                        glblitemstore = [];
                        itemdetails = [];
                        for (var a in data.Data) {
                            if (data.Data[a].ItemMasterId !== "0") {
                                storetotalamt += parseFloat(data.Data[a].DiscountPrice);
                                storedueamt += parseFloat(data.Data[a].DiscountPrice);
                                itemdetails.push({
                                    Key: a,
                                    ItemMasterId: data.Data[a].ItemMasterId,
                                    IsPackage: data.Data[a].IsPackage === "True" ? "1" : data.Data[a].IsPackage === "False" ? "0" : data.Data[a].IsPackage,
                                    DiscountPrice: data.Data[a].DiscountPrice,
                                    PatientId: data.Data[a].PatientId,
                                    ActualPrice: data.Data[a].ActualPrice,
                                    ItemMasterName: data.Data[a].ItemMasterName,
                                    OffPercent: data.Data[a].OffPercent,
                                    HomeCollectionAvailable: data.Data[a].HomeCollectionAvailable
                                })
                                glblitemstore.push(
                                    {
                                        ItemSno: data.Data[a].ItemMasterId,
                                        ItemCode: data.Data[a].ItemCode,
                                        Item: data.Data[a].ItemMasterName,
                                        MRP: data.Data[a].ActualPrice,
                                        Disc: data.Data[a].DiscountAmt,
                                        Rate: data.Data[a].DiscountPrice,
                                        DeliveryDate: data.Data[a].DeliveryDate,
                                        MachineSNo: "0",
                                        AppointmentTime: data.Data[a].AppointmentTime,
                                        Prereqsite: data.Data[a].PreRequisite,
                                        Urgent: data.Data[a].Urgent === "True" ? "1" : "0",
                                        IsPackage: data.Data[a].IsPackage,
                                        SchemeId: data.Data[a].SchemeId
                                    }
                                );
                            }
                        }
                        setTotalAmt(storetotalamt);
                        if (paidamt == "0" && discamt == "0" && credamt == "0") { setDueAmt(parseFloat(storedueamt).toFixed(2)) }
                        else if (paidamt == "0" && discamt == "0" && credamt != "0") { setDueAmt(parseFloat(storedueamt - credamt).toFixed(2)); }
                        else if (paidamt == "0" && discamt != "0" && credamt == "0") { setDueAmt(parseFloat(storedueamt - discamt).toFixed(2)); }
                        else if (paidamt != "0" && discamt == "0" && credamt == "0") { setDueAmt(parseFloat(storedueamt - paidamt).toFixed(2)); }
                        else if (paidamt != "0" && discamt != "0" && credamt == "0") { setDueAmt(parseFloat(storedueamt - (paidamt + discamt)).toFixed(2)); }
                        else if (paidamt != "0" && discamt == "0" && credamt != "0") { setDueAmt(parseFloat(storedueamt - (paidamt + credamt)).toFixed(2)); }
                        else if (paidamt == "0" && discamt != "0" && credamt != "0") { setDueAmt(parseFloat(storedueamt - (discamt + credamt)).toFixed(2)); }
                        else if (paidamt != "0" && discamt != "0" && credamt != "0") { setDueAmt(parseFloat(storedueamt - (paidamt + (discamt + credamt))).toFixed(2)); }
                        setStoreItem(glblitemstore);
                        var jsonObject = itemdetails.map(JSON.stringify);
                        var uniqueSet = new Set(jsonObject);
                        var uniqueArray = Array.from(uniqueSet).map(JSON.parse);
                        ////////////////////
                        setAllPatientName(patientname);
                        setAllItemDetails(uniqueArray);
                        GetWebsiteAppointmentDetails(AppId);
                        var holdarr = [];
                        for (var key in data.Data) {
                            holdarr.push({
                                ItemMasterId: data.Data[key].ItemMasterId,
                                IsPackage: data.Data[key].IsPackage === "True" ? "1" : data.Data[key].IsPackage === "False" ? "0" : data.Data[key].IsPackage,
                            })
                        }
                        if (holdarr.length === 0) { localStorage.setItem('itemmasterdetails', []); }
                        else { localStorage.setItem('itemmasterdetails', JSON.stringify(holdarr)); }
                    }
                    else { localStorage.setItem('itemmasterdetails', []); }
                }
            }
            else {
                APIError(data.Data, data.ErrorList.length === 0 ? "" : data.ErrorList[0].errorMsg);
            }
        }).catch(function (error) {
            setIsLoading(false);
            if (error.response) {
                AxiosError(error.response.status);
            }
        });
    }
    const actionpaymentFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                {
                    rowData.OrderDetailsId === "0" && rowData.PaymentModeId === "13" && channelid == "8" ? null :
                        // rowData.OrderDetailsId !== "0" && (rowData.PaymentModeId === "1" || rowData.PaymentModeId === "13") ? null :
                        <DeleteToolTip title="Delete" placement="top" id={`DeletePayment${rowData.PaymentModeId}`} onClick={() => RemovePayment(rowData)} />
                }
            </div>
        </React.Fragment>
    }
    const paymentColumns = [
        { dataField: 'PaymentMode', text: 'Payment Mode', editable: false },
        { dataField: 'PaidAmount', text: 'Paid Amount', editable: false },
        { dataField: 'ReferenceNo', text: 'Reference No.', editable: false },
        { dataField: 'Authorizer', text: 'Authorizer', editable: false },
        { dataField: 'ConcessionReason', text: 'Concession Reason', editable: false },
        { dataField: '', text: 'Remove', editable: false, formatter: actionpaymentFormatter }
    ]
    const GetUserSampleCount = async (AppId) => {
        setIsLoading(true);
        let url = FrontDesk_URL + 'Phlebo/GetSampleCount/' + AppId
        await axios.get(url).then((response) => {
            const data = response.data;
            setIsLoading(false);
            if (data.Success) {
                if (data.Data !== null) {
                    setTableColorData(data.Data);
                }
            }
        }).catch(function (error) {
            setIsLoading(false);
            if (error.response) {
                AxiosError(error.response.status);
            }
        });
    }
    const GetWebsiteAppointmentDetails = async (AppId) => {
        let url = CallCentre_URL + 'master/getwebsiteappointmentdetails/' + AppId
        await axios.get(url).then((response) => {
            const data = response.data.Data;
            if (data === null) { } else {
                localStorage.setItem("appointmentdate", data.AppointmentDate);
                localStorage.setItem("appdate", data.SlotAppointmentDate);
                localStorage.setItem("apptime", data.SlotAppointmentTime);
                localStorage.setItem("apptimetext", data.FromTime.substring(0, 5) + " - " + data.ToTime.substring(0, 5));
                localStorage.setItem("appaddress", data.WebsiteAppointmentAddress);
                GetCartAddress("1");
            }
        }).catch(function (error) { if (error.response) { AxiosError(error.response.status); } });
    }
    const GetCartAddress = async (flag) => {
        setIsLoading(true);
        let url = CallCentre_URL + 'Cart/getcartaddress/' + localStorage.getItem("CentreId") + '/' + localStorage.getItem("LocationId") + '/' + loginid
        await axios.get(url).then((response) => {
            const data = response.data;
            setIsLoading(false);
            if (data.Success) {
                if (flag === "1") {
                    if (data.Data.carthomeaddress !== null) {
                        setCartHomeAddress(data.Data.carthomeaddress);
                        setAddressDetailId(localStorage.getItem("appaddress"));
                        // var datenew = new Date(localStorage.getItem("appdate")),
                        //     mnth = ("0" + (datenew.getMonth() + 1)).slice(-2),
                        //     day = ("0" + datenew.getDate()).slice(-2);
                        // var fslotdate = [datenew.getFullYear(), mnth, day].join("-");
                        var fdate = localStorage.getItem("appdate").split(" ");
                        var alldate = fdate[0].split("-");
                        var date = alldate[0];
                        var month = alldate[1];
                        var year = alldate[2];
                        // var date = alldate[2];
                        // var month = alldate[1];
                        // var year = alldate[0];
                        var finaldate = [month, date, year].join("-");
                        var datenew = new Date(finaldate),
                            mnth = ("0" + (datenew.getMonth() + 1)).slice(-2),
                            day = ("0" + datenew.getDate()).slice(-2);
                        var fslotdate = [datenew.getFullYear(), mnth, day].join("-");
                        RequestNewDate(fslotdate);
                    }
                    else {
                        setCartHomeAddress([]);
                    }
                }
            }
            else { APIError(data.Data, data.ErrorList.length === 0 ? "" : data.ErrorList[0].errorMsg); }
        }).catch(function (error) { setIsLoading(false); if (error.response) { AxiosError(error.response.status); } });
    }
    const RequestNewDate = (e) => {
        var finaltime = "";
        const value = e.split("-");
        const finaldate = toMonthName(value[1]) + " " + value[2] + ", " + value[0];
        setFinalDateTime(finaldate + ", " + finaltime);
        const valuenew = localStorage.getItem("apptimetext");
        finaltime = valuenew;
        setFinalDateTime(finaldate + ", " + finaltime);
    }
    const toMonthName = (monthNumber) => {
        const date = new Date();
        date.setMonth(monthNumber - 1);
        return date.toLocaleString('en-US', {
            month: 'long',
        });
    }
    const cancelBookingHandler = async () => {
        if (refcancelreason.current.value === "") { Swal.fire("Please enter cancellation reason."); return false; }
        Swal.fire({
            title: 'Are you sure?',
            text: "You are going to cancel an appointment request for " + finaldatetime,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Ok',
            reverseButtons: true,
            cancelButtonText: 'Cancel',
            cancelButtonColor: "#000",
        })
            .then((result) => {
                if (result.isConfirmed) {
                    CancelAppointment();
                }
                else { Swal.fire('Appointment still pending') }
            })
    }
    const CancelAppointment = async () => {
        const requestOptions = {
            CentreId: localStorage.getItem("CentreId"),
            LoginId: loginid,
            AllVisitIds: allvisitids,
            CancelReason: refcancelreason.current.value
        };
        setIsButtonLoading(true);
        let url = CallCentre_URL + 'Cart/CancelAppointment'
        await axios.post(url, requestOptions).then((response) => {
            const data = response.data;
            setIsButtonLoading(false);
            if (data.Success) {
                Swal.fire({
                    title: 'Success',
                    text: "Appointment cancelled",
                    icon: 'success',
                    showCancelButton: false,
                    confirmButtonText: 'Ok',
                    reverseButtons: true
                })
                    .then((result) => {
                        if (result.value) {
                            localStorage.setItem('itemmasterdetails', []);
                            setAppointmentId("0");
                            history.push({ pathname: `${process.env.PUBLIC_URL}/phlebo/myroutes`, search: `` });
                            // window.location.reload(false);
                        }
                        else {
                            localStorage.setItem('itemmasterdetails', []);
                            setAppointmentId("0");
                            history.push({ pathname: `${process.env.PUBLIC_URL}/phlebo/myroutes`, search: `` });
                            // window.location.reload(false);
                        }
                    })
            }
            else {
                APIError(data.Data, data.ErrorList.length === 0 ? "" : data.ErrorList[0].errorMsg);
            }
        }).catch(function (error) {
            setIsButtonLoading(false);
            if (error.response) {
                AxiosError(error.response.status);
            }
        });
    }
    const confirmBookingHandler = async () => {
        if (parseFloat(dueamt) != 0) {
            Swal.fire('Please add remaining due amount');
            return false;
        }
        else {

            Swal.fire({
                title: 'Are you sure?',
                text: "You are going to receive a payment",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Ok',
                reverseButtons: true,
                cancelButtonText: 'Cancel',
                cancelButtonColor: "#000",
            })
                .then((result) => {
                    if (result.isConfirmed) {
                        grandtotalamount = 0;
                        for (var key in allitemdetails) {
                            grandtotalamount += parseFloat(allitemdetails[key].DiscountPrice);
                        }
                        BookAppointment(grandtotalamount);
                    }
                    else { Swal.fire('Payment still pending') }
                })
        }
    }
    const BookAppointment = async (TotalAmount) => {
        setIsButtonLoading(true);
        const uniqueArray = [...new Set(orderDetails.map(q => q.VisitingId))];
        var resultresp;
        for (var k in uniqueArray) {
            if (uniqueArray[k] != "0") {
                glblitemstore = [];
                var visittotalamt = 0;
                var visitpaidamt = 0;
                var patientOrderDetails = [];
                var disAmtArr = [];
                var disAmt = 0;
                patientOrderDetails = orderDetails.filter((d) => d.VisitingId === uniqueArray[k]);
                for (var l in patientOrderDetails) {
                    visittotalamt += parseFloat(patientOrderDetails[l].DiscountPrice);
                    visitpaidamt += parseFloat(patientOrderDetails[l].DiscountPrice);
                    glblitemstore.push(
                        {
                            ItemSno: patientOrderDetails[l].ItemMasterId,
                            ItemCode: patientOrderDetails[l].ItemCode,
                            Item: patientOrderDetails[l].ItemMasterName,
                            MRP: patientOrderDetails[l].ActualPrice,
                            Disc: patientOrderDetails[l].DiscountAmt,
                            Rate: patientOrderDetails[l].DiscountPrice,
                            DeliveryDate: patientOrderDetails[l].DeliveryDate,
                            MachineSNo: "0",
                            AppointmentTime: patientOrderDetails[l].AppointmentTime,
                            Prereqsite: patientOrderDetails[l].PreRequisite,
                            Urgent: patientOrderDetails[l].Urgent === "True" ? "1" : "0",
                            IsPackage: patientOrderDetails[l].IsPackage,
                            SchemeId: patientOrderDetails[l].SchemeId
                        }
                    );
                }

                if (glblCheckboxList.length > 0) {
                    var docTextList = [];
                    const index = glbldoctext.findIndex(object => object.DocumentTypeSNo === "Checkbox");
                    if (index === -1) {
                        for (var i in glblCheckboxList) {
                            var isChcked = "false";
                            if (glblCheckboxList[i].Checked === true) { isChcked = "true"; }
                            glbldoctext.push({ DocumentSNo: glblCheckboxList[i].DocSNo, DocumentTypeSNo: "Checkbox", Document: isChcked });
                        }
                    }
                    else {
                        for (var x in glbldoctext) {
                            if (glbldoctext[x].DocumentTypeSNo.toLowerCase() !== "checkbox") {
                                docTextList.push({ DocumentSNo: glbldoctext[x].DocumentSNo, DocumentTypeSNo: glbldoctext[x].DocumentTypeSNo, Document: glbldoctext[x].Document });
                            }
                        }
                        glbldoctext = docTextList;
                        for (var i in glblCheckboxList) {
                            var isChcked = "false";
                            if (glblCheckboxList[i].Checked === true) { isChcked = "true"; }
                            glbldoctext.push({ DocumentSNo: glblCheckboxList[i].DocSNo, DocumentTypeSNo: "Checkbox", Document: isChcked });
                        }
                    }
                }
                var discountauthname = "";
                var discountauthemail = "";
                var creditauthname = "";
                var creditauthemail = "";
                for (var key in paymenttable) {
                    if (paymenttable[key].PaymentModeId === "14") {
                        discountauthname = discountauthorizerdetails.DiscountAuthorizerName === undefined ? "" : discountauthorizerdetails.DiscountAuthorizerName
                        discountauthemail = discountauthorizerdetails.DiscountAuthorizerEmail === undefined ? "" : discountauthorizerdetails.DiscountAuthorizerEmail
                    }
                    if (paymenttable[key].PaymentModeId === "15") {
                        creditauthname = creditauthorizerdetails.CreditAuthorizerName === undefined ? "" : creditauthorizerdetails.CreditAuthorizerName
                        creditauthemail = creditauthorizerdetails.CreditAuthorizerEmail === undefined ? "" : creditauthorizerdetails.CreditAuthorizerEmail
                    }
                }
                disAmtArr = paitientdiscount.filter((d) => d.visitid === patientOrderDetails[0].VisitingId);
                if (disAmtArr.length > 0)
                    disAmt = disAmtArr[0].discountAmt;
                const requestOptions = {
                    AlreadyAdded: "0",
                    VisitingId: patientOrderDetails[0].VisitingId,
                    Address: patientOrderDetails[0].Address,
                    AadharNo: orderDetails[k].AadharNo,
                    PatientName: patientOrderDetails[0].PatientName,
                    DOB: patientOrderDetails[0].DOB,
                    Age: patientOrderDetails[0].PatientAge,
                    Gender: patientOrderDetails[0].GenderName.toString() == "Male" ? "1" : "2",
                    RefreingDr: patientOrderDetails[0].RefreingDr,
                    OtherDr: patientOrderDetails[0].OtherDr,
                    EmailID: patientOrderDetails[0].EmailID,
                    MobileNo: orderDetails[k].MobileNo,
                    HouseNo: patientOrderDetails[0].HouseNo,
                    PinCode: patientOrderDetails[0].PinCode,
                    PatientImage: patientOrderDetails[0].PatientImage,
                    PatientTypeSNo: "1",
                    Source: patientOrderDetails[0].Source,
                    DesipatchMode: patientOrderDetails[0].DesipatchMode,
                    Remarks: patientOrderDetails[0].Remarks,
                    RateType: patientOrderDetails[0].RateTypeID,
                    MLC: patientOrderDetails[0].MLC,
                    DocList: null,
                    ItemDetailsList: JSON.stringify(glblitemstore),
                    PaymentDetailsList: JSON.stringify(paymenttable),
                    RequiredFieldsList: null,
                    PreRequisiteDocument: null,
                    TotalAmount: visittotalamt.toString(),
                    PaidAmount: visitpaidamt.toString(),
                    DueAmount: "0",
                    DiscAmount: "0",
                    CredAmount: "0",
                    DiscountAmount: disAmt.toString(),
                    DiscountReason: "",
                    selectPatientTypeSNo: "0",
                    OPDID: patientOrderDetails[0].OPDID,
                    HospitalUHID: "",
                    CentreSNo: localStorage.CentreSNo,
                    CreatedBy: localStorage.LoggedInUser,
                    Title: patientOrderDetails[0].Title,
                    VisitType: "Homecollection",
                    IsNewDoctor: "0",
                    SAPStateId: patientOrderDetails[0].SAPStateId,
                    SAPRegionId: patientOrderDetails[0].SAPRegionId,
                    SAPDistrictId: patientOrderDetails[0].SAPDistrictId,
                    RegNo: patientOrderDetails[0].PatientRegNo,
                    IsHold: "0",
                    OtherLabRefNo: patientOrderDetails[0].OtherLabRefNo,
                    DiscountAuthorizerName: discountauthname,
                    DiscountAuthorizerEmail: discountauthemail,
                    CreditAuthorizerName: creditauthname,
                    CreditAuthorizerEmail: creditauthemail,
                    PRO: patientOrderDetails[0].PRO,
                    Year: patientOrderDetails[0].PatientYear,
                    Month: patientOrderDetails[0].PatientMonth,
                    Day: patientOrderDetails[0].PatientDay,
                    PrescriptionDocument: null,
                    POSMachineId: "",
                    POSMachineName: "",
                    PaymentChannelId: "0",
                    TotalOrderAmount: TotalAmount.toString(),
                    PhelboBooking: "1",
                    IsItemLevelDiscount: "0",
                    PartnerTypeId: "0",
                    IsHLMParty: "0",
                    RefId: glblrefno
                };
                let url = FrontDesk_URL + 'master/addPatientRegistration'
                await axios.post(url, requestOptions).then((response) => {
                    setIsButtonLoading(false);
                    resultresp = response;
                }).catch(function (error) {
                    setIsButtonLoading(false);
                    if (error.response) {
                        AxiosError(error.response.status);
                    }
                }
                );
            }
        }
        if (resultresp != null) {
            setIsButtonLoading(false);
            if (resultresp.data.Success) {
                Swal.fire({
                    title: 'Success',
                    text: "Payment Done.Go to sample collection",
                    icon: 'success',
                    showCancelButton: false,
                    confirmButtonText: 'Ok',
                    reverseButtons: true
                })
                    .then((result) => {
                        if (result.value) {
                            localStorage.setItem('itemmasterdetails', []);
                            history.push({ pathname: `${process.env.PUBLIC_URL}/sample-collection/list`, search: `` });
                            window.location.reload(false);
                        }
                        else {
                            localStorage.setItem('itemmasterdetails', []);
                            history.push({ pathname: `${process.env.PUBLIC_URL}/sample-collection/list`, search: `` });
                            window.location.reload(false);
                        }
                    })

            }
            else {
                if (resultresp.data.ErrorList[0].errorCode == -3000) { Swal.fire('Insufficient cash.') }
                else if (resultresp.data.ErrorList[0].errorCode == -3001) { Swal.fire('Credit limit now allowed.') }
                else if (resultresp.data.ErrorList[0].errorCode == -3002) { Swal.fire('Credit limit amount should be equal to total amount.') }
                else if (resultresp.data.ErrorList[0].errorCode == -3003) { Swal.fire('Machine is not available for selected slot.') }
                else if (resultresp.data.ErrorList[0].errorCode == -3010) { Swal.fire('Patient with same mobile number already exists') }
                else if (resultresp.data.Data == -3005) { Swal.fire('Item already added in package.') }
                else if (resultresp.data.ErrorList[0].errorCode == -9997) { Swal.fire('Amount does not match, this is due to parallel edit of amount by another user. Please check edit again.') }
                else if (resultresp.data.ErrorList[0].errorCode == -2000 || resultresp.data.Data == -2000) { Swal.fire({ title: "API Runtime Exception", text: "Some error occured! Please try after sometime.", icon: "error" }) }
                else { Swal.fire({ title: "Application Exception", text: "Some error occured! Please try after sometime.", icon: "error" }) }

            }
        }

        else {
            setIsButtonLoading(false);
            Swal.fire({ title: "API Runtime Exception", text: "Some error occured! Please try after sometime.", icon: "error" });
        }
    }
    const ClearStoredState = () => {
        setAppointmentId("0");
        history.push({ pathname: `${process.env.PUBLIC_URL}/phlebo/myroutes`, search: `` });
    }
    const AxiosError = async (ErrorCode) => {
        if (ErrorCode === 400) { Swal.fire({ title: "Error 400", text: "Bad request", icon: "error" }) }
        else if (ErrorCode === 401) { Swal.fire({ title: "Error 401", text: "Unauthorized", icon: "error" }) }
        else if (ErrorCode === 403) { Swal.fire({ title: "Error 403", text: "Forbidden", icon: "error" }) }
        else if (ErrorCode === 404) { Swal.fire({ title: "Error 404", text: "Page not found", icon: "error" }) }
        else if (ErrorCode === 500) { Swal.fire({ title: "Error 500", text: "Internal Server Error", icon: "error" }) }
        else if (ErrorCode === 503) { Swal.fire({ title: "Error 503", text: "Service Unavailable", icon: "error" }) }
    }
    const APIError = async (ErrorCode, ErrorMsg) => {
        if (ErrorCode === "-1") { Swal.fire({ title: "Duplicate", text: "User already exist", icon: "error" }) }
        else if (ErrorCode === "0") { Swal.fire({ title: "Location not found", text: "Please select location", icon: "error" }) }
        else if (ErrorCode === "-1997") { Swal.fire({ title: "Empty", text: "No record found", icon: "error" }) }
        else if (ErrorCode === "-1998") { Swal.fire({ title: "Some value is missing", text: "Check server and db logs", icon: "error" }) }
        else if (ErrorCode === "-1999") { Swal.fire({ title: "Runtime Exception", text: ErrorMsg, icon: "error" }) }
        else { Swal.fire({ title: "Application Exception", text: "Some error occured! Please try after sometime", icon: "error" }) }
    }
    const GetPaymentMode = async (paymntType) => {
        try {
            glblPaymentModesList = [];
            var tmpPaymentModes = [];
            let url = FrontDesk_URL + 'master/GetPaymentModes'
            await axios.get(url).then((response) => {
                const data = response.data;
                if (data.Success) {
                    var allPaymentModes = data.Data;
                    for (var i in allPaymentModes) {
                        if (paymntType === "8" || paymntType === "9") {
                            setPaymentModeId("2");
                            if (allPaymentModes[i].SNo === "2") {
                                tmpPaymentModes.push({ "PaymentMode": allPaymentModes[i].PaymentMode, "SNo": allPaymentModes[i].SNo });
                            }
                        }
                        else {
                            if (paymentModeIdSetting === "2") {
                                setPaymentModeId("2");
                                if (allPaymentModes[i].SNo === "2" || allPaymentModes[i].SNo === "14") {
                                    tmpPaymentModes.push({ "PaymentMode": allPaymentModes[i].PaymentMode, "SNo": allPaymentModes[i].SNo });
                                }
                            }
                            else {
                                setPaymentModeId("1");
                                var _visitId = "0";
                                if (visitingid == "" || visitingid == undefined || visitingid == null || visitingid == "0") { _visitId = "0"; } else { _visitId = visitingid; }
                                if (_visitId !== "0") {
                                    if (allPaymentModes[i].SNo !== "2") {
                                        tmpPaymentModes.push({ "PaymentMode": allPaymentModes[i].PaymentMode, "SNo": allPaymentModes[i].SNo });
                                    }
                                }
                                else {
                                    if (allPaymentModes[i].SNo !== "2" && allPaymentModes[i].SNo !== "23") {
                                        tmpPaymentModes.push({ "PaymentMode": allPaymentModes[i].PaymentMode, "SNo": allPaymentModes[i].SNo });
                                    }
                                }
                            }
                        }
                    }
                    glblPaymentModesList = tmpPaymentModes;
                    var string = [];
                    for (var i in glblPaymentModesList) {
                        //if (glblPaymentModesList[i].SNo != "13") -- stopped online
                        string.push(<option key={glblPaymentModesList[i].SNo} value={glblPaymentModesList[i].SNo}>{glblPaymentModesList[i].PaymentMode}</option>);
                    }
                    setPaymentMode([]);
                    setPaymentMode(string);
                }
            }).catch(function (error) { if (error.response) { ErrorFunction(error.response.status); } });
        }
        catch (error) { Swal.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    }
    const ErrorFunction = async (ErrorCode) => {
        if (ErrorCode == "400")
            Swal.fire({ title: "Error 400", text: "Bad request", icon: "error" })
        else if (ErrorCode == "401")
            Swal.fire({ title: "Error 401", text: "Unauthorized", icon: "error" })
        else if (ErrorCode == "403")
            Swal.fire({ title: "Error 403", text: "Forbidden", icon: "error" })
        else if (ErrorCode == "404")
            Swal.fire({ title: "Error 404", text: "Page not found", icon: "error" })
        else if (ErrorCode == "500")
            Swal.fire({ title: "Error 500", text: "Internal Server Error", icon: "error" })
        else if (ErrorCode == "503")
            Swal.fire({ title: "Error 503", text: "Service Unavailable", icon: "error" })
    }
    const BindPaymentGrid = async () => {
        if (paymentmodedetail.current.value == "0") { Swal.fire('Please select payment mode'); return false }
        if (paymentamountdetail.current.value == "") { Swal.fire('Please add payment amount'); return false }
        if (paymentmodedetail.current.value == "13" && paymentChannelId.current.value === "0") { Swal.fire('Please select payment channel'); return false }
        if (paymentmodedetail.current.value == "13" && channelid !== "8" && paymentReferenceNo.current.value === "") { Swal.fire('Please add reference no'); return false }
        if (paymentmodedetail.current.value !== "23" && parseFloat(paymentamountdetail.current.value) <= 0 && dueamt >= 0) { Swal.fire('Please add payment amount more than ZERO'); return false }
        if (paymentmodedetail.current.value == "13") {
            for (var x in glblPaymentsList) {
                if (paymentChannelId.current.value === glblPaymentsList[x].PaymentChannelId && glblPaymentsList[x].OrderDetailsId == "0" && paymentmodedetail.current.value == "13") {
                    Swal.fire('Payment channel already selected, please remove selected channel or change it.');
                    return false;
                }
            }
        }
        else {
            for (var x in glblPaymentsList) {
                if (paymentmodedetail.current.value === glblPaymentsList[x].PaymentModeId && glblPaymentsList[x].OrderDetailsId == "0" && paymentmodedetail.current.value != "13") {
                    Swal.fire('Payment mode already selected, please remove selected mode or change it.');
                    return false;
                }
            }
        }
        if (paymentmodedetail.current.value == "13" && channelid == "8") {
            GetReferenceLink();
            // const options = {
            //     key: razorpaykey,
            //     amount: parseFloat(paymentamountdetail.current.value) * 100, //in paise
            //     currency: "INR",
            //     name: "Atulaya healthcare",
            //     //description: "Phlebo payment received",
            //     //image: "https://example.com/your_logo",
            //     handler: (response) => {
            //         const referenceno = response.razorpay_payment_id;
            //         paymentReferenceNo.current.value = referenceno;
            //         AddPaymentGrid();
            //     },
            //     // config: {
            //     //     display: {
            //     //         hide: [
            //     //             { method: 'paylater' },
            //     //             { method: 'emi' }
            //     //         ],
            //     //         preferences: { show_default_blocks: true }
            //     //     }
            //     // },
            //     // method: {
            //     //     netbanking: false,
            //     //     card: false,
            //     //     wallet: false,
            //     //     upi: true
            //     // },
            //     prefill: {
            //         name: "",
            //         email: localStorage.EmailId,
            //         contact: localStorage.MobileNo,
            //     },
            //     // notes: {
            //     //     address: "Razorpay Corporate Office",
            //     // },
            //     // theme: {
            //     //     color: "#3399cc",
            //     // },
            // };
            // const rzpay = new Razorpay(options);
            // rzpay.on("payment.failed", function (response) {
            //     const referenceno = response.error.metadata.payment_id;
            //     paymentReferenceNo.current.value = referenceno;
            // });
            // rzpay.open();
        }
        else {
            AddPaymentGrid();
        }
    }
    const AddPaymentGrid = async () => {
        if ((paymentmodedetail.current.value !== "1" && paymentmodedetail.current.value !== "13") && localStorage.MinBookingCash !== "") {
            if (parseFloat(localStorage.MinBookingCash) > 0) {
                var tmpValue = parseFloat(totalamt) * (parseFloat(localStorage.MinBookingCash) / 100);
                var alreadyAddedAmount = 0;
                var cardpaidexist = false;
                for (var x in glblPaymentsList) {
                    if (glblPaymentsList[x].PaymentModeId == "1" || glblPaymentsList[x].PaymentModeId == "13") {
                        cardpaidexist = true; alreadyAddedAmount += parseFloat(glblPaymentsList[x].PaidAmount)
                    }
                }
                if (cardpaidexist === true) {
                    if (parseFloat(alreadyAddedAmount) < parseFloat(tmpValue)) { Swal.fire('You have to pay atleast ' + parseFloat(tmpValue).toFixed(2) + ' in cash.'); return false }
                } else {
                    discoutamount = discoutamount + parseFloat(paymentamountdetail.current.value);
                    if (discoutamount > (parseFloat(totalamt) - parseFloat(tmpValue))) {
                        Swal.fire('You have to pay atleast ' + parseFloat(tmpValue).toFixed(2) + ' in cash.'); discoutamount = 0; return false
                    }
                }
            }
        }
        if (paymentmodedetail.current.value == "14" && paymentModeIdSetting !== "2") {
            var selectedAuthorizorInfo = glblDiscountAuthorizer.filter(item => (item.Id === authorizerId.current.value));
            var orderPercent = parseFloat(selectedAuthorizorInfo[0].PerOrderLimit);
            var monthlyAvailableLimit = parseFloat(selectedAuthorizorInfo[0].MonthlyLimit);
            var curntAmount = paymentamountdetail.current.value;

            if (Percentagecheckbox === "1") {
                var crntDiscount = parseFloat(totalamt) * (parseFloat(paymentamountdetail.current.value) / 100);
                if (parseFloat(crntDiscount) > parseFloat(monthlyAvailableLimit)) { Swal.fire('Authorizer does not have sufficient limit to approve this amount.'); return false }
                if (parseFloat(curntAmount) > parseFloat(orderPercent)) { Swal.fire('You can give ' + parseFloat(orderPercent).toFixed(2) + '% discount of total order value.'); return false }
                if (parseFloat(crntDiscount) > parseFloat(dueamt)) { Swal.fire('You can give discount of ' + parseFloat(dueamt).toFixed(2) + '.'); return false }
            }
            else {
                var maxOrderAmount = parseFloat(totalamt) * (parseFloat(orderPercent) / 100);
                if (parseFloat(curntAmount) > parseFloat(maxOrderAmount)) { Swal.fire('You can give ' + parseFloat(orderPercent).toFixed(2) + '% discount of total order value.'); return false }
                if (parseFloat(curntAmount) > parseFloat(monthlyAvailableLimit)) { Swal.fire('Authorizer does not have sufficient limit to approve this amount.'); return false }
            }
        }
        if (paymentmodedetail.current.value == "15") {
            var selectedAuthorizorInfo = glblCreditAuthorizer.filter(item => (item.Id === authorizerId.current.value));
            var orderPercent = parseFloat(selectedAuthorizorInfo[0].PerOrderLimit);
            var monthlyAvailableLimit = parseFloat(selectedAuthorizorInfo[0].MonthlyLimit);
            var curntAmount = paymentamountdetail.current.value;

            if (Percentagecheckbox === "1") {
                var crntOutstanding = parseFloat(totalamt) * (parseFloat(paymentamountdetail.current.value) / 100);
                if (parseFloat(crntOutstanding) > parseFloat(monthlyAvailableLimit)) { Swal.fire('Authorizer does not have sufficient limit to approve this amount.'); return false }
                if (parseFloat(curntAmount) > parseFloat(orderPercent)) { Swal.fire('You can use ' + parseFloat(orderPercent).toFixed(2) + '% of total order value from outstanding amount.'); return false }
                if (parseFloat(crntOutstanding) > parseFloat(dueamt)) { Swal.fire('You can use outstanding amount of ' + parseFloat(dueamt).toFixed(2) + '.'); return false }
            }
            else {
                var maxOrderAmount = parseFloat(totalamt) * (parseFloat(orderPercent) / 100);
                if (parseFloat(curntAmount) > parseFloat(monthlyAvailableLimit)) { Swal.fire('Authorizer does not have sufficient limit to approve this amount.'); return false }
                if (parseFloat(curntAmount) > parseFloat(maxOrderAmount)) { Swal.fire('You can use ' + parseFloat(orderPercent).toFixed(2) + '% of total order value from outstanding amount.'); return false }
            }

        }
        if (paymentmodedetail.current.value == "14" && paymentModeIdSetting === "2") {
            var orderPercent = parseFloat(centreDiscountLimit);
            var maxOrderAmount = parseFloat(totalamt) * (parseFloat(orderPercent) / 100);
            var curntAmount = paymentamountdetail.current.value;
            if (parseFloat(curntAmount) > parseFloat(maxOrderAmount)) { Swal.fire('You can give ' + parseFloat(orderPercent).toFixed(2) + '% discount of total order value.'); return false }
        }
        for (var x in glblPaymentsList) {
            if (paymentmodedetail.current.value === glblPaymentsList[x].PaymentModeId && glblPaymentsList[x].OrderDetailsId == "0") { Swal.fire('Payment mode already exist, please remove selected mode or change it.'); return false }
        }
        if (parseFloat(dueamt) < parseFloat(paymentamountdetail.current.value)) { Swal.fire('Amount cannot be greater than total amount'); return false }
        var payRefNo = ""; if (paymentReferenceNo.current !== null && paymentReferenceNo.current !== undefined) { payRefNo = paymentReferenceNo.current.value; }
        var _authorizerId = ""; if (authorizerId.current !== null && authorizerId.current !== undefined) { _authorizerId = authorizerId.current.value; }
        var _authorizer = ""; if (authorizerId.current !== null && authorizerId.current !== undefined) { _authorizer = authorizerId.current.options[authorizerId.current.selectedIndex].text; }
        var _concessionReason = ""; if (concessionReason.current !== null && concessionReason.current !== undefined) { _concessionReason = concessionReason.current.value; }
        var pChkbx = "";
        if (Percentagecheckbox === "1") { pChkbx = parseFloat(parseFloat(totalamt) * (parseFloat(paymentamountdetail.current.value) / 100)).toFixed(2); }
        else { pChkbx = paymentamountdetail.current.value; }
        let intCountr = 0;
        for (var x in glblPaymentsList) { intCountr = parseInt(glblPaymentsList[x].Id) + 1; }
        var txtPayMode = paymentmodedetail.current.options[paymentmodedetail.current.selectedIndex].text;
        var pymntChnlId = "0";
        if (paymentChannelId.current != undefined) { pymntChnlId = paymentChannelId.current.value; }
        if (paymentmodedetail.current.value === "13" && pymntChnlId !== "0") { txtPayMode = txtPayMode + "(" + paymentChannelId.current.options[paymentChannelId.current.selectedIndex].text + ")" }
        glblPaymentsList.push(
            {
                Id: intCountr, OrderDetailsId: "0", PaymentMode: txtPayMode, PaymentModeId: paymentmodedetail.current.value,
                PaymentChannelId: pymntChnlId, PaidAmount: pChkbx.toString(), ReferenceNo: payRefNo, AuthorizerId: _authorizerId,
                Authorizer: _authorizer, ConcessionReason: _concessionReason, Status: "0", DiscountRejectReason: "",
                POSMachineName: "", POSMachineId: "0"
            }
        );
        var totalPaidSoFar = 0;
        var totalDiscSoFar = 0;
        var totalCredSoFar = 0;
        setPaidAmt(0);
        setDiscAmt(0);
        setCredAmt(0);
        for (var x in glblPaymentsList) {
            if (glblPaymentsList[x].PaymentModeId === "1" || glblPaymentsList[x].PaymentModeId === "13") {
                totalPaidSoFar = totalPaidSoFar + parseFloat(glblPaymentsList[x].PaidAmount);
            }
            if (glblPaymentsList[x].PaymentModeId === "14") {
                totalDiscSoFar = totalDiscSoFar + parseFloat(glblPaymentsList[x].PaidAmount);
            }
            if (glblPaymentsList[x].PaymentModeId === "15" || glblPaymentsList[x].PaymentModeId === "2") {
                totalCredSoFar = totalCredSoFar + parseFloat(glblPaymentsList[x].PaidAmount);
            }
        }
        setPaidAmt(totalPaidSoFar);
        setDiscAmt(totalDiscSoFar);
        setCredAmt(totalCredSoFar);
        var _visitId = "0";
        if (visitingid == "" || visitingid == undefined || visitingid == null || visitingid == "0") { _visitId = "0"; } else { _visitId = visitingid; }
        if (_visitId === "0") {
            var tmpArray = [];
            for (var i in glblPaymentModesList) {
                var payModeExist = false;
                for (var j in glblPaymentsList) { if (glblPaymentsList[j].PaymentModeId == glblPaymentModesList[i].SNo) { payModeExist = true; break; } }
                if (payModeExist == false) {
                    // if (glblPaymentModesList[i].SNo != "13") -- stopped online
                    tmpArray.push(<option value={glblPaymentModesList[i].SNo}>{glblPaymentModesList[i].PaymentMode}</option>);
                }
            }
            setPaymentMode([]);
            setPaymentMode(tmpArray);
        }
        setPaymentTable(glblPaymentsList);
        paymentamountdetail.current.value = "";
        if (paymentReferenceNo.current !== undefined && paymentReferenceNo.current !== null) { paymentReferenceNo.current.value = ""; }
        paymentmodedetail.current.value = "0";
        ChangePaymentMode("0");
    }
    const RemovePayment = async (rowData) => {
        Swal.fire({ title: 'Are you sure?', text: "Once deleted, you will not be able to recover!", icon: 'warning', showCancelButton: true, confirmButtonText: 'Ok', cancelButtonText: 'Cancel', reverseButtons: true })
            .then((result) => {
                if (result.value) {
                    if (rowData.PaymentModeId == "14") {
                        discountpatientTotalAmt = 0;
                        setPaitientDiscount([]);
                        childCompRef.current.ClearDiscAmt();
                    }
                    setPaymentTable([]);
                    glblPaymentsList = glblPaymentsList.filter(function (x) { return x.Id !== rowData.Id; })
                    var totalPaidSoFar = 0;
                    var totalDiscSoFar = 0;
                    var totalCredSoFar = 0;
                    setPaidAmt(0);
                    setDiscAmt(0);
                    setCredAmt(0);
                    for (var x in glblPaymentsList) {
                        if (glblPaymentsList[x].PaymentModeId === "1" || glblPaymentsList[x].PaymentModeId === "13") {
                            totalPaidSoFar = totalPaidSoFar + parseFloat(glblPaymentsList[x].PaidAmount);
                        }
                        if (glblPaymentsList[x].PaymentModeId === "14") {
                            totalDiscSoFar = totalDiscSoFar + parseFloat(glblPaymentsList[x].PaidAmount);
                        }
                        if (glblPaymentsList[x].PaymentModeId === "15" || glblPaymentsList[x].PaymentModeId === "2") {
                            totalCredSoFar = totalCredSoFar + parseFloat(glblPaymentsList[x].PaidAmount);
                        }
                    }
                    setPaidAmt(totalPaidSoFar);
                    setDiscAmt(totalDiscSoFar);
                    setCredAmt(totalCredSoFar);
                    setPaymentTable(glblPaymentsList);
                    var _visitId = "0";
                    if (visitingid == "" || visitingid == undefined || visitingid == null || visitingid == "0") { _visitId = "0"; } else { _visitId = visitingid; }
                    if (_visitId === "0") {
                        var tmpArray = [];
                        for (var i in glblPaymentModesList) {
                            var payModeExist = false;
                            for (var j in glblPaymentsList) { if (glblPaymentsList[j].PaymentModeId == glblPaymentModesList[i].SNo) { payModeExist = true; break; } }
                            if (payModeExist == false) {
                                // if (glblPaymentModesList[i].SNo != "13")-- stopped online
                                tmpArray.push(<option value={glblPaymentModesList[i].SNo}>{glblPaymentModesList[i].PaymentMode}</option>);

                            }
                        }
                        setPaymentMode([]);
                        setPaymentMode(tmpArray);
                    }
                    paymentmodedetail.current.value = "0";
                    ChangePaymentMode("0");
                }
                else { Swal.fire('Your data is safe!') }
            });
    }
    const ChangePaymentMode = (value) => {
        setPaymentModeId(value);
        setdisablePayAmount(false);
        if (value === "14" || value === "15") { }
        else { setPercentagecheckbox("0"); }
        if (value === "0") { paymentamountdetail.current.value = ""; }
        else if (value === "14") {
            paymentamountdetail.current.value = discountpatientTotalAmt;
            setdisablePayAmount(true);
            paymentmodedetail.current.value = "14";
            //paymentmodedetail.current.setValue("14");
        }
        else { paymentamountdetail.current.value = dueamt; }
    }
    const SetAuthorizerDetails = (e) => {
        if (paymentModeId === "14") {
            for (var key in glblDiscountAuthorizer) {
                if (glblDiscountAuthorizer[key].Id === e.target.value) {
                    setDiscountAuthorizerDetails({
                        "DiscountAuthorizerName": glblDiscountAuthorizer[key].Name.split("[")[0],
                        "DiscountAuthorizerEmail": glblDiscountAuthorizer[key].EmailID
                    });
                    return false;
                }
            }
        }
        if (paymentModeId === "15") {
            for (var key in glblCreditAuthorizer) {
                if (glblCreditAuthorizer[key].Id === e.target.value) {
                    setCreditAuthorizerDetails({
                        "CreditAuthorizerName": glblCreditAuthorizer[key].Name.split("[")[0],
                        "CreditAuthorizerEmail": glblCreditAuthorizer[key].EmailID
                    });
                    return false;
                }
            }
        }
    }
    const handlePercentageCheckbox = (e) => {
        const checkedflg = e.target.checked;
        if (checkedflg) { setPercentagecheckbox("1"); } else { setPercentagecheckbox("0"); }
    };
    const GetAuthorizer = async (tId) => {
        try {
            let url = FrontDesk_URL + 'master/getauthorizers/' + localStorage.CentreSNo + '/' + tId
            await axios.get(url).then((response) => {
                const data = response.data;
                if (data.Success) {
                    if (tId === "1") {
                        glblDiscountAuthorizer = [];
                        glblDiscountAuthorizer = data.Data;
                        setDiscountAuthorizers(glblDiscountAuthorizer.map((d) => (<option key={d.Id} value={d.Id}>{d.Name}</option>)));
                        setDiscountAuthorizerDetails({ "DiscountAuthorizerName": data.Data[0].Name.split("[")[0], "DiscountAuthorizerEmail": data.Data[0].EmailID });
                    } else {
                        glblCreditAuthorizer = [];
                        glblCreditAuthorizer = data.Data;
                        setCreditAuthorizers(glblCreditAuthorizer.map((d) => (<option key={d.Id} value={d.Id}>{d.Name}</option>)));
                        setCreditAuthorizerDetails({ "CreditAuthorizerName": data.Data[0].Name.split("[")[0], "CreditAuthorizerEmail": data.Data[0].EmailID });
                    }
                }
            }).catch(function (error) { });
        }
        catch (error) { Swal.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    }
    const GetSettings = async (cId, uId) => {
        try {
            let url = FrontDesk_URL + 'master/getcentresettings/' + cId + '/' + uId;
            await axios.get(url).then((response) => {
                const data = response.data;
                if (data.Success) {
                    const settingInfo = data.Data;
                    let hideBookingAmount = false; if (settingInfo.HideBookingAmount === "True") { hideBookingAmount = true; }
                    let showBalanceAmount = false; if (settingInfo.ShowBalanceAmount === "True") { showBalanceAmount = true; }
                    let hideReceiptRate = false; if (settingInfo.HideReceiptRate === "True") { hideReceiptRate = true; }
                    let batchRequired = false; if (settingInfo.BatchRequired === "True") { batchRequired = true; }
                    let showMRPBill = false; if (settingInfo.ShowMRPBill === "True") { showMRPBill = true; }
                    let printDepartmentSlip = false; if (settingInfo.PrintDepartmentSlip === "True") { printDepartmentSlip = true; }
                    let showPROInRegistration = false; if (settingInfo.ShowPROInRegistration === "True") { showPROInRegistration = true; }
                    let showCentreDetailOnReport = false; if (settingInfo.ShowCentreDetailOnReport === "True") { showCentreDetailOnReport = true; }
                    let hideFooterOnWithoutLetterheadReport = false; if (settingInfo.HideFooterOnWithoutLetterheadReport === "True") { hideFooterOnWithoutLetterheadReport = true; }
                    let otherLabRefNo = false; if (settingInfo.OtherLabRefNo === "True") { otherLabRefNo = true; }
                    let allowRegistration = false; if (settingInfo.AllowRegistration === "True") { allowRegistration = true; }
                    setCreditLimitSetting(settingInfo.CreditLimit === "" ? 0 : settingInfo.CreditLimit);
                    setCentreDiscountLimit(settingInfo.CentreDiscountLimit === "" ? 0 : settingInfo.CentreDiscountLimit);
                    localStorage.setItem('MinBookingCash', settingInfo.MinBookingCash);
                    localStorage.setItem('CreditLimit', settingInfo.CreditLimit);
                }
            }).catch(function (error) { setIsLoading(false); });
        }
        catch (error) { Swal.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); setIsLoading(false); }
    }
    const GetPaymentChannels_Phlebo = async () => {
        razorpaykey = "";
        try {
            let url = FrontDesk_URL + 'master/getpaymentchannels_phlebo/' + localStorage.LoggedInUser
            await axios.get(url).then((response) => {
                const data = response.data;
                if (data.Success) {
                    setPaymentChannels(data.Data.map((d) => (<option key={d.SNo} value={d.SNo}>{d.PaymentChannel}</option>)));
                    razorpaykey = data.Data[0].RazorPayKey;
                }
            }).catch(function (error) { });
        }
        catch (error) { Swal.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    }
    const SelectChannel = async (e) => {
        setChannelId(e.target.value);
    }
    const GetReferenceLink = async () => {
        var grandtotalamount = paymentamountdetail.current.value;
        var unixtimstamp = Math.floor(new Date().getTime() * 1000);
        var description = "0";
        var centreusername = "";
        var mobileno = "";
        var email = "";
        var isExist = 0;
        for (var key in orderDetails) {
            if (orderDetails[key].EmailID != "" && orderDetails[key].MobileNo != "" && isExist == 0) {
                centreusername = orderDetails[key].PatientName;
                mobileno = orderDetails[key].MobileNo;
                email = orderDetails[key].EmailID;
                isExist = 1;
            }
        }
        if (mobileno == "") {
            centreusername = orderDetails[0].PatientName == "" ? "0" : orderDetails[0].PatientName;
            mobileno = orderDetails[0].MobileNo == "" ? "0" : orderDetails[0].MobileNo;
            email = orderDetails[0].EmailID == "" ? "0" : orderDetails[0].EmailID;
        }
        setMobileNumber(orderDetails[0].MobileNo == "" ? "0" : orderDetails[0].MobileNo);
        setIsButtonLoading(true);
        try {
            let url = FrontDesk_URL + 'accounts/getphlebopaymenturl/' + grandtotalamount.toString() + '/' + unixtimstamp.toString() + '/' + description + '/' + centreusername + '/' + mobileno + '/' + email + '/' + logincentreid + '/' + loginuserid
            await axios.get(url).then((response) => {
                const data = response.data;
                setIsButtonLoading(false);
                if (data.Success) {
                    if (data.Data == null) { Swal.fire("Some error occured! Please try again after sometime"); }
                    else {
                        if (data.Data == "-1001") {
                            Swal.fire("Currently don't have permission to access this feature. Please contact your admin.");
                        }
                        else {
                            CallExternalPaymentLink(data.Data.ReferenceId, data.Data.PaymentKey, grandtotalamount, unixtimstamp, description, centreusername, mobileno, email);
                        }
                    }
                }
                else {
                    setIsButtonLoading(false);
                    if (data.Data == "0") { Swal.fire("Payment link already sent to user.Click razorpay pending status button"); }
                    else { Swal.fire("Error Code: " + data.Data + " Check db logs!"); }
                }
            }).catch(function (error) { setIsButtonLoading(false); if (error.response) { ErrorFunction(error.response.status); } });
        } catch (error) { setIsButtonLoading(false); Swal.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    }
    const CallExternalPaymentLink = async (refId, paymentKey, amount, expiry, description, centreusername, mobileno, email) => {
        amount = parseFloat(amount) * 100;
        try {
            let url = FrontDesk_URL + 'accounts/callexternalpaymentlinkphlebo/' + refId + '/' + amount.toString() + '/' + expiry.toString() + '/' + description + '/' + (centreusername == "" ? "0" : centreusername) + '/' + (mobileno == "" ? "0" : mobileno) + '/' + (email == "" ? "0" : email)
            await axios.get(url).then((response) => {
                const data = response.data;
                setIsButtonLoading(false);
                if (data.Success) {
                    if (data.Data == "-1001") { setHideShowPaymentButton(false); Swal.fire("Currently don't have permission to access this feature. Please contact your admin."); }
                    else {
                        setGlblRefNo(refId);
                        setHideShowPaymentButton(true);
                        Swal.fire("Payment link sent to patient mobile number");
                        //window.open(data.Data, "_blank");
                    }
                }
                else {
                    setGlblRefNo("");
                    setIsButtonLoading(false);
                    setHideShowPaymentButton(false);
                    if (data.Data == "-2") { Swal.fire("No payment link generated.Please try again"); }
                    else { Swal.fire("Error : " + data.Data + " Check logs!"); }
                }
            }).catch(function (error) { setHideShowPaymentButton(false); setIsButtonLoading(false); if (error.response) { ErrorFunction(error.response.status); } });
        } catch (error) { setHideShowPaymentButton(false); setIsButtonLoading(false); Swal.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    }
    const CheckPaymentStatus = async () => {
        if (glblrefno == "") {
            Swal.fire("Reference no is empty to check status.Please try again");
            return false;
        }
        setIsLoading(true);
        let url = FrontDesk_URL + 'accounts/getphlebopaymentandupdatestatus/' + glblrefno
        await axios.get(url).then((response) => {
            const data = response.data;
            setIsLoading(false);
            if (data.Success) {
                if (data.Data == "-1001") { setHideShowPaymentButton(false); Swal.fire("Currently don't have permission to access this feature. Please contact your admin."); }
                else {
                    if (data.Data == "-2") { Swal.fire("Payment cancelled.Please try again."); }
                    else if (data.Data == "-3") { Swal.fire("Payment expired.Please try again."); }
                    else if (data.Data == "0") { Swal.fire("Payment still pending."); }
                    else if (data.Data == "-2000") { Swal.fire("Some error occured.Check db logs"); }
                    else {
                        setHideShowPaymentButton(false);
                        const referenceno = data.Data
                        paymentReferenceNo.current.value = referenceno;
                        // paymentmodedetail.current.value = "0";
                        // setChannelId("0");      
                        AddPaymentGrid();
                    }
                }
            }
            else {
                Swal.fire('Transaction failed while receiving response from razorpay.Please try again');
            }
        }).catch(function (error) {
            setIsLoading(false);
            if (error.response) { ErrorFunction(error.response.status); }
        });
    }
    const CheckExistingRazorPayment = async () => {
        var mobn = "0";
        mobn = orderDetails[0].MobileNo;
        if (mobn == "" || mobn == null || mobn == undefined || mobn == "0") {
            Swal.fire("Patient mobile is mandatory");
            return false;
        }
        setIsLoading(true);
        let url = FrontDesk_URL + 'accounts/checkexistingrazorpayment/' + mobn
        await axios.get(url).then((response) => {
            const data = response.data;
            setIsLoading(false);
            if (data.Success) {
                if (data.Data == null) { Swal.fire("No record found"); }
                else { setGlblRefNo(data.Data.ReferenceNo); CheckPaymentStatusRazorRecall(data.Data.ReferenceNo, data.Data.Amount); }
            }
            else {
                if (data.Data != null) { Swal.fire("Error is: " + data.Data); }
                else { Swal.fire("Some error occured.Check db logs"); }
            }
        }).catch(function (error) {
            setIsLoading(false);
            if (error.response) { ErrorFunction(error.response.status); }
        });
    }
    const CheckPaymentStatusRazorRecall = async (refno, amount) => {
        if (refno == "") {
            Swal.fire("Reference no is empty to check status.Please try again");
            return false;
        }
        setIsLoading(true);
        let url = FrontDesk_URL + 'accounts/getphlebopaymentandupdatestatus/' + refno
        await axios.get(url).then((response) => {
            const data = response.data;
            setIsLoading(false);
            if (data.Success) {
                if (data.Data == "-1001") { Swal.fire("Currently don't have permission to access this feature. Please contact your admin."); }
                else {
                    if (data.Data == "-2") { Swal.fire("Payment cancelled.Please try again."); }
                    else if (data.Data == "-3") { Swal.fire("Payment expired.Please try again."); }
                    else if (data.Data == "0") { Swal.fire("Payment still pending."); }
                    else if (data.Data == "-2000") { Swal.fire("Some error occured.Check db logs"); }
                    else {
                        const referenceno = data.Data
                        paymentReferenceNo.current.value = referenceno;
                        AddPaymentGridNew(referenceno, amount);
                    }
                }
            }
            else {
                Swal.fire('Transaction failed while receiving response from razorpay.Please try again');
            }
        }).catch(function (error) {
            setIsLoading(false);
            if (error.response) { ErrorFunction(error.response.status); }
        });
    }
    const AddPaymentGridNew = async (referenceno, amount) => {
        if ((paymentmodedetail.current.value !== "1" && paymentmodedetail.current.value !== "13") && localStorage.MinBookingCash !== "") {
            if (parseFloat(localStorage.MinBookingCash) > 0) {
                var tmpValue = parseFloat(totalamt) * (parseFloat(localStorage.MinBookingCash) / 100);
                var alreadyAddedAmount = 0;
                var cardpaidexist = false;
                for (var x in glblPaymentsList) {
                    if (glblPaymentsList[x].PaymentModeId == "1" || glblPaymentsList[x].PaymentModeId == "13") {
                        cardpaidexist = true; alreadyAddedAmount += parseFloat(glblPaymentsList[x].PaidAmount)
                    }
                }
                if (cardpaidexist === true) {
                    if (parseFloat(alreadyAddedAmount) < parseFloat(tmpValue)) { Swal.fire('You have to pay atleast ' + parseFloat(tmpValue).toFixed(2) + ' in cash.'); return false }
                } else {
                    discoutamount = discoutamount + parseFloat(amount);
                    if (discoutamount > (parseFloat(totalamt) - parseFloat(tmpValue))) {
                        Swal.fire('You have to pay atleast ' + parseFloat(tmpValue).toFixed(2) + ' in cash.'); discoutamount = 0; return false
                    }
                }
            }
        }
        if (paymentmodedetail.current.value == "14" && paymentModeIdSetting !== "2") {
            var selectedAuthorizorInfo = glblDiscountAuthorizer.filter(item => (item.Id === authorizerId.current.value));
            var orderPercent = parseFloat(selectedAuthorizorInfo[0].PerOrderLimit);
            var monthlyAvailableLimit = parseFloat(selectedAuthorizorInfo[0].MonthlyLimit);
            var curntAmount = amount;

            if (Percentagecheckbox === "1") {
                var crntDiscount = parseFloat(totalamt) * (parseFloat(amount) / 100);
                if (parseFloat(crntDiscount) > parseFloat(monthlyAvailableLimit)) { Swal.fire('Authorizer does not have sufficient limit to approve this amount.'); return false }
                if (parseFloat(curntAmount) > parseFloat(orderPercent)) { Swal.fire('You can give ' + parseFloat(orderPercent).toFixed(2) + '% discount of total order value.'); return false }
                if (parseFloat(crntDiscount) > parseFloat(dueamt)) { Swal.fire('You can give discount of ' + parseFloat(dueamt).toFixed(2) + '.'); return false }
            }
            else {
                var maxOrderAmount = parseFloat(totalamt) * (parseFloat(orderPercent) / 100);
                if (parseFloat(curntAmount) > parseFloat(maxOrderAmount)) { Swal.fire('You can give ' + parseFloat(orderPercent).toFixed(2) + '% discount of total order value.'); return false }
                if (parseFloat(curntAmount) > parseFloat(monthlyAvailableLimit)) { Swal.fire('Authorizer does not have sufficient limit to approve this amount.'); return false }
            }
        }
        if (paymentmodedetail.current.value == "15") {
            var selectedAuthorizorInfo = glblCreditAuthorizer.filter(item => (item.Id === authorizerId.current.value));
            var orderPercent = parseFloat(selectedAuthorizorInfo[0].PerOrderLimit);
            var monthlyAvailableLimit = parseFloat(selectedAuthorizorInfo[0].MonthlyLimit);
            var curntAmount = amount;

            if (Percentagecheckbox === "1") {
                var crntOutstanding = parseFloat(totalamt) * (parseFloat(amount) / 100);
                if (parseFloat(crntOutstanding) > parseFloat(monthlyAvailableLimit)) { Swal.fire('Authorizer does not have sufficient limit to approve this amount.'); return false }
                if (parseFloat(curntAmount) > parseFloat(orderPercent)) { Swal.fire('You can use ' + parseFloat(orderPercent).toFixed(2) + '% of total order value from outstanding amount.'); return false }
                if (parseFloat(crntOutstanding) > parseFloat(dueamt)) { Swal.fire('You can use outstanding amount of ' + parseFloat(dueamt).toFixed(2) + '.'); return false }
            }
            else {
                var maxOrderAmount = parseFloat(totalamt) * (parseFloat(orderPercent) / 100);
                if (parseFloat(curntAmount) > parseFloat(monthlyAvailableLimit)) { Swal.fire('Authorizer does not have sufficient limit to approve this amount.'); return false }
                if (parseFloat(curntAmount) > parseFloat(maxOrderAmount)) { Swal.fire('You can use ' + parseFloat(orderPercent).toFixed(2) + '% of total order value from outstanding amount.'); return false }
            }

        }
        if (paymentmodedetail.current.value == "14" && paymentModeIdSetting === "2") {
            var orderPercent = parseFloat(centreDiscountLimit);
            var maxOrderAmount = parseFloat(totalamt) * (parseFloat(orderPercent) / 100);
            var curntAmount = amount;
            if (parseFloat(curntAmount) > parseFloat(maxOrderAmount)) { Swal.fire('You can give ' + parseFloat(orderPercent).toFixed(2) + '% discount of total order value.'); return false }
        }
        for (var x in glblPaymentsList) {
            if (paymentmodedetail.current.value === glblPaymentsList[x].PaymentModeId && glblPaymentsList[x].OrderDetailsId == "0") { Swal.fire('Payment mode already exist, please remove selected mode or change it.'); return false }
        }
        if (parseFloat(dueamt) < parseFloat(amount)) { Swal.fire('Amount cannot be greater than total amount'); return false }
        var payRefNo = ""; if (paymentReferenceNo.current !== null && paymentReferenceNo.current !== undefined) { payRefNo = paymentReferenceNo.current.value; }
        var _authorizerId = ""; if (authorizerId.current !== null && authorizerId.current !== undefined) { _authorizerId = authorizerId.current.value; }
        var _authorizer = ""; if (authorizerId.current !== null && authorizerId.current !== undefined) { _authorizer = authorizerId.current.options[authorizerId.current.selectedIndex].text; }
        var _concessionReason = ""; if (concessionReason.current !== null && concessionReason.current !== undefined) { _concessionReason = concessionReason.current.value; }
        var pChkbx = "";
        if (Percentagecheckbox === "1") { pChkbx = parseFloat(parseFloat(totalamt) * (parseFloat(amount) / 100)).toFixed(2); }
        else { pChkbx = amount; }
        let intCountr = 0;
        for (var x in glblPaymentsList) { intCountr = parseInt(glblPaymentsList[x].Id) + 1; }
        var txtPayMode = paymentmodedetail.current.options[paymentmodedetail.current.selectedIndex].text;
        var pymntChnlId = "0";
        if (paymentChannelId.current != undefined) { pymntChnlId = paymentChannelId.current.value; }
        if (paymentmodedetail.current.value === "13" && pymntChnlId !== "0") { txtPayMode = txtPayMode + "(" + paymentChannelId.current.options[paymentChannelId.current.selectedIndex].text + ")" }
        glblPaymentsList.push(
            {
                Id: intCountr, OrderDetailsId: "0", PaymentMode: txtPayMode, PaymentModeId: paymentmodedetail.current.value,
                PaymentChannelId: pymntChnlId, PaidAmount: pChkbx.toString(), ReferenceNo: payRefNo, AuthorizerId: _authorizerId,
                Authorizer: _authorizer, ConcessionReason: _concessionReason, Status: "0", DiscountRejectReason: "",
                POSMachineName: "", POSMachineId: "0"
            }
        );
        var totalPaidSoFar = 0;
        var totalDiscSoFar = 0;
        var totalCredSoFar = 0;
        setPaidAmt(0);
        setDiscAmt(0);
        setCredAmt(0);
        for (var x in glblPaymentsList) {
            if (glblPaymentsList[x].PaymentModeId === "1" || glblPaymentsList[x].PaymentModeId === "13") {
                totalPaidSoFar = totalPaidSoFar + parseFloat(glblPaymentsList[x].PaidAmount);
            }
            if (glblPaymentsList[x].PaymentModeId === "14") {
                totalDiscSoFar = totalDiscSoFar + parseFloat(glblPaymentsList[x].PaidAmount);
            }
            if (glblPaymentsList[x].PaymentModeId === "15" || glblPaymentsList[x].PaymentModeId === "2") {
                totalCredSoFar = totalCredSoFar + parseFloat(glblPaymentsList[x].PaidAmount);
            }
        }
        setPaidAmt(totalPaidSoFar);
        setDiscAmt(totalDiscSoFar);
        setCredAmt(totalCredSoFar);
        var _visitId = "0";
        if (visitingid == "" || visitingid == undefined || visitingid == null || visitingid == "0") { _visitId = "0"; } else { _visitId = visitingid; }
        if (_visitId === "0") {
            var tmpArray = [];
            for (var i in glblPaymentModesList) {
                var payModeExist = false;
                for (var j in glblPaymentsList) { if (glblPaymentsList[j].PaymentModeId == glblPaymentModesList[i].SNo) { payModeExist = true; break; } }
                if (payModeExist == false) {
                    // if (glblPaymentModesList[i].SNo != "13") -- stopped online
                    tmpArray.push(<option value={glblPaymentModesList[i].SNo}>{glblPaymentModesList[i].PaymentMode}</option>);
                }
            }
            setPaymentMode([]);
            setPaymentMode(tmpArray);
        }
        setPaymentTable(glblPaymentsList);
        paymentamountdetail.current.value = "";
        if (paymentReferenceNo.current !== undefined && paymentReferenceNo.current !== null) { paymentReferenceNo.current.value = ""; }
        paymentmodedetail.current.value = "0";
        ChangePaymentMode("0");
    }
    return (
        <React.Fragment>
            <Breadcrumb title="Appointment Info" parent="Phlebo" />
            <Container fluid>
                <Row className='mb-2'>
                    {
                        appointmentId === "0" ? null :
                            <>
                                <Col md={12} className="text-right">
                                    <Button variant='secondary' type='submit' onClick={() => ClearStoredState()}>Back</Button>
                                </Col>
                            </>
                    }
                </Row>
                {
                    appointmentId === "0" ? null
                        :
                        <>
                            {
                                reviewstatus === true ?
                                    <>
                                        {
                                            finaldatetime === undefined ? null :
                                                <>
                                                    <Row>
                                                        <Col lg={8} md={12}>
                                                            <Order ref={childCompRef} paitientdiscount={paitientdiscount} onChangeSomeState={onChangeSomeState} PatientNames={allpatientname} ItemDetails={allitemdetails} Address={carthomeaddress} AddressId={addressdetailid} DateTime={finaldatetime} />
                                                        </Col>
                                                        <Col lg={4} md={12} className="mt-lg-0 mt-md-3">
                                                            <CartSummaryPhlebo PatientNames={allpatientname} ItemDetails={allitemdetails} reviewstatus={(reviewstatus) => setReviewStatus(reviewstatus)} statevalues={(statevalues) => setStateValues(statevalues)} />

                                                            {
                                                                tableColorData.length > 0 ?
                                                                    <Col lg={12} md={12} className="text-center mb-5 mt-3 p-0">
                                                                        <div>

                                                                            <Col md={12} className='mt-5 p-0'>
                                                                                <Card>
                                                                                    <Card.Header className={classes.bgTransparent}>
                                                                                        <p className='mb-0 fw-bold ft18 text-left'>Sample Details</p>
                                                                                    </Card.Header>
                                                                                    <Card.Body>

                                                                                        {
                                                                                            tableColorData.map((x, i) =>
                                                                                                <>
                                                                                                    {
                                                                                                        <>
                                                                                                            <Row className='mb-3'>
                                                                                                                <Col xs={4} className="text-left">
                                                                                                                    <span className={classes.textDark}>{x.PatientName}</span>
                                                                                                                </Col>
                                                                                                                <Col xs={2} className={classes.textDark}><div className='colorCode1' style={{ 'backgroundColor': `${x.Color}` }} id={`dv${x.VisitingId + i}`}></div></Col>
                                                                                                                <Col xs={4} className="text-left">
                                                                                                                    <span className={classes.textDark}>{x.SampleType}</span>
                                                                                                                </Col>
                                                                                                                <Col xs={2} className="text-right">
                                                                                                                    <span className={classes.priceTotal}>{x.Qty}</span>
                                                                                                                </Col>
                                                                                                            </Row>
                                                                                                        </>
                                                                                                    }
                                                                                                </>
                                                                                            )
                                                                                        }

                                                                                    </Card.Body>
                                                                                </Card>
                                                                            </Col>
                                                                        </div>
                                                                    </Col>
                                                                    :
                                                                    null
                                                            }
                                                            <OrderSummary PatientNames={allpatientname} ItemDetails={allitemdetails} />
                                                        </Col>
                                                    </Row>
                                                </>
                                        }
                                    </> :
                                    <>
                                        <AppointmentDetails UserExistFlag="1" Age={localStorage.getItem("Age")} Gender={localStorage.getItem("Gender")} CenterId={localStorage.getItem("CentreId")} AppointmentId={localStorage.getItem("aId")} Status="0"></AppointmentDetails>
                                    </>
                            }
                            <Row md={12}>
                                {
                                    parentcentreid === "0" && reviewstatus === true ?
                                        <>
                                            <Col md={12}>
                                                <Card>
                                                    <Card.Header className={classes.bgTransparent}>
                                                        <p className='mb-0 fw-bold ft18 text-left'>Payment</p>
                                                    </Card.Header>
                                                    <Card.Body>
                                                        <Form.Row className='align-items-center' style={{ marginTop: '30px' }}>
                                                            <Col md={12}>
                                                                <Form.Row>
                                                                    <Col md={2}>
                                                                        <Form.Group>
                                                                            <Form.Label>Payment Mode</Form.Label>
                                                                            <Form.Control as="select" ref={paymentmodedetail} onChange={(e) => { ChangePaymentMode(e.target.value) }} disabled={disablePayMode} >
                                                                                {<option value="0">----Select-----</option>}
                                                                                {paymentmode}
                                                                            </Form.Control>
                                                                        </Form.Group>
                                                                    </Col>
                                                                    {
                                                                        paymentModeId === "13" ?
                                                                            <Col md={2}>
                                                                                <Form.Group>
                                                                                    <Form.Label>Payment Channel</Form.Label>
                                                                                    <Form.Control as="select" ref={paymentChannelId} onChange={(e) => SelectChannel(e)}>
                                                                                        {<option value="0">----Select-----</option>}
                                                                                        {paymentChannels}
                                                                                    </Form.Control>
                                                                                </Form.Group>
                                                                            </Col>
                                                                            : null
                                                                    }
                                                                    {
                                                                        (paymentModeId === "15") && paymentModeIdSetting !== "2" ?
                                                                            // (paymentModeId === "14" || paymentModeId === "15") && paymentModeIdSetting !== "2" ?
                                                                            <Col md={2}>
                                                                                <Form.Group style={{ marginTop: 35, marginLeft: 20 }}>
                                                                                    <input className="checkbox_animated" id="inpercentage" type="checkbox" onClick={(e) => { handlePercentageCheckbox(e); }} /> In Percentage
                                                                                </Form.Group>
                                                                            </Col> :
                                                                            null
                                                                    }
                                                                    {Percentagecheckbox === "0" ?
                                                                        <Col md={2}>
                                                                            <Form.Group>
                                                                                <Form.Label>Amount</Form.Label>
                                                                                <Form.Control type="text" ref={paymentamountdetail} disabled={disablePayAmount} />
                                                                            </Form.Group>
                                                                        </Col>
                                                                        :
                                                                        <Col md={2}>
                                                                            <Form.Group>
                                                                                <Form.Label>In Percentage Amount</Form.Label>
                                                                                <Form.Control type="text" ref={paymentamountdetail} />
                                                                            </Form.Group>
                                                                        </Col>
                                                                    }
                                                                    {paymentModeId === "13" ?
                                                                        <>
                                                                            {
                                                                                channelid === "0" ? null :
                                                                                    <>
                                                                                        {
                                                                                            channelid == "8" ?
                                                                                                <Col md={3}>
                                                                                                    <Form.Group>
                                                                                                        <Form.Label>Reference No. <span className='text-red'>(Max 50 characters)</span></Form.Label>
                                                                                                        <Form.Control type="text" maxLength={50} ref={paymentReferenceNo} disabled />
                                                                                                    </Form.Group>
                                                                                                </Col>
                                                                                                :
                                                                                                <Col md={3}>
                                                                                                    <Form.Group>
                                                                                                        <Form.Label>Reference No. <span className='text-red'>(Max 50 characters)</span></Form.Label>
                                                                                                        <Form.Control type="text" maxLength={50} ref={paymentReferenceNo} />
                                                                                                    </Form.Group>
                                                                                                </Col>
                                                                                        }
                                                                                    </>
                                                                            }
                                                                        </>
                                                                        : (paymentModeId === "14" || paymentModeId === "15") && paymentModeIdSetting !== "2" ?
                                                                            <>
                                                                                <Col md={2}>
                                                                                    <Form.Group>
                                                                                        <Form.Label>{paymentModeId === "14" ? "Discount Authorizer" : "Credit Authorizer"}</Form.Label>
                                                                                        <Form.Control as="select" ref={authorizerId} onChange={(e) => SetAuthorizerDetails(e)}>
                                                                                            {paymentModeId === "14" ? discountAuthorizers : creditAuthorizers}
                                                                                        </Form.Control>
                                                                                    </Form.Group>
                                                                                </Col>
                                                                                <Col md={3}>
                                                                                    <Form.Group>
                                                                                        <Form.Label>{paymentModeId === "14" ? "Discount" : "Credit"} Reason</Form.Label>
                                                                                        <Form.Control type="text" ref={concessionReason} />
                                                                                    </Form.Group>
                                                                                </Col>
                                                                            </>
                                                                            : null}
                                                                    {
                                                                        hideAddPayButton ? null :
                                                                            <>
                                                                                {
                                                                                    hideshowpaymentbutton === false ?
                                                                                        <>
                                                                                            <Col md={2} xs={12} className='text-center text-md-left'>
                                                                                                <Form.Group className='m-t-15'>
                                                                                                    <Form.Label className='icRight'>
                                                                                                        <Button variant='secondary' onClick={BindPaymentGrid}>Add</Button>
                                                                                                    </Form.Label>
                                                                                                </Form.Group>
                                                                                            </Col>
                                                                                            {
                                                                                                channelid == "8" ?
                                                                                                    <Col md={2} xs={11}>
                                                                                                        <Form.Group className='m-t-15'>
                                                                                                            <Form.Label className='icRight'>
                                                                                                                {isLoading ? <LoadingButton variantName='secondary' cssName="" /> :
                                                                                                                    <Button variant='primary' onClick={CheckExistingRazorPayment}>Check razorpay pending status</Button>}
                                                                                                            </Form.Label>
                                                                                                        </Form.Group>
                                                                                                    </Col> : null
                                                                                            }
                                                                                        </>
                                                                                        :
                                                                                        <Col md={2} xs={12} className='text-center text-md-left'>
                                                                                            <Form.Group className='m-t-15'>
                                                                                                <Form.Label className='icRight'>
                                                                                                    {isLoading ? <LoadingButton variantName='secondary' cssName="" /> :
                                                                                                        <Button variant='secondary' onClick={CheckPaymentStatus}>Check payment status</Button>}
                                                                                                </Form.Label>
                                                                                            </Form.Group>
                                                                                        </Col>
                                                                                }
                                                                            </>
                                                                    }
                                                                </Form.Row>
                                                            </Col>
                                                            <Col md={12}>
                                                                <Form.Row>
                                                                    <Col md={12} className="text-left">
                                                                        <p><b>Total Amount:</b><i class="fa fa-inr"></i>{totalamt} &nbsp;&nbsp;&nbsp;&nbsp;<b>Due Amount:</b><i class="fa fa-inr"></i>{dueamt}</p>
                                                                        <p><b>Discount Amount:</b><i class="fa fa-inr"></i>{discamt}&nbsp;&nbsp;&nbsp;&nbsp;<b>Credit Amount:</b><i class="fa fa-inr"></i>{credamt}</p>
                                                                        <p><b>Paid Amount:</b><i class="fa fa-inr"></i>{paidamt}</p>

                                                                    </Col>
                                                                </Form.Row>
                                                            </Col>
                                                        </Form.Row>
                                                        <div className="table-responsive mt15">
                                                            <DataTables keyField='PaymentModeId' tableData={paymenttable} columns={paymentColumns} noDataIndication={NoRecordFounds} />
                                                        </div>
                                                        <Form.Row>
                                                            <Col md={12} className="mt-4 text-center">
                                                                {isButtonLoading ? <LoadingButton variantName='primary' cssName="" /> :
                                                                    <Button variant="dark" className='ml-2' type='submit' onClick={(e) => confirmBookingHandler()}>Pay Now</Button>}
                                                            </Col>
                                                        </Form.Row>
                                                    </Card.Body>
                                                </Card>
                                            </Col>
                                        </> :
                                        null

                                }
                            </Row>
                            <Row md={12}>
                                {<Col lg={12} md={12} className='text-left align-items-center justify-content-between'>
                                    <div>
                                        {
                                            isButtonLoading ?
                                                <LoadingButton variantName='dark' cssName="" /> :
                                                <>
                                                    <Col md={12} className='mt-5 p-0'>
                                                        <Card>
                                                            <Card.Header className={classes.bgTransparent}>
                                                                <p className='mb-0 fw-bold ft18 text-left'>Enter Cancellation Reason</p>
                                                            </Card.Header>
                                                            <Card.Body>
                                                                <Form>
                                                                    <Form.Group className="mb-3">
                                                                        <Form.Control as="textarea" rows={3} name="cancelreason" ref={refcancelreason} />
                                                                    </Form.Group>
                                                                </Form>
                                                            </Card.Body>
                                                        </Card>
                                                    </Col>
                                                    <Col md={12} className='mt-5 p-0 text-center'>
                                                        <Button variant="primary" type='submit' onClick={(e) => cancelBookingHandler()}>Cancel</Button>
                                                    </Col>
                                                </>
                                        }
                                    </div>
                                </Col>
                                }
                            </Row>
                        </>
                }


            </Container>
        </React.Fragment>
    )
}

export default RouteInfo;