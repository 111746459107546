import React, { Fragment } from 'react'

const LoginButton = (props) => {
    return (
        <Fragment>
                <div className="form-group form-row mt-3 mb-0">
                    <button className="btn btn-primary btn-block" type="button" ref={props.reference} onClick={props.onClick} >Login</button>
                </div>
        </Fragment>
    )
}

export default LoginButton
