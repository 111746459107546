import React, { useState, useEffect, useRef } from 'react';
import SweetAlert from 'sweetalert2';
import Breadcrumb from '../../components/common/breadcrumb';
import { Container, Row, Col, Card, Button, Table, Form } from 'react-bootstrap';
import Alert from "react-bootstrap/Alert";
import InputGroup from 'react-bootstrap/InputGroup';
import ImageUploader from 'react-images-upload';
import DeleteToolTip from '../UI/ToolTips/DeleteToolTip';
import { FrontDesk_URL, PineLabs_URL, Textarea } from '../../constant';
import axios from 'axios';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import DataTables from '../commonComponent/DataTable/DataTables';
import NoRecordFounds from '../commonComponent/DataTable/NoRecordFounds';
import { Typeahead } from 'react-bootstrap-typeahead';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ViewPackageToolTip from '../UI/ToolTips/ViewPackageToolTip';
import profile from '../../assets/images/profile.png'
import { Spinner } from 'react-bootstrap';
import LoadingButton from '../commonComponent/LoadingButton';
import ExportPdfComponent from '../commonComponent/ExportPdfComponent ';
import CommonToolTip from '../UI/ToolTips/CommonToolTip';
import Select from 'react-select';
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';
import PrintPDFReceipt from '../frontOffice/PdfPrintReceipt';

var glblVisitValue = "0";
var refreshyear = "";
var refreshmonth = "";
var fday = "";
var fmonth = "";
var fyear = "";
var FinalYear = "";
var FinalMonth = "";
var FinalDay = "";
var NewFinalMonth = "";
var NewFinalDay = "";
var dtDay = "";
var dtMonth = "";
var dtYear = "";
var pcid = "";
var discoutamount = 0;
var chkflag = '';
var glblitemstore = [];
var glbldoctext = [];
var ids = "";
var editids = "";
var glblpatientitmdetId = "";
var pattypecheck = "";
var PackageItemId = "";
var PackageIdFlag = "";
var chkeditornot = "";
var glblreqfieldstore = [];
var glblreqfieldstoredetails = [];
var glblprerequisitefieldstore = [];
var glblRecordsData = "";
var glblprerequisitestoredetails = [];
var glblDocumentArr = [];
var glblDefaultRec = [];
var glblArrUrgent = [];
var templateid = "";
var glbEditTestsList = [];
var glbEditVisitId = [];
var gridItemList = [];
var glblCheckboxList = [];
var glblDiscountAuthorizer = [];
var glblCreditAuthorizer = [];
var glblPaymentsList = [];
var glblPaymentModesList = [];
var paymentModeIdSetting = "";
var glblsource = [];
var glblposmachine = [];
const Registration = () => {
    const [patienttype, setPatientType] = useState(0);
    const [dispatchmode, setDispatchMode] = useState(0);
    const [viasource, setViaSource] = useState([]);
    const [paymentmode, setPaymentMode] = useState([]);
    const [ratetype, setRateType] = useState(0);
    const [genders, setGenders] = useState(0);
    const [patienttypecheck, setPatientTypeCheck] = useState("0");
    const [patienttypenamecheck, setPatientTypeNameCheck] = useState("Corporate");
    const [globaldocument, setGlobalDocument] = useState(0);
    const [globalcheck, setGlobalCheck] = useState("0");
    const [options, setOptions] = useState([]);
    const [refdocoptions, setRefDocOptions] = useState([]);
    const [table, setTable] = useState([]);
    const [itemsno, setItemSno] = useState("");
    const [refdocsno, setRefDocSno] = useState("");
    //const [refdocaddname, setRefDocAddName] = useState(""); after disable doctor search in add new
    const [modal, setModal] = useState();
    const [errorModal, setErrorModal] = useState();
    const [imagename, setImageName] = useState("");
    const [checkbox, setCheckbox] = useState(0);
    const [chkBoxPrescription, setChkBoxPrescription] = useState(0);
    const [totaltest, setTotalTest] = useState(0);
    const [totalamt, setTotalAmt] = useState(0);
    const [changeflag, setChangeFlag] = useState(false);
    const [paidamt, setPaidAmt] = useState(0);
    const [discamt, setDiscAmt] = useState(0);
    const [credamt, setCredAmt] = useState(0);
    const [dueamt, setDueAmt] = useState(0);
    const [storeitem, setStoreItem] = useState([]);
    const [chkurgentflag, setChkUrgentFlag] = useState("");
    const [paymenttable, setPaymentTable] = useState([]);
    const [globaldocuments, setGlobalDocuments] = useState([]);
    const [storedocumentitem, setStoreDocumentItem] = useState([]);
    const [disablefields, setDisableFields] = useState({});
    const [editdisablefields, setEditDisableFields] = useState({});
    const [edittabledataavailable, setEditTableDataAvailable] = useState(false);
    const [edittable, setEditTable] = useState([]);
    const [moboruhidoptions, setMobOrUhidOptions] = useState([]);
    const [refdocname, setRefDocName] = useState("");
    const [holdmobileno, setHoldMobileNo] = useState("0");
    const [patregno, setPatRegNo] = useState("");
    const [invoiceModal, setInvoiceModal] = useState();
    const [invoicedetails, setInvoiceDetails] = useState({});
    const [invoicetable, setInvoiceTable] = useState([]);
    const [patientsno, setPatientSNo] = useState("");
    const [invoicegenerated, setInvoiceGenerated] = useState(false);
    const [dobtext, setDobText] = useState();
    const [editdobtext, setEditDobText] = useState();
    const [agetext, setAgeText] = useState();
    const [editagetext, setEditAgeText] = useState();
    const [editdetails, setEditDetails] = useState(false);
    const [prerequisite, setPreRequisite] = useState([]);
    const [orderdate, setOrderDate] = useState(0);
    const [visitingid, setVisitingId] = useState(0);
    const [alreadyaddedmore, setAlreadyAddedMore] = useState(0);
    const [packageModal, setPackageModal] = useState(false);
    const [crntPageNO, setCrntPageNO] = useState(0);
    const [popupTableData, setPopupTableData] = useState([]);
    const [viewpackageitems, setViewPackageItems] = useState([]);
    const [viewcities, setViewCities] = useState([]);
    const [viewpackageModal, setViewPackageModal] = useState();
    const [cityModal, setCityModal] = useState(false);
    const [viewpanelitems, setViewPanelItems] = useState([]);
    const [viewpanelModal, setViewPanelModal] = useState();
    const [viewreqfieldsitems, setViewReqFieldsItems] = useState([]);
    const [viewreqfieldsModal, setViewReqFieldsModal] = useState();
    const [viewprerequisiteitems, setViewPreRequisiteItems] = useState([]);
    const [doctorModal, setDoctorModal] = useState();
    const [demographicModal, setDemographicModal] = useState();
    const [remarksModal, setRemarksModal] = useState();
    const [showdiscount, setShowDiscount] = useState(true);
    const [newdoctorflag, setNewDoctorFlag] = useState("0");
    const [status, setStatus] = useState(false);
    const [alldisablefields, setAllDisableFields] = useState({});
    const [sourcenew, setSourceNew] = useState("0");
    const [dispatchmodenew, setDispatchModeNew] = useState("0");
    const [remarksnew, setRemarksNew] = useState("");
    const [holdmobvalue, setHoldMobValue] = useState();
    const [hideeditsavefield, setHideEditSaveField] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isPageLoading, setIsPageLoading] = useState(false);
    const [isButtonLoading, setIsButtonLoading] = useState(false);
    const [consentFormName, setConsentFormName] = useState(false);
    const [isPrintConsent, setIsPrintConsent] = useState(false);
    const [isHold, setIsHold] = useState(false);
    const [checkedState, setCheckedState] = useState(new Array(glblCheckboxList.length).fill(false));
    const [Percentagecheckbox, setPercentagecheckbox] = useState("0");
    const [discountauthorizerdetails, setDiscountAuthorizerDetails] = useState({});
    const [creditauthorizerdetails, setCreditAuthorizerDetails] = useState({});
    const [PROName, setPROName] = useState([]);
    const [paymentChannels, setPaymentChannels] = useState([]);
    const [posmachines, setPOSMachines] = useState([]);
    const [prescriptiondocument, setPrescriptionDocument] = useState("");
    const [editviasource, setEditViaSource] = useState("");
    const [editviasourcename, setEditViaSourceName] = useState("");
    const [refdocnamevisible, setRefDocNameVisible] = useState(false);
    const [edititemsflag, setEditItemsFlag] = useState(false);
    const [posmachineid, setPosMachineId] = useState("0");
    const [posmachinename, setPosMachineName] = useState("");
    const [paychannelid, setPayChannelId] = useState("0");
    const [channelid, setChannelId] = useState("0");
    const [editdetailsallowed, setEditDetailsAllowed] = useState(false);
    const [editdemographicdetailsallowed, setEditDemographicDetailsAllowed] = useState(false);
    const [proSelected, setproSelected] = useState([]);
    const [pendingtime, setPendingTime] = useState("0");
    const [defaultptype, setDefaultPType] = useState("0");
    const [defaultglobaldocument, setDefaultGlobalDocument] = useState("0");
    const [districtsList, setDistrictsList] = useState([]);
    const [statesList, setStatesList] = useState([]);
    const [refDoctorName, setRefDoctorName] = useState("");
    const [disablePayMode, setDisablePayMode] = useState(false);
    const [paymentModeId, setPaymentModeId] = useState("0");
    const [discountAuthorizers, setDiscountAuthorizers] = useState([]);
    const [creditAuthorizers, setCreditAuthorizers] = useState([]);
    const [hideAddPayButton, setHideAddPayButton] = useState(false);
    const [PartnerTypeIdSetting, setPartnerTypeIdSetting] = useState("0");
    const [PartnerTypePincodeSetting, setPartnerTypePincodeSetting] = useState("");
    const [PartnerTypeCentreAddressSetting, setPartnerTypeCentreAddressSetting] = useState("");
    const [PartnerTypeSAPStateIdSetting, setPartnerTypeSAPStateIdSetting] = useState("0");
    const [PartnerTypeSAPRegionIdSetting, setPartnerTypeSAPRegionIdSetting] = useState("0");
    const [PartnerTypeSAPDistrictIdSetting, setPartnerTypeSAPDistrictIdSetting] = useState("0");
    const [PartnerTypeEmailIdSetting, setPartnerTypeEmailIdSetting] = useState("");
    const [PartnerTypeContactNoSetting, setPartnerTypeContactNoSetting] = useState("");
    const [HideAmountInBookingSetting, setHideAmountInBookingSetting] = useState(false);
    const [ShowBalanceAmountSetting, setShowBalanceAmountSetting] = useState(false);
    const [HideReceiptRateSetting, setHideReceiptRateSetting] = useState(false);
    const [PrintDepartmentSlipSetting, setPrintDepartmentSlipSetting] = useState(false);
    const [ShowPROInRegistrationSetting, setShowPROInRegistrationSetting] = useState(false);
    const [HideFooterOnWithoutLetterheadReport, setHideFooterOnWithoutLetterheadReportSetting] = useState(false);
    const [OtherLabRefNoSetting, setOtherLabRefNoSetting] = useState(false);
    const [CreditLimitSetting, setCreditLimitSetting] = useState(0);
    const [BookingLimitSetting, setBookingLimitSetting] = useState(0);
    const [ShowMRPBillSetting, setShowMRPBillSetting] = useState(false);
    const [disableBookings, setDisableBookings] = useState(false);
    const [centreDiscountLimit, setCentreDiscountLimit] = useState(0);
    const [centreTypeIdSetting, setCentreTypeIdSetting] = useState("0");
    const [count, setCount] = useState(0);
    const [allowRegistrationSetting, setAllowRegistrationSetting] = useState('falsee');
    const [UserCustomerCode, setUserCustomerCode] = useState("");
    const [UserCashCode, setUserCashCode] = useState("");
    const [UserVendorCode, setUserVendorCode] = useState("");
    const [UserPOSCode, setUserPOSCode] = useState("");
    const [parentcentreid, setParentCentreId] = useState("0");
    const [plutustransrefid, setPlutusTransRefId] = useState("0");
    const [transactionno, setTransactionNo] = useState("0");
    const [merchantstorepostcode, setMerchantStorePostCode] = useState("");
    const [imei, setIMEI] = useState("");
    const [hideshowpaymentbutton, setHideShowPaymentButton] = useState(false);
    const [sapstatelist, setSAPStateList] = useState([]);
    const [sapregionlist, setSAPRegionList] = useState([]);
    const [sapdistrictlist, setSAPDistrictList] = useState([]);
    const [specializationlist, setSpecializationList] = useState([]);
    const [doctortitlelist, setDoctorTitleList] = useState([]);
    const [dobstatus, setDobStatus] = useState(false);
    const [editdobstatus, setEditDobStatus] = useState(false);
    const [finaldateofbirth, setFinalDateOfBirth] = useState("");
    const [editfinaldateofbirth, setEditFinalDateOfBirth] = useState("");
    const [iscentreadmin, setIsCentreAdmin] = useState(false);
    const [itemlevelvalue, setItemLevelValue] = useState(false);
    const [totalildiscount, setTotalILDiscount] = useState(0);
    const [totalhighpercent, setTotalHighPercent] = useState(0);
    const [hlmparty, setHLMParty] = useState("0");
    const [localcentreid, setLocalCentreId] = useState(localStorage.CentreSNo);
    const [loginuserid, setLoginUserId] = useState(localStorage.LoggedInUser == undefined || localStorage.LoggedInUser == null ? "" : localStorage.LoggedInUser);
    const [logincentreid, setLoginCentreId] = useState(localStorage.CentreSNo == undefined || localStorage.CentreSNo == null ? "" : localStorage.CentreSNo);
    const [viewremarks, setViewRemarks] = useState([]);
    const [pdfreceipt, setPdfReceipt] = useState(false);
    const [glblrefno, setGlblRefNo] = useState("");
    const [globalpanelid, setGlobalPanelId] = useState("0");

    const multiple = false
    let remarks = useRef();
    let paymentmodedetail = useRef();
    let paymentamountdetail = useRef();
    let moboruhid = useRef();
    let testreference = useRef();
    let patientname = useRef();
    let patientmobile = useRef();
    let dateobirth = useRef();
    let editdateobirth = useRef();
    let day = useRef();
    let month = useRef();
    let year = useRef();
    let editday = useRef();
    let editmonth = useRef();
    let edityear = useRef();
    let pincode = useRef();
    let paymentReferenceNo = useRef();
    let authorizerId = useRef();
    let concessionReason = useRef();
    let paymentChannelId = useRef();
    let posMachineId = useRef();
    let viasourcetype = useRef();
    let cleardoctor = useRef();
    let rratetype = useRef();
    let testdocreference = useRef();
    let refpatienttype = useRef();
    const openModal = () => { setCrntPageNO(0); setViewPackageModal(!viewpackageModal); }
    const openPanelModal = () => { setCrntPageNO(0); setViewPanelModal(!viewpanelModal); }
    const openCitiesModal = () => { setCrntPageNO(0); setCityModal(!cityModal); }
    const openRequiredFieldModal = () => { setViewReqFieldsModal(!viewreqfieldsModal); }
    const openConsentModal = () => { setIsPrintConsent(!isPrintConsent); }
    const openDoctorModal = () => {
        doctorformik.resetForm(); setDoctorModal(!doctorModal);
        GetDoctorTitle();
        GetSAPStates();
        GetSpecialization();
        //setRefDocAddName(""); after disable doctor search in add new
    }
    const openDemographicModal = async () => {
        setDemographicModal(!demographicModal);
        setEditDisableFields({ editgender: !editformik.dirty });
        editformik.values.edittitle = formik.values.title;
        editformik.values.editpatientmobile = formik.values.patientmobile;
        editformik.values.editpatientname = formik.values.patientname;
        editformik.values.editdob = formik.values.dob;
        editformik.values.editage = formik.values.age;
        editformik.values.editgender = formik.values.gender;
        CallYears();
    }
    const CallYears = () => {
        setTimeout(() => {
            edityear.current.value = year.current.value;
            editmonth.current.value = month.current.value;
            editday.current.value = day.current.value;
        }, 100);
    }
    const popupPaginationOptions = {
        sizePerPage: 10,
        showTotal: false,
        firstPageText: 'First',
        prePageText: 'Back',
        nextPageText: 'Next',
        lastPageText: 'Last',
        onPageChange: (page, sizePerPage) => { setCrntPageNO(((page - 1) * sizePerPage)); }
    };
    useEffect(() => {
        if (finaldateofbirth != "") {
            formik.values.dob = finaldateofbirth;
            setFinalDateOfBirth("");
        }
    }, [dobstatus])
    useEffect(() => {
        if (editfinaldateofbirth != "") {
            editformik.values.editdob = editfinaldateofbirth;
            setEditFinalDateOfBirth("");
        }
    }, [editdobstatus])
    useEffect(() => {
        var storetotalamt = 0;
        var storedueamt = 0;
        var storepartnerrate = 0;
        var totalitemleveldiscount = 0;
        var totalhighpercnt = 0;
        setTotalTest(table.length);
        if (table == "") {
            // storetotalamt = 0;
            // storedueamt = 0;
            // setPaidAmt(0);
            // setPaymentTable([]);
            // setTotalAmt(0);
            // setDiscAmt(0);
            // setCredAmt(0);
            glblitemstore = [];
            glblArrUrgent = [];
            setChkUrgentFlag("");
            setStoreItem([]);
        }
        else {
            glblitemstore = [];
            for (var key in table) {
                if (localStorage.CentreTypeId == "2" && localStorage.PartnerTypeId != "5") {
                    storetotalamt += parseInt(table[key].Amt);
                    storedueamt += parseInt(table[key].Amt);
                    storepartnerrate += parseInt(table[key].PartnerRate);
                }
                else {
                    storetotalamt += parseInt(table[key].Rate);
                    storedueamt += parseInt(table[key].Rate);
                    storepartnerrate += parseInt(table[key].PartnerRate);
                }
                if (table[key].ItemLevelDiscount == null || table[key].ItemLevelDiscount == undefined || table[key].ItemLevelDiscount == "" || table[key].ItemLevelDiscount == 0 || table[key].ItemLevelDiscount == 0.00) { }
                else { totalitemleveldiscount += parseFloat(table[key].ItemLevelDiscount) }
                if (key == 0) { totalhighpercnt = parseFloat(table[key].ItemLevelDiscountPer) }
                else { if (parseFloat(table[key].ItemLevelDiscountPer) > totalhighpercnt) { totalhighpercnt = parseFloat(table[key].ItemLevelDiscountPer) } }
                glblitemstore.push(
                    {
                        ItemSno: table[key].ItemSNo,
                        ItemCode: table[key].Code,
                        Item: table[key].Item,
                        MRP: table[key].Amt,
                        Disc: table[key].Disc,
                        Rate: table[key].Rate,
                        DeliveryDate: table[key].DeliveryDate,
                        MachineSNo: "",
                        AppointmentTime: table[key].AppointmentTime,
                        Prereqsite: table[key].Prerequisite,
                        Urgent: table[key].Urgent === "True" ? "1" : "0",
                        IsPackage: table[key].IsPackage,
                        SchemeId: table[key].SchemeId,
                        ItemLevelDiscount: (table[key].ItemLevelDiscount == null || table[key].ItemLevelDiscount == undefined || table[key].ItemLevelDiscount == "" || table[key].ItemLevelDiscount == 0 || table[key].ItemLevelDiscount == 0.00) ? "0.00" : table[key].ItemLevelDiscount,
                        ItemLevelDiscountPer: (table[key].ItemLevelDiscountPer == null || table[key].ItemLevelDiscountPer == undefined || table[key].ItemLevelDiscountPer == "" || table[key].ItemLevelDiscountPer == 0 || table[key].ItemLevelDiscountPer == 0.00) ? "0" : table[key].ItemLevelDiscountPer
                    }
                );
            }
            if (glblArrUrgent.length > 0) {
                for (var keys in glblitemstore) {
                    for (var keyss in glblArrUrgent) {
                        if (glblitemstore[keys].ItemSno === glblArrUrgent[keyss].ItemSno) {
                            glblitemstore[keys].Urgent = glblArrUrgent[keyss].Urgent;
                        }
                    }
                }
            }
            if (glblArrUrgent.length > 0) {
                for (var keysnew in table) {
                    for (var keyssnew in glblArrUrgent) {
                        if (table[keysnew].ItemSNo === glblArrUrgent[keyssnew].ItemSno) {
                            if (glblArrUrgent[keyssnew].Urgent === "1") {
                                document.getElementById(`chkUrgent${table[keysnew].ItemSNo}`).checked = true;
                            }
                        }
                    }
                }
            }
            else {
                for (var keyedit in table) {
                    if (table[keyedit].Urgent === "1" || table[keyedit].Urgent === "True") {
                        document.getElementById(`chkUrgent${table[keyedit].ItemSNo}`).checked = true;
                    }
                }
            }
        }
        setTotalAmt(storetotalamt);
        if (parentcentreid != "0") {
            glblPaymentsList = [];
            glblPaymentsList.push(
                {
                    Id: "1", OrderDetailsId: "0", PaymentMode: "Credit", PaymentModeId: "2",
                    PaymentChannelId: "0", PaidAmount: storetotalamt.toString(), ReferenceNo: "", AuthorizerId: "0",
                    Authorizer: "", ConcessionReason: "", Status: "0", DiscountRejectReason: "", POSMachineName: "", POSMachineId: "0"
                }
            );
            setPaymentTable(glblPaymentsList);
        }
        setTotalILDiscount(totalitemleveldiscount.toFixed(2));
        setTotalHighPercent(totalhighpercnt);
        /////////////////////////////////////////
        // paidamt == "0" ? setDueAmt(parseFloat(storedueamt).toFixed(2)) : setDueAmt(parseFloat(storedueamt - paidamt).toFixed(2));
        if (paidamt == "0" && discamt == "0" && credamt == "0") { setDueAmt(parseFloat(storedueamt).toFixed(2)) }
        else if (paidamt == "0" && discamt == "0" && credamt != "0") { setDueAmt(parseFloat(storedueamt - credamt).toFixed(2)); }
        else if (paidamt == "0" && discamt != "0" && credamt == "0") { setDueAmt(parseFloat(storedueamt - discamt).toFixed(2)); }
        else if (paidamt != "0" && discamt == "0" && credamt == "0") { setDueAmt(parseFloat(storedueamt - paidamt).toFixed(2)); }
        else if (paidamt != "0" && discamt != "0" && credamt == "0") { setDueAmt(parseFloat(storedueamt - (paidamt + discamt)).toFixed(2)); }
        else if (paidamt != "0" && discamt == "0" && credamt != "0") { setDueAmt(parseFloat(storedueamt - (paidamt + credamt)).toFixed(2)); }
        else if (paidamt == "0" && discamt != "0" && credamt != "0") { setDueAmt(parseFloat(storedueamt - (discamt + credamt)).toFixed(2)); }
        else if (paidamt != "0" && discamt != "0" && credamt != "0") { setDueAmt(parseFloat(storedueamt - (paidamt + (discamt + credamt))).toFixed(2)); }
        /////////////////////////////////////////
        setHideAddPayButton(false);
        setStoreItem(glblitemstore);
        setChkUrgentFlag("");
        if (PartnerTypeIdSetting === "3") {
            var currentLimit = parseFloat(parseFloat(CreditLimitSetting) + parseFloat("-" + storepartnerrate));
            if (currentLimit < parseFloat("-" + BookingLimitSetting)) {
                setDisableBookings(true)
                setHideAddPayButton(true);
            } else {
                setDisableBookings(false)
                setHideAddPayButton(false);
            }
        }
        // Added for child centre of FC -- Kavish
        if (PartnerTypeIdSetting === "5" && parentcentreid !== "0") {
            var currentLimit = parseFloat(parseFloat(CreditLimitSetting) + parseFloat("-" + storetotalamt));
            if (currentLimit < parseFloat("-" + BookingLimitSetting)) {
                setDisableBookings(true)
                setHideAddPayButton(true);
            } else {
                setDisableBookings(false)
                setHideAddPayButton(false);
            }
        }
        // Added for child centre of FC -- Kavish
    }, [table, chkurgentflag, itemlevelvalue]);
    useEffect(() => {
        if (PackageIdFlag !== "0") { setTable(table.filter(item => item.PackageId !== PackageIdFlag)) }
        else { setTable(table.filter(item => item.ItemSNo !== chkflag)) }
        setChangeFlag(false); // this flag determines to delete a row from a grid
    }, [changeflag]);
    useEffect(() => {
        if (table == "") {
            setPackageModal(false);
            ////Commented by Kavish because of edit item bug the taken amount getting lost
            //glblPaymentsList = []; 
            ////Added by Kavish because of edit item bug the taken amount getting lost
            if (edititemsflag === false) {
                glblPaymentsList = [];
                setPaymentTable([]);
                setPaidAmt(0);
                setDueAmt(0);
                setDiscAmt(0);
                setCredAmt(0);
            }
            ////Added by Kavish because of edit item bug the taken amount getting lost
        } else { setPackageModal(false); CallPackages(); }
    }, [table, itemlevelvalue]);
    useEffect(() => {
        ////////////////////////////////////////////////
        // paidamt == "0" ? setDueAmt(parseFloat(totalamt).toFixed(2)) : setDueAmt(parseFloat(totalamt - paidamt).toFixed(2));
        if (paidamt == "0" && discamt == "0" && credamt == "0") { setDueAmt(parseFloat(totalamt).toFixed(2)) }
        else if (paidamt == "0" && discamt == "0" && credamt != "0") { setDueAmt(parseFloat(totalamt - credamt).toFixed(2)); }
        else if (paidamt == "0" && discamt != "0" && credamt == "0") { setDueAmt(parseFloat(totalamt - discamt).toFixed(2)); }
        else if (paidamt != "0" && discamt == "0" && credamt == "0") { setDueAmt(parseFloat(totalamt - paidamt).toFixed(2)); }
        else if (paidamt != "0" && discamt != "0" && credamt == "0") { setDueAmt(parseFloat(totalamt - (paidamt + discamt)).toFixed(2)); }
        else if (paidamt != "0" && discamt == "0" && credamt != "0") { setDueAmt(parseFloat(totalamt - (paidamt + credamt)).toFixed(2)); }
        else if (paidamt == "0" && discamt != "0" && credamt != "0") { setDueAmt(parseFloat(totalamt - (discamt + credamt)).toFixed(2)); }
        else if (paidamt != "0" && discamt != "0" && credamt != "0") { setDueAmt(parseFloat(totalamt - (paidamt + (discamt + credamt))).toFixed(2)); }
        ///////////////////////////////////////////////
        totalamt === paidamt ? setHideAddPayButton(true) : setHideAddPayButton(false);
    }, [paidamt, discamt, credamt]);
    useEffect(() => {
        GetDispatchMode();
        GetViaSource();
        GetProName();
        GetGenderType();
        GetStates();
        setDiscountAuthorizerDetails({});
        setCreditAuthorizerDetails({});
        GetAuthorizer("1");
        GetAuthorizer("2");
        formik.values.gender = "1";
        setDisableFields({ gender: !formik.dirty });
        moboruhid.current.focus();
        GetPaymentChannels();
        GetPOSMachines();
        GetSettings(localStorage.CentreSNo, localStorage.LoggedInUser);
        if (localStorage.getItem("SRPatientId") !== null) {
            GetVisitEditRegistrationDetails(localStorage.getItem("SRPatientId"), localStorage.getItem("SRVisitingId"));
        }
        if (localStorage.getItem("WebsitePatientId") !== null && localStorage.getItem("WebsiteVisitingId") !== null) {
            GetVisitEditRegistrationDetails(localStorage.getItem("WebsitePatientId"), localStorage.getItem("WebsiteVisitingId"));
        }
        const pageAccessedByReload = (
            (window.performance.navigation && window.performance.navigation.type === 1) ||
            window.performance
                .getEntriesByType('navigation')
                .map((nav) => nav.type)
                .includes('reload')
        );
        if (pageAccessedByReload === true) {
            localStorage.removeItem("WebsitePatientId");
            localStorage.removeItem("WebsiteVisitingId");
            localStorage.removeItem("SRPatientId");
            localStorage.removeItem("SRVisitingId");
        }
        return () => {
            if (localStorage.getItem("SRPatientId") !== null) {
                localStorage.removeItem("SRPatientId");
                localStorage.removeItem("SRVisitingId");
            }
            if (localStorage.getItem("WebsitePatientId") !== null) {
                localStorage.removeItem("WebsitePatientId");
                localStorage.removeItem("WebsiteVisitingId");
            }
        }
    }, []);
    useEffect(() => {
        if (formik.values.ratetype != "0" && formik.values.ratetype != "") {
            if (edittable.length > 0) {
                if (hideeditsavefield === true) { if (templateid == "0" || templateid == "") { } else { formik.values.ratetype = templateid; } }
                else {
                    if (glblDefaultRec.length > 0) {
                        for (var key in glblDefaultRec) {
                            if (glblDefaultRec[key].IsDefaultTemplate === "True") {
                                formik.values.ratetype = glblDefaultRec[key].SNo
                            }
                        }
                    }
                    else {
                        formik.values.ratetype = "0"
                    }
                }
            }
            else {
                if (glblDefaultRec.length > 0) {
                    for (var key in glblDefaultRec) {
                        if (glblDefaultRec[key].IsDefaultTemplate === "True") {
                            formik.values.ratetype = glblDefaultRec[key].SNo
                        }
                    }
                }
                else {
                    formik.values.ratetype = "0"
                }
            }
        }
        else {
            if (glblDefaultRec.length > 0) {
                for (var key in glblDefaultRec) {
                    if (glblDefaultRec[key].IsDefaultTemplate === "True") {
                        formik.values.ratetype = glblDefaultRec[key].SNo
                    }
                }
            }
            else {
                formik.values.ratetype = "0"
            }
        }
        callDynamicValidations();
    }, [ratetype])
    useEffect(() => {
        if (localStorage.IsSourceMandatory === true || localStorage.IsSourceMandatory === "True") {
            formik.values.source = sourcenew
        }
        formik.values.dispatchmode = dispatchmodenew
        formik.values.remarks = remarksnew
        callDynamicValidations()
    }, [sourcenew, dispatchmodenew, remarksnew])
    useEffect(() => {
        formik.values.patientmobile = holdmobvalue;
        moboruhid.current.clear();
    }, [holdmobvalue])
    useEffect(() => {
        if (defaultptype !== "0") { formik.values.patienttype = defaultptype; }
    }, [defaultptype])
    useEffect(() => {
        if (defaultglobaldocument !== "0") { formik.values.globaldocument = defaultglobaldocument; }
    }, [defaultglobaldocument])
    const errorHandler = (callFrom) => {
        if (callFrom === "Hold") { setIsHold(true); } else { setIsHold(false); }
        setModal(true); setErrorModal(true);
    }
    const toggleModal = () => { setModal(!modal) }
    const phoneRegExppatientname = /^[a-zA-Z .']{0,500}$/
    const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
    const formik = useFormik({
        initialValues: {
            patientname: '', age: '', dob: '', gender: '', otherdoc: '', emailid: '', patientmobile: '', pincode: '', patienttype: '', remarks: '', ratetype: '', totaldiscamt: '',
            discountreason: '', address: '', title: '', visittype: '', locStateId: "", locDistrictId: "", otherLabRefNo: "", houseno: "", viasource: '', otherreflab: ''
        },
        validationSchema: Yup.object({
            patientname: Yup.string().required('Patient name is required').matches(phoneRegExppatientname, 'Special characters is not allowed'),
            patientmobile: Yup.string().required('Patient mobile is required').max(10, 'Patient mobile must be 10 numbers').min(10, 'Patient mobile must be 10 numbers').matches(phoneRegExp, 'Patient mobile is not valid'),
            dob: Yup.string().required('DOB is required'),
            //houseno: Yup.string().required('Patient houseno is required'),
            address: Yup.string().required('Patient address is required'),
            //age: Yup.number().required('Age is required').typeError('Age must be a number').min(0, 'Min age must be 0.').max(99, 'Max age must be 99.'),
            emailid: Yup.string().email('Invalid email id'),
            pincode: Yup.number().typeError('Pin Code  must be a number').required('Pin Code is required'),
            locStateId: Yup.string().required('State is required'),
            locDistrictId: Yup.string().required('District is required'),
            //viasource: Yup.string().required('Source is required'),
        }),
        onSubmit: values => {
            setModal(false);
            if (isHold === false) { AddPatientRegistration(values); } else { HoldOrder(values); }
        }
    });
    const phoneRegExpdoctor = /^'?\p{L}+(?:[' ]\p{L}+)*'?$/u
    const doctorformik = useFormik({
        initialValues: { doctortitle: '', docfirstname: '', docmiddlename: '', doclastname: '', doctorsappincode: '', doctorsapstate: '', doctorsapregion: '', doctorsapdistrict: '', doctorspecialization: '', docclinicno: '', docmobileno: '', doclandmark: '' },//, docemail: '' after disable doctor search in add new
        validationSchema: Yup.object({
            doctortitle: Yup.string().required('Title is required'),
            docfirstname: Yup.string().required('First name is required').matches(phoneRegExpdoctor, 'Special characters is not allowed'),
            docmiddlename: Yup.string().matches(phoneRegExpdoctor, 'Special characters is not allowed'),
            doclastname: Yup.string().matches(phoneRegExpdoctor, 'Special characters is not allowed'),
            docmobileno: Yup.string().max(10, 'Mobile phone must be 10 numbers').min(10, 'Mobile phone must be 10 numbers').matches(phoneRegExp, 'Mobile phone is not valid'),
            docclinicno: Yup.string().max(10, 'Clinic phone must be 10 numbers').min(10, 'Clinic phone must be 10 numbers').matches(phoneRegExp, 'Clinic phone is not valid'),
            doctorsappincode: Yup.string().typeError('Pincode  must be a number'),
            //docemail: Yup.string().email('Invalid email id'), after disable doctor search in add new
        }),
        onSubmit: values => { SaveReferringDoctor(values); }
    });
    const editformik = useFormik({
        initialValues: {
            editpatientname: '', editdob: '', editgender: '', editpatientmobile: '', edittitle: ''
        },
        validationSchema: Yup.object({
            editpatientname: Yup.string().required('Patient name is required').matches(phoneRegExppatientname, 'Special characters is not allowed'),
            editpatientmobile: Yup.string().required('Patient mobile is required').max(10, 'Patient mobile must be 10 numbers').min(10, 'Patient mobile must be 10 numbers').matches(phoneRegExp, 'Patient mobile is not valid'),
            editdob: Yup.string().required('DOB is required')
        }),
        onSubmit: values => {
            SaveEditDemographicPatientDetails(values);
        }
    });
    const popupidFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                <span>{crntPageNO + rowIndex + 1}</span>
            </div>
        </React.Fragment>
    }
    const popupactionFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div><a href="javascript:void(0)" onClick={() => BindPackageItems(rowData)}>Select</a></div>
        </React.Fragment>
    }
    const popuppackageformatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>{rowData.PackageName}
                &nbsp;&nbsp;&nbsp;&nbsp;
                <ViewPackageToolTip title="View Items" placement="top" id={rowData.PackageId} onClick={() => ViewPackageItems(rowData.PackageId)} />
            </div>
        </React.Fragment>
    }

    const popupColumns =
        parentcentreid === "0" ?
            localStorage.CentreTypeId == "2" ?
                iscentreadmin === true ?
                    [
                        { dataField: '#', text: '#', editable: false, formatter: popupidFormatter, headerStyle: (colum, colIndex) => { return { width: '7%' }; } },
                        { dataField: 'PackageName', text: 'Package Name', editable: false, formatter: popuppackageformatter, },
                        { dataField: 'PackageCode', text: 'Package Code', editable: false },
                        { dataField: 'PackageMRP', text: 'Package MRP', editable: false },
                        { dataField: 'PackageRate', text: 'Package Rate', editable: false },
                        { dataField: 'actions', text: 'Actions', formatter: popupactionFormatter, editable: false, headerStyle: (colum, colIndex) => { return { width: '15%' }; } }
                    ] :
                    [
                        { dataField: '#', text: '#', editable: false, formatter: popupidFormatter, headerStyle: (colum, colIndex) => { return { width: '7%' }; } },
                        { dataField: 'PackageName', text: 'Package Name', editable: false, formatter: popuppackageformatter, },
                        { dataField: 'PackageCode', text: 'Package Code', editable: false },
                        { dataField: 'PackageMRP', text: 'Package MRP', editable: false },
                        { dataField: 'actions', text: 'Actions', formatter: popupactionFormatter, editable: false, headerStyle: (colum, colIndex) => { return { width: '15%' }; } }
                    ]
                :
                [
                    { dataField: '#', text: '#', editable: false, formatter: popupidFormatter, headerStyle: (colum, colIndex) => { return { width: '7%' }; } },
                    { dataField: 'PackageName', text: 'Package Name', editable: false, formatter: popuppackageformatter, },
                    { dataField: 'PackageCode', text: 'Package Code', editable: false },
                    { dataField: 'PackageMRP', text: 'Package MRP', editable: false },
                    { dataField: 'PackageRate', text: 'Package Rate', editable: false },
                    { dataField: 'actions', text: 'Actions', formatter: popupactionFormatter, editable: false, headerStyle: (colum, colIndex) => { return { width: '15%' }; } }
                ] :
            [
                { dataField: '#', text: '#', editable: false, formatter: popupidFormatter, headerStyle: (colum, colIndex) => { return { width: '7%' }; } },
                { dataField: 'PackageName', text: 'Package Name', editable: false, formatter: popuppackageformatter, },
                { dataField: 'PackageCode', text: 'Package Code', editable: false },
                { dataField: 'actions', text: 'Actions', formatter: popupactionFormatter, editable: false, headerStyle: (colum, colIndex) => { return { width: '15%' }; } }
            ]
    const popupidcityFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                <span>{rowIndex + 1}</span>
            </div>
        </React.Fragment>
    }
    const popupactioncityFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div><a href="javascript:void(0)" onClick={() => SelectedCity(rowData)}>Select</a></div>
        </React.Fragment>
    }
    const popupcityColumns = [
        { dataField: '#', text: '#', editable: false, formatter: popupidcityFormatter, headerStyle: (colum, colIndex) => { return { width: '7%' }; } },
        { dataField: 'StateName', text: 'State', editable: false },
        { dataField: 'DistrictName', text: 'District', editable: false },
        { dataField: 'actions', text: 'Actions', formatter: popupactioncityFormatter, editable: false, headerStyle: (colum, colIndex) => { return { width: '15%' }; } }
    ];
    const popupviewColumns = [
        { dataField: '#', text: '#', editable: false, formatter: popupidFormatter, headerStyle: (colum, colIndex) => { return { width: '7%' }; } },
        { dataField: 'Code', text: 'Code', editable: false },
        { dataField: 'Item', text: 'Item', editable: false }
    ];
    const popupviewpanelColumns = [
        { dataField: '#', text: '#', editable: false, formatter: popupidFormatter, headerStyle: (colum, colIndex) => { return { width: '7%' }; } },
        { dataField: 'Name', text: 'Item', editable: false }
    ];
    const actionFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                {
                    rowData.Prerequisite.toLowerCase() === "consent form" ?
                        <CommonToolTip title="Print Consent Form" id={`ConsentForm${rowData.ItemSNo}`} placement="top" class="fa fa-print mr-2" onClick={() => OpenConsentForm(rowData)} /> : null
                }
                {
                    // rowData.IsPackage !== "0" ? <DeleteToolTip title="Delete Package" id={`Delete${rowData.ItemSNo}`} placement="top" onClick={() => DisplayDeleteTest(rowData.ItemSNo, rowData.PackageId)} /> :
                    rowData.IsPackage !== "0" ? <DeleteToolTip title="Delete Package" id={`Delete${rowData.ItemSNo}`} placement="top" onClick={() => DisplayDeleteTest(rowData)} /> :
                        // <DeleteToolTip title="Delete Item" placement="top" id={`Delete${rowData.ItemSNo}`} onClick={() => DisplayDeleteTest(rowData.ItemSNo, rowData.PackageId)} />
                        <DeleteToolTip title="Delete Item" placement="top" id={`Delete${rowData.ItemSNo}`} onClick={() => DisplayDeleteTest(rowData)} />
                }
                {
                    rowData.IsPackage !== "0" ? <ViewPackageToolTip title="View Package Items" placement="top" id={`View${rowData.ItemSNo}`} onClick={() => ViewPackageItems(rowData.ItemSNo)} /> :
                        null
                }
                {
                    rowData.IsPanel.toLowerCase() === "profile" ? <ViewPackageToolTip title="View Panel Items" placement="top" id={`View${rowData.InvestigationId}`} onClick={() => ViewPanelItems(rowData.InvestigationId)} /> :
                        null
                }
            </div>
        </React.Fragment>
    }
    const OpenConsentForm = (rowData) => {
        var consentFileUrl = rowData.PreRequisiteFileUrl.split("/");
        var consentFormImage = consentFileUrl[consentFileUrl.length - 1];
        var consentForm = consentFormImage.split(".");
        setConsentFormName(consentForm[0]);
        setIsPrintConsent(true);
    }
    const checkboxFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                <input class="checkbox_animated" type="checkbox" id={`chkUrgent${rowData.ItemSNo}`} onClick={(e) => { handleUrgentCheckbox(e, rowData.ItemSNo); }} />
            </div>
        </React.Fragment>
    }
    const idFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                <span>{rowIndex + 1}</span>
            </div>
        </React.Fragment>
    }
    const UploadPrerequisite = async (e, ItemId) => {
        document.getElementById("temp" + ItemId).innerHTML = "";
        glblprerequisitestoredetails = glblprerequisitestoredetails.filter((d) => d.PreRequisite !== ItemId);
        const arryVal = e.target.files[0].name.split(".");
        const extension = arryVal[arryVal.length - 1];
        var base64 = await toBase64(e.target.files[0]);
        var base64img = base64.split("base64,");
        const formData = new FormData();
        formData.append('Image', base64img[1]);
        formData.append('FolderName', 'prerequisite');
        formData.append('pathflag', 'prerequisite');
        const ImageName = Math.random();
        var imgName = ImageName + "prerequisite." + extension;
        formData.append('ImageName', imgName);
        // formData.append('SNo', localStorage.LoggedInUser + "-" + ImageName);
        // formData.append('extension', extension[1]);
        let url = FrontDesk_URL + 'master/UploadImage_Root/'
        await axios.post(url, formData, {
            headers: { "Content-type": "multipart/form-data", },
        }).then((response) => {
            const data = response.data;
            if (data.Success) {
                glblprerequisitestoredetails.push({
                    PreRequisite: ItemId, PreRequisiteDocPath: imgName
                });
                SweetAlert.fire('Document Selected');
            } else { SweetAlert.fire({ title: "Upload failed", text: "Unable to upload document due to " + data.Data + ".Please retry again", icon: "error" }) }
        }).catch(function (error) { if (error.response) { } });
    }
    const UploadPrescription = async (e) => {
        document.getElementById("temp_prescription").innerHTML = "";
        //glblprerequisitestoredetails = glblprerequisitestoredetails.filter((d) => d.PreRequisite !== ItemId);
        const arryVal = e.target.files[0].name.split(".");
        const extension = arryVal[arryVal.length - 1];
        var base64 = await toBase64(e.target.files[0]);
        var base64img = base64.split("base64,");
        const formData = new FormData();
        formData.append('Image', base64img[1]);
        formData.append('FolderName', 'prescription');
        formData.append('pathflag', 'prescription');
        const ImageName = Math.random();
        var imgName = ImageName + "prescription." + extension;
        formData.append('ImageName', imgName);
        // formData.append('SNo', localStorage.LoggedInUser + "-" + ImageName);
        // formData.append('extension', extension[1]);
        let url = FrontDesk_URL + 'master/UploadImage_Root/'
        await axios.post(url, formData, {
            headers: { "Content-type": "multipart/form-data", },
        }).then((response) => {
            const data = response.data;
            if (data.Success) {
                setPrescriptionDocument(imgName);
                // glblprerequisitestoredetails.push({
                //     PreRequisite: ItemId, PreRequisiteDocPath: "PreRequisiteimg" + localStorage.LoggedInUser + "-" + ImageName + "." + extension[1]
                // });
                SweetAlert.fire('Document Selected');
            } else { SweetAlert.fire({ title: "Upload failed", text: "Unable to upload document due to " + data.Data + ".Please retry again", icon: "error" }) }
        }).catch(function (error) { if (error.response) { } });
    }
    const actionpaymentFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                {/* <DeleteToolTip title="Delete" placement="top" id={`DeletePayment${rowData.PaymentModeId}`} onClick={() => RemovePayment(rowData)} /> */}
                {
                    channelid === "-1" || channelid === "8" ? null :
                        <>
                            {
                                rowData.OrderDetailsId !== "0" && (rowData.PaymentModeId === "1" || rowData.PaymentModeId === "13") ? null :
                                    <DeleteToolTip title="Delete" placement="top" id={`DeletePayment${rowData.PaymentModeId}`} onClick={() => RemovePayment(rowData)} />
                            }
                        </>
                }
            </div>
        </React.Fragment>
    }
    const editclassFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                {
                    localStorage.CentreTypeId == "2" && localStorage.PartnerTypeId != "5" ?
                        <span><i class='fa fa-inr'></i>{rowData.Amt}</span>
                        :
                        <span><i class='fa fa-inr'></i>{rowData.Rate}</span>
                }
            </div>
        </React.Fragment>
    }
    const editcheckboxFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                <input class="checkbox_animated" type="checkbox" id={`checkboxtest${rowData.SNo}`} onClick={(e) => { handleEditCheckbox(e, rowData.SNo, rowData.PatientItemDetailsId); }} />
            </div>
        </React.Fragment>
    }
    const CalladdedDiscount = (oldValue, newvalue, rowData, column) => {
        const phoneRegExpalphabets = /[a-zA-Z]/g
        const phoneRegExpcharacters = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?~]/
        if (phoneRegExpalphabets.test(newvalue) == true) {
            rowData.ItemLevelDiscount = "0";
            rowData.ItemLevelDiscountPer = "0";
            SweetAlert.fire("Characters are not allowed.");
            return false;
        }
        if (phoneRegExpcharacters.test(newvalue) == true) {
            rowData.ItemLevelDiscount = "0";
            rowData.ItemLevelDiscountPer = "0";
            SweetAlert.fire("Characters are not allowed.");
            return false;
        }
        if (newvalue == "" || newvalue == null || newvalue == undefined || newvalue == ".") { newvalue = "0"; }
        if (paymenttable.length > 0) {
            var ifexist = 0;
            for (var key in paymenttable) {
                if (paymenttable[key].PaymentModeId == "14" && ifexist == 0 && newvalue > 0) {
                    ifexist = 1;
                }
            }
            if (ifexist == 1) {
                setTotalILDiscount(0);
                rowData.ItemLevelDiscount = "0";
                rowData.ItemLevelDiscountPer = "0";
                SweetAlert.fire("Discount already added in grid. Please remove discount first");
                return false;
            }
        }
        var x = JSON.stringify(column);
        if (JSON.parse(x).text == "Discount in %.") {
            if (parseFloat(rowData.ItemLevelDiscountPer) > 100) {
                setTotalILDiscount(0);
                rowData.ItemLevelDiscount = "0";
                rowData.ItemLevelDiscountPer = "0";
                SweetAlert.fire("Discount percentage cannot be greater than 100 %");
                return false;
            }
            else {
                if (newvalue == "0") {
                    rowData.ItemLevelDiscount = "0";
                    rowData.ItemLevelDiscountPer = "0";
                }
                else {
                    var crntDiscount = parseFloat(rowData.Rate) * (parseFloat(newvalue) / 100);
                    var roundoffvalue = Math.round(crntDiscount);
                    rowData.ItemLevelDiscount = roundoffvalue.toFixed(2);
                    var crntDiscountPer = (parseFloat(roundoffvalue) * 100) / parseFloat(rowData.Rate);
                    rowData.ItemLevelDiscountPer = crntDiscountPer.toFixed(2);
                }
                if (itemlevelvalue == false) { setItemLevelValue(true); } else { setItemLevelValue(false); }
            }
        }
        else {
            if (parseFloat(rowData.ItemLevelDiscount) > parseFloat(rowData.Rate)) {
                setTotalILDiscount(0);
                rowData.ItemLevelDiscount = "0";
                rowData.ItemLevelDiscountPer = "0";
                SweetAlert.fire("Discount amount cannot be greater than rate");
                return false;
            }
            else {
                if (newvalue == "0") {
                    rowData.ItemLevelDiscount = "0";
                    rowData.ItemLevelDiscountPer = "0";
                }
                else {
                    var roundoffvalue = Math.round(parseFloat(newvalue));
                    var crntDiscountPer = (parseFloat(roundoffvalue) * 100) / parseFloat(rowData.Rate);
                    rowData.ItemLevelDiscount = parseFloat(roundoffvalue).toFixed(2);
                    rowData.ItemLevelDiscountPer = crntDiscountPer.toFixed(2);
                }
                if (itemlevelvalue == false) { setItemLevelValue(true); } else { setItemLevelValue(false); }
            }
        }
        // var glbAdDisc = [];
        //     glbAdDisc = table;
        //     for (var key in glbAdDisc) {
        //         if (glbAdDisc[key].ItemSNo == rowData.ItemSNo) {
        //             glbAdDisc[key].ItemLevelDiscount = "0"
        //         }
        //     }

        // if (edititemsflag == false) {
        //     setPaymentTable([]);
        //     setPaidAmt(0);
        //     setDiscAmt(0);
        //     setCredAmt(0);
        //     glblPaymentsList = [];
        //     setChannelId("0");
        //     if (paymentReferenceNo.current !== undefined && paymentReferenceNo.current !== null) { paymentReferenceNo.current.value = ""; }
        //     if (paymentmodedetail.current !== undefined && paymentmodedetail.current !== null) { paymentmodedetail.current.value = "0"; }
        //     ChangePaymentMode("0");
        // }
    }
    const editamtFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                <span><i class='fa fa-inr'></i>{rowData.Amt}</span>
            </div>
        </React.Fragment>
    }
    const columns =
        parentcentreid === "0" ?
            HideAmountInBookingSetting === true ?
                localStorage.CentreTypeId == "2" ?
                    iscentreadmin === true ?
                        [
                            { dataField: '#', text: '#', editable: false, formatter: idFormatter, headerStyle: (colum, colIndex) => { return { width: '5%' }; } },
                            { dataField: 'Code', text: 'Code', editable: false },
                            { dataField: 'Item', text: 'Item', editable: false },
                            { dataField: 'Modality', text: 'Department', editable: false },
                            { dataField: 'Amt', text: 'MRP', editable: false },
                            { dataField: 'Rate', text: 'Rate', editable: false },
                            { dataField: 'DeliveryDate', text: 'Delivery Date', editable: false },
                            { dataField: 'Precautions', text: 'Precautions', editable: false, },
                            { dataField: 'Urgent', text: 'Urgent', editable: false, formatter: checkboxFormatter },
                            { dataField: 'actions', isDummyField: true, text: 'Actions', editable: false, formatter: actionFormatter, headerStyle: (colum, colIndex) => { return { width: '7%' }; } }
                        ]
                        :
                        [
                            { dataField: '#', text: '#', editable: false, formatter: idFormatter, headerStyle: (colum, colIndex) => { return { width: '5%' }; } },
                            { dataField: 'Code', text: 'Code', editable: false },
                            { dataField: 'Item', text: 'Item', editable: false },
                            { dataField: 'Modality', text: 'Department', editable: false },
                            { dataField: 'Amt', text: 'MRP', editable: false },
                            { dataField: 'DeliveryDate', text: 'Delivery Date', editable: false },
                            { dataField: 'Precautions', text: 'Precautions', editable: false, },
                            { dataField: 'Urgent', text: 'Urgent', editable: false, formatter: checkboxFormatter },
                            { dataField: 'actions', isDummyField: true, text: 'Actions', editable: false, formatter: actionFormatter, headerStyle: (colum, colIndex) => { return { width: '7%' }; } }
                        ]
                    :
                    formik.values.patienttype == "8" || formik.values.patienttype == "9" ?
                        [
                            { dataField: '#', text: '#', editable: false, formatter: idFormatter, headerStyle: (colum, colIndex) => { return { width: '5%' }; } },
                            { dataField: 'Code', text: 'Code', editable: false },
                            { dataField: 'Item', text: 'Item', editable: false },
                            { dataField: 'Modality', text: 'Department', editable: false },
                            { dataField: 'Amt', text: 'MRP', editable: false },
                            { dataField: 'Rate', text: 'Rate', editable: false },
                            { dataField: 'DeliveryDate', text: 'Delivery Date', editable: false },
                            { dataField: 'Precautions', text: 'Precautions', editable: false, },
                            { dataField: 'Urgent', text: 'Urgent', editable: false, formatter: checkboxFormatter },
                            { dataField: 'actions', isDummyField: true, text: 'Actions', editable: false, formatter: actionFormatter, headerStyle: (colum, colIndex) => { return { width: '7%' }; } }
                        ]
                        :
                        [
                            { dataField: '#', text: '#', editable: false, formatter: idFormatter, headerStyle: (colum, colIndex) => { return { width: '5%' }; } },
                            { dataField: 'Code', text: 'Code', editable: false },
                            { dataField: 'Item', text: 'Item', editable: false },
                            { dataField: 'Modality', text: 'Department', editable: false },
                            { dataField: 'Amt', text: 'MRP', editable: false },
                            { dataField: 'Rate', text: 'Rate', editable: false },
                            { dataField: 'DeliveryDate', text: 'Delivery Date', editable: false },
                            { dataField: 'Precautions', text: 'Precautions', editable: false, },
                            { dataField: 'Urgent', text: 'Urgent', editable: false, formatter: checkboxFormatter },
                            { dataField: 'ItemLevelDiscountPer', text: 'Discount in %.', editable: true },
                            { dataField: 'ItemLevelDiscount', text: 'Discount in Rs.', editable: true },
                            { dataField: 'actions', isDummyField: true, text: 'Actions', editable: false, formatter: actionFormatter, headerStyle: (colum, colIndex) => { return { width: '7%' }; } }
                        ]
                :
                localStorage.CentreTypeId == "2" ?
                    iscentreadmin === true ?
                        [
                            { dataField: '#', text: '#', editable: false, formatter: idFormatter, headerStyle: (colum, colIndex) => { return { width: '5%' }; } },
                            { dataField: 'Code', text: 'Code', editable: false },
                            { dataField: 'Item', text: 'Item', editable: false },
                            { dataField: 'Modality', text: 'Department', editable: false },
                            { dataField: 'Amt', text: 'MRP', editable: false },
                            { dataField: 'Rate', text: 'Rate', editable: false },
                            { dataField: 'DeliveryDate', text: 'Delivery Date', editable: false },
                            { dataField: 'Precautions', text: 'Precautions', editable: false, },
                            { dataField: 'Urgent', text: 'Urgent', editable: false, formatter: checkboxFormatter },
                            { dataField: 'actions', isDummyField: true, text: 'Actions', editable: false, formatter: actionFormatter, headerStyle: (colum, colIndex) => { return { width: '7%' }; } }
                        ]
                        :
                        [
                            { dataField: '#', text: '#', editable: false, formatter: idFormatter, headerStyle: (colum, colIndex) => { return { width: '5%' }; } },
                            { dataField: 'Code', text: 'Code', editable: false },
                            { dataField: 'Item', text: 'Item', editable: false },
                            { dataField: 'Modality', text: 'Department', editable: false },
                            { dataField: 'Amt', text: 'MRP', editable: false },
                            { dataField: 'DeliveryDate', text: 'Delivery Date', editable: false },
                            { dataField: 'Precautions', text: 'Precautions', editable: false, },
                            { dataField: 'Urgent', text: 'Urgent', editable: false, formatter: checkboxFormatter },
                            { dataField: 'actions', isDummyField: true, text: 'Actions', editable: false, formatter: actionFormatter, headerStyle: (colum, colIndex) => { return { width: '7%' }; } }
                        ]
                    :
                    formik.values.patienttype == "8" || formik.values.patienttype == "9" ?
                        [
                            { dataField: '#', text: '#', editable: false, formatter: idFormatter, headerStyle: (colum, colIndex) => { return { width: '5%' }; } },
                            { dataField: 'Code', text: 'Code', editable: false },
                            { dataField: 'Item', text: 'Item', editable: false },
                            { dataField: 'Modality', text: 'Department', editable: false },
                            { dataField: 'Amt', text: 'MRP', editable: false },
                            { dataField: 'Rate', text: 'Rate', editable: false },
                            { dataField: 'DeliveryDate', text: 'Delivery Date', editable: false },
                            { dataField: 'Precautions', text: 'Precautions', editable: false, },
                            { dataField: 'Urgent', text: 'Urgent', editable: false, formatter: checkboxFormatter },
                            { dataField: 'actions', isDummyField: true, text: 'Actions', editable: false, formatter: actionFormatter, headerStyle: (colum, colIndex) => { return { width: '7%' }; } }
                        ]
                        :
                        [
                            { dataField: '#', text: '#', editable: false, formatter: idFormatter, headerStyle: (colum, colIndex) => { return { width: '5%' }; } },
                            { dataField: 'Code', text: 'Code', editable: false },
                            { dataField: 'Item', text: 'Item', editable: false },
                            { dataField: 'Modality', text: 'Department', editable: false },
                            { dataField: 'Amt', text: 'MRP', editable: false },
                            { dataField: 'Rate', text: 'Rate', editable: false },
                            { dataField: 'DeliveryDate', text: 'Delivery Date', editable: false },
                            { dataField: 'Precautions', text: 'Precautions', editable: false, },
                            { dataField: 'Urgent', text: 'Urgent', editable: false, formatter: checkboxFormatter },
                            { dataField: 'ItemLevelDiscountPer', text: 'Discount in %.', editable: true },
                            { dataField: 'ItemLevelDiscount', text: 'Discount in Rs.', editable: true },
                            { dataField: 'actions', isDummyField: true, text: 'Actions', editable: false, formatter: actionFormatter, headerStyle: (colum, colIndex) => { return { width: '7%' }; } }
                        ]
            :
            [
                { dataField: '#', text: '#', editable: false, formatter: idFormatter, headerStyle: (colum, colIndex) => { return { width: '5%' }; } },
                { dataField: 'Code', text: 'Code', editable: false },
                { dataField: 'Item', text: 'Item', editable: false },
                { dataField: 'Modality', text: 'Department', editable: false },
                { dataField: 'DeliveryDate', text: 'Delivery Date', editable: false },
                { dataField: 'Precautions', text: 'Precautions', editable: false, },
                { dataField: 'Urgent', text: 'Urgent', editable: false, formatter: checkboxFormatter },
                { dataField: 'actions', isDummyField: true, text: 'Actions', editable: false, formatter: actionFormatter, headerStyle: (colum, colIndex) => { return { width: '7%' }; } }
            ]
    const paymentColumns = [
        { dataField: 'PaymentMode', text: 'Payment Mode', editable: false },
        { dataField: 'PaidAmount', text: 'Paid Amount', editable: false },
        { dataField: 'ReferenceNo', text: 'Reference No.', editable: false },
        { dataField: 'Authorizer', text: 'Authorizer', editable: false },
        { dataField: 'ConcessionReason', text: 'Concession Reason', editable: false },
        { dataField: 'POSMachineName', text: 'POS Machine', editable: false },
        { dataField: '', text: 'Remove', editable: false, formatter: actionpaymentFormatter }
    ]
    const editcolumns =
        hideeditsavefield === true ?
            parentcentreid === "0" ?
                [
                    { dataField: 'SNo', text: 'Select', editable: false, formatter: editcheckboxFormatter, headerStyle: (colum, colIndex) => { return { width: '10%' }; }, },
                    { dataField: 'Item', text: 'Name', editable: false },
                    { dataField: 'ItemDateDone', text: 'Date', editable: false },
                    { dataField: 'Result', text: 'Result', editable: false },
                    { dataField: HideAmountInBookingSetting === true ? null : 'Rate', text: HideAmountInBookingSetting === true ? null : 'Amount', editable: false, formatter: HideAmountInBookingSetting === true ? null : editclassFormatter }
                ] :
                [
                    { dataField: 'SNo', text: 'Select', editable: false, formatter: editcheckboxFormatter, headerStyle: (colum, colIndex) => { return { width: '10%' }; }, },
                    { dataField: 'Item', text: 'Name', editable: false },
                    { dataField: 'ItemDateDone', text: 'Date', editable: false },
                    { dataField: 'Result', text: 'Result', editable: false }
                ]
            :
            parentcentreid === "0" ?
                [
                    { dataField: 'Item', text: 'Name', editable: false },
                    { dataField: 'ItemDateDone', text: 'Date', editable: false },
                    { dataField: 'Result', text: 'Result', editable: false },
                    { dataField: 'Rate', text: 'Amount', editable: false, formatter: editclassFormatter }
                ]
                :
                [
                    { dataField: 'SNo', text: 'Select', editable: false, formatter: editcheckboxFormatter, headerStyle: (colum, colIndex) => { return { width: '10%' }; }, },
                    { dataField: 'Item', text: 'Name', editable: false },
                    { dataField: 'ItemDateDone', text: 'Date', editable: false },
                    { dataField: 'Result', text: 'Result', editable: false }
                ]
    const invoicecolumns =
        localStorage.CentreTypeId == "2" ?
            iscentreadmin == true ?
                [
                    { dataField: '#', text: '#', editable: false, formatter: idFormatter, headerStyle: (colum, colIndex) => { return { width: '7%' }; } },
                    { dataField: 'Item', text: 'Item', editable: false },
                    { dataField: 'DeliveryDate', text: 'DeliveryDate', editable: false },
                    { dataField: 'Rate', text: 'MRP', editable: false, formatter: editclassFormatter },
                    { dataField: 'Amt', text: 'Rate', editable: false, formatter: editamtFormatter }
                ] :
                [
                    { dataField: '#', text: '#', editable: false, formatter: idFormatter, headerStyle: (colum, colIndex) => { return { width: '7%' }; } },
                    { dataField: 'Item', text: 'Item', editable: false },
                    { dataField: 'DeliveryDate', text: 'DeliveryDate', editable: false },
                    { dataField: 'Rate', text: 'MRP', editable: false, formatter: editclassFormatter }
                ] :
            [
                { dataField: '#', text: '#', editable: false, formatter: idFormatter, headerStyle: (colum, colIndex) => { return { width: '7%' }; } },
                { dataField: 'Item', text: 'Item', editable: false },
                { dataField: 'DeliveryDate', text: 'DeliveryDate', editable: false },
                { dataField: 'Rate', text: 'MRP', editable: false, formatter: editclassFormatter },
                { dataField: 'Amt', text: 'Rate', editable: false, formatter: editamtFormatter }
            ];
    const GetPatientType = async () => {
        var centreid = "";
        if (pcid === "0") { centreid = localStorage.CentreSNo }
        else { centreid = pcid }
        try {
            let url = FrontDesk_URL + 'master/GetPatientType/' + centreid + '/' + loginuserid
            await axios.get(url).then((response) => {
                const data = response.data;
                if (data.Success) {
                    setPatientType(data.Data.map((d) => (<option key={d.SNo} value={d.SNo}>{d.PatientType}</option>)));
                    for (var key in data.Data) {
                        if (data.Data[key].IsDefault === "True" || data.Data[key].IsDefault === "1") { formik.values.patienttype = data.Data[key].SNo }
                    }
                    GetAllDocumentGlobal(formik.values.patienttype);
                    GetPaymentMode(formik.values.patienttype, "0");
                }
            }).catch(function (error) { if (error.response) { ErrorFunction(error.response.status); } });
        }
        catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    }
    const GetDispatchMode = async () => {
        try {
            let url = FrontDesk_URL + 'master/GetDispatchMode'
            await axios.get(url).then((response) => {
                const data = response.data;
                if (data.Success) { setDispatchMode(data.Data.map((d) => (<option key={d.SNo} value={d.SNo}>{d.Dispatch}</option>))); }
            }).catch(function (error) { });
        }
        catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    }
    const GetViaSource = async () => {
        try {
            let url = FrontDesk_URL + 'master/GetViaSource'
            await axios.get(url).then((response) => {
                const data = response.data;
                if (data.Success) {
                    //setViaSource(data.Data.map((d) => (<option key={d.SNo} value={d.SNo}>{d.Source}</option>)));
                    glblsource = data.Data;
                    setViaSource(data.Data);
                }
            }).catch(function (error) { });
        }
        catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    }
    const getsourcelist = async (e, text) => {
        if (e.target.value === "") {
            setViaSource(glblsource);
            formik.values.viasource = "";
            setEditViaSource("");
            setEditViaSourceName("");
        } else {
            setViaSource(glblsource.filter((d) => d.Source !== text));
        }
    }
    const BindSource = async (SNo) => {
        if (SNo !== "-1") {
            formik.values.viasource = SNo;
        }
    }
    const GetProName = async () => {
        var glblArr = [];
        try {
            let url = FrontDesk_URL + 'master/GetProName/' + localStorage.CentreSNo
            await axios.get(url).then((response) => {
                const data = response.data;
                if (data.Success) {
                    if (data != null) {
                        var itmData = data.Data;
                        itmData.map((option) => { return glblArr.push({ 'value': option.SNo, 'label': option.PROname, 'disabled': false }); });
                        setPROName(glblArr);
                    }
                    else {
                        setPROName([]);
                    }
                    //setPROName(data.Data.map((d) => (<option key={d.SNo} value={d.SNo}>{d.PROname}</option>))); 
                }
            }).catch(function (error) { });
        }
        catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    }
    const GetPaymentMode = async (paymntType, panelid) => {
        try {
            glblPaymentModesList = [];
            var tmpPaymentModes = [];
            let url = FrontDesk_URL + 'master/GetPaymentModes_Front/' + (panelid == "0" ? globalpanelid : panelid)
            await axios.get(url).then((response) => {
                const data = response.data;
                if (data.Success) {
                    var allPaymentModes = data.Data;
                    for (var i in allPaymentModes) {
                        if (paymntType === "8" || paymntType === "9") {
                            if (allPaymentModes[i].PaymentModeId == "0" || allPaymentModes[i].PaymentModeId == "False" || allPaymentModes[i].PaymentModeId == "2") {
                                setPaymentModeId("2");
                                if (allPaymentModes[i].SNo === "2") {
                                    tmpPaymentModes.push({ "PaymentMode": allPaymentModes[i].PaymentMode, "SNo": allPaymentModes[i].SNo });
                                }
                            }
                            else {
                                setPaymentModeId("1");
                                var _visitId = "0";
                                if (visitingid == "" || visitingid == undefined || visitingid == null || visitingid == "0") { _visitId = "0"; } else { _visitId = visitingid; }
                                if (_visitId !== "0") {
                                    if (allPaymentModes[i].SNo !== "2") {
                                        tmpPaymentModes.push({ "PaymentMode": allPaymentModes[i].PaymentMode, "SNo": allPaymentModes[i].SNo });
                                    }
                                }
                                else {
                                    if (allPaymentModes[i].SNo !== "2" && allPaymentModes[i].SNo !== "23") {
                                        tmpPaymentModes.push({ "PaymentMode": allPaymentModes[i].PaymentMode, "SNo": allPaymentModes[i].SNo });
                                    }
                                }
                            }
                        }
                        else {
                            if (paymentModeIdSetting === "2") {
                                setPaymentModeId("2");
                                if (allPaymentModes[i].SNo === "2" || allPaymentModes[i].SNo === "14") {
                                    tmpPaymentModes.push({ "PaymentMode": allPaymentModes[i].PaymentMode, "SNo": allPaymentModes[i].SNo });
                                }
                            }
                            else {
                                setPaymentModeId("1");
                                var _visitId = "0";
                                if (visitingid == "" || visitingid == undefined || visitingid == null || visitingid == "0") { _visitId = "0"; } else { _visitId = visitingid; }
                                if (_visitId !== "0") {
                                    if (allPaymentModes[i].SNo !== "2") {
                                        tmpPaymentModes.push({ "PaymentMode": allPaymentModes[i].PaymentMode, "SNo": allPaymentModes[i].SNo });
                                    }
                                }
                                else {
                                    if (allPaymentModes[i].SNo !== "2" && allPaymentModes[i].SNo !== "23") {
                                        tmpPaymentModes.push({ "PaymentMode": allPaymentModes[i].PaymentMode, "SNo": allPaymentModes[i].SNo });
                                    }
                                }
                            }
                        }
                    }
                    glblPaymentModesList = tmpPaymentModes;
                    var string = [];
                    for (var i in glblPaymentModesList) {
                        string.push(<option key={glblPaymentModesList[i].SNo} value={glblPaymentModesList[i].SNo}>{glblPaymentModesList[i].PaymentMode}</option>);
                    }
                    setPaymentMode([]);
                    setPaymentMode(string);
                }
            }).catch(function (error) { if (error.response) { ErrorFunction(error.response.status); } });
        }
        catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    }
    const GetPaymentChannels = async () => {
        try {
            let url = FrontDesk_URL + 'master/getpaymentchannels_phlebo/' + localStorage.LoggedInUser
            await axios.get(url).then((response) => {
                const data = response.data;
                if (data.Success) { setPaymentChannels(data.Data.map((d) => (<option key={d.SNo} value={d.SNo}>{d.PaymentChannel}</option>))); }
            }).catch(function (error) { });
        }
        catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    }
    const GetPOSMachines = async () => {
        try {
            let url = FrontDesk_URL + 'master/getposmachines/' + localStorage.LoggedInUser
            await axios.get(url).then((response) => {
                const data = response.data;
                if (data.Success) { glblposmachine = data.Data; setPOSMachines(data.Data.map((d) => (<option key={d.SNo} value={d.SNo}>{d.POSMachine}</option>))); }
            }).catch(function (error) { });
        }
        catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    }
    const GetRateTypeByPatientType = async (PatientTypeID, CorpGovSNo, setvalue, panelid) => {
        var centreid = "";
        if (pcid === "0") { centreid = localStorage.CentreSNo }
        else { centreid = pcid }
        try {
            let url = FrontDesk_URL + 'master/RateTypeListByPatientType/' + PatientTypeID + '/' + centreid + '/' + CorpGovSNo + '/' + panelid + '/' + loginuserid
            await axios.get(url).then((response) => {
                const data = response.data;
                if (data.Success) {
                    setRateType(data.Data.map((d) => (<option key={d.SNo} value={d.SNo}>{d.TemplateName}</option>)));
                    glblDefaultRec = data.Data;
                    patienttypecheck = "";
                }
                else { setRateType("0") }
            }).catch(function (error) { if (error.response) { ErrorFunction(error.response.status); } });
        }
        catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    }
    const GetGenderType = async () => {
        try {
            let url = FrontDesk_URL + 'master/GetGenderType'
            await axios.get(url).then((response) => {
                const data = response.data;
                if (data.Success) {
                    setGenders(data.Data.map((d) => (<option key={d.GenderId} value={d.GenderId}>{d.GenderName}</option>)));
                }
            }).catch(function (error) { });
        }
        catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    }
    const GetAuthorizer = async (tId) => {
        try {
            let url = FrontDesk_URL + 'master/getauthorizers/' + localStorage.CentreSNo + '/' + tId
            await axios.get(url).then((response) => {
                const data = response.data;
                if (data.Success) {
                    if (tId === "1") {
                        glblDiscountAuthorizer = [];
                        glblDiscountAuthorizer = data.Data;
                        setDiscountAuthorizers(glblDiscountAuthorizer.map((d) => (<option key={d.Id} value={d.Id}>{d.Name}</option>)));
                        setDiscountAuthorizerDetails({ "DiscountAuthorizerName": data.Data[0].Name.split("[")[0], "DiscountAuthorizerEmail": data.Data[0].EmailID });
                    } else {
                        glblCreditAuthorizer = [];
                        glblCreditAuthorizer = data.Data;
                        setCreditAuthorizers(glblCreditAuthorizer.map((d) => (<option key={d.Id} value={d.Id}>{d.Name}</option>)));
                        setCreditAuthorizerDetails({ "CreditAuthorizerName": data.Data[0].Name.split("[")[0], "CreditAuthorizerEmail": data.Data[0].EmailID });
                    }
                }
            }).catch(function (error) { });
        }
        catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    }
    const GetAllDocumentGlobal = async (GlobalVal) => {
        try {
            let url = FrontDesk_URL + 'master/GlobalDocumentList/' + GlobalVal + '/' + localStorage.CentreSNo
            await axios.get(url).then((response) => {
                const data = response.data;
                if (data.Success) { setGlobalDocument(data.Data.map((d) => (<option key={d.SNo} value={d.SNo}>{d.CorpName}</option>))); }
                else {
                    setGlobalDocument([]);
                    formik.values.ratetype = "0";
                    GetRateTypeByPatientType(GlobalVal, 0, 0, 0)
                }
            }).catch(function (error) { });
        }
        catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    }
    const ChangePaymentMode = (value) => {
        setPaymentModeId(value);
        if (value === "14" || value === "15") { }
        else { setPercentagecheckbox("0"); }
        if (value === "0") { paymentamountdetail.current.value = "" }
        else { paymentamountdetail.current.value = dueamt; }
    }
    const getitemlist = async (e, text) => {
        if (formik.values.age == "") {
            testreference.current.clear();
            SweetAlert.fire('Please select age.')
            return false;
        }
        else if (formik.values.gender == "0" || formik.values.gender == "") {
            testreference.current.clear();
            SweetAlert.fire('Please select gender.')
            return false;
        }
        else if (formik.values.ratetype == "0" || formik.values.ratetype == "") {
            testreference.current.clear();
            SweetAlert.fire('Please select rate plan.')
            return false;
        }
        var centreid = "";
        if (pcid === "0") { centreid = localStorage.CentreSNo }
        else { centreid = pcid }
        const requestOptions = {
            CentreSNo: centreid, RateTypeSNo: formik.values.ratetype, Gender: formik.values.gender, Age: formik.values.age.toString(), Item: e.target.value, ItemTypeSno: "0"
        };
        let url = FrontDesk_URL + 'master/getItemList'
        await axios.post(url, requestOptions).then((response) => {
            const data = response.data.Data;
            if (data === null) { } else { setOptions(data); }
        }).catch(function (error) { });
    }
    const getreferringdoctorlist = async (e) => {
        let url = FrontDesk_URL + 'master/GetReferredDoctorList/' + e.target.value + '/' + localStorage.CentreSNo
        await axios.get(url).then((response) => {
            const data = response.data.Data;
            if (data !== null) { setRefDocOptions(data); }
            else {
                SweetAlert.fire({
                    title: 'Please add new doctor',
                    text: "Doctor you are entering is a new doctor",
                    icon: 'warning',
                    showCancelButton: false,
                    confirmButtonText: 'Ok',
                    reverseButtons: true
                }).then((result) => { if (result.value) { openDoctorModal(); testdocreference.current.clear(); } else { openDoctorModal(); testdocreference.current.clear(); } })
                //SweetAlert.fire('Please add new doctor');
            }
        }).catch(function (error) { });
    }
    const getaddreferringdoctorlist = async (e, text, flag) => {
        if (e.target.value.length > 100) {
            SweetAlert.fire('Character limit must be 100 only');
            cleardoctor.current.clear();
            return false;
        }
        //setRefDocAddName(text);after disable doctor search in add new
        let url = FrontDesk_URL + 'master/GetReferredDoctorList/' + e.target.value + '/' + localStorage.CentreSNo
        await axios.get(url).then((response) => {
            const data = response.data.Data;
            if (data === null) { }
            else { setRefDocOptions(data); }
        }).catch(function (error) { });
    }
    const BindGrid = async (itmsno, ispackage, ratetemplateid) => {
        if (itmsno == "") { SweetAlert.fire('Please add test'); return false }
        testreference.current.clear();
        for (var key in table) { if (table[key].ItemSNo == itmsno && table[key].IsPackage == ispackage) { SweetAlert.fire('Item already added'); return false; } }
        CheckItemPackageExists(itmsno, ispackage, ratetemplateid);
    }
    const CheckItemPackageExists = async (itmsno, ispackage, ratetemplateid) => {
        if (table.length > 0) {
            var isBreak = false;
            for (var key in table) {
                let url = FrontDesk_URL + 'master/CheckItemPackageExists/' + itmsno + '/' + ispackage + '/' + table[key].ItemSNo + '/' + table[key].IsPackage + '/' + localStorage.CentreSNo
                await axios.get(url).then((response) => {
                    const data = response.data.Data;
                    if (data !== null) { if (data[0].Responseoutput === "1") { SweetAlert.fire('Item already added in list'); isBreak = true; } }
                }).catch(function (error) { });
                if (isBreak) { break; } else { }
            }
            if (isBreak) { } else { CallBindGrid(itmsno, ispackage, ratetemplateid, "0"); }
        }
        else { CallBindGrid(itmsno, ispackage, ratetemplateid, "0"); }
    }
    const CallBindGrid = async (itmsno, ispackage, ratetemplateid, callFrom) => {
        var centreid = "";
        if (pcid === "0") { centreid = localStorage.CentreSNo }
        else { centreid = pcid }
        setIsLoading(true);
        var corpGov = "0";
        var alreadyadded = "";
        const requestOptions = {
            ItemTemplateSNo: formik.values.ratetype, Gender: formik.values.gender, Age: formik.values.age.toString(), ItemSNo: itmsno, RateType: patienttypecheck, CorpGovSNo: corpGov.toString(), AlreadyAdded: alreadyadded, CentreSNo: centreid, VisitingId: "0", IsPackage: ispackage,
            RateTemplatesId: ratetemplateid === undefined ? "" : ratetemplateid, PatientItemDetailsId: "", CentreCode: localStorage.CentreCode
        };
        let url = FrontDesk_URL + 'master/AddRegestrationTestItems'
        await axios.post(url, requestOptions).then((response) => {
            const data = response.data;
            setIsLoading(false);
            if (data.Success) {
                if (data.Data.regestrationTestDetails != null) {

                    if (data.Data.AddedStatus === "-3000") { SweetAlert.fire('Item is already exists.') }
                    else if (data.Data.AddedStatus == "-3001") { SweetAlert.fire('Item is already exists.') }
                    else {
                        if (callFrom === "0") { setTable([...table, ...data.Data.regestrationTestDetails]); }
                        else { var gridItem = data.Data.regestrationTestDetails; gridItemList.push(gridItem[0]); }
                        if (data.Data.regestrationTestDetails[0].PreRequisiteFileUrl === "") { }
                        else {
                            if (refdocname.length > 0 && refDoctorName === "") { setRefDoctorName(refdocname[0]); }
                            var consentDetails = data.Data.regestrationTestDetails[0];
                            if (consentDetails.Prerequisite.toLowerCase() === "consent form") {
                                var consentFileUrl = consentDetails.PreRequisiteFileUrl.split("/");
                                var consentFormImage = consentFileUrl[consentFileUrl.length - 1];
                                var consentForm = consentFormImage.split(".");
                                setConsentFormName(consentForm[0]);
                                setIsPrintConsent(true);
                            }
                        }
                    }
                }
                else { SweetAlert.fire('No item exists.') }
            }
            else { SweetAlert.fire({ title: "API Error!", text: "Some error occured! Please try after sometime.", icon: "error" }); }
        }).catch(function (error) { setIsLoading(false); if (error.response) { ErrorFunction(error.response.status); } });
        if (callFrom !== "0") { setTable(gridItemList); }
    }
    // const DisplayDeleteTest = (ItemNo, PackageId) => {
    const DisplayDeleteTest = (rawData) => {
        // rowData.ItemSNo, rowData.PackageId
        if (rawData.PackageId === "0") {
            SweetAlert.fire({
                title: 'You are going to delete an item. Are you sure?',
                text: "Once deleted, you will not be able to recover!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Ok',
                cancelButtonText: 'Cancel',
                reverseButtons: true
                // }).then((result) => { if (result.value) { DeleteTest(ItemNo, PackageId); } else { SweetAlert.fire('Your data is safe!') } })
            }).then((result) => { if (result.value) { DeleteTest(rawData); } else { SweetAlert.fire('Your data is safe!') } })
        }
        else {
            SweetAlert.fire({
                title: 'You are going to delete a package. Are you sure?',
                text: "Once deleted, you will not be able to recover!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Ok',
                cancelButtonText: 'Cancel',
                reverseButtons: true
                // }).then((result) => { if (result.value) { DeleteTest(ItemNo, PackageId); } else { SweetAlert.fire('Your data is safe!') } })
            }).then((result) => { if (result.value) { DeleteTest(rawData); } else { SweetAlert.fire('Your data is safe!') } })
        }

    }
    // const DeleteTest = async (ItemNo, PackageId) => {
    const DeleteTest = async (rawData) => {
        setChangeFlag(true);
        chkflag = rawData.ItemSNo;
        PackageIdFlag = rawData.PackageId;
        setPreRequisite(prerequisite.filter((d) => d.ItemSNo !== rawData.ItemNo))
        SweetAlert.fire('Deleted!', 'Data has been deleted.', 'success')
        //setPaymentTable([]);
        // setPaidAmt(0);
        // setDiscAmt(0);
        // setCredAmt(0);
        //setDueAmt(0);
        //GetPaymentMode(formik.values.patienttype);
    }
    const RemovePayment = async (rowData) => {
        SweetAlert.fire({ title: 'Are you sure?', text: "Once deleted, you will not be able to recover!", icon: 'warning', showCancelButton: true, confirmButtonText: 'Ok', cancelButtonText: 'Cancel', reverseButtons: true })
            .then((result) => {
                if (result.value) {
                    setPaymentTable([]);
                    glblPaymentsList = glblPaymentsList.filter(function (x) { return x.Id !== rowData.Id; })
                    //////////////////////////////////
                    // var totalPaidSoFar = 0;
                    // setPaidAmt(0);
                    // for (var x in glblPaymentsList) { totalPaidSoFar = totalPaidSoFar + parseFloat(glblPaymentsList[x].PaidAmount); }
                    // setPaidAmt(totalPaidSoFar);
                    var totalPaidSoFar = 0;
                    var totalDiscSoFar = 0;
                    var totalCredSoFar = 0;
                    setPaidAmt(0);
                    setDiscAmt(0);
                    setCredAmt(0);
                    for (var x in glblPaymentsList) {
                        if (glblPaymentsList[x].PaymentModeId === "1" || glblPaymentsList[x].PaymentModeId === "13") {
                            totalPaidSoFar = totalPaidSoFar + parseFloat(glblPaymentsList[x].PaidAmount);
                        }
                        if (glblPaymentsList[x].PaymentModeId === "14") {
                            totalDiscSoFar = totalDiscSoFar + parseFloat(glblPaymentsList[x].PaidAmount);
                        }
                        if (glblPaymentsList[x].PaymentModeId === "15" || glblPaymentsList[x].PaymentModeId === "2") {
                            totalCredSoFar = totalCredSoFar + parseFloat(glblPaymentsList[x].PaidAmount);
                        }
                    }
                    setPaidAmt(totalPaidSoFar);
                    setDiscAmt(totalDiscSoFar);
                    setCredAmt(totalCredSoFar);
                    //////////////////////////////////
                    setPaymentTable(glblPaymentsList);
                    var _visitId = "0";
                    if (visitingid == "" || visitingid == undefined || visitingid == null || visitingid == "0") { _visitId = "0"; } else { _visitId = visitingid; }
                    if (_visitId === "0") {
                        var tmpArray = [];
                        for (var i in glblPaymentModesList) {
                            var payModeExist = false;
                            for (var j in glblPaymentsList) { if (glblPaymentsList[j].PaymentModeId == glblPaymentModesList[i].SNo) { payModeExist = true; break; } }
                            if (payModeExist == false) { tmpArray.push(<option value={glblPaymentModesList[i].SNo}>{glblPaymentModesList[i].PaymentMode}</option>); }
                        }
                        setPaymentMode([]);
                        setPaymentMode(tmpArray);
                    }
                    paymentmodedetail.current.value = "0";
                    ChangePaymentMode("0");
                }
                else { SweetAlert.fire('Your data is safe!') }
            });
    }
    const handleCheckbox = (e) => {
        const checked = e.target.checked;
        if (checked) { setCheckbox(1); } else { setCheckbox(0); }
    };
    const handlePrescriptionChecked = (e) => {
        const checked = e.target.checked;
        if (checked) { setChkBoxPrescription(1); } else { setChkBoxPrescription(0); }
    };
    const handleUrgentCheckbox = (e, ItemSNo) => {
        const checked = e.target.checked;
        if (glblArrUrgent.length > 0) {
            glblArrUrgent = glblArrUrgent.filter((e) => e.ItemSno !== ItemSNo)
        }
        if (checked) {
            setChkUrgentFlag(1);
            glblArrUrgent.push({ Urgent: "1", ItemSno: ItemSNo });
        } else {
            setChkUrgentFlag(0);
            glblArrUrgent.push({ Urgent: "0", ItemSno: ItemSNo });
        }
    };
    const CallFunctions = (e) => {
        setGlobalPanelId("0");
        formik.values.globaldocument = "0";
        setGlobalDocument([]);
        setPatientTypeNameCheck(e.target.options[e.target.selectedIndex].text);
        setPatientTypeCheck(e.target.value);
        setVisitingId("0");
        setRateType(0);
        setTable([]);
        glbldoctext = [];
        glblCheckboxList = [];
        setStoreDocumentItem([]);
        RebindFields(e.target.value);
        GetAllDocumentGlobal(e.target.value);
        if (e.target.value == "8" || e.target.value == "9") { }
        else { GetPaymentMode(e.target.value, "0"); }
    }
    const GetGlobalDocument = async (GlobalId) => {
        if (GlobalId !== null) {
            formik.values.ratetype = "0";
            glbldoctext.map((d) => d.DocumentTypeSNo == "1" ? document.getElementById("bank" + d.DocumentSNo).value = "" : null);
            glbldoctext = [];
            glblCheckboxList = [];
            setStoreDocumentItem([]);
            setGlobalCheck(GlobalId);
            if (GlobalId != "0") {
                setGlobalPanelId(GlobalId);
                pattypecheck == "" ? GetRateTypeByPatientType(formik.values.patienttype, 0, 0, GlobalId) : GetRateTypeByPatientType(formik.values.patienttype, 0, 1, GlobalId)
                GetGlobalDocs(GlobalId);
                GetPaymentMode(formik.values.patienttype, GlobalId);
            }
            else {
                setGlobalPanelId("0");
                setRateType(0);
                formik.values.ratetype = '';
                formik.values.globaldocument = '';
                setGlobalDocuments([]);
            }
        }
    }
    const GetGlobalDocumentCopy = async (GlobalId, PatientTypeId) => {
        if (GlobalId !== null) {
            formik.values.ratetype = "0";
            glbldoctext.map((d) => d.DocumentTypeSNo == "1" ? document.getElementById("bank" + d.DocumentSNo).value = "" : null);
            glbldoctext = [];
            glblCheckboxList = [];
            setStoreDocumentItem([]);
            setGlobalCheck(GlobalId);
            if (GlobalId != "0") {
                setGlobalPanelId(GlobalId);
                pattypecheck == "" ? GetRateTypeByPatientType(PatientTypeId, 0, 0, GlobalId) : GetRateTypeByPatientType(PatientTypeId, 0, 1, GlobalId)
                GetGlobalDocs(GlobalId);
                GetPaymentMode(formik.values.patienttype, GlobalId);
            }
            else {
                setGlobalPanelId("0");
                setRateType(0);
                formik.values.ratetype = '';
                formik.values.globaldocument = '';
                setGlobalDocuments([]);
            }
        }
    }
    const GetGlobalDocs = async (GlobalSNo) => {
        glblDocumentArr = [];
        glblCheckboxList = [];
        try {
            let url = FrontDesk_URL + 'master/getGlobalDocumentReq/' + GlobalSNo
            await axios.get(url).then((response) => {
                const data = response.data;
                if (data.Success) {
                    setGlobalDocuments(data.Data);
                    for (var key in data.Data) {
                        if (data.Data[key].DocumentType.toLowerCase() === "dropdown") {
                            var array = data.Data[key].DocumentValue.split(",");
                            for (var keyy in array) { glblDocumentArr.push({ DocSNo: data.Data[key].SNo, DocValue: array[keyy] }); }
                        }
                        if (data.Data[key].DocumentType.toLowerCase() === "checkbox") {
                            var array = data.Data[key].DocumentValue.split(",");
                            for (var keyy in array) { glblCheckboxList.push({ DocSNo: data.Data[key].SNo, DocValue: array[keyy], Checked: false }); }
                            setCheckedState(new Array(glblCheckboxList.length).fill(false));
                        }
                    }
                }
                else { setGlobalDocuments([]); }
            }).catch(function (error) { if (error.response) { ErrorFunction(error.response.status); } });
        }
        catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }

    }
    const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
    const onGlobalUploadDocs = async (pictureFiles, SNo, documentType) => {
        const file = pictureFiles[0];
        var base64 = await toBase64(file);
        if (pictureFiles.length > 0) {
            var extension = pictureFiles[0].name.split(".");
            var base64img = base64.split("base64,");
            const formData = new FormData();
            formData.append('Image', base64img[1]);
            formData.append('FolderName', 'prerequisite');
            formData.append('pathflag', 'prerequisite');
            const ImageName = Math.random();
            var imgName = ImageName + "patientdocumentrequired." + extension[1];
            formData.append('ImageName', imgName);
            let url = FrontDesk_URL + 'master/UploadImage_Root/'
            await axios.post(url, formData, {
                headers: { "Content-type": "multipart/form-data", },
            }).then((response) => {
                const data = response.data;
                if (glbldoctext.length > 0) { glbldoctext = glbldoctext.filter(item => (item.DocumentSNo !== SNo)); }
                if (data.Data == '' || data.Data == undefined || data.Data.length == 0) { return false; }
                glbldoctext.push({ DocumentSNo: SNo, DocumentTypeSNo: documentType, Document: imgName, });
                setStoreDocumentItem(glbldoctext);
                document.getElementById("globalimg" + SNo).innerHTML = "";
                document.getElementById("globalimg" + SNo).value = "1";
                data.Success ? SweetAlert.fire('Document selected') : SweetAlert.fire({ title: "Upload failed", text: "Unable to upload document due to " + data.Data + ".Please retry again", icon: "error" })
            }).catch(function (error) { });
        }
    }
    const Savedocumenttext = async (e, SNo, documentType) => {
        if (glbldoctext.length > 0) { glbldoctext = glbldoctext.filter(item => (item.DocumentSNo !== SNo)); }
        glbldoctext.push({ DocumentSNo: SNo, DocumentTypeSNo: documentType, Document: e.target.value, });
        setStoreDocumentItem(glbldoctext);
    }
    const BindPaymentGrid = async () => {
        if (table == "") { SweetAlert.fire('Please add some test'); return false }
        if (paymentmodedetail.current.value == "0") { SweetAlert.fire('Please select payment mode'); return false }
        if (paymentamountdetail.current.value == "") { SweetAlert.fire('Please add payment amount'); return false }
        if (paymentmodedetail.current.value !== "23" && parseFloat(paymentamountdetail.current.value) <= 0 && dueamt >= 0) { SweetAlert.fire('Please add payment amount more than ZERO'); return false }
        if ((paymentmodedetail.current.value === "13" || paymentmodedetail.current.value === "14" || paymentmodedetail.current.value === "15") && parseFloat(paymentamountdetail.current.value) <= 0 && dueamt <= 0) { SweetAlert.fire('You can make refund only in cash'); return false }
        if (paymentmodedetail.current.value == "13" && paymentChannelId.current.value === "0") { SweetAlert.fire('Please select payment channel'); return false }
        if (paymentmodedetail.current.value == "13" && paymentReferenceNo.current.value === "") {
            if (channelid == "-1" || channelid == "8") { }
            else { SweetAlert.fire('Please add reference no'); return false }
        }
        if (paymentmodedetail.current.value == "13" && channelid === "-1" && posMachineId.current.value === "0") { SweetAlert.fire('Please select POS machine'); return false }
        if ((paymentmodedetail.current.value !== "1" && paymentmodedetail.current.value !== "13") && localStorage.MinBookingCash !== "") {
            if (parseFloat(localStorage.MinBookingCash) > 0) {
                var tmpValue = parseFloat(totalamt) * (parseFloat(localStorage.MinBookingCash) / 100);
                var alreadyAddedAmount = 0;
                var cardpaidexist = false;
                for (var x in glblPaymentsList) {
                    if (glblPaymentsList[x].PaymentModeId == "1" || glblPaymentsList[x].PaymentModeId == "13") {
                        cardpaidexist = true; alreadyAddedAmount += parseFloat(glblPaymentsList[x].PaidAmount)
                    }
                }
                if (paymentModeIdSetting !== "1") {
                    if (cardpaidexist === true) {
                        if (parseFloat(alreadyAddedAmount) < parseFloat(tmpValue)) { SweetAlert.fire('You have to pay atleast ' + parseFloat(tmpValue).toFixed(2) + ' in cash.'); return false }
                    } else {
                        discoutamount = discoutamount + parseFloat(paymentamountdetail.current.value);
                        if (discoutamount > (parseFloat(totalamt) - parseFloat(tmpValue))) {
                            SweetAlert.fire('You have to pay atleast ' + parseFloat(tmpValue).toFixed(2) + ' in cash.'); discoutamount = 0; return false
                        }
                    }
                }
            }
        }
        if (paymentmodedetail.current.value == "14" && paymentModeIdSetting !== "2") {
            var selectedAuthorizorInfo = glblDiscountAuthorizer.filter(item => (item.Id === authorizerId.current.value));
            var orderPercent = parseFloat(selectedAuthorizorInfo[0].PerOrderLimit);
            var monthlyAvailableLimit = parseFloat(selectedAuthorizorInfo[0].MonthlyLimit);
            var curntAmount = paymentamountdetail.current.value;
            if (parseFloat(totalildiscount) > 0) {
                if (parseFloat(orderPercent) < parseFloat(totalhighpercent)) {
                    SweetAlert.fire('Authorizer does not have sufficient limit to approve this amount because you had selected line item level discount');
                    return false;
                }
            }
            if (Percentagecheckbox === "1") {
                var crntDiscount = parseFloat(totalamt) * (parseFloat(paymentamountdetail.current.value) / 100);
                if (parseFloat(crntDiscount) > parseFloat(monthlyAvailableLimit)) { SweetAlert.fire('Authorizer does not have sufficient limit to approve this amount.'); return false }
                if (parseFloat(curntAmount) > parseFloat(orderPercent)) { SweetAlert.fire('You can give ' + parseFloat(orderPercent).toFixed(2) + '% discount of total order value.'); return false }
                if (parseFloat(crntDiscount) > parseFloat(dueamt)) { SweetAlert.fire('You can give discount of ' + parseFloat(dueamt).toFixed(2) + '.'); return false }
            }
            else {
                var maxOrderAmount = parseFloat(totalamt) * (parseFloat(orderPercent) / 100);
                if (parseFloat(curntAmount) > parseFloat(maxOrderAmount)) { SweetAlert.fire('You can give ' + parseFloat(orderPercent).toFixed(2) + '% discount of total order value.'); return false }
                if (parseFloat(curntAmount) > parseFloat(monthlyAvailableLimit)) { SweetAlert.fire('Authorizer does not have sufficient limit to approve this amount.'); return false }
            }
        }
        if (paymentmodedetail.current.value == "15") {
            var selectedAuthorizorInfo = glblCreditAuthorizer.filter(item => (item.Id === authorizerId.current.value));
            var orderPercent = parseFloat(selectedAuthorizorInfo[0].PerOrderLimit);
            var monthlyAvailableLimit = parseFloat(selectedAuthorizorInfo[0].MonthlyLimit);
            var curntAmount = paymentamountdetail.current.value;

            if (Percentagecheckbox === "1") {
                var crntOutstanding = parseFloat(totalamt) * (parseFloat(paymentamountdetail.current.value) / 100);
                if (parseFloat(crntOutstanding) > parseFloat(monthlyAvailableLimit)) { SweetAlert.fire('Authorizer does not have sufficient limit to approve this amount.'); return false }
                if (parseFloat(curntAmount) > parseFloat(orderPercent)) { SweetAlert.fire('You can use ' + parseFloat(orderPercent).toFixed(2) + '% of total order value from outstanding amount.'); return false }
                if (parseFloat(crntOutstanding) > parseFloat(dueamt)) { SweetAlert.fire('You can use outstanding amount of ' + parseFloat(dueamt).toFixed(2) + '.'); return false }
            }
            else {
                var maxOrderAmount = parseFloat(totalamt) * (parseFloat(orderPercent) / 100);
                if (parseFloat(curntAmount) > parseFloat(monthlyAvailableLimit)) { SweetAlert.fire('Authorizer does not have sufficient limit to approve this amount.'); return false }
                if (parseFloat(curntAmount) > parseFloat(maxOrderAmount)) { SweetAlert.fire('You can use ' + parseFloat(orderPercent).toFixed(2) + '% of total order value from outstanding amount.'); return false }
            }

        }
        if (paymentmodedetail.current.value == "14" && paymentModeIdSetting === "2") {
            var orderPercent = parseFloat(centreDiscountLimit);
            var maxOrderAmount = parseFloat(totalamt) * (parseFloat(orderPercent) / 100);
            var curntAmount = paymentamountdetail.current.value;
            if (parseFloat(curntAmount) > parseFloat(maxOrderAmount)) { SweetAlert.fire('You can give ' + parseFloat(orderPercent).toFixed(2) + '% discount of total order value.'); return false }
        }
        if (paymentmodedetail.current.value == "13") {
            for (var x in glblPaymentsList) {
                if (paymentChannelId.current.value === glblPaymentsList[x].PaymentChannelId && glblPaymentsList[x].OrderDetailsId == "0" && paymentmodedetail.current.value == "13") {
                    SweetAlert.fire('Payment channel already selected, please remove selected channel or change it.');
                    return false;
                }
            }
        }
        else {
            for (var x in glblPaymentsList) {
                if (paymentmodedetail.current.value === glblPaymentsList[x].PaymentModeId && glblPaymentsList[x].OrderDetailsId == "0" && paymentmodedetail.current.value != "13") {
                    SweetAlert.fire('Payment mode already selected, please remove selected mode or change it.');
                    return false;
                }
            }
        }
        if (Percentagecheckbox === "0") { if (parseFloat(dueamt) < parseFloat(paymentamountdetail.current.value)) { SweetAlert.fire('Amount cannot be greater than total amount'); return false } }
        var payRefNo = ""; if (paymentReferenceNo.current !== null && paymentReferenceNo.current !== undefined) { payRefNo = paymentReferenceNo.current.value; }
        var _authorizerId = ""; if (authorizerId.current !== null && authorizerId.current !== undefined) { _authorizerId = authorizerId.current.value; }
        var _authorizer = ""; if (authorizerId.current !== null && authorizerId.current !== undefined) { _authorizer = authorizerId.current.options[authorizerId.current.selectedIndex].text; }
        var _concessionReason = ""; if (concessionReason.current !== null && concessionReason.current !== undefined) { _concessionReason = concessionReason.current.value; }
        var pChkbx = "";
        if (Percentagecheckbox === "1") { pChkbx = parseFloat(parseFloat(totalamt) * (parseFloat(paymentamountdetail.current.value) / 100)).toFixed(2); }
        else { pChkbx = paymentamountdetail.current.value; }
        pChkbx = Math.round(pChkbx);
        let intCountr = 0;
        for (var x in glblPaymentsList) { intCountr = parseInt(glblPaymentsList[x].Id) + 1; }
        var txtPayMode = paymentmodedetail.current.options[paymentmodedetail.current.selectedIndex].text;
        var pymntChnlId = "0";
        if (paymentChannelId.current != undefined) { pymntChnlId = paymentChannelId.current.value; }
        if (paymentmodedetail.current.value === "13" && pymntChnlId !== "0") { txtPayMode = txtPayMode + "(" + paymentChannelId.current.options[paymentChannelId.current.selectedIndex].text + ")" }
        if (paymentmodedetail.current.value === "13" && channelid === "-1" && posMachineId.current.value !== "0") {
            var POSMachineName = posMachineId.current.options[posMachineId.current.selectedIndex].text;
        }
        var posmchnId = "0";
        if (posMachineId.current != undefined) { posmchnId = posMachineId.current.value; }
        if (channelid === "-1") {
            generatepinelabtransactionid(paymentamountdetail.current.value);
        }
        else if (paymentmodedetail.current.value == "13" && channelid == "8") {
            GetReferenceLink();
        }
        else {
            glblPaymentsList.push(
                {
                    Id: intCountr, OrderDetailsId: "0", PaymentMode: txtPayMode, PaymentModeId: paymentmodedetail.current.value,
                    PaymentChannelId: pymntChnlId, PaidAmount: pChkbx.toString(), ReferenceNo: payRefNo, AuthorizerId: _authorizerId,
                    Authorizer: _authorizer, ConcessionReason: _concessionReason, Status: "0", DiscountRejectReason: "", POSMachineName: POSMachineName, POSMachineId: posmchnId
                }
            );
            //////////////////////////////////
            var totalPaidSoFar = 0;
            var totalDiscSoFar = 0;
            var totalCredSoFar = 0;
            setPaidAmt(0);
            setDiscAmt(0);
            setCredAmt(0);
            for (var x in glblPaymentsList) {
                if (glblPaymentsList[x].PaymentModeId === "1" || glblPaymentsList[x].PaymentModeId === "13") {
                    totalPaidSoFar = totalPaidSoFar + parseFloat(glblPaymentsList[x].PaidAmount);
                }
                if (glblPaymentsList[x].PaymentModeId === "14") {
                    totalDiscSoFar = totalDiscSoFar + parseFloat(glblPaymentsList[x].PaidAmount);
                }
                if (glblPaymentsList[x].PaymentModeId === "15" || glblPaymentsList[x].PaymentModeId === "2") {
                    totalCredSoFar = totalCredSoFar + parseFloat(glblPaymentsList[x].PaidAmount);
                }
            }
            setPaidAmt(totalPaidSoFar);
            setDiscAmt(totalDiscSoFar);
            setCredAmt(totalCredSoFar);
            //////////////////////////////////
            var _visitId = "0";
            if (visitingid == "" || visitingid == undefined || visitingid == null || visitingid == "0") { _visitId = "0"; } else { _visitId = visitingid; }
            if (_visitId === "0") {
                var tmpArray = [];
                for (var i in glblPaymentModesList) {
                    var payModeExist = false;
                    for (var j in glblPaymentsList) { if (glblPaymentsList[j].PaymentModeId == glblPaymentModesList[i].SNo) { payModeExist = true; break; } }
                    if (payModeExist == false) {
                        tmpArray.push(<option value={glblPaymentModesList[i].SNo}>{glblPaymentModesList[i].PaymentMode}</option>);
                    }
                }
                setPaymentMode([]);
                setPaymentMode(tmpArray);
            }
            setPaymentTable(glblPaymentsList);
            paymentamountdetail.current.value = "";
            if (paymentReferenceNo.current !== undefined && paymentReferenceNo.current !== null) { paymentReferenceNo.current.value = ""; }
            if (paymentmodedetail.current.value == "13") {
                setPayChannelId(paymentChannelId.current.value);
                if (channelid === "-1") {
                    setPosMachineId(posMachineId.current.value);
                    setPosMachineName(posMachineId.current.options[posMachineId.current.selectedIndex].text);
                }
            }
            paymentmodedetail.current.value = "0";
            setChannelId("0");
            ChangePaymentMode("0");
        }
    }
    const GetReferenceLink = async () => {
        var grandtotalamount = paymentamountdetail.current.value;
        var unixtimstamp = Math.floor(new Date().getTime() * 1000);
        var description = "0";
        var centreusername = (formik.values.patientname == "" || formik.values.patientname == null || formik.values.patientname == undefined) ? "0" : formik.values.patientname;
        var mobileno = (formik.values.patientmobile == "" || formik.values.patientmobile == null || formik.values.patientmobile == undefined) ? "0" : formik.values.patientmobile;
        var email = (formik.values.emailid == "" || formik.values.emailid == null || formik.values.emailid == undefined) ? "0" : formik.values.emailid;
        setIsButtonLoading(true);
        try {
            let url = FrontDesk_URL + 'accounts/getphlebopaymenturl/' + grandtotalamount.toString() + '/' + unixtimstamp.toString() + '/' + description + '/' + centreusername + '/' + mobileno + '/' + email + '/' + logincentreid + '/' + loginuserid
            await axios.get(url).then((response) => {
                const data = response.data;
                setIsButtonLoading(false);
                if (data.Success) {
                    if (data.Data == null) { SweetAlert.fire("Some error occured! Please try again after sometime"); }
                    else {
                        if (data.Data == "-1001") {
                            SweetAlert.fire("Currently don't have permission to access this feature. Please contact your admin.");
                        }
                        else {
                            CallExternalPaymentLink(data.Data.ReferenceId, data.Data.PaymentKey, grandtotalamount, unixtimstamp, description, centreusername, mobileno, email);
                        }
                    }
                }
                else {
                    setIsButtonLoading(false);
                    if (data.Data == "0") { SweetAlert.fire("Payment link already sent to user.Click razorpay pending status button"); }
                    else { SweetAlert.fire("Error Code: " + data.Data + " Check db logs!"); }
                }
            }).catch(function (error) { setIsButtonLoading(false); if (error.response) { ErrorFunction(error.response.status); } });
        } catch (error) { setIsButtonLoading(false); SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    }
    const CallExternalPaymentLink = async (refId, paymentKey, amount, expiry, description, centreusername, mobileno, email) => {
        amount = parseFloat(amount) * 100;
        try {
            let url = FrontDesk_URL + 'accounts/callexternalpaymentlinkphlebo/' + refId + '/' + amount.toString() + '/' + expiry.toString() + '/' + description + '/' + (centreusername == "" ? "0" : centreusername) + '/' + (mobileno == "" ? "0" : mobileno) + '/' + (email == "" ? "0" : email)
            await axios.get(url).then((response) => {
                const data = response.data;
                setIsButtonLoading(false);
                if (data.Success) {
                    if (data.Data == "-1001") { setHideShowPaymentButton(false); SweetAlert.fire("Currently don't have permission to access this feature. Please contact your admin."); }
                    else {
                        setGlblRefNo(refId);
                        setHideShowPaymentButton(true);
                        SweetAlert.fire("Payment link sent to patient mobile number");
                        //window.open(data.Data, "_blank");
                    }
                }
                else {
                    setGlblRefNo("");
                    setIsButtonLoading(false);
                    setHideShowPaymentButton(false);
                    if (data.Data == "-2") { SweetAlert.fire("No payment link generated.Please try again"); }
                    else { SweetAlert.fire("Error : " + data.Data + " Check logs!"); }
                }
            }).catch(function (error) { setHideShowPaymentButton(false); setIsButtonLoading(false); if (error.response) { ErrorFunction(error.response.status); } });
        } catch (error) { setHideShowPaymentButton(false); setIsButtonLoading(false); SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    }
    const CheckPaymentStatusRazor = async () => {
        if (glblrefno == "") {
            SweetAlert.fire("Reference no is empty to check status.Please try again");
            return false;
        }
        setIsLoading(true);
        let url = FrontDesk_URL + 'accounts/getphlebopaymentandupdatestatus/' + glblrefno
        await axios.get(url).then((response) => {
            const data = response.data;
            setIsLoading(false);
            if (data.Success) {
                if (data.Data == "-1001") { setHideShowPaymentButton(false); SweetAlert.fire("Currently don't have permission to access this feature. Please contact your admin."); }
                else {
                    if (data.Data == "-2") { SweetAlert.fire("Payment cancelled.Please try again."); }
                    else if (data.Data == "-3") { SweetAlert.fire("Payment expired.Please try again."); }
                    else if (data.Data == "0") { SweetAlert.fire("Payment still pending."); }
                    else if (data.Data == "-2000") { SweetAlert.fire("Some error occured.Check db logs"); }
                    else {
                        setHideShowPaymentButton(false);
                        const referenceno = data.Data
                        paymentReferenceNo.current.value = referenceno;
                        RecallPaymentGrid(referenceno);
                    }
                }
            }
            else {
                SweetAlert.fire('Transaction failed while receiving response from razorpay.Please try again');
            }
        }).catch(function (error) {
            setIsLoading(false);
            if (error.response) { ErrorFunction(error.response.status); }
        });
    }
    const AddPatientRegistration = async (values) => {
        setIsButtonLoading(true);
        for (var key in table) { if (table[key].Prerequisite === "") { glblitemstore[key].PrerequisiteDoc = ""; } else { glblitemstore[key].PrerequisiteDoc = ""; } }
        if (table.length == 0) { setIsButtonLoading(false); SweetAlert.fire('Please add test'); return false }
        if (parentcentreid === "0") {
            setIsButtonLoading(false);
            if (parseFloat(dueamt) != 0) { SweetAlert.fire('Please add remaining due amount'); return false; }
        }
        else {
            setIsButtonLoading(false);
            glblPaymentsList = [];
            glblPaymentsList.push(
                {
                    Id: "1", OrderDetailsId: "0", PaymentMode: "Credit", PaymentModeId: "2",
                    PaymentChannelId: "0", PaidAmount: totalamt.toString(), ReferenceNo: "", AuthorizerId: "0",
                    Authorizer: "", ConcessionReason: "", Status: "0", DiscountRejectReason: "", POSMachineName: "", POSMachineId: "0"
                }
            );
            setDueAmt("0");
            setPaymentTable(glblPaymentsList);
        }
        //if (refdocsno !== "" && refdocsno !== "0" && chkBoxPrescription === 0) { SweetAlert.fire('Prescription is mandatory.'); return false; }
        CallItemRequiredFields(values);
    }
    const SearchPatient = async (e) => {
        if (e.target.value.length == "10") {
            try {
                let url = FrontDesk_URL + 'master/getregistractionlist/' + e.target.value
                await axios.get(url).then((response) => {
                    const data = response.data;
                    if (data.Data !== null) {
                        setHoldMobileNo(e.target.value);
                        moboruhid.current.clear();
                        setMobOrUhidOptions(data.Data);
                        remarks.current.focus();
                        moboruhid.current.focus();
                        let checkbox = document.getElementById('mlcchkbox');
                        checkbox.focus();
                        moboruhid.current.focus();
                    }
                    else { setHoldMobValue(e.target.value); patientname.current.focus(); SweetAlert.fire("No patient exist"); }
                }).catch(function (error) { if (error.response) { ErrorFunction(error.response.status); } });
            }
            catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
            e.preventDefault();
        }
    }
    const ClickSearchPatient = async () => {
        if (moboruhid.current.value.length != "10") SweetAlert.fire('Patient mobile must be exactly 10 characters');
    }
    const GetEditRegistrationDetails = async (PatientSNo) => {
        setIsLoading(true);
        setPatRegNo(PatientSNo);
        setEditDetails(false); // This is to remove focus
        if (PatientSNo == "0") { return false; }
        GetOrderDate(PatientSNo);
        try {
            var isHold = "false";
            var isTestsExist = false;
            let url = FrontDesk_URL + 'master/geteditregistrationdetails/' + PatientSNo + '/' + localStorage.CentreSNo
            await axios.get(url).then((response) => {
                const data = response.data;
                if (data.Success) {
                    glbEditTestsList = [];
                    glbEditVisitId = "0";
                    setPatientSNo(PatientSNo);
                    setInvoiceGenerated(data.Data.InvoiceGenerated == 0 || data.Data.InvoiceGenerated == "False" ? false : true);
                    var dobcheck = data.Data.DOB.split("/");
                    formik.values.patienttype = data.Data.PatientTypeSNo;
                    setImageName(data.Data.PatientImage);
                    GetAllDocumentGlobal(data.Data.PatientTypeSNo);
                    GetGlobalDocument(data.Data.CorporateSNo);
                    formik.values.globaldocument = data.Data.CorporateSNo;
                    formik.values.aadhar = data.Data.AadharNo;
                    formik.values.address = data.Data.Address;
                    formik.values.title = data.Data.Title;
                    formik.values.visittype = data.Data.VisitType;
                    formik.values.patientname = data.Data.PatientName;
                    formik.values.patientmobile = data.Data.MobileNo;//holdmobileno;
                    formik.values.dob = dobcheck[0] + "-" + dobcheck[1] + "-" + dobcheck[2];//dobcheck[2] + "-" + dobcheck[1] + "-" + dobcheck[0];
                    formik.values.age = data.Data.Age;
                    formik.values.gender = data.Data.Gender;
                    if (centreTypeIdSetting == "2") {
                        formik.values.otherreflab = data.Data.OtherRefLab;
                    }
                    else {
                        formik.values.otherdoc = data.Data.OtherDr;
                    }
                    formik.values.otherreflab = data.Data.OtherRefLab;
                    formik.values.emailid = data.Data.EmailID;
                    formik.values.locStateId = data.Data.SAPStateId;
                    GetDistricts(formik.values.locStateId);
                    formik.values.locDistrictId = data.Data.SAPDistrictId;
                    formik.values.houseno = data.Data.HouseNo;
                    formik.values.pincode = data.Data.PinCode;
                    var glblArrSave = [];
                    glblArrSave.push({ 'value': data.Data.PRO, 'label': data.Data.PROName, 'disabled': false });
                    setproSelected(glblArrSave);
                    year.current.value = data.Data.Year;
                    month.current.value = data.Data.Month;
                    day.current.value = data.Data.Day;
                    setTimeout(() => {
                        if (refpatienttype.current.options[refpatienttype.current.selectedIndex].text.toLowerCase() !== "---select---") {
                            setPatientTypeNameCheck(refpatienttype.current.options[refpatienttype.current.selectedIndex].text)
                        }
                    });
                    if (localStorage.IsSourceMandatory === true || localStorage.IsSourceMandatory === "True") { formik.values.source = data.Data.Source; }
                    formik.values.viasource = data.Data.Source;
                    setEditViaSource(data.Data.Source);
                    setEditViaSourceName(data.Data.ViaSource);
                    formik.values.dispatchmode = data.Data.DesipatchMode;
                    formik.values.remarks = data.Data.Remarks;
                    if (data.Data.Patientratetypedetails !== null) {
                        formik.values.orderdate = data.Data.Patientratetypedetails[0].OrderId;
                        setVisitingId(data.Data.Patientratetypedetails[0].OrderId);
                        glbEditVisitId = data.Data.Patientratetypedetails[0].OrderId;
                    }
                    if (data.Data.MLC == "True") { setCheckbox(1); document.getElementById("mlcchkbox").checked = true; }
                    else { setCheckbox(0); document.getElementById("mlcchkbox").checked = false; }

                    if (data.Data.Prescription == "") { setChkBoxPrescription(0); document.getElementById("prescriptionchkbox").checked = false; }
                    else { setChkBoxPrescription(1); document.getElementById("prescriptionchkbox").checked = true; }

                    if (data.Data.PatientTypeSNo !== null) {
                        setPatientTypeCheck(data.Data.PatientTypeSNo);
                        pattypecheck = data.Data.PatientTypeSNo;
                    }
                    formik.values.refdoctext = data.Data.DoctorName;
                    setRefDocSno(data.Data.RefreingDr);
                    setRefDocName(data.Data.DoctorName);
                    setRefDocNameVisible(false);
                    setNewDoctorFlag(data.Data.IsNewDoctor);
                    setRefDoctorName(data.Data.DoctorName);
                    setEditDetailsAllowed(data.Data.EditDetailsAllowed);
                    setEditDemographicDetailsAllowed(data.Data.EditDemographicDetailsAllowed);
                    if (data.Data.IsHold.toLowerCase() === "true") {
                        if (data.Data.EditDetailsAllowed === "False") {
                            if (centreTypeIdSetting == "2") {
                                setDisableFields({
                                    aadhar: formik.dirty == true ? formik.dirty : !formik.dirty,
                                    address: formik.dirty == true ? formik.dirty : !formik.dirty,
                                    title: formik.dirty == true ? formik.dirty : !formik.dirty,
                                    visittype: formik.dirty == true ? formik.dirty : !formik.dirty,
                                    patientname: formik.dirty == true ? formik.dirty : !formik.dirty,
                                    patientmobile: formik.dirty == true ? formik.dirty : !formik.dirty,
                                    dob: formik.dirty == true ? formik.dirty : !formik.dirty,
                                    age: formik.dirty == true ? formik.dirty : !formik.dirty,
                                    gender: formik.dirty == true ? formik.dirty : !formik.dirty,
                                    otherreflab: formik.dirty == true ? formik.dirty : !formik.dirty,
                                    emailid: formik.dirty == true ? formik.dirty : !formik.dirty,
                                    area: formik.dirty == true ? formik.dirty : !formik.dirty,
                                    //houseno: formik.dirty == true ? formik.dirty : !formik.dirty,
                                    pincode: formik.dirty == true ? formik.dirty : !formik.dirty,
                                    locStateId: formik.dirty == true ? formik.dirty : !formik.dirty,
                                    locDistrictId: formik.dirty == true ? formik.dirty : !formik.dirty,
                                })
                            }
                            else {
                                setDisableFields({
                                    aadhar: formik.dirty == true ? formik.dirty : !formik.dirty,
                                    address: formik.dirty == true ? formik.dirty : !formik.dirty,
                                    title: formik.dirty == true ? formik.dirty : !formik.dirty,
                                    visittype: formik.dirty == true ? formik.dirty : !formik.dirty,
                                    patientname: formik.dirty == true ? formik.dirty : !formik.dirty,
                                    patientmobile: formik.dirty == true ? formik.dirty : !formik.dirty,
                                    dob: formik.dirty == true ? formik.dirty : !formik.dirty,
                                    age: formik.dirty == true ? formik.dirty : !formik.dirty,
                                    gender: formik.dirty == true ? formik.dirty : !formik.dirty,
                                    otherdoc: formik.dirty == true ? formik.dirty : !formik.dirty,
                                    emailid: formik.dirty == true ? formik.dirty : !formik.dirty,
                                    area: formik.dirty == true ? formik.dirty : !formik.dirty,
                                    //houseno: formik.dirty == true ? formik.dirty : !formik.dirty,
                                    pincode: formik.dirty == true ? formik.dirty : !formik.dirty,
                                    locStateId: formik.dirty == true ? formik.dirty : !formik.dirty,
                                    locDistrictId: formik.dirty == true ? formik.dirty : !formik.dirty,
                                })
                            }
                            setDobText(formik.dirty == true ? formik.dirty : !formik.dirty);
                            setAgeText(formik.dirty == true ? formik.dirty : !formik.dirty);
                        }
                    }
                    else {
                        if (centreTypeIdSetting == "2") {
                            setDisableFields({
                                aadhar: formik.dirty == true ? formik.dirty : !formik.dirty,
                                address: formik.dirty == true ? formik.dirty : !formik.dirty,
                                title: formik.dirty == true ? formik.dirty : !formik.dirty,
                                visittype: formik.dirty == true ? formik.dirty : !formik.dirty,
                                patientname: formik.dirty == true ? formik.dirty : !formik.dirty,
                                patientmobile: formik.dirty == true ? formik.dirty : !formik.dirty,
                                dob: formik.dirty == true ? formik.dirty : !formik.dirty,
                                age: formik.dirty == true ? formik.dirty : !formik.dirty,
                                gender: formik.dirty == true ? formik.dirty : !formik.dirty,
                                otherreflab: formik.dirty == true ? formik.dirty : !formik.dirty,
                                emailid: formik.dirty == true ? formik.dirty : !formik.dirty,
                                area: formik.dirty == true ? formik.dirty : !formik.dirty,
                                //houseno: formik.dirty == true ? formik.dirty : !formik.dirty,
                                pincode: formik.dirty == true ? formik.dirty : !formik.dirty,
                                locStateId: formik.dirty == true ? formik.dirty : !formik.dirty,
                                locDistrictId: formik.dirty == true ? formik.dirty : !formik.dirty,
                            })
                        }
                        else {
                            setDisableFields({
                                aadhar: formik.dirty == true ? formik.dirty : !formik.dirty,
                                address: formik.dirty == true ? formik.dirty : !formik.dirty,
                                title: formik.dirty == true ? formik.dirty : !formik.dirty,
                                visittype: formik.dirty == true ? formik.dirty : !formik.dirty,
                                patientname: formik.dirty == true ? formik.dirty : !formik.dirty,
                                patientmobile: formik.dirty == true ? formik.dirty : !formik.dirty,
                                dob: formik.dirty == true ? formik.dirty : !formik.dirty,
                                age: formik.dirty == true ? formik.dirty : !formik.dirty,
                                gender: formik.dirty == true ? formik.dirty : !formik.dirty,
                                otherdoc: formik.dirty == true ? formik.dirty : !formik.dirty,
                                emailid: formik.dirty == true ? formik.dirty : !formik.dirty,
                                area: formik.dirty == true ? formik.dirty : !formik.dirty,
                                //houseno: formik.dirty == true ? formik.dirty : !formik.dirty,
                                pincode: formik.dirty == true ? formik.dirty : !formik.dirty,
                                locStateId: formik.dirty == true ? formik.dirty : !formik.dirty,
                                locDistrictId: formik.dirty == true ? formik.dirty : !formik.dirty,
                            })
                        }
                        setDobText(formik.dirty == true ? formik.dirty : !formik.dirty);
                        setAgeText(formik.dirty == true ? formik.dirty : !formik.dirty);
                    }
                    setEditDetails(true);// This is to remove focus
                    moboruhid.current.blur();
                    if (data.Data.Patientratetypedetails != null) {
                        if (data.Data.Patientratetypedetails.length > 0) {
                            setEditTableDataAvailable(true);
                            glbEditTestsList = data.Data.Patientratetypedetails;
                            setEditTable(glbEditTestsList);
                            for (var keyyyy in data.Data.Patientratetypedetails) {
                                if (data.Data.Patientratetypedetails[keyyyy].Status === "1") { setStatus(true); }
                                else { setStatus(false); }
                            }
                        }
                    }
                    setTimeout(() => {
                        if (data.Data.patientdocument !== null) {
                            if (data.Data.patientdocument.length > 0) {
                                var tmpCheckList = [];
                                for (var i = 0; i < data.Data.patientdocument.length; i++) {
                                    if (data.Data.patientdocument[i].DocumentTypeSNo.toLowerCase() == "text") {
                                        document.getElementById("global" + data.Data.patientdocument[i].DocumentSNo).value = data.Data.patientdocument[i].Document
                                        document.getElementById("global" + data.Data.patientdocument[i].DocumentSNo).setAttribute('disabled', '');
                                    }
                                    else if (data.Data.patientdocument[i].DocumentTypeSNo.toLowerCase() == "dropdown") {
                                        document.getElementById("globaldropdown" + data.Data.patientdocument[i].DocumentSNo).value = data.Data.patientdocument[i].Document
                                        document.getElementById("globaldropdown" + data.Data.patientdocument[i].DocumentSNo).setAttribute('disabled', '');
                                    }
                                    else if (data.Data.patientdocument[i].DocumentTypeSNo.toLowerCase() == "checkbox") {
                                        var tmpValChecked = false;
                                        if (data.Data.patientdocument[i].Document.toLowerCase() === "true") { tmpValChecked = true; }
                                        tmpCheckList.push(tmpValChecked);
                                        setCheckedState(tmpCheckList);
                                    }
                                    else {
                                        document.getElementById("globalimg" + data.Data.patientdocument[i].DocumentSNo).innerHTML = "<a href='javascript:void(0)'>Previous Document Selected</a>"//data.Data.patientdocument[i].Document
                                        document.getElementById("globalimg" + data.Data.patientdocument[i].DocumentSNo).value = "1";
                                    }
                                    if (glbldoctext.length > 0) {
                                        glbldoctext = glbldoctext.filter(item => (item.DocumentSNo !== data.Data.patientdocument[i].DocumentSNo));
                                    }
                                    glbldoctext.push(
                                        {
                                            DocumentSNo: data.Data.patientdocument[i].DocumentSNo,
                                            DocumentTypeSNo: data.Data.patientdocument[i].DocumentTypeSNo,
                                            Document: data.Data.patientdocument[i].Document,
                                        }
                                    );
                                    setStoreDocumentItem(glbldoctext);
                                }
                                for (var l in glblCheckboxList) {
                                    glblCheckboxList[l].Checked = tmpCheckList[l];
                                    document.getElementById("chkBox-" + l).checked = tmpCheckList[l];
                                    document.getElementById("chkBox-" + l).setAttribute('disabled', '');
                                }
                            }
                        }
                    }, 1500);
                    setTimeout(() => {
                        if (data.Data.patienttotals !== null) {
                            if (data.Data.IsHold.toLowerCase() === "true") {
                                var ifexist = "0";
                                ifexist = ratetype.filter((d) => d.SNo == data.Data.patienttotals[0].SNo);
                                if (ifexist == "0") {
                                    formik.values.ratetype = "0";
                                    setHideEditSaveField(false);
                                }
                                else {
                                    formik.values.ratetype = data.Data.patienttotals[0].SNo;
                                    setHideEditSaveField(data.Data.patienttotals[0].HideEditSaveFields === "0" ? false : true);
                                }
                            }
                            else {
                                formik.values.ratetype = data.Data.patienttotals[0].SNo
                                setHideEditSaveField(data.Data.patienttotals[0].HideEditSaveFields === "0" ? false : true)
                            }
                        }
                    }, 1000);
                    if (data.Data.Patientratetypedetails != null) {
                        setAllDisableFields({
                            remarks: formik.dirty == true ? formik.dirty : !formik.dirty,
                            dispatchmode: formik.dirty == true ? formik.dirty : !formik.dirty,
                            source: formik.dirty == true ? formik.dirty : !formik.dirty,
                            patienttype: formik.dirty == true ? formik.dirty : !formik.dirty,
                            ratetype: formik.dirty == true ? formik.dirty : !formik.dirty,
                            globaldocument: formik.dirty == true ? formik.dirty : !formik.dirty,
                            viasource: formik.dirty == true ? formik.dirty : !formik.dirty,
                        });
                        isTestsExist = true;
                    }
                    moboruhid.current.clear();
                    setMobOrUhidOptions([]);
                    setTable([]);
                    ids = "";
                    editids = "";
                    glblpatientitmdetId = "";
                    setAlreadyAddedMore("0");
                    if (data.Data.Patientratetypedetails != null) { setShowDiscount(false); }
                    isHold = data.Data.IsHold.toLowerCase();
                    GetPaymentMode(formik.values.patienttype, "0");
                }
                else { setPatientSNo("0"); }
            }).catch(function (error) { setPatientSNo("0"); if (error.response) { ErrorFunction(error.response.status); } });
            if (isHold === "true") { if (isTestsExist === true) { editItems("1"); } else { saveandaddmore(); } }
            else { if (table.length == 0) { setIsLoading(false); } }
        }
        catch (error) { setPatientSNo("0"); SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    }
    const GetVisitEditRegistrationDetails = async (PatientSNo, VisitingId) => {
        setTimeout(async () => {
            setPatRegNo(PatientSNo);
            setEditDetails(false); // This is to remove focus
            if (PatientSNo == "0") { return false; }
            GetOrderDate(PatientSNo);
            try {
                var isHold = "false";
                var isTestsExist = false;
                let url = FrontDesk_URL + 'master/getvisiteditregistrationdetails/' + PatientSNo + '/' + localStorage.CentreSNo + '/' + VisitingId
                await axios.get(url).then((response) => {
                    const data = response.data;
                    if (data.Success) {
                        glbEditTestsList = [];
                        glbEditVisitId = "0";
                        setPatientSNo(PatientSNo);
                        setInvoiceGenerated(data.Data.InvoiceGenerated == 0 || data.Data.InvoiceGenerated == "False" ? false : true);
                        var dobcheck = data.Data.DOB.split("/");
                        formik.values.patienttype = data.Data.PatientTypeSNo;
                        setImageName(data.Data.PatientImage);
                        GetAllDocumentGlobal(data.Data.PatientTypeSNo);
                        GetGlobalDocument(data.Data.CorporateSNo);
                        formik.values.globaldocument = data.Data.CorporateSNo;
                        formik.values.aadhar = data.Data.AadharNo;
                        formik.values.address = data.Data.Address;
                        formik.values.title = data.Data.Title;
                        formik.values.visittype = data.Data.VisitType;
                        formik.values.patientname = data.Data.PatientName;
                        formik.values.patientmobile = data.Data.MobileNo;
                        formik.values.dob = dobcheck[0] + "-" + dobcheck[1] + "-" + dobcheck[2];//dobcheck[2] + "-" + dobcheck[1] + "-" + dobcheck[0];
                        formik.values.age = data.Data.Age;
                        formik.values.gender = data.Data.Gender;
                        if (centreTypeIdSetting == "2") {
                            formik.values.otherreflab = data.Data.OtherRefLab;
                        }
                        else {
                            formik.values.otherdoc = data.Data.OtherDr;
                        }
                        formik.values.emailid = data.Data.EmailID;
                        formik.values.locStateId = data.Data.SAPStateId;
                        GetDistricts(formik.values.locStateId);
                        formik.values.locDistrictId = data.Data.SAPDistrictId;
                        //formik.values.houseno = data.Data.HouseNo;
                        formik.values.pincode = data.Data.PinCode;
                        var glblArrSave = [];
                        glblArrSave.push({ 'value': data.Data.PRO, 'label': data.Data.PROName, 'disabled': false });
                        setproSelected(glblArrSave);
                        year.current.value = data.Data.Year;
                        month.current.value = data.Data.Month;
                        day.current.value = data.Data.Day;
                        setTimeout(() => {
                            if (refpatienttype.current.options[refpatienttype.current.selectedIndex].text.toLowerCase() !== "---select---") {
                                setPatientTypeNameCheck(refpatienttype.current.options[refpatienttype.current.selectedIndex].text)
                            }
                        });
                        if (localStorage.IsSourceMandatory === true || localStorage.IsSourceMandatory === "True") { formik.values.source = data.Data.Source; }
                        formik.values.viasource = data.Data.Source;
                        setEditViaSource(data.Data.Source);
                        setEditViaSourceName(data.Data.ViaSource);
                        formik.values.dispatchmode = data.Data.DesipatchMode;
                        formik.values.remarks = data.Data.Remarks;
                        if (data.Data.Patientratetypedetails !== null) {
                            formik.values.orderdate = data.Data.Patientratetypedetails[0].OrderId;
                            setVisitingId(data.Data.Patientratetypedetails[0].OrderId);
                            glbEditVisitId = data.Data.Patientratetypedetails[0].OrderId;
                        }
                        if (data.Data.MLC == "True") { setCheckbox(1); document.getElementById("mlcchkbox").checked = true; }
                        else { setCheckbox(0); document.getElementById("mlcchkbox").checked = false; }

                        if (data.Data.Prescription == "") { setChkBoxPrescription(0); document.getElementById("prescriptionchkbox").checked = false; }
                        else { setChkBoxPrescription(1); document.getElementById("prescriptionchkbox").checked = true; }

                        if (data.Data.PatientTypeSNo !== null) {
                            setPatientTypeCheck(data.Data.PatientTypeSNo);
                            pattypecheck = data.Data.PatientTypeSNo;
                        }
                        formik.values.refdoctext = data.Data.DoctorName;
                        setRefDocSno(data.Data.RefreingDr);
                        setRefDocName(data.Data.DoctorName);
                        setRefDocNameVisible(false);
                        setNewDoctorFlag(data.Data.IsNewDoctor);
                        setRefDoctorName(data.Data.DoctorName);
                        setEditDetailsAllowed(data.Data.EditDetailsAllowed);
                        setEditDemographicDetailsAllowed(data.Data.EditDemographicDetailsAllowed);
                        if (data.Data.IsHold.toLowerCase() === "true") {
                            if (data.Data.EditDetailsAllowed === "False") {
                                if (centreTypeIdSetting == "2") {
                                    setDisableFields({
                                        aadhar: formik.dirty == true ? formik.dirty : !formik.dirty,
                                        address: formik.dirty == true ? formik.dirty : !formik.dirty,
                                        title: formik.dirty == true ? formik.dirty : !formik.dirty,
                                        visittype: formik.dirty == true ? formik.dirty : !formik.dirty,
                                        patientname: formik.dirty == true ? formik.dirty : !formik.dirty,
                                        patientmobile: formik.dirty == true ? formik.dirty : !formik.dirty,
                                        dob: formik.dirty == true ? formik.dirty : !formik.dirty,
                                        age: formik.dirty == true ? formik.dirty : !formik.dirty,
                                        gender: formik.dirty == true ? formik.dirty : !formik.dirty,
                                        otherreflab: formik.dirty == true ? formik.dirty : !formik.dirty,
                                        emailid: formik.dirty == true ? formik.dirty : !formik.dirty,
                                        area: formik.dirty == true ? formik.dirty : !formik.dirty,
                                        //houseno: formik.dirty == true ? formik.dirty : !formik.dirty,
                                        pincode: formik.dirty == true ? formik.dirty : !formik.dirty,
                                        locStateId: formik.dirty == true ? formik.dirty : !formik.dirty,
                                        locDistrictId: formik.dirty == true ? formik.dirty : !formik.dirty,
                                    })
                                }
                                else {
                                    setDisableFields({
                                        aadhar: formik.dirty == true ? formik.dirty : !formik.dirty,
                                        address: formik.dirty == true ? formik.dirty : !formik.dirty,
                                        title: formik.dirty == true ? formik.dirty : !formik.dirty,
                                        visittype: formik.dirty == true ? formik.dirty : !formik.dirty,
                                        patientname: formik.dirty == true ? formik.dirty : !formik.dirty,
                                        patientmobile: formik.dirty == true ? formik.dirty : !formik.dirty,
                                        dob: formik.dirty == true ? formik.dirty : !formik.dirty,
                                        age: formik.dirty == true ? formik.dirty : !formik.dirty,
                                        gender: formik.dirty == true ? formik.dirty : !formik.dirty,
                                        otherdoc: formik.dirty == true ? formik.dirty : !formik.dirty,
                                        emailid: formik.dirty == true ? formik.dirty : !formik.dirty,
                                        area: formik.dirty == true ? formik.dirty : !formik.dirty,
                                        //houseno: formik.dirty == true ? formik.dirty : !formik.dirty,
                                        pincode: formik.dirty == true ? formik.dirty : !formik.dirty,
                                        locStateId: formik.dirty == true ? formik.dirty : !formik.dirty,
                                        locDistrictId: formik.dirty == true ? formik.dirty : !formik.dirty,
                                    })
                                }
                                setDobText(formik.dirty == true ? formik.dirty : !formik.dirty);
                                setAgeText(formik.dirty == true ? formik.dirty : !formik.dirty);
                            }
                        }
                        else {
                            if (centreTypeIdSetting == "2") {
                                setDisableFields({
                                    aadhar: formik.dirty == true ? formik.dirty : !formik.dirty,
                                    address: formik.dirty == true ? formik.dirty : !formik.dirty,
                                    title: formik.dirty == true ? formik.dirty : !formik.dirty,
                                    visittype: formik.dirty == true ? formik.dirty : !formik.dirty,
                                    patientname: formik.dirty == true ? formik.dirty : !formik.dirty,
                                    patientmobile: formik.dirty == true ? formik.dirty : !formik.dirty,
                                    dob: formik.dirty == true ? formik.dirty : !formik.dirty,
                                    age: formik.dirty == true ? formik.dirty : !formik.dirty,
                                    gender: formik.dirty == true ? formik.dirty : !formik.dirty,
                                    otherreflab: formik.dirty == true ? formik.dirty : !formik.dirty,
                                    emailid: formik.dirty == true ? formik.dirty : !formik.dirty,
                                    area: formik.dirty == true ? formik.dirty : !formik.dirty,
                                    //houseno: formik.dirty == true ? formik.dirty : !formik.dirty,
                                    pincode: formik.dirty == true ? formik.dirty : !formik.dirty,
                                    locStateId: formik.dirty == true ? formik.dirty : !formik.dirty,
                                    locDistrictId: formik.dirty == true ? formik.dirty : !formik.dirty,
                                })
                            }
                            else {
                                setDisableFields({
                                    aadhar: formik.dirty == true ? formik.dirty : !formik.dirty,
                                    address: formik.dirty == true ? formik.dirty : !formik.dirty,
                                    title: formik.dirty == true ? formik.dirty : !formik.dirty,
                                    visittype: formik.dirty == true ? formik.dirty : !formik.dirty,
                                    patientname: formik.dirty == true ? formik.dirty : !formik.dirty,
                                    patientmobile: formik.dirty == true ? formik.dirty : !formik.dirty,
                                    dob: formik.dirty == true ? formik.dirty : !formik.dirty,
                                    age: formik.dirty == true ? formik.dirty : !formik.dirty,
                                    gender: formik.dirty == true ? formik.dirty : !formik.dirty,
                                    otherdoc: formik.dirty == true ? formik.dirty : !formik.dirty,
                                    emailid: formik.dirty == true ? formik.dirty : !formik.dirty,
                                    area: formik.dirty == true ? formik.dirty : !formik.dirty,
                                    //houseno: formik.dirty == true ? formik.dirty : !formik.dirty,
                                    pincode: formik.dirty == true ? formik.dirty : !formik.dirty,
                                    locStateId: formik.dirty == true ? formik.dirty : !formik.dirty,
                                    locDistrictId: formik.dirty == true ? formik.dirty : !formik.dirty,
                                })
                            }
                            setDobText(formik.dirty == true ? formik.dirty : !formik.dirty);
                            setAgeText(formik.dirty == true ? formik.dirty : !formik.dirty);
                        }
                        setEditDetails(true);// This is to remove focus
                        moboruhid.current.blur();
                        if (data.Data.Patientratetypedetails != null) {
                            if (data.Data.Patientratetypedetails.length > 0) {
                                setEditTableDataAvailable(true);
                                glbEditTestsList = data.Data.Patientratetypedetails;
                                setEditTable(glbEditTestsList);
                                for (var keyyyy in data.Data.Patientratetypedetails) {
                                    if (data.Data.Patientratetypedetails[keyyyy].Status === "1") { setStatus(true); }
                                    else { setStatus(false); }
                                }
                            }
                        }
                        setTimeout(() => {
                            if (data.Data.patientdocument !== null) {
                                if (data.Data.patientdocument.length > 0) {
                                    var tmpCheckList = [];
                                    for (var i = 0; i < data.Data.patientdocument.length; i++) {
                                        if (data.Data.patientdocument[i].DocumentTypeSNo.toLowerCase() == "text") {
                                            document.getElementById("global" + data.Data.patientdocument[i].DocumentSNo).value = data.Data.patientdocument[i].Document
                                            document.getElementById("global" + data.Data.patientdocument[i].DocumentSNo).setAttribute('disabled', '');
                                        }
                                        else if (data.Data.patientdocument[i].DocumentTypeSNo.toLowerCase() == "dropdown") {
                                            document.getElementById("globaldropdown" + data.Data.patientdocument[i].DocumentSNo).value = data.Data.patientdocument[i].Document
                                            document.getElementById("globaldropdown" + data.Data.patientdocument[i].DocumentSNo).setAttribute('disabled', '');
                                        }
                                        else if (data.Data.patientdocument[i].DocumentTypeSNo.toLowerCase() == "checkbox") {
                                            var tmpValChecked = false;
                                            if (data.Data.patientdocument[i].Document.toLowerCase() === "true") { tmpValChecked = true; }
                                            tmpCheckList.push(tmpValChecked);
                                            setCheckedState(tmpCheckList);
                                        }
                                        else {
                                            document.getElementById("globalimg" + data.Data.patientdocument[i].DocumentSNo).innerHTML = "<a href='javascript:void(0)'>Previous Document Selected</a>"//data.Data.patientdocument[i].Document
                                            document.getElementById("globalimg" + data.Data.patientdocument[i].DocumentSNo).value = "1";
                                        }
                                        if (glbldoctext.length > 0) {
                                            glbldoctext = glbldoctext.filter(item => (item.DocumentSNo !== data.Data.patientdocument[i].DocumentSNo));
                                        }
                                        glbldoctext.push(
                                            {
                                                DocumentSNo: data.Data.patientdocument[i].DocumentSNo,
                                                DocumentTypeSNo: data.Data.patientdocument[i].DocumentTypeSNo,
                                                Document: data.Data.patientdocument[i].Document,
                                            }
                                        );
                                        setStoreDocumentItem(glbldoctext);
                                    }
                                    for (var l in glblCheckboxList) {
                                        glblCheckboxList[l].Checked = tmpCheckList[l];
                                        document.getElementById("chkBox-" + l).checked = tmpCheckList[l];
                                        document.getElementById("chkBox-" + l).setAttribute('disabled', '');
                                    }
                                }
                            }
                        }, 1500);
                        setTimeout(() => {
                            if (data.Data.patienttotals !== null) {
                                if (data.Data.IsHold.toLowerCase() === "true") {
                                    var ifexist = "0";
                                    ifexist = ratetype.filter((d) => d.SNo == data.Data.patienttotals[0].SNo);
                                    if (ifexist == "0") {
                                        formik.values.ratetype = "0";
                                        setHideEditSaveField(false);
                                    }
                                    else {
                                        formik.values.ratetype = data.Data.patienttotals[0].SNo;
                                        setHideEditSaveField(data.Data.patienttotals[0].HideEditSaveFields === "0" ? false : true);
                                    }
                                }
                                else {
                                    formik.values.ratetype = data.Data.patienttotals[0].SNo
                                    setHideEditSaveField(data.Data.patienttotals[0].HideEditSaveFields === "0" ? false : true)
                                }
                                // if (data.Data.patienttotals !== null) {
                                //     formik.values.ratetype = data.Data.patienttotals[0].SNo
                                //     setHideEditSaveField(data.Data.patienttotals[0].HideEditSaveFields === "0" ? false : true)
                                // }
                            }
                        }, 1000);
                        if (data.Data.Patientratetypedetails != null) {
                            setAllDisableFields({
                                remarks: formik.dirty == true ? formik.dirty : !formik.dirty,
                                dispatchmode: formik.dirty == true ? formik.dirty : !formik.dirty,
                                source: formik.dirty == true ? formik.dirty : !formik.dirty,
                                patienttype: formik.dirty == true ? formik.dirty : !formik.dirty,
                                ratetype: formik.dirty == true ? formik.dirty : !formik.dirty,
                                globaldocument: formik.dirty == true ? formik.dirty : !formik.dirty,
                                viasource: formik.dirty == true ? formik.dirty : !formik.dirty,
                            });
                            isTestsExist = true;
                        }
                        moboruhid.current.clear();
                        setMobOrUhidOptions([]);
                        setTable([]);
                        ids = "";
                        editids = "";
                        glblpatientitmdetId = "";
                        setAlreadyAddedMore("0");
                        if (data.Data.Patientratetypedetails != null) { setShowDiscount(false); }
                        isHold = data.Data.IsHold.toLowerCase();
                        GetPaymentMode(formik.values.patienttype, "0");
                    }
                    else { setPatientSNo("0"); }
                }).catch(function (error) { setPatientSNo("0"); if (error.response) { ErrorFunction(error.response.status); } });
                if (isHold === "true") { if (isTestsExist === true) { editItems("1"); } else { saveandaddmore(); } }
                else { if (table.length == 0) { setIsLoading(false); } }
            }
            catch (error) { setPatientSNo("0"); SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
        }, 3000);
    }
    const saveandaddmore = async () => {
        if (editdetailsallowed === "True") {
            if (centreTypeIdSetting == "2") {
                setDisableFields({
                    aadhar: formik.dirty == false ? formik.dirty : !formik.dirty,
                    address: formik.dirty == false ? formik.dirty : !formik.dirty,
                    title: formik.dirty == false ? formik.dirty : !formik.dirty,
                    visittype: formik.dirty == false ? formik.dirty : !formik.dirty,
                    patientname: formik.dirty == false ? formik.dirty : !formik.dirty,
                    patientmobile: formik.dirty == false ? formik.dirty : !formik.dirty,
                    dob: formik.dirty == false ? formik.dirty : !formik.dirty,
                    age: formik.dirty == false ? formik.dirty : !formik.dirty,
                    gender: formik.dirty == false ? formik.dirty : !formik.dirty,
                    otherreflab: formik.dirty == false ? formik.dirty : !formik.dirty,
                    emailid: formik.dirty == false ? formik.dirty : !formik.dirty,
                    area: formik.dirty == false ? formik.dirty : !formik.dirty,
                    //houseno: formik.dirty == false ? formik.dirty : !formik.dirty,
                    pincode: formik.dirty == false ? formik.dirty : !formik.dirty,
                    locStateId: formik.dirty == false ? formik.dirty : !formik.dirty,
                    locDistrictId: formik.dirty == false ? formik.dirty : !formik.dirty,
                })
            } else {
                setDisableFields({
                    aadhar: formik.dirty == false ? formik.dirty : !formik.dirty,
                    address: formik.dirty == false ? formik.dirty : !formik.dirty,
                    title: formik.dirty == false ? formik.dirty : !formik.dirty,
                    visittype: formik.dirty == false ? formik.dirty : !formik.dirty,
                    patientname: formik.dirty == false ? formik.dirty : !formik.dirty,
                    patientmobile: formik.dirty == false ? formik.dirty : !formik.dirty,
                    dob: formik.dirty == false ? formik.dirty : !formik.dirty,
                    age: formik.dirty == false ? formik.dirty : !formik.dirty,
                    gender: formik.dirty == false ? formik.dirty : !formik.dirty,
                    otherdoc: formik.dirty == false ? formik.dirty : !formik.dirty,
                    emailid: formik.dirty == false ? formik.dirty : !formik.dirty,
                    area: formik.dirty == false ? formik.dirty : !formik.dirty,
                    //houseno: formik.dirty == false ? formik.dirty : !formik.dirty,
                    pincode: formik.dirty == false ? formik.dirty : !formik.dirty,
                    locStateId: formik.dirty == false ? formik.dirty : !formik.dirty,
                    locDistrictId: formik.dirty == false ? formik.dirty : !formik.dirty,
                })
            }
            setDobText(formik.dirty == false ? formik.dirty : !formik.dirty);
            setAgeText(formik.dirty == false ? formik.dirty : !formik.dirty);
        }
        setAllDisableFields({});
        setEditTableDataAvailable(false);
        setShowDiscount(true);
        var counter = 0;
        for (var key in glbldoctext) {
            if (glbldoctext[key].DocumentTypeSNo.toLowerCase() === "text") {
                document.getElementById("global" + glbldoctext[key].DocumentSNo).removeAttribute('disabled')
            }
            else if (glbldoctext[key].DocumentTypeSNo.toLowerCase() === "dropdown") {
                document.getElementById("globaldropdown" + glbldoctext[key].DocumentSNo).removeAttribute('disabled')
            }
            else if (glbldoctext[key].DocumentTypeSNo.toLowerCase() === "checkbox") {
                document.getElementById("chkBox-" + counter).removeAttribute('disabled')
                counter = counter + 1;
            }
        }
        var corpGov = "0";
        const requestOptions = {
            ItemTemplateSNo: formik.values.ratetype, Gender: formik.values.gender, Age: formik.values.age, ItemSNo: ids, RateType: formik.values.patienttype, CorpGovSNo: corpGov.toString(), AlreadyAdded: "SaveMore", CentreSNo: localStorage.CentreSNo, VisitingId: "-1",
            IsPackage: "", RateTemplatesId: "", PatientItemDetailsId: glblpatientitmdetId, CentreCode: localStorage.CentreCode
        };
        setIsLoading(true);
        let url = FrontDesk_URL + 'master/AddRegestrationTestItems'
        await axios.post(url, requestOptions).then((response) => {
            const data = response.data;
            setIsLoading(false);
            if (data.Success) {
                setVisitingId(-1);
                if (data.Data.regestrationTestDetails != null) {
                    var glbNewRecords = [];
                    var showmsg = "";
                    for (var key in data.Data.regestrationTestDetails) {
                        if (data.Data.regestrationTestDetails[key].Rate === "0" || data.Data.regestrationTestDetails[key].Rate === "0.00") { showmsg = "1" }
                        else { glbNewRecords.push(data.Data.regestrationTestDetails[key]); }
                    }
                    if (showmsg === "1") { SweetAlert.fire('One or more items would not show because they do not have rates.') }
                    data.Data.AddedStatus == "-3000" ? SweetAlert.fire('Item is already exists.') :
                        data.Data.AddedStatus == "-3001" ? SweetAlert.fire('Item is already exists.') :
                            data.Data.AddedStatus == "-2000" ? SweetAlert.fire('Some error occured! Please try after sometime.') :
                                setTable([...table, ...glbNewRecords])
                    setAlreadyAddedMore("1");
                }
            }
            else { SweetAlert.fire('Some error occured! Please try after sometime.') }
        }).catch(function (error) {
            setIsLoading(false);
            if (error.response) { ErrorFunction(error.response.status); }
        });
    }
    const editItems = async (callFrom) => {
        if (editdetailsallowed === "True") {
            if (centreTypeIdSetting == "2") {
                setDisableFields({
                    aadhar: formik.dirty == false ? formik.dirty : !formik.dirty,
                    address: formik.dirty == false ? formik.dirty : !formik.dirty,
                    title: formik.dirty == false ? formik.dirty : !formik.dirty,
                    visittype: formik.dirty == false ? formik.dirty : !formik.dirty,
                    patientname: formik.dirty == false ? formik.dirty : !formik.dirty,
                    patientmobile: formik.dirty == false ? formik.dirty : !formik.dirty,
                    dob: formik.dirty == false ? formik.dirty : !formik.dirty,
                    age: formik.dirty == false ? formik.dirty : !formik.dirty,
                    gender: formik.dirty == false ? formik.dirty : !formik.dirty,
                    otherreflab: formik.dirty == false ? formik.dirty : !formik.dirty,
                    emailid: formik.dirty == false ? formik.dirty : !formik.dirty,
                    area: formik.dirty == false ? formik.dirty : !formik.dirty,
                    //houseno: formik.dirty == false ? formik.dirty : !formik.dirty,
                    pincode: formik.dirty == false ? formik.dirty : !formik.dirty,
                    locStateId: formik.dirty == false ? formik.dirty : !formik.dirty,
                    locDistrictId: formik.dirty == false ? formik.dirty : !formik.dirty,
                })
            }
            else {
                setDisableFields({
                    aadhar: formik.dirty == false ? formik.dirty : !formik.dirty,
                    address: formik.dirty == false ? formik.dirty : !formik.dirty,
                    title: formik.dirty == false ? formik.dirty : !formik.dirty,
                    visittype: formik.dirty == false ? formik.dirty : !formik.dirty,
                    patientname: formik.dirty == false ? formik.dirty : !formik.dirty,
                    patientmobile: formik.dirty == false ? formik.dirty : !formik.dirty,
                    dob: formik.dirty == false ? formik.dirty : !formik.dirty,
                    age: formik.dirty == false ? formik.dirty : !formik.dirty,
                    gender: formik.dirty == false ? formik.dirty : !formik.dirty,
                    otherdoc: formik.dirty == false ? formik.dirty : !formik.dirty,
                    emailid: formik.dirty == false ? formik.dirty : !formik.dirty,
                    area: formik.dirty == false ? formik.dirty : !formik.dirty,
                    //houseno: formik.dirty == false ? formik.dirty : !formik.dirty,
                    pincode: formik.dirty == false ? formik.dirty : !formik.dirty,
                    locStateId: formik.dirty == false ? formik.dirty : !formik.dirty,
                    locDistrictId: formik.dirty == false ? formik.dirty : !formik.dirty,
                })
            }
            setDobText(formik.dirty == false ? formik.dirty : !formik.dirty);
            setAgeText(formik.dirty == false ? formik.dirty : !formik.dirty);
        }
        if (callFrom === "1") { setEditItemsFlag(false); }
        else { setEditItemsFlag(true); }
        setAllDisableFields({});
        setShowDiscount(true);
        setPreRequisite([]);
        var counter = 0;
        for (var key in glbldoctext) {
            if (glbldoctext[key].DocumentTypeSNo.toLowerCase() === "text") {
                document.getElementById("global" + glbldoctext[key].DocumentSNo).removeAttribute('disabled')
            }
            else if (glbldoctext[key].DocumentTypeSNo.toLowerCase() === "dropdown") {
                document.getElementById("globaldropdown" + glbldoctext[key].DocumentSNo).removeAttribute('disabled')
            }
            else if (glbldoctext[key].DocumentTypeSNo.toLowerCase() === "checkbox") {
                document.getElementById("chkBox-" + counter).removeAttribute('disabled')
                counter = counter + 1;
            }
        }
        var tmpVisitId = visitingid;
        if (callFrom === "1") { glbEditTestsList.forEach((item) => { editids += item.SNo + ","; }); tmpVisitId = glbEditVisitId; }
        else { edittable.forEach((item) => { editids += item.SNo + ","; }); }
        setEditTableDataAvailable(false);
        const requestOptions = {
            ItemTemplateSNo: formik.values.ratetype, Gender: formik.values.gender, Age: formik.values.age, ItemSNo: editids,
            RateType: formik.values.patienttype, CorpGovSNo: "0", AlreadyAdded: "EditItems", CentreSNo: localStorage.CentreSNo, VisitingId: tmpVisitId,
            IsPackage: "", RateTemplatesId: "", PatientItemDetailsId: "", CentreCode: localStorage.CentreCode
        };
        setIsLoading(true);
        var tmpArray = [];
        let url = FrontDesk_URL + 'master/AddRegestrationTestItems'
        await axios.post(url, requestOptions).then((response) => {
            const data = response.data;
            setIsLoading(false);
            if (data.Success) {
                if (data.Data.regestrationTestDetails != null) {
                    if (data.Data.AddedStatus == "-3000") { SweetAlert.fire('Item is already exists.') }
                    else if (data.Data.AddedStatus == "-3001") { SweetAlert.fire('Item is already exists.') }
                    else if (data.Data.AddedStatus == "-2000") { SweetAlert.fire('Some error occured! Please try after sometime.') }
                    else {
                        setTimeout(() => {
                            setTable([...table, ...data.Data.regestrationTestDetails]);
                        }, 1000);
                        tmpArray = data.Data.patientPaymentDetails;
                        GetPaymentMode(formik.values.patienttype, "0");
                    }
                }
                else { SweetAlert.fire('Item Details not exists.') }
            }
            else { SweetAlert.fire('Some error occured! Please try after sometime.') }
        }).catch(function (error) { setIsLoading(false); if (error.response) { ErrorFunction(error.response.status); } });

        ////////////////////////////////////////////////////
        // var totalPaidSoFar = 0;
        // setPaidAmt(0);
        // let tmpIntId = 0;
        // for (var info in tmpArray) {
        //     tmpIntId = parseInt(tmpIntId) + 1;
        //     glblPaymentsList.push(
        //         {
        //             Id: tmpIntId,
        //             OrderDetailsId: tmpArray[info].OrderDetailsId,
        //             PaymentMode: tmpArray[info].PaymentMode,
        //             PaymentModeId: tmpArray[info].PaymentModeId,
        //             PaidAmount: tmpArray[info].PaidAmount,
        //             ReferenceNo: tmpArray[info].ReferenceNo,
        //             AuthorizerId: tmpArray[info].AuthorizerId,
        //             Authorizer: tmpArray[info].Authorizer,
        //             ConcessionReason: tmpArray[info].ConcessionReason,
        //             Status: tmpArray[info].Status,
        //             DiscountRejectReason: tmpArray[info].DiscountRejectReason,
        //         }
        //     );
        //     totalPaidSoFar = totalPaidSoFar + parseFloat(tmpArray[info].PaidAmount);
        // }
        // setPaidAmt(totalPaidSoFar);
        var totalPaidSoFar = 0;
        var totalDiscSoFar = 0;
        var totalCredSoFar = 0;
        setPaidAmt(0);
        setDiscAmt(0);
        setCredAmt(0);
        let tmpIntId = 0;
        for (var info in tmpArray) {
            tmpIntId = parseInt(tmpIntId) + 1;
            glblPaymentsList.push(
                {
                    Id: tmpIntId,
                    OrderDetailsId: tmpArray[info].OrderDetailsId,
                    PaymentMode: tmpArray[info].PaymentMode,
                    PaymentModeId: tmpArray[info].PaymentModeId,
                    PaymentChannelId: tmpArray[info].PaymentChannelId,
                    PaidAmount: tmpArray[info].PaidAmount,
                    ReferenceNo: tmpArray[info].ReferenceNo,
                    AuthorizerId: tmpArray[info].AuthorizerId,
                    Authorizer: tmpArray[info].Authorizer,
                    ConcessionReason: tmpArray[info].ConcessionReason,
                    Status: tmpArray[info].Status,
                    DiscountRejectReason: tmpArray[info].DiscountRejectReason,
                    POSMachineId: tmpArray[info].POSMachineId,
                    POSMachineName: tmpArray[info].POSMachineName,
                }
            );
            if (tmpArray[info].PaymentModeId === "1" || tmpArray[info].PaymentModeId === "13") {
                totalPaidSoFar = totalPaidSoFar + parseFloat(tmpArray[info].PaidAmount);
            }
            if (tmpArray[info].PaymentModeId === "14") {
                totalDiscSoFar = totalDiscSoFar + parseFloat(tmpArray[info].PaidAmount);
            }
            if (tmpArray[info].PaymentModeId === "15" || tmpArray[info].PaymentModeId === "2") {
                totalCredSoFar = totalCredSoFar + parseFloat(tmpArray[info].PaidAmount);
            }
        }
        setPaidAmt(totalPaidSoFar);
        setDiscAmt(totalDiscSoFar);
        setCredAmt(totalCredSoFar);
        ////////////////////////////////////////////////////
        if (parentcentreid == "0") { setPaymentTable(glblPaymentsList); }
    }
    const addnewitems = async () => {
        GetPatientType();
        saveandaddmore();
    }
    const handleEditCheckbox = (e, fieldID, patientitmdetailsid) => {
        const checked = e.target.checked;
        if (checked) { ids += fieldID + ","; glblpatientitmdetId += patientitmdetailsid + ","; }
        else { ids = ids.replace(fieldID + ",", ''); glblpatientitmdetId = glblpatientitmdetId.replace(patientitmdetailsid + ",", ''); }
    };
    const invoiceHandler = () => { setInvoiceModal(!invoiceModal); };
    const GetInvoiceDetails = async () => {
        var visitid = "0";
        if (visitingid == "" || visitingid == undefined || visitingid == null || visitingid == "0") { visitid = "0"; }
        else { visitid = visitingid; }
        try {
            let url = FrontDesk_URL + 'master/GetInvoiceDetails/' + patregno + '/' + visitid
            await axios.get(url).then((response) => {
                const data = response.data;
                if (data.Success) {
                    if (data != null) {
                        setInvoiceDetails({
                            PatientName: data.Data.PatientName,
                            PatientUHID: data.Data.PatientUHID,
                            LabNo: data.Data.LabNo,
                            MobileNo: data.Data.MobileNo,
                            InvoiceNo: data.Data.InvoiceNo,
                            InvoiceDate: data.Data.InvoiceDate,
                            TotalAmt: data.Data.TotalAmt,
                            CashAmt: data.Data.CashAmt,
                            CreditAmt: data.Data.CreditAmt,
                            OnlinePayment: data.Data.OnlinePayment,
                            DiscountAmount: data.Data.DiscountAmount,
                            OutstandingAmount: data.Data.OutstandingAmount
                        });
                        setInvoiceTable(data.Data.invoicetest);
                        setInvoiceModal(true)
                    }
                }
            }).catch(function (error) { if (error.response) { ErrorFunction(error.response.status); } });
        }
        catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    }
    const CallAge = (days, months, years) => {
        if (days == "" && months == "" && years == "") {
            formik.values.dob = '';
            setDobText(formik.dirty === true ? !formik.dirty : formik.dirty);
            setCount(0);
            setTable([]);
            return false;
        }
        else if (months > 12 || months.length > 2) {
            day.current.value = "";
            month.current.value = "";
            year.current.value = "";
            formik.values.dob = "";
            setDobText(formik.dirty);
            setCount(0);
            setTable([]);
            SweetAlert.fire('Please enter valid month');
            return false;
        }
        else if (years.length > 2) {
            day.current.value = "";
            month.current.value = "";
            year.current.value = "";
            formik.values.dob = "";
            setDobText(formik.dirty);
            setCount(0);
            setTable([]);
            SweetAlert.fire('Please enter valid year');
            return false;
        }
        var mNow = months;
        if (mNow < 10 && mNow.length < 2) { mNow = "0" + mNow; }
        if ((mNow == "04" || mNow == "06" || mNow == "09" || mNow == "11") && (days > 30 || days.length > 2)) {
            day.current.value = "";
            month.current.value = "";
            year.current.value = "";
            formik.values.dob = "";
            setDobText(formik.dirty);
            setCount(0);
            setTable([]);
            SweetAlert.fire('Please enter valid day');
            return false;
        }
        else if ((mNow == "02") && (days > 28 || days.length > 2)) {
            day.current.value = "";
            month.current.value = "";
            year.current.value = "";
            formik.values.dob = "";
            setDobText(formik.dirty);
            setCount(0);
            setTable([]);
            SweetAlert.fire('Please enter valid day');
            return false;
        }
        else if ((mNow == "01" || mNow == "03" || mNow == "05" || mNow == "07" || mNow == "08" || mNow == "10" || mNow == "12") && (days > 31 || days.length > 2)) {
            day.current.value = "";
            month.current.value = "";
            year.current.value = "";
            formik.values.dob = "";
            setDobText(formik.dirty);
            setCount(0);
            setTable([]);
            SweetAlert.fire('Please enter valid day');
            return false;
        }
        else {
            refreshyear = "";
            refreshmonth = "";
            fday = "";
            fmonth = "";
            fyear = "";
            FinalYear = "";
            FinalMonth = "";
            FinalDay = "";
            NewFinalMonth = "";
            NewFinalDay = "";
            dtDay = "";
            dtMonth = "";
            dtYear = "";
            var d = new Date(), cdate = d.getDate(), cmonth = d.getMonth() + 1, cyear = d.getFullYear();
            dtDay = cdate;
            dtMonth = cmonth;
            dtYear = cyear;
            if (cdate < 10) { dtDay = "0" + cdate; }
            //if (cmonth < 10) { dtMonth = "0" + parseInt(cmonth + 1); }
            if (cmonth < 10) { dtMonth = "0" + cmonth; }
            if (years == "0" || years == "") { }
            else {
                FinalYear = dtYear - parseInt(years);
                fmonth = dtMonth - parseInt(months == "" ? 0 : months);
                fday = dtDay - parseInt(days == "" ? 0 : days);
                if (fmonth < 10) { FinalMonth = "0" + fmonth; } else { FinalMonth = fmonth }
                if (fday < 10) { FinalDay = "0" + fday; } else { FinalDay = fday }
                if (count > 0) { dateobirth.current.value = FinalDay + "-" + FinalMonth + "-" + FinalYear; }
                else { formik.values.dob = FinalDay + "-" + FinalMonth + "-" + FinalYear; }
                refreshyear = FinalYear;
                refreshmonth = FinalMonth;
            }
            if (months == "0" || months == "") { }
            else {
                FinalYear = (refreshyear == "" ? dtYear == "" ? 0 : dtYear : refreshyear);
                fmonth = dtMonth - parseInt(months);
                fday = dtDay - parseInt(days == "" ? 0 : days);
                if (fmonth < 10) { FinalMonth = "0" + fmonth; } else { FinalMonth = fmonth }
                if (fday < 10) { FinalDay = "0" + fday; } else { FinalDay = fday }
                if (months < 10 && months.length < 2) { months = "0" + months; }
                if (months >= dtMonth) {
                    var mNow = parseInt(FinalYear) - 1;
                    if (FinalMonth === "00") { NewFinalMonth = "12"; }
                    else {
                        NewFinalMonth = 12 - parseInt(FinalMonth.toString().substr(2));
                        if (NewFinalMonth < 10) { NewFinalMonth = "0" + NewFinalMonth; }
                    }
                    if (count > 0) { dateobirth.current.value = FinalDay + "-" + NewFinalMonth + "-" + (parseInt(FinalYear) - 1) }
                    else { formik.values.dob = FinalDay + "-" + NewFinalMonth + "-" + (parseInt(FinalYear) - 1) }
                }
                else {
                    if (count > 0) { dateobirth.current.value = FinalDay + "-" + FinalMonth + "-" + FinalYear; }
                    else { formik.values.dob = FinalDay + "-" + FinalMonth + "-" + FinalYear; }
                }
                refreshmonth = FinalMonth;
            }
            if (days == "0" || days == "") { }
            else {
                if (dateobirth.current.value == "" || dateobirth.current.value == "0") {
                    FinalYear = dtYear;
                    fmonth = dtMonth;
                }
                else {
                    if ((years == "" || years == "0") && (months == "" || months == "0")) {
                        FinalYear = dtYear;
                        fmonth = dtMonth;
                    }
                    else {
                        var breakdob = dateobirth.current.value.split("-");
                        FinalYear = (breakdob[2] == "" ? dtYear : breakdob[2]);
                        fmonth = (breakdob[1] == "" ? dtMonth : breakdob[1]);
                        if (FinalYear == undefined) { FinalYear = dtYear }
                        if (fmonth == undefined) { fmonth = dtMonth }
                    }
                }
                // FinalYear = (refreshyear == "" ? dtYear : refreshyear);
                // fmonth = (refreshmonth == "" ? dtMonth : refreshmonth);
                fday = dtDay - parseInt(days);
                if (fday < 10) { FinalDay = "0" + fday; } else { FinalDay = fday }
                if (fmonth < 10 && fmonth.length < 2) { FinalMonth = "0" + fmonth; } else { FinalMonth = fmonth }
                if (days < 10 && days.length < 2) { days = "0" + days; }

                if (days >= dtDay) {
                    NewFinalDay = "";
                    var mNow = parseInt(FinalMonth) - 1;
                    if (mNow < 10 && mNow.length < 2) { mNow = "0" + mNow; }
                    if (mNow == "04" || mNow == "06" || mNow == "09" || mNow == "11") {
                        if (parseInt(FinalDay.toString().substr(2)) > 29) {
                            day.current.value = "";
                            month.current.value = "";
                            year.current.value = "";
                            formik.values.dob = "";
                            setDobText(formik.dirty);
                            setCount(0);
                            setTable([]);
                            SweetAlert.fire("Please enter valid day"); return false;
                        }
                        else if (FinalDay === "00") { NewFinalDay = "30"; }
                        else {
                            NewFinalDay = 30 - parseInt(FinalDay.toString().substr(2));
                            if (NewFinalDay < 10) { NewFinalDay = "0" + NewFinalDay; }
                        }
                    }
                    else if (mNow == "02") {
                        if (parseInt(FinalDay.toString().substr(2)) > 27) {
                            day.current.value = "";
                            month.current.value = "";
                            year.current.value = "";
                            formik.values.dob = "";
                            setDobText(formik.dirty);
                            setCount(0);
                            setTable([]);
                            SweetAlert.fire("Please enter valid day"); return false;
                        }
                        else if (FinalDay === "00") { NewFinalDay = "30"; }
                        else {
                            NewFinalDay = 28 - parseInt(FinalDay.toString().substr(2));
                            if (NewFinalDay < 10) { NewFinalDay = "0" + NewFinalDay; }
                        }
                    }
                    else if (mNow == "01" || mNow == "03" || mNow == "05" || mNow == "07" || mNow == "08" || mNow == "10" || mNow == "12") {
                        if (parseInt(FinalDay.toString().substr(2)) > 30) {
                            day.current.value = "";
                            month.current.value = "";
                            year.current.value = "";
                            formik.values.dob = "";
                            setDobText(formik.dirty);
                            setCount(0);
                            setTable([]);
                            SweetAlert.fire("Please enter valid day"); return false;
                        }
                        else if (FinalDay === "00") { NewFinalDay = "30"; }
                        else {
                            NewFinalDay = 31 - parseInt(FinalDay.toString().substr(2));
                            if (NewFinalDay < 10) { NewFinalDay = "0" + NewFinalDay; }
                        }
                    }
                    if ((parseInt(FinalMonth) - 1) < 10) { FinalMonth = "0" + (parseInt(FinalMonth) - 1) }
                    else { FinalMonth = (parseInt(FinalMonth) - 1) }
                    if (count > 0) { dateobirth.current.value = NewFinalDay + "-" + FinalMonth + "-" + FinalYear; }
                    else { formik.values.dob = NewFinalDay + "-" + FinalMonth + "-" + FinalYear; }
                }
                else {
                    if (count > 0) { dateobirth.current.value = FinalDay + "-" + FinalMonth + "-" + FinalYear; }
                    else { formik.values.dob = FinalDay + "-" + FinalMonth + "-" + FinalYear; }
                }
            }
            setCount(1);
            setDobText(formik.dirty === false ? !formik.dirty : formik.dirty);
            CalculateAge();
        }
    }
    const getBirthDateFromAge = (ageDays, ageMonths, ageYears) => {
        const date = new Date();
        date.setFullYear(date.getFullYear() - ageYears);
        date.setMonth(date.getMonth() - ageMonths);
        date.setDate(date.getDate() - ageDays);
        convert(date);
        setDobText(formik.dirty === false ? !formik.dirty : formik.dirty);
        //CalculateAge();
    };
    function convert(str) {
        var date = new Date(str),
            mnth = ("0" + (date.getMonth() + 1)).slice(-2),
            day = ("0" + date.getDate()).slice(-2);
        //return [date.getFullYear(), mnth, day].join("-");
        formatDate([date.getFullYear(), mnth, day].join("-"));
    }
    function formatDate(input) {
        var datePart = input.match(/\d+/g),
            year = datePart[0], month = datePart[1], day = datePart[2];
        //return day + '/' + month + '/' + year;
        setFinalDateOfBirth(day + '-' + month + '-' + year);
        if (dobstatus == false) { setDobStatus(true); }
        else { setDobStatus(false); }
        //formik.values.dob = day + '-' + month + '-' + year;
        const date = new Date();
        const currentyear = date.getFullYear();
        formik.values.age = (currentyear - year).toString();
    }
    const getEditBirthDateFromAge = (ageDays, ageMonths, ageYears) => {
        const date = new Date();
        date.setFullYear(date.getFullYear() - ageYears);
        date.setMonth(date.getMonth() - ageMonths);
        date.setDate(date.getDate() - ageDays);
        convertEdit(date);
        setEditDobText(editformik.dirty === false ? !editformik.dirty : editformik.dirty);
    };
    function convertEdit(str) {
        var date = new Date(str),
            mnth = ("0" + (date.getMonth() + 1)).slice(-2),
            day = ("0" + date.getDate()).slice(-2);
        formatEditDate([date.getFullYear(), mnth, day].join("-"));
    }
    function formatEditDate(input) {
        var datePart = input.match(/\d+/g),
            year = datePart[0], month = datePart[1], day = datePart[2];
        setEditFinalDateOfBirth(day + '-' + month + '-' + year);
        if (editdobstatus == false) { setEditDobStatus(true); }
        else { setEditDobStatus(false); }
        const date = new Date();
        const currentyear = date.getFullYear();
        editformik.values.editage = (currentyear - year).toString();
    }
    function CallDob(dateString) {
        if (formik.values.dob == "") {
            day.current.value = "";
            month.current.value = "";
            year.current.value = "";
            setAgeText(formik.dirty === true ? !formik.dirty : formik.dirty);
            return false;
        }
        setAgeText(formik.dirty);
        if (dateString.target.value.split('-')[1] > 12 || dateString.target.value.split('-')[1].length > 2) {
            SweetAlert.fire('Please enter valid month');
            formik.values.dob = "";
            day.current.value = "";
            month.current.value = "";
            year.current.value = "";
            setAgeText(!formik.dirty);
            return false;
        }
        var mNow = dateString.target.value.split('-')[1];
        if (mNow < 10 && mNow.length < 2) { mNow = "0" + mNow; }
        if (mNow == "04" || mNow == "06" || mNow == "09" || mNow == "11") {
            if (dateString.target.value.split('-')[0] > 30 || dateString.target.value.split('-')[0].length > 2) {
                SweetAlert.fire('Please enter valid day');
                formik.values.dob = "";
                day.current.value = "";
                month.current.value = "";
                year.current.value = "";
                setAgeText(!formik.dirty);
                return false;
            }
        }
        else if (mNow == "02") {
            if (dateString.target.value.split('-')[0] > 28 || dateString.target.value.split('-')[0].length > 2) {
                SweetAlert.fire('Please enter valid day');
                formik.values.dob = "";
                day.current.value = "";
                month.current.value = "";
                year.current.value = "";
                setAgeText(!formik.dirty);
                return false;
            }
        }
        else {
            if (dateString.target.value.split('-')[0] > 31 || dateString.target.value.split('-')[0].length > 2) {
                SweetAlert.fire('Please enter valid day');
                formik.values.dob = "";
                day.current.value = "";
                month.current.value = "";
                year.current.value = "";
                setAgeText(!formik.dirty);
                return false;
            }
        }
        if (dateString.target.value !== "") {
            if (dateString.target.value.split('-')[2].length > 4) {
                SweetAlert.fire('Please enter valid year');
                formik.values.dob = "";
                formik.values.age = "";
                day.current.value = "";
                month.current.value = "";
                year.current.value = "";
                setAgeText(!formik.dirty);
                return false;
            }
        }
        var now = new Date();
        var yearNow = now.getYear();
        var monthNow = now.getMonth();
        var dateNow = now.getDate();

        var dob = new Date(dateString.target.value.substring(6, 10),
            dateString.target.value.substring(3, 5) - 1,
            dateString.target.value.substring(0, 2)
        );

        var yearDob = dob.getYear();
        var monthDob = dob.getMonth();
        var dateDob = dob.getDate();
        var age = {};
        var yearAge = "";

        yearAge = yearNow - yearDob;

        if (monthNow >= monthDob)
            var monthAge = monthNow - monthDob;
        else {
            yearAge--;
            var monthAge = 12 + monthNow - monthDob;
        }
        if (dateNow >= dateDob)
            var dateAge = dateNow - dateDob;
        else {
            monthAge--;
            var dateAge = 31 + dateNow - dateDob;

            if (monthAge < 0) {
                monthAge = 11;
                yearAge--;
            }
        }
        age = {
            years: yearAge,
            months: monthAge,
            days: dateAge
        };
        if (dateString.target.value.split('-')[2].length === 4) {
            day.current.value = age.days;
            month.current.value = age.months;
            year.current.value = age.years;
            CalculateAge();
        }
        else {
            day.current.value = "";
            month.current.value = "";
            year.current.value = "";
            formik.values.age = "";
            setOptions([]);
            setTable([]);
        }
    }
    function CallEditDob(dateString) {
        if (editformik.values.editdob == "") {
            editday.current.value = "";
            editmonth.current.value = "";
            edityear.current.value = "";
            setEditAgeText(editformik.dirty === true ? !editformik.dirty : editformik.dirty);
            return false;
        }
        setEditAgeText(editformik.dirty);
        if (dateString.target.value.split('-')[1] > 12 || dateString.target.value.split('-')[1].length > 2) {
            SweetAlert.fire('Please enter valid month');
            editformik.values.editdob = "";
            editday.current.value = "";
            editmonth.current.value = "";
            edityear.current.value = "";
            setEditAgeText(!editformik.dirty);
            return false;
        }
        var mNow = dateString.target.value.split('-')[1];
        if (mNow < 10 && mNow.length < 2) { mNow = "0" + mNow; }
        if (mNow == "04" || mNow == "06" || mNow == "09" || mNow == "11") {
            if (dateString.target.value.split('-')[0] > 30 || dateString.target.value.split('-')[0].length > 2) {
                SweetAlert.fire('Please enter valid day');
                editformik.values.editdob = "";
                editday.current.value = "";
                editmonth.current.value = "";
                edityear.current.value = "";
                setEditAgeText(!editformik.dirty);
                return false;
            }
        }
        else if (mNow == "02") {
            if (dateString.target.value.split('-')[0] > 28 || dateString.target.value.split('-')[0].length > 2) {
                SweetAlert.fire('Please enter valid day');
                editformik.values.editdob = "";
                editday.current.value = "";
                editmonth.current.value = "";
                edityear.current.value = "";
                setEditAgeText(!editformik.dirty);
                return false;
            }
        }
        else {
            if (dateString.target.value.split('-')[0] > 31 || dateString.target.value.split('-')[0].length > 2) {
                SweetAlert.fire('Please enter valid day');
                editformik.values.editdob = "";
                editday.current.value = "";
                editmonth.current.value = "";
                edityear.current.value = "";
                setEditAgeText(!editformik.dirty);
                return false;
            }
        }
        if (dateString.target.value !== "") {
            if (dateString.target.value.split('-')[2].length > 4) {
                SweetAlert.fire('Please enter valid year');
                editformik.values.editdob = "";
                editformik.values.editage = "";
                editday.current.value = "";
                editmonth.current.value = "";
                edityear.current.value = "";
                setEditAgeText(!editformik.dirty);
                return false;
            }
        }
        var now = new Date();
        var yearNow = now.getYear();
        var monthNow = now.getMonth();
        var dateNow = now.getDate();

        var dob = new Date(dateString.target.value.substring(6, 10),
            dateString.target.value.substring(3, 5) - 1,
            dateString.target.value.substring(0, 2)
        );

        var yearDob = dob.getYear();
        var monthDob = dob.getMonth();
        var dateDob = dob.getDate();
        var age = {};
        var yearAge = "";

        yearAge = yearNow - yearDob;

        if (monthNow >= monthDob)
            var monthAge = monthNow - monthDob;
        else {
            yearAge--;
            var monthAge = 12 + monthNow - monthDob;
        }
        if (dateNow >= dateDob)
            var dateAge = dateNow - dateDob;
        else {
            monthAge--;
            var dateAge = 31 + dateNow - dateDob;

            if (monthAge < 0) {
                monthAge = 11;
                yearAge--;
            }
        }
        age = {
            years: yearAge,
            months: monthAge,
            days: dateAge
        };
        if (dateString.target.value.split('-')[2].length === 4) {
            editday.current.value = age.days;
            editmonth.current.value = age.months;
            edityear.current.value = age.years;
            CalculateEditAge();
        }
        else {
            editday.current.value = "";
            editmonth.current.value = "";
            edityear.current.value = "";
            editformik.values.editage = "";
        }
    }
    const callDynamicValidations = () => {
        setTable([]);
    }
    const callDynamicValidationsnew = () => {
        setTable([]);
        glblPaymentsList = [];
        setPaymentTable([]);
        setDueAmt(0);
        setPaidAmt(0);
        setDiscAmt(0);
        setCredAmt(0);
        glblVisitValue = "1";
        setAlreadyAddedMore(0);
        saveandaddmore();
    }
    const RebindFields = (Id) => {
        pattypecheck = "";
        if (Id == "5") { formik.values.corporate = ''; }
        else if (Id == "10") { formik.values.government = ''; }
        setGlobalDocuments([]);
    }
    const CallStateCity = (e, flag) => {
        let text = e.target.value;
        let length = text.length;
        if (length === 6) { CallStateCityData(e.target.value, flag); }
        else {
            formik.values.locStateId = "0"
            formik.values.locDistrictId = "0"
        }
    }
    const CallStateCityData = async (pincode, flag) => {
        try {
            let url = FrontDesk_URL + 'master/getpincodelocations/' + pincode
            await axios.get(url).then((response) => {
                const data = response.data;
                if (data.Success) {
                    if (data != null) {
                        const pinCodeData = data.Data;
                        if (flag === "1") {
                            if (pinCodeData.length === 1) {
                                setCityModal(false);
                                formik.values.locStateId = pinCodeData[0].StateId;
                                GetDistricts(formik.values.locStateId);
                                formik.values.locDistrictId = pinCodeData[0].DistrictId;
                            }
                            else { chkeditornot = "1"; setCityModal(true); setViewCities(pinCodeData); }
                        }
                    }
                }
            }).catch(function (error) { if (error.response) { ErrorFunction(error.response.status); } });
        }
        catch (error) {
            SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" });
        }
    }
    const SelectedCity = (pinCodeData) => {
        setCityModal(false);
        if (chkeditornot === "1") {
            formik.values.locStateId = pinCodeData.StateId;
            GetDistricts(formik.values.locStateId);
            formik.values.locDistrictId = pinCodeData.DistrictId;
            chkeditornot = "";
        }
    }
    const GetOrderDate = async (patientid) => {
        try {
            let url = FrontDesk_URL + 'master/GetOrderDate/' + patientid
            await axios.get(url).then((response) => {
                const data = response.data;
                if (data.Success) {
                    setOrderDate(data.Data.map((d) => (<option key={d.OrderId} value={d.OrderId}>{d.OrderDate}</option>)));
                }
            }).catch(function (error) { });
        }
        catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    }
    const CallOrderDates = async (e) => {
        setStatus(false)
        if (e.target.value === "0") {
            setEditTable([]);
        } else {
            formik.values.patienttype = "1";
            setVisitingId(e.target.value);
            try {
                let url = FrontDesk_URL + 'master/GetChangeOrderDate/' + e.target.value + '/' + localStorage.CentreSNo
                await axios.get(url).then((response) => {
                    const data = response.data;
                    if (data.Success) {
                        setDefaultPType(data.Data[0].PatientTypeSNo);
                        setDefaultPType("0");
                        setGlobalDocument([]);
                        if (data.Data[0].SubPanelId == "0" || data.Data[0].SubPanelId == "False") {
                            if (data.Data[0].HideSaveEdit === "1") { GetRateTypeByPatientType(data.Data[0].PatientTypeSNo, "0", "0", "0"); }
                            else { GetRateTypeByPatientType("0", "0", "0", "0"); }
                        }
                        else {
                            if (refpatienttype.current.options[refpatienttype.current.selectedIndex].text.toLowerCase() !== "---select---") {
                                setPatientTypeNameCheck(refpatienttype.current.options[refpatienttype.current.selectedIndex].text)
                            }
                            GetAllDocumentGlobal(data.Data[0].PatientTypeSNo);
                            GetGlobalDocumentCopy(data.Data[0].SubPanelId, data.Data[0].PatientTypeSNo);
                            setDefaultGlobalDocument(data.Data[0].SubPanelId);
                            setDefaultGlobalDocument("0");
                        }
                        templateid = data.Data[0].TemplateId;
                        setHideEditSaveField(data.Data[0].HideSaveEdit === "0" ? false : true)
                        setEditTable(data.Data);
                        ids = "";
                        editids = "";
                        glblpatientitmdetId = "";
                        setAlreadyAddedMore("0");
                        setSourceNew(data.Data[0].Source);
                        setDispatchModeNew(data.Data[0].DesipatchMode);
                        setRemarksNew(data.Data[0].Remarks);
                        setInvoiceGenerated(data.Data[0].InvoiceGenerated == 0 || data.Data[0].InvoiceGenerated == "False" ? false : true);
                        var glblArrSave = [];
                        glblArrSave.push({ 'value': data.Data[0].PRO, 'label': data.Data[0].PROName, 'disabled': false });
                        setproSelected(glblArrSave);
                        formik.values.refdoctext = data.Data[0].RefDoctorName;
                        setRefDocSno(data.Data[0].RefDoctorId);
                        setRefDocName(data.Data[0].RefDoctorName);
                        setRefDocNameVisible(false);
                        setNewDoctorFlag(data.Data[0].IsNewDoctor);
                        setRefDoctorName(data.Data[0].RefDoctorName);
                        for (var key in data.Data) { if (data.Data[key].Status === "1") { setStatus(true) } }
                    }
                }).catch(function (error) { if (error.response) { ErrorFunction(error.response.status); } });
            }
            catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
        }
    }
    const CallPackages = async () => {
        PackageItemId = "";
        table.forEach((item) => { if (item.IsPackage === "0") { PackageItemId += item.ItemSNo + ","; } })
        try {
            let url = FrontDesk_URL + 'master/GetPackages/' + PackageItemId + '/' + formik.values.gender + '/' + formik.values.age.toString() + '/' + localStorage.CentreSNo + '/' + formik.values.ratetype
            await axios.get(url).then((response) => {
                const data = response.data;
                if (data.Success) {
                    setPackageModal(true);
                    setPopupTableData(data.Data);
                }
            }).catch(function (error) { });
        }
        catch (error) { }
    }
    const ViewPackageItems = async (PackageId) => {
        const requestOptions = {
            ItemTemplateSNo: formik.values.ratetype, Gender: formik.values.gender, Age: formik.values.age.toString(), PackageId: PackageId, RateType: formik.values.patienttype, CentreSNo: localStorage.CentreSNo
        };
        let url = FrontDesk_URL + 'master/PackageItems'
        await axios.post(url, requestOptions).then((response) => {
            const data = response.data;
            if (data.Success) {
                if (data.Data.regestrationTestDetails != null) { setViewPackageItems(data.Data.regestrationTestDetails); openModal(); }
                else { SweetAlert.fire("No items found"); }
            }
            else { SweetAlert.fire({ title: "API Error!", text: "Some error occured! Please try after sometime.", icon: "error" }); }
        }).catch(function (error) { if (error.response) { ErrorFunction(error.response.status); } });
    }
    const BindPackageItems = async (rawData) => {
        var pckgeItems = rawData.PackageItems
        gridItemList = [];
        for (var x in table) {
            var isExist = false;
            for (var i in pckgeItems) { if (pckgeItems[i].ItemId === table[x].ItemSNo) { isExist = true; break; } }
            if (isExist === false) {
                gridItemList.push(
                    {
                        Amt: table[x].Amt, AppointmentTime: "", Code: table[x].Code, DeliveryDate: table[x].DeliveryDate, Disc: table[x].Disc,
                        InvestigationId: table[x].InvestigationId, IsPackage: table[x].IsPackage, IsPanel: table[x].IsPanel, Item: table[x].Item,
                        ItemSNo: table[x].ItemSNo, Modality: table[x].Modality, PackageId: table[x].PackageId, PackageName: table[x].PackageName,
                        PreRequisiteFileUrl: table[x].PreRequisiteFileUrl, Precautions: table[x].Precautions, Prerequisite: table[x].Prerequisite,
                        PrerequisiteDocPath: table[x].PrerequisiteDocPath, Rate: table[x].Rate, SchemeId: table[x].SchemeId, Urgent: table[x].Urgent
                    }
                )
            }
            else {
                setChangeFlag(true);
                chkflag = table[x].ItemSNo;
                PackageIdFlag = "0";
                setPaymentTable([]);
                setPreRequisite(prerequisite.filter((d) => d.ItemSNo !== table[x].ItemSNo))
                // GetPaymentMode();
            }
        }
        CallBindGrid(rawData.PackageId, "1", rawData.RateTemplatesId, "1");
    }
    const ViewPanelItems = async (InvestigationId) => {
        const requestOptions = { InvestigationId: InvestigationId };
        let url = FrontDesk_URL + 'master/PanelItems'
        await axios.post(url, requestOptions).then((response) => {
            const data = response.data;
            if (data.Success) {
                if (data.Data.regestrationTestDetails != null) {
                    setViewPanelItems(data.Data.regestrationTestDetails);
                    openPanelModal();
                }
                else { SweetAlert.fire("No items found"); }
            }
            else { SweetAlert.fire({ title: "API Error!", text: "Some error occured! Please try after sometime.", icon: "error" }); }
        }).catch(function (error) { if (error.response) { ErrorFunction(error.response.status); } });
    }
    const CallItemRequiredFields = async (values) => {
        setIsButtonLoading(true);
        glblRecordsData = "";
        glblRecordsData = values;
        const requestOptionsReq = {
            ItemDetailsList: JSON.stringify(storeitem),
            CentreSNo: localStorage.CentreSNo,
            CreatedBy: localStorage.LoggedInUser
        }
        let url = FrontDesk_URL + 'master/CallItemRequiredFields'
        await axios.post(url, requestOptionsReq).then((response) => {
            const data = response.data;
            if (data.Success) {
                if (data.Data.items !== null) {
                    setIsButtonLoading(false);
                    glblreqfieldstore = [];
                    glblprerequisitestoredetails = [];
                    glblreqfieldstore = data.Data.items;
                    glblprerequisitefieldstore = [];
                    glblprerequisitefieldstore = data.Data.itemsprerequisite;
                    setViewReqFieldsItems(data.Data.items === null ? [] : data.Data.items);
                    setViewPreRequisiteItems(data.Data.itemsprerequisite === null ? [] : data.Data.itemsprerequisite);
                    openRequiredFieldModal();
                    glblRecordsData = "";
                    glblRecordsData = values;
                    GetRequiredFieldItemsDetails();
                }
                else {
                    var chkvalueexist = "0";
                    for (var key in data.Data.itemsprerequisite) {
                        if (data.Data.itemsprerequisite[key].PreRequisite === "") { }
                        else { chkvalueexist = "1" }
                    }
                    if (chkvalueexist === "1") {
                        setIsButtonLoading(false);
                        glblprerequisitefieldstore = [];
                        glblprerequisitestoredetails = [];
                        glblprerequisitefieldstore = data.Data.itemsprerequisite;
                        setViewPreRequisiteItems(data.Data.itemsprerequisite === null ? [] : data.Data.itemsprerequisite);
                        openRequiredFieldModal();
                        glblRecordsData = "";
                        glblRecordsData = values;
                        GetRequiredFieldItemsDetails();
                    } else {
                        setIsButtonLoading(false);
                        if (chkBoxPrescription === 1) { openRequiredFieldModal(); GetRequiredFieldItemsDetails(); }
                        else { SaveRegistrationDetails(values); }
                    }
                }
            }
            else {
                if (chkBoxPrescription === 1) { setIsButtonLoading(false); openRequiredFieldModal(); GetRequiredFieldItemsDetails(); }
                else { setIsButtonLoading(false); SaveRegistrationDetails(values); }
                //SaveRegistrationDetails(values);
            }
        }).catch(function (error) { setIsButtonLoading(false); if (error.response) { ErrorFunction(error.response.status); } });
    }
    const SaveRequiredFields = async () => {
        glblreqfieldstoredetails = [];
        for (var key in glblreqfieldstore) {
            if (glblreqfieldstore[key].InputType.toLowerCase() !== "dropdownlist") {
                if (glblreqfieldstore[key].InputType.toLowerCase() === "textbox") {
                    if (document.getElementById("text" + glblreqfieldstore[key].FieldId).value === "" || document.getElementById("text" + glblreqfieldstore[key].FieldId).value === undefined) {
                        SweetAlert.fire({ title: "Incomplete!", text: glblreqfieldstore[key].FieldName + " is required", icon: "warning" });
                        return false;
                    }
                    else {
                        glblreqfieldstoredetails.push({
                            FieldId: glblreqfieldstore[key].FieldId,
                            FieldNameValue: glblreqfieldstore[key].InputType.toLowerCase() === "textbox" ? document.getElementById("text" + glblreqfieldstore[key].FieldId).value : glblreqfieldstore[key].InputType.toLowerCase() === "date" ? document.getElementById("date" + glblreqfieldstore[key].FieldId).value : document.getElementById("select" + glblreqfieldstore[key].FieldId).value,
                            UnitValue: glblreqfieldstore[key].IsUnit === "1" ? document.getElementById("unitselect" + glblreqfieldstore[key].FieldId).value : "",
                        });
                    }
                }
                if (glblreqfieldstore[key].InputType.toLowerCase() === "date") {
                    if (document.getElementById("date" + glblreqfieldstore[key].FieldId).value === "" || document.getElementById("date" + glblreqfieldstore[key].FieldId).value === undefined) {
                        SweetAlert.fire({ title: "Incomplete!", text: glblreqfieldstore[key].FieldName + " is required", icon: "warning" });
                        return false;
                    }
                    else {
                        glblreqfieldstoredetails.push({
                            FieldId: glblreqfieldstore[key].FieldId,
                            FieldNameValue: glblreqfieldstore[key].InputType.toLowerCase() === "textbox" ? document.getElementById("text" + glblreqfieldstore[key].FieldId).value : glblreqfieldstore[key].InputType.toLowerCase() === "date" ? document.getElementById("date" + glblreqfieldstore[key].FieldId).value : document.getElementById("select" + glblreqfieldstore[key].FieldId).value,
                            UnitValue: glblreqfieldstore[key].IsUnit === "1" ? document.getElementById("unitselect" + glblreqfieldstore[key].FieldId).value : "",
                        });
                    }
                }
            }
            else {
                glblreqfieldstoredetails.push({
                    FieldId: glblreqfieldstore[key].FieldId,
                    FieldNameValue: glblreqfieldstore[key].InputType.toLowerCase() === "textbox" ? document.getElementById("text" + glblreqfieldstore[key].FieldId).value : glblreqfieldstore[key].InputType.toLowerCase() === "date" ? document.getElementById("date" + glblreqfieldstore[key].FieldId).value : document.getElementById("select" + glblreqfieldstore[key].FieldId).value,
                    UnitValue: glblreqfieldstore[key].IsUnit === "1" ? document.getElementById("unitselect" + glblreqfieldstore[key].FieldId).value : "",
                });
            }
        }
        for (var keyy in glblprerequisitefieldstore) {
            if (glblprerequisitefieldstore[keyy].PreRequisite !== "") {
                if (document.getElementById("temp" + glblprerequisitefieldstore[keyy].PreRequisite).value === "" || document.getElementById("temp" + glblprerequisitefieldstore[keyy].PreRequisite).value === undefined) {
                    if (document.getElementById("prerequisite" + glblprerequisitefieldstore[keyy].PreRequisite).value === "" || document.getElementById("prerequisite" + glblprerequisitefieldstore[keyy].PreRequisite).value === undefined) {
                        SweetAlert.fire({ title: "Incomplete!", text: glblprerequisitefieldstore[keyy].PreRequisite + " is required", icon: "warning" });
                        return false;
                    }
                }
            }
        }
        if (prescriptiondocument === "" && chkBoxPrescription === 1) {
            SweetAlert.fire({ title: "Incomplete!", text: "Prescription is required", icon: "warning" });
            return false;
        }
        SaveRegistrationDetails(glblRecordsData);
    }
    const SaveRegistrationDetails = async (values) => {
        if (values.patientmobile.toString().charAt(0) == "0") {
            SweetAlert.fire("Starting character of patient mobile cannot be zero");
            return false;
        }
        var visitid = "0";
        var saveaddmore = "0";
        if (visitingid == "" || visitingid == undefined || visitingid == null || visitingid == "0") { visitid = "0"; } else { visitid = visitingid; }
        if (alreadyaddedmore == "" || alreadyaddedmore == undefined || alreadyaddedmore == null || alreadyaddedmore == "0") { saveaddmore = "0"; } else { saveaddmore = alreadyaddedmore; }
        if (rratetype.current.value == "" || rratetype.current.value == "0" || rratetype.current.value == false) {
            glblVisitValue = "1";
            SweetAlert.fire("This appointment was on hold, so either choose a rate plan for new appointment or visit same centre for hold appointment.");
            return false;
        }
        if (glblVisitValue == "1") {
            visitid = "0";
            saveaddmore = "0";
        }
        if (localStorage.CentreSNo != localcentreid) {
            SweetAlert.fire("Please refresh page because centre has been change from another tab");
            return false;
        }
        if (values.gender == "0" || values.gender == "" || values.gender == null || values.gender == undefined) {
            SweetAlert.fire('Please select gender.')
            return false;
        }
        if ((values.viasource == "" || values.viasource == null || values.viasource == undefined)) {
            if (PartnerTypeIdSetting == "3" || PartnerTypeIdSetting == "4") { }
            else {
                SweetAlert.fire('Please enter source.')
                return false;
            }
        }
        var opdID = "";
        var hospitalUHID = "";
        if (glblCheckboxList.length > 0) {
            var docTextList = [];
            const index = glbldoctext.findIndex(object => object.DocumentTypeSNo === "Checkbox");
            if (index === -1) {
                for (var i in glblCheckboxList) {
                    var isChcked = "false";
                    if (glblCheckboxList[i].Checked === true) { isChcked = "true"; }
                    glbldoctext.push({ DocumentSNo: glblCheckboxList[i].DocSNo, DocumentTypeSNo: "Checkbox", Document: isChcked });
                }
            }
            else {
                for (var x in glbldoctext) {
                    if (glbldoctext[x].DocumentTypeSNo.toLowerCase() !== "checkbox") {
                        docTextList.push({ DocumentSNo: glbldoctext[x].DocumentSNo, DocumentTypeSNo: glbldoctext[x].DocumentTypeSNo, Document: glbldoctext[x].Document });
                    }
                }
                glbldoctext = docTextList;
                for (var i in glblCheckboxList) {
                    var isChcked = "false";
                    if (glblCheckboxList[i].Checked === true) { isChcked = "true"; }
                    glbldoctext.push({ DocumentSNo: glblCheckboxList[i].DocSNo, DocumentTypeSNo: "Checkbox", Document: isChcked });
                }
            }
        }
        setStoreDocumentItem(glbldoctext);
        var discountauthname = "";
        var discountauthemail = "";
        var creditauthname = "";
        var creditauthemail = "";
        var isLineItemBreak = "0";
        for (var key in paymenttable) {
            if (paymenttable[key].PaymentModeId === "14") {
                discountauthname = discountauthorizerdetails.DiscountAuthorizerName === undefined ? "" : discountauthorizerdetails.DiscountAuthorizerName
                discountauthemail = discountauthorizerdetails.DiscountAuthorizerEmail === undefined ? "" : discountauthorizerdetails.DiscountAuthorizerEmail
            }
            if (paymenttable[key].PaymentModeId === "15") {
                creditauthname = creditauthorizerdetails.CreditAuthorizerName === undefined ? "" : creditauthorizerdetails.CreditAuthorizerName
                creditauthemail = creditauthorizerdetails.CreditAuthorizerEmail === undefined ? "" : creditauthorizerdetails.CreditAuthorizerEmail
            }
            if (parseFloat(totalildiscount) > 0 && paymenttable[key].PaymentModeId == "14" && isLineItemBreak == "0") {
                isLineItemBreak = "1"
            }
        }
        if (totalildiscount > 0 && isLineItemBreak == 0) { SweetAlert.fire("Please enter discount entry also in grid because you had entered line item level discount"); return false; }
        if (values.patienttype == "8" || values.patienttype == "9") { }
        else { values.globaldocument = "0"; }
        if (values.age > 150) { SweetAlert.fire("Age cannot be greater than 150 years"); return false; }
        setIsButtonLoading(true);
        const requestOptions = {
            AlreadyAdded: saveaddmore,
            VisitingId: visitid.toString(),
            Address: values.address === undefined ? "" : values.address,
            AadharNo: values.aadhar === undefined ? "" : values.aadhar,
            PatientName: values.patientname,
            DOB: values.dob.split('-')[2] + '-' + values.dob.split('-')[1] + '-' + values.dob.split('-')[0],//values.dob.replaceAll("-", "/"),
            Age: values.age.toString(),
            Gender: values.gender,
            RefreingDr: refdocsno,
            OtherDr: values.otherdoc == undefined || values.otherdoc == null ? "" : values.otherdoc,
            EmailID: values.emailid === undefined ? "" : values.emailid,
            MobileNo: values.patientmobile.toString(),
            HouseNo: "",//(values.houseno == undefined || values.houseno == null) ? ""  : values.houseno,
            PinCode: values.pincode.toString() === undefined ? "" : values.pincode.toString(),
            PatientImage: imagename,
            PatientTypeSNo: values.patienttype,
            Source: (PartnerTypeIdSetting == "3" || PartnerTypeIdSetting == "4") ? "18" : values.viasource === undefined ? "" : values.viasource,
            DesipatchMode: values.dispatchmode === undefined ? "" : values.dispatchmode === null ? "" : values.dispatchmode,
            Remarks: values.remarks === undefined ? "" : values.remarks === null ? "" : values.remarks,
            RateType: values.ratetype,
            MLC: checkbox.toString(),
            DocList: JSON.stringify(glbldoctext),
            ItemDetailsList: JSON.stringify(storeitem),
            PaymentDetailsList: paymenttable.length == 0 || paymenttable == null || paymenttable == [] ? JSON.stringify(glblPaymentsList) : JSON.stringify(paymenttable),
            RequiredFieldsList: JSON.stringify(glblreqfieldstoredetails),
            PreRequisiteDocument: JSON.stringify(glblprerequisitestoredetails),
            TotalAmount: totalamt.toString(),
            PaidAmount: paidamt.toString(),
            DueAmount: dueamt.toString(),
            DiscAmount: discamt == null || discamt == undefined ? discamt.toString() : discamt.toString(),
            CredAmount: credamt == null || credamt == undefined ? credamt.toString() : credamt.toString(),
            DiscountAmount: values.totaldiscamt.toString(),
            DiscountReason: values.discountreason,
            selectPatientTypeSNo: values.globaldocument === undefined || values.globaldocument == null ? "0" : values.globaldocument,
            OPDID: opdID,
            HospitalUHID: hospitalUHID,
            CentreSNo: localStorage.CentreSNo,
            CreatedBy: localStorage.LoggedInUser,
            Title: values.title === "" ? "Mr." : values.title,
            VisitType: values.visittype === "" ? "CentreVisit" : values.visittype,
            IsNewDoctor: newdoctorflag,
            SAPStateId: values.locStateId,
            SAPRegionId: "0",
            SAPDistrictId: values.locDistrictId,
            RegNo: patientsno,
            IsHold: "0",
            OtherLabRefNo: values.otherLabRefNo,
            DiscountAuthorizerName: discountauthname,
            DiscountAuthorizerEmail: discountauthemail,
            CreditAuthorizerName: creditauthname,
            CreditAuthorizerEmail: creditauthemail,
            PRO: proSelected.value == null || proSelected.value == undefined || proSelected.value == [] ? "0" : proSelected.value, // values.PROName,
            Year: year.current.value,
            Month: month.current.value,
            Day: day.current.value,
            PrescriptionDocument: prescriptiondocument,
            POSMachineId: posmachineid,
            POSMachineName: posmachinename,
            PaymentChannelId: paychannelid,
            IsItemLevelDiscount: isLineItemBreak,
            PartnerTypeId: PartnerTypeIdSetting,
            IsHLMParty: hlmparty,
            OtherRefLab: values.otherreflab == undefined || values.otherreflab == null ? "" : values.otherreflab,
            RefId: glblrefno
        };
        let url = FrontDesk_URL + 'master/addPatientRegistration'
        await axios.post(url, requestOptions).then((response) => {
            setIsButtonLoading(false);
            const data = response.data;
            if (data.Success) {
                glblVisitValue = "0";
                //GetSettings(localStorage.CentreSNo, localStorage.LoggedInUser);
                localStorage.setItem('vId', data.Data);
                if (HideAmountInBookingSetting === false) {
                    if (parentcentreid === "0") {
                        setPdfReceipt(true);
                        window.open(`${process.env.PUBLIC_URL}/front-office/receipt`, '_blank');
                        //window.open(`${process.env.PUBLIC_URL}/front-office/pdfreceipt`, '_blank');
                    }
                }
                if (PrintDepartmentSlipSetting) { window.open(`${process.env.PUBLIC_URL}/front-office/printdepartmentslip`, '_blank'); }
                window.open(`${process.env.PUBLIC_URL}/front-office/printstickerdetails`, '_blank');
                SweetAlert.fire({
                    title: 'Thank You!', text: "Patient registered successfully.", icon: 'success', showCancelButton: false, confirmButtonText: 'Ok', reverseButtons: true
                }).then((result) => {
                    window.location.reload(false);
                })
            }
            else {
                setPdfReceipt(false);
                if (data.ErrorList[0].errorCode == -3000) { SweetAlert.fire('Insufficient cash.') }
                else if (data.ErrorList[0].errorCode == -3001) { SweetAlert.fire('Credit limit not allowed.') }
                else if (data.ErrorList[0].errorCode == -3002) { SweetAlert.fire('Credit limit amount should be equal to total amount.') }
                else if (data.ErrorList[0].errorCode == -3003) { SweetAlert.fire('Machine is not available for selected slot.') }
                else if (data.ErrorList[0].errorCode == -3010) { SweetAlert.fire('Patient with same mobile number already exists') }
                else if (data.ErrorList[0].errorCode == -1) { SweetAlert.fire('You cannot edit this item because invoice has already generated') }
                else if (data.Data == -3005) { SweetAlert.fire('Item already added in package.') }
                else if (data.ErrorList[0].errorCode == -9997) { SweetAlert.fire('Amount does not match, this is due to parallel edit of amount by another user. Please check edit again.') }
                else if (data.ErrorList[0].errorCode == -2000 || data.Data == -2000) { SweetAlert.fire({ title: "API Runtime Exception", text: "Some error occured! Please try after sometime.", icon: "error" }) }
                else if (data.ErrorList[0].errorCode == -2005) { SweetAlert.fire('You can make refund only in cash.') }
                else if (data.ErrorList[0].errorCode == -3004) { SweetAlert.fire('Age cannot be greater than 150 years') }
                else if (data.ErrorList[0].errorCode == -3012) { SweetAlert.fire('Please enter discount entry also in grid because you had entered line item level discount') }
                else if (data.ErrorList[0].errorCode == -3013) { SweetAlert.fire('Discount amount cannot be greater than rate') }
                else if (data.ErrorList[0].errorCode == -3021) { SweetAlert.fire('Insufficient credit limit.Please contact parent company') }
                else { SweetAlert.fire({ title: "Application Exception", text: "Some error occured! Please try after sometime.", icon: "error" }) }
            }
        }).catch(function (error) {
            setPdfReceipt(false);
            setIsButtonLoading(false);
            if (error.response) { ErrorFunction(error.response.status); }
        });
    }
    const GetRequiredFieldItemsDetails = async () => {
        let url = FrontDesk_URL + 'master/GetRequiredFieldItemsDetails/' + visitingid.toString()
        await axios.get(url).then((response) => {
            const data = response.data;
            if (data.Success) {
                if (data.Data.itemsreqfields !== null) {
                    for (var key in data.Data.itemsreqfields) {
                        if (data.Data.itemsreqfields[key].Type === "text") {
                            document.getElementById("text" + data.Data.itemsreqfields[key].FieldId).value = data.Data.itemsreqfields[key].FieldNameValue;
                            document.getElementById("unitselect" + data.Data.itemsreqfields[key].FieldId).value = data.Data.itemsreqfields[key].UnitValue;
                        }
                        else if (data.Data.itemsreqfields[key].Type === "date") {
                            document.getElementById("date" + data.Data.itemsreqfields[key].FieldId).value = data.Data.itemsreqfields[key].FieldNameValue;
                        }
                        else if (data.Data.itemsreqfields[key].Type === "dropdown") {
                            document.getElementById("select" + data.Data.itemsreqfields[key].FieldId).value = data.Data.itemsreqfields[key].FieldNameValue;
                        }
                    }
                }
                if (data.Data.itemsprerequisite !== null) {
                    for (var keyy in data.Data.itemsprerequisite) {
                        if (data.Data.itemsprerequisite[keyy].PreRequisiteDoc === "") {
                            document.getElementById("prerequisite" + data.Data.itemsprerequisite[keyy].PreRequisite).style.display = 'block';
                        }
                        else {
                            document.getElementById("prerequisite" + data.Data.itemsprerequisite[keyy].PreRequisite).style.display = 'block';
                            document.getElementById("temp" + data.Data.itemsprerequisite[keyy].PreRequisite).innerHTML = "<a href='javascript:void(0)'>Previous Document Selected</a>"
                            document.getElementById("temp" + data.Data.itemsprerequisite[keyy].PreRequisite).value = data.Data.itemsprerequisite[keyy].PreRequisiteDoc;
                            glblprerequisitestoredetails.push({
                                PreRequisite: data.Data.itemsprerequisite[keyy].PreRequisite,
                                PreRequisiteDocPath: data.Data.itemsprerequisite[keyy].PreRequisiteDoc
                            });
                        }
                    }
                }
                if (data.Data !== null) {
                    if (data.Data.Prescription === "" || data.Data.Prescription === null) {
                        document.getElementById("temp_prescription").innerHTML = ""
                        document.getElementById("temp_prescription").value = "";
                        setPrescriptionDocument("");
                    }
                    else {
                        document.getElementById("up_prescription").style.display = 'block';
                        document.getElementById("temp_prescription").innerHTML = "<a href='javascript:void(0)'>Previous Document Selected</a>"
                        document.getElementById("temp_prescription").value = data.Data.Prescription;
                        setPrescriptionDocument(data.Data.Prescription);
                    }
                }
            }
        }).catch(function (error) { if (error.response) { ErrorFunction(error.response.status); } });
    }
    const ChangeGender = async (e) => {
        if (e.target.value === "Mr.") { formik.values.gender = "1"; setDisableFields({ gender: !formik.dirty === true ? !formik.dirty : formik.dirty }) }
        else if (e.target.value === "Mrs.") { formik.values.gender = "2"; setDisableFields({ gender: !formik.dirty === true ? !formik.dirty : formik.dirty }) }
        else if (e.target.value === "Miss.") { formik.values.gender = "2"; setDisableFields({ gender: !formik.dirty === true ? !formik.dirty : formik.dirty }) }
        else if (e.target.value === "Baby.") { formik.values.gender = "2"; setDisableFields({ gender: !formik.dirty === true ? !formik.dirty : formik.dirty }) }
        else if (e.target.value === "Baba.") { formik.values.gender = "1"; setDisableFields({ gender: !formik.dirty === true ? !formik.dirty : formik.dirty }) }
        // else if (e.target.value === "Master.") { formik.values.gender = "1"; setDisableFields({ gender: !formik.dirty === true ? !formik.dirty : formik.dirty }) }
        // else if (e.target.value === "Dr.") { formik.values.gender = "1"; setDisableFields({ gender: !formik.dirty === true ? !formik.dirty : formik.dirty }) }
        // else if (e.target.value === "B/O.") { formik.values.gender = "1"; setDisableFields({ gender: !formik.dirty === true ? !formik.dirty : formik.dirty }) }
        else if (e.target.value === "Master." || e.target.value === "Dr." || e.target.value === "B/O.") {
            formik.values.gender = "";
            setDisableFields({ gender: !formik.dirty === true ? formik.dirty : !formik.dirty })
        }
        else if (e.target.value === "Ms.") { formik.values.gender = "2"; setDisableFields({ gender: !formik.dirty === true ? !formik.dirty : formik.dirty }) }
        else if (e.target.value === "C/O.") { formik.values.gender = "3"; setDisableFields({ gender: !formik.dirty === true ? !formik.dirty : formik.dirty }) }
        else if (e.target.value === "Mx.") { formik.values.gender = "3"; setDisableFields({ gender: !formik.dirty === true ? !formik.dirty : formik.dirty }) }
        setDueAmt(0);
        setTable([]);
        setEditTable([]);
        setOptions([]);
        setPaymentTable([]);
        setPaidAmt(0);
        setDiscAmt(0);
        setCredAmt(0);
        GetPaymentMode(formik.values.patienttype, "0");
    }
    const ChangeGenderDemographic = async (e) => {
        if (e.target.value === "Mr.") { editformik.values.editgender = "1"; setEditDisableFields({ editgender: !editformik.dirty === true ? !editformik.dirty : editformik.dirty }) }
        else if (e.target.value === "Mrs.") { editformik.values.editgender = "2"; setEditDisableFields({ editgender: !editformik.dirty === true ? !editformik.dirty : editformik.dirty }) }
        else if (e.target.value === "Miss.") { editformik.values.editgender = "2"; setEditDisableFields({ editgender: !editformik.dirty === true ? !editformik.dirty : editformik.dirty }) }
        else if (e.target.value === "Baby.") { editformik.values.editgender = "2"; setEditDisableFields({ editgender: !editformik.dirty === true ? !editformik.dirty : editformik.dirty }) }
        else if (e.target.value === "Baba.") { editformik.values.editgender = "1"; setEditDisableFields({ editgender: !editformik.dirty === true ? !editformik.dirty : editformik.dirty }) }
        // else if (e.target.value === "Master.") { editformik.values.editgender = "1"; setEditDisableFields({ editgender: !editformik.dirty === true ? !editformik.dirty : editformik.dirty }) }
        // else if (e.target.value === "Dr.") { editformik.values.editgender = "1"; setEditDisableFields({ editgender: !editformik.dirty === true ? !editformik.dirty : editformik.dirty }) }
        // else if (e.target.value === "B/O.") { editformik.values.editgender = "1"; setEditDisableFields({ editgender: !editformik.dirty === true ? !editformik.dirty : editformik.dirty }) }
        else if (e.target.value === "Master." || e.target.value === "Dr." || e.target.value === "B/O.") {
            editformik.values.editgender = "";
            setEditDisableFields({ editgender: !editformik.dirty === true ? editformik.dirty : !editformik.dirty })
        }
        else if (e.target.value === "Ms.") { editformik.values.editgender = "2"; setEditDisableFields({ editgender: !editformik.dirty === true ? !editformik.dirty : editformik.dirty }) }
        else if (e.target.value === "C/O.") { editformik.values.editgender = "3"; setEditDisableFields({ editgender: !editformik.dirty === true ? !editformik.dirty : editformik.dirty }) }
        else if (e.target.value === "Mx.") { editformik.values.editgender = "3"; setEditDisableFields({ editgender: !editformik.dirty === true ? !editformik.dirty : editformik.dirty }) }
    }
    const GetDoctorTitle = async () => {
        try {
            let url = FrontDesk_URL + 'master/getdoctortitlelist'
            await axios.get(url).then((response) => {
                const data = response.data;
                if (data.Success) { setDoctorTitleList(data.Data.map((d) => (<option key={d.Id} value={d.Id}>{d.Value}</option>))); }
            }).catch(function (error) { });
        }
        catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    }
    const GetSAPStates = async () => {
        try {
            let url = FrontDesk_URL + 'master/getstateslist/1'
            await axios.get(url).then((response) => {
                const data = response.data;
                if (data.Success) { setSAPStateList(data.Data.map((d) => (<option key={d.Id} value={d.Id}>{d.Value}</option>))); }
            }).catch(function (error) { });
        }
        catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    }
    const GetSAPRegion = async (StateId) => {
        try {
            let url = FrontDesk_URL + 'master/getregionslist/' + StateId
            await axios.get(url).then((response) => {
                const data = response.data;
                if (data.Success) { setSAPRegionList(data.Data.map((d) => (<option key={d.Id} value={d.Id}>{d.Value}</option>))); }
            }).catch(function (error) { });
        }
        catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    }
    const GetSAPDistrict = async (RegionId) => {
        try {
            let url = FrontDesk_URL + 'master/getdistrictslist/' + RegionId
            await axios.get(url).then((response) => {
                const data = response.data;
                if (data.Success) { setSAPDistrictList(data.Data.map((d) => (<option key={d.Id} value={d.Id}>{d.Value}</option>))); }
            }).catch(function (error) { });
        }
        catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    }
    const GetSpecialization = async () => {
        try {
            let url = FrontDesk_URL + 'master/getspecializationlist'
            await axios.get(url).then((response) => {
                const data = response.data;
                if (data.Success) { setSpecializationList(data.Data.map((d) => (<option key={d.Id} value={d.Id}>{d.Value}</option>))); }
            }).catch(function (error) { });
        }
        catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    }
    const SAPStateChangeHandler = (e) => {
        doctorformik.values.doctorsapregion = "";
        doctorformik.values.doctorsapdistrict = "";
        if (e.target.value !== "") { GetSAPRegion(e.target.value); } else { setSAPRegionList([]); }
        setSAPDistrictList([]);
    }
    const SAPRegionChangeHandler = (e) => {
        doctorformik.values.doctorsapdistrict = "";
        if (e.target.value !== "0") { GetSAPDistrict(e.target.value); } else { setSAPRegionList([]); }
    }
    const SaveReferringDoctor = async (values) => {
        //if (refdocaddname === "" || refdocaddname === undefined) { SweetAlert.fire("Please enter doctor name"); return false; } after disable doctor search in add new
        if (localStorage.CentreSNo != localcentreid) {
            SweetAlert.fire("Please refresh page because centre has been change from another tab");
            return false;
        }
        const requestOptions = {
            DoctorTitle: values.doctortitle,
            DoctorFirstName: values.docfirstname,
            DoctorMiddleName: values.docmiddlename === undefined ? "" : values.docmiddlename,
            DoctorLastName: values.doclastname === undefined ? "" : values.doclastname,
            DoctorPincode: values.doctorsappincode === undefined ? "0" : values.doctorsappincode,
            DoctorSAPState: values.doctorsapstate === undefined ? "0" : values.doctorsapstate,
            DoctorSAPRegion: values.doctorsapregion === undefined ? "0" : values.doctorsapregion,
            DoctorSAPDistrict: values.doctorsapdistrict === undefined ? "0" : values.doctorsapdistrict,
            DoctorSpecialization: values.doctorspecialization === undefined ? "0" : values.doctorspecialization,
            DoctorClinicNo: values.docclinicno === undefined ? "" : values.docclinicno,
            DoctorMobileNo: values.docmobileno === undefined ? "" : values.docmobileno,
            DoctorLandmark: values.doclandmark === undefined ? "" : values.doclandmark,
            //DoctorName: refdocaddname, after disable doctor search in add new
            //Email: values.docemail === undefined ? "" : values.docemail, after disable doctor search in add new
            CentreSNo: localStorage.CentreSNo,
            CreatedBy: localStorage.LoggedInUser,
        };
        let url = FrontDesk_URL + 'master/SaveReferringDoctor'
        await axios.post(url, requestOptions).then((response) => {
            const data = response.data;
            if (data.Success) {
                if (data.Data === "0") { SweetAlert.fire('Unable to save.Please try again') }
                else {
                    SweetAlert.fire({
                        title: 'Success!',
                        text: "Data has been saved!",
                        icon: 'success',
                        showCancelButton: false,
                        confirmButtonText: 'Ok',
                        reverseButtons: true
                    })
                        .then((result) => {
                            if (result.value) {
                                let docname = data.Data[0].DoctorName;
                                let docid = data.Data[0].Id;
                                let isnewflag = data.Data[0].IsNewFlag;
                                setNewDoctorFlag(isnewflag);
                                setRefDocSno(docid);
                                setRefDoctorName(docname);
                                setRefDocName(docname);
                                setRefDocNameVisible(true);
                                formik.values.refdoctext = docname;
                                openDoctorModal();
                            }
                            else {
                                setRefDocNameVisible(false);
                                setNewDoctorFlag("0");
                                setRefDocSno("");
                                setRefDoctorName("");
                                setRefDocName("");
                                formik.values.refdoctext = "";
                                openDoctorModal();
                            }
                        })
                }
            }
            else {
                if (data.Data === "-2001") { SweetAlert.fire('Doctor with same mobile number already exist.Please search'); }
                else if (data.Data === "-2003") { SweetAlert.fire('Doctor with same name and landmark already exist.Please change landmark'); }
                else if (data.Data === "-2004") { SweetAlert.fire('Doctor with same name already exist.Please add landmark also'); }
                else if (data.Data === "-2000") { SweetAlert.fire({ title: "DB Error", text: "Some error occured! Check db logs", icon: "error" }); }
                else { SweetAlert.fire({ title: "Application Exception", text: "Some error occured! Please try after sometime.", icon: "error" }); }
            }
        }).catch(function (error) { if (error.response) { ErrorFunction(error.response.status); } });
    }
    const SaveEditDemographicPatientDetails = async (values) => {
        if (patientsno == "" || patientsno == "0" || patientsno == null || patientsno == undefined) {
            SweetAlert.fire("You cannot edit at this moment.Please try again later.");
            return false;
        }
        if (values.editgender == "0" || values.editgender == "" || values.editgender == null || values.editgender == undefined) {
            SweetAlert.fire("Please select gender.");
            return false;
        }
        var fdob = values.editdob.split("-");
        var finaldob = fdob[2] + "-" + fdob[1] + "-" + fdob[0];
        const requestOptions = {
            Age: values.editage.toString(),
            DOB: finaldob,
            Gender: values.editgender,
            PatientMobile: values.editpatientmobile,
            PatientName: values.editpatientname,
            PatientTitle: values.edittitle,
            Year: edityear.current.value,
            Month: editmonth.current.value,
            Day: editday.current.value,
            CentreId: localcentreid,
            CreatedBy: localStorage.LoggedInUser,
            PatientSNO: patientsno
        };
        setIsButtonLoading(true);
        let url = FrontDesk_URL + 'master/SaveEditDemographicPatientDetails'
        await axios.post(url, requestOptions).then((response) => {
            const data = response.data;
            if (data.Success) {
                setIsButtonLoading(false);
                SweetAlert.fire({
                    title: 'Thank You!', text: "Demographic successfully updated.", icon: 'success', showCancelButton: false, confirmButtonText: 'Ok', reverseButtons: true
                }).then((result) => { window.location.reload(false); })
            }
            else {
                if (data.Data === "-2001") { SweetAlert.fire('Patient with same mobile number already exist.'); }
                else if (data.Data === "-2002") { SweetAlert.fire('You have not made any update.'); }
                else if (data.Data === "-1") { SweetAlert.fire('Some exception occured while processing. Check db logs!'); }
                else if (data.Data === "-2") { SweetAlert.fire('Unable to sent data to LIS/RIS due to connectivity issue.Please try again to update demographic'); }
                else if (data.Data === "-2000") { SweetAlert.fire('Unable to save data.Please try again.'); }
                else { SweetAlert.fire({ title: "Check server logs!", text: "No response received", icon: "error" }); }
                setIsButtonLoading(false);
            }
        }).catch(function (error) { if (error.response) { setIsButtonLoading(false); ErrorFunction(error.response.status); } });
    }
    const GetStates = async (id) => {
        try {
            let url = FrontDesk_URL + 'master/StateList'
            await axios.get(url).then((response) => {
                const data = response.data;
                if (data.Success) { setStatesList(data.Data.map((d) => (<option key={d.SNo} value={d.SNo}>{d.StateName}</option>))); }
            }).catch(function (error) { });
        }
        catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    }
    const GetDistricts = async (id) => {
        try {
            let url = FrontDesk_URL + 'master/getdistricts/' + id;
            await axios.get(url).then((response) => {
                const data = response.data;
                if (data.Success) { setDistrictsList(data.Data.map((d) => (<option key={d.Id} value={d.Id}>{d.Value}</option>))); }
            }).catch(function (error) { });
        }
        catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    }
    const StateChangeHandler = (e) => {
        if (e.target.value !== "0") { GetDistricts(e.target.value); } else { setDistrictsList([]); }
        setDistrictsList([]);
    }
    const HoldOrder = async (values) => {
        if (values.patientmobile.toString().charAt(0) == "0") {
            SweetAlert.fire("Starting character of patient mobile cannot be zero");
            return false;
        }
        if (localStorage.CentreSNo != localcentreid) {
            SweetAlert.fire("Please refresh page because centre has been change from another tab");
            return false;
        }
        if (values.gender == "0" || values.gender == "" || values.gender == null || values.gender == undefined) {
            SweetAlert.fire('Please select gender.')
            return false;
        }
        if ((values.viasource == "" || values.viasource == null || values.viasource == undefined) && (PartnerTypeIdSetting != "3" || PartnerTypeIdSetting != "4")) {
            SweetAlert.fire('Please enter source.')
            return false;
        }
        var opdID = "";
        var hospitalUHID = "";
        var visitid = "0";
        var saveaddmore = "0";
        if (visitingid == "" || visitingid == undefined || visitingid == null || visitingid == "0") { visitid = "0"; }
        else {
            visitid = visitingid;
            if (storeitem.length === 0 || storeitem === null || storeitem == []) {
                SweetAlert.fire('You cannot hold, if there are no items added while editing order.');
                return false;
            }
        }
        if (alreadyaddedmore == "" || alreadyaddedmore == undefined || alreadyaddedmore == null || alreadyaddedmore == "0") { saveaddmore = "0"; } else { saveaddmore = alreadyaddedmore; }
        if (values.patienttype == "8" || values.patienttype == "9") { }
        else { values.globaldocument = "0"; }
        if (values.age > 150) { SweetAlert.fire("Age cannot be greater than 150 years"); return false; }
        setIsButtonLoading(true);
        const requestOptions = {
            AlreadyAdded: saveaddmore,
            VisitingId: visitid.toString(),
            Address: values.address === undefined ? "" : values.address,
            AadharNo: values.aadhar === undefined ? "" : values.aadhar,
            PatientName: values.patientname,
            DOB: values.dob.split('-')[2] + '-' + values.dob.split('-')[1] + '-' + values.dob.split('-')[0],//values.dob.replaceAll("-", "/"),
            Age: values.age.toString(),
            Gender: values.gender,
            RefreingDr: refdocsno,
            OtherDr: values.otherdoc == undefined || values.otherdoc == null ? "" : values.otherdoc,
            EmailID: values.emailid === undefined ? "" : values.emailid,
            MobileNo: values.patientmobile.toString(),
            HouseNo: "",//(values.houseno === undefined || values.houseno === null) ? "" : values.houseno,
            PinCode: values.pincode.toString() === undefined ? "" : values.pincode.toString(),
            PatientImage: imagename,
            PatientTypeSNo: values.patienttype,
            Source: (PartnerTypeIdSetting == "3" || PartnerTypeIdSetting == "4") ? "18" : values.viasource === undefined ? "" : values.viasource,
            DesipatchMode: values.dispatchmode === undefined ? "" : values.dispatchmode === null ? "" : values.dispatchmode,
            Remarks: values.remarks === undefined ? "" : values.remarks === null ? "" : values.remarks,
            RateType: values.ratetype,
            MLC: checkbox.toString(),
            DocList: JSON.stringify(storedocumentitem),
            ItemDetailsList: JSON.stringify(storeitem),
            PaymentDetailsList: JSON.stringify(paymenttable),
            RequiredFieldsList: JSON.stringify(glblreqfieldstoredetails),
            PreRequisiteDocument: JSON.stringify(glblprerequisitestoredetails),
            TotalAmount: totalamt.toString(),
            PaidAmount: paidamt.toString(),
            DueAmount: dueamt.toString(),
            DiscAmount: discamt == null || discamt == undefined ? discamt.toString() : discamt.toString(),
            CredAmount: credamt == null || credamt == undefined ? credamt.toString() : credamt.toString(),
            DiscountAmount: values.totaldiscamt.toString(),
            DiscountReason: values.discountreason,
            selectPatientTypeSNo: values.globaldocument === undefined || values.globaldocument === null ? "0" : values.globaldocument,
            OPDID: opdID,
            HospitalUHID: hospitalUHID,
            CentreSNo: localStorage.CentreSNo,
            CreatedBy: localStorage.LoggedInUser,
            Title: values.title === "" ? "Mr." : values.title,
            VisitType: values.visittype === "" ? "CentreVisit" : values.visittype,
            IsNewDoctor: newdoctorflag,
            SAPStateId: values.locStateId,
            SAPRegionId: "0",
            SAPDistrictId: values.locDistrictId,
            RegNo: patientsno,
            IsHold: "1",
            OtherLabRefNo: values.otherLabRefNo,
            PRO: proSelected.value == null || proSelected.value == undefined || proSelected.value == [] ? "0" : proSelected.value,//provalues.PRO,
            Year: year.current.value,
            Month: month.current.value,
            Day: day.current.value,
            PrescriptionDocument: prescriptiondocument,
            POSMachineId: posmachineid,
            POSMachineName: posmachinename,
            PaymentChannelId: paychannelid,
            IsItemLevelDiscount: "0",
            PartnerTypeId: PartnerTypeIdSetting,
            IsHLMParty: hlmparty,
            OtherReferralLab: values.otherreflab == undefined || values.otherreflab == null ? "" : values.otherreflab,
            RefId: glblrefno
        };
        let url = FrontDesk_URL + 'master/addPatientRegistration'
        await axios.post(url, requestOptions).then((response) => {
            setIsButtonLoading(false);
            const data = response.data;
            if (data.Success) {
                SweetAlert.fire({
                    title: 'Thank You!', text: "Patient is on hold .", icon: 'success', showCancelButton: false, confirmButtonText: 'Ok', reverseButtons: true
                }).then((result) => { window.location.reload(false); })
            }
            else {
                if (data.ErrorList[0].errorCode == -3000) { SweetAlert.fire('Insufficient cash.') }
                else if (data.ErrorList[0].errorCode == -3001) { SweetAlert.fire('Credit limit not allowed.') }
                else if (data.ErrorList[0].errorCode == -3002) { SweetAlert.fire('Credit limit amount should be equal to total amount.') }
                else if (data.ErrorList[0].errorCode == -3003) { SweetAlert.fire('Machine is not available for selected slot.') }
                else if (data.ErrorList[0].errorCode == -3010) { SweetAlert.fire('Patient with same mobile number already exists') }
                else if (data.ErrorList[0].errorCode == -1) { SweetAlert.fire('You cannot edit this item because invoice has already generated') }
                else if (data.Data == -3005) { SweetAlert.fire('Item already added in package.') }
                else if (data.ErrorList[0].errorCode == -9997) { SweetAlert.fire('Amount does not match, this is due to parallel edit of amount by another user. Please check edit again.') }
                else if (data.ErrorList[0].errorCode == -2000 || data.Data == -2000) { SweetAlert.fire({ title: "API Runtime Exception", text: "Some error occured! Please try after sometime.", icon: "error" }) }
                else if (data.ErrorList[0].errorCode == -2005) { SweetAlert.fire('You can make refund only in cash.') }
                else if (data.ErrorList[0].errorCode == -3004) { SweetAlert.fire('Age cannot be greater than 150 years') }
                else if (data.ErrorList[0].errorCode == -3012) { SweetAlert.fire('Please enter discount entry also in grid because you had entered line item level discount') }
                else if (data.ErrorList[0].errorCode == -3013) { SweetAlert.fire('Discount amount cannot be greater than rate') }
                else if (data.ErrorList[0].errorCode == -3021) { SweetAlert.fire('Insufficient credit limit.Please contact parent company') }
                else { SweetAlert.fire({ title: "Application Exception", text: "Some error occured! Please try after sometime.", icon: "error" }) }
            }
        }).catch(function (error) {
            setIsButtonLoading(false);
            if (error.response) { ErrorFunction(error.response.status); }
        });
    }
    const PusherHandler = async (callFrom) => {
        var jsnMsg = "";
        if (callFrom === "1") {
            const currentValues = {
                MobileNo: formik.values.patientmobile == undefined ? "" : formik.values.patientmobile.toString(),
                Title: formik.values.title === "" ? "Mr." : formik.values.title,
                PatientName: formik.values.patientname,
                Gender: formik.values.gender,
                DOB: (formik.values.dob == undefined || formik.values.dob == "" || formik.values.dob == null) ? "" : formik.values.dob.split('-')[2] + '-' + formik.values.dob.split('-')[1] + '-' + formik.values.dob.split('-')[0],
                Age: formik.values.age == undefined ? "0" : formik.values.age.toString(),
                PinCode: formik.values.pincode === undefined ? "" : formik.values.pincode.toString(),
                StateId: formik.values.locStateId,
                DistrictId: formik.values.locDistrictId,
                //HouseNo: formik.values.houseno === undefined ? "" : formik.values.houseno,
                Address: formik.values.address === undefined ? "" : formik.values.address,
                RefreingDr: refDoctorName,
                OtherDr: formik.values.otherdoc,
                EmailID: formik.values.emailid === undefined ? "" : formik.values.emailid,
                CentreId: localStorage.CentreSNo,
                UserId: localStorage.LoggedInUser,
                IsClear: "NO",
                ItemDetails: JSON.stringify(table),
                TotalAmount: totalamt,
                Year: (year.current.value == "" || year.current.value == null || year.current.value == undefined) ? "0" : year.current.value,
                Month: (month.current.value == "" || month.current.value == null || month.current.value == undefined) ? "0" : month.current.value,
                Day: (day.current.value == "" || day.current.value == null || day.current.value == undefined) ? "0" : day.current.value,
            };
            jsnMsg = JSON.stringify(currentValues);
        }
        else {
            const currentValues = {
                CentreId: localStorage.CentreSNo,
                UserId: localStorage.LoggedInUser,
                IsClear: "YES"
            };
            jsnMsg = JSON.stringify(currentValues);
        }
        var requestOptions = { MsgInfo: jsnMsg }
        let url = FrontDesk_URL + 'master/pushnotification'
        await axios.post(url, requestOptions).then((response) => {
            const data = response.data;
            if (data.Success === false) { SweetAlert.fire({ title: "Application Exception", text: "Some error occured! Please try after sometime.", icon: "error" }); }
        }).catch(function (error) { if (error.response) { ErrorFunction(error.response.status); } });
    }
    const handleOnChange = (position) => {
        const updatedCheckedState = checkedState.map((item, index) => index === position ? !item : item);
        setCheckedState(updatedCheckedState);
        var counter = 0;
        for (var i in glblCheckboxList) {
            if (counter === position) { if (glblCheckboxList[i].Checked === false) { glblCheckboxList[i].Checked = true; } }
            counter = counter + 1;
        }
    };
    const handlePercentageCheckbox = (e) => {
        const checkedflg = e.target.checked;
        if (checkedflg) { setPercentagecheckbox("1"); } else { setPercentagecheckbox("0"); }
    };
    const GetSettings = async (cId, uId) => {
        setIsPageLoading(true);
        try {
            let url = FrontDesk_URL + 'master/getcentresettings/' + cId + '/' + uId;
            await axios.get(url).then((response) => {
                const data = response.data;
                if (data.Success) {
                    const settingInfo = data.Data;
                    let hideBookingAmount = false; if (settingInfo.HideBookingAmount === "True") { hideBookingAmount = true; }
                    let showBalanceAmount = false; if (settingInfo.ShowBalanceAmount === "True") { showBalanceAmount = true; }
                    let hideReceiptRate = false; if (settingInfo.HideReceiptRate === "True") { hideReceiptRate = true; }
                    let batchRequired = false; if (settingInfo.BatchRequired === "True") { batchRequired = true; }
                    let showMRPBill = false; if (settingInfo.ShowMRPBill === "True") { showMRPBill = true; }
                    let printDepartmentSlip = false; if (settingInfo.PrintDepartmentSlip === "True") { printDepartmentSlip = true; }
                    let showPROInRegistration = false; if (settingInfo.ShowPROInRegistration === "True") { showPROInRegistration = true; }
                    let showCentreDetailOnReport = false; if (settingInfo.ShowCentreDetailOnReport === "True") { showCentreDetailOnReport = true; }
                    let hideFooterOnWithoutLetterheadReport = false; if (settingInfo.HideFooterOnWithoutLetterheadReport === "True") { hideFooterOnWithoutLetterheadReport = true; }
                    let otherLabRefNo = false; if (settingInfo.OtherLabRefNo === "True") { otherLabRefNo = true; }
                    let allowRegistration = false; if (settingInfo.AllowRegistration === "True") { allowRegistration = true; }
                    let IsCAdmin = false; if (settingInfo.IsCentreAdmin.toLowerCase() == "true" || settingInfo.IsCentreAdmin == "1") { IsCAdmin = true; }
                    setPartnerTypePincodeSetting(settingInfo.Pincode);
                    setPartnerTypeCentreAddressSetting(settingInfo.CentreAddress);
                    setPartnerTypeSAPStateIdSetting(settingInfo.SAPStateId);
                    setPartnerTypeSAPRegionIdSetting(settingInfo.SAPRegionId);
                    setPartnerTypeSAPDistrictIdSetting(settingInfo.SAPDistrictId);
                    setPartnerTypeEmailIdSetting(settingInfo.EmailId);
                    setPartnerTypeContactNoSetting(settingInfo.ContactNo);
                    setHLMParty(settingInfo.IsHLMParty);
                    setPartnerTypeIdSetting(settingInfo.PartnerTypeId);
                    setPendingTime(settingInfo.PendingTime);
                    setHideAmountInBookingSetting(hideBookingAmount);
                    setShowBalanceAmountSetting(showBalanceAmount);
                    setHideReceiptRateSetting(hideReceiptRate);
                    setShowMRPBillSetting(showMRPBill);
                    setIsCentreAdmin(IsCAdmin);
                    setPrintDepartmentSlipSetting(printDepartmentSlip);
                    setShowPROInRegistrationSetting(showPROInRegistration);
                    setHideFooterOnWithoutLetterheadReportSetting(hideFooterOnWithoutLetterheadReport);
                    setOtherLabRefNoSetting(otherLabRefNo);
                    setCreditLimitSetting(settingInfo.CreditLimit === "" ? 0 : settingInfo.CreditLimit);
                    setBookingLimitSetting(settingInfo.BookingLimit === "" ? 0 : settingInfo.BookingLimit);
                    setCentreTypeIdSetting(settingInfo.CentreTypeId);
                    setCentreDiscountLimit(settingInfo.CentreDiscountLimit === "" ? 0 : settingInfo.CentreDiscountLimit);
                    paymentModeIdSetting = settingInfo.PaymentModeId;
                    if (parseFloat("-" + settingInfo.BookingLimit) > parseFloat(settingInfo.CreditLimit)) { setDisableBookings(true); } else { setDisableBookings(false); }
                    localStorage.setItem('MinBookingCash', settingInfo.MinBookingCash);
                    localStorage.setItem('CreditLimit', settingInfo.CreditLimit);
                    GetPaymentMode(formik.values.patienttype, "0");
                    //setAllowRegistrationSetting(true);
                    setAllowRegistrationSetting(allowRegistration);
                    setShowPROInRegistrationSetting(showPROInRegistration);
                    setUserCustomerCode(settingInfo.UserCustomerCode);
                    setUserCashCode(settingInfo.UserCashCode);
                    setUserVendorCode(settingInfo.UserVendorCode);
                    setUserPOSCode(settingInfo.UserPOSCode);
                    setParentCentreId(settingInfo.ParentCentreId);
                    pcid = settingInfo.ParentCentreId;
                    if (settingInfo.PartnerTypeId == "3" || settingInfo.PartnerTypeId == "4") {
                        GetDistricts((settingInfo.SAPStateId == "" || settingInfo.SAPStateId == null || settingInfo.SAPStateId == undefined) ? "" : settingInfo.SAPStateId);
                        formik.values.patientmobile = settingInfo.ContactNo;
                        formik.values.pincode = settingInfo.Pincode;
                        formik.values.address = settingInfo.CentreAddress;
                        formik.values.emailid = settingInfo.EmailId;
                        formik.values.locStateId = (settingInfo.SAPStateId == "" || settingInfo.SAPStateId == null || settingInfo.SAPStateId == undefined) ? "" : settingInfo.SAPStateId;
                        formik.values.locDistrictId = (settingInfo.SAPDistrictId == "" || settingInfo.SAPDistrictId == null || settingInfo.SAPDistrictId == undefined) ? "" : settingInfo.SAPDistrictId;
                    }
                    GetPatientType();
                }
                setIsPageLoading(false);
            }).catch(function (error) { setIsLoading(false); setIsPageLoading(false); });
        }
        catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); setIsLoading(false); }
    }
    const SetAuthorizerDetails = (e) => {
        if (paymentModeId === "14") {
            for (var key in glblDiscountAuthorizer) {
                if (glblDiscountAuthorizer[key].Id === e.target.value) {
                    setDiscountAuthorizerDetails({
                        "DiscountAuthorizerName": glblDiscountAuthorizer[key].Name.split("[")[0],
                        "DiscountAuthorizerEmail": glblDiscountAuthorizer[key].EmailID
                    });
                    return false;
                }
            }
        }
        if (paymentModeId === "15") {
            for (var key in glblCreditAuthorizer) {
                if (glblCreditAuthorizer[key].Id === e.target.value) {
                    setCreditAuthorizerDetails({
                        "CreditAuthorizerName": glblCreditAuthorizer[key].Name.split("[")[0],
                        "CreditAuthorizerEmail": glblCreditAuthorizer[key].EmailID
                    });
                    return false;
                }
            }
        }
    }
    const CheckIfPatientExist = async (e) => {
        if (formik.values.patientmobile == "" || formik.values.patientmobile == null || formik.values.patientmobile == undefined) {
            SweetAlert.fire("Please enter mobile no before name");
            formik.values.patientname = "";
            return false;
        }
        if (formik.values.patientmobile !== "" && e.target.value !== "" && (PartnerTypeIdSetting != "3" && PartnerTypeIdSetting != "4")) {
            var requestOptions = { MobileNo: formik.values.patientmobile, PatinetName: e.target.value }
            let url = FrontDesk_URL + 'master/checkpatientexist'
            await axios.post(url, requestOptions).then((response) => {
                const data = response.data;
                if (data.Success === true) {
                    if (data.Data === "1") {
                        SweetAlert.fire({
                            title: 'Patient Validation',
                            text: "Patient with mobile no. '" + formik.values.patientmobile + "' already exist with us, please search patient instead of adding new one.",
                            icon: 'warning',
                            showCancelButton: false, confirmButtonText: 'Refresh'
                        }).then((result) => { window.location.reload(false) });
                    }
                }
            }).catch(function (error) { if (error.response) { ErrorFunction(error.response.status); } });
        }
    }
    const CallValidationCheck = async (evt) => {
        if (evt.which != 8 && evt.which != 0 && evt.which < 48 || evt.which > 57) { evt.preventDefault(); }
    }
    const CallAllFunctions = async (evt) => {
        // CallAge(day.current.value, month.current.value, year.current.value);
        if (day.current.value == "" && month.current.value == "" && year.current.value == "") {
            formik.values.dob = "";
            formik.values.age = "";
            setOptions([]);
            setTable([]);
            setDobText(formik.dirty == true ? !formik.dirty : formik.dirty);
        }
        else {
            getBirthDateFromAge(day.current.value, month.current.value, year.current.value);
        }
    }
    const CallEditAllFunctions = async (evt) => {
        if (editday.current.value == "" && editmonth.current.value == "" && edityear.current.value == "") {
            editformik.values.editdob = "";
            editformik.values.editage = "";
            setEditDobText(editformik.dirty == true ? !editformik.dirty : editformik.dirty);
        }
        else {
            getEditBirthDateFromAge(editday.current.value, editmonth.current.value, edityear.current.value);
        }
    }
    const CalculateAge = () => {
        formik.values.age = year.current.value;
    }
    const CalculateEditAge = () => {
        editformik.values.editage = edityear.current.value;
    }
    const ConfirmCancelOrder = async () => {
        const tmpVisitId = visitingid;
        SweetAlert.fire({
            title: 'You are going to cancel an order. Are you sure?',
            text: "Once deleted, you will not be able to recover!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Ok',
            cancelButtonText: 'Cancel',
            reverseButtons: true
        }).then((result) => { if (result.value) { CancelOrder(tmpVisitId); } else { SweetAlert.fire('Your order is safe!') } })
    }
    const CancelOrder = async (VisitingId) => {
        var requestOptions = { VisitingId: VisitingId, CentreId: localStorage.CentreSNo, UserId: localStorage.LoggedInUser, }
        let url = FrontDesk_URL + 'master/cancelorder'
        await axios.post(url, requestOptions).then((response) => {
            const data = response.data;
            if (data.Success === true) {
                if (data.Data === "2000") {
                    SweetAlert.fire({
                        title: 'Order Cancelled',
                        text: "Your order cancelled successfully",
                        icon: 'success',
                        showCancelButton: false,
                        confirmButtonText: 'Ok',
                        reverseButtons: true
                    }).then((result) => { if (result.value) { window.location.reload(false); } else { window.location.reload(false); } })
                }
                else { SweetAlert.fire({ title: "Order Not Cancelled", text: "You cannot cancel order because it has radiology tests also.", icon: "warning" }); }
            }
            else {
                if (data.Data === "-1998") { SweetAlert.fire({ title: "API Runtime Exception", text: "Some value is missing", icon: "error" }); }
                else { SweetAlert.fire({ title: "API Runtime Exception", text: "Check logs", icon: "error" }); }
            }
        }).catch(function (error) { if (error.response) { ErrorFunction(error.response.status); } });
    }
    const SelectChannel = async (e) => {
        setChannelId(e.target.value);
    }
    const generatepinelabtransactionid = async (amountvalue) => {
        const requestOptions = {
            MobileNo: formik.values.patientmobile, CentreID: localStorage.CentreSNo, Amount: amountvalue, AddedBy: localStorage.LoggedInUser
        };
        setIsLoading(true);
        let url = PineLabs_URL + 'PaymentPineLabs/SetPineLabsTransaction'
        await axios.post(url, requestOptions).then((response) => {
            const data = response.data;
            setIsLoading(false);
            if (data.Success) {
                var transactionnumber = data.Data;
                uploadtransaction((amountvalue * 100), transactionnumber);
            }
            else { SweetAlert.fire('TransactionId not created, Please try again later.'); }
        }).catch(function (error) {
            setIsLoading(false);
            if (error.response) { ErrorFunction(error.response.status); }
        });
    }
    const uploadtransaction = async (amountvalue, transactionnumber) => {
        var arr = [];
        arr = glblposmachine.filter((d) => d.SNo === posMachineId.current.value);
        const requestOptions = {
            TransactionNumber: transactionnumber, SequenceNumber: "1", AllowedPaymentMode: "0", Amount: amountvalue, AutoCancelDurationInMinutes: "5", UserID: localStorage.LoggedInUser,
            MerchantStorePosCode: arr[0].MerchantStorePosCode, IMEI: arr[0].IMEI
        };
        setIsLoading(true);
        let url = PineLabs_URL + 'PaymentPineLabs/uploadtransaction'
        await axios.post(url, requestOptions).then((response) => {
            const data = response.data;
            setIsLoading(false);
            if (data.Success) {
                if (data.Data === null) {
                    setPlutusTransRefId("0");
                    setTransactionNo("0");
                    setMerchantStorePostCode("");
                    setIMEI("");
                    setHideShowPaymentButton(false);
                    SweetAlert.fire('Runtime error occured, Please try again or contact support team.');
                }
                else {
                    if (data.Data.ResponseCode == "0" || data.Data.ResponseCode == "False") {
                        setPlutusTransRefId(data.Data.PlutusTransactionReferenceID);
                        setTransactionNo(transactionnumber);
                        setMerchantStorePostCode(arr[0].MerchantStorePosCode);
                        setIMEI(arr[0].IMEI);
                        SweetAlert.fire({
                            title: 'Check POS Machine',
                            text: "Payment sent to POS machine",
                            icon: 'success',
                            showCancelButton: false,
                            confirmButtonText: 'Ok',
                            reverseButtons: true
                        }).then((result) => { if (result.value) { setHideShowPaymentButton(true); } else { setHideShowPaymentButton(true); } })

                    }
                    else {
                        setPlutusTransRefId("0");
                        setTransactionNo("0");
                        setMerchantStorePostCode("");
                        setIMEI("");
                        setHideShowPaymentButton(false);
                        SweetAlert.fire(data.Data.ResponseMessage);
                    }
                }
            }
            else {
                setPlutusTransRefId("0");
                setTransactionNo("0");
                setMerchantStorePostCode("");
                setIMEI("");
                setHideShowPaymentButton(false);
                SweetAlert.fire('Transaction failed while pushing to POS.Please try again');
            }
        }).catch(function (error) {
            setIsLoading(false);
            if (error.response) { ErrorFunction(error.response.status); }
        });
    }
    const CheckPaymentStatus = async () => {
        const requestOptions = {
            PlutusTransactionReferenceID: plutustransrefid, TransactionNumber: transactionno, MerchantStorePosCode: merchantstorepostcode, IMEI: imei
        };
        setIsLoading(true);
        let url = PineLabs_URL + 'PaymentPineLabs/TransactionStatus'
        await axios.post(url, requestOptions).then((response) => {
            const data = response.data;
            setIsLoading(false);
            if (data.Success) {
                if (data.Data === null) {
                    paymentmodedetail.current.value = "0";
                    setChannelId("0");
                    setPlutusTransRefId("0");
                    setTransactionNo("0");
                    setMerchantStorePostCode("");
                    setIMEI("");
                    setHideShowPaymentButton(false);
                    SweetAlert.fire('Runtime error occured, Please try again or contact support team.');
                }
                else {
                    if (data.Data.ResponseCode == "0" || data.Data.ResponseCode == "False") {
                        SweetAlert.fire({
                            title: 'Check POS Machine',
                            text: "Payment success from POS machine",
                            icon: 'success',
                            showCancelButton: false,
                            confirmButtonText: 'Ok',
                            reverseButtons: true
                        }).then((result) => {
                            if (result.value) {
                                setHideShowPaymentButton(false);
                                var isBreak = "0";
                                for (var key in data.Data.TransactionData) {
                                    if (data.Data.TransactionData[key].Tag == "TransactionLogId" && isBreak == "0") {
                                        isBreak = "1";
                                        RecallPaymentGrid(data.Data.TransactionData[key].Value);
                                    }
                                }
                            } else {
                                setHideShowPaymentButton(false);
                                var isBreak = "0";
                                for (var key in data.Data.TransactionData) {
                                    if (data.Data.TransactionData[key].Tag == "TransactionLogId" && isBreak == "0") {
                                        isBreak = "1";
                                        RecallPaymentGrid(data.Data.TransactionData[key].Value);
                                    }
                                }
                            }
                        })
                    }
                    else {
                        // setPlutusTransRefId("0");
                        // setTransactionNo("0");
                        // setMerchantStorePostCode("");
                        // setIMEI("");
                        SweetAlert.fire(data.Data.ResponseMessage);
                    }
                }
            }
            else {
                paymentmodedetail.current.value = "0";
                setChannelId("0");
                setPlutusTransRefId("0");
                setTransactionNo("0");
                setMerchantStorePostCode("");
                setIMEI("");
                setHideShowPaymentButton(false);
                SweetAlert.fire('Transaction failed while receiving response from POS.Please try again');
            }
        }).catch(function (error) {
            setIsLoading(false);
            if (error.response) { ErrorFunction(error.response.status); }
        });
    }
    const RecallPaymentGrid = async (posrefnumber) => {
        var _authorizerId = ""; if (authorizerId.current !== null && authorizerId.current !== undefined) { _authorizerId = authorizerId.current.value; }
        var _authorizer = ""; if (authorizerId.current !== null && authorizerId.current !== undefined) { _authorizer = authorizerId.current.options[authorizerId.current.selectedIndex].text; }
        var _concessionReason = ""; if (concessionReason.current !== null && concessionReason.current !== undefined) { _concessionReason = concessionReason.current.value; }
        var pChkbx = "";
        if (Percentagecheckbox === "1") { pChkbx = parseFloat(parseFloat(totalamt) * (parseFloat(paymentamountdetail.current.value) / 100)).toFixed(2); }
        else { pChkbx = paymentamountdetail.current.value; }
        pChkbx = Math.round(pChkbx);
        let intCountr = 0;
        for (var x in glblPaymentsList) { intCountr = parseInt(glblPaymentsList[x].Id) + 1; }
        var txtPayMode = paymentmodedetail.current.options[paymentmodedetail.current.selectedIndex].text;
        var pymntChnlId = "0";
        if (paymentChannelId.current != undefined) { pymntChnlId = paymentChannelId.current.value; }
        var posmchnId = "0";
        if (posMachineId.current != undefined) { posmchnId = posMachineId.current.value; }
        if (paymentmodedetail.current.value === "13" && channelid === "-1" && posMachineId.current.value !== "0") {
            var POSMachineName = posMachineId.current.options[posMachineId.current.selectedIndex].text;
        }
        glblPaymentsList.push(
            {
                Id: intCountr, OrderDetailsId: "0", PaymentMode: txtPayMode, PaymentModeId: paymentmodedetail.current.value,
                PaymentChannelId: pymntChnlId, PaidAmount: pChkbx.toString(), ReferenceNo: posrefnumber, AuthorizerId: _authorizerId,
                Authorizer: _authorizer, ConcessionReason: _concessionReason, Status: "0", DiscountRejectReason: "", POSMachineName: POSMachineName, POSMachineId: posmchnId
            }
        );
        //////////////////////////////////
        var totalPaidSoFar = 0;
        var totalDiscSoFar = 0;
        var totalCredSoFar = 0;
        setPaidAmt(0);
        setDiscAmt(0);
        setCredAmt(0);
        for (var x in glblPaymentsList) {
            if (glblPaymentsList[x].PaymentModeId === "1" || glblPaymentsList[x].PaymentModeId === "13") {
                totalPaidSoFar = totalPaidSoFar + parseFloat(glblPaymentsList[x].PaidAmount);
            }
            if (glblPaymentsList[x].PaymentModeId === "14") {
                totalDiscSoFar = totalDiscSoFar + parseFloat(glblPaymentsList[x].PaidAmount);
            }
            if (glblPaymentsList[x].PaymentModeId === "15" || glblPaymentsList[x].PaymentModeId === "2") {
                totalCredSoFar = totalCredSoFar + parseFloat(glblPaymentsList[x].PaidAmount);
            }
        }
        setPaidAmt(totalPaidSoFar);
        setDiscAmt(totalDiscSoFar);
        setCredAmt(totalCredSoFar);
        //////////////////////////////////
        var _visitId = "0";
        if (visitingid == "" || visitingid == undefined || visitingid == null || visitingid == "0") { _visitId = "0"; } else { _visitId = visitingid; }
        if (_visitId === "0") {
            var tmpArray = [];
            for (var i in glblPaymentModesList) {
                var payModeExist = false;
                for (var j in glblPaymentsList) { if (glblPaymentsList[j].PaymentModeId == glblPaymentModesList[i].SNo) { payModeExist = true; break; } }
                if (payModeExist == false) {
                    tmpArray.push(<option value={glblPaymentModesList[i].SNo}>{glblPaymentModesList[i].PaymentMode}</option>);
                }
            }
            setPaymentMode([]);
            setPaymentMode(tmpArray);
        }
        setPaymentTable(glblPaymentsList);
        paymentamountdetail.current.value = "";
        if (paymentReferenceNo.current !== undefined && paymentReferenceNo.current !== null) { paymentReferenceNo.current.value = ""; }
        if (paymentmodedetail.current.value == "13") {
            setPayChannelId(paymentChannelId.current.value);
            if (channelid === "-1") {
                setPosMachineId(posMachineId.current.value);
                setPosMachineName(posMachineId.current.options[posMachineId.current.selectedIndex].text);
            }
        }
        paymentmodedetail.current.value = "0";
        setChannelId("0");
        ChangePaymentMode("0");
    }
    const PROChangeHandler = (options) => {
        // var isBreak = "0";
        // for (var key in options) {
        //     if (options[key].value === "0" && isBreak === "0") {
        //         isBreak = "1";
        //     }
        // }
        // if (isBreak === "1") {
        //     setproSelected(PROName);
        // } else {
        //     setproSelected(options);
        // }
        setproSelected(options);
    }
    const GetPincodeSAPLocations = async (e) => {
        let text = e.target.value;
        let length = text.length;
        if (length === 6) {
            try {
                let url = FrontDesk_URL + 'master/getpincodesaplocations/' + e.target.value
                await axios.get(url).then((response) => {
                    const data = response.data;
                    if (data.Success) {
                        if (data != null) {
                            const pinCodeData = data.Data;
                            doctorformik.values.doctorsapstate = pinCodeData[0].SAPStateId;
                            GetSAPRegion(doctorformik.values.doctorsapstate);
                            doctorformik.values.doctorsapregion = pinCodeData[0].SAPRegionId;
                            GetSAPDistrict(doctorformik.values.doctorsapregion);
                            doctorformik.values.doctorsapdistrict = pinCodeData[0].SAPDistrictId;
                        }
                        else {
                            doctorformik.values.doctorsapstate = '';
                            doctorformik.values.doctorsapregion = '';
                            doctorformik.values.doctorsapdistrict = '';
                        }
                    }
                    else {
                        doctorformik.values.doctorsapstate = '';
                        doctorformik.values.doctorsapregion = '';
                        doctorformik.values.doctorsapdistrict = '';
                    }
                }).catch(function (error) { if (error.response) { ErrorFunction(error.response.status); } });
            }
            catch (error) {
                SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" });
            }
        }
        else {
            doctorformik.values.doctorsapstate = '';
            doctorformik.values.doctorsapregion = '';
            doctorformik.values.doctorsapdistrict = '';
            setSAPRegionList([]);
            setSAPDistrictList([]);
        }
    }
    const ViewAllRemarks = async (e) => {
        var visitid = (visitingid == null || visitingid == undefined || visitingid == "" ? "0" : visitingid)
        try {
            let url = FrontDesk_URL + 'master/viewremarks/' + visitid + '/' + localStorage.CentreSNo + '/' + localStorage.LoggedInUser
            await axios.get(url).then((response) => {
                const data = response.data;
                if (data.Success) {
                    if (data.Data != null) { setViewRemarks(data.Data); }
                    else { setViewRemarks([]); SweetAlert.fire("No record found"); }
                } else { setViewRemarks([]); }
            }).catch(function (error) { if (error.response) { setViewRemarks([]); ErrorFunction(error.response.status); } });
        }
        catch (error) { setViewRemarks([]); SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    }
    const openRemarksModal = async () => {
        setRemarksModal(!remarksModal);
        ViewAllRemarks();
    }
    const CheckExistingRazorPayment = async () => {
        if (formik.values.patientmobile === "" || formik.values.patientmobile === null || formik.values.patientmobile === undefined) {
            SweetAlert.fire("Patient mobile is mandatory");
            return false;
        }
        setIsLoading(true);
        let url = FrontDesk_URL + 'accounts/checkexistingrazorpayment/' + formik.values.patientmobile
        await axios.get(url).then((response) => {
            const data = response.data;
            setIsLoading(false);
            if (data.Success) {
                if (data.Data == null) { SweetAlert.fire("No record found"); }
                else { setGlblRefNo(data.Data.ReferenceNo); CheckPaymentStatusRazorRecall(data.Data.ReferenceNo, data.Data.Amount); }
            }
            else {
                if (data.Data != null) { SweetAlert.fire("Error is: " + data.Data); }
                else { SweetAlert.fire("Some error occured.Check db logs"); }
            }
        }).catch(function (error) {
            setIsLoading(false);
            if (error.response) { ErrorFunction(error.response.status); }
        });
    }
    const CheckPaymentStatusRazorRecall = async (refno, amount) => {
        if (refno == "") {
            SweetAlert.fire("Reference no is empty to check status.Please try again");
            return false;
        }
        setIsLoading(true);
        let url = FrontDesk_URL + 'accounts/getphlebopaymentandupdatestatus/' + refno
        await axios.get(url).then((response) => {
            const data = response.data;
            setIsLoading(false);
            if (data.Success) {
                if (data.Data == "-1001") { SweetAlert.fire("Currently don't have permission to access this feature. Please contact your admin."); }
                else {
                    if (data.Data == "-2") { SweetAlert.fire("Payment cancelled.Please try again."); }
                    else if (data.Data == "-3") { SweetAlert.fire("Payment expired.Please try again."); }
                    else if (data.Data == "0") { SweetAlert.fire("Payment still pending."); }
                    else if (data.Data == "-2000") { SweetAlert.fire("Some error occured.Check db logs"); }
                    else {
                        const referenceno = data.Data
                        paymentReferenceNo.current.value = referenceno;
                        RecallPaymentGridNew(referenceno, amount);
                    }
                }
            }
            else {
                SweetAlert.fire('Transaction failed while receiving response from razorpay.Please try again');
            }
        }).catch(function (error) {
            setIsLoading(false);
            if (error.response) { ErrorFunction(error.response.status); }
        });
    }
    const RecallPaymentGridNew = async (posrefnumber, amount) => {
        var _authorizerId = ""; if (authorizerId.current !== null && authorizerId.current !== undefined) { _authorizerId = authorizerId.current.value; }
        var _authorizer = ""; if (authorizerId.current !== null && authorizerId.current !== undefined) { _authorizer = authorizerId.current.options[authorizerId.current.selectedIndex].text; }
        var _concessionReason = ""; if (concessionReason.current !== null && concessionReason.current !== undefined) { _concessionReason = concessionReason.current.value; }
        var pChkbx = "";
        if (Percentagecheckbox === "1") { pChkbx = parseFloat(parseFloat(totalamt) * (parseFloat(amount) / 100)).toFixed(2); }
        else { pChkbx = amount; }
        pChkbx = Math.round(pChkbx);
        let intCountr = 0;
        for (var x in glblPaymentsList) { intCountr = parseInt(glblPaymentsList[x].Id) + 1; }
        var txtPayMode = paymentmodedetail.current.options[paymentmodedetail.current.selectedIndex].text;
        var pymntChnlId = "0";
        if (paymentChannelId.current != undefined) { pymntChnlId = paymentChannelId.current.value; }
        var posmchnId = "0";
        if (posMachineId.current != undefined) { posmchnId = posMachineId.current.value; }
        if (paymentmodedetail.current.value === "13" && channelid === "-1" && posMachineId.current.value !== "0") {
            var POSMachineName = posMachineId.current.options[posMachineId.current.selectedIndex].text;
        }
        glblPaymentsList.push(
            {
                Id: intCountr, OrderDetailsId: "0", PaymentMode: txtPayMode, PaymentModeId: paymentmodedetail.current.value,
                PaymentChannelId: pymntChnlId, PaidAmount: pChkbx.toString(), ReferenceNo: posrefnumber, AuthorizerId: _authorizerId,
                Authorizer: _authorizer, ConcessionReason: _concessionReason, Status: "0", DiscountRejectReason: "", POSMachineName: POSMachineName, POSMachineId: posmchnId
            }
        );
        //////////////////////////////////
        var totalPaidSoFar = 0;
        var totalDiscSoFar = 0;
        var totalCredSoFar = 0;
        setPaidAmt(0);
        setDiscAmt(0);
        setCredAmt(0);
        for (var x in glblPaymentsList) {
            if (glblPaymentsList[x].PaymentModeId === "1" || glblPaymentsList[x].PaymentModeId === "13") {
                totalPaidSoFar = totalPaidSoFar + parseFloat(glblPaymentsList[x].PaidAmount);
            }
            if (glblPaymentsList[x].PaymentModeId === "14") {
                totalDiscSoFar = totalDiscSoFar + parseFloat(glblPaymentsList[x].PaidAmount);
            }
            if (glblPaymentsList[x].PaymentModeId === "15" || glblPaymentsList[x].PaymentModeId === "2") {
                totalCredSoFar = totalCredSoFar + parseFloat(glblPaymentsList[x].PaidAmount);
            }
        }
        setPaidAmt(totalPaidSoFar);
        setDiscAmt(totalDiscSoFar);
        setCredAmt(totalCredSoFar);
        //////////////////////////////////
        var _visitId = "0";
        if (visitingid == "" || visitingid == undefined || visitingid == null || visitingid == "0") { _visitId = "0"; } else { _visitId = visitingid; }
        if (_visitId === "0") {
            var tmpArray = [];
            for (var i in glblPaymentModesList) {
                var payModeExist = false;
                for (var j in glblPaymentsList) { if (glblPaymentsList[j].PaymentModeId == glblPaymentModesList[i].SNo) { payModeExist = true; break; } }
                if (payModeExist == false) {
                    tmpArray.push(<option value={glblPaymentModesList[i].SNo}>{glblPaymentModesList[i].PaymentMode}</option>);
                }
            }
            setPaymentMode([]);
            setPaymentMode(tmpArray);
        }
        setPaymentTable(glblPaymentsList);
        paymentamountdetail.current.value = "";
        if (paymentReferenceNo.current !== undefined && paymentReferenceNo.current !== null) { paymentReferenceNo.current.value = ""; }
        if (paymentmodedetail.current.value == "13") {
            setPayChannelId(paymentChannelId.current.value);
            if (channelid === "-1") {
                setPosMachineId(posMachineId.current.value);
                setPosMachineName(posMachineId.current.options[posMachineId.current.selectedIndex].text);
            }
        }
        paymentmodedetail.current.value = "0";
        setChannelId("0");
        ChangePaymentMode("0");
    }
    const ErrorFunction = async (ErrorCode) => {
        if (ErrorCode == "400")
            SweetAlert.fire({ title: "Error 400", text: "Bad request", icon: "error" })
        else if (ErrorCode == "401")
            SweetAlert.fire({ title: "Error 401", text: "Unauthorized", icon: "error" })
        else if (ErrorCode == "403")
            SweetAlert.fire({ title: "Error 403", text: "Forbidden", icon: "error" })
        else if (ErrorCode == "404")
            SweetAlert.fire({ title: "Error 404", text: "Page not found", icon: "error" })
        else if (ErrorCode == "500")
            SweetAlert.fire({ title: "Error 500", text: "Internal Server Error", icon: "error" })
        else if (ErrorCode == "503")
            SweetAlert.fire({ title: "Error 503", text: "Service Unavailable", icon: "error" })
    }
    return (
        <>
            <Breadcrumb title="Registration" parent="Work Order" />
            {/* <div><b>Welcome to {localStorage.CentreName}</b></div> */}
            <Form onSubmit={formik.handleSubmit} onKeyDown={(e) => e.keyCode == "13" ? e.preventDefault() : null}>
                <Container fluid>
                    {
                        isPageLoading ? <div className='text-center'> <Spinner animation="border" size='sm' /></div> :
                            <>

                                {
                                    localStorage.CentreTypeId == "2" ? null :
                                        <>
                                            {
                                                allowRegistrationSetting === 'falsee' ? null :
                                                    allowRegistrationSetting === false ?
                                                        <Row>
                                                            <Col md={12}>
                                                                <Card>
                                                                    <Card.Body>
                                                                        <Form.Row>
                                                                            <Col md={12} className="text-center">
                                                                                <Button variant='primary'>Please close your day first.</Button>
                                                                            </Col>
                                                                        </Form.Row>
                                                                    </Card.Body>
                                                                </Card>
                                                            </Col>
                                                        </Row>
                                                        :
                                                        UserCustomerCode === "" ?
                                                            <Row>
                                                                <Col md={12}>
                                                                    <Card>
                                                                        <Card.Body>
                                                                            <Form.Row>
                                                                                <Col md={12} className="text-center">
                                                                                    <Button variant='primary'>You are not authorized for registration, your customer code not found.</Button>
                                                                                </Col>
                                                                            </Form.Row>
                                                                        </Card.Body>
                                                                    </Card>
                                                                </Col>
                                                            </Row> :
                                                            UserCashCode === "" ?
                                                                <Row>
                                                                    <Col md={12}>
                                                                        <Card>
                                                                            <Card.Body>
                                                                                <Form.Row>
                                                                                    <Col md={12} className="text-center">
                                                                                        <Button variant='primary'>You are not authorized for registration, your cash code not found.</Button>
                                                                                    </Col>
                                                                                </Form.Row>
                                                                            </Card.Body>
                                                                        </Card>
                                                                    </Col>
                                                                </Row> :
                                                                UserVendorCode === "" ?
                                                                    <Row>
                                                                        <Col md={12}>
                                                                            <Card>
                                                                                <Card.Body>
                                                                                    <Form.Row>
                                                                                        <Col md={12} className="text-center">
                                                                                            <Button variant='primary'>You are not authorized for registration, your vendor code not found.</Button>
                                                                                        </Col>
                                                                                    </Form.Row>
                                                                                </Card.Body>
                                                                            </Card>
                                                                        </Col>
                                                                    </Row>
                                                                    // :
                                                                    // UserPOSCode === "" ?
                                                                    //     <Row>
                                                                    //         <Col md={12}>
                                                                    //             <Card>
                                                                    //                 <Card.Body>
                                                                    //                     <Form.Row>
                                                                    //                         <Col md={12} className="text-center">
                                                                    //                             <Button variant='primary'>You are not authorized for registration, your pos code not found.</Button>
                                                                    //                         </Col>
                                                                    //                     </Form.Row>
                                                                    //                 </Card.Body>
                                                                    //             </Card>
                                                                    //         </Col>
                                                                    //     </Row>
                                                                    :
                                                                    null
                                            }
                                        </>
                                }
                                {
                                    localStorage.CentreTypeId == "2" ? null :
                                        <>
                                            {
                                                allowRegistrationSetting === true && UserCustomerCode !== "" && UserCashCode !== "" && UserVendorCode !== "" ?
                                                    <Row>
                                                        <Col md={12} className='text-right'>
                                                            <Button variant='primary'>Registration allowed : {pendingtime} days left</Button>
                                                        </Col>
                                                    </Row>
                                                    : null
                                            }
                                        </>
                                }
                                <Row className='mt-3'>
                                    <Col md={12}>
                                        <Card>
                                            <Card.Body>
                                                <Form.Row className='justify-content-center align-items-center'>
                                                    <Col className='col-auto adPatientColLeft'>
                                                        <Form.Group className='mb-0'>
                                                            <InputGroup id='inpt'>
                                                                <Typeahead
                                                                    disabled={editdetails}
                                                                    id="mobuhid-typeahead"
                                                                    labelKey="Patient"
                                                                    multiple={multiple}
                                                                    options={moboruhidoptions}
                                                                    ref={moboruhid}
                                                                    placeholder="Search by patient mobile"
                                                                    onInputChange={(text, e) => { SearchPatient(e, text); }}
                                                                    onChange={(text) => { JSON.stringify(text) == "[]" ? GetEditRegistrationDetails("0") : GetEditRegistrationDetails(text[0].SNo) }}
                                                                />
                                                                <InputGroup.Append>
                                                                    <Button variant="secondary" onClick={ClickSearchPatient}><i className='fa fa-search'></i></Button>
                                                                </InputGroup.Append>
                                                            </InputGroup>
                                                        </Form.Group>
                                                    </Col>
                                                    {
                                                        editdetails &&
                                                        <>
                                                            {
                                                                isButtonLoading ?
                                                                    <LoadingButton variantName='primary' cssName="" /> :
                                                                    <Col className='col-auto adPatientColRight'>
                                                                        <Form.Group className='mb-0'>
                                                                            <Button variant='primary' type='submit' onClick={(e) => { setIsButtonLoading(true); e.preventDefault(); window.location.reload(false) }}>Search Again</Button>
                                                                        </Form.Group>
                                                                    </Col>
                                                            }
                                                        </>
                                                    }
                                                    {ShowBalanceAmountSetting ?
                                                        parentcentreid === "0" ?
                                                            <Col md={2}>
                                                                <Form.Label>Available Balance: <span className='text-red'>{parseFloat(CreditLimitSetting).toFixed(2)}</span></Form.Label>
                                                                <Form.Label>Booking Limit: <span className='text-red'>{parseFloat("-" + BookingLimitSetting).toFixed(2)}</span></Form.Label>
                                                            </Col> :
                                                            null
                                                        : null
                                                    }
                                                </Form.Row>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                </Row>
                                {allowRegistrationSetting === true && UserCustomerCode !== "" && UserCashCode !== "" && UserVendorCode !== "" ? <>
                                    <Row>
                                        <Col md={12}>
                                            <Card>
                                                <Card.Header className='pb-0'>
                                                    <Card.Title>Demographic Details
                                                        {
                                                            patientsno == "" ? null :
                                                                <>
                                                                    {
                                                                        editdemographicdetailsallowed == "false" || editdemographicdetailsallowed == "False" ?
                                                                            null
                                                                            :
                                                                            <>
                                                                                &nbsp;&nbsp;<i className='fa fa-edit' style={{ color: 'black' }} onClick={openDemographicModal}></i>
                                                                            </>
                                                                    }
                                                                </>
                                                        }
                                                    </Card.Title>
                                                </Card.Header>
                                                <Card.Body style={{ paddingTop: '0px' }}>
                                                    <Form.Row>
                                                        <Col md={3}>
                                                            <Form.Group>
                                                                <Form.Label>Patient Mobile <span className='text-red'>*</span></Form.Label>
                                                                <Form.Control type="text" id="patientmobile" ref={patientmobile} name="patientmobile" value={formik.values.patientmobile} onChange={formik.handleChange}
                                                                    onBlur={formik.handleBlur} disabled={disablefields.patientmobile} />
                                                                {formik.touched.patientmobile && formik.errors.patientmobile ? (
                                                                    <div className='text-required'>{formik.errors.patientmobile}</div>
                                                                ) : null}
                                                            </Form.Group>
                                                        </Col>
                                                        <Col md={3}>
                                                            <Form.Group>
                                                                <Form.Label>Title<span className='text-red'>*</span></Form.Label>
                                                                <Form.Control as="select" name="title" value={formik.values.title} onChange={formik.handleChange}
                                                                    onBlur={formik.handleBlur} disabled={disablefields.title} onChangeCapture={(e) => ChangeGender(e)}>
                                                                    <option value="Mr.">Mr.</option>
                                                                    <option value="Mrs.">Mrs.</option>
                                                                    <option value="Miss.">Miss.</option>
                                                                    <option value="Baby.">Baby.</option>
                                                                    <option value="Baba.">Baba.</option>
                                                                    <option value="Master.">Master.</option>
                                                                    <option value="Dr.">Dr.</option>
                                                                    <option value="B/O.">B/O.</option>
                                                                    <option value="Ms.">Ms.</option>
                                                                    <option value="C/O.">C/O.</option>
                                                                    <option value="Mx.">Mx.</option>
                                                                </Form.Control>
                                                                {formik.touched.title && formik.errors.title ? (
                                                                    <div className='text-required'>{formik.errors.title}</div>
                                                                ) : null}
                                                            </Form.Group>
                                                        </Col>
                                                        <Col md={3}>
                                                            <Form.Group>
                                                                <Form.Label>Patient Name <span className='text-red'>*</span></Form.Label>
                                                                <Form.Control type="text" name="patientname" ref={patientname}
                                                                    onChange={formik.handleChange}
                                                                    onBlur={formik.handleBlur}
                                                                    value={formik.values.patientname}
                                                                    disabled={disablefields.patientname}
                                                                    onBlurCapture={CheckIfPatientExist}
                                                                />
                                                                {formik.touched.patientname && formik.errors.patientname ? (
                                                                    <div className='text-required'>{formik.errors.patientname}</div>
                                                                ) : null}
                                                            </Form.Group>
                                                        </Col>
                                                        <Col md={3}>
                                                            <Form.Group>
                                                                <Form.Label>Gender <span className='text-red'>*</span></Form.Label>
                                                                <Form.Control as="select" name="gender" value={formik.values.gender} onChange={formik.handleChange}
                                                                    onBlur={formik.handleBlur} disabled={disablefields.gender} onChangeCapture={(d) => setTable([])} >
                                                                    <option value="">Select</option>
                                                                    {genders}
                                                                </Form.Control>
                                                                {formik.touched.gender && formik.errors.gender ? (
                                                                    <div className='text-required'>{formik.errors.gender}</div>
                                                                ) : null}
                                                            </Form.Group>
                                                        </Col>
                                                        <Col md={3}>
                                                            <Form.Group>
                                                                <Form.Label>DOB (DD-MM-YYYY)<span className='text-red'>*</span></Form.Label>
                                                                <Form.Control type="text" ref={dateobirth} name="dob" value={formik.values.dob} onChange={formik.handleChange}
                                                                    onBlur={formik.handleBlur} disabled={dobtext} onKeyUp={(e) => CallDob(e)} />
                                                                {formik.touched.dob && formik.errors.dob ? (
                                                                    <div className='text-required'>{formik.errors.dob}</div>
                                                                ) : null}
                                                            </Form.Group>
                                                        </Col>
                                                        <Col md={3}>
                                                            <Form.Group>
                                                                <Form.Label>Year<span className='text-red'>*</span></Form.Label>
                                                                <Form.Control type="tel" ref={year} name="year" disabled={agetext} onKeyPress={(e) => CallValidationCheck(e)} onKeyUp={(e) => CallAllFunctions(e)} />
                                                            </Form.Group>
                                                        </Col>
                                                        <Col md={3}>
                                                            <Form.Group>
                                                                <Form.Label>Month<span className='text-red'>*</span></Form.Label>
                                                                <Form.Control type="tel" ref={month} name="month" disabled={agetext} onKeyPress={(e) => CallValidationCheck(e)} onKeyUp={(e) => CallAllFunctions(e)} />
                                                            </Form.Group>
                                                        </Col>
                                                        <Col md={3}>
                                                            <Form.Group>
                                                                <Form.Label>Day<span className='text-red'>*</span></Form.Label>
                                                                <Form.Control type="tel" ref={day} name="day" disabled={agetext} onKeyPress={(e) => CallValidationCheck(e)} onKeyUp={(e) => CallAllFunctions(e)} />
                                                            </Form.Group>
                                                        </Col>
                                                        <Col md={3}>
                                                            <Form.Group>
                                                                <Form.Label>Pin Code<span className='text-red'>*</span></Form.Label>
                                                                <Form.Control ref={pincode} id="pincode" type="text" name="pincode" value={formik.values.pincode} onChange={formik.handleChange}
                                                                    onBlur={formik.handleBlur} onKeyUp={(e) => CallStateCity(e, "1")} /> {/*disabled={disablefields.pincode} */}
                                                                {formik.touched.pincode && formik.errors.pincode ? (
                                                                    <div className='text-required'>{formik.errors.pincode}</div>
                                                                ) : null}
                                                            </Form.Group>
                                                        </Col>
                                                        <Col md={3}>
                                                            <Form.Group>
                                                                <Form.Label>State<span className='text-red'>*</span></Form.Label>
                                                                <Form.Control as="select" name="locStateId" id="locStateId" value={formik.values.locStateId} onChange={formik.handleChange} onBlur={formik.handleBlur}
                                                                    onChangeCapture={StateChangeHandler}> {/*disabled={disablefields.locStateId} */}
                                                                    <option value="">---Select---</option>
                                                                    {statesList}
                                                                </Form.Control>
                                                                {formik.touched.locStateId && formik.errors.locStateId ? (<div className='text-required'>{formik.errors.locStateId}</div>) : null}
                                                            </Form.Group>
                                                        </Col>
                                                        <Col md={3}>
                                                            <Form.Group>
                                                                <Form.Label>District<span className='text-red'>*</span></Form.Label>
                                                                <Form.Control as="select" name="locDistrictId" value={formik.values.locDistrictId}
                                                                    onChange={formik.handleChange} onBlur={formik.handleBlur}> {/*disabled={disablefields.locDistrictId} */}
                                                                    <option value="">---Select---</option>
                                                                    {districtsList}
                                                                </Form.Control>
                                                                {formik.touched.locDistrictId && formik.errors.locDistrictId ? (<div className='text-required'>{formik.errors.locDistrictId}</div>) : null}
                                                            </Form.Group>
                                                        </Col>
                                                        {/* <Col md={3}>
                                                            <Form.Group>
                                                                <Form.Label>House No. <span className='text-red'>*</span></Form.Label>
                                                                <Form.Control type="text" name="houseno" value={formik.values.houseno} onChange={formik.handleChange}
                                                                    onBlur={formik.handleBlur} />
                                                                {formik.touched.houseno && formik.errors.houseno ? (<div className='text-required'>{formik.errors.houseno}</div>) : null}
                                                            </Form.Group>
                                                        </Col> */}
                                                        <Col md={3}>
                                                            <Form.Group>
                                                                <Form.Label>Address <span className='text-red'>*</span></Form.Label>
                                                                <Form.Control type="text" name="address" onChange={formik.handleChange}
                                                                    onBlur={formik.handleBlur} value={formik.values.address} /> {/*disabled={disablefields.address} */}
                                                                {formik.touched.address && formik.errors.address ? (<div className='text-required'>{formik.errors.address}</div>) : null}
                                                            </Form.Group>
                                                        </Col>
                                                        <Col md={3}>
                                                            <Form.Group>
                                                                <Form.Label>Email Id</Form.Label>
                                                                <Form.Control type="email" name="emailid" value={formik.values.emailid} onChange={formik.handleChange}
                                                                    onBlur={formik.handleBlur} /> {/*disabled={disablefields.emailid} */}
                                                                {formik.touched.emailid && formik.errors.emailid ? (
                                                                    <div className='text-required'>{formik.errors.emailid}</div>
                                                                ) : null}
                                                            </Form.Group>
                                                        </Col>
                                                        {
                                                            PartnerTypeIdSetting == "3" || PartnerTypeIdSetting == "4" ? null :
                                                                <>
                                                                    <Col md={3}>
                                                                        <Form.Group>
                                                                            <Form.Label>Visit Type <span className='text-red'>*</span></Form.Label>
                                                                            <Form.Control as="select" name="visittype" value={formik.values.visittype} onChange={formik.handleChange}
                                                                                onBlur={formik.handleBlur} > {/*disabled={disablefields.visittype} */}
                                                                                <option value="CentreVisit">Centre Visit</option>
                                                                                <option value="Homecollection">Home Collection</option>
                                                                            </Form.Control>
                                                                            {formik.touched.visittype && formik.errors.visittype ? (
                                                                                <div className='text-required'>{formik.errors.visittype}</div>
                                                                            ) : null}
                                                                        </Form.Group>
                                                                    </Col>
                                                                    <Col md={3}>
                                                                        <Form.Group>
                                                                            <Form.Label>Dispatch Mode</Form.Label>
                                                                            <Form.Control as="select" id="dispatchmodenew" name="dispatchmode" onChange={formik.handleChange}
                                                                                onBlur={formik.handleBlur} value={formik.values.dispatchmode}> {/*disabled={disablefields.dispatchmode} */}
                                                                                <option value="0">---Select---</option>
                                                                                {dispatchmode}
                                                                            </Form.Control>
                                                                            {formik.touched.dispatchmode && formik.errors.dispatchmode ? (
                                                                                <div className='text-required'>{formik.errors.dispatchmode}</div>
                                                                            ) : null}
                                                                        </Form.Group>
                                                                    </Col>
                                                                </>
                                                        }
                                                        {/* <Col md={3}>
                                                <Form.Group>
                                                    <Form.Label>Remarks <span className='text-red'>(Max length 500 characters allowed)</span></Form.Label>
                                                    <Form.Control as="textarea" rows={3} ref={remarks} maxLength={500} id="remarksnew" name="remarks" onChange={formik.handleChange}
                                                        // type="text"
                                                        onBlur={formik.handleBlur} value={formik.values.remarks} /> 
                                                </Form.Group>
                                            </Col> */}
                                                        {
                                                            // localStorage.CentreTypeId == "2" ? null :
                                                            ShowPROInRegistrationSetting == false ? null :
                                                                <Col md={3}>
                                                                    <Form.Group>
                                                                        <Form.Label> PRO</Form.Label>
                                                                        <Select options={[{ label: "---Select---", value: "0" }, ...PROName]} onChange={PROChangeHandler} value={proSelected} />
                                                                        {formik.touched.PROName && formik.errors.PROName ? (
                                                                            <div className='text-required'>{formik.errors.PROName}</div>
                                                                        ) : null}
                                                                    </Form.Group>
                                                                </Col>
                                                        }
                                                        {
                                                            PartnerTypeIdSetting == "3" || PartnerTypeIdSetting == "4" ? null :
                                                                <Col md={3}>
                                                                    <Form.Group>
                                                                        <Form.Label>Source <span className='text-red'>*</span></Form.Label>
                                                                        {
                                                                            editviasource === "" ?
                                                                                <>
                                                                                    <Typeahead
                                                                                        id="viasource"
                                                                                        labelKey="Source"
                                                                                        multiple={multiple}
                                                                                        options={viasource}
                                                                                        placeholder="---Select---"
                                                                                        ref={viasourcetype}
                                                                                        value={formik.values.viasource}
                                                                                        onInputChange={(text, e) => { getsourcelist(e, text); }}
                                                                                        onChange={(text) => {
                                                                                            JSON.stringify(text) == "[]" ? BindSource("-1") : BindSource(text[0].SNo)
                                                                                        }}
                                                                                    />
                                                                                    {formik.touched.viasource && formik.errors.viasource ? (
                                                                                        <div className='text-required'>{formik.errors.viasource}</div>
                                                                                    ) : null}
                                                                                </> :
                                                                                <>
                                                                                    <Typeahead
                                                                                        id="viasource"
                                                                                        labelKey="Source"
                                                                                        multiple={multiple}
                                                                                        options={viasource}
                                                                                        placeholder={editviasourcename}
                                                                                        ref={viasourcetype}
                                                                                        value={formik.values.viasource}
                                                                                        onInputChange={(text, e) => { getsourcelist(e, text); }}
                                                                                        onChange={(text) => {
                                                                                            JSON.stringify(text) == "[]" ? BindSource("-1") : BindSource(text[0].SNo)
                                                                                        }}
                                                                                    />
                                                                                    {formik.touched.viasource && formik.errors.viasource ? (
                                                                                        <div className='text-required'>{formik.errors.viasource}</div>
                                                                                    ) : null}
                                                                                </>
                                                                        }
                                                                    </Form.Group>
                                                                </Col>
                                                        }
                                                        {OtherLabRefNoSetting ?
                                                            <Col md={3}>
                                                                <Form.Group>
                                                                    <Form.Label>Other Lab Ref No</Form.Label>
                                                                    <Form.Control type="text" ref={remarks} id="otherLabRefNo" name="otherLabRefNo" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.otherLabRefNo} />
                                                                </Form.Group>
                                                            </Col>
                                                            : null
                                                        }
                                                        {
                                                            refdocname.length < 1 ?
                                                                <Col md={6}>
                                                                    <Form.Group>
                                                                        <Form.Label>Referring Doctor <span className='text-red'>*</span></Form.Label>
                                                                        <Button variant='secondary' className='padrevert' onClick={openDoctorModal}>Add New</Button>
                                                                        <Typeahead
                                                                            ref={testdocreference}
                                                                            id="refdoc-typeahead"
                                                                            labelKey="DoctorName"
                                                                            multiple={multiple}
                                                                            options={refdocoptions}
                                                                            placeholder="Self"
                                                                            onInputChange={(text, e) => {
                                                                                if (text !== '') { getreferringdoctorlist(e, text, 0); }
                                                                                else { setRefDocSno(""); setRefDoctorName(""); setNewDoctorFlag("0"); }
                                                                            }}
                                                                            onChange={(text) => {
                                                                                if (JSON.stringify(text) === "[]") { setRefDocSno(""); setRefDoctorName(""); setNewDoctorFlag("0"); }
                                                                                else { setRefDocSno(text[0].SNo); setRefDoctorName(text[0].DoctorName); setNewDoctorFlag(text[0].IsNewDoctorFlag.toLowerCase() == "true" ? "1" : text[0].IsNewDoctorFlag.toLowerCase() == "false" ? "0" : text[0].IsNewDoctorFlag); }
                                                                            }}
                                                                        />
                                                                    </Form.Group>
                                                                </Col>
                                                                :
                                                                null
                                                        }
                                                        {
                                                            refdocname.length > 0 && refdocnamevisible === false ?
                                                                <Col md={6}>
                                                                    <Form.Group>
                                                                        <Form.Label>Referring Doctor <span className='text-red'>*</span></Form.Label>
                                                                        <Button variant='secondary' className='padrevert' onClick={openDoctorModal}>Add New</Button>
                                                                        <Typeahead
                                                                            ref={testdocreference}
                                                                            id="refdoc-typeahead"
                                                                            labelKey="DoctorName"
                                                                            multiple={multiple}
                                                                            options={refdocoptions}
                                                                            placeholder={refdocname.length > 0 ? refdocname : `Self`}
                                                                            //defaultInputValue={refdocname}
                                                                            onInputChange={(text, e) => {
                                                                                if (text !== '') { getreferringdoctorlist(e, text, 0); }
                                                                                else { setRefDocSno(""); setRefDoctorName(""); setNewDoctorFlag("0"); }
                                                                            }}
                                                                            onChange={(text) => {
                                                                                if (JSON.stringify(text) === "[]") { setRefDocSno(""); setRefDoctorName(""); setNewDoctorFlag("0"); }
                                                                                else { setRefDocSno(text[0].SNo); setRefDoctorName(text[0].DoctorName); setNewDoctorFlag(text[0].IsNewDoctorFlag.toLowerCase() == "true" ? "1" : text[0].IsNewDoctorFlag.toLowerCase() == "false" ? "0" : text[0].IsNewDoctorFlag); }
                                                                            }}
                                                                        />
                                                                    </Form.Group>
                                                                </Col>
                                                                :
                                                                null
                                                        }
                                                        {
                                                            refdocnamevisible &&
                                                            <>
                                                                <Col md={6}>
                                                                    <Form.Group>
                                                                        <Form.Label>Referring Doctor <span className='text-red'>*</span></Form.Label>
                                                                        <Button variant='secondary' className='padrevert' onClick={openDoctorModal}>Add New</Button>
                                                                        <Typeahead
                                                                            ref={testdocreference}
                                                                            id="refdoc-typeahead"
                                                                            labelKey="DoctorName"
                                                                            multiple={multiple}
                                                                            options={refdocoptions}
                                                                            placeholder="Self"
                                                                            defaultInputValue={refdocname}
                                                                            onInputChange={(text, e) => {
                                                                                if (text !== '') { getreferringdoctorlist(e, text, 0); }
                                                                                else { setRefDocSno(""); setRefDoctorName(""); setNewDoctorFlag("0"); }
                                                                            }}
                                                                            onChange={(text) => {
                                                                                if (JSON.stringify(text) === "[]") { setRefDocSno(""); setRefDoctorName(""); setNewDoctorFlag("0"); }
                                                                                else { setRefDocSno(text[0].SNo); setRefDoctorName(text[0].DoctorName); setNewDoctorFlag(text[0].IsNewDoctorFlag.toLowerCase() == "true" ? "1" : text[0].IsNewDoctorFlag.toLowerCase() == "false" ? "0" : text[0].IsNewDoctorFlag); }
                                                                            }}
                                                                        />
                                                                    </Form.Group>
                                                                </Col>
                                                            </>
                                                        }
                                                        {
                                                            centreTypeIdSetting == "2" ?
                                                                <Col md={3}>
                                                                    <Form.Group>
                                                                        <Form.Label>Referrer Lab</Form.Label>
                                                                        <Form.Control type="text" name="otherreflab" onChange={formik.handleChange}
                                                                            onBlur={formik.handleBlur} value={formik.values.otherreflab} />
                                                                    </Form.Group>
                                                                </Col>
                                                                :
                                                                <Col md={3}>
                                                                    <Form.Group>
                                                                        <Form.Label>Other Doctor</Form.Label>
                                                                        <Form.Control type="text" name="otherdoc" onChange={formik.handleChange}
                                                                            onBlur={formik.handleBlur} value={formik.values.otherdoc} /> {/*disabled={disablefields.otherdoc} */}
                                                                    </Form.Group>
                                                                </Col>
                                                        }
                                                        <Col md={3} style={{ paddingTop: 27 }}>
                                                            <Form.Group className='animate-chk'>
                                                                <Form.Label>
                                                                    <input className="checkbox_animated" id="mlcchkbox" type="checkbox" onClick={(e) => { handleCheckbox(e); }} /> MLC
                                                                </Form.Label>
                                                                <Form.Label className='m-l-15'>
                                                                    <input className="checkbox_animated" id="prescriptionchkbox" type="checkbox" onClick={(e) => { handlePrescriptionChecked(e); }} /> Prescription
                                                                </Form.Label>
                                                            </Form.Group>
                                                        </Col>
                                                    </Form.Row>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={12}>
                                            <Card>
                                                <Card.Header className='pb-0'>
                                                    <Card.Title>Investigations</Card.Title>
                                                </Card.Header>
                                                <Card.Body>
                                                    <Form.Row className='align-items-center'>
                                                        <Col md={3}>
                                                            <Form.Group>
                                                                <Form.Label>Patient Type <span className='text-red'>*</span></Form.Label>
                                                                <Form.Control as="select" disabled={alldisablefields.patienttype} name="patienttype" onChange={formik.handleChange}
                                                                    onBlur={formik.handleBlur} value={formik.values.patienttype} ref={refpatienttype} onChangeCapture={(e) => { CallFunctions(e) }}>
                                                                    <option value="0">---Select---</option>
                                                                    {patienttype}
                                                                </Form.Control>
                                                                {formik.touched.patienttype && formik.errors.patienttype ? (<div className='text-required'>{formik.errors.patienttype}</div>) : null}
                                                            </Form.Group>
                                                        </Col>
                                                        {
                                                            globaldocument.length > 0 ?
                                                                <>
                                                                    <Col md={3}>
                                                                        <Form.Group>
                                                                            <Form.Label>Select {patienttypenamecheck} <span className='text-red'>*</span></Form.Label>
                                                                            <Form.Control as="select" disabled={alldisablefields.globaldocument} name="globaldocument" onChange={formik.handleChange}
                                                                                onBlur={formik.handleBlur} value={formik.values.globaldocument} onChangeCapture={(e) => { GetGlobalDocument(e.target.value) }}>
                                                                                <option value="0">---Select---</option>
                                                                                {globaldocument}
                                                                            </Form.Control>
                                                                            {formik.touched.globaldocument && formik.errors.globaldocument ? (<div className='text-required'>{formik.errors.globaldocument}</div>) : null}
                                                                        </Form.Group>
                                                                    </Col>
                                                                    {
                                                                        globaldocuments.length > 0 ?
                                                                            globaldocuments.map((d) =>
                                                                                d.DocumentType.toLowerCase() == "text" ?
                                                                                    <Col md={3}>
                                                                                        <Form.Group>
                                                                                            <Form.Label>{d.DocumentName}</Form.Label>
                                                                                            <Form.Control type="text" id={`global${d.SNo}`} onBlur={(e) => Savedocumenttext(e, d.SNo, d.DocumentType)} />
                                                                                        </Form.Group>
                                                                                    </Col> :
                                                                                    d.DocumentType.toLowerCase() == "dropdown" ?
                                                                                        <Col md={3}>
                                                                                            <Form.Group>
                                                                                                <Form.Label>{d.DocumentName}</Form.Label>
                                                                                                <Form.Control as="select" id={`globaldropdown${d.SNo}`} onChange={(e) => Savedocumenttext(e, d.SNo, d.DocumentType)}>
                                                                                                    <option key="0" value="0">Select</option>
                                                                                                    {
                                                                                                        glblDocumentArr.map((e) => e.DocSNo === d.SNo ?
                                                                                                            <option key={e.DocValue} value={e.DocValue}>{e.DocValue}</option>
                                                                                                            : null
                                                                                                        )
                                                                                                    }
                                                                                                </Form.Control>
                                                                                            </Form.Group>
                                                                                        </Col>
                                                                                        :
                                                                                        d.DocumentType.toLowerCase() == "checkbox" ?
                                                                                            <Col md={3}>
                                                                                                <Form.Group style={{ marginBottom: '0px' }}>
                                                                                                    <Form.Label>{d.DocumentName}</Form.Label>
                                                                                                </Form.Group>
                                                                                                <Form.Group className='animate-chk m-checkbox-inline' style={{ marginTop: '5px' }}>
                                                                                                    {glblCheckboxList.map(({ DocValue, Checked }, index) => {
                                                                                                        return (
                                                                                                            <Form.Label for={`chkBox-${index}`}>
                                                                                                                <input class="checkbox_animated" type="checkbox" id={`chkBox-${index}`}
                                                                                                                    checkedState={Checked} onChange={() => handleOnChange(index)} />{DocValue}
                                                                                                            </Form.Label>
                                                                                                        );
                                                                                                    })}
                                                                                                </Form.Group>
                                                                                            </Col>
                                                                                            :
                                                                                            <Col md={3} className='text-center'>
                                                                                                <Form.Group>
                                                                                                    <Form.Label>{d.DocumentName}</Form.Label>
                                                                                                    <ImageUploader
                                                                                                        withIcon={false}
                                                                                                        withPreview={false}
                                                                                                        label=""
                                                                                                        singleImage={true}
                                                                                                        buttonText="Select Document"
                                                                                                        onChange={(e) => onGlobalUploadDocs(e, d.SNo, d.DocumentType)}
                                                                                                        imgExtension={[".jpg", ".gif", ".png", ".gif", ".svg"]}
                                                                                                        maxFileSize={1048576}
                                                                                                        fileSizeError="file size is too big"
                                                                                                    /><div id={`globalimg${d.SNo}`}></div>
                                                                                                </Form.Group>
                                                                                            </Col>
                                                                            )
                                                                            :
                                                                            null
                                                                    }
                                                                </>
                                                                :
                                                                null
                                                        }
                                                        <Col md={3}>
                                                            <Form.Group>
                                                                <Form.Label>Rate Plan <span className='text-red'>*</span></Form.Label>
                                                                <Form.Control as="select" name="ratetype" disabled={alldisablefields.ratetype} onChange={formik.handleChange}
                                                                    onBlur={formik.handleBlur} value={formik.values.ratetype} onChangeCapture={callDynamicValidationsnew} ref={rratetype}>
                                                                    <option value="0">---Select---</option>
                                                                    {ratetype}
                                                                </Form.Control>
                                                                {formik.touched.ratetype && formik.errors.ratetype ? (
                                                                    <div className='text-required'>{formik.errors.ratetype}</div>
                                                                ) : null}
                                                            </Form.Group>
                                                        </Col>
                                                    </Form.Row>
                                                    <Form.Row>
                                                        {
                                                            edittabledataavailable == false ?
                                                                <>
                                                                    <Col md={6}>
                                                                        <Form.Group>
                                                                            <Form.Label>Add Test</Form.Label>
                                                                            <Typeahead
                                                                                disabled={disableBookings}
                                                                                id="basic-typeahead"
                                                                                labelKey="Item"
                                                                                filterBy={["Item", "ShortName"]}
                                                                                multiple={multiple}
                                                                                options={options}
                                                                                placeholder="Choose a test..."
                                                                                ref={testreference}
                                                                                onInputChange={(text, e) => { getitemlist(e, text); }}
                                                                                onChange={(text) => {
                                                                                    JSON.stringify(text) == "[]" ? setItemSno("") : setItemSno(text[0].SNo)
                                                                                    BindGrid(text[0].SNo, text[0].IsPackage, text[0].RateTemplatesId)
                                                                                }}
                                                                            />
                                                                        </Form.Group>
                                                                    </Col>
                                                                </>
                                                                :
                                                                <>
                                                                    <Col md={3}>
                                                                        <Form.Group>
                                                                            <Form.Label>Order Date</Form.Label>
                                                                            <Form.Control as="select" name="orderdate" onChange={formik.handleChange}
                                                                                onBlur={formik.handleBlur} value={formik.values.orderdate} onChangeCapture={(e) => CallOrderDates(e)}>
                                                                                <option value="0">---Select---</option>
                                                                                {orderdate}
                                                                            </Form.Control>
                                                                            {formik.touched.orderdate && formik.errors.orderdate ? (
                                                                                <div className='text-required'>{formik.errors.orderdate}</div>
                                                                            ) : null}
                                                                        </Form.Group>
                                                                    </Col>
                                                                </>
                                                        }
                                                    </Form.Row>
                                                    {
                                                        edittabledataavailable == false ?
                                                            <>
                                                                <div className='text-right'>
                                                                    <b>Total Test:</b> <span className='text-danger mr-2' name="totaltest" onChange={formik.handleChange}
                                                                        onBlur={formik.handleBlur} value={formik.values.totaltest}>{totaltest}</span>
                                                                    {
                                                                        parentcentreid === "0" ?
                                                                            <>
                                                                                <b>Total Amount:</b> <span className='text-danger mr-2' name="totalamount" onChange={formik.handleChange}
                                                                                    onBlur={formik.handleBlur} value={formik.values.totalamount}><i className='fa fa-inr'></i>{totalamt}</span>
                                                                            </> : null
                                                                    }
                                                                </div>
                                                                {
                                                                    isLoading ? <div className='text-center'> <Spinner animation="border" size='sm' /></div> :
                                                                        <div className="table-responsive mt15">
                                                                            {/* <DataTables
                                                                    keyField='ItemSNo'
                                                                    tableData={table}
                                                                    columns={columns}
                                                                    noDataIndication={NoRecordFounds}
                                                                /> */}
                                                                            <BootstrapTable
                                                                                keyField='ItemSNo'
                                                                                data={table}
                                                                                columns={columns}
                                                                                bootstrap4
                                                                                headerWrapperClasses="thead-light"
                                                                                bordered={false}
                                                                                noDataIndication={<NoRecordFounds />}
                                                                                cellEdit={cellEditFactory({
                                                                                    mode: 'click',
                                                                                    blurToSave: true,
                                                                                    afterSaveCell: (oldValue, newValue, rowData, column) => { CalladdedDiscount(oldValue, newValue, rowData, column) },
                                                                                })
                                                                                }
                                                                            />
                                                                        </div>
                                                                }
                                                                <Col md={6} className='mt-5'>
                                                                    <Form.Group>
                                                                        <Form.Label>Remarks <span className='text-red'>(Max length 500 characters allowed)</span></Form.Label>
                                                                        {/* {
                                                                hideeditsavefield == true ? <Button variant='secondary' className='padrevert' onClick={openRemarksModal}>View Remarks</Button> : null
                                                            } */}
                                                                        <Form.Control as="textarea" rows={3} ref={remarks} maxLength={500} id="remarksnew" name="remarks" onChange={formik.handleChange}
                                                                            onBlur={formik.handleBlur} value={formik.values.remarks} />
                                                                    </Form.Group>
                                                                </Col>
                                                            </>
                                                            :
                                                            <>
                                                                <Row>
                                                                    <Col md={12} className='text-right'>
                                                                        {
                                                                            // status === true ? null :
                                                                            hideeditsavefield === true ?
                                                                                invoicegenerated === false ?
                                                                                    <Button variant='primary' style={{ marginRight: 10 }} onClick={editItems} >Edit Items</Button>
                                                                                    :
                                                                                    null
                                                                                :
                                                                                parentcentreid === "0" ? null :
                                                                                    <Button variant='primary' style={{ marginRight: 10 }} onClick={editItems} >Edit Items</Button>
                                                                        }
                                                                        {
                                                                            HideAmountInBookingSetting === true ? null :
                                                                                parentcentreid === "0" ?
                                                                                    <Button variant='primary' onClick={() => GetInvoiceDetails()}>View Bill</Button> :
                                                                                    null
                                                                        }
                                                                    </Col>
                                                                </Row>
                                                                <div className="table-responsive mt15">
                                                                    <DataTables
                                                                        keyField='ItemSNo'
                                                                        tableData={edittable}
                                                                        columns={editcolumns}
                                                                        noDataIndication={NoRecordFounds}
                                                                    />
                                                                </div>
                                                                <Col md={6} className='mt-5'>
                                                                    <Form.Group>
                                                                        <Form.Label>Remarks <span className='text-red'>(Max length 500 characters allowed)</span></Form.Label>
                                                                        {/* <Button variant='secondary' className='padrevert' onClick={openRemarksModal}>View Remarks</Button> */}
                                                                        <Form.Control as="textarea" rows={3} ref={remarks} maxLength={500} id="remarksnew" name="remarks" onChange={formik.handleChange}
                                                                            // type="text"
                                                                            onBlur={formik.handleBlur} value={formik.values.remarks} />
                                                                    </Form.Group>
                                                                </Col>
                                                                {
                                                                    hideeditsavefield === true ?
                                                                        <Row>
                                                                            <Col md={12} className='text-right'>
                                                                                <Button variant='primary' onClick={saveandaddmore}>Save & Add More</Button>
                                                                            </Col>
                                                                        </Row>
                                                                        :
                                                                        parentcentreid === "0" ?
                                                                            <Row>
                                                                                <Col md={6}>
                                                                                    <Card.Header className='pb-0'>
                                                                                        <Card.Title>Rate plan not available so you cannot edit items</Card.Title>
                                                                                    </Card.Header>
                                                                                </Col>
                                                                                <Col md={6} className='text-right'>
                                                                                    <Button variant='primary' onClick={addnewitems}>Add New Items</Button>
                                                                                </Col>
                                                                            </Row> :
                                                                            <Row>
                                                                                <Col md={12} className='text-right'>
                                                                                    <Button variant='primary' onClick={saveandaddmore}>Save & Add More</Button>
                                                                                </Col>
                                                                            </Row>
                                                                }

                                                            </>
                                                    }
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    </Row>
                                    {
                                        showdiscount &&
                                        <>
                                            <Row>
                                                {packageModal &&
                                                    <Col md={12}>
                                                        <Card>
                                                            <Card.Header className='pb-0'>
                                                                <Card.Title>Recommended Packages</Card.Title>
                                                            </Card.Header>
                                                            <Card.Body>
                                                                <DataTables keyField='PackageId' tableData={popupTableData} columns={popupColumns} pagination={paginationFactory(popupPaginationOptions)} />
                                                            </Card.Body>
                                                        </Card>
                                                    </Col>
                                                }
                                                {
                                                    table.length == 0 ? null :
                                                        <>
                                                            {
                                                                parentcentreid === "0" ?
                                                                    <>
                                                                        <Col md={12}>
                                                                            <Card>
                                                                                <Card.Header className='pb-0'>
                                                                                    <Card.Title>Payment</Card.Title>
                                                                                </Card.Header>
                                                                                <Card.Body>
                                                                                    <Form.Row className='align-items-center' style={{ marginTop: '30px' }}>
                                                                                        <Col md={12}>
                                                                                            {totalamt > 0 ?
                                                                                                <Form.Row>
                                                                                                    <Col md={2}>
                                                                                                        <Form.Group>
                                                                                                            <Form.Label>Payment Mode</Form.Label>
                                                                                                            <Form.Control as="select" ref={paymentmodedetail} onChange={(e) => { ChangePaymentMode(e.target.value) }} disabled={disablePayMode} >
                                                                                                                {<option value="0">----Select-----</option>}
                                                                                                                {paymentmode}
                                                                                                            </Form.Control>
                                                                                                        </Form.Group>
                                                                                                    </Col>
                                                                                                    {
                                                                                                        paymentModeId === "13" ?
                                                                                                            <Col md={2}>
                                                                                                                <Form.Group>
                                                                                                                    <Form.Label>Payment Channel</Form.Label>
                                                                                                                    <Form.Control as="select" ref={paymentChannelId} disabled={disablePayMode} onChange={(e) => SelectChannel(e)}>
                                                                                                                        {<option value="0">----Select-----</option>}
                                                                                                                        {UserPOSCode === "" ? null : <option value="-1">POS</option>}
                                                                                                                        {paymentChannels}
                                                                                                                    </Form.Control>
                                                                                                                </Form.Group>
                                                                                                            </Col>
                                                                                                            : null
                                                                                                    }
                                                                                                    {
                                                                                                        totalildiscount < 1 ?
                                                                                                            <>
                                                                                                                {
                                                                                                                    (paymentModeId === "14" || paymentModeId === "15") && paymentModeIdSetting !== "2" ?
                                                                                                                        <Col md={2}>
                                                                                                                            <Form.Group style={{ marginTop: 35, marginLeft: 20 }}>
                                                                                                                                <input className="checkbox_animated" id="inpercentage" type="checkbox" onClick={(e) => { handlePercentageCheckbox(e); }} /> In Percentage
                                                                                                                            </Form.Group>
                                                                                                                        </Col> :
                                                                                                                        null
                                                                                                                }
                                                                                                            </>
                                                                                                            :
                                                                                                            null
                                                                                                    }
                                                                                                    {Percentagecheckbox === "0" ?
                                                                                                        <>
                                                                                                            {
                                                                                                                totalildiscount > 0 && paymentModeId === "14" ?
                                                                                                                    <Col md={2}>
                                                                                                                        <Form.Group>
                                                                                                                            <Form.Label>Amount</Form.Label>
                                                                                                                            <Form.Control type="text" ref={paymentamountdetail} value={totalildiscount} disabled />
                                                                                                                        </Form.Group>
                                                                                                                    </Col>
                                                                                                                    :
                                                                                                                    <Col md={2}>
                                                                                                                        <Form.Group>
                                                                                                                            <Form.Label>Amount</Form.Label>
                                                                                                                            <Form.Control type="text" ref={paymentamountdetail} />
                                                                                                                        </Form.Group>
                                                                                                                    </Col>
                                                                                                            }
                                                                                                        </>
                                                                                                        :
                                                                                                        <Col md={2}>
                                                                                                            <Form.Group>
                                                                                                                <Form.Label>In Percentage Amount</Form.Label>
                                                                                                                <Form.Control type="text" ref={paymentamountdetail} />
                                                                                                            </Form.Group>
                                                                                                        </Col>
                                                                                                    }
                                                                                                    {paymentModeId === "13" ?
                                                                                                        <>
                                                                                                            {
                                                                                                                channelid === "0" ? null :
                                                                                                                    <>
                                                                                                                        {
                                                                                                                            channelid === "-1" || channelid == "8" ?
                                                                                                                                <Col md={3}>
                                                                                                                                    <Form.Group>
                                                                                                                                        <Form.Label>Reference No. <span className='text-red'>(Max 50 characters)</span></Form.Label>
                                                                                                                                        <Form.Control type="text" maxLength={50} ref={paymentReferenceNo} disabled />
                                                                                                                                    </Form.Group>
                                                                                                                                </Col> :
                                                                                                                                <Col md={3}>
                                                                                                                                    <Form.Group>
                                                                                                                                        <Form.Label>Reference No. <span className='text-red'>(Max 50 characters)</span></Form.Label>
                                                                                                                                        <Form.Control type="text" maxLength={50} ref={paymentReferenceNo} />
                                                                                                                                    </Form.Group>
                                                                                                                                </Col>
                                                                                                                        }
                                                                                                                    </>
                                                                                                            }
                                                                                                        </>
                                                                                                        : (paymentModeId === "14" || paymentModeId === "15") && paymentModeIdSetting !== "2" ?
                                                                                                            <>
                                                                                                                <Col md={2}>
                                                                                                                    <Form.Group>
                                                                                                                        <Form.Label>{paymentModeId === "14" ? "Discount Authorizer" : "Credit Authorizer"}</Form.Label>
                                                                                                                        <Form.Control as="select" ref={authorizerId} onChange={(e) => SetAuthorizerDetails(e)}>
                                                                                                                            {paymentModeId === "14" ? discountAuthorizers : creditAuthorizers}
                                                                                                                        </Form.Control>
                                                                                                                    </Form.Group>
                                                                                                                </Col>
                                                                                                                <Col md={3}>
                                                                                                                    <Form.Group>
                                                                                                                        <Form.Label>{paymentModeId === "14" ? "Discount" : "Credit"} Reason <span className='text-red'>(Max 100 characters allowed)</span></Form.Label>
                                                                                                                        <Form.Control type="text" ref={concessionReason} maxLength={100} />
                                                                                                                    </Form.Group>
                                                                                                                </Col>
                                                                                                            </>
                                                                                                            : null}
                                                                                                    {
                                                                                                        paymentModeId === "13" && channelid === "-1" ?
                                                                                                            <Col md={2}>
                                                                                                                <Form.Group>
                                                                                                                    <Form.Label>POS Machine</Form.Label>
                                                                                                                    <Form.Control as="select" ref={posMachineId} disabled={disablePayMode} >
                                                                                                                        {<option value="0">----Select-----</option>}
                                                                                                                        {posmachines}
                                                                                                                    </Form.Control>
                                                                                                                </Form.Group>
                                                                                                            </Col>
                                                                                                            : null
                                                                                                    }
                                                                                                    {hideAddPayButton ? null :
                                                                                                        <>
                                                                                                            {
                                                                                                                hideshowpaymentbutton === false ?
                                                                                                                    <>
                                                                                                                        <Col md={2} xs={11}>
                                                                                                                            <Form.Group className='m-t-15'>
                                                                                                                                <Form.Label className='icRight'>
                                                                                                                                    <Button variant='secondary' onClick={BindPaymentGrid}>Add</Button>
                                                                                                                                </Form.Label>
                                                                                                                            </Form.Group>
                                                                                                                        </Col>
                                                                                                                        {
                                                                                                                            channelid == "8" ?
                                                                                                                                <Col md={2} xs={11}>
                                                                                                                                    <Form.Group className='m-t-15'>
                                                                                                                                        <Form.Label className='icRight'>
                                                                                                                                            {isLoading ? <LoadingButton variantName='secondary' cssName="" /> :
                                                                                                                                                <Button variant='primary' onClick={CheckExistingRazorPayment}>Check razorpay pending status</Button>}
                                                                                                                                        </Form.Label>
                                                                                                                                    </Form.Group>
                                                                                                                                </Col> : null
                                                                                                                        }

                                                                                                                    </>
                                                                                                                    :
                                                                                                                    channelid == "8" ?
                                                                                                                        <Col md={2} xs={11}>
                                                                                                                            <Form.Group className='m-t-15'>
                                                                                                                                <Form.Label className='icRight'>
                                                                                                                                    {isLoading ? <LoadingButton variantName='secondary' cssName="" /> :
                                                                                                                                        <Button variant='secondary' onClick={CheckPaymentStatusRazor}>Check payment status</Button>}
                                                                                                                                </Form.Label>
                                                                                                                            </Form.Group>
                                                                                                                        </Col>
                                                                                                                        :
                                                                                                                        <Col md={2} xs={11}>
                                                                                                                            <Form.Group className='m-t-15'>
                                                                                                                                <Form.Label className='icRight'>
                                                                                                                                    {isLoading ? <LoadingButton variantName='secondary' cssName="" /> :
                                                                                                                                        <Button variant='secondary' onClick={CheckPaymentStatus}>Check payment status</Button>}
                                                                                                                                </Form.Label>
                                                                                                                            </Form.Group>
                                                                                                                        </Col>
                                                                                                            }
                                                                                                        </>
                                                                                                    }
                                                                                                </Form.Row>
                                                                                                : null}
                                                                                        </Col>
                                                                                        <Col md={12}>
                                                                                            <Form.Row>
                                                                                                <Col md={12} className="text-right">
                                                                                                    <Form.Label>Total Amount:</Form.Label>
                                                                                                    <Form.Label className='m-l-5 h5'><i class="fa fa-inr"></i>{totalamt}</Form.Label>
                                                                                                    <Form.Label className='m-l-15'>Discount Amount <span class="m-l-5 h5" name="discountamount" onChange={formik.handleChange}
                                                                                                        onBlur={formik.handleBlur} value={formik.values.discountamount}><i class="fa fa-inr"></i>{discamt}</span></Form.Label>
                                                                                                    <Form.Label className='m-l-15'>Credit Amount <span class="m-l-5 h5" name="creditamount" onChange={formik.handleChange}
                                                                                                        onBlur={formik.handleBlur} value={formik.values.creditamount}><i class="fa fa-inr"></i>{credamt}</span></Form.Label>
                                                                                                    <Form.Label className='m-l-15'>Paid Amount <span class="m-l-5 h5" name="paidamount" onChange={formik.handleChange}
                                                                                                        onBlur={formik.handleBlur} value={formik.values.paidamount}><i class="fa fa-inr"></i>{paidamt}</span></Form.Label>
                                                                                                    <Form.Label className='m-l-15'>Due Amount <span class="m-l-5 h5" name="dueamount" onChange={formik.handleChange}
                                                                                                        onBlur={formik.handleBlur} value={formik.values.dueamount}><i class="fa fa-inr"></i>{dueamt}</span></Form.Label>
                                                                                                </Col>
                                                                                            </Form.Row>
                                                                                        </Col>
                                                                                    </Form.Row>
                                                                                    <div className="table-responsive mt15">
                                                                                        <DataTables keyField='PaymentModeId' tableData={paymenttable} columns={paymentColumns} noDataIndication={NoRecordFounds} />
                                                                                    </div>
                                                                                </Card.Body>
                                                                            </Card>
                                                                        </Col>
                                                                    </> :
                                                                    null
                                                            }
                                                        </>
                                                }
                                            </Row>
                                            {disableBookings ? <Button variant='primary'>CREDIT LIMIT OVER FOR BOOKINGS</Button> :
                                                <>
                                                    {
                                                        parentcentreid === "0" ?
                                                            <>
                                                                <Row>
                                                                    <Col md={12} className='text-center'>
                                                                        {isButtonLoading ? <LoadingButton variantName='primary' cssName="" /> : <Button variant='primary' className="mr-2" type='submit' onClick={(e) => errorHandler("Save")}>Save</Button>}
                                                                        {
                                                                            edititemsflag === true ? null :
                                                                                <>
                                                                                    {isButtonLoading ? <LoadingButton variantName='secondary' cssName="" /> : <Button type='submit' variant='secondary' className="mr-2" onClick={(e) => errorHandler("Hold")}>Hold</Button>}</>
                                                                        }
                                                                        {
                                                                            edititemsflag === true ?
                                                                                <>
                                                                                    <Button variant='primary' className="mr-2" onClick={(e) => ConfirmCancelOrder()} >Cancel Order</Button>
                                                                                    <Button variant='secondary' className="mr-2" onClick={(e) => { e.preventDefault(); window.location.reload(false) }} >Refresh</Button>
                                                                                    <Button variant='primary' className="mr-2" onClick={(e) => PusherHandler("1")}>Customer Verification</Button>
                                                                                    <Button variant='secondary' onClick={(e) => PusherHandler("0")}>Clear Verification</Button>
                                                                                </>
                                                                                :
                                                                                <>
                                                                                    <Button variant='primary' className="mr-2" onClick={(e) => { e.preventDefault(); window.location.reload(false) }} >Refresh</Button>
                                                                                    <Button variant='secondary' className="mr-2" onClick={(e) => PusherHandler("1")}>Customer Verification</Button>
                                                                                    <Button variant='primary' onClick={(e) => PusherHandler("0")}>Clear Verification</Button>
                                                                                </>
                                                                        }
                                                                    </Col>
                                                                </Row>
                                                            </> :
                                                            <>
                                                                <Row>
                                                                    <Col md={12} className='text-center'>
                                                                        {isButtonLoading ? <LoadingButton variantName='primary' cssName="" /> : <Button variant='primary' className="mr-2" type='submit' onClick={(e) => errorHandler("Save")}>Save</Button>}
                                                                        <Button variant='secondary' className="mr-2" onClick={(e) => { e.preventDefault(); window.location.reload(false) }} >Refresh</Button>
                                                                        {
                                                                            edititemsflag === true ?
                                                                                <Button variant='primary' className="mr-2" onClick={(e) => ConfirmCancelOrder()} >Cancel Order</Button>
                                                                                :
                                                                                null
                                                                        }
                                                                    </Col>
                                                                </Row>
                                                            </>
                                                    }
                                                </>
                                            }
                                        </>
                                    }
                                </>
                                    : null
                                }
                            </>
                    }
                </Container>
            </Form >
            {
                errorModal &&
                <Modal isOpen={modal} toggle={toggleModal} centered={true}>
                    <ModalHeader toggle={toggleModal}>Errors</ModalHeader>
                    <ModalBody>
                        <p>{formik.touched.aadhar && formik.errors.aadhar ? (<div className='text-required'>{formik.errors.aadhar}</div>) : null}</p>
                        <p>{formik.touched.title && formik.errors.title ? (<div className='text-required'>{formik.errors.title}</div>) : null}</p>
                        <p>{formik.touched.patientname && formik.errors.patientname ? (<div className='text-required'>{formik.errors.patientname}</div>) : null}</p>
                        <p>{formik.touched.patientmobile && formik.errors.patientmobile ? (<div className='text-required'>{formik.errors.patientmobile}</div>) : null}</p>
                        <p>{formik.touched.dob && formik.errors.dob ? (<div className='text-required'>{formik.errors.dob}</div>) : null}</p>
                        <p>{formik.touched.age && formik.errors.age ? (<div className='text-required'>{formik.errors.age}</div>) : null}</p>
                        <p>{formik.touched.gender && formik.errors.gender ? (<div className='text-required'>{formik.errors.gender}</div>) : null}</p>
                        <p>{formik.touched.refdoctor && formik.errors.refdoctor ? (<div className='text-required'>{formik.errors.refdoctor}</div>) : null}</p>
                        <p>{formik.touched.emailid && formik.errors.emailid ? (<div className='text-required'>{formik.errors.emailid}</div>) : null}</p>
                        <p>{formik.touched.address && formik.errors.address ? (<div className='text-required'>{formik.errors.address}</div>) : null}</p>
                        {/* <p>{formik.touched.houseno && formik.errors.houseno ? (<div className='text-required'>{formik.errors.houseno}</div>) : null}</p> */}
                        <p>{formik.touched.pincode && formik.errors.pincode ? (<div className='text-required'>{formik.errors.pincode}</div>) : null}</p>
                        <p>{formik.touched.patienttype && formik.errors.patienttype ? (<div className='text-required'>{formik.errors.patienttype}</div>) : null}</p>
                        <p>{formik.touched.source && formik.errors.source ? (<div className='text-required'>{formik.errors.source}</div>) : null}</p>
                        {/* <p>{formik.touched.viasource && formik.errors.viasource ? (<div className='text-required'>{formik.errors.viasource}</div>) : null}</p> */}
                        <p>{formik.touched.dispatchmode && formik.errors.dispatchmode ? (<div className='text-required'>{formik.errors.dispatchmode}</div>) : null}</p>
                        <p>{formik.touched.ratetype && formik.errors.ratetype ? (<div className='text-required'>{formik.errors.ratetype}</div>) : null}</p>
                        <p>{formik.touched.corporate && formik.errors.corporate ? (<div className='text-required'>{formik.errors.corporate}</div>) : null}</p>
                        <p>{formik.touched.globaldocument && formik.errors.globaldocument ? (<div className='text-required'>{formik.errors.globaldocument}</div>) : null}</p>
                        <p>{formik.touched.b2b && formik.errors.b2b ? (<div className='text-required'>{formik.errors.b2b}</div>) : null}</p>
                        <p>{formik.touched.b2bmobile && formik.errors.b2bmobile ? (<div className='text-required'>{formik.errors.b2bmobile}</div>) : null}</p>
                        <p>{formik.touched.b2bemail && formik.errors.b2bemail ? (<div className='text-required'>{formik.errors.b2bemail}</div>) : null}</p>
                        <p>{formik.touched.ipdpattype && formik.errors.ipdpattype ? (<div className='text-required'>{formik.errors.ipdpattype}</div>) : null}</p>
                        <p>{formik.touched.government && formik.errors.government ? (<div className='text-required'>{formik.errors.government}</div>) : null}</p>
                        <p>{formik.touched.tpa && formik.errors.tpa ? (<div className='text-required'>{formik.errors.tpa}</div>) : null}</p>
                        <p>{formik.touched.company && formik.errors.company ? (<div className='text-required'>{formik.errors.company}</div>) : null}</p>
                        <p>{formik.touched.visittype && formik.errors.visittype ? (<div className='text-required'>{formik.errors.visittype}</div>) : null}</p>
                    </ModalBody>
                    <ModalFooter>
                        <Button variant="secondary" onClick={toggleModal}>Cancel</Button>
                        <Button variant="primary" onClick={toggleModal}>Ok</Button>
                    </ModalFooter>
                </Modal>
            }
            {
                invoiceModal &&
                <Modal isOpen={invoiceModal} toggle={invoiceHandler} centered={true} size="lg">
                    <ModalHeader toggle={invoiceHandler}>Invoice Details</ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col md={12}>
                                <h6>Patient Information</h6>
                                <Alert variant="dark" className="mt15 text-center">
                                    <ul className="list-inline pointsInline">
                                        <li className="list-inline-item">Name: <strong>{invoicedetails.PatientName}</strong></li>
                                        <li className="list-inline-item">UHID: <strong>{invoicedetails.PatientUHID}</strong></li>
                                        <li className="list-inline-item">Lab No: <strong>{invoicedetails.LabNo}</strong></li>
                                        <li className="list-inline-item">Mobile No: <strong>{invoicedetails.MobileNo}</strong></li>
                                        <li className="list-inline-item">Invoice No: <strong>{invoicedetails.InvoiceNo}</strong></li>
                                        <li className="list-inline-item">Invoice Date: <strong>{invoicedetails.InvoiceDate}</strong></li>
                                    </ul>
                                </Alert>
                            </Col>
                        </Row>
                        <Row className="mt15">
                            <Col md={12}>
                                <h6>Tests</h6>
                                <div className="table-responsive">
                                    <DataTables
                                        keyField='Item'
                                        tableData={invoicetable}
                                        columns={invoicecolumns}
                                        noDataIndication={NoRecordFounds}
                                    />
                                </div>
                            </Col>
                        </Row>
                        <Row className="justify-content-start mt15">
                            {invoicedetails.CashAmt != "0.00" ?
                                <Col md={3}>
                                    <div className="selling-update text-center">
                                        <div>Cash</div>
                                        <h5 className="mb-0 f-18"><i className="fa fa-inr"></i>{invoicedetails.CashAmt}</h5>
                                    </div>
                                </Col>
                                : null
                            }
                            {invoicedetails.DiscountAmount != "0.00" ?
                                <Col md={3}>
                                    <div className="selling-update text-center">
                                        <div>Discount</div>
                                        <h5 className="mb-0 f-18"><i className="fa fa-inr"></i>{invoicedetails.DiscountAmount}</h5>
                                    </div>
                                </Col>
                                : null
                            }

                            {invoicedetails.OnlinePayment != "0.00" ?
                                <Col md={3}>
                                    <div className="selling-update text-center">
                                        <div>Online</div>
                                        <h5 className="mb-0 f-18"><i className="fa fa-inr"></i>{invoicedetails.OnlinePayment}</h5>
                                    </div>
                                </Col>
                                : null
                            }

                            {invoicedetails.OutstandingAmount != "0.00" ?
                                <Col md={3}>
                                    <div className="selling-update text-center">
                                        <div>Outstanding</div>
                                        <h5 className="mb-0 f-18"><i className="fa fa-inr"></i>{invoicedetails.OutstandingAmount}</h5>
                                    </div>
                                </Col>
                                : null
                            }
                            {invoicedetails.CreditAmt != "0.00" ?
                                <Col md={3}>
                                    <div className="selling-update text-center">
                                        <div>Credit</div>
                                        <h5 className="mb-0 f-18"><i className="fa fa-inr"></i>{invoicedetails.CreditAmt}</h5>
                                    </div>
                                </Col>
                                : null
                            }
                            <Col md={3}>
                                <div className="selling-update text-center" style={{ backgroundColor: '#e9ecef' }}>
                                    <div><b>Total</b></div>
                                    <h5 className="mb-0 f-18"><i className="fa fa-inr"></i>{invoicedetails.TotalAmt}</h5>
                                </div>
                            </Col>
                        </Row>
                    </ModalBody>
                </Modal>
            }
            {
                viewpackageModal &&
                <Modal isOpen={viewpackageModal} toggle={openModal} centered={true} size="lg">
                    <ModalHeader toggle={openModal}>Items</ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col md={12}>
                                <Card>
                                    <Card.Body>
                                        <DataTables keyField='ItemSNo' tableData={viewpackageitems} columns={popupviewColumns} pagination={paginationFactory(popupPaginationOptions)} />
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </ModalBody>
                </Modal>
            }
            {
                viewpanelModal &&
                <Modal isOpen={viewpanelModal} toggle={openPanelModal} centered={true} size="lg">
                    <ModalHeader toggle={openPanelModal}>Items</ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col md={12}>
                                <Card>
                                    <Card.Body>
                                        <DataTables keyField='Id' tableData={viewpanelitems} columns={popupviewpanelColumns} pagination={paginationFactory(popupPaginationOptions)} />
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </ModalBody>
                </Modal>
            }
            {
                cityModal &&
                <Modal isOpen={cityModal} toggle={openCitiesModal} centered={true} size="lg">
                    <ModalHeader toggle={openCitiesModal}>Cities</ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col md={12}>
                                <Card>
                                    <Card.Body>
                                        <DataTables keyField='CityId' tableData={viewcities} columns={popupcityColumns} />
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </ModalBody>
                </Modal>
            }
            {
                viewreqfieldsModal &&
                <Modal isOpen={viewreqfieldsModal} toggle={openRequiredFieldModal} centered={true} size="lg">
                    <ModalHeader toggle={openRequiredFieldModal}>Required Fields</ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col md={12}>
                                <Card>
                                    <Card.Body>
                                        {
                                            chkBoxPrescription === 1 ?
                                                <Form.Row>
                                                    <Col md={4}>
                                                        <Form.Group>
                                                            <Form.Label>Prescription <span className='text-red'>*</span></Form.Label>
                                                            <div><input type="file" id='up_prescription' onChange={(e) => UploadPrescription(e)} /></div>
                                                            <div id='temp_prescription'></div>
                                                        </Form.Group>
                                                    </Col>
                                                </Form.Row>
                                                :
                                                null
                                        }
                                        <Form.Row>
                                            {
                                                viewreqfieldsitems != [] ?
                                                    viewreqfieldsitems.map((d) =>
                                                        <>
                                                            <Col md={6}>
                                                                <Form.Group>
                                                                    <div>{d.FieldName} <span className='text-red'>*</span></div>
                                                                </Form.Group>
                                                            </Col>
                                                            {
                                                                d.InputType.toLowerCase() === "textbox" ?
                                                                    <Col md={3}>
                                                                        <Form.Group>
                                                                            <Form.Control type="text" id={`text${d.FieldId}`} />
                                                                        </Form.Group>
                                                                    </Col> :
                                                                    d.InputType.toLowerCase() === "date" ?
                                                                        <Col md={3}>
                                                                            <Form.Group>
                                                                                <Form.Control type="date" id={`date${d.FieldId}`} />
                                                                            </Form.Group>
                                                                        </Col>
                                                                        :
                                                                        <Col md={3}>
                                                                            <Form.Group>
                                                                                <Form.Control as="select" id={`select${d.FieldId}`}>
                                                                                    {
                                                                                        d.itemsDetails.map((e) => <option key={e.DropDownOption} value={e.DropDownOption}>{e.DropDownOption}</option>)
                                                                                    }
                                                                                </Form.Control>
                                                                            </Form.Group>
                                                                        </Col>
                                                            }
                                                            {
                                                                d.IsUnit === "1" ?
                                                                    <Col md={3}>
                                                                        <Form.Group>
                                                                            <Form.Control as="select" id={`unitselect${d.FieldId}`}>
                                                                                {
                                                                                    d.itemsDetails.map((e) => <option key={e.Unit} value={e.Unit}>{e.Unit}</option>)
                                                                                }
                                                                            </Form.Control>
                                                                        </Form.Group>
                                                                    </Col> :
                                                                    null
                                                            }
                                                        </>
                                                    ) :
                                                    null
                                            }
                                        </Form.Row>
                                        <Form.Row>
                                            {
                                                viewprerequisiteitems != [] ?
                                                    viewprerequisiteitems.map((d) =>
                                                        <>
                                                            {d.PreRequisite === "" ? null :
                                                                <Col md={4}>
                                                                    <Form.Group>
                                                                        <Form.Label>{d.PreRequisite} <span className='text-red'>*</span></Form.Label>
                                                                        <div><input type="file" id={`prerequisite${d.PreRequisite}`} onChange={(e) => UploadPrerequisite(e, d.PreRequisite)} /></div>
                                                                        <div id={`temp${d.PreRequisite}`}></div>
                                                                        {/* <div><i id={`temp${d.PreRequisite}`} style={{ fontSize: 40, display: 'none' }} class="fa fa-file" aria-hidden="true"></i></div> */}
                                                                    </Form.Group>
                                                                </Col>
                                                            }
                                                        </>
                                                    ) :
                                                    null
                                            }
                                        </Form.Row>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12} className='text-center'>
                                <Button variant='secondary' className="mr-2" onClick={openRequiredFieldModal}>Cancel</Button>
                                {
                                    isButtonLoading ?
                                        <LoadingButton variantName='primary' cssName="" /> :
                                        <Button variant='primary' type='submit' onClick={(e) => SaveRequiredFields()}>Save</Button>
                                }
                            </Col>
                        </Row>
                    </ModalBody>
                </Modal>
            }
            <Modal isOpen={doctorModal} toggle={openDoctorModal} centered={true} size="lg">
                <ModalHeader toggle={openDoctorModal}>Add Doctor</ModalHeader>
                <ModalBody>
                    <Row>
                        <Col md={12}>
                            <Card>
                                <Card.Body>
                                    <Form.Row>
                                        <Col md={6}>
                                            <Form.Group>
                                                <Form.Label>Title <span className='text-red'>*</span></Form.Label>
                                                <Form.Control as="select" name="doctortitle" onChange={doctorformik.handleChange}
                                                    onBlur={doctorformik.handleBlur} value={doctorformik.values.doctortitle}>
                                                    <option value=''>---Select---</option>
                                                    {doctortitlelist}
                                                </Form.Control>
                                                {doctorformik.touched.doctortitle && doctorformik.errors.doctortitle ? (<div className='text-required'>{doctorformik.errors.doctortitle}</div>) : null}
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group>
                                                <Form.Label>First Name <span className='text-red'>*</span></Form.Label>
                                                <Form.Control type="text" name="docfirstname" onChange={doctorformik.handleChange} onBlur={doctorformik.handleBlur} value={doctorformik.values.docfirstname} />
                                                {doctorformik.touched.docfirstname && doctorformik.errors.docfirstname ? (<div className='text-required'>{doctorformik.errors.docfirstname}</div>) : null}
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group>
                                                <Form.Label>Middle Name</Form.Label>
                                                <Form.Control type="text" name="docmiddlename" onChange={doctorformik.handleChange} onBlur={doctorformik.handleBlur} value={doctorformik.values.docmiddlename} />
                                                {doctorformik.touched.docmiddlename && doctorformik.errors.docmiddlename ? (<div className='text-required'>{doctorformik.errors.docmiddlename}</div>) : null}
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group>
                                                <Form.Label>Last Name</Form.Label>
                                                <Form.Control type="text" name="doclastname" onChange={doctorformik.handleChange} onBlur={doctorformik.handleBlur} value={doctorformik.values.doclastname} />
                                                {doctorformik.touched.doclastname && doctorformik.errors.doclastname ? (<div className='text-required'>{doctorformik.errors.doclastname}</div>) : null}
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group>
                                                <Form.Label>Pin Code</Form.Label>
                                                <Form.Control type="text" name="doctorsappincode" onChange={doctorformik.handleChange} onBlur={doctorformik.handleBlur} value={doctorformik.values.doctorsappincode} onKeyUp={(e) => GetPincodeSAPLocations(e)} />
                                                {doctorformik.touched.doctorsappincode && doctorformik.errors.doctorsappincode ? (<div className='text-required'>{doctorformik.errors.doctorsappincode}</div>) : null}
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group>
                                                <Form.Label>SAP State</Form.Label>
                                                <Form.Control as="select" name="doctorsapstate" onChange={doctorformik.handleChange}
                                                    onBlur={doctorformik.handleBlur} value={doctorformik.values.doctorsapstate} onChangeCapture={SAPStateChangeHandler}>
                                                    <option value=''>---Select---</option>
                                                    {sapstatelist}
                                                </Form.Control>
                                                {doctorformik.touched.doctorsapstate && doctorformik.errors.doctorsapstate ? (<div className='text-required'>{doctorformik.errors.doctorsapstate}</div>) : null}
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group>
                                                <Form.Label>SAP Region</Form.Label>
                                                <Form.Control as="select" name="doctorsapregion" onChange={doctorformik.handleChange}
                                                    onBlur={doctorformik.handleBlur} value={doctorformik.values.doctorsapregion} onChangeCapture={SAPRegionChangeHandler}>
                                                    <option value=''>---Select---</option>
                                                    {sapregionlist}
                                                </Form.Control>
                                                {doctorformik.touched.doctorsapregion && doctorformik.errors.doctorsapregion ? (<div className='text-required'>{doctorformik.errors.doctorsapregion}</div>) : null}
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group>
                                                <Form.Label>SAP District</Form.Label>
                                                <Form.Control as="select" name="doctorsapdistrict" onChange={doctorformik.handleChange}
                                                    onBlur={doctorformik.handleBlur} value={doctorformik.values.doctorsapdistrict}>
                                                    <option value=''>---Select---</option>
                                                    {sapdistrictlist}
                                                </Form.Control>
                                                {doctorformik.touched.doctorsapdistrict && doctorformik.errors.doctorsapdistrict ? (<div className='text-required'>{doctorformik.errors.doctorsapdistrict}</div>) : null}
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group>
                                                <Form.Label>Specialization</Form.Label>
                                                <Form.Control as="select" name="doctorspecialization" onChange={doctorformik.handleChange}
                                                    onBlur={doctorformik.handleBlur} value={doctorformik.values.doctorspecialization}>
                                                    <option value=''>---Select---</option>
                                                    {specializationlist}
                                                </Form.Control>
                                                {doctorformik.touched.doctorspecialization && doctorformik.errors.doctorspecialization ? (<div className='text-required'>{doctorformik.errors.doctorspecialization}</div>) : null}
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group>
                                                <Form.Label>Clinic Phone</Form.Label>
                                                <Form.Control type="text" name="docclinicno" onChange={doctorformik.handleChange} onBlur={doctorformik.handleBlur} value={doctorformik.values.docclinicno} />
                                                {doctorformik.touched.docclinicno && doctorformik.errors.docclinicno ? (<div className='text-required'>{doctorformik.errors.docclinicno}</div>) : null}
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group>
                                                <Form.Label>Mobile Phone</Form.Label>
                                                <Form.Control type="text" name="docmobileno" onChange={doctorformik.handleChange} onBlur={doctorformik.handleBlur} value={doctorformik.values.docmobileno} />
                                                {doctorformik.touched.docmobileno && doctorformik.errors.docmobileno ? (<div className='text-required'>{doctorformik.errors.docmobileno}</div>) : null}
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group>
                                                <Form.Label>Landmark <span className='text-red'>(Max 500 characters allowed)</span></Form.Label>
                                                <Form.Control type="text" name="doclandmark" max={500} onChange={doctorformik.handleChange} onBlur={doctorformik.handleBlur} value={doctorformik.values.doclandmark} />
                                                {doctorformik.touched.doclandmark && doctorformik.errors.doclandmark ? (<div className='text-required'>{doctorformik.errors.doclandmark}</div>) : null}
                                            </Form.Group>
                                        </Col>
                                        {/* <Col md={12}>
                                            <Form.Group>
                                                <Form.Label>Doctor Name <span className='text-red'>* limit 100 characters only</span></Form.Label>
                                                <Typeahead
                                                    id="refadddoc-typeahead"
                                                    labelKey="DoctorName"
                                                    multiple={multiple}
                                                    options={refdocoptions}
                                                    placeholder="Add a referring doctor..."
                                                    onInputChange={(text, e) => { getaddreferringdoctorlist(e, text, 0); }}
                                                    ref={cleardoctor}
                                                    onChange={(text) => { JSON.stringify(text) == "[]" ? setRefDocAddName("") : setRefDocAddName(text[0].DoctorName); }}
                                                />
                                            </Form.Group>
                                        </Col> */}
                                    </Form.Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>


                    <Row>
                        <Col md={12} className='text-center'>
                            <Button variant='secondary' className="mr-2" onClick={openDoctorModal}>Cancel</Button>
                            <Button variant='primary' type='submit' onClick={doctorformik.handleSubmit}>Save</Button>
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>
            <Modal isOpen={isPrintConsent} toggle={openConsentModal} centered={true} size="lg">
                <ModalHeader toggle={openConsentModal}>Consent Form</ModalHeader>
                <ModalBody>
                    <Form.Row>
                        <Col md={12}>
                            <ExportPdfComponent formName={consentFormName} PName={formik.values.patientname} Age={formik.values.age}
                                Gender={formik.values.gender} RefDoctor={refDoctorName !== "" && refdocname.length !== "0" ? refdocname : refDoctorName} />
                        </Col>
                    </Form.Row>
                </ModalBody>
            </Modal>
            <Modal isOpen={demographicModal} toggle={openDemographicModal} centered={true} size="lg">
                <ModalHeader toggle={openDemographicModal}>Edit Demographic</ModalHeader>
                <ModalBody>
                    <Row>
                        <Col md={12}>
                            <Card>
                                <Card.Body style={{ paddingTop: '0px' }}>
                                    <Form.Row>
                                        <Col md={6}>
                                            <Form.Group>
                                                <Form.Label>Patient Mobile <span className='text-red'>*</span></Form.Label>
                                                <Form.Control type="text" id="editpatientmobile" name="editpatientmobile" value={editformik.values.editpatientmobile} onChange={editformik.handleChange}
                                                    onBlur={editformik.handleBlur} />
                                                {editformik.touched.editpatientmobile && editformik.errors.editpatientmobile ? (
                                                    <div className='text-required'>{editformik.errors.editpatientmobile}</div>
                                                ) : null}
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group>
                                                <Form.Label>Title<span className='text-red'>*</span></Form.Label>
                                                <Form.Control as="select" name="edittitle" value={editformik.values.edittitle} onChange={editformik.handleChange}
                                                    onBlur={editformik.handleBlur} onChangeCapture={(e) => ChangeGenderDemographic(e)}>
                                                    <option value="Mr.">Mr.</option>
                                                    <option value="Mrs.">Mrs.</option>
                                                    <option value="Miss.">Miss.</option>
                                                    <option value="Baby.">Baby.</option>
                                                    <option value="Baba.">Baba.</option>
                                                    <option value="Master.">Master.</option>
                                                    <option value="Dr.">Dr.</option>
                                                    <option value="B/O.">B/O.</option>
                                                    <option value="Ms.">Ms.</option>
                                                    <option value="C/O.">C/O.</option>
                                                    <option value="Mx.">Mx.</option>
                                                </Form.Control>
                                                {editformik.touched.edittitle && editformik.errors.edittitle ? (
                                                    <div className='text-required'>{editformik.errors.edittitle}</div>
                                                ) : null}
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group>
                                                <Form.Label>Patient Name <span className='text-red'>*</span></Form.Label>
                                                <Form.Control type="text" name="editpatientname" ref={patientname}
                                                    onChange={editformik.handleChange}
                                                    onBlur={editformik.handleBlur}
                                                    value={editformik.values.editpatientname}
                                                // onBlurCapture={CheckIfPatientExist}
                                                />
                                                {editformik.touched.editpatientname && editformik.errors.editpatientname ? (
                                                    <div className='text-required'>{editformik.errors.editpatientname}</div>
                                                ) : null}
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group>
                                                <Form.Label>Gender <span className='text-red'>*</span></Form.Label>
                                                <Form.Control as="select" name="editgender" value={editformik.values.editgender} onChange={editformik.handleChange}
                                                    onBlur={editformik.handleBlur} disabled={editdisablefields.editgender}>
                                                    <option value="">Select</option>
                                                    {genders}
                                                </Form.Control>
                                                {editformik.touched.editgender && editformik.errors.editgender ? (
                                                    <div className='text-required'>{editformik.errors.editgender}</div>
                                                ) : null}
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group>
                                                <Form.Label>DOB (DD-MM-YYYY)<span className='text-red'>*</span></Form.Label>
                                                <Form.Control type="text" ref={editdateobirth} name="editdob" value={editformik.values.editdob} onChange={editformik.handleChange}
                                                    onBlur={editformik.handleBlur} disabled={editdobtext} onKeyUp={(e) => CallEditDob(e)} />
                                                {editformik.touched.editdob && editformik.errors.editdob ? (
                                                    <div className='text-required'>{editformik.errors.editdob}</div>
                                                ) : null}
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group>
                                                <Form.Label>Year<span className='text-red'>*</span></Form.Label>
                                                <Form.Control type="tel" ref={edityear} name="edityear" disabled={editagetext} onKeyPress={(e) => CallValidationCheck(e)} onKeyUp={(e) => CallEditAllFunctions(e)} />
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group>
                                                <Form.Label>Month<span className='text-red'>*</span></Form.Label>
                                                <Form.Control type="tel" ref={editmonth} name="editmonth" disabled={editagetext} onKeyPress={(e) => CallValidationCheck(e)} onKeyUp={(e) => CallEditAllFunctions(e)} />
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group>
                                                <Form.Label>Day<span className='text-red'>*</span></Form.Label>
                                                <Form.Control type="tel" ref={editday} name="editday" disabled={editagetext} onKeyPress={(e) => CallValidationCheck(e)} onKeyUp={(e) => CallEditAllFunctions(e)} />
                                            </Form.Group>
                                        </Col>
                                    </Form.Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12} className='text-center'>
                            <Button variant='secondary' className="mr-2" onClick={openDemographicModal}>Cancel</Button>
                            {isButtonLoading ? <LoadingButton variantName='primary' cssName="" /> : <Button variant='primary' type='submit' onClick={editformik.handleSubmit}>Save</Button>}
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>
            {
                viewremarks == [] || viewremarks == null || viewremarks == undefined || viewremarks.length == 0 ? null :
                    <Modal isOpen={remarksModal} toggle={openRemarksModal} centered={true} size="lg">
                        <ModalHeader toggle={openRemarksModal}>View Remarks</ModalHeader>
                        <ModalBody className='modalvariation'>
                            {
                                viewremarks.map((d) =>
                                    <Row>
                                        <Col md={12}>
                                            <Card className='routeCard'>
                                                <Card.Header>
                                                    <Row className='align-items-center'>
                                                        <Col xs={6}>
                                                            <h3 className='routeSN'>{d.IsLISBSLFlag}</h3>
                                                        </Col>
                                                    </Row>
                                                </Card.Header>
                                                <Card.Body>
                                                    <Row>
                                                        <Col xs={12}>
                                                            <p><b>Remarks:</b> {d.Remarks}</p>
                                                            <p><b>DateTime:</b> {d.RemarksDateTime}</p>
                                                        </Col>
                                                    </Row>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    </Row>
                                )
                            }
                        </ModalBody>
                    </Modal>
            }
            {
                pdfreceipt &&
                <div style={{ marginTop: '500px' }}>
                    <PrintPDFReceipt />
                </div>
            }
        </>
    )
}

export default Registration;
