import React from "react";
import { Container, Row, Col, Card, Form, Button, Table } from 'react-bootstrap';

class RequestFormMaternal extends React.Component {

  render() {
    return (
      <>
        <Container fluid>
          <Row>
            <Col md={12}>
              <Card>
                <Card.Body>
                  <Form>
                    <Form.Row>
                      <Col md={2}>
                        <Form.Group>
                          <Form.Label>Patient's Name:</Form.Label>
                          
                        </Form.Group>
                      </Col>
                      <Col md={3}>
                        <Form.Group>
                          <Form.Label>Name:</Form.Label>
                          <Form.Control type='text' />
                        </Form.Group>
                      </Col>
                      <Col md={2}>
                        <Form.Group>
                          <Form.Label>Status</Form.Label>
                          <Form.Control as="select" id="sample" name="sample" >
                            <option value="-1" selected>All</option>
                            <option value="0">Not Collected</option>
                            <option value="1">Partial Collected</option>
                            <option value="2">Collected</option>
                            {/* <option value="3">Partial Rejected</option>
                                                    <option value="4">Rejected</option> */}
                          </Form.Control>
                        </Form.Group>
                      </Col>
                      <Col md={2}>
                        <Form.Group>
                          <Form.Label>Date From</Form.Label>
                          <Form.Control type="date" id="date1" />
                        </Form.Group>
                      </Col>
                      <Col md={2}>
                        <Form.Group>
                          <Form.Label>Date To</Form.Label>
                          <Form.Control type="date" id="date2"/>
                        </Form.Group>
                      </Col>
                    </Form.Row>
                  </Form>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
        <div>Maternal Screen Request Form</div>
        <table className="table">
          <thead>
            <tr>
              <th>Firstname</th>
              <th>Lastname</th>
              <th>Email</th>
              <th>Image</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>John</td>
              <td>Doe</td>
              <td>john@example.com</td>
              <td>
                <img src="https://i.pravatar.cc/50?img=1" alt="thumb" />
              </td>
            </tr>
            <tr>
              <td>Mary</td>
              <td>Moe</td>
              <td>mary@example.com</td>
              <td>
                <img src="https://i.pravatar.cc/50?img=2" alt="thumb" />
              </td>
            </tr>
            <tr>
              <td>July</td>
              <td>Dooley</td>
              <td>july@example.com</td>
              <td>
                <img src="https://i.pravatar.cc/50?img=3" alt="thumb" />
              </td>
            </tr>
          </tbody>
        </table>
      </>
    );
  }
}

export default RequestFormMaternal;