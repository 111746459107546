import React, { useEffect, useState, useRef } from 'react';
import { Container, Row, Col, Card, Form, Button, Table } from 'react-bootstrap';
import Breadcrumb from '../../components/common/breadcrumb';
import DataTables from '../commonComponent/DataTable/DataTables';
import SweetAlert from 'sweetalert2';
import { FrontDesk_URL } from '../../constant';
import axios from 'axios';
import { Spinner } from 'react-bootstrap';
import Alert from "react-bootstrap/Alert";
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import PrintPDF from '../commonComponent/PrintPDF';
import EditToolTip from '../UI/ToolTips/EditToolTip';
import { CSVLink } from "react-csv";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import InputGroup from 'react-bootstrap/InputGroup';
const UserwiseCollections = () => {
    let fromDate = useRef();
    let toDate = useRef();
    let reportsuser = useRef();
    const [isLoading, setIsLoading] = useState(false);
    const [apiData, setAPIData] = useState();
    const [defaultDate, setDefaultDate] = useState("");
    const [userName, setUserName] = useState("");
    const [totalSale, setTotalSale] = useState("0");
    const [cashSale, setCashSale] = useState("0");
    const [paytmSale, setPaytmSale] = useState("0");
    const [bharatPaySale, setBharatPaySale] = useState("0");
    const [phonePeSale, setPhonePeSale] = useState("0");
    const [creditCardSale, setCreditCardSale] = useState("0");
    const [debitCardSale, setDebitCardSale] = useState("0");
    const [gPaySale, setGPaySale] = useState("0");
    const [onlineSale, setOnlineSale] = useState("0");
    const [posSale, setPOSSale] = useState("0");
    const [razorpaySale, setRazorPaySale] = useState("0");
    const [cashTable, setCashTable] = useState([]);
    const [paytmTable, setPaytmTable] = useState([]);
    const [bharatPayTable, setBharatPayTable] = useState([]);
    const [phonePeTable, setPhonePeTable] = useState([]);
    const [creditCardTable, setCreditCardTable] = useState([]);
    const [debitCardTable, setDebitCardTable] = useState([]);
    const [gPayTable, setGPayTable] = useState([]);
    const [onlineTable, setOnlineTable] = useState([]);
    const [isPrintReport, setIsPrintReport] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [visitingid, setVisitingId] = useState("");
    const openReportModal = () => { setIsPrintReport(!isPrintReport); }
    const openEditModal = () => { setIsEdit(!isEdit); }
    const [displayPrintReport, setDisplayPrintReport] = useState(false);
    const [downloadvisible, setDownloadVisible] = useState(false);
    const [edittabledata, setEditTableData] = useState([]);
    const [users, setUsers] = useState([]);
    const [posTable, setPOSTable] = useState([]);
    const [razorpayTable, setRazorPayTable] = useState([]);
    useEffect(() => {
        const today = new Date();
        const date = today.setDate(today.getDate());
        const defaultValue = new Date(date).toISOString().split('T')[0] // yyyy-mm-dd
        setDefaultDate(defaultValue);
        BindGrid(defaultValue, defaultValue);
    }, [])

   
    const columns = [
        { dataField: 'ReceiptNo', text: '#', editable: false },
        { dataField: 'ReceiptDate', text: 'Receipt Date', editable: false },
        { dataField: 'BillNo', text: 'Bill No', editable: false },
        { dataField: 'BillDate', text: 'Bill Date', editable: false },
        { dataField: 'VisitingCode', text: 'Visiting Id', editable: false },
        { dataField: 'PatientName', text: 'Patient Name', editable: false },
        { dataField: 'PaidAmount', text: 'Paid Amount', editable: false },
        { dataField: 'ISRefund', text: 'IS Refund', editable: false }
    ];
    const handleSearch = async () => {
        BindGrid(fromDate.current.value, fromDate.current.value);
    }
    const BindGrid = async (fromDateSelected, toDateSelected,PageNo,PageSize) => {
        const  ObjServiceCollection= {
            CentreId:localStorage.getItem('CentreSNo'),
            FromDate:fromDateSelected,
            ToDate:toDateSelected,
            PageNo:`${PageNo}`,
            PageSize:`${PageSize}`,
            UserId:localStorage.getItem('LoggedInUser'),
            IsExcelD:`${0}`
        };      
        setIsLoading(true);
        setDisplayPrintReport(false);

        let url = FrontDesk_URL + 'reports/getUsercollectionbyCentrereport'
        await axios.post(url,ObjServiceCollection).then((response) => {
            const data = response.data;
            if (data.Success) {
                var apiData = data.Data;
                if (apiData.length > 0) {
                    var curntUserData = apiData[0];
                    setAPIData(curntUserData);
                    setUserName(curntUserData.UserName);
                    setTotalSale(curntUserData.Total);
                    setCashSale(curntUserData.Cash);
                    setPaytmSale(curntUserData.Paytm);
                    setBharatPaySale(curntUserData.BharatPay);
                    setPhonePeSale(curntUserData.PhonePe);
                    setCreditCardSale(curntUserData.CreditCard);
                    setDebitCardSale(curntUserData.DebitCard);
                    setGPaySale(curntUserData.GPay);
                    setOnlineSale(curntUserData.Online);
                    setCashTable(curntUserData.CashCollectionList);
                    setPaytmTable(curntUserData.PaytmCollectionList);
                    setBharatPayTable(curntUserData.BharatPayCollectionList);
                    setPhonePeTable(curntUserData.PhonePeCollectionList);
                    setCreditCardTable(curntUserData.CreditCardCollectionList);
                    setDebitCardTable(curntUserData.DebitCardCollectionList);
                    setGPayTable(curntUserData.GPayCollectionList);
                    setOnlineTable(curntUserData.OnlineCollectionList);
                    setPOSSale(curntUserData.POS);
                    setRazorPaySale(curntUserData.RazorPay);
                    setPOSTable(curntUserData.POSCollectionList);
                    setRazorPayTable(curntUserData.RazorPayCollectionList);
                    setDisplayPrintReport(true);
                } else { setCashTable([]); setPaytmTable([]); setBharatPayTable([]); setPhonePeTable([]); setCreditCardTable([]); setDebitCardTable([]); setGPayTable([]);setPOSTable([]);setRazorPayTable([]); }
                setIsLoading(false);
            }
            else { SweetAlert.fire({ title: "Application Exception", text: "Some error occured! Please try after sometime", icon: "error" }); setIsLoading(false); }
        }).catch(function (error) { if (error.response) { ErrorFunction(error.response.status); } setIsLoading(false); });
    }
    const OpenReport = () => {
        setIsPrintReport(true);
    }
    useEffect(() => {
        if (downloadvisible === true) {
            document.getElementById("csvdownload").click();
        }
    }, [downloadvisible])

    const OpenCSV = () => {
        setDownloadVisible(true);
    }
    const ErrorFunction = async (ErrorCode) => {
        if (ErrorCode == "400") { SweetAlert.fire({ title: "Error 400", text: "Bad request", icon: "error" }); }
        else if (ErrorCode == "401") { SweetAlert.fire({ title: "Error 401", text: "Unauthorized", icon: "error" }); }
        else if (ErrorCode == "403") { SweetAlert.fire({ title: "Error 403", text: "Forbidden", icon: "error" }); }
        else if (ErrorCode == "404") { SweetAlert.fire({ title: "Error 404", text: "Page not found", icon: "error" }); }
        else if (ErrorCode == "500") { SweetAlert.fire({ title: "Error 500", text: "Internal Server Error", icon: "error" }); }
        else if (ErrorCode == "503") { SweetAlert.fire({ title: "Error 503", text: "Service Unavailable", icon: "error" }); }
    }
    return (
        <>
            <Breadcrumb title="Customer Collections" parent="Reports" />
            <Container fluid>
                <Row>
                    <Col md={12}>
                        <Card>
                            <Card.Body>
                                <Form>
                                    <Form.Row className='justify-content-center mt-4'>
                                    {/* <Col md={3}>
                                        <Form.Group>
                                            <Form.Label>From Date</Form.Label>
                                            <InputGroup className="mb-3 d-flex justify-content-start">
                                                <Form.Control type="date" id="fromDate" name="fromDate" ref={fromDate} defaultValue={defaultDate} />
                                            </InputGroup>
                                        </Form.Group>
                                    </Col> */}
                                    <Col md={3}>
                                        <Form.Group>
                                            <Form.Label>Select Date</Form.Label>
                                            <InputGroup className="mb-3 d-flex justify-content-start">
                                                <Form.Control type="date" id="fromDate" name="fromDate" ref={fromDate} defaultValue={defaultDate} />
                                            </InputGroup>
                                        </Form.Group>
                                    </Col>
                                    <Col md={4} className='topmargin30'>
                                        <Button variant='btn btn-primary' onClick={handleSearch}>Search</Button>
                                      
                                    </Col>
                                       
                                    </Form.Row>
                                </Form>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <Card>
                            <Card.Body>
                                <Form>
                                    <Form.Row>
                                        <Col md={12} className="summaryHeadingAlertSec">
                                            <h6 className='text-red'>Summary</h6>
                                            <Alert variant="success" className="text-center">
                                                <ul className="list-inline pointsInline">
                                                    <li className="list-inline-item">Name: <strong>{userName}</strong></li>
                                                    <li className="list-inline-item">Total Sale: <strong>{totalSale}</strong></li>
                                                    <li className="list-inline-item">Cash: <strong>{cashSale}</strong></li>
                                                    <li className="list-inline-item">Paytm: <strong>{paytmSale}</strong></li>
                                                    <li className="list-inline-item">Bharat Pay: <strong>{bharatPaySale}</strong></li>
                                                    <li className="list-inline-item">Axis Soundbox: <strong>{phonePeSale}</strong></li>
                                                    <li className="list-inline-item">Credit Card: <strong>{creditCardSale}</strong></li>
                                                    <li className="list-inline-item">Debit Card: <strong>{debitCardSale}</strong></li>
                                                    <li className="list-inline-item">GPay: <strong>{gPaySale}</strong></li>
                                                    <li className="list-inline-item">Online: <strong>{onlineSale}</strong></li>
                                                    <li className="list-inline-item">POS: <strong>{posSale}</strong></li>
                                                    <li className="list-inline-item">Razor Pay: <strong>{razorpaySale}</strong></li>
                                                </ul>
                                            </Alert>
                                        </Col>
                                    </Form.Row>
                                    {cashTable.length > 0 ?
                                        <Form.Row className='mt15 summaryTableClass'>
                                            <Col md={12}>
                                                <h6 className='text-red'>Cash Details</h6>
                                                {
                                                    isLoading ?
                                                        <div className='text-center'> <Spinner animation="border" size='sm' /></div> :
                                                        <>
                                                            <DataTables keyField='id' tableData={cashTable} columns={columns} />
                                                        </>
                                                }
                                            </Col>
                                        </Form.Row>
                                        : null}
                                    {paytmTable.length > 0 ?
                                        <Form.Row className='mt15 summaryTableClass'>
                                            <Col md={12}>
                                                <h6 className='text-red'>Paytm Details</h6>
                                                {
                                                    isLoading ?
                                                        <div className='text-center'> <Spinner animation="border" size='sm' /></div> :
                                                        <>
                                                            <DataTables keyField='id' tableData={paytmTable} columns={columns} />
                                                        </>
                                                }
                                            </Col>
                                        </Form.Row>
                                        : null}
                                    {bharatPayTable.length > 0 ?
                                        <Form.Row className='mt15 summaryTableClass'>
                                            <Col md={12}>
                                                <h6 className='text-red'>Bharat Pay Details</h6>
                                                {
                                                    isLoading ?
                                                        <div className='text-center'> <Spinner animation="border" size='sm' /></div> :
                                                        <>
                                                            <DataTables keyField='id' tableData={bharatPayTable} columns={columns} />
                                                        </>
                                                }
                                            </Col>
                                        </Form.Row>
                                        : null}
                                    {phonePeTable.length > 0 ?
                                        <Form.Row className='mt15 summaryTableClass'>
                                            <Col md={12}>
                                                <h6 className='text-red'>Axis Soundbox Details</h6>
                                                {
                                                    isLoading ?
                                                        <div className='text-center'> <Spinner animation="border" size='sm' /></div> :
                                                        <>
                                                            <DataTables keyField='id' tableData={phonePeTable} columns={columns} />
                                                        </>
                                                }
                                            </Col>
                                        </Form.Row>
                                        : null}
                                    {creditCardTable.length > 0 ?
                                        <Form.Row className='mt15 summaryTableClass'>
                                            <Col md={12}>
                                                <h6 className='text-red'>Credit Card Details</h6>
                                                {
                                                    isLoading ?
                                                        <div className='text-center'> <Spinner animation="border" size='sm' /></div> :
                                                        <>
                                                            <DataTables keyField='id' tableData={creditCardTable} columns={columns} />
                                                        </>
                                                }
                                            </Col>
                                        </Form.Row>
                                        : null}
                                    {debitCardTable.length > 0 ?
                                        <Form.Row className='mt15 summaryTableClass'>
                                            <Col md={12}>
                                                <h6 className='text-red'>Debit Card Details</h6>
                                                {
                                                    isLoading ?
                                                        <div className='text-center'> <Spinner animation="border" size='sm' /></div> :
                                                        <>
                                                            <DataTables keyField='id' tableData={debitCardTable} columns={columns} />
                                                        </>
                                                }
                                            </Col>
                                        </Form.Row>
                                        : null}
                                    {gPayTable.length > 0 ?
                                        <Form.Row className='mt15 summaryTableClass'>
                                            <Col md={12}>
                                                <h6 className='text-red'>GPay Details</h6>
                                                {
                                                    isLoading ?
                                                        <div className='text-center'> <Spinner animation="border" size='sm' /></div> :
                                                        <>
                                                            <DataTables keyField='id' tableData={gPayTable} columns={columns} />
                                                        </>
                                                }
                                            </Col>
                                        </Form.Row>
                                        : null}
                                        {onlineTable.length > 0 ?
                                        <Form.Row className='mt15 summaryTableClass'>
                                            <Col md={12}>
                                                <h6 className='text-red'>Online Details</h6>
                                                {
                                                    isLoading ?
                                                        <div className='text-center'> <Spinner animation="border" size='sm' /></div> :
                                                        <>
                                                            <DataTables keyField='id' tableData={onlineTable} columns={columns} />
                                                        </>
                                                }
                                            </Col>
                                        </Form.Row>
                                        : null}
                                        {posTable.length > 0 ?
                                        <Form.Row className='mt15 summaryTableClass'>
                                            <Col md={12}>
                                                <h6 className='text-red'>POS Details</h6>
                                                {
                                                    isLoading ?
                                                        <div className='text-center'> <Spinner animation="border" size='sm' /></div> :
                                                        <>
                                                            <DataTables keyField='id' tableData={posTable} columns={columns} />
                                                        </>
                                                }
                                            </Col>
                                        </Form.Row>
                                        : null}
                                        {razorpayTable.length > 0 ?
                                        <Form.Row className='mt15 summaryTableClass'>
                                            <Col md={12}>
                                                <h6 className='text-red'>RazorPay Details</h6>
                                                {
                                                    isLoading ?
                                                        <div className='text-center'> <Spinner animation="border" size='sm' /></div> :
                                                        <>
                                                            <DataTables keyField='id' tableData={razorpayTable} columns={columns} />
                                                        </>
                                                }
                                            </Col>
                                        </Form.Row>
                                        : null}
                                    <Form.Row className='mt15'>
                                        <Col md={12} className='text-center'>
                                            {displayPrintReport ?
                                                <>
                                                    <Button variant='primary' className="mr-2" onClick={(e) => OpenReport()}>Print Preview</Button>
                                                </>
                                                : null}
                                        </Col>
                                    </Form.Row>
                                </Form>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <Modal isOpen={isPrintReport} toggle={openReportModal} centered={true} className="printReportContainerModel">
                <ModalHeader toggle={openReportModal}>Print Report</ModalHeader>
                <ModalBody>
                    <Form.Row>
                        <Col md={12} className="PrintDayEndReport">
                            <PrintPDF ReportName="CustomerCollection" APIData={apiData} />
                        </Col>
                    </Form.Row>
                </ModalBody>
            </Modal>
        </>
    )
}

export default UserwiseCollections