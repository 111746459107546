import React, { useEffect, useState, useRef } from 'react';
import { Container, Row, Col, Card, Form, Button } from 'react-bootstrap';
import Breadcrumb from '../../components/common/breadcrumb';
import DataTables from '../commonComponent/DataTable/DataTables';
import { FrontDesk_URL } from '../../constant';
import axios from 'axios';
import SweetAlert from 'sweetalert2';
import { Spinner } from 'react-bootstrap';
import NoRecordFounds from '../commonComponent/DataTable/NoRecordFounds';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { CSVLink } from 'react-csv';


const LedgersNew = () => {
    let fromDate = useRef();
    let toDate = useRef();
    const [isLoading, setIsLoading] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [crntPageNO, setCrntPageNO] = useState(0);
    const [isLedgerExist, setIsLedgerExist] = useState(false);
    const [csvLedger, setCSVLedger] = useState([]);
    const [downloadvisible, setDownloadVisible] = useState(false);
    const [defaultDate, setDefaultDate] = useState("");

    const paginationOptions = { sizePerPage: 50, showTotal: false, firstPageText: 'First', prePageText: 'Back', nextPageText: 'Next', lastPageText: 'Last', onPageChange: (page, sizePerPage) => { setCrntPageNO(((page - 1) * sizePerPage)); } };
    const idFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                <span>{crntPageNO + rowIndex + 1}</span>
            </div>
        </React.Fragment>
    }
   

    const columns = [
        { dataField: '#', text: '#', editable: false, formatter: idFormatter ,  headerStyle: { width: '5%', fontSize: 7 }, style:{width: '5%', fontSize: 10 }  },
        //{ dataField: '#', text: '#', editable: false, formatter: idFormatter, headerStyle: (colum, colIndex) => { return { width: '5%', fontSize: 7  }; } },
        { dataField: 'Date', text: 'Date', editable: false,  headerStyle: { fontSize: 7 }, style:{ fontSize: 10 } },
        { dataField: 'Narration', text: 'Narration', editable: false,headerStyle: { fontSize: 7 }, style:{ fontSize: 10 } },
        { dataField: 'OrderNo', text: 'Order No', editable: false ,  headerStyle: { fontSize: 7 }, style:{ fontSize: 10 }},
        {
            dataField: 'BillNo',
            text: 'Bill No',
            editable: false,
            formatter: (cell, row) => {
                return (
                    <span
                        onClick={() => Printreceipt(row['VistingID'])}
                        style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }}
                    >
                        {cell}
                    </span>
                );
            },
            headerStyle: { fontSize: 7 },
            style: { fontSize: 10 }
        },
       //{ dataField: 'BillNo', text: 'Bill No', editable: false, headerStyle: { fontSize: 7 }, style:{ fontSize: 10 }},
        //{ dataField: 'Amount', text: 'Amount', editable: false, headerStyle: { fontSize: 7 }, style:{ fontSize: 10 }},
        { dataField: 'PP', text: 'Amount', editable: false, headerStyle: { fontSize: 7 }, style:{ fontSize: 10 }},
        { dataField: 'Discount', text: 'Discount', editable: false, headerStyle: { fontSize: 7 }, style:{ fontSize: 10 }},
        { dataField: 'Net', text: 'Net', editable: false, headerStyle: { fontSize: 7 }, style:{ fontSize: 10 }},
        { dataField: 'Billing_Cr', text: 'Billing Cr', editable: false,headerStyle: { fontSize: 7 }, style:{ fontSize: 10 }},
        { dataField: 'Billing_Bal', text: 'Billing Bal', editable: false, headerStyle: { fontSize: 7 }, style:{ fontSize: 10 }},
       // { dataField: 'Customer_Cr', text: 'Customer Cr', editable: false,headerStyle: { fontSize: 7 }, style:{ fontSize: 10 }},
       // { dataField: 'Customer_Bal', text: 'Customer Bal', editable: false,headerStyle: { fontSize: 7 }, style:{ fontSize: 10 }},
        { dataField: 'Cash_Cr', text: 'Cash Cr', editable: false,headerStyle: { fontSize: 7 }, style:{ fontSize: 10 }},
        { dataField: 'Cash_Bal', text: 'Cash Bal', editable: false ,headerStyle: { fontSize: 7 }, style:{ fontSize: 10 }},
        { dataField: 'POS_Cr', text: 'POS Cr', editable: false,headerStyle: { fontSize: 7 }, style:{ fontSize: 10 }},
        { dataField: 'POS_Bal', text: 'POS Bal', editable: false ,headerStyle: { fontSize: 7 }, style:{ fontSize: 10 }},
    ];


    
    useEffect(() => {
        const today = new Date();
        const date = today.setDate(today.getDate());
        const defaultValue = new Date(date).toISOString().split('T')[0];
        fromDate.current.value = defaultValue;
        toDate.current.value = defaultValue;
        // setDefaultDate(defaultValue);
        BindGrid("0");
    }, []);
    const handleSearch = async () => {
        if (fromDate.current.value != "" && toDate.current.value != "") { BindGrid("0"); }
    }
    const BindGrid = async (isCSV) => {
        setIsLoading(true);
        let ledgerId = localStorage.LoggedInUser;
        if (parseInt(localStorage.CentreTypeId) > 1) { ledgerId = localStorage.CentreSNo; }
        let ledgerType = "1";
        if (parseInt(localStorage.CentreTypeId) > 1) { ledgerType = "2"; }
        let url = "";
        if(isCSV === "1")
        {
            url = FrontDesk_URL + 'accounts/getnewledgersinfodownload/' + ledgerId + "/" + ledgerType + "/" + fromDate.current.value + "/" + toDate.current.value
        }
        else
        {
            url = FrontDesk_URL + 'accounts/getnewledgersinfo/' + ledgerId + "/" + ledgerType + "/" + fromDate.current.value + "/" + toDate.current.value
        }
        await axios.get(url).then((response) => {
            const data = response.data;
            if (data.Success) {
                if (data.Data !== null) {
                    if (isCSV === "0") { setTableData(data.Data);setIsLedgerExist(true); } else { setDownloadVisible(true); setCSVLedger(data.Data); document.getElementById("csvDownload").click(); }
                } else { setTableData([]); setDownloadVisible(false); setIsLedgerExist(false);}
                setIsLoading(false);
            }
            else { SweetAlert.fire({ title: "Application Exception", text: "Some error occured! Please try after sometime", icon: "error" }); setIsLoading(false); setIsLedgerExist(false); }
        }).catch(function (error) { if (error.response) { ErrorFunction(error.response.status); } setIsLoading(false); setIsLedgerExist(false); });
    }
    const APIError = async (ErrorCode, ErrorMsg) => {
        if (ErrorCode === "-1") { SweetAlert.fire({ title: "Data unavailable", text: "No record found", icon: "error" }) }
        else if (ErrorCode === "-2") { SweetAlert.fire({ title: "API Server Error", text: ErrorMsg, icon: "error" }); }
        else if (ErrorCode === "-3") { SweetAlert.fire({ title: "Runtime Exception", text: "Some error occured! Please try after sometime.", icon: "error" }) }
        else if (ErrorCode === "-4") { SweetAlert.fire({ title: "Wrong Credentials", text: "Please enter correct credentials", icon: "error" }) }
        else if (ErrorCode === "-5") { SweetAlert.fire({ title: "Oops", text: "Data could not be saved, Please try again later", icon: "error" }) }
        else if (ErrorCode === "-6") { SweetAlert.fire({ title: "Failed", text: "Check DB Logs", icon: "error" }) }
        else if (ErrorCode === "-7") { SweetAlert.fire({ title: "Duplicate", text: "Template already exists", icon: "error" }) }
        else if (ErrorCode === "-8") { SweetAlert.fire({ title: "Data unavailable", text: "No record found to import", icon: "error" }) }
        else if (ErrorCode === "-9") { SweetAlert.fire({ title: "LDAP authentication failed", text: ErrorMsg, icon: "error" }) }
        else { SweetAlert.fire({ title: "Application Exception", text: "Some error occured! Please try after sometime", icon: "error" }) }
    }
    const ErrorFunction = async (ErrorCode) => {
        if (ErrorCode == "400") { SweetAlert.fire({ title: "Error 400", text: "Bad request", icon: "error" }) }
        else if (ErrorCode == "401") { SweetAlert.fire({ title: "Error 401", text: "Unauthorized", icon: "error" }) }
        else if (ErrorCode == "403") { SweetAlert.fire({ title: "Error 403", text: "Forbidden", icon: "error" }) }
        else if (ErrorCode == "404") { SweetAlert.fire({ title: "Error 404", text: "Page not found", icon: "error" }) }
        else if (ErrorCode == "500") { SweetAlert.fire({ title: "Error 500", text: "Internal Server Error", icon: "error" }) }
        else if (ErrorCode == "503") { SweetAlert.fire({ title: "Error 503", text: "Service Unavailable", icon: "error" }) }
    }

    // const Printreceipt = (vId) => {
    //     localStorage.removeItem('vId');
    //     localStorage.setItem('vId', vId);
    //     window.open(`${process.env.PUBLIC_URL}/front-office/receipt`, '_blank');
        
    // }
    const Printreceipt = (vId) => {
        if( vId !== "0" )
        {
            localStorage.removeItem('vId');
            localStorage.setItem('vId', vId);
            window.open(`${process.env.PUBLIC_URL}/front-office/receipt`, '_blank');
        }
    }
    return (
        <React.Fragment>
            <Breadcrumb title="LedgersNew" parent="Accounts" />
            <Container fluid>
                <Row>
                    <Col md={12}>
                        <Card>
                            <Card.Body>
                                <Form.Row>
                                    <Col md={3}>
                                        <Form.Group>
                                            <Form.Label>From Date</Form.Label>
                                            <Form.Control type="date" id="date1" name="date1" ref={fromDate} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={3}>
                                        <Form.Group>
                                            <Form.Label>To Date</Form.Label>
                                            <Form.Control type="date" id="date2" name="date2" ref={toDate} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={3} className='text-center __center__buttonsec'>
                                        <Form.Group>
                                            <Button variant='secondary' className="mr-2 mt-4" onClick={handleSearch} >Search</Button>
                                        </Form.Group>
                                    </Col>
                                </Form.Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <Card>
                            <Card.Header className='pb-0'>
                                <Card.Title>Ledger Transactions</Card.Title>
                            </Card.Header>
                            <Card.Body>
                                {isLedgerExist ?
                                    <Form.Row className='mb-3'>
                                        <Col className='text-right' md={12}>
                                            <Button variant='primary' className="mr-2" onClick={() => BindGrid('1')} filename={"LedgerStatement.csv"} >Download Statement</Button>
                                        </Col>
                                    </Form.Row>
                                    : null}
                                {
                                    isLoading ?
                                        <div className='text-center'> <Spinner animation="border" size='sm' /></div> :
                                        <>
                                            <DataTables id='tbLedger' keyField='CreatedAt' tableData={tableData} columns={columns} noDataIndication={NoRecordFounds}
                                                pagination={paginationFactory(paginationOptions)}  />
                                        </>
                                }
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                {
                    downloadvisible &&
                    <CSVLink data={csvLedger} filename="LedgerStatement.csv" target="_blank" id="csvDownload"></CSVLink>
                }
            </Container>
        </React.Fragment>
    )
}
export default LedgersNew;