import React, { useState, useEffect, useRef } from 'react';
import { Container, Row, Col, Card, Form, Button } from 'react-bootstrap';
import Breadcrumb from '../../components/common/breadcrumb';
import POSFlowDetails from '../account/POSFlowDetails';
import { Spinner } from 'react-bootstrap';
import DataTables from '../commonComponent/DataTable/DataTables';
import NoRecordFounds from '../commonComponent/DataTable/NoRecordFounds';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { FrontDesk_URL } from '../../constant';
import axios from 'axios';
import SweetAlert from 'sweetalert2';
import AllToolTip from '../UI/ToolTips/AllToolTip';
import ToolTip from '../UI/ToolTips/ToolTip';
import InputGroup from 'react-bootstrap/InputGroup';

const POSFlow = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [table, setTable] = useState([]);
    const [table2, setTable2] = useState([]);
    const [crntPageNO, setCrntPageNO] = useState(0);
    const [crntPageNO1, setCrntPageNO1] = useState(0);
    const [availableamountonuser, setAvailableAmountOnUser] = useState(0);
    const [defaultDate, setDefaultDate] = useState("");
    const [displayPending, setDisplayPending] = useState(false);
    let fromdate = useRef();
    let todate = useRef();
    const [holdamount, setHoldAmount] = useState(0);
    const [dailyLimit, setDailyLimit] = useState(0);
    const [monthlyLimit, setMonthlyLimit] = useState(0);
    const [allowPOSTransfer, setAllowPOSTransfer] = useState(false);

    
    useEffect(() => {
        const today = new Date();
        const date = today.setDate(today.getDate());
        const defaultValue = new Date(date).toISOString().split('T')[0];
        setDefaultDate(defaultValue);
        GetPOSFlowDetails(defaultValue, defaultValue);
    }, []);
    const idFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                <span>{crntPageNO + rowIndex + 1}</span>
            </div>
        </React.Fragment>
    }
    const idnewFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                <span>{crntPageNO1 + rowIndex + 1}</span>
            </div>
        </React.Fragment>
    }
    const actionFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                {
                    rowData.ActionRequired === "1" || rowData.ReconsileIcon === "True" ?
                        <>
                            <ToolTip title="Reconcile" placement="top" linkId={"Rejcon" + rowIndex} faName="fa fa-check-square-o" onClick={(e) => ReconsileAmount(rowData.CashFlowId, "1")}></ToolTip>
                            <span>  </span>
                            <ToolTip title="Reject Entry" placement="top" linkId={"Reject" + rowIndex} faName="fa fa-close mr-2" onClick={(e) => ReconsileAmount(rowData.CashFlowId, "2")}></ToolTip>
                        </>
                        : rowData.ReconcileStatus == "2" ? <div>Rejected</div> : <div>Pending</div>
                }
            </div>
        </React.Fragment>
    }
    const actionPOSFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                {
                    rowData.ReconcileStatus === "1" || rowData.ReconcileStatus === "True" ?
                        <div>Approved</div>
                        : rowData.ReconcileStatus == "2" ? <div>Rejected</div> : <div>Pending</div>
                }
            </div>
        </React.Fragment>
    }
    const PaginationOptions = {
        sizePerPage: 25, showTotal: false, firstPageText: 'First', prePageText: 'Back', nextPageText: 'Next', lastPageText: 'Last',
        onPageChange: (page, sizePerPage) => { setCrntPageNO(((page - 1) * sizePerPage)); }
    };
    const PaginationOptions2 = {
        sizePerPage: 10, showTotal: false, firstPageText: 'First', prePageText: 'Back', nextPageText: 'Next', lastPageText: 'Last',
        onPageChange: (page, sizePerPage) => { setCrntPageNO1(((page - 1) * sizePerPage)); }
    };
    const columns = [
        { dataField: '#', text: '#', editable: false, formatter: idFormatter, headerStyle: (colum, colIndex) => { return { width: '5%' }; } },
        { dataField: 'ReferenceNo', text: 'Reference No/Receipt No', editable: false },
        { dataField: 'Mode', text: 'Mode', editable: false },
        { dataField: 'Amount', text: 'Amount', editable: false },
        // { dataField: 'POSMachineName', text: 'POS Machine Name', editable: false },
        { dataField: 'ReconcileBy', text: 'Reconcile By', editable: false },
        { dataField: 'ReconcileOn', text: 'Reconcile On', editable: false },
        { dataField: 'EntryDateTime', text: 'Entry Date Time', editable: false }
    ];
    const columns2 = [
        { dataField: '#', text: '#', editable: false, formatter: idnewFormatter, headerStyle: (colum, colIndex) => { return { width: '5%' }; } },
        { dataField: 'Mode', text: 'Mode', editable: false },
        { dataField: 'Amount', text: 'Amount', editable: false },
        { dataField: 'ReferenceNo', text: 'Reference No', editable: false },
        { dataField: 'ReconcileBy', text: 'Reconcile By', editable: false },
        { dataField: 'ReconcileOn', text: 'Reconcile On', editable: false },
        { dataField: 'EntryDateTime', text: 'Entry Date Time', editable: false },
        { dataField: 'actions', isDummyField: true, text: 'Action', editable: false, formatter: actionPOSFormatter, headerStyle: (colum, colIndex) => { return { width: '7%' }; } }
    ];
    const GetPOSFlowDetails = async (fromdate, todate) => {
        setIsLoading(true);
        try {
            let url = FrontDesk_URL + 'accounts/getposflowstatement/' + localStorage.getItem('LoggedInUser') + '/' + fromdate.toString() + '/' + todate.toString()
            await axios.get(url).then((response) => {
                const data = response.data;
                setIsLoading(false);
                if (data.Success) {
                    setAvailableAmountOnUser(data.Data.AvailableAmount === "" || data.Data.AvailableAmount === null ? "0" : data.Data.AvailableAmount);
                    setHoldAmount((data.Data.AvailableAmount === "" || data.Data.AvailableAmount === null ? "0" : data.Data.AvailableAmount) - (data.Data.PendingAmount === "" || data.Data.PendingAmount === null ? "0" : data.Data.PendingAmount));
                    if (data.Data.SettledEntries === null || data.Data.SettledEntries.length == 0) { setTable([]); }
                    else { setTable(data.Data.SettledEntries); }
                    if (data.Data.UnSettledEntries === null || data.Data.UnSettledEntries.length == 0) { setDisplayPending(false); setTable2([]); }
                    else { setDisplayPending(true); setTable2(data.Data.UnSettledEntries); }
                }
                else {
                    setAvailableAmountOnUser(0);
                    setTable([]);
                    setTable2([]);
                }
            }).catch(function (error) { setIsLoading(false); if (error.response) { ErrorFunction(error.response.status); } });
        }
        catch (error) { setIsLoading(false); SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    }
    const ReconsileAmount = async (CashFlowId, isReconcile) => {
        const requestOptions = { CashFlowId: CashFlowId, CentreId: localStorage.getItem('CentreSNo'), LoginId: localStorage.getItem('LoggedInUser'), Status: isReconcile };
        let url = FrontDesk_URL + 'master/ReconsileAmount'
        await axios.post(url, requestOptions).then((response) => {
            const data = response.data;
            if (data.Success) {
                if (isReconcile === "1") { SweetAlert.fire({ text: "Reconciled successfully", icon: "success" }); }
                else { SweetAlert.fire({ text: "Rejected successfully", icon: "success" }); }
                GetPOSFlowDetails(fromdate.current.value, todate.current.value);
            }
            else {
                if (data.ErrorList[0].errorCode == -2000 || data.Data == -2000) {
                    SweetAlert.fire({ title: "API Runtime Exception", text: "Some error occured! Please try after sometime.", icon: "error" })
                }
                else if (data.ErrorList[0].errorCode == -1 || data.Data == -1) {
                    SweetAlert.fire({
                        title: 'Already reconciled',
                        text: "Already reconciled",
                        icon: 'warning',
                        showCancelButton: false,
                        confirmButtonText: 'Ok',
                        reverseButtons: true
                    }).then((result) => { if (result.value) { window.location.reload(false); } else { window.location.reload(false); } })
                }
                else { SweetAlert.fire({ title: "Application Exception", text: "Some error occured! Please try after sometime.", icon: "error" }) }
            }
        }).catch(function (error) { if (error.response) { ErrorFunction(error.response.status); } });
    }
    const DisableTypingkey = (e) => {
        e.preventDefault();
        return false;
    }
    const handleSearch = (e) => {
        if (fromdate.current.value === "" && todate.current.value === "") { SweetAlert.fire("Please enter date"); return false; }
        if (fromdate.current.value !== "" && todate.current.value === "") { SweetAlert.fire("Please enter to date"); return false; }
        if (fromdate.current.value === "" && todate.current.value !== "") { SweetAlert.fire("Please enter from date"); return false; }
        GetPOSFlowDetails(fromdate.current.value, todate.current.value);
    }
    const handleTransfer = (e) => { setAllowPOSTransfer(true); }
    const ErrorFunction = async (ErrorCode) => {
        if (ErrorCode == "400") { SweetAlert.fire({ title: "Error 400", text: "Bad request", icon: "error" }); }
        else if (ErrorCode == "401") { SweetAlert.fire({ title: "Error 401", text: "Unauthorized", icon: "error" }); }
        else if (ErrorCode == "403") { SweetAlert.fire({ title: "Error 403", text: "Forbidden", icon: "error" }); }
        else if (ErrorCode == "404") { SweetAlert.fire({ title: "Error 404", text: "Page not found", icon: "error" }); }
        else if (ErrorCode == "500") { SweetAlert.fire({ title: "Error 500", text: "Internal Server Error", icon: "error" }); }
        else if (ErrorCode == "503") { SweetAlert.fire({ title: "Error 503", text: "Service Unavailable", icon: "error" }); }
    }
    return (
        <>
            <Breadcrumb title="POS Flow Statement" parent="Accounts" />
            <Container fluid>
                <Row>
                    <Col md={12}>
                        <Card>
                            <Card.Body>
                                <Form>
                                    <Form.Row>
                                        <Col md={9} className='mb-4'>
                                            <h6>
                                                <span>Total POS Collection: <i class="fa fa-inr"></i> {parseFloat(availableamountonuser).toFixed(2)} </span>
                                                <span className='text-red'> Transferable Amount: <i class="fa fa-inr"></i> {parseFloat(holdamount).toFixed(2)}</span>
                                            </h6>
                                        </Col>
                                        {/* {
                                            (parseFloat(holdamount) > 0 && allowPOSTransfer == false) ?
                                                <Col md={3} className='text-right'>
                                                    <Button variant='primary' onClick={(e) => handleTransfer(e)}>POS Amount Transfer</Button>
                                                </Col>
                                                : null
                                        } */}
                                    </Form.Row>
                                </Form>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                {allowPOSTransfer == false ?
                    <Row>
                        <Col md={12}>
                            <Card>
                                <Card.Body>
                                    <Form>
                                        <Form.Row>
                                            <Col md={3}>
                                                <Form.Group>
                                                    <InputGroup className="mb-5">
                                                        <Form.Label className='raFilter'>From Date : &nbsp;</Form.Label>
                                                        <Form.Control ref={fromdate} type="date" onKeyPress={(e) => DisableTypingkey(e)} defaultValue={defaultDate} />
                                                    </InputGroup>
                                                </Form.Group>
                                            </Col>
                                            <Col md={3}>
                                                <Form.Group>
                                                    <InputGroup className="mb-5">
                                                        <Form.Label className='raFilter'>To Date : &nbsp;</Form.Label>
                                                        <Form.Control ref={todate} type="date" onKeyPress={(e) => DisableTypingkey(e)} defaultValue={defaultDate} />
                                                    </InputGroup>
                                                </Form.Group>
                                            </Col>
                                            <Col md={3}>
                                                <Button variant='primary' onClick={(e) => handleSearch(e)}>Search</Button>
                                            </Col>
                                        </Form.Row>
                                        <Form.Row>
                                            <Col md={12}>
                                                <h6>Statement</h6>
                                                {
                                                    isLoading ? <div className='text-center'> <Spinner animation="border" size='sm' /></div> :
                                                        <div className="table-responsive mt15">
                                                            <DataTables keyField='POSFlowId' tableData={table} columns={columns} noDataIndication={NoRecordFounds}
                                                                pagination={paginationFactory(PaginationOptions)} />
                                                        </div>
                                                }
                                            </Col>
                                        </Form.Row>
                                        {displayPending ?
                                            <Form.Row style={{ marginTop: 50 }}>
                                                <Col md={12}>
                                                    <h6>Reconciliation Status</h6>
                                                    {
                                                        isLoading ? <div className='text-center'> <Spinner animation="border" size='sm' /></div> :
                                                            <div className="table-responsive mt15">
                                                                <DataTables keyField='POSFlowId' tableData={table2} columns={columns2} noDataIndication={NoRecordFounds}
                                                                    pagination={paginationFactory(PaginationOptions2)} />
                                                            </div>
                                                    }
                                                </Col>
                                            </Form.Row>
                                            : null
                                        }
                                    </Form>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    :
                    <POSFlowDetails pendingamount={availableamountonuser} holdamount={holdamount} allowPOSTransfer={(allowPOSTransfer) => setAllowPOSTransfer(allowPOSTransfer)} />
                }
            </Container>
        </>
    )
}

export default POSFlow;