import React, { useEffect, useState, useRef } from 'react';
import Breadcrumb from '../../components/common/breadcrumb';
import { Container, Row, Col, Card, Form, Button } from 'react-bootstrap';
import SweetAlert from 'sweetalert2';
import { FrontDesk_URL } from '../../constant';
import axios from 'axios';



const Details = () => {

    const [emailid, setEmailId] = useState("");

    useEffect(() => {
        GetCentreTypeHelpData("0");
    }, [])

    const GetCentreTypeHelpData = async (flag) => {
        try {
            let url = FrontDesk_URL + 'master/GetCentreTypeHelpData/' + flag
            await axios.get(url).then((response) => {
                const data = response.data;
                if (data.Success) {
                    setEmailId(data.Data.EmailId);
                    if (data.Data.Doc == "" || data.Data.Doc == null || data.Data.Doc == undefined) { }
                    else { window.open(data.Data.Doc, '_blank'); }
                }
                else { setEmailId(""); }
            }).catch(function (error) { if (error.response) { ErrorFunction(error.response.status); } });
        }
        catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    }
    const ErrorFunction = async (ErrorCode) => {
        if (ErrorCode == "400") { SweetAlert.fire({ title: "Error 400", text: "Bad request", icon: "error" }); }
        else if (ErrorCode == "401") { SweetAlert.fire({ title: "Error 401", text: "Unauthorized", icon: "error" }); }
        else if (ErrorCode == "403") { SweetAlert.fire({ title: "Error 403", text: "Forbidden", icon: "error" }); }
        else if (ErrorCode == "404") { SweetAlert.fire({ title: "Error 404", text: "Page not found", icon: "error" }); }
        else if (ErrorCode == "500") { SweetAlert.fire({ title: "Error 500", text: "Internal Server Error", icon: "error" }); }
        else if (ErrorCode == "503") { SweetAlert.fire({ title: "Error 503", text: "Service Unavailable", icon: "error" }); }
    }
    return (
        <React.Fragment>
            <Breadcrumb title="Help" parent="Reports" />
            <Container fluid>
                <Row className='mt25'>
                    <Col md={12}>
                        <Card>
                            <Card.Body>
                                {
                                    localStorage.CentreTypeId == "1" ?
                                        <>
                                            <ul className="pointList">
                                                <li>
                                                    <button className='btn btn-link' style={{ color: '#e31e25', padding: '0px' }} onClick={() => GetCentreTypeHelpData("1")}><i className='fa fa-file-pdf-o'></i> BSL-SOP-FRONTDESK</button>
                                                </li>
                                                <li>
                                                    <button className='btn btn-link' style={{ color: '#e31e25', padding: '0px' }} onClick={() => GetCentreTypeHelpData("2")}><i className='fa fa-file-pdf-o'></i> DAY-END-SOP</button>
                                                </li>
                                                <li>
                                                    <button className='btn btn-link' style={{ color: '#e31e25', padding: '0px' }} onClick={() => GetCentreTypeHelpData("3")}><i className='fa fa-file-pdf-o'></i> BSL-SOP-DISCOUNTS</button>
                                                </li>
                                            </ul>
                                        </> :
                                        localStorage.CentreTypeId == "2" ?
                                            <>
                                                <ul className="pointList">
                                                    <li>
                                                        <button className='btn btn-link' style={{ color: '#e31e25', padding: '0px' }} onClick={() => GetCentreTypeHelpData("4")}>B2B_CC-BSL-SOP</button>
                                                    </li>
                                                </ul>
                                            </>
                                            :
                                            null
                                }
                                <p className="pointList">
                                    Please feel free to go to email: <a href={`mailto:${emailid}`}>{emailid}</a>
                                </p>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </React.Fragment>
    )
}

export default Details;