import React, { useState, useEffect, useRef } from 'react'
import { Container, Row, Col, Card, Button, Table, Form } from 'react-bootstrap';
import Breadcrumb from '../../components/common/breadcrumb';
import DataTables from '../commonComponent/DataTable/DataTables';
import NoRecordFounds from '../commonComponent/DataTable/NoRecordFounds';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Spinner } from 'react-bootstrap';
import CommonToolTip from '../UI/ToolTips/CommonToolTip';
import { CardHeader } from 'reactstrap';
import { AllFiles, FrontDesk_URL, Image_Path, PineLabs_URL } from '../../constant';
import axios from 'axios';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import SweetAlert from 'sweetalert2';
import LoadingButton from '../commonComponent/LoadingButton';
import DeleteToolTip from '../UI/ToolTips/DeleteToolTip';
import InputGroup from 'react-bootstrap/InputGroup';
import ToolTip from '../commonComponent/ToolTip';
import { useHistory } from 'react-router';

var glblArr = [];
var paymode = [];
var newpaymentArr = [];
var glblOrderArr = [];
var glblDiscountAuthorizer = [];
var glblCreditAuthorizer = [];
var PendingAmountVar = "0";
var glblposmachine = [];
var paymentModeIdSetting = "";
var discoutamount = 0;
var glblDocArr = [];
const SaleRegister = () => {
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);
    const [isRazorLoading, setIsRazorLoading] = useState(false);
    const [table, setTable] = useState([]);
    const [paymentmode, setPaymentMode] = useState([]);
    const [crntPageNO, setCrntPageNO] = useState(0);
    const [openmodal, setOpenModal] = useState(false);
    const [paymentModeId, setPaymentModeId] = useState("0");
    const [pendingamount, setPendingAmount] = useState("0");
    const [orderstatus, setOrderStatus] = useState("");
    const [discountstatus, setDiscountStatus] = useState("");
    const [paymenttable, setPaymentTable] = useState([]);
    const [orderid, setOrderId] = useState("0");
    const [isButtonLoading, setIsButtonLoading] = useState(false);
    const [PaymodeId, setPaymodeId] = useState("");
    const [Percentagecheckbox, setPercentagecheckbox] = useState("0");
    const [discountAuthorizers, setDiscountAuthorizers] = useState([]);
    const [creditAuthorizers, setCreditAuthorizers] = useState([]);
    const [discountauthorizerdetails, setDiscountAuthorizerDetails] = useState({});
    const [creditauthorizerdetails, setCreditAuthorizerDetails] = useState({});
    const [totalamt, setTotalAmt] = useState(0);
    const [dueamt, setDueAmt] = useState(0);
    const [VisitingId, setVisitingId] = useState("0");
    const [discountorderdetailid, setDiscountOrderDetailId] = useState("0");
    const [creditorderdetailid, setCreditOrderDetailId] = useState("0");
    const [paymentChannels, setPaymentChannels] = useState([]);
    const [posmachines, setPOSMachines] = useState([]);
    const [viewreqfieldsModal, setViewReqFieldsModal] = useState();
    const [prescriptiondocument, setPrescriptionDocument] = useState("");
    const [visitid, setVisitId] = useState("0");
    const [HideAmountInBookingSetting, setHideAmountInBookingSetting] = useState(false);
    const [PrintDepartmentSlipSetting, setPrintDepartmentSlipSetting] = useState(false);
    const [parentcentreid, setParentCentreId] = useState("0");
    const [posmachineid, setPosMachineId] = useState("0");
    const [posmachinename, setPosMachineName] = useState("");
    const [channelid, setChannelId] = useState("0");
    const [UserPOSCode, setUserPOSCode] = useState("");
    const [patientmobile, setPatientMobile] = useState("");
    const [patientname, setPatientName] = useState("");
    const [patientemail, setPatientEmail] = useState("");
    const [plutustransrefid, setPlutusTransRefId] = useState("0");
    const [transactionno, setTransactionNo] = useState("0");
    const [merchantstorepostcode, setMerchantStorePostCode] = useState("");
    const [imei, setIMEI] = useState("");
    const [hideshowpaymentbutton, setHideShowPaymentButton] = useState(false);
    const [remarksvisitingid, setRemarksVisitingId] = useState("0");
    const [remarksModal, setRemarksModal] = useState();
    const [viewremarks, setViewRemarks] = useState([]);
    const [doctype, setDocType] = useState("0");
    const [doccrntPageNO, setDocCrntPageNO] = useState(0);
    const [tabledocdata, setTableDocData] = useState([]);
    const [doccrntPageNO1, setDocCrntPageNO1] = useState(0);
    const [tabledocdata1, setTableDocData1] = useState([]);
    const [documenttype, setDocumentType] = useState([]);
    const [doctypename, setDocTypeName] = useState("");
    const [UserCustomerCode, setUserCustomerCode] = useState("");
    const [UserCashCode, setUserCashCode] = useState("");
    const [loginuserid, setLoginUserId] = useState(localStorage.LoggedInUser == undefined || localStorage.LoggedInUser == null ? "" : localStorage.LoggedInUser);
    const [logincentreid, setLoginCentreId] = useState(localStorage.CentreSNo == undefined || localStorage.CentreSNo == null ? "" : localStorage.CentreSNo);
    const [glblrefno, setGlblRefNo] = useState("");

    let remarks = useRef();
    let authorizerId = useRef();
    let concessionReason = useRef();
    let paymentmodedetail = useRef();
    let paymentamountdetail = useRef();
    let paymentReferenceNo = useRef();
    let doctypee = useRef();

    const [defaultDate, setDefaultDate] = useState("");
    let fromDate = useRef();
    let toDate = useRef();
    let refmobilenumber = useRef();
    let refuhid = useRef();
    let refsinno = useRef();
    let refpatientname = useRef();
    let refvisitid = useRef();
    const [settleType, setSettleType] = useState("");
    let paymentChannelId = useRef();
    let posMachineId = useRef();
    useEffect(() => {
        const pageAccessedByReload = (
            (window.performance.navigation && window.performance.navigation.type === 1) ||
            window.performance
                .getEntriesByType('navigation')
                .map((nav) => nav.type)
                .includes('reload')
        );
        if (pageAccessedByReload === true) {
            localStorage.removeItem('vId');
            localStorage.removeItem('mode');
        }
        const today = new Date();
        const date = today.setDate(today.getDate());
        const defaultValue = new Date(date).toISOString().split('T')[0] // yyyy-mm-dd
        setDefaultDate(defaultValue);
        GetSettings(localStorage.CentreSNo, localStorage.LoggedInUser, defaultValue);
        GetAuthorizer("1");
        GetAuthorizer("2");
        GetPaymentChannels();
        GetPOSMachines();
    }, [])

    const popupPaginationOptions = {
        sizePerPage: 10,
        showTotal: false,
        firstPageText: 'First',
        prePageText: 'Back',
        nextPageText: 'Next',
        lastPageText: 'Last',
        onPageChange: (page, sizePerPage) => { setCrntPageNO(((page - 1) * sizePerPage)); }
    };
    const idFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                <span>{crntPageNO + rowIndex + 1}</span>
            </div>
        </React.Fragment>
    }
    const actionFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                {
                    rowData.OrderStatus === "1" || rowData.OrderStatus === "True" ? null :
                        localStorage.CentreTypeId === "1" ?
                            <>
                                <CommonToolTip title="Settle order" id={`SettleOrder${rowData.OrderId}`} placement="top" class="fa fa-pencil mr-2" onClick={() => openSettlePaymentModal(rowData)} />
                            </>
                            : null
                }
                {
                    HideAmountInBookingSetting === false ?
                        parentcentreid === "0" ?
                            <>
                                <CommonToolTip title="Print receipt" id={`Printreceipt${rowData.OrderId}`} placement="top" class="fa fa-print" onClick={() => Printreceipt(rowData.VisitingId)} />
                                &nbsp;&nbsp;
                            </>
                            :
                            null
                        : null
                }
                {
                    PrintDepartmentSlipSetting &&
                    <>
                        <CommonToolTip title="Print department slip" id={`Printdepartmentreceipt${rowData.OrderId}`} placement="top" class="fa fa-building-o" onClick={() => Printdepartmentslip(rowData.VisitingId)} />
                        &nbsp;&nbsp;
                    </>
                }
                <CommonToolTip title="Print sticker" id={`Printsticker${rowData.OrderId}`} placement="top" class="fa fa-sticky-note-o" onClick={() => Printsticker(rowData.VisitingId)} />
                {rowData.InvoiceNo === "" ? <> &nbsp;<CommonToolTip title="Generate Invoice" id={`Invoice${rowData.OrderId}`} placement="top" class="fa fa-file-text" onClick={() => ConfirmInvoiceGeneration(rowData.VisitingId)} /></> : null}
            </div>
        </React.Fragment>
    }
    const actionStatusFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                {(localStorage.CentreTypeId === "1") || (localStorage.CentreTypeId === "2" && localStorage.PartnerTypeId === "5") ?
                    <>
                        {
                            HideAmountInBookingSetting === false ?
                                parentcentreid === "0" ?
                                    rowData.PaidAmount === "0.00" ?
                                        // rowData.TotalAmount === rowData.DiscountAmount ?
                                        <>
                                            <CommonToolTip title="Print receipt" id={`Printreceipt${rowData.OrderId}`} placement="top" class="fa fa-print" onClick={() => Printreceipt(rowData.VisitingId)} />
                                            &nbsp;&nbsp;
                                        </>
                                        // :
                                        // null
                                        :
                                        <>
                                            <CommonToolTip title="Print receipt" id={`Printreceipt${rowData.OrderId}`} placement="top" class="fa fa-print" onClick={() => Printreceipt(rowData.VisitingId)} />
                                            &nbsp;&nbsp;
                                        </> :
                                    null
                                : null
                        }
                        {
                            rowData.ExtraFlagRemove === "1" || rowData.ExtraFlagRemove === "True" ? null :
                                <>
                                    {
                                        PrintDepartmentSlipSetting &&
                                        <>
                                            <CommonToolTip title="Print department slip" id={`Printdepartmentreceipt${rowData.OrderId}`} placement="top" class="fa fa-building-o" onClick={() => Printdepartmentslip(rowData.VisitingId)} />
                                            &nbsp;&nbsp;
                                        </>
                                    }
                                    <CommonToolTip title="Print sticker" id={`Printsticker${rowData.OrderId}`} placement="top" class="fa fa-sticky-note-o" onClick={() => Printsticker(rowData.VisitingId)} />
                                </>
                        }
                    </>
                    : null
                }
                {/* {
                    rowData.ExtraFlagRemove === "1" || rowData.ExtraFlagRemove === "True" ? " Complete" :
                        <>
                            {
                                rowData.OrderStatus === "1" || rowData.OrderStatus === "True" ? " Complete" :
                                    rowData.OrderStatus === "0" || rowData.OrderStatus === "False" ? " Pending" :
                                        rowData.OrderStatus === "-1" ? " Cancelled" :
                                            " Rejected"
                            }
                        </>
                } */}
                {
                    rowData.OrderStatus === "1" || rowData.OrderStatus === "True" ? " Complete" :
                        rowData.OrderStatus === "0" || rowData.OrderStatus === "False" ? " Pending" :
                            rowData.OrderStatus === "-1" ? " Cancelled" :
                                " Rejected"
                }
            </div>
        </React.Fragment>
    }
    const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
    const UploadPrescription = async (e) => {
        const arryVal = e.target.files[0].name.split(".");
        const extension = arryVal[arryVal.length - 1];
        var base64 = await toBase64(e.target.files[0]);
        var base64img = base64.split("base64,");
        const formData = new FormData();
        formData.append('Image', base64img[1]);
        formData.append('FolderName', doctype == "2" ? 'prescription' : 'prerequisite');
        formData.append('pathflag', doctype == "2" ? 'prescription' : 'prerequisite');
        const ImageName = Math.random();
        var imgName = "";
        if (doctype == "2") { imgName = ImageName + "prescription." + extension; }
        else { imgName = ImageName + "prerequisite." + extension; }
        formData.append('ImageName', imgName);
        let url = FrontDesk_URL + 'master/UploadImage_Root/'
        await axios.post(url, formData, {
            headers: { "Content-type": "multipart/form-data", },
        }).then((response) => {
            const data = response.data;
            if (data.Success) {
                var key = glblDocArr.length;
                glblDocArr.push({
                    RowId: (parseInt(key) + 1).toString(),
                    VisitingId: visitid,
                    Document: imgName,
                    Type: doctypename//doctype == "2" ? "Prescription" : "Others"
                });
                setPrescriptionDocument(imgName);
                setTableDocData1(glblDocArr);
                setDocType("0");
                doctypee.current.value = "0";
            } else { SweetAlert.fire({ title: "Upload failed", text: "Unable to upload document due to " + data.Data + ".Please retry again", icon: "error" }) }
        }).catch(function (error) { if (error.response) { } });
    }
    const actionDownloadStatusFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                {
                    rowData.ExtraFlagRemove === "1" || rowData.ExtraFlagRemove === "True" ? null :
                        <>
                            <CommonToolTip title="Upload Document" id={`Documentsss${rowData.OrderId}`} placement="top" class="fa fa-upload" onClick={() => openSaveRequiredFieldModal(rowData.VisitingId)} />
                            &nbsp;&nbsp;
                            {/* {
                                rowData.DocumentPersistFlag === "1" || rowData.DocumentPersistFlag === "True" ?
                                    <>
                                        <CommonToolTip title="Download Uploaded Documents" id={`Documents${rowData.OrderId}`} placement="top" class="fa fa-download" onClick={() => ShowAllDocuments(rowData.VisitingId)} />
                                        &nbsp;&nbsp;
                                    </>
                                    : null
                            } */}
                            <CommonToolTip title="View Report" id={`ViewReport${rowData.VisitingId}`} placement="top" class="fa fa-eye" onClick={() => editHandler(rowData.VisitingId)} />
                        </>
                }
                &nbsp;&nbsp;
                <CommonToolTip title="Add/View Remarks" id={`Remarks${rowData.VisitingId}`} placement="top" class="fa fa-wechat mr-2" onClick={() => openRemarksModal(rowData.VisitingId)} />
            </div>
        </React.Fragment>
    }
    const actionpaymentFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                {
                    channelid === "-1" || channelid == "8" ? null :
                        <DeleteToolTip title="Delete" placement="top" id={`DeletePayment${rowData.PaymentModeId == "Payment Gateway" ? "13" : rowData.PaymentModeId}`} onClick={() => RemovePayment(rowData.PaymentModeId, rowData.PaymentChannelId)} />
                }
            </div>
        </React.Fragment>
    }
    const settleDiscountFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                {/* {
                    rowData.ExtraFlagRemove === "1" || rowData.ExtraFlagRemove === "True" ? null :
                        <> */}
                <span>
                    {rowData.DiscountAmount}
                    {(rowData.DiscountStatus === "0" || rowData.DiscountStatus === "6") && (rowData.PaymentModeId === "14" || rowData.PaymentModeId === "14,15" || rowData.PaymentModeId === "15,14") && parseFloat(rowData.DiscountAmount) > 0 ? " Pending on " + rowData.DiscountAuthorizer : null}
                    {rowData.DiscountStatus === "2" && parseFloat(rowData.DiscountAmount) > 0 ? " Rejected by " + rowData.DiscountAuthorizer : null}
                    {rowData.DiscountStatus === "1" && parseFloat(rowData.DiscountAmount) > 0 ? " Approved by " + rowData.DiscountAuthorizer : null}
                    &nbsp;
                </span>
                {
                    (rowData.DiscountStatus === "2" || rowData.DiscountStatus === "0" || rowData.DiscountStatus === "6") && (rowData.PaymentModeId === "14" || rowData.PaymentModeId === "14,15" || rowData.PaymentModeId === "15,14") ?
                        <>
                            <CommonToolTip title="Settle order" id={`SettleOrder${rowData.OrderId}`} placement="top" class="fa fa-pencil mr-2" onClick={() => openSettlePaymentModal(rowData, "Discount")} />
                        </>
                        : null
                }
                {/* </>
                } */}
            </div>
        </React.Fragment>
    }
    const settleCreditFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                {/* {
                    rowData.ExtraFlagRemove === "1" || rowData.ExtraFlagRemove === "True" ? null :
                        <> */}
                <span>{rowData.OutStandingAmount} </span>
                {(rowData.OutStandingStatus === "0") && (rowData.PaymentModeId === "15" || rowData.PaymentModeId === "14,15" || rowData.PaymentModeId === "15,14") && parseFloat(rowData.OutStandingAmount) > 0 ? " Pending on " + rowData.CreditAuthorizer : null}
                {(rowData.OutStandingStatus === "5") && (rowData.PaymentModeId === "15" || rowData.PaymentModeId === "14,15" || rowData.PaymentModeId === "15,14") && parseFloat(rowData.OutStandingAmount) > 0 ? " Credit approved by " + rowData.CreditAuthorizer : null}
                {rowData.OutStandingStatus === "2" && parseFloat(rowData.OutStandingAmount) > 0 ? " Rejected by " + rowData.CreditAuthorizer : null}
                {rowData.OutStandingStatus === "1" && parseFloat(rowData.OutStandingAmount) > 0 ? " Approved by " + rowData.CreditAuthorizer : null}
                &nbsp;
                {
                    (rowData.OutStandingStatus === "2" || rowData.OutStandingStatus === "5" || rowData.OutStandingStatus === "0") && (rowData.PaymentModeId === "15" || rowData.PaymentModeId === "14,15" || rowData.PaymentModeId === "15,14") ?
                        <>
                            <CommonToolTip title="Settle order" id={`SettleOrder${rowData.OrderId}`} placement="top" class="fa fa-pencil mr-2" onClick={() => openSettlePaymentModal(rowData, "Credit")} />
                        </>
                        : null
                }
                {/* </>
                } */}
            </div>
        </React.Fragment>
    }
    const actionBillFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                {/* {
                    rowData.ExtraFlagRemove === "1" || rowData.ExtraFlagRemove === "True" ? null :
                        <> */}
                {
                    (rowData.InvoiceNo === "" || rowData.InvoiceNo === null || rowData.InvoiceNo === undefined) ?
                        <>
                            <CommonToolTip title="Edit Items" id={`Edit${rowData.PatientId, rowData.VisitingId}`} placement="top" class="fa fa-edit" onClick={() => openEditScreen(rowData.PatientId, rowData.VisitingId)} />
                        </>
                        :
                        (rowData.InvoiceNo === "1" || rowData.InvoiceNo === "True") ? null :
                            <span>{rowData.InvoiceNo} </span>
                }
                {/* </>
                } */}
            </div>
        </React.Fragment>
    }
    const columns =
        parentcentreid === "0" ?
            [
                { dataField: '#', text: '#', editable: false, formatter: idFormatter, headerStyle: (colum, colIndex) => { return { width: '5%' }; } },
                { dataField: 'VisitingCode', text: 'Visit No', editable: false },
                { dataField: 'TemplateName', text: 'Rate Template', editable: false },
                { dataField: 'PatientName', text: 'Patient', editable: false },
                { dataField: 'InvoiceNo', text: 'Bill No', editable: false, formatter: actionBillFormatter },
                { dataField: 'OrderTime', text: 'Timestamp', editable: false, },
                { dataField: 'TotalAmount', text: 'Total Amount', editable: false },
                { dataField: 'PaidAmount', text: 'Paid Amount', editable: false },
                { text: 'Discount', editable: false, formatter: settleDiscountFormatter },
                { text: 'Outstanding', editable: false, formatter: settleCreditFormatter },
                { dataField: 'OrderStatus', text: 'Payment Status', editable: false, formatter: actionStatusFormatter, headerStyle: (colum, colIndex) => { return { width: '7%' }; } },
                { dataField: 'Actions', text: 'Actions (Max 6 mb accepted)', editable: false, formatter: actionDownloadStatusFormatter, headerStyle: (colum, colIndex) => { return { width: '12%' }; } },
                // { dataField: 'actions', isDummyField: true, text: 'Action', editable: false, formatter: actionFormatter  }
            ] :
            [
                { dataField: '#', text: '#', editable: false, formatter: idFormatter, headerStyle: (colum, colIndex) => { return { width: '5%' }; } },
                { dataField: 'VisitingCode', text: 'Visit No', editable: false },
                { dataField: 'TemplateName', text: 'Rate Template', editable: false },
                { dataField: 'PatientName', text: 'Patient', editable: false },
                { dataField: 'InvoiceNo', text: 'Bill No', editable: false, formatter: actionBillFormatter },
                { dataField: 'OrderTime', text: 'Timestamp', editable: false, },
                // { dataField: 'TotalAmount', text: 'Total Amount', editable: false },
                // { dataField: 'PaidAmount', text: 'Paid Amount', editable: false },
                // { text: 'Discount', editable: false, formatter: settleDiscountFormatter },
                // { text: 'Outstanding', editable: false, formatter: settleCreditFormatter },
                { dataField: 'OrderStatus', text: 'Payment Status', editable: false, formatter: actionStatusFormatter, headerStyle: (colum, colIndex) => { return { width: '7%' }; } },
                { dataField: 'Actions', text: 'Actions (Max 6 mb accepted)', editable: false, formatter: actionDownloadStatusFormatter, headerStyle: (colum, colIndex) => { return { width: '12%' }; } },
                // { dataField: 'actions', isDummyField: true, text: 'Action', editable: false, formatter: actionFormatter  }
            ];

    const paymentColumns = [
        { dataField: 'PaymentMode', text: 'Payment Mode', editable: false },
        { dataField: 'PaymentChannel', text: 'Payment Channel', editable: false },
        { dataField: 'PaidAmount', text: 'Paid Amount', editable: false },
        { dataField: 'ReferenceNo', text: 'Reference No.', editable: false },
        { dataField: 'Authorizer', text: 'Authorizer', editable: false },
        { dataField: 'POSMachineName', text: 'POS Machine', editable: false },
        { dataField: '', text: 'Remove', editable: false, formatter: actionpaymentFormatter }
    ];
    const GetPendingOrderList = async (fromDate, toDate) => {
        setIsLoading(true);
        let mobno = refmobilenumber.current.value === "" ? "0" : refmobilenumber.current.value;
        let visitid = refvisitid.current.value === "" ? "0" : refvisitid.current.value;
        let uhid = refuhid.current.value === "" ? "0" : refuhid.current.value;
        let sinno = refsinno.current.value === "" ? "0" : refsinno.current.value;
        let patname = refpatientname.current.value === "" ? "0" : refpatientname.current.value;
        const requestOptions = {
            CentreId: localStorage.CentreSNo,
            LoginId: localStorage.LoggedInUser,
            fromdate: fromDate,
            todate: toDate,
            mobilenumber: mobno,
            visitid: visitid,
            uhid: uhid,
            sinno: sinno,
            patientname: patname
        }
        try {
            let url = FrontDesk_URL + 'master/getpendingorderlist'
            await axios.post(url, requestOptions).then((response) => {
                const data = response.data;
                setIsLoading(false);
                if (data.Success) {
                    if (data.Data.pendingOrderList !== null) {
                        glblOrderArr = data.Data.pendingOrderList;
                        setTable(data.Data.pendingOrderList);
                    }
                    else { glblOrderArr = []; setTable([]); }
                    if (data.Data.payModeList !== null) {
                        paymode = data.Data.payModeList;
                        setPaymentMode(data.Data.payModeList.map((d) => (<option key={d.PaymentId} value={d.PaymentId}>{d.Payment}</option>)));
                    }
                    else { paymode = []; setPaymentMode([]); }
                }
                else { setTable([]); setPaymentMode([]); glblOrderArr = []; paymode = []; }
            }).catch(function (error) {
                setIsLoading(false);
                if (error.response) {
                    ErrorFunction(error.response.status);
                }
            });
        }
        catch (error) {
            setIsLoading(false);
            SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" });
        }
    }
    const GetPaymentChannels = async () => {
        try {
            let url = FrontDesk_URL + 'master/getpaymentchannels_phlebo/' + localStorage.LoggedInUser
            await axios.get(url).then((response) => {
                const data = response.data;
                if (data.Success) { setPaymentChannels(data.Data.map((d) => (<option key={d.SNo} value={d.SNo}>{d.PaymentChannel}</option>))); }
            }).catch(function (error) { });
        }
        catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    }
    const GetPOSMachines = async () => {
        try {
            let url = FrontDesk_URL + 'master/getposmachines/' + localStorage.LoggedInUser
            await axios.get(url).then((response) => {
                const data = response.data;
                if (data.Success) { glblposmachine = data.Data; setPOSMachines(data.Data.map((d) => (<option key={d.SNo} value={d.SNo}>{d.POSMachine}</option>))); }
            }).catch(function (error) { });
        }
        catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    }
    const Printreceipt = (vId) => {
        localStorage.setItem('vId', vId);
        window.open(`${process.env.PUBLIC_URL}/front-office/receipt`, '_blank');
    }
    const Printdepartmentslip = (vId) => {
        localStorage.setItem('vId', vId);
        window.open(`${process.env.PUBLIC_URL}/front-office/printdepartmentslip`, '_blank');
    }
    const Printsticker = (vId) => {
        localStorage.setItem('vId', vId);
        window.open(`${process.env.PUBLIC_URL}/front-office/printstickerdetails`, '_blank');
    }
    const ShowAllDocuments = async (vId) => {
        try {
            let url = FrontDesk_URL + 'master/getalldocuments_doc/' + vId
            await axios.get(url).then((response) => {
                const data = response.data;
                if (data.Success) {
                    if (data.Data != null) {
                        setTableDocData(data.Data);
                    }
                    else { setTableDocData([]); }
                }
                else {
                    if (data.data != null) { SweetAlert.fire("Error: " + data.Data); }
                    else { SweetAlert.fire("Some error occured. Check logs!"); }
                }
            }).catch(function (error) { if (error.response) { ErrorFunction(error.response.status); } });
        }
        catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    }
    function downloadFile(url, fileName) {
        const aElement = document.createElement('a');
        aElement.setAttribute('download', fileName);
        aElement.href = url;
        aElement.setAttribute('target', '_blank');
        aElement.click();
        URL.revokeObjectURL(url);
    }
    const openSettlePaymentModal = (rawData, callingType) => {
        setChannelId("0");
        setHideShowPaymentButton(false);
        setPercentagecheckbox("0");
        var tmpPendingAmount = rawData.PendingAmount;
        if (callingType === "Discount") { tmpPendingAmount = rawData.DiscountAmount; } else { tmpPendingAmount = rawData.OutStandingAmount; }
        setSettleType(callingType);
        glblArr = [];
        setPaymentTable([]);
        newpaymentArr = [];
        PendingAmountVar = tmpPendingAmount;
        setPendingAmount(tmpPendingAmount);
        setOrderStatus(rawData.OrderStatus);
        setDiscountStatus(rawData.DiscountStatus);
        setOrderId(rawData.OrderId);
        setPaymodeId(rawData.PaymentModeId);
        setVisitingId(VisitingId);
        setDiscountOrderDetailId(rawData.DOrderDetailID);
        setCreditOrderDetailId(rawData.COrderDetailID);
        setTotalAmt(rawData.TotalAmount);
        setOpenModal(!openmodal);
        setPatientMobile(rawData.PatientMobile);
        setPatientName(rawData.PName);
        setPatientEmail(rawData.PatientEmail);
        var tmpArray = [];
        if (callingType === "Discount") {
            if (rawData.InvoiceGenerated === "1" || rawData.InvoiceGenerated === "True") {
                tmpArray.push(<option value='1'>Cash</option>);
                tmpArray.push(<option value='13'>Payment Gateway</option>);
            }
            tmpArray.push(<option value='14'>Discount</option>);
        }
        else {
            if (rawData.InvoiceGenerated === "1" || rawData.InvoiceGenerated === "True") {
                tmpArray.push(<option value='1'>Cash</option>);
                tmpArray.push(<option value='13'>Payment Gateway</option>);
                tmpArray.push(<option value='14'>Discount After Bill</option>);
            }
            if (rawData.OutStandingStatus === "0" || rawData.OutStandingStatus === "False" || rawData.OutStandingStatus === "2") {
                tmpArray.push(<option value='15'>Outstanding</option>);
            }
        }
        setPaymentModeId("0");
        setPaymentMode([]);
        setPaymentMode(tmpArray);
        paymode = tmpArray;
        if(paymentamountdetail.current != null){
            paymentamountdetail.current.value = tmpPendingAmount;
        } 
    }
    const ChangePaymentMode = (value) => {
        if (value === "Cash") { setPaymentModeId("1") }
        else if (value === "Discount" || value === "Discount After Bill") { setPaymentModeId("14") }
        else if (value === "Payment Gateway") { setPaymentModeId("13") }
        else if (value === "Outstanding") { setPaymentModeId("15") }
        else { setPaymentModeId(value); }
        if (value === "14" || value === "15") { }
        else { setPercentagecheckbox("0"); }
        paymentamountdetail.current.value = pendingamount;
    }
    const BindPaymentGrid = () => {
        if (paymentmodedetail.current.value == "0") { SweetAlert.fire('Please select payment mode'); return false }
        if (paymentamountdetail.current.value == "") { SweetAlert.fire('Please add payment amount'); return false }
        if (parseFloat(paymentamountdetail.current.value) <= 0) { SweetAlert.fire('Please add payment amount more than ZERO'); return false }
        if ((paymentmodedetail.current.value == "13" || paymentmodedetail.current.value == "Payment Gateway") && paymentChannelId.current.value === "0") { SweetAlert.fire('Please select payment channel'); return false }
        if ((paymentmodedetail.current.value == "13" || paymentmodedetail.current.value == "Payment Gateway") && paymentReferenceNo.current.value === "") {
            if (channelid == "-1" || channelid == "8") { }
            else { SweetAlert.fire('Please add reference no'); return false }
        }
        //if ((paymentmodedetail.current.value == "13" || paymentmodedetail.current.value == "Payment Gateway") && channelid !== "-1" && paymentReferenceNo.current.value === "") { SweetAlert.fire('Please add reference no'); return false }
        if ((paymentmodedetail.current.value == "13" || paymentmodedetail.current.value == "Payment Gateway") && channelid === "-1" && posMachineId.current.value === "0") { SweetAlert.fire('Please select POS machine'); return false }
        var payRefNo = ""; if (paymentReferenceNo.current !== null && paymentReferenceNo.current !== undefined) { payRefNo = paymentReferenceNo.current.value; }
        var _authorizerId = ""; if (authorizerId.current !== null && authorizerId.current !== undefined) { _authorizerId = authorizerId.current.value; }
        var _authorizer = ""; if (authorizerId.current !== null && authorizerId.current !== undefined) { _authorizer = authorizerId.current.options[authorizerId.current.selectedIndex].text; }
        var _concessionReason = ""; if (concessionReason.current !== null && concessionReason.current !== undefined) { _concessionReason = concessionReason.current.value; }
        if ((paymentmodedetail.current.value == "14" || paymentmodedetail.current.value === "Discount After Bill") && settleType.toLowerCase() === "credit") { //paymentmodedetail.current.value == "Discount"
            var selectedAuthorizorInfo = glblDiscountAuthorizer.filter(item => (item.Id === authorizerId.current.value));
            var orderPercent = parseFloat(selectedAuthorizorInfo[0].PerOrderLimit);
            var monthlyAvailableLimit = parseFloat(selectedAuthorizorInfo[0].MonthlyLimit);
            var curntAmount = paymentamountdetail.current.value;
            if (Percentagecheckbox === "1") {
                var crntDiscount = parseFloat(PendingAmountVar) * (parseFloat(paymentamountdetail.current.value) / 100);
                var discpercent = parseFloat(pendingamount) * 100 / (parseFloat(PendingAmountVar));
                if (parseFloat(crntDiscount) > parseFloat(monthlyAvailableLimit)) { SweetAlert.fire('Authorizer does not have sufficient limit to approve this amount.'); return false }
                if (parseFloat(curntAmount) > parseFloat(orderPercent)) { SweetAlert.fire('You can give ' + parseFloat(orderPercent).toFixed(2) + '% discount of pending amount value.'); return false }
                if (parseFloat(crntDiscount) > parseFloat(pendingamount)) { SweetAlert.fire('You can give discount of Rs.' + parseFloat(pendingamount).toFixed(2) + " or " + discpercent + "%  maximum"); return false }
            }
            else {
                var maxOrderAmount = parseFloat(PendingAmountVar) * (parseFloat(orderPercent) / 100);
                if (parseFloat(curntAmount) > parseFloat(maxOrderAmount)) { SweetAlert.fire('You can give ' + parseFloat(orderPercent).toFixed(2) + '% discount of pending amount value.'); return false }
                if (parseFloat(curntAmount) > parseFloat(monthlyAvailableLimit)) { SweetAlert.fire('Authorizer does not have sufficient limit to approve this amount.'); return false }
            }
        }
        // if (paymentmodedetail.current.value == "15" || paymentmodedetail.current.value == "Outstanding") {
        //     var selectedAuthorizorInfo = glblCreditAuthorizer.filter(item => (item.Id === authorizerId.current.value));
        //     var orderPercent = parseFloat(selectedAuthorizorInfo[0].PerOrderLimit);
        //     var monthlyAvailableLimit = parseFloat(selectedAuthorizorInfo[0].MonthlyLimit);
        //     var curntAmount = paymentamountdetail.current.value;
        //     if (Percentagecheckbox === "1") {
        //         var crntOutstanding = parseFloat(PendingAmountVar) * (parseFloat(paymentamountdetail.current.value) / 100);
        //         if (parseFloat(curntAmount) > parseFloat(orderPercent)) { SweetAlert.fire('You can give ' + parseFloat(orderPercent).toFixed(2) + '% outstanding of pending amount value.'); return false }
        //         if (parseFloat(crntOutstanding) > parseFloat(monthlyAvailableLimit)) { SweetAlert.fire('Authorizer does not have sufficient limit to approve this amount.'); return false }
        //         //if (parseFloat(crntOutstanding) > parseFloat(pendingamount)) { SweetAlert.fire('You can use outstanding amount of ' + parseFloat(pendingamount).toFixed(2) + '.'); return false }
        //     }
        //     else {
        //         var maxOrderAmount = parseFloat(PendingAmountVar) * (parseFloat(orderPercent) / 100);
        //         if (parseFloat(curntAmount) > parseFloat(maxOrderAmount)) { SweetAlert.fire('You can give ' + parseFloat(orderPercent).toFixed(2) + '% outstanding of pending amount value.'); return false }
        //         if (parseFloat(curntAmount) > parseFloat(monthlyAvailableLimit)) { SweetAlert.fire('Authorizer does not have sufficient limit to approve this amount.'); return false }
        //     }
        // }
        var pChkbx = "";
        if (Percentagecheckbox === "0") { if (parseFloat(pendingamount) < parseFloat(paymentamountdetail.current.value)) { SweetAlert.fire('Amount cannot be greater than pending amount'); return false } }
        if (Percentagecheckbox === "1") { pChkbx = parseFloat(parseFloat(PendingAmountVar) * (parseFloat(paymentamountdetail.current.value) / 100)).toFixed(2); }
        else { pChkbx = paymentamountdetail.current.value; }
        var txtPayMode = paymentmodedetail.current.options[paymentmodedetail.current.selectedIndex].text;
        var txtPayChannel = "";
        var pymntChnlId = "0";
        if (paymentChannelId.current != undefined) { pymntChnlId = paymentChannelId.current.value; txtPayChannel = paymentChannelId.current.options[paymentChannelId.current.selectedIndex].text; }
        if ((paymentmodedetail.current.value == "13" || paymentmodedetail.current.value == "Payment Gateway") && channelid === "-1" && posMachineId.current.value !== "0") {
            var POSMachineName = posMachineId.current.options[posMachineId.current.selectedIndex].text;
        }
        var posmchnId = "0";
        if (posMachineId.current != undefined) { posmchnId = posMachineId.current.value; }
        /////////////This logic is to stop adding same payment mode again, and this logic replaces below commented logic/////////////////
        if (glblArr.length > 0) {
            var payvalue = paymentmodedetail.current.value == "Cash" ? "1" : (paymentmodedetail.current.value == "Discount" || paymentmodedetail.current.value === "Discount After Bill") ? "14" : paymentmodedetail.current.value === "Payment Gateway" ? "13" : paymentmodedetail.current.value
            var isValueBreak = 0;
            for (var key in glblArr) {
                if (glblArr[key].PaymentModeId !== "13" && isValueBreak === 0) {
                    if (glblArr[key].PaymentModeId === payvalue && isValueBreak === 0) {
                        isValueBreak = 1
                    }
                }
                if (glblArr[key].PaymentModeId === "13" && isValueBreak === 0) {
                    if (paymentChannelId.current != undefined) {
                        if (glblArr[key].PaymentChannelId === paymentChannelId.current.value && isValueBreak === 0) {
                            isValueBreak = 2
                        }
                    }
                }
            }
            if (isValueBreak === 1) {
                SweetAlert.fire("Payment mode already selected, please remove selected mode or change it.");
                return false;
            }
            if (isValueBreak === 2) {
                SweetAlert.fire("Payment channel already selected, please remove selected channel or change it.");
                return false;
            }
        }
        /////////////This logic is to stop adding same payment mode again, and this logic replaces below commented logic/////////////////
        /////////////This logic is build to make 100 % credit and discount amount transfer to another user instead of multiple settlement/////////////////
        if (Percentagecheckbox === "0") {
            if (settleType == "Discount") { if (parseFloat(PendingAmountVar) != parseFloat(paymentamountdetail.current.value) && (paymentmodedetail.current.value == "14" || paymentmodedetail.current.value == "Discount")) { SweetAlert.fire('Please enter total discount amount. You cannot make multiple settlements with discount amount'); return false } }
            if (parseFloat(PendingAmountVar) != parseFloat(paymentamountdetail.current.value) && (paymentmodedetail.current.value == "15" || paymentmodedetail.current.value == "Outstanding")) { SweetAlert.fire('Please enter total outstanding amount. You cannot make multiple settlements with outstanding amount'); return false }
        }
        else {
            var maxOrderAmount = parseFloat(PendingAmountVar) * (parseFloat(paymentamountdetail.current.value) / 100);
            if (settleType == "Discount") { if (parseFloat(PendingAmountVar) != parseFloat(maxOrderAmount) && (paymentmodedetail.current.value == "14" || paymentmodedetail.current.value == "Discount")) { SweetAlert.fire('Please enter total discount amount. You cannot make multiple settlements with discount amount'); return false } }
            if (parseFloat(PendingAmountVar) != parseFloat(maxOrderAmount) && (paymentmodedetail.current.value == "15" || paymentmodedetail.current.value == "Outstanding")) { SweetAlert.fire('Please enter total outstanding amount. You cannot make multiple settlements with outstanding amount'); return false }
        }
        /////////////This logic is build to make 100 % credit and discount amount transfer to another user instead of multiple settlement/////////////////
        if (channelid === "-1") {
            generatepinelabtransactionid(pChkbx.toString());
        }
        else if ((paymentmodedetail.current.value == "13" || paymentmodedetail.current.value == "Payment Gateway") && channelid == "8") {
            GetReferenceLink();
        }
        else {
            glblArr.push({
                OrderId: orderid, PaymentMode: txtPayMode, PaymentModeId: paymentmodedetail.current.value == "Cash" ? "1" : (paymentmodedetail.current.value == "Discount" || paymentmodedetail.current.value == "Discount After Bill") ? "14" : paymentmodedetail.current.value === "Payment Gateway" ? "13" : paymentmodedetail.current.value, PaymentChannelId: pymntChnlId,
                PaidAmount: pChkbx.toString(), ReferenceNo: payRefNo,
                Status: paymentmodedetail.current.options[paymentmodedetail.current.selectedIndex].text == "Discount After Bill" ? "0" : paymentmodedetail.current.value === "14" || paymentmodedetail.current.value.toLowerCase() === "discount" ? "6" : paymentmodedetail.current.value === "15" || paymentmodedetail.current.value.toLowerCase() === "credit" ? "0" : "1",
                OrderStatus: orderstatus, Authorizer: _authorizer, Reason: _concessionReason, POSMachineName: POSMachineName, POSMachineId: posmchnId,
                DiscountOrderDetailId: discountorderdetailid, CreditOrderDetailId: creditorderdetailid, PaymentChannel: txtPayChannel
            });
            setPaymentTable(glblArr);
            setPendingAmount(pendingamount - pChkbx.toString());
            if (paymentmodedetail.current.value == "13" || paymentmodedetail.current.value == "Payment Gateway") {
                if (channelid === "-1") {
                    setPosMachineId(posMachineId.current.value);
                    setPosMachineName(posMachineId.current.options[posMachineId.current.selectedIndex].text);
                }
            }
            paymentmodedetail.current.value = "0";
            paymentamountdetail.current.value = "";
            if (paymentModeId === "13" && channelid === "-1") { paymentChannelId.current.value = "0"; posMachineId.current.value = "0"; }
            else if (paymentModeId === "13" && channelid !== "-1") { paymentChannelId.current.value = "0"; paymentReferenceNo.current.value = ""; }
            setPaymentModeId("0");
            setChannelId("0");
        }
    }
    const RemovePayment = async (PaymentModeId, PaymentChannelId) => {
        newpaymentArr = [];
        SweetAlert.fire({ title: 'Are you sure?', text: "Once deleted, you will not be able to recover!", icon: 'warning', showCancelButton: true, confirmButtonText: 'Ok', cancelButtonText: 'Cancel', reverseButtons: true })
            .then((result) => {
                if (result.value) {
                    if (PaymentModeId === "13") {
                        glblArr = glblArr.filter(function (x) { return x.PaymentChannelId !== PaymentChannelId })
                    }
                    else {
                        glblArr = glblArr.filter(function (x) { return x.PaymentModeId !== PaymentModeId })
                    }
                    setPaymentTable(glblArr);
                    var totalPaidSoFar = 0;
                    for (var x in glblArr) { totalPaidSoFar = totalPaidSoFar + parseFloat(glblArr[x].PaidAmount); }
                    setPendingAmount(PendingAmountVar - totalPaidSoFar);
                    if (glblArr.length === 0) {
                        setPaymentMode(paymode.map((d) => (<option key={d.PaymentId} value={d.PaymentId}>{d.Payment}</option>)));
                    }
                    else {
                        for (var key in glblArr) {
                            newpaymentArr = paymode.filter((d) => d.PaymentId !== glblArr[key].PaymentModeId)
                        }
                        setPaymentMode(newpaymentArr.map((d) => (<option key={d.PaymentId} value={d.PaymentId}>{d.Payment}</option>)));
                    }
                    setPaymentModeId("0");
                    paymentmodedetail.current.value = "0";
                    if (paymentChannelId.current != undefined) { paymentChannelId.current.value = "0"; }
                    setChannelId("0");
                }
                else { SweetAlert.fire('Your data is safe!') }
            });
    }
    const SavePaymentDetails = async () => {
        if (pendingamount != 0) {
            SweetAlert.fire("Please add pending amount");
            return false;
        }
        if (UserCustomerCode == "" || UserCustomerCode == null || UserCustomerCode == undefined) {
            SweetAlert.fire("You are not authorized because your sap code not found");
            return false;
        }
        if (UserCashCode == "" | UserCashCode == null || UserCashCode == undefined) {
            SweetAlert.fire("You are not authorized because your cash code not found");
            return false;
        }
        var PName = "";
        var Age = "";
        var GName = "";
        var PUHID = "";
        var BCentre = "";
        var UName = "";
        var PAmount = "";
        var NAmount = "";
        var NAmount = "";
        var VDate = "";
        var discountauthid = "";
        var creditauthid = "";
        var discountauthname = "";
        var discountauthemail = "";
        var creditauthname = "";
        var creditauthemail = "";
        var modeisavail = "0";
        var concessionReason = "";
        for (var key in paymenttable) {
            if (paymenttable[key].PaymentModeId === "14" || paymenttable[key].PaymentModeId.toLowerCase() === "discount" || paymenttable[key].PaymentModeId === "Discount After Bill") {
                modeisavail = "1";
                discountauthname = discountauthorizerdetails.DiscountAuthorizerName === undefined ? "" : discountauthorizerdetails.DiscountAuthorizerName
                discountauthemail = discountauthorizerdetails.DiscountAuthorizerEmail === undefined ? "" : discountauthorizerdetails.DiscountAuthorizerEmail
                discountauthid = discountauthorizerdetails.DiscountAuthorizerId
                concessionReason = paymenttable[key].Reason
            }
            if (paymenttable[key].PaymentModeId === "15" || paymenttable[key].PaymentModeId.toLowerCase() === "credit") {
                creditauthname = creditauthorizerdetails.CreditAuthorizerName === undefined ? "" : creditauthorizerdetails.CreditAuthorizerName
                creditauthemail = creditauthorizerdetails.CreditAuthorizerEmail === undefined ? "" : creditauthorizerdetails.CreditAuthorizerEmail
                creditauthid = creditauthorizerdetails.CreditAuthorizerId
                concessionReason = paymenttable[key].Reason
            }
            for (var keyy in table) {
                if (paymenttable[key].OrderId === table[keyy].OrderId) {
                    PName = table[keyy].PName;
                    Age = table[keyy].Age;
                    GName = table[keyy].GName;
                    PUHID = table[keyy].PUHID;
                    BCentre = table[keyy].BCentre;
                    UName = table[keyy].UName;
                    PAmount = table[keyy].PaidAmount;
                    NAmount = table[keyy].TotalAmount;
                    VDate = table[keyy].VisitDate;
                }
            }
        }
        var alldataArr = [];
        var pmodeid = "0";
        var pmodeamount = "";
        for (var key in paymenttable) {
            var paymodeid = (paymenttable[key].PaymentModeId === "Discount" || paymenttable[key].PaymentModeId === "Discount After Bill") ? "14" : paymenttable[key].PaymentModeId === "Cash" ? "1" : paymenttable[key].PaymentModeId === "Payment Gateway" ? "13" : paymenttable[key].PaymentModeId === "Credit" ? "15" : paymenttable[key].PaymentModeId
            var paymodeamount = (paymenttable[key].PaymentModeId === "Discount" || paymenttable[key].PaymentModeId === "Discount After Bill" || paymenttable[key].PaymentModeId === "14" || paymenttable[key].PaymentModeId === "15" || paymenttable[key].PaymentModeId === "Credit") ? paymenttable[key].PaidAmount : ""
            alldataArr.push({
                OrderId: paymenttable[key].OrderId,
                PaymentModeId: paymodeid,
                PaymentChannelId: paymenttable[key].PaymentChannelId,
                PaidAmount: paymenttable[key].PaidAmount,
                ReferenceNo: paymenttable[key].ReferenceNo,
                Status: paymenttable[key].Status,
                OrderStatus: paymenttable[key].OrderStatus,
                DiscountOrderDetailId: (settleType == "Discount" || settleType == "Discount After Bill") ? paymenttable[key].DiscountOrderDetailId : "0",
                CreditOrderDetailId: (settleType == "Credit") ? paymenttable[key].CreditOrderDetailId : "0",
            })
            if (paymodeid == "14" || paymodeid == "15") { pmodeid = paymodeid; pmodeamount = paymodeamount; }
        }
        var requestOptions = {
            CentreId: localStorage.CentreSNo,
            LoginId: localStorage.LoggedInUser,
            DiscountAuthorizerName: discountauthname,
            DiscountAuthorizerEmail: discountauthemail,
            CreditAuthorizerName: creditauthname,
            CreditAuthorizerEmail: creditauthemail,
            VisitingId: VisitingId,
            modeisavail: modeisavail,
            DiscountAuthorizerId: (settleType == "Discount" || pmodeid == "14") ? discountauthid : creditauthid,
            SettleType: (settleType == "Discount" || settleType == "Discount After Bill") ? "14" : "15",
            ConcessionReason: concessionReason,
            PName: PName,
            Age: Age,
            GName: GName,
            PUHID: PUHID,
            BCentre: BCentre,
            UName: UName,
            PAmount: PAmount,
            NAmount: NAmount,
            VDate: VDate,
            POSMachineId: posmachineid,
            POSMachineName: posmachinename,
            AllDataArray: JSON.stringify(alldataArr),
            PModeId: pmodeid,
            PModeAmount: pmodeamount,
            RefId: glblrefno
        };
        setIsButtonLoading(true);
        try {
            let url = FrontDesk_URL + 'master/savepaymentdetails'
            await axios.post(url, requestOptions).then((response) => {
                const data = response.data;
                setIsButtonLoading(false);
                if ((paymenttable.length - 1).toString() === key.toString()) {
                    if (data.Success) {
                        SweetAlert.fire({ title: "Success", text: "Data saved successfully", icon: "success" });
                        setOpenModal(!openmodal);
                        const today = new Date();
                        const date = today.setDate(today.getDate());
                        const defaultValue = new Date(date).toISOString().split('T')[0] // yyyy-mm-dd
                        setDefaultDate(defaultValue);
                        GetPendingOrderList(defaultValue, defaultValue);
                    }
                    else {
                        if (data.Data == "-1") {
                            SweetAlert.fire('You had already requested for discount.');
                            return false;
                        }
                        else if (data.Data == "-9990") {
                            SweetAlert.fire('You are not authorized because your sap code not found.');
                            return false;
                        }
                        else if (data.Data == "-9991") {
                            SweetAlert.fire('You are not authorized because your cash code not found.');
                            return false;
                        }
                        else if (data.Data == "-9999") {
                            SweetAlert.fire('Outstanding already settled');
                            setOpenModal(!openmodal);
                            const today = new Date();
                            const date = today.setDate(today.getDate());
                            const defaultValue = new Date(date).toISOString().split('T')[0] // yyyy-mm-dd
                            setDefaultDate(defaultValue);
                            GetPendingOrderList(defaultValue, defaultValue);
                            return false;
                        }
                        else if (data.Data == "-9998") {
                            SweetAlert.fire('Discount already settled');
                            setOpenModal(!openmodal);
                            const today = new Date();
                            const date = today.setDate(today.getDate());
                            const defaultValue = new Date(date).toISOString().split('T')[0] // yyyy-mm-dd
                            setDefaultDate(defaultValue);
                            GetPendingOrderList(defaultValue, defaultValue);
                            return false;
                        }
                        else if (data.Data == "-9997") {
                            SweetAlert.fire('Discount already approved');
                            setOpenModal(!openmodal);
                            const today = new Date();
                            const date = today.setDate(today.getDate());
                            const defaultValue = new Date(date).toISOString().split('T')[0] // yyyy-mm-dd
                            setDefaultDate(defaultValue);
                            GetPendingOrderList(defaultValue, defaultValue);
                            return false;
                        }
                        else if (data.Data == "-9996") {
                            SweetAlert.fire('Outstanding already approved');
                            setOpenModal(!openmodal);
                            const today = new Date();
                            const date = today.setDate(today.getDate());
                            const defaultValue = new Date(date).toISOString().split('T')[0] // yyyy-mm-dd
                            setDefaultDate(defaultValue);
                            GetPendingOrderList(defaultValue, defaultValue);
                            return false;
                        }
                        else { SweetAlert.fire({ title: "Error", text: "Unable to save data.Please try again", icon: "error" }); }
                    }
                }
            }).catch(function (error) {
                setIsButtonLoading(false);
                if (error.response) {
                    ErrorFunction(error.response.status);
                }
            });
        }
        catch (error) {
            setIsButtonLoading(false);
            SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" });
        }
    }
    const handlePercentageCheckbox = (e) => {
        const checkedflg = e.target.checked;
        if (checkedflg) { setPercentagecheckbox("1"); } else { setPercentagecheckbox("0"); }
    };
    const handleSearch = () => {
        if ((fromDate.current.value === "" || fromDate.current.value === undefined) && (toDate.current.value !== "")) {
            glblOrderArr = [];
            setTable([]);
            SweetAlert.fire("Please enter from date");
        }
        else if ((toDate.current.value === "" || toDate.current.value === undefined) && (fromDate.current.value !== "")) {
            glblOrderArr = [];
            setTable([]);
            SweetAlert.fire("Please enter to date");
        }
        else if (fromDate.current.value === "" && toDate.current.value === "" && refmobilenumber.current.value === "" && refvisitid.current.value === "" && refuhid.current.value === "" && refsinno.current.value === "" && refpatientname.current.value === "") {
            glblOrderArr = [];
            setTable([]);
        }
        else if (refmobilenumber.current.value !== "" && (refmobilenumber.current.value.length > 10 || refmobilenumber.current.value.length < 10)) {
            SweetAlert.fire("Please enter correct 10 digit mobile number");
        }
        else { GetPendingOrderList((fromDate.current.value === undefined || fromDate.current.value === "") ? "1900-01-01" : fromDate.current.value, (toDate.current.value === undefined || toDate.current.value === "") ? "1900-01-01" : toDate.current.value); }
        //GetPendingOrderList(fromDate.current.value);
    }
    const DisableTypingkey = (e) => {
        e.preventDefault();
        return false;
    }
    const GetAuthorizer = async (tId) => {
        try {
            let url = FrontDesk_URL + 'master/getauthorizers/' + localStorage.CentreSNo + '/' + tId
            await axios.get(url).then((response) => {
                const data = response.data;
                if (data.Success) {
                    if (tId === "1") {
                        glblDiscountAuthorizer = [];
                        glblDiscountAuthorizer = data.Data;
                        setDiscountAuthorizers(glblDiscountAuthorizer.map((d) => (<option key={d.Id} value={d.Id}>{d.Name}</option>)));
                        setDiscountAuthorizerDetails({ "DiscountAuthorizerId": data.Data[0].Id, "DiscountAuthorizerName": data.Data[0].Name.split("[")[0], "DiscountAuthorizerEmail": data.Data[0].EmailID });
                    } else {
                        glblCreditAuthorizer = [];
                        glblCreditAuthorizer = data.Data;
                        setCreditAuthorizers(glblCreditAuthorizer.map((d) => (<option key={d.Id} value={d.Id}>{d.Name}</option>)));
                        setCreditAuthorizerDetails({ "CreditAuthorizerId": data.Data[0].Id, "CreditAuthorizerName": data.Data[0].Name.split("[")[0], "CreditAuthorizerEmail": data.Data[0].EmailID });
                    }
                }
            }).catch(function (error) { });
        }
        catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    }
    const SetAuthorizerDetails = (e) => {
        if (paymentModeId === "14") {
            for (var key in glblDiscountAuthorizer) {
                if (glblDiscountAuthorizer[key].Id === e.target.value) {
                    setDiscountAuthorizerDetails({
                        "DiscountAuthorizerName": glblDiscountAuthorizer[key].Name.split("[")[0],
                        "DiscountAuthorizerEmail": glblDiscountAuthorizer[key].EmailID,
                        "DiscountAuthorizerId": glblDiscountAuthorizer[key].Id
                    });
                    return false;
                }
            }
        }
        if (paymentModeId === "15") {
            for (var key in glblCreditAuthorizer) {
                if (glblCreditAuthorizer[key].Id === e.target.value) {
                    setCreditAuthorizerDetails({
                        "CreditAuthorizerName": glblCreditAuthorizer[key].Name.split("[")[0],
                        "CreditAuthorizerEmail": glblCreditAuthorizer[key].EmailID,
                        "CreditAuthorizerId": glblCreditAuthorizer[key].Id
                    });
                    return false;
                }
            }
        }
    }
    const ConfirmInvoiceGeneration = async (vId) => {
        try {
            SweetAlert.fire({
                title: 'You are going to generate invoice. Are you sure?',
                text: "Once generated, you will not be able to edit invoice!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Ok',
                cancelButtonText: 'Cancel',
                reverseButtons: true
            }).then((result) => {
                if (result.value) { GenerateInvoice(vId); }
            })
        }
        catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    }
    const GenerateInvoice = async (vId) => {
        try {
            let url = FrontDesk_URL + 'accounts/generateinvoice/' + vId + '/' + localStorage.LoggedInUser
            await axios.get(url).then((response) => {
                const data = response.data;
                if (data.Success) { GetPendingOrderList(); SweetAlert.fire({ title: "Success", text: "Invoice generated successfully", icon: "success" }); }
                else { SweetAlert.fire({ title: "Failed!", text: "Invoice could not be generated, please try again!", icon: "error" }); }
            }).catch(function (error) { SweetAlert.fire({ title: "Exception Occurred!", text: "Some exception occurred, please try again!", icon: "error" }); });
        }
        catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    }
    const openSaveRequiredFieldModal = (VisitingId) => {
        setDocType("0");
        setDocTypeName("");
        setDocumentType([]);
        glblDocArr = [];
        setTableDocData1([]);
        setVisitId(VisitingId);
        openRequiredFieldModal();
        GetDocumentType();
        ShowAllDocuments(VisitingId);
    }
    const SaveRequiredFields = async () => {
        if (tabledocdata1.length == 0 || tabledocdata1 == [] || tabledocdata1 == null) {
            SweetAlert.fire("Please upload document first");
            return false;
        }
        var requestOptions = {
            CentreId: localStorage.CentreSNo,
            LoginId: localStorage.LoggedInUser,
            VisitingId: visitid,
            PrescriptionDocument: JSON.stringify(tabledocdata1)//prescriptiondocument
        };
        setIsButtonLoading(true);
        try {
            let url = FrontDesk_URL + 'master/saveprescriptiondocument'
            await axios.post(url, requestOptions).then((response) => {
                const data = response.data;
                setIsButtonLoading(false);
                if (data.Success) {
                    const today = new Date();
                    const date = today.setDate(today.getDate());
                    const defaultValue = new Date(date).toISOString().split('T')[0] // yyyy-mm-dd
                    setDefaultDate(defaultValue);
                    SweetAlert.fire({
                        title: 'Success',
                        text: "Data saved successfully",
                        icon: 'success',
                        confirmButtonText: 'Ok',
                        reverseButtons: true
                    }).then((result) => {
                        if (result.value) {
                            openRequiredFieldModal();
                            GetPendingOrderList(defaultValue, defaultValue);
                        }
                        else {
                            openRequiredFieldModal();
                            GetPendingOrderList(defaultValue, defaultValue);
                        }
                    })
                }
                else {
                    SweetAlert.fire({ title: "Error", text: "Unable to save data.Please try again", icon: "error" });
                }
            }).catch(function (error) {
                setIsButtonLoading(false);
                if (error.response) {
                    ErrorFunction(error.response.status);
                }
            });
        }
        catch (error) {
            setIsButtonLoading(false);
            SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" });
        }
    }
    const openRequiredFieldModal = () => { setViewReqFieldsModal(!viewreqfieldsModal); }
    const GetSettings = async (cId, uId, defaultValue) => {
        setIsLoading(true);
        try {
            let url = FrontDesk_URL + 'master/getcentresettings/' + cId + '/' + uId;
            await axios.get(url).then((response) => {
                const data = response.data;
                setIsLoading(false);
                if (data.Success) {
                    const settingInfo = data.Data;
                    let hideBookingAmount = false; if (settingInfo.HideBookingAmount === "True") { hideBookingAmount = true; }
                    let printDepartmentSlip = false; if (settingInfo.PrintDepartmentSlip === "True") { printDepartmentSlip = true; }
                    setHideAmountInBookingSetting(hideBookingAmount);
                    setPrintDepartmentSlipSetting(printDepartmentSlip);
                    setParentCentreId(settingInfo.ParentCentreId);
                    setUserPOSCode(settingInfo.UserPOSCode);
                    setUserCustomerCode(settingInfo.UserCustomerCode);
                    setUserCashCode(settingInfo.UserCashCode);
                    paymentModeIdSetting = settingInfo.PaymentModeId;
                    localStorage.setItem('MinBookingCash', settingInfo.MinBookingCash);
                    GetPendingOrderList(defaultValue, defaultValue);
                }
            }).catch(function (error) { setIsLoading(false); });
        }
        catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); setIsLoading(false); }
    }
    const Reset = async () => {
        refmobilenumber.current.value = "";
        refvisitid.current.value = "";
        refuhid.current.value = "";
        refsinno.current.value = "";
        refpatientname.current.value = "";
        const today = new Date();
        const date = today.setDate(today.getDate());
        const defaultValue = new Date(date).toISOString().split('T')[0] // yyyy-mm-dd
        setDefaultDate(defaultValue);
        fromDate.current.value = defaultValue;
        toDate.current.value = defaultValue;
        GetPendingOrderList(defaultValue, defaultValue);
    }
    const editHandler = async (vId) => {
        localStorage.setItem('vId', vId);
        localStorage.setItem('mode', 'saleregister');
        history.push({ pathname: `${process.env.PUBLIC_URL}/lab-report/overview`, search: `` });
    }
    const openEditScreen = async (PatientId, VisitingId) => {
        localStorage.setItem("SRPatientId", PatientId);
        localStorage.setItem("SRVisitingId", VisitingId);
        history.push({ pathname: `${process.env.PUBLIC_URL}/registration`, search: `` });
    }
    const SelectChannel = async (e) => {
        setChannelId(e.target.value);
    }
    const generatepinelabtransactionid = async (amountvalue) => {
        const requestOptions = {
            MobileNo: patientmobile, CentreID: localStorage.CentreSNo, Amount: amountvalue, AddedBy: localStorage.LoggedInUser
        };
        setIsLoading(true);
        let url = PineLabs_URL + 'PaymentPineLabs/SetPineLabsTransaction'
        await axios.post(url, requestOptions).then((response) => {
            const data = response.data;
            setIsLoading(false);
            if (data.Success) {
                var transactionnumber = data.Data;
                uploadtransaction((amountvalue * 100), transactionnumber);
            }
            else { SweetAlert.fire('TransactionId not created, Please try again later.'); }
        }).catch(function (error) {
            setIsLoading(false);
            if (error.response) { ErrorFunction(error.response.status); }
        });
    }
    const uploadtransaction = async (amountvalue, transactionnumber) => {
        var arr = [];
        arr = glblposmachine.filter((d) => d.SNo === posMachineId.current.value);
        const requestOptions = {
            TransactionNumber: transactionnumber, SequenceNumber: "1", AllowedPaymentMode: "0", Amount: amountvalue, AutoCancelDurationInMinutes: "5", UserID: localStorage.LoggedInUser,
            MerchantStorePosCode: arr[0].MerchantStorePosCode, IMEI: arr[0].IMEI
        };
        setIsLoading(true);
        let url = PineLabs_URL + 'PaymentPineLabs/uploadtransaction'
        await axios.post(url, requestOptions).then((response) => {
            const data = response.data;
            setIsLoading(false);
            if (data.Success) {
                if (data.Data === null) {
                    setPlutusTransRefId("0");
                    setTransactionNo("0");
                    setMerchantStorePostCode("");
                    setIMEI("");
                    setHideShowPaymentButton(false);
                    SweetAlert.fire('Runtime error occured, Please try again or contact support team.');
                }
                else {
                    if (data.Data.ResponseCode == "0" || data.Data.ResponseCode == "False") {
                        setPlutusTransRefId(data.Data.PlutusTransactionReferenceID);
                        setTransactionNo(transactionnumber);
                        setMerchantStorePostCode(arr[0].MerchantStorePosCode);
                        setIMEI(arr[0].IMEI);
                        SweetAlert.fire({
                            title: 'Check POS Machine',
                            text: "Payment sent to POS machine",
                            icon: 'success',
                            showCancelButton: false,
                            confirmButtonText: 'Ok',
                            reverseButtons: true
                        }).then((result) => { if (result.value) { setHideShowPaymentButton(true); } else { setHideShowPaymentButton(true); } })

                    }
                    else {
                        setPlutusTransRefId("0");
                        setTransactionNo("0");
                        setMerchantStorePostCode("");
                        setIMEI("");
                        setHideShowPaymentButton(false);
                        SweetAlert.fire(data.Data.ResponseMessage);
                    }
                }
            }
            else {
                setPlutusTransRefId("0");
                setTransactionNo("0");
                setMerchantStorePostCode("");
                setIMEI("");
                setHideShowPaymentButton(false);
                SweetAlert.fire('Transaction failed while pushing to POS.Please try again');
            }
        }).catch(function (error) {
            setIsLoading(false);
            if (error.response) { ErrorFunction(error.response.status); }
        });
    }
    const CheckPaymentStatus = async () => {
        const requestOptions = {
            PlutusTransactionReferenceID: plutustransrefid, TransactionNumber: transactionno, MerchantStorePosCode: merchantstorepostcode, IMEI: imei
        };
        setIsLoading(true);
        let url = PineLabs_URL + 'PaymentPineLabs/TransactionStatus'
        await axios.post(url, requestOptions).then((response) => {
            const data = response.data;
            setIsLoading(false);
            if (data.Success) {
                if (data.Data === null) {
                    paymentmodedetail.current.value = "0";
                    setChannelId("0");
                    setPlutusTransRefId("0");
                    setTransactionNo("0");
                    setMerchantStorePostCode("");
                    setIMEI("");
                    setHideShowPaymentButton(false);
                    SweetAlert.fire('Runtime error occured, Please try again or contact support team.');
                }
                else {
                    if (data.Data.ResponseCode == "0" || data.Data.ResponseCode == "False") {
                        SweetAlert.fire({
                            title: 'Check POS Machine',
                            text: "Payment success from POS machine",
                            icon: 'success',
                            showCancelButton: false,
                            confirmButtonText: 'Ok',
                            reverseButtons: true
                        }).then((result) => {
                            if (result.value) {
                                setHideShowPaymentButton(false);
                                var isBreak = "0";
                                for (var key in data.Data.TransactionData) {
                                    if (data.Data.TransactionData[key].Tag == "TransactionLogId" && isBreak == "0") {
                                        isBreak = "1";
                                        RecallPaymentGrid(data.Data.TransactionData[key].Value);
                                    }
                                }
                            } else {
                                setHideShowPaymentButton(false);
                                var isBreak = "0";
                                for (var key in data.Data.TransactionData) {
                                    if (data.Data.TransactionData[key].Tag == "TransactionLogId" && isBreak == "0") {
                                        isBreak = "1";
                                        RecallPaymentGrid(data.Data.TransactionData[key].Value);
                                    }
                                }
                            }
                        })
                    }
                    else {
                        // setPlutusTransRefId("0");
                        // setTransactionNo("0");
                        // setMerchantStorePostCode("");
                        // setIMEI("");
                        SweetAlert.fire(data.Data.ResponseMessage);
                    }
                }
            }
            else {
                paymentmodedetail.current.value = "0";
                setChannelId("0");
                setPlutusTransRefId("0");
                setTransactionNo("0");
                setMerchantStorePostCode("");
                setIMEI("");
                setHideShowPaymentButton(false);
                SweetAlert.fire('Transaction failed while receiving response from POS.Please try again');
            }
        }).catch(function (error) {
            setIsLoading(false);
            if (error.response) { ErrorFunction(error.response.status); }
        });
    }
    const RecallPaymentGrid = async (posrefnumber) => {
        var _authorizerId = ""; if (authorizerId.current !== null && authorizerId.current !== undefined) { _authorizerId = authorizerId.current.value; }
        var _authorizer = ""; if (authorizerId.current !== null && authorizerId.current !== undefined) { _authorizer = authorizerId.current.options[authorizerId.current.selectedIndex].text; }
        var _concessionReason = ""; if (concessionReason.current !== null && concessionReason.current !== undefined) { _concessionReason = concessionReason.current.value; }
        var txtPayMode = paymentmodedetail.current.options[paymentmodedetail.current.selectedIndex].text;
        var txtPayChannel = "";
        var pymntChnlId = "0";
        if (paymentChannelId.current != undefined) { pymntChnlId = paymentChannelId.current.value; txtPayChannel = paymentChannelId.current.options[paymentChannelId.current.selectedIndex].text; }
        if ((paymentmodedetail.current.value == "13" || paymentmodedetail.current.value == "Payment Gateway") && channelid === "-1" && posMachineId.current.value !== "0") {
            var POSMachineName = posMachineId.current.options[posMachineId.current.selectedIndex].text;
        }
        var posmchnId = "0";
        if (posMachineId.current != undefined) { posmchnId = posMachineId.current.value; }
        glblArr.push({
            OrderId: orderid, PaymentMode: txtPayMode, PaymentModeId: paymentmodedetail.current.value, PaymentChannelId: pymntChnlId,
            PaidAmount: paymentamountdetail.current.value, ReferenceNo: posrefnumber,
            Status: paymentmodedetail.current.options[paymentmodedetail.current.selectedIndex].text == "Discount After Bill" ? "0" : paymentmodedetail.current.value === "14" || paymentmodedetail.current.value.toLowerCase() === "discount" ? "6" : paymentmodedetail.current.value === "15" || paymentmodedetail.current.value.toLowerCase() === "credit" ? "0" : "1",
            OrderStatus: orderstatus, Authorizer: _authorizer, Reason: _concessionReason, POSMachineName: POSMachineName, POSMachineId: posmchnId,
            DiscountOrderDetailId: discountorderdetailid, CreditOrderDetailId: creditorderdetailid, PaymentChannel: txtPayChannel
        });
        setPaymentTable(glblArr);
        setPendingAmount(pendingamount - paymentamountdetail.current.value);
        if (paymentmodedetail.current.value == "13" || paymentmodedetail.current.value == "Payment Gateway") {
            if (channelid === "-1") {
                setPosMachineId(posMachineId.current.value);
                setPosMachineName(posMachineId.current.options[posMachineId.current.selectedIndex].text);
            }
        }
        paymentmodedetail.current.value = "0";
        paymentamountdetail.current.value = "";
        if (paymentModeId === "13" && channelid === "-1") { paymentChannelId.current.value = "0"; posMachineId.current.value = "0"; }
        else if (paymentModeId === "13" && channelid !== "-1") { paymentChannelId.current.value = "0"; paymentReferenceNo.current.value = ""; }
        setPaymentModeId("0");
        newpaymentArr = [];
        if (glblArr.length > 1) { setPaymentMode([]); }
        else {
            for (var key in glblArr) { newpaymentArr = paymode.filter((d) => d.PaymentId !== glblArr[key].PaymentModeId) }
            setPaymentMode(newpaymentArr.map((d) => (<option key={d.PaymentId} value={d.PaymentId}>{d.Payment}</option>)));
        }
        setChannelId("0");
    }
    const openRemarksModal = async (vId) => {
        setRemarksVisitingId(vId);
        setRemarksModal(!remarksModal);
        ViewAllRemarks(vId);
    }
    const ViewAllRemarks = async (vId) => {
        var visitid = (vId == null || vId == undefined || vId == "" ? "0" : vId)
        try {
            let url = FrontDesk_URL + 'master/viewremarks/' + visitid + '/' + localStorage.CentreSNo + '/' + localStorage.LoggedInUser
            await axios.get(url).then((response) => {
                const data = response.data;
                if (data.Success) {
                    if (data.Data != null) { setViewRemarks(data.Data); }
                    else {
                        setViewRemarks([]);
                        // SweetAlert.fire("No record found");
                    }
                } else { setViewRemarks([]); }
            }).catch(function (error) { if (error.response) { setViewRemarks([]); ErrorFunction(error.response.status); } });
        }
        catch (error) { setViewRemarks([]); SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    }
    const SaveRemarks = async () => {
        if (remarksvisitingid == "0") { SweetAlert.fire("Unable to get some values.Please refresh before proceed!"); return false; }
        if (remarks.current.value == null || remarks.current.value == undefined || remarks.current.value == "") { SweetAlert.fire("Please enter remarks"); return false; }
        setIsButtonLoading(true);
        const requestOptions = { Remarks: remarks.current.value, VisitingId: remarksvisitingid, CentreId: localStorage.getItem('CentreSNo'), LoginId: localStorage.getItem('LoggedInUser') };
        let url = FrontDesk_URL + 'master/saveremarks'
        await axios.post(url, requestOptions).then((response) => {
            const data = response.data;
            if (data.Success) {
                if (data.Data !== null) {
                    SweetAlert.fire({
                        title: 'Successful',
                        text: "Remarks saved succesfully",
                        icon: 'success',
                        showCancelButton: false,
                        confirmButtonText: 'Ok',
                        reverseButtons: true
                    }).then((result) => {
                        if (result.value) { window.location.reload(false); } else { window.location.reload(false); }
                    });
                }
                else { SweetAlert.fire("Unable to save data. Please try again."); }
                setIsButtonLoading(false);
            }
            else { SweetAlert.fire({ title: "Application Exception", text: "Some error occured! Check db and server logs", icon: "error" }); setIsButtonLoading(false); }
        }).catch(function (error) { if (error.response) { ErrorFunction(error.response.status); } setIsButtonLoading(false); });
    }
    const docPaginationOptions = {
        sizePerPage: 10,
        showTotal: false,
        firstPageText: 'First',
        prePageText: 'Back',
        nextPageText: 'Next',
        lastPageText: 'Last',
        onPageChange: (page, sizePerPage) => { setDocCrntPageNO(((page - 1) * sizePerPage)); }
    };
    const docPaginationOptions1 = {
        sizePerPage: 10,
        showTotal: false,
        firstPageText: 'First',
        prePageText: 'Back',
        nextPageText: 'Next',
        lastPageText: 'Last',
        onPageChange: (page, sizePerPage) => { setDocCrntPageNO1(((page - 1) * sizePerPage)); }
    };
    const OpenDocument = async (VisitingId, Type, Document) => {
        var prename = "";
        var preurl = "";
        if (Type.toLowerCase() == "doctor prescription") {
            prename = "Prescription_" + VisitingId;
            preurl = Image_Path + "prescription/" + Document;
        }
        else {
            prename = "Prerequisite_" + VisitingId;
            preurl = Image_Path + "prerequisite/" + Document;
        }
        downloadFile(preurl, prename);
    }
    const iddocFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                <span>{doccrntPageNO + rowIndex + 1}</span>
            </div>
        </React.Fragment>
    }
    const iddocFormatter1 = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                <span>{doccrntPageNO1 + rowIndex + 1}</span>
            </div>
        </React.Fragment>
    }
    const docFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                <button className='btn btn-link' style={{ color: '#e31e25' }} id={`Documents${rowData.RowId}`} onClick={() => OpenDocument(rowData.VisitingId, rowData.Type, rowData.Document)}>View Document</button>
            </div>
        </React.Fragment>
    }
    const docFormatter1 = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                <button className='btn btn-link' style={{ color: '#e31e25' }} id={`Documents${rowData.RowId}`} onClick={() => OpenDocument(rowData.VisitingId, rowData.Type, rowData.Document)}>View Document</button>
            </div>
        </React.Fragment>
    }
    const docColumns = [
        { dataField: '#', text: '#', editable: false, formatter: iddocFormatter, headerStyle: (colum, colIndex) => { return { width: '2%' }; } },
        { dataField: 'Type', text: 'Type', editable: false, headerStyle: (colum, colIndex) => { return { width: '5%' }; } },
        { dataField: 'actions', text: 'Actions', editable: false, formatter: docFormatter, headerStyle: (colum, colIndex) => { return { width: '5%' }; } }
    ];
    const docColumns1 = [
        { dataField: '#', text: '#', editable: false, formatter: iddocFormatter1, headerStyle: (colum, colIndex) => { return { width: '2%' }; } },
        { dataField: 'Type', text: 'Type', editable: false, headerStyle: (colum, colIndex) => { return { width: '5%' }; } },
        { dataField: 'actions', text: 'Actions', editable: false, formatter: docFormatter1, headerStyle: (colum, colIndex) => { return { width: '5%' }; } }
    ];
    const ChangeDocType = async (e) => {
        setDocType(e.target.value);
        setDocTypeName(e.target.options[e.target.selectedIndex].text);
    }
    const GetDocumentType = async () => {
        try {
            let url = FrontDesk_URL + 'master/GetDocumentType/' + localStorage.CentreSNo
            await axios.get(url).then((response) => {
                const data = response.data;
                if (data.Success) {
                    if (data.Data == null) { SweetAlert.fire("No document type available"); }
                    else { setDocumentType(data.Data.map((d) => (<option key={d.DocumentId} value={d.DocumentId}>{d.DocumentName}</option>))); }
                }
                else { setDocumentType([]); SweetAlert.fire("Error occured due to: " + data.Data); }
            }).catch(function (error) {
                setDocumentType([]);
                if (error.response) {
                    ErrorFunction(error.response.status);
                }
            });
        }
        catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    }
    const GetReferenceLink = async () => {
        var grandtotalamount = paymentamountdetail.current.value;
        var unixtimstamp = Math.floor(new Date().getTime() * 1000);
        var description = "0";
        var centreusername = (patientname == "" || patientname == null || patientname == undefined) ? "0" : patientname;
        var mobileno = (patientmobile == "" || patientmobile == null || patientmobile == undefined) ? "0" : patientmobile;
        var email = (patientemail == "" || patientemail == null || patientemail == undefined) ? "0" : patientemail;
        setIsButtonLoading(true);
        try {
            let url = FrontDesk_URL + 'accounts/getphlebopaymenturl/' + grandtotalamount.toString() + '/' + unixtimstamp.toString() + '/' + description + '/' + centreusername + '/' + mobileno + '/' + email + '/' + logincentreid + '/' + loginuserid
            await axios.get(url).then((response) => {
                const data = response.data;
                setIsButtonLoading(false);
                if (data.Success) {
                    if (data.Data == null) { SweetAlert.fire("Some error occured! Please try again after sometime"); }
                    else {
                        if (data.Data == "-1001") {
                            SweetAlert.fire("Currently don't have permission to access this feature. Please contact your admin.");
                        }
                        else {
                            CallExternalPaymentLink(data.Data.ReferenceId, data.Data.PaymentKey, grandtotalamount, unixtimstamp, description, centreusername, mobileno, email);
                        }
                    }
                }
                else {
                    setIsButtonLoading(false);
                    if (data.Data == "0") { SweetAlert.fire("Payment link already sent to user.Click razorpay pending status button"); }
                    else { SweetAlert.fire("Error Code: " + data.Data + " Check db logs!"); }
                }
            }).catch(function (error) { setIsButtonLoading(false); if (error.response) { ErrorFunction(error.response.status); } });
        } catch (error) { setIsButtonLoading(false); SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    }
    const CallExternalPaymentLink = async (refId, paymentKey, amount, expiry, description, centreusername, mobileno, email) => {
        amount = parseFloat(amount) * 100;
        try {
            let url = FrontDesk_URL + 'accounts/callexternalpaymentlinkphlebo/' + refId + '/' + amount.toString() + '/' + expiry.toString() + '/' + description + '/' + (centreusername == "" ? "0" : centreusername) + '/' + (mobileno == "" ? "0" : mobileno) + '/' + (email == "" ? "0" : email)
            await axios.get(url).then((response) => {
                const data = response.data;
                setIsButtonLoading(false);
                if (data.Success) {
                    if (data.Data == "-1001") { setHideShowPaymentButton(false); SweetAlert.fire("Currently don't have permission to access this feature. Please contact your admin."); }
                    else {
                        setGlblRefNo(refId);
                        setHideShowPaymentButton(true);
                        SweetAlert.fire("Payment link sent to patient mobile number");
                        //window.open(data.Data, "_blank");
                    }
                }
                else {
                    setGlblRefNo("");
                    setIsButtonLoading(false);
                    setHideShowPaymentButton(false);
                    if (data.Data == "-2") { SweetAlert.fire("No payment link generated.Please try again"); }
                    else { SweetAlert.fire("Error : " + data.Data + " Check logs!"); }
                }
            }).catch(function (error) { setHideShowPaymentButton(false); setIsButtonLoading(false); if (error.response) { ErrorFunction(error.response.status); } });
        } catch (error) { setHideShowPaymentButton(false); setIsButtonLoading(false); SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    }
    const CheckPaymentStatusRazor = async () => {
        if (glblrefno == "") {
            SweetAlert.fire("Reference no is empty to check status.Please try again");
            return false;
        }
        setIsLoading(true);
        let url = FrontDesk_URL + 'accounts/getphlebopaymentandupdatestatus/' + glblrefno
        await axios.get(url).then((response) => {
            const data = response.data;
            setIsLoading(false);
            if (data.Success) {
                if (data.Data == "-1001") { setHideShowPaymentButton(false); SweetAlert.fire("Currently don't have permission to access this feature. Please contact your admin."); }
                else {
                    if (data.Data == "-2") { SweetAlert.fire("Payment cancelled.Please try again."); }
                    else if (data.Data == "-3") { SweetAlert.fire("Payment expired.Please try again."); }
                    else if (data.Data == "0") { SweetAlert.fire("Payment still pending."); }
                    else if (data.Data == "-2000") { SweetAlert.fire("Some error occured.Check db logs"); }
                    else {
                        setHideShowPaymentButton(false);
                        const referenceno = data.Data
                        paymentReferenceNo.current.value = referenceno;
                        RecallPaymentGrid(referenceno);
                    }
                }
            }
            else {
                SweetAlert.fire('Transaction failed while receiving response from razorpay.Please try again');
            }
        }).catch(function (error) {
            setIsLoading(false);
            if (error.response) { ErrorFunction(error.response.status); }
        });
    }
    const CheckExistingRazorPayment = async () => {
        if (patientmobile == "" || patientmobile == null || patientmobile == undefined) {
            SweetAlert.fire("Patient mobile is mandatory");
            return false;
        }
        setIsRazorLoading(true);
        let url = FrontDesk_URL + 'accounts/checkexistingrazorpayment/' + patientmobile
        await axios.get(url).then((response) => {
            const data = response.data;
            setIsRazorLoading(false);
            if (data.Success) {
                if (data.Data == null) { SweetAlert.fire("No record found"); }
                else { setGlblRefNo(data.Data.ReferenceNo); CheckPaymentStatusRazorRecall(data.Data.ReferenceNo, data.Data.Amount); }
            }
            else {
                if (data.Data != null) { SweetAlert.fire("Error is: " + data.Data); }
                else { SweetAlert.fire("Some error occured.Check db logs"); }
            }
        }).catch(function (error) {
            setIsRazorLoading(false);
            if (error.response) { ErrorFunction(error.response.status); }
        });
    }
    const CheckPaymentStatusRazorRecall = async (refno, amount) => {
        if (refno == "") {
            SweetAlert.fire("Reference no is empty to check status.Please try again");
            return false;
        }
        setIsRazorLoading(true);
        let url = FrontDesk_URL + 'accounts/getphlebopaymentandupdatestatus/' + refno
        await axios.get(url).then((response) => {
            const data = response.data;
            setIsRazorLoading(false);
            if (data.Success) {
                if (data.Data == "-1001") { SweetAlert.fire("Currently don't have permission to access this feature. Please contact your admin."); }
                else {
                    if (data.Data == "-2") { SweetAlert.fire("Payment cancelled.Please try again."); }
                    else if (data.Data == "-3") { SweetAlert.fire("Payment expired.Please try again."); }
                    else if (data.Data == "0") { SweetAlert.fire("Payment still pending."); }
                    else if (data.Data == "-2000") { SweetAlert.fire("Some error occured.Check db logs"); }
                    else {
                        const referenceno = data.Data
                        paymentReferenceNo.current.value = referenceno;
                        RecallPaymentGridNew(referenceno, amount);
                    }
                }
            }
            else {
                SweetAlert.fire('Transaction failed while receiving response from razorpay.Please try again');
            }
        }).catch(function (error) {
            setIsRazorLoading(false);
            if (error.response) { ErrorFunction(error.response.status); }
        });
    }
    const RecallPaymentGridNew = async (posrefnumber,amount) => {
        var _authorizerId = ""; if (authorizerId.current !== null && authorizerId.current !== undefined) { _authorizerId = authorizerId.current.value; }
        var _authorizer = ""; if (authorizerId.current !== null && authorizerId.current !== undefined) { _authorizer = authorizerId.current.options[authorizerId.current.selectedIndex].text; }
        var _concessionReason = ""; if (concessionReason.current !== null && concessionReason.current !== undefined) { _concessionReason = concessionReason.current.value; }
        var txtPayMode = paymentmodedetail.current.options[paymentmodedetail.current.selectedIndex].text;
        var txtPayChannel = "";
        var pymntChnlId = "0";
        if (paymentChannelId.current != undefined) { pymntChnlId = paymentChannelId.current.value; txtPayChannel = paymentChannelId.current.options[paymentChannelId.current.selectedIndex].text; }
        if ((paymentmodedetail.current.value == "13" || paymentmodedetail.current.value == "Payment Gateway") && channelid === "-1" && posMachineId.current.value !== "0") {
            var POSMachineName = posMachineId.current.options[posMachineId.current.selectedIndex].text;
        }
        var posmchnId = "0";
        if (posMachineId.current != undefined) { posmchnId = posMachineId.current.value; }
        glblArr.push({
            OrderId: orderid, PaymentMode: txtPayMode, PaymentModeId: paymentmodedetail.current.value, PaymentChannelId: pymntChnlId,
            PaidAmount: amount, ReferenceNo: posrefnumber,
            Status: paymentmodedetail.current.options[paymentmodedetail.current.selectedIndex].text == "Discount After Bill" ? "0" : paymentmodedetail.current.value === "14" || paymentmodedetail.current.value.toLowerCase() === "discount" ? "6" : paymentmodedetail.current.value === "15" || paymentmodedetail.current.value.toLowerCase() === "credit" ? "0" : "1",
            OrderStatus: orderstatus, Authorizer: _authorizer, Reason: _concessionReason, POSMachineName: POSMachineName, POSMachineId: posmchnId,
            DiscountOrderDetailId: discountorderdetailid, CreditOrderDetailId: creditorderdetailid, PaymentChannel: txtPayChannel
        });
        setPaymentTable(glblArr);
        setPendingAmount(pendingamount - amount);
        if (paymentmodedetail.current.value == "13" || paymentmodedetail.current.value == "Payment Gateway") {
            if (channelid === "-1") {
                setPosMachineId(posMachineId.current.value);
                setPosMachineName(posMachineId.current.options[posMachineId.current.selectedIndex].text);
            }
        }
        paymentmodedetail.current.value = "0";
        paymentamountdetail.current.value = "";
        if (paymentModeId === "13" && channelid === "-1") { paymentChannelId.current.value = "0"; posMachineId.current.value = "0"; }
        else if (paymentModeId === "13" && channelid !== "-1") { paymentChannelId.current.value = "0"; paymentReferenceNo.current.value = ""; }
        setPaymentModeId("0");
        newpaymentArr = [];
        if (glblArr.length > 1) { setPaymentMode([]); }
        else {
            for (var key in glblArr) { newpaymentArr = paymode.filter((d) => d.PaymentId !== glblArr[key].PaymentModeId) }
            setPaymentMode(newpaymentArr.map((d) => (<option key={d.PaymentId} value={d.PaymentId}>{d.Payment}</option>)));
        }
        setChannelId("0");
    }
    const ErrorFunction = async (ErrorCode) => {
        if (ErrorCode == "400")
            SweetAlert.fire({ title: "Error 400", text: "Bad request", icon: "error" })
        else if (ErrorCode == "401")
            SweetAlert.fire({ title: "Error 401", text: "Unauthorized", icon: "error" })
        else if (ErrorCode == "403")
            SweetAlert.fire({ title: "Error 403", text: "Forbidden", icon: "error" })
        else if (ErrorCode == "404")
            SweetAlert.fire({ title: "Error 404", text: "Page not found", icon: "error" })
        else if (ErrorCode == "500")
            SweetAlert.fire({ title: "Error 500", text: "Internal Server Error", icon: "error" })
        else if (ErrorCode == "503")
            SweetAlert.fire({ title: "Error 503", text: "Service Unavailable", icon: "error" })
    }
    return (
        <>
            <Breadcrumb title="Sale register" parent="Work Order" />
            <Form>
                <Container fluid>
                    <Row>
                        <Col md={12}>
                            <Card>
                                <Card.Body>
                                    <Form>
                                        <Form.Row>
                                            <Col lg={3} md={6}>
                                                <Form.Group>
                                                    <Form.Label>Mobile Number:</Form.Label>
                                                    <Form.Control type="number" min={0} id="mobilenumber" name="mobilenumber" ref={refmobilenumber} />
                                                </Form.Group>
                                            </Col>
                                            <Col lg={3} md={6}>
                                                <Form.Group>
                                                    <Form.Label>Visit No:</Form.Label>
                                                    <Form.Control type="text" id="visitid" name="visitid" ref={refvisitid} />
                                                </Form.Group>
                                            </Col>
                                            <Col lg={3} md={6}>
                                                <Form.Group>
                                                    <Form.Label>UHID:</Form.Label>
                                                    <Form.Control type="text" id="uhid" name="uhid" ref={refuhid} />
                                                </Form.Group>
                                            </Col>
                                            <Col lg={3} md={6}>
                                                <Form.Group>
                                                    <Form.Label>SIN No:</Form.Label>
                                                    <Form.Control type="text" id="sinno" name="sinno" ref={refsinno} />
                                                </Form.Group>
                                            </Col>
                                            <Col lg={3} md={6}>
                                                <Form.Group>
                                                    <Form.Label>Patient Name:</Form.Label>
                                                    <Form.Control type="text" id="patientname" name="patientname" ref={refpatientname} />
                                                </Form.Group>
                                            </Col>
                                            <Col lg={3} md={6}>
                                                <Form.Group>
                                                    <Form.Label>From date:</Form.Label>
                                                    <Form.Control type="date" id="date1" name="date1" ref={fromDate} defaultValue={defaultDate} />
                                                </Form.Group>
                                            </Col>
                                            <Col lg={3} md={6}>
                                                <Form.Group>
                                                    <Form.Label>To date:</Form.Label>
                                                    <Form.Control type="date" id="date2" name="date2" ref={toDate} defaultValue={defaultDate} />
                                                </Form.Group>
                                            </Col>
                                        </Form.Row>
                                        <Form.Row className='mt15'>
                                            <Col md={12} className='text-center'>
                                                <Button variant='secondary' onClick={Reset} className='mr-2'>Reset</Button>
                                                <Button variant='primary' onClick={handleSearch}>Search</Button>
                                            </Col>
                                        </Form.Row>
                                    </Form>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <Card>
                                <Card.Header className='pb-0'>
                                    <Form.Row>
                                        <Col md={8}>
                                            <Card.Title>Order Details</Card.Title>
                                        </Col>
                                    </Form.Row>
                                </Card.Header>
                                <Card.Body>
                                    {
                                        isLoading ? <div className='text-center'> <Spinner animation="border" size='sm' /></div> :
                                            <DataTables
                                                keyField='OrderId'
                                                tableData={table}
                                                columns={columns}
                                                noDataIndication={NoRecordFounds}
                                                pagination={paginationFactory(popupPaginationOptions)}
                                            />
                                    }
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </Form>
            {
                openmodal &&
                <Modal isOpen={openmodal} toggle={openSettlePaymentModal} centered={true} size="lg">
                    <ModalHeader toggle={openSettlePaymentModal}>Settle Payment</ModalHeader>
                    <ModalBody>
                        <Form.Row>
                            <Col md={3}>
                                <Form.Group>
                                    <Form.Label>Payment Mode</Form.Label>
                                    <Form.Control as="select" ref={paymentmodedetail} onChange={(e) => { ChangePaymentMode(e.target.value) }} >
                                        <option key="0" value="0">----Select-----</option>
                                        {paymentmode}
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                            {
                                //(paymentModeId === "14" || paymentModeId === "15") && paymentModeIdSetting !== "2" ?
                                paymentModeId === "14" && settleType.toLowerCase() === "credit" && paymentModeIdSetting !== "2" ?
                                    <Col md={3}>
                                        <Form.Group style={{ marginTop: 35, marginLeft: 20 }}>
                                            <input className="checkbox_animated" id="inpercentage" type="checkbox" onClick={(e) => { handlePercentageCheckbox(e); }} /> In Percentage
                                        </Form.Group>
                                    </Col> :
                                    null
                            }
                            {Percentagecheckbox === "0" ?
                                <>
                                    {
                                        paymentModeId === "14" && settleType.toLowerCase() === "discount" ?
                                            <Col md={3}>
                                                <Form.Group>
                                                    <Form.Label>Amount</Form.Label>
                                                    <Form.Control type="text" ref={paymentamountdetail} disabled />
                                                </Form.Group>
                                            </Col> :
                                            paymentModeId === "15" && settleType.toLowerCase() === "credit" ?
                                                <Col md={3}>
                                                    <Form.Group>
                                                        <Form.Label>Amount</Form.Label>
                                                        <Form.Control type="text" ref={paymentamountdetail} disabled />
                                                    </Form.Group>
                                                </Col> :
                                                <Col md={3}>
                                                    <Form.Group>
                                                        <Form.Label>Amount</Form.Label>
                                                        <Form.Control type="text" ref={paymentamountdetail} />
                                                    </Form.Group>
                                                </Col>
                                    }
                                </>
                                :
                                <Col md={3}>
                                    <Form.Group>
                                        <Form.Label>In Percentage Amount</Form.Label>
                                        <Form.Control type="text" ref={paymentamountdetail} />
                                    </Form.Group>
                                </Col>
                            }
                            {paymentModeId === "13" ?
                                <>
                                    <Col md={3}>
                                        <Form.Group>
                                            <Form.Label>Payment Channel</Form.Label>
                                            <Form.Control as="select" ref={paymentChannelId} onChange={(e) => SelectChannel(e)}>
                                                {<option value="0">----Select-----</option>}
                                                {UserPOSCode === "" ? null : <option value="-1">POS</option>}
                                                {paymentChannels}
                                            </Form.Control>
                                        </Form.Group>
                                    </Col>
                                    {
                                        channelid === "0" ? null :
                                            <>
                                                {
                                                    channelid === "-1" || channelid == "8" ?
                                                        <Col md={3}>
                                                            <Form.Group>
                                                                <Form.Label>Reference No. <span className='text-red'>(Max 50 characters)</span></Form.Label>
                                                                <Form.Control type="text" maxLength={50} ref={paymentReferenceNo} disabled />
                                                            </Form.Group>
                                                        </Col> :
                                                        <Col md={3}>
                                                            <Form.Group>
                                                                <Form.Label>Reference No. <span className='text-red'>(Max 50 characters)</span></Form.Label>
                                                                <Form.Control type="text" maxLength={50} ref={paymentReferenceNo} />
                                                            </Form.Group>
                                                        </Col>
                                                }
                                            </>
                                    }
                                </>
                                // : (paymentModeId === "14" || paymentModeId === "15") && paymentModeIdSetting !== "2" ?
                                : paymentModeId === "14" || paymentModeId === "15" && paymentModeIdSetting !== "2" ? //&& settleType.toLowerCase() === "credit" 
                                    <>
                                        <Col md={3}>
                                            <Form.Group>
                                                <Form.Label>{paymentModeId === "14" ? "Discount Authorizer" : "Credit Authorizer"}</Form.Label>
                                                <Form.Control as="select" ref={authorizerId} onChange={(e) => SetAuthorizerDetails(e)}>
                                                    {paymentModeId === "14" ? discountAuthorizers : creditAuthorizers}
                                                </Form.Control>
                                            </Form.Group>
                                        </Col>
                                        <Col md={3}>
                                            <Form.Group>
                                                <Form.Label>{paymentModeId === "14" ? "Discount" : "Credit"} Reason <span className='text-red'>(Max 100 characters allowed)</span></Form.Label>
                                                <Form.Control type="text" ref={concessionReason} maxLength={100} />
                                            </Form.Group>
                                        </Col>
                                    </>
                                    : null}
                            {
                                paymentModeId === "13" && channelid === "-1" ?
                                    <Col md={2}>
                                        <Form.Group>
                                            <Form.Label>POS Machine</Form.Label>
                                            <Form.Control as="select" ref={posMachineId} >
                                                {<option value="0">----Select-----</option>}
                                                {posmachines}
                                            </Form.Control>
                                        </Form.Group>
                                    </Col>
                                    : null
                            }
                            {
                                hideshowpaymentbutton === false ?
                                    <>
                                        <Col md={2} xs={11}>
                                            <Form.Group className='m-t-15'>
                                                <Form.Label className='icRight'>
                                                    <Button variant='secondary' onClick={BindPaymentGrid}>Add</Button>
                                                </Form.Label>
                                            </Form.Group>
                                        </Col>
                                        {
                                            channelid == "8" ?
                                                <Col md={2} xs={11}>
                                                    <Form.Group className='m-t-15'>
                                                        <Form.Label className='icRight'>
                                                            {isRazorLoading ? <LoadingButton variantName='secondary' cssName="" /> :
                                                                <Button variant='primary' onClick={CheckExistingRazorPayment}>Check razorpay pending status</Button>}
                                                        </Form.Label>
                                                    </Form.Group>
                                                </Col> : null
                                        }
                                    </>
                                    :
                                    channelid == "8" ?
                                        <Col md={2} xs={11}>
                                            <Form.Group className='m-t-15'>
                                                <Form.Label className='icRight'>
                                                    {isLoading ? <LoadingButton variantName='secondary' cssName="" /> :
                                                        <Button variant='secondary' onClick={CheckPaymentStatusRazor}>Check payment status</Button>}
                                                </Form.Label>
                                            </Form.Group>
                                        </Col> :
                                        <Col md={2} xs={11}>
                                            <Form.Group className='m-t-15'>
                                                <Form.Label className='icRight'>
                                                    {isLoading ? <LoadingButton variantName='secondary' cssName="" /> :
                                                        <Button variant='secondary' onClick={CheckPaymentStatus}>Check payment status</Button>}
                                                </Form.Label>
                                            </Form.Group>
                                        </Col>
                            }
                        </Form.Row>
                        <div className='text-right'>
                            <b>Pending Amount:</b> <span className='text-danger mr-2'>{pendingamount}</span>
                        </div>
                        <div className="table-responsive mt15">
                            {/* <DataTables keyField='PaymentModeId' tableData={paymenttable} columns={paymentColumns} noDataIndication={NoRecordFounds} /> */}
                            <DataTables keyField='Id' tableData={paymenttable} columns={paymentColumns} noDataIndication={NoRecordFounds} />
                        </div>
                        {
                            paymenttable.length > 0 ?
                                <>
                                    <Row>
                                        <Col md={12} className='text-center'>
                                            {isButtonLoading ? <LoadingButton variantName='primary' cssName="" /> : <Button variant='primary' onClick={SavePaymentDetails}>Save</Button>}
                                        </Col>
                                    </Row>
                                </>
                                :
                                null
                        }
                    </ModalBody>
                </Modal>
            }
            {
                viewreqfieldsModal &&
                <Modal isOpen={viewreqfieldsModal} toggle={openRequiredFieldModal} centered={true} size="lg">
                    <ModalHeader toggle={openRequiredFieldModal}>View/Upload Documents</ModalHeader>
                    <ModalBody className='modalvariation'>
                        {
                            tabledocdata == [] || tabledocdata == null || tabledocdata.length == 0 ? null :
                                <Row>
                                    <Col md={12}>
                                        <Card>
                                            <Card.Header>
                                                <Row className='align-items-center'>
                                                    <Col xs={6}>
                                                        <h3 className='routeSN'>View Documents</h3>
                                                    </Col>
                                                </Row>
                                            </Card.Header>
                                            <Card.Body>
                                                <DataTables
                                                    keyField='RowId'
                                                    tableData={tabledocdata}
                                                    columns={docColumns}
                                                    noDataIndication={NoRecordFounds}
                                                    pagination={paginationFactory(docPaginationOptions)} />

                                            </Card.Body>
                                        </Card>
                                    </Col>
                                </Row>
                        }
                        <Row>
                            <Col md={12}>
                                <Card>
                                    <Card.Header>
                                        <Row className='align-items-center'>
                                            <Col xs={6}>
                                                <h3 className='routeSN'>Upload Documents</h3>
                                            </Col>
                                        </Row>
                                    </Card.Header>
                                    <Card.Body>
                                        <Form.Row>
                                            <Col md={4}>
                                                <Form.Group>
                                                    <Form.Label>Document Type <span className='text-red'>*</span></Form.Label>
                                                    <Form.Control as="select" ref={doctypee} onChange={(e) => { ChangeDocType(e) }}>
                                                        <option key="0" value="0">---Select---</option>
                                                        {documenttype}
                                                    </Form.Control>
                                                </Form.Group>
                                            </Col>
                                            {
                                                doctype == "0" ? null :
                                                    <Col md={4}>
                                                        <Form.Group>
                                                            <Form.Label>{doctypename} <span className='text-red'>*</span></Form.Label>
                                                            <div><input type="file" id='up_prescription' onChange={(e) => UploadPrescription(e)} /></div>
                                                        </Form.Group>
                                                    </Col>
                                            }
                                        </Form.Row>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12}>
                                {
                                    prescriptiondocument == "" || prescriptiondocument == null ? null :
                                        <Row>
                                            <Col md={12}>
                                                <Card>
                                                    <Card.Header>
                                                        <Row className='align-items-center'>
                                                            <Col xs={6}>
                                                                <h3 className='routeSN'>View Uploaded Documents</h3>
                                                            </Col>
                                                        </Row>
                                                    </Card.Header>
                                                    <Card.Body>
                                                        <DataTables
                                                            keyField='RowId'
                                                            tableData={tabledocdata1}
                                                            columns={docColumns1}
                                                            noDataIndication={NoRecordFounds}
                                                            pagination={paginationFactory(docPaginationOptions1)} />
                                                    </Card.Body>
                                                </Card>
                                            </Col>
                                        </Row>
                                }
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12} className='text-center'>
                                <Button variant='secondary' className="mr-2" onClick={openRequiredFieldModal}>Cancel</Button>
                                {
                                    prescriptiondocument == "" ? null :
                                        <>
                                            {
                                                isButtonLoading ?
                                                    <LoadingButton variantName='primary' cssName="" /> :
                                                    <Button variant='primary' type='submit' onClick={(e) => SaveRequiredFields()}>Save</Button>
                                            }
                                        </>
                                }
                            </Col>
                        </Row>
                    </ModalBody>
                </Modal>
            }
            {/* {
                    viewremarks == [] || viewremarks == null || viewremarks == undefined || viewremarks.length == 0 ? null : */}
            <Modal isOpen={remarksModal} toggle={openRemarksModal} centered={true} size="lg">
                <ModalHeader toggle={openRemarksModal}>Add/View Remarks</ModalHeader>
                <ModalBody className='modalvariation'>
                    {
                        viewremarks == [] || viewremarks == null || viewremarks == undefined || viewremarks.length == 0 ? null :
                            viewremarks.map((d) =>
                                <Row>
                                    <Col md={12}>
                                        <Card className='routeCard'>
                                            <Card.Header>
                                                <Row className='align-items-center'>
                                                    <Col xs={6}>
                                                        <h3 className='routeSN'>{d.IsLISBSLFlag}</h3>
                                                    </Col>
                                                </Row>
                                            </Card.Header>
                                            <Card.Body>
                                                <Row>
                                                    <Col xs={12}>
                                                        <p><b>Remarks:</b> {d.Remarks}</p>
                                                        <p><b>DateTime:</b> {d.RemarksDateTime}</p>
                                                    </Col>
                                                </Row>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                </Row>
                            )
                    }
                    <Row>
                        <Col md={12} className='mt-5'>
                            <Form.Group>
                                <Form.Label>Remarks <span className='text-red'>(Max length 500 characters allowed)</span></Form.Label>
                                <Form.Control as="textarea" rows={3} ref={remarks} maxLength={500} id="remarksnew" name="remarks" />
                            </Form.Group>
                        </Col>
                        <Col md={12} className='mt-2 text-center'>
                            {isButtonLoading ? <LoadingButton variantName='primary' cssName="" /> : <Button variant='primary' className="mr-2" onClick={SaveRemarks}>Save</Button>}
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>
            {/* } */}
        </>
    )
}

export default SaleRegister