import React, { useEffect, useState, useRef } from 'react';
import Breadcrumb from '../../components/common/breadcrumb';
import { URL_Start, FrontDesk_URL } from '../../constant';
import { Container, Row, Col, Card, Form, Button } from 'react-bootstrap';
import axios from 'axios';
import SweetAlert from 'sweetalert2';
import InputGroup from 'react-bootstrap/InputGroup';
import { Spinner } from 'react-bootstrap';
import DataTables from '../commonComponent/DataTable/DataTables';
import NoRecordFounds from '../commonComponent/DataTable/NoRecordFounds';
import paginationFactory from 'react-bootstrap-table2-paginator';
import DownloadToolTip from '../UI/ToolTips/DownloadToolTip';
import SelfInactiveToolTip from '../UI/ToolTips/InactiveToolTip';
let glbCreditCentreTypes = [];

const BulkInvoice = () => {
    // const [centres, setCentres] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    // const [partnerTypeList, setPartnerTypeList] = useState([]);
    // const [CenterTypeId, setCenterTypeId] = useState(false);
    const [defaultDate, setDefaultDate] = useState("");
    const [tableData, setTableData] = useState([]);
    const [crntPageNO, setCrntPageNO] = useState(0);
    let fromDate = useRef();
    let toDate = useRef();
    useEffect(() => {
        const today = new Date();
        const date = today.setDate(today.getDate());
        const defaultValue = new Date(date).toISOString().split('T')[0] // yyyy-mm-dd
        setDefaultDate(defaultValue);
    }, []);
    
    const idFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                <span>{crntPageNO + rowIndex + 1}</span>
            </div>
        </React.Fragment>
    }
    const actionFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <DownloadToolTip title="Download Invoice" id={`DownloadInvoice${rowData.VisitingId}`} placement="top" onClick={() => DownloadHandler(rowData)} />
            {/* {
                rowData.InvoiceStatus.toLowerCase() == "active" ? <SelfInactiveToolTip title="Cancel Invoice" id={`CancelInvoice${rowData.VisitingId}`} placement="top" onClick={() => CancelHandler(rowData)} /> : null
            } */}
        </React.Fragment>
    }
    const TypeFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            {rowData.InvoiceType == "1"?"Invoice":"Credit Note"}
        </React.Fragment>
    }
    const paginationOptions = { sizePerPage: 50, showTotal: false, firstPageText: 'First', prePageText: 'Back', nextPageText: 'Next', lastPageText: 'Last', onPageChange: (page, sizePerPage) => { setCrntPageNO(((page - 1) * sizePerPage)); } };
    const columns = [
        { dataField: '#', text: '#', editable: false, formatter: idFormatter },
        { dataField: 'InvoiceType', text: 'Type', editable: false,  formatter: TypeFormatter },
        { dataField: 'VisitingCode', text: 'Visiting Code', editable: false },
        // { dataField: 'IRNNo', text: 'IRN Number', editable: false, headerStyle: (colum, colIndex) => { return { width: '33%' }; } },
        { dataField: 'InvoiceNo', text: 'Invoice Number', editable: false },
        { dataField: 'InvoiceAmount', text: 'Amount', editable: false },
        { dataField: 'InvoiceDate', text: 'Date', editable: false },
        { dataField: 'InvoiceStatus', text: 'Status', editable: false},
        { dataField: 'Action', text: 'Action', editable: false, formatter: actionFormatter, headerStyle: (colum, colIndex) => { return { width: '7%' }; } }
    ];
    const BindGrid = async () => {
        setIsLoading(true);
        const ObjBulkInvoiceReq = {
            CentreId: localStorage.CentreSNo,//centreid.current.value,
            FromDate: fromDate.current.value,
            ToDate: toDate.current.value,
            InvoiceStatus: '1'
        };
        let url = FrontDesk_URL + 'master/GetBulkInvoiceLists/';
        await axios.post(url, ObjBulkInvoiceReq).then((response) => {
            const data = response.data;
            if (data.Success) {
                if (data.Data !== null) { setTableData(data.Data); } else { setTableData([]); }
                setIsLoading(false);
            }
            else { SweetAlert.fire({ title: "Application Exception", text: "Some error occured! Please try after sometime", icon: "error" }); setIsLoading(false); }
        }).catch(function (error) { if (error.response) { ErrorFunction(error.response.status); } setIsLoading(false); });
    } 
    const DownloadHandler = async (rowData) => {
        const stateparams = { VisitingId: rowData.VisitingID, BuyerCentreId: rowData.CentreId, CreatedBy: localStorage.LoggedInUser };
        localStorage.removeItem('CyclotronData');
        localStorage.setItem('CyclotronData', JSON.stringify(stateparams));
        window.open(`${process.env.PUBLIC_URL}/pdfPrints/PrintCyclotronInvoice`, '_blank');
    }
    const ErrorFunction = async (ErrorCode) => {
        if (ErrorCode == "400") { SweetAlert.fire({ title: "Error 400", text: "Bad request", icon: "error" }); }
        else if (ErrorCode == "401") { SweetAlert.fire({ title: "Error 401", text: "Unauthorized", icon: "error" }); }
        else if (ErrorCode == "403") { SweetAlert.fire({ title: "Error 403", text: "Forbidden", icon: "error" }); }
        else if (ErrorCode == "404") { SweetAlert.fire({ title: "Error 404", text: "Page not found", icon: "error" }); }
        else if (ErrorCode == "500") { SweetAlert.fire({ title: "Error 500", text: "Internal Server Error", icon: "error" }); }
        else if (ErrorCode == "503") { SweetAlert.fire({ title: "Error 503", text: "Service Unavailable", icon: "error" }); }
    }
    return (
        <React.Fragment>
            <Breadcrumb title="Report Invoices" parent="Work Order" />
            <Container fluid>
                <Row className='mt25'>
                    <Col md={12}>
                        <Card>
                            <Card.Body>
                                <Form>
                                    <Form.Row>                                       
                                        <Col md={3}>
                                            <Form.Group>
                                                <Form.Label>From Date</Form.Label>
                                                <InputGroup className="mb-3 d-flex justify-content-start">
                                                    <Form.Control type="date" id="fromDate" name="fromDate" ref={fromDate} defaultValue={defaultDate} />
                                                </InputGroup>
                                            </Form.Group>
                                        </Col>
                                        <Col md={3}>
                                            <Form.Group>
                                                <Form.Label>To Date</Form.Label>
                                                <InputGroup className="mb-3 d-flex justify-content-start">
                                                    <Form.Control type="date" id="toDate" name="toDate" ref={toDate} defaultValue={defaultDate} />
                                                </InputGroup>
                                            </Form.Group>
                                        </Col>
                                        <Col md={3}>
                                            <Form.Label>&nbsp;</Form.Label>
                                            <Form.Group>
                                                <Button className='ml-2' variant='primary' onClick={BindGrid} >Get Bulk Invoice</Button>
                                            </Form.Group>
                                        </Col>
                                    </Form.Row>
                                    <Row>
                                        <Col md={12}>
                                            <Card>
                                                <Card.Header className='pb-0'>
                                                    <Card.Title>Bulk Invoice List</Card.Title>
                                                </Card.Header>
                                                <Card.Body>
                                                    {
                                                        isLoading ?
                                                            <div className='text-center'> <Spinner animation="border" size='sm' /></div> :
                                                            <>
                                                                <DataTables
                                                                    keyField='InvoiceId'
                                                                    tableData={tableData}
                                                                    columns={columns}
                                                                    noDataIndication={NoRecordFounds}
                                                                    pagination={paginationFactory(paginationOptions)}
                                                                />
                                                            </>
                                                    }
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    </Row>
                                </Form>

                            </Card.Body>
                        </Card>
                    </Col>
                </Row>

            </Container>
        </React.Fragment>
    )
}

export default BulkInvoice
