import React, { useEffect, useState, useRef } from 'react';
import { Container, Row, Col, Card, Form, Button, Table } from 'react-bootstrap';
import Breadcrumb from '../../components/common/breadcrumb';
import DataTables from '../commonComponent/DataTable/DataTables';
import SweetAlert from 'sweetalert2';
import { FrontDesk_URL } from '../../constant';
import axios from 'axios';
import { Spinner } from 'react-bootstrap';
import Alert from "react-bootstrap/Alert";
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import PrintPDF from '../commonComponent/PrintPDF';
import NoRecordFounds from '../commonComponent/DataTable/NoRecordFounds';
import paginationFactory from 'react-bootstrap-table2-paginator';

var glblOldRecord = [];
var glbladhoc = [];
var glblfinance = [];
const DayEndReport = () => {
    let fromDate = useRef();
    let toDate = useRef();
    let transfertoperson = useRef();
    let remarks = useRef();
    const [isLoading, setIsLoading] = useState(false);
    const [apiData, setAPIData] = useState();
    const [defaultDate, setDefaultDate] = useState("");
    const [defaulttoDate, setDefaultToDate] = useState("");
    const [previousDate, setPreviousDate] = useState("");
    const [closedorderdid, setClosedOrderDid] = useState("");
    const [loginname, setLoginname] = useState("");
    const [cashReceived, setCashReceived] = useState("");
    const [printDate, setPrintDate] = useState("");
    const [isPrintReport, setIsPrintReport] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const openReportModal = () => { setIsPrintReport(!isPrintReport); }
    const openEditModal = () => { setIsEdit(!isEdit); }
    const [displayPrintReport, setDisplayPrintReport] = useState(false);
    const [table, setTable] = useState([]);
    const [edittabledata, setEditTableData] = useState([]);
    const [dayEndFailed, setDayEndFailed] = useState(false);
    const openDayEndFailedModal = () => { setDayEndFailed(!dayEndFailed); }
    const [dayEndPendingData, setDayEndPendingData] = useState([]);
    const [dayendadhocdata, setDayEndAdhocData] = useState([]);
    const [deniedType, setDeniedType] = useState("0");
    const [posreceived, setPOSReceived] = useState("");
    const [paytmreceived, setPaytmReceived] = useState("");
    const [bharatpereceived, setBharatpeReceived] = useState("");
    const [phonepereceived, setPhonepeReceived] = useState("");
    const [creditcardreceived, setCreditcardReceived] = useState("");
    const [debitcardreceived, setDebitcardReceived] = useState("");
    const [gpayreceived, setGpayReceived] = useState("");
    const [onlinereceived, setOnlineReceived] = useState("");
    const [razorpayreceived, setRazorpayReceived] = useState("");
    const [cashpending, setCashpending] = useState("");
    const [cashpendingadhoc, setCashpendingAdhoc] = useState("");
    const [financeperson, setFinancePerson] = useState([]);
    const [adhocdata, setAdhocData] = useState([]);
    const [tramount, setTrAmount] = useState("");
    const [crntPageNO, setCrntPageNO] = useState(0);
    const [crntPageNO1, setCrntPageNO1] = useState(0);
    const [alreadytransferredamt, setAlreadyTransferredAmt] = useState("");
    const [cashcode, setCashCode] = useState("");
    const [poscode, setPOSCode] = useState("");
    const [paytmcode, setPaytmCode] = useState("");
    const [bharatpecode, setBharatpeCode] = useState("");
    const [phonepecode, setPhonepeCode] = useState("");
    const [creditcardcode, setCreditcardCode] = useState("");
    const [debitcardcode, setDebitcardCode] = useState("");
    const [gpaycode, setGpayCode] = useState("");
    const [onlinecode, setOnlineCode] = useState("");
    const [razorpaycode, setRazorpayCode] = useState("");

    useEffect(() => {
        GetLastCloseDate();
        GetFinancePersonOrBankName("1");
    }, []);

    const PaginationOptions = {
        sizePerPage: 10, showTotal: false, firstPageText: 'First', prePageText: 'Back', nextPageText: 'Next', lastPageText: 'Last',
        onPageChange: (page, sizePerPage) => { setCrntPageNO(((page - 1) * sizePerPage)); }
    };
    const PaginationOptions1 = {
        sizePerPage: 10, showTotal: false, firstPageText: 'First', prePageText: 'Back', nextPageText: 'Next', lastPageText: 'Last',
        onPageChange: (page, sizePerPage) => { setCrntPageNO1(((page - 1) * sizePerPage)); }
    };
    const GetFinancePersonOrBankName = async (tType) => {
        try {
            let url = FrontDesk_URL + 'master/GetFinancePersonORBankNameDayEnd/' + tType + '/' + localStorage.getItem('LoggedInUser')
            await axios.get(url).then((response) => {
                const data = response.data;
                if (data.Success) {
                    glblfinance = data.Data;
                    setFinancePerson(data.Data.map((d) => (<option key={d.Id} value={d.Id}>{d.Value}</option>)))
                }
                else { setFinancePerson([]); }
            }).catch(function (error) { if (error.response) { ErrorFunction(error.response.status); } });
        }
        catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    }
    const idFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                <span>{rowIndex + 1} </span>
                {/* <ToolTip title="Change Payment Mode" placement="top" onClick={() => editHandler(rowData.VisitingId)} linkId="EditLink" faName="fa fa-pencil mr-2"></ToolTip> */}
            </div>
        </React.Fragment>
    }
    const adhocidFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                <span>{rowIndex + 1} </span>
                {/* <ToolTip title="Change Payment Mode" placement="top" onClick={() => editHandler(rowData.VisitingId)} linkId="EditLink" faName="fa fa-pencil mr-2"></ToolTip> */}
            </div>
        </React.Fragment>
    }
    const editidFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                <span>{rowIndex + 1}</span>
            </div>
        </React.Fragment>
    }
    const editactionFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                {
                    rowData.PaymentModeId === "1" ? <Button variant='primary' onClick={(e) => UpdatePaymentMode("1", rowData.OrderDetailId)} >Update to online received</Button> :
                        <Button variant='primary' onClick={(e) => UpdatePaymentMode("13", rowData.OrderDetailId)} >Update to cash received</Button>
                }
            </div>
        </React.Fragment>
    }
    const totalFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                <span>{(parseFloat(rowData.CashReceived) + parseFloat(rowData.POS) + parseFloat(rowData.Paytm) + parseFloat(rowData.Bharatpay) + parseFloat(rowData.Phonepe) + parseFloat(rowData.Creditcard) + parseFloat(rowData.Debitcard) + parseFloat(rowData.Gpay) + parseFloat(rowData.Online)+ parseFloat(rowData.Razorpay)).toFixed(2)}</span>
            </div>
        </React.Fragment>
    }
    const totalcashFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                {
                    rowData.CashReceived == "" ? "0.00" : <span>{rowData.CashReceived}</span>
                }
            </div>
        </React.Fragment>
    }
    const totalposFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                {
                    rowData.POS == "" ? "0.00" : <span>{rowData.POS}</span>
                }
            </div>
        </React.Fragment>
    }
    const totalpaytmFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                {
                    rowData.Paytm == "" ? "0.00" : <span>{rowData.Paytm}</span>
                }
            </div>
        </React.Fragment>
    }
    const totalbharatpayFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                {
                    rowData.Bharatpay == "" ? "0.00" : <span>{rowData.Bharatpay}</span>
                }
            </div>
        </React.Fragment>
    }
    const totalphonepeFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                {
                    rowData.Phonepe == "" ? "0.00" : <span>{rowData.Phonepe}</span>
                }
            </div>
        </React.Fragment>
    }
    const totalcreditcardFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                {
                    rowData.Creditcard == "" ? "0.00" : <span>{rowData.Creditcard}</span>
                }
            </div>
        </React.Fragment>
    }
    const totaldebitcardFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                {
                    rowData.Debitcard == "" ? "0.00" : <span>{rowData.Debitcard}</span>
                }
            </div>
        </React.Fragment>
    }
    const totalgpayFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                {
                    rowData.Gpay == "" ? "0.00" : <span>{rowData.Gpay}</span>
                }
            </div>
        </React.Fragment>
    }
    const totalonlineFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                {
                    rowData.Online == "" ? "0.00" : <span>{rowData.Online}</span>
                }
            </div>
        </React.Fragment>
    }
    const totalrazorpayFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                {
                    rowData.Razorpay == "" ? "0.00" : <span>{rowData.Razorpay}</span>
                }
            </div>
        </React.Fragment>
    }
    const idpendingFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                <span>{crntPageNO + rowIndex + 1}</span>
            </div>
        </React.Fragment>
    }
    const idadhocFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                <span>{crntPageNO1 + rowIndex + 1}</span>
            </div>
        </React.Fragment>
    }
    const columns =
        [
            { dataField: '#', text: '#', editable: false, formatter: idFormatter, headerStyle: (colum, colIndex) => { return { width: '5%' }; } },
            { dataField: 'OrderAmount', text: 'Total Amount', editable: false, formatter: totalFormatter },
            { dataField: 'CashReceived', text: 'Cash Received', editable: cashcode == "" ? false : true, formatter: totalcashFormatter, attrs: function callback(cell, row, rowIndex, colIndex) { CallLeftAmount() } },
            { dataField: 'POS', text: 'POS Received', editable: poscode == "" ? false : true, formatter: totalposFormatter, attrs: function callback(cell, row, rowIndex, colIndex) { CallLeftAmount() } },
            { dataField: 'Paytm', text: 'Paytm Received', editable: paytmcode == "" ? false : true, formatter: totalpaytmFormatter, attrs: function callback(cell, row, rowIndex, colIndex) { CallLeftAmount() } },
            { dataField: 'Bharatpay', text: 'Bharatpe Received', editable: bharatpecode == "" ? false : true, formatter: totalbharatpayFormatter, attrs: function callback(cell, row, rowIndex, colIndex) { CallLeftAmount() } },
            { dataField: 'Phonepe', text: 'Axis Soundbox Received', editable: phonepecode == "" ? false : true, formatter: totalphonepeFormatter, attrs: function callback(cell, row, rowIndex, colIndex) { CallLeftAmount() } },
            { dataField: 'Creditcard', text: 'Creditcard Received', editable: creditcardcode == "" ? false : true, formatter: totalcreditcardFormatter, attrs: function callback(cell, row, rowIndex, colIndex) { CallLeftAmount() } },
            { dataField: 'Debitcard', text: 'Debitcard Received', editable: debitcardcode == "" ? false : true, formatter: totaldebitcardFormatter, attrs: function callback(cell, row, rowIndex, colIndex) { CallLeftAmount() } },
            { dataField: 'Gpay', text: 'Gpay Received', editable: gpaycode == "" ? false : true, formatter: totalgpayFormatter, attrs: function callback(cell, row, rowIndex, colIndex) { CallLeftAmount() } },
            { dataField: 'Online', text: 'Online Received', editable: onlinecode == "" ? false : true, formatter: totalonlineFormatter, attrs: function callback(cell, row, rowIndex, colIndex) { CallLeftAmount() } },
            { dataField: 'Razorpay', text: 'Razorpay Received', editable: razorpaycode == "" ? false : true, formatter: totalrazorpayFormatter, attrs: function callback(cell, row, rowIndex, colIndex) { CallLeftAmount() } }    
        ];
    const adhoccolumns =
        [
            { dataField: '#', text: '#', editable: false, formatter: adhocidFormatter, headerStyle: (colum, colIndex) => { return { width: '5%' }; } },
            { dataField: 'Name', text: 'Transferred authorizer', editable: false },
            { dataField: 'TrfApprAmount', text: 'Transferred amount', editable: false }
        ];
    const editcolumns = [
        { dataField: '#', text: '#', editable: false, formatter: editidFormatter, headerStyle: (colum, colIndex) => { return { width: '5%' }; } },
        { dataField: 'PaymentMode', text: 'Payment Mode', editable: false },
        { dataField: 'PaidAmount', text: 'Amount', editable: false },
        { dataField: 'OrderDate', text: 'Order Date', editable: false },
        { dataField: '', text: 'Action', formatter: editactionFormatter, editable: false, headerStyle: (colum, colIndex) => { return { width: '5%' }; } }
    ];
    const dayEndPendingColumnsOrder = [
        { dataField: '#', text: '#', editable: false, formatter: idpendingFormatter, headerStyle: (colum, colIndex) => { return { width: '5%' }; } },
        { dataField: 'VisitingCode', text: 'Visit No', editable: false },
        { dataField: 'InvoiceNo', text: 'Order No', editable: false },
        { dataField: 'InvoiceDate', text: 'Order Date', editable: false },
        { dataField: 'Amount', text: 'Amount', editable: false },
        { dataField: 'PendingOn', text: 'Remarks', editable: false }
    ];
    const dayEndAdhocColumnsOrder = [
        { dataField: '#', text: '#', editable: false, formatter: idadhocFormatter, headerStyle: (colum, colIndex) => { return { width: '5%' }; } },
        { dataField: 'AddedOn', text: 'Transfer Date', editable: false },
        { dataField: 'Amount', text: 'Amount', editable: false },
        { dataField: 'ApproverName', text: 'Remarks', editable: false }
    ];
    const handleSearch = async () => {
        if (toDate.current.value == "" || toDate.current.value == undefined || toDate.current.value == null) {
            SweetAlert.fire("To date is mandatory.");
            return false;
        }
        if (toDate.current.value > defaulttoDate) {
            SweetAlert.fire("To date cannot be greater than today date.");
            return false;
        }
        if (toDate.current.value >= defaultDate) {
            BindGrid(fromDate.current.value, toDate.current.value);
        } else {
            SweetAlert.fire("To date cannot be less than from date.");
        }
    }
    const BindGrid = async (fromdate, todate) => {
        setIsLoading(true);
        let url = FrontDesk_URL + 'reports/getdayendreportdetails/' + localStorage.getItem('LoggedInUser') + "/" + localStorage.getItem('CentreSNo') + "/" + fromdate + "/" + todate
        await axios.get(url).then((response) => {
            const data = response.data;
            if (data.Success) {
                glblOldRecord = [];
                var apiData = data.Data.deDetails;
                if (apiData == null || apiData.length == 0) { glblOldRecord = []; } else {
                    if (glblOldRecord.length == 0 || glblOldRecord == []) { glblOldRecord = apiData; }
                    setAPIData(apiData);
                    setClosedOrderDid(apiData[0].ClosedOrderDetailID);
                    setLoginname(apiData[0].LoginName);
                    setCashReceived(apiData[0].CashReceived);
                    setPOSReceived(apiData[0].POS);
                    setPaytmReceived(apiData[0].Paytm);
                    setBharatpeReceived(apiData[0].Bharatpay);
                    setPhonepeReceived(apiData[0].Phonepe);
                    setCreditcardReceived(apiData[0].Creditcard);
                    setDebitcardReceived(apiData[0].Debitcard);
                    setGpayReceived(apiData[0].Gpay);
                    setOnlineReceived(apiData[0].Online);
                    setRazorpayReceived(apiData[0].Razorpay);
                    setCashpending(apiData[0].PendingAmount);
                    setCashpendingAdhoc(apiData[0].AdhocPendingAmount);
                    setPrintDate(apiData[0].PrintOn);
                    setTrAmount(apiData[0].TotalLeftAmount > 0 ? apiData[0].TotalLeftAmount : '0.00');
                }
                var apiDataAdhoc = data.Data.adhDetails;
                glbladhoc = data.Data.adhDetails;
                if (apiDataAdhoc == null || apiDataAdhoc.length == 0) { setAdhocData([]); glbladhoc = []; }
                else { setAdhocData(apiDataAdhoc); }
                setIsLoading(false);
                BindGridTable(fromdate, todate);
            }
            else { SweetAlert.fire({ title: "Application Exception", text: "Some error occured! Please try after sometime", icon: "error" }); setIsLoading(false); }
        }).catch(function (error) { if (error.response) { ErrorFunction(error.response.status); } setIsLoading(false); });
    }
    const BindGridTable = async (fromdate, todate) => {
        setIsLoading(true);
        setDisplayPrintReport(false);
        let url = FrontDesk_URL + 'reports/getdayendreportdetails/' + localStorage.getItem('LoggedInUser') + "/" + localStorage.getItem('CentreSNo') + "/" + fromdate + "/" + todate
        await axios.get(url).then((response) => {
            const data = response.data;
            if (data.Success) {
                var apiTableData = data.Data.deDetails;
                if (apiTableData == null || apiTableData.length == 0) { setTable([]); } else { setTable(apiTableData); setDisplayPrintReport(true); }
                setIsLoading(false);
            }
            else { SweetAlert.fire({ title: "Application Exception", text: "Some error occured! Please try after sometime", icon: "error" }); setIsLoading(false); }
        }).catch(function (error) { if (error.response) { ErrorFunction(error.response.status); } setIsLoading(false); });
    }
    const GetLastCloseDate = async () => {
        setIsLoading(true);
        let url = FrontDesk_URL + 'reports/getlastclosedate/' + localStorage.getItem('LoggedInUser') + "/" + localStorage.getItem('CentreSNo')
        await axios.get(url).then((response) => {
            const data = response.data;
            if (data.Success) {
                if (data.Data == null || data.Data.LastCloseDate == null || data.Data.LastCloseDate == '') { SweetAlert.fire("No record found"); }
                else {
                    if (data.Data.CashCode == "" || data.Data.CashCode == null || data.Data.CashCode == undefined) { setCashCode(""); }
                    else { setCashCode(data.Data.CashCode); }
                    if (data.Data.POSCode == "" || data.Data.POSCode == null || data.Data.POSCode == undefined) { setPOSCode(""); }
                    else { setPOSCode(data.Data.POSCode); }
                    if (data.Data.PaytmCode == "" || data.Data.PaytmCode == null || data.Data.PaytmCode == undefined) { setPaytmCode(""); }
                    else { setPaytmCode(data.Data.PaytmCode); }
                    if (data.Data.BharatpeCode == "" || data.Data.BharatpeCode == null || data.Data.BharatpeCode == undefined) { setBharatpeCode(""); }
                    else { setBharatpeCode(data.Data.BharatpeCode); }
                    if (data.Data.PhonepeCode == "" || data.Data.PhonepeCode == null || data.Data.PhonepeCode == undefined) { setPhonepeCode(""); }
                    else { setPhonepeCode(data.Data.PhonepeCode); }
                    if (data.Data.CreditcardCode == "" || data.Data.CreditcardCode == null || data.Data.CreditcardCode == undefined) { setCreditcardCode(""); }
                    else { setCreditcardCode(data.Data.CreditcardCode); }
                    if (data.Data.DebitcardCode == "" || data.Data.DebitcardCode == null || data.Data.DebitcardCode == undefined) { setDebitcardCode(""); }
                    else { setDebitcardCode(data.Data.DebitcardCode); }
                    if (data.Data.GpayCode == "" || data.Data.GpayCode == null || data.Data.GpayCode == undefined) { setGpayCode(""); }
                    else { setGpayCode(data.Data.GpayCode); }
                    if (data.Data.OnlineCode == "" || data.Data.OnlineCode == null || data.Data.OnlineCode == undefined) { setOnlineCode(""); }
                    else { setOnlineCode(data.Data.OnlineCode); }
                    if (data.Data.RazorpayCode == "" || data.Data.RazorpayCode == null || data.Data.RazorpayCode == undefined) { setRazorpayCode(""); }
                    else { setRazorpayCode(data.Data.RazorpayCode); }
                    const lastcdate = data.Data.LastCloseDate.split(" ")[0];
                    const today = new Date();
                    const date = today.setDate(today.getDate());
                    const defaultValue = new Date(date).toISOString().split('T')[0] // yyyy-mm-dd
                    setDefaultDate(lastcdate);
                    setDefaultToDate(defaultValue);
                    const previousday = getPreviousDay();
                    const previousdate = previousday.setDate(previousday.getDate());
                    const previousfdate = new Date(previousdate).toISOString().split('T')[0] // yyyy-mm-dd
                    setPreviousDate(previousfdate);
                    BindGrid(lastcdate, defaultValue);
                }
                setIsLoading(false);
            }
            else { SweetAlert.fire({ title: "Application Exception", text: "Some error occured! Please try after sometime", icon: "error" }); setIsLoading(false); }
        }).catch(function (error) { if (error.response) { ErrorFunction(error.response.status); } setIsLoading(false); });
    }
    const OpenReport = () => { setIsPrintReport(true); }
    function getPreviousDay(date = new Date()) {
        const previous = new Date(date.getTime());
        previous.setDate(date.getDate() - 1);
        return previous;
    }
    const UpdatePaymentMode = async (PaymentModeId, OrderDetailId) => {
        var requestOptions = { PaymentModeId: PaymentModeId, OrderDetailId: OrderDetailId, UpdatedBy: localStorage.LoggedInUser }
        let url = FrontDesk_URL + 'reports/updatepaymentmode'
        await axios.post(url, requestOptions).then((response) => {
            const data = response.data;
            if (data.Success === true) {
                SweetAlert.fire("Payment Mode updated successfully");
                openEditModal();
                const today = new Date();
                const date = today.setDate(today.getDate());
                const defaultValue = new Date(date).toISOString().split('T')[0] // yyyy-mm-dd
                setDefaultDate(defaultValue);
                BindGrid(fromDate.current.value, fromDate.current.value);
            }
        }).catch(function (error) { if (error.response) { ErrorFunction(error.response.status); } });
    }
    const CloseMyDayConfirm = async () => {
        SweetAlert.fire({ title: "Alert", text: "Are you sure, you want to close your day?", icon: 'warning', showCancelButton: true, confirmButtonText: 'Ok', cancelButtonText: 'Cancel', reverseButtons: true })
            .then((result) => {
                if (result.value) { CloseMyDay(); }
            });
    }
    const CloseMyDay = async () => {
        CallLeftAmount();
        if (transfertoperson.current.value == "" && (remarks.current.value == "" || remarks.current.value == undefined || remarks.current.value == null)) {
            SweetAlert.fire("Please select transfer person and remarks is also mandatory");
            return false;
        }
        if (transfertoperson.current.value == "") {
            SweetAlert.fire("Please select transfer person");
            return false;
        }
        if (remarks.current.value == "" || remarks.current.value == undefined || remarks.current.value == null) {
            SweetAlert.fire("Remarks is mandatory");
            return false;
        }
        if (toDate.current.value == "" || toDate.current.value == undefined || toDate.current.value == null) {
            SweetAlert.fire("To date is mandatory.");
            return false;
        }
        const totalamount = (parseFloat(cashReceived) + parseFloat(posreceived) + parseFloat(paytmreceived) + parseFloat(bharatpereceived) + parseFloat(phonepereceived) + parseFloat(creditcardreceived) + parseFloat(debitcardreceived) + parseFloat(gpayreceived) + parseFloat(onlinereceived) + parseFloat(razorpayreceived)).toFixed(2)
        const addedamount = (parseFloat(table[0].CashReceived == "" ? "0.00" : table[0].CashReceived) + parseFloat(table[0].POS == "" ? "0.00" : table[0].POS) + parseFloat(table[0].Paytm == "" ? "0.00" : table[0].Paytm) + parseFloat(table[0].Bharatpay == "" ? "0.00" : table[0].Bharatpay) + parseFloat(table[0].Phonepe == "" ? "0.00" : table[0].Phonepe) + parseFloat(table[0].Creditcard == "" ? "0.00" : table[0].Creditcard) + parseFloat(table[0].Debitcard == "" ? "0.00" : table[0].Debitcard) + parseFloat(table[0].Gpay == "" ? "0.00" : table[0].Gpay) + parseFloat(table[0].Online == "" ? "0.00" : table[0].Online)+ parseFloat(table[0].Razorpay == "" ? "0.00" : table[0].Razorpay)).toFixed(2);
        if (totalamount != addedamount) {
            SweetAlert.fire("Cash received and total online received should be equal to total amount");
            return false;
        }
        if (alreadytransferredamt == "-1") {
            SweetAlert.fire("Cash received cannot be less than total transferred amount because you had already transferred");
            return false;
        }
        var olddata = [];
        var newdata = [];
        olddata.push({
            CashReceived: glblOldRecord[0].CashReceived == "" ? "0.00" : glblOldRecord[0].CashReceived,
            POS: glblOldRecord[0].POS == "" ? "0.00" : glblOldRecord[0].POS,
            Paytm: glblOldRecord[0].Paytm == "" ? "0.00" : glblOldRecord[0].Paytm,
            Bharatpay: glblOldRecord[0].Bharatpay == "" ? "0.00" : glblOldRecord[0].Bharatpay,
            Phonepe: glblOldRecord[0].Phonepe == "" ? "0.00" : glblOldRecord[0].Phonepe,
            Creditcard: glblOldRecord[0].Creditcard == "" ? "0.00" : glblOldRecord[0].Creditcard,
            Debitcard: glblOldRecord[0].Debitcard == "" ? "0.00" : glblOldRecord[0].Debitcard,
            Gpay: glblOldRecord[0].Gpay == "" ? "0.00" : glblOldRecord[0].Gpay,
            Online: glblOldRecord[0].Online == "" ? "0.00" : glblOldRecord[0].Online,
            Razorpay: glblOldRecord[0].Razorpay == "" ? "0.00" : glblOldRecord[0].Razorpay
        });
        newdata.push({
            CashReceived: table[0].CashReceived == "" ? "0.00" : table[0].CashReceived,
            POS: table[0].POS == "" ? "0.00" : table[0].POS,
            Paytm: table[0].Paytm == "" ? "0.00" : table[0].Paytm,
            Bharatpay: table[0].Bharatpay == "" ? "0.00" : table[0].Bharatpay,
            Phonepe: table[0].Phonepe == "" ? "0.00" : table[0].Phonepe,
            Creditcard: table[0].Creditcard == "" ? "0.00" : table[0].Creditcard,
            Debitcard: table[0].Debitcard == "" ? "0.00" : table[0].Debitcard,
            Gpay: table[0].Gpay == "" ? "0.00" : table[0].Gpay,
            Online: table[0].Online == "" ? "0.00" : table[0].Online,
            Razorpay: table[0].Razorpay == "" ? "0.00" : table[0].Razorpay
        });
        var cashflowids = "";
        for (var key in adhocdata) {
            if (adhocdata.length == (parseFloat(key) + 1)) {
                cashflowids += adhocdata[key].CashFlowId
            } else { cashflowids += adhocdata[key].CashFlowId + "," }
        }
        var emailid = "";
        for (var keys in glblfinance) {
            if (glblfinance[keys].Id == transfertoperson.current.value && emailid == "") {
                emailid = glblfinance[keys].EmailId
            }
        }
        const requestOptions = {
            Remarks: remarks.current.value == undefined || remarks.current.value == null ? "" : remarks.current.value,
            Transfertoid: transfertoperson.current.value,
            CentreId: localStorage.getItem('CentreSNo'),
            LoginId: localStorage.getItem('LoggedInUser'),
            FromDate: defaultDate,
            ToDate: toDate.current.value,
            TotalAmount: addedamount == undefined || addedamount == null || addedamount == "" ? "0.00" : addedamount,
            olddata: JSON.stringify(olddata),
            newdata: JSON.stringify(newdata),
            LeftAmount: tramount == undefined || tramount == null || tramount == "" || tramount < 0 ? "0.00" : tramount,
            CashFlowIds: cashflowids,
            TransferPersonName: transfertoperson.current.options[transfertoperson.current.selectedIndex].text,
            TransferPersonEmailId: emailid,
            RequestedPersonName: localStorage.getItem('UserName'),
            RequestedCentreName: localStorage.getItem('CentreName'),
            ClosedOrderDID : closedorderdid == undefined || closedorderdid == null ? "" : closedorderdid
        }
        let url = FrontDesk_URL + 'accounts/setdayend'
        await axios.post(url, requestOptions).then((response) => {
            const data = response.data;
            if (data.Success) {
                var apiData = data.Data;
                if (parseInt(apiData.OutputMsg) > 0) {
                    // if (apiData.AllowDayClosed === "0") { SweetAlert.fire("Your day closed successfully") }
                    if (apiData.AllowDayClosed == "0" || apiData.AllowDayClosed == "false") {
                        SweetAlert.fire({
                            title: 'Request sent',
                            text: "Your close my day request sent for reconcilation to the selected authorizer. Once authorizer reconciles then your day will close automatically",
                            icon: 'success',
                            showCancelButton: false,
                            confirmButtonText: 'Ok',
                            reverseButtons: true
                        }).then((result) => { if (result.value) { window.location.reload(false); } else { window.location.reload(false); } })
                    }
                    else {
                        setDeniedType(apiData.AllowDayClosed);
                        if (apiData.PendingInvoices !== null) { setDayEndPendingData(apiData.PendingInvoices); } else { setDayEndPendingData([]); }
                        if (apiData.PendingAdhoc !== null) { setDayEndAdhocData(apiData.PendingAdhoc); } else { setDayEndAdhocData([]); }
                        setDayEndFailed(true);
                    }
                }
                else if (parseInt(apiData.OutputMsg) == "-2002") {
                    SweetAlert.fire({ title: "Amount mismatch", text: "Cash received cannot be less than total transferred amount because you had already transferred", icon: "error" });
                }
                else if (parseInt(apiData.OutputMsg) == "-2001") {
                    SweetAlert.fire({ title: "Amount mismatch", text: "Cash received and total online received is not equal to total amount", icon: "error" });
                }
                else if (parseInt(apiData.OutputMsg) == "-2000") {
                    SweetAlert.fire({ title: "Already requested for day end", text: "Please ask approver ( " + apiData.PendingDayEndApprover + " ) to reconcile your previous day end request first.", icon: "warning" });
                }
                else if (parseInt(apiData.OutputMsg) == "-2005") {
                    SweetAlert.fire({ title: "Amount mismatch", text: "Please refresh page", icon: "warning" });
                }
                else { SweetAlert.fire({ title: "Application Exception", text: "Some error occured in db! Please try after sometime", icon: "error" }); }
            }
            else { SweetAlert.fire({ title: "Application Exception", text: "Some error occured! Please try after sometime", icon: "error" }); }
        }).catch(function (error) { if (error.response) { ErrorFunction(error.response.status); } });
    }
    const CallLeftAmount = () => {
        var addamount = 0;
        var famt = 0;
        for (var key in glbladhoc) {
            addamount = (parseFloat(addamount) + parseFloat(glbladhoc[key].TrfApprAmount)).toFixed(2);
        }
        var amt = (parseFloat(table[0].CashReceived) - parseFloat(addamount)).toFixed(2);
        famt = amt >= 0 ? amt : "-1";
        if (amt > 0) { amt = amt; }
        else { amt = '0.00'; }
        setTrAmount(amt);
        setAlreadyTransferredAmt(famt);
    }
    const ErrorFunction = async (ErrorCode) => {
        if (ErrorCode == "400") { SweetAlert.fire({ title: "Error 400", text: "Bad request", icon: "error" }); }
        else if (ErrorCode == "401") { SweetAlert.fire({ title: "Error 401", text: "Unauthorized", icon: "error" }); }
        else if (ErrorCode == "403") { SweetAlert.fire({ title: "Error 403", text: "Forbidden", icon: "error" }); }
        else if (ErrorCode == "404") { SweetAlert.fire({ title: "Error 404", text: "Page not found", icon: "error" }); }
        else if (ErrorCode == "500") { SweetAlert.fire({ title: "Error 500", text: "Internal Server Error", icon: "error" }); }
        else if (ErrorCode == "503") { SweetAlert.fire({ title: "Error 503", text: "Service Unavailable", icon: "error" }); }
    }
    return (
        <>
            <Breadcrumb title="Day End Report" parent="Accounts" />
            <Container fluid>
                <Row>
                    <Col md={12}>
                        <Card>
                            <Card.Body>
                                <Form>
                                    <Form.Row>
                                        <Col md={2}>
                                            <Form.Group>
                                                <Form.Label>From Date</Form.Label>
                                                <Form.Control type="date" id="date1" name="date1" ref={fromDate} defaultValue={defaultDate} disabled />
                                            </Form.Group>
                                        </Col>
                                        <Col md={2}>
                                            <Form.Group>
                                                <Form.Label>To Date</Form.Label>
                                                <Form.Control type="date" id="date2" name="date2" ref={toDate} defaultValue={defaulttoDate} />
                                            </Form.Group>
                                        </Col>
                                        <Col md={1}>
                                            <div className="searchButtonSec">
                                                <Button variant='primary' onClick={handleSearch} >Search</Button>
                                            </div>
                                        </Col>
                                    </Form.Row>
                                </Form>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <Card>
                            <Card.Body>
                                <Form>
                                    <Form.Row>
                                        <Col md={12}>
                                            <h6 className='text-red'>Summary</h6>
                                            {
                                                defaultDate != defaulttoDate && defaultDate != previousDate ?
                                                    <h6 className='text-red text-right'>Your previous days was not closed</h6> : null
                                            }
                                            <Alert variant="success" className="text-center">
                                                <ul className="list-inline pointsInline">
                                                    <li className="list-inline-item">Name: <strong>{loginname}</strong></li>
                                                    <li className="list-inline-item">Total amount: <strong>{(parseFloat(cashReceived) + parseFloat(posreceived) + parseFloat(paytmreceived) + parseFloat(bharatpereceived) + parseFloat(phonepereceived) + parseFloat(creditcardreceived) + parseFloat(debitcardreceived) + parseFloat(gpayreceived) + parseFloat(onlinereceived) + parseFloat(razorpayreceived)).toFixed(2)}</strong></li>
                                                    <li className="list-inline-item">Cash received: <strong>{cashReceived}</strong></li>
                                                    <li className="list-inline-item">POS received: <strong>{posreceived}</strong></li>
                                                    <li className="list-inline-item">Paytm received: <strong>{paytmreceived}</strong></li>
                                                    <li className="list-inline-item">Bharatpe received: <strong>{bharatpereceived}</strong></li>
                                                    <li className="list-inline-item">Axis soundbox received: <strong>{phonepereceived}</strong></li>
                                                    <li className="list-inline-item">Creditcard received: <strong>{creditcardreceived}</strong></li>
                                                    <li className="list-inline-item">Debitcard received: <strong>{debitcardreceived}</strong></li>
                                                    <li className="list-inline-item">Gpay received: <strong>{gpayreceived}</strong></li>
                                                    <li className="list-inline-item">Online received: <strong>{onlinereceived}</strong></li>
                                                    <li className="list-inline-item">Razorpay received: <strong>{razorpayreceived}</strong></li>
                                                    <li className="list-inline-item">Total available Amount: <strong>{cashpending}</strong></li>
                                                    <li className="list-inline-item">Total pending adhoc amount: <strong>{cashpendingadhoc}</strong></li>
                                                    <li className="list-inline-item">Total Transferrable Amount Left: <strong>{tramount}</strong></li>
                                                    <li className="list-inline-item">Print On: <strong>{printDate}</strong></li>
                                                </ul>
                                            </Alert>
                                        </Col>
                                    </Form.Row>
                                    <Form.Row className='mt15 summaryTableClass'>
                                        <Col md={12}>
                                            {
                                                isLoading ?
                                                    <div className='text-center'> <Spinner animation="border" size='sm' /></div> :
                                                    <>
                                                        <DataTables keyField='id' tableData={table} columns={columns} />
                                                    </>
                                            }
                                        </Col>
                                    </Form.Row>
                                    <Form.Row className='mt25 summaryTableClass'>
                                        <Col md={4}>
                                            <Form.Group>
                                                <Form.Label>Select Transfer Person</Form.Label>
                                                <Form.Control as="select" name="transfertoperson" ref={transfertoperson}>
                                                    <option key="" value="">---Select---</option>
                                                    {financeperson}
                                                </Form.Control>
                                            </Form.Group>
                                        </Col>
                                        <Col md={4}>
                                            <Form.Group>
                                                <Form.Label>Remarks</Form.Label>
                                                <Form.Control type="text" ref={remarks} name="remarks" />
                                            </Form.Group>
                                        </Col>
                                    </Form.Row>
                                    {
                                        adhocdata == [] || adhocdata == null || adhocdata.length == 0 ? null :
                                            <Form.Row className='mt15 summaryTableClass'>
                                                <h6 className='text-red'>Succesfully transferred amount</h6>
                                                <Col md={12}>
                                                    {
                                                        isLoading ?
                                                            <div className='text-center'> <Spinner animation="border" size='sm' /></div> :
                                                            <>
                                                                <DataTables keyField='CashFlowId' tableData={adhocdata} columns={adhoccolumns} />
                                                            </>
                                                    }
                                                </Col>
                                            </Form.Row>
                                    }
                                    <Form.Row className='mt15'>
                                        <Col md={12} className='text-center'>
                                            {displayPrintReport ?
                                                <>
                                                    {
                                                        (parseFloat(cashReceived) + parseFloat(posreceived) + parseFloat(paytmreceived) + parseFloat(bharatpereceived) + parseFloat(phonepereceived) + parseFloat(creditcardreceived) + parseFloat(debitcardreceived) + parseFloat(gpayreceived) + parseFloat(onlinereceived)+ parseFloat(razorpayreceived)).toFixed(2) > 0 ?
                                                            <Button variant='primary' className="mr-2" onClick={(e) => CloseMyDayConfirm()}>Close My Day</Button> : null
                                                    }
                                                </>
                                                : null}
                                        </Col>
                                    </Form.Row>
                                </Form>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <Modal isOpen={isPrintReport} toggle={openReportModal} centered={true} className="printReportContainerModel">
                <ModalHeader toggle={openReportModal}>Print Report</ModalHeader>
                <ModalBody>
                    <Form.Row>
                        <Col md={12} className="PrintDayEndReport">
                            <PrintPDF ReportName="PrintDayEndReport" APIData={apiData} />
                        </Col>
                    </Form.Row>
                </ModalBody>
            </Modal>
            <Modal isOpen={isEdit} toggle={openEditModal} centered={true} size="lg">
                <ModalHeader toggle={openEditModal}>Edit Payment Mode</ModalHeader>
                <ModalBody>
                    <Row>
                        <Col md={12} className="OrderDetailClass">
                            <DataTables keyField='OrderDetailId' tableData={edittabledata} columns={editcolumns} />
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>
            <Modal isOpen={dayEndFailed} toggle={openDayEndFailedModal} centered={true} size="lg">
                <ModalHeader toggle={openDayEndFailedModal}>Pending</ModalHeader>
                <ModalBody>
                    {
                        deniedType === "1" ?
                            <>
                                {
                                    dayEndPendingData == null || dayEndPendingData == [] || dayEndPendingData.length == 0 ?
                                        null :
                                        <>
                                            <Row>
                                                <Col md={12} className="text-red">You have following pending orders, so you need to settle following orders first.</Col>
                                            </Row>
                                            <Row>
                                                <Col md={12} className="OrderDetailClass">
                                                    <DataTables keyField='VisitingId' tableData={dayEndPendingData} columns={dayEndPendingColumnsOrder} noDataIndication={NoRecordFounds}
                                                        pagination={paginationFactory(PaginationOptions)} />
                                                </Col>
                                            </Row>
                                        </>
                                }
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                {
                                    dayendadhocdata == null || dayendadhocdata == [] || dayendadhocdata.length == 0 ?
                                        null :
                                        <>
                                            <Row>
                                                <Col md={12} className="text-red">You have following adhoc transfer pending, so you need to ask approver to reconcile first.</Col>
                                            </Row>
                                            <Row>
                                                <Col md={12} className="OrderDetailClass">
                                                    <DataTables keyField='VisitingId' tableData={dayendadhocdata} columns={dayEndAdhocColumnsOrder} noDataIndication={NoRecordFounds}
                                                        pagination={paginationFactory(PaginationOptions1)} />
                                                </Col>
                                            </Row>
                                        </>
                                }
                            </>
                            : null
                    }
                </ModalBody>
            </Modal>
        </>
    )
}

export default DayEndReport;