import React, { useEffect, useState, useRef } from 'react';
import { Container, Row, Col, Card, Form, Button } from 'react-bootstrap';
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import axios from 'axios';
import SweetAlert from 'sweetalert2';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Spinner } from 'react-bootstrap';
import ToolTip from '../commonComponent/ToolTip';
import { FrontDesk_URL } from '../../constant';
import DataTables from '../commonComponent/DataTable/DataTables';
import Breadcrumb from '../../components/common/breadcrumb';
import LoadingButton from '../commonComponent/LoadingButton';
import InputGroup from 'react-bootstrap/InputGroup';

var glbRecords = [];
var glbTestRecords = [];
var dates = "";
const RefundApproval = () => {
    const [tableData, setTableData] = useState([]);
    const [crntPageNO, setCrntPageNO] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [testsModal, setTestsModal] = useState();
    const [visitsTableData, setVisitsTableData] = useState([]);
    const [patientName, setPatientName] = useState();
    const [patientAge, setPatientAge] = useState();
    const [visitNo, setVisitNo] = useState();
    const [refundrequestid, setRefundRequestId] = useState("0");
    const openModal = () => { setTestsModal(!testsModal); };
    const [modal, setModal] = useState();
    const toggleModal = () => { setModal(!modal) }
    const [statevalue, setStateValue] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [refundAmount, setRefundAmount] = useState(0.00);
    const [enableRefund, setEnableRefund] = useState(false);
    const [defaultDate, setDefaultDate] = useState("");
    let fromDate = useRef();
    useEffect(() => {
        const today = new Date();
        const date = today.setDate(today.getDate());
        const defaultValue = new Date(date).toISOString().split('T')[0] // yyyy-mm-dd
        setDefaultDate(defaultValue);
        setStateValue(true);
        BindGrid("", "", defaultValue);
    }, [])

    const paginationOptions = {
        sizePerPage: 10, showTotal: false, firstPageText: 'First', prePageText: 'Back', nextPageText: 'Next', lastPageText: 'Last',
        onPageChange: (page, sizePerPage) => { setCrntPageNO(((page - 1) * sizePerPage)); }
    };
    const idFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                <span>{crntPageNO + rowIndex + 1}</span>
            </div>
        </React.Fragment>
    }
    const actionFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                {
                    (rowData.StatusCheck == "True" || rowData.StatusCheck == "1") ? null :
                        <ToolTip title="Check Visits" placement="top" onClick={() => GetVisits(rowData)} linkId="View" faName="fa fa-eye mr-2"></ToolTip>
                }
            </div>
        </React.Fragment>
    }
    const actionnewFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                {(rowData.Status == "0" || rowData.Status == "False") ? <span>Pending</span> : (rowData.Status == "2") ? <span>Rejected</span> : <span>Approved</span>}
            </div>
        </React.Fragment>
    }
    const columns = [
        { dataField: '#', text: '#', editable: false, formatter: idFormatter, headerStyle: (colum, colIndex) => { return { width: '5%' }; } },
        { dataField: 'VisitingCode', text: 'Visit Id', editable: false },
        { dataField: 'InvoiceNo', text: 'Invoice No', editable: false },
        { dataField: 'PatientName', text: 'Patient', editable: false },
        { dataField: 'MobileNo', text: 'Mobile', editable: false },
        { dataField: 'Status', text: 'Status', formatter: actionnewFormatter, editable: false },
        { dataField: 'RefundReason', text: 'Refund Reason', editable: false },
        { dataField: 'actions', text: 'Actions', formatter: actionFormatter, editable: false, headerStyle: (colum, colIndex) => { return { width: '7%' }; } }
    ];
    const BindGrid = async (mobileno, visitno, date) => {
        setTableData([]);
        setIsLoading(true);
        let url = FrontDesk_URL + 'accounts/getrefundrequests/' + localStorage.LoggedInUser + '/' + localStorage.CentreSNo + '/' + date
        await axios.get(url).then((response) => {
            const data = response.data;
            glbRecords = [];
            if (data.Success) {
                if (data.Data != null) {
                    glbRecords = data.Data;
                    setTableData(glbRecords);
                }
                setIsLoading(false);
            }
            else { APIError(data.Data, data.ErrorList.length === 0 ? "" : data.ErrorList[0].errorMsg); setIsLoading(false); }
        }).catch(function (error) { if (error.response) { AxiosError(error.response.status); } setIsLoading(false); });
    }
    const GetVisits = async (rwData) => {
        if (rwData.Status == "0") { setEnableRefund(true); }
        else { setEnableRefund(false); }
        setPatientName(rwData.PatientName);
        setPatientAge(rwData.Age);
        GetTestsList(rwData.Id, rwData.VisitingCode, rwData.OriginalInvoiceNo);
    }
    const testsColumns = [
        { dataField: '#', text: '#', editable: false, formatter: idFormatter, headerStyle: (colum, colIndex) => { return { width: '7%' }; } },
        { dataField: 'ItemCode', text: 'Test Code', editable: false },
        { dataField: 'Investigation', text: 'Test Name', editable: false },
        { dataField: 'ItemMRP', text: 'MRP', editable: false },
        { dataField: 'Discount', text: 'Discount', editable: false },
        { dataField: 'ItemRate', text: 'Rate', editable: false },
    ];
    const GetTestsList = async (id, visitcode, orgInvoiceNo) => {
        setTestsModal(false);
        setVisitNo(visitcode);
        setRefundRequestId(id);
        // setIsLoading(true);
        let url = FrontDesk_URL + 'accounts/getrefundrequesteditems/' + id;
        await axios.get(url).then((response) => {
            const data = response.data;
            if (data.Success) {
                if (data.Data != null) {
                    glbTestRecords = data.Data;
                    setVisitsTableData(glbTestRecords);
                    var refndAmount = 0;
                    for (var x in glbTestRecords) {
                        if (glbTestRecords[x].IsRefunded == "False") {
                            refndAmount = parseFloat(refndAmount) + parseFloat(glbTestRecords[x].ItemRate);
                        }
                    }
                    setRefundAmount(refndAmount);
                    openModal();
                    // setTimeout(() => { openModal(); }, 100);
                }
                // setIsLoading(false);
            }
            else { APIError(data.Data, data.ErrorList.length === 0 ? "" : data.ErrorList[0].errorMsg); }
        }).catch(function (error) { if (error.response) { AxiosError(error.response.status); } });
    }
    const ProcessRefunds = async (flag) => {
        if (parseFloat(refundAmount) > 0) {
            setIsSaving(true);
            var arrayRefunds = [];
            var refndAmount = 0;
            var vId = 0;
            for (var i in glbTestRecords) {
                var isRefund = "1";
                vId = glbTestRecords[i].VisitId; isRefund = "1";
                if (glbTestRecords[i].IsRefunded == "False") { refndAmount = parseFloat(refndAmount) + parseFloat(glbTestRecords[i].ItemRate); }
                arrayRefunds.push({ Id: glbTestRecords[i].Id, IsRefund: isRefund });
            }
            if ((parseFloat(refndAmount) == parseFloat(refundAmount)) && parseFloat(refndAmount) > 0) {
                const requestOptions = {
                    VisitId: vId,
                    RefundBy: localStorage.LoggedInUser,
                    RefundAmount: parseFloat(refundAmount).toFixed(2),
                    RefundInfo: arrayRefunds,
                    ApproveRejectFlag: flag,
                    RefundRequestId: (refundrequestid == "" || refundrequestid == null || refundrequestid == undefined) ? "0" : refundrequestid
                };
                // console.log(requestOptions)
                let url = FrontDesk_URL + 'accounts/refundbill'
                await axios.post(url, requestOptions).then((response) => {
                    const data = response.data;
                    if (data.Success) {
                        SweetAlert.fire({ title: "Success", text: "Refund updated successfully", icon: "success" });
                        openModal();
                        setIsSaving(false);
                        BindGrid("", "", fromDate.current.value);
                    }
                    else {
                        if (data.Data == "-1999") {
                            SweetAlert.fire({ title: "Rejected", text: "Refund rejected successfully", icon: "success" });
                            openModal();
                            setIsSaving(false);
                            BindGrid("", "", fromDate.current.value);
                        }
                        else { SweetAlert.fire({ title: "Application Exception", text: "Some error occured! Please try after sometime", icon: "error" }); }
                        setIsSaving(false);
                    }
                }).catch(function (error) { setIsSaving(false); });
            }
        } else { SweetAlert.fire({ title: "Validation  Failed", text: "Refund amount must be greater than ZERO", icon: "error" }); }
    }
    const handleSearch = (e) => {
        BindGrid("", "", fromDate.current.value);
    }
    const AxiosError = async (ErrorCode) => {
        if (ErrorCode === 400) { SweetAlert.fire({ title: "Error 400", text: "Bad request", icon: "error" }) }
        else if (ErrorCode === 401) { SweetAlert.fire({ title: "Error 401", text: "Unauthorized", icon: "error" }) }
        else if (ErrorCode === 403) { SweetAlert.fire({ title: "Error 403", text: "Forbidden", icon: "error" }) }
        else if (ErrorCode === 404) { SweetAlert.fire({ title: "Error 404", text: "Page not found", icon: "error" }) }
        else if (ErrorCode === 500) { SweetAlert.fire({ title: "Error 500", text: "Internal Server Error", icon: "error" }) }
        else if (ErrorCode === 503) { SweetAlert.fire({ title: "Error 503", text: "Service Unavailable", icon: "error" }) }
    }
    const APIError = async (ErrorCode, ErrorMsg) => {
        if (ErrorCode === "-1") { SweetAlert.fire({ title: "Data unavailable", text: "No record found", icon: "error" }) }
        else if (ErrorCode === "-2") { SweetAlert.fire({ title: "API Server Error", text: ErrorMsg, icon: "error" }); }
        else if (ErrorCode === "-3") { SweetAlert.fire({ title: "Runtime Exception", text: "Some error occured! Please try after sometime.", icon: "error" }) }
        else if (ErrorCode === "-4") { SweetAlert.fire({ title: "Wrong Credentials", text: "Please enter correct credentials", icon: "error" }) }
        else if (ErrorCode === "-5") { SweetAlert.fire({ title: "Oops", text: "Data could not be saved, Please try again later", icon: "error" }) }
        else if (ErrorCode === "-6") { SweetAlert.fire({ title: "Failed", text: "Check DB Logs", icon: "error" }) }
        else if (ErrorCode === "-7") { SweetAlert.fire({ title: "Duplicate", text: "Template already exists", icon: "error" }) }
        else if (ErrorCode === "-8") { SweetAlert.fire({ title: "Data unavailable", text: "No record found to import", icon: "error" }) }
        else if (ErrorCode === "-9") { SweetAlert.fire({ title: "LDAP authentication failed", text: ErrorMsg, icon: "error" }) }
        else { SweetAlert.fire({ title: "Application Exception", text: "Some error occured! Please try after sometime", icon: "error" }) }
    }
    return (
        <React.Fragment>
            <Breadcrumb title="Refund Approval" parent="Registration" />
            <Container fluid>
                <Row>
                    <Col md={12}>
                        <Card>
                            <Card.Header className='pb-0'>
                                <Form.Row>
                                    <Col md={8}>
                                        <Card.Title>Patient List</Card.Title>
                                    </Col>
                                    <Col md={4}>
                                        <Form.Group>
                                            <InputGroup className="mb-3">
                                                <Form.Label className='raFilter'>Filter by date : &nbsp;</Form.Label>
                                                <Form.Control type="date" id="date1" name="date1" ref={fromDate} defaultValue={defaultDate} onChange={handleSearch} />
                                            </InputGroup>
                                        </Form.Group>
                                    </Col>
                                </Form.Row>
                            </Card.Header>
                            <Card.Body>
                                {
                                    isLoading ?
                                        <div className='text-center'> <Spinner animation="border" size='sm' /></div> :
                                        <>
                                            <DataTables keyField='VisitingId' tableData={tableData} columns={columns} />
                                        </>
                                }
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                {testsModal &&
                    <Modal isOpen={testsModal} toggle={openModal} centered={true} size="lg">
                        <ModalHeader toggle={openModal}>Refund Approval List</ModalHeader>
                        <ModalBody>
                            <>
                                <Row>
                                    <Col md={12}>
                                        <Card>
                                            <Card.Body>
                                                <div className='row align-items-center'>
                                                    <div className='col-md-10 order-2 order-md-1'>
                                                        <p><b>Name:</b>{patientName}, <b>Age:</b> {patientAge} Years, <b>Visit Id:</b> {visitNo}</p>
                                                    </div>
                                                </div>
                                                {
                                                    isLoading ?
                                                        <div className='text-center'> <Spinner animation="border" size='sm' /></div> :
                                                        <>
                                                            <DataTables keyField='id' tableData={visitsTableData} columns={testsColumns} pagination={paginationFactory(paginationOptions)} />
                                                        </>
                                                }
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                </Row>
                                {enableRefund ?
                                    <Row>
                                        <Col md={12} className='text-center'>
                                            {isSaving ?
                                                <LoadingButton variantName='primary' cssName="mr-2" /> :
                                                <>
                                                    <Button variant='primary' type='submit' className="mr-2" onClick={(e) => ProcessRefunds("1")}>Approve {parseFloat(refundAmount).toFixed(2)}</Button>
                                                    <Button variant='primary' type='submit' className="mr-2" onClick={(e) => ProcessRefunds("2")}>Reject</Button>
                                                </>
                                            }
                                        </Col>
                                    </Row>
                                    : null
                                }
                            </>
                            :
                            <Row>
                            </Row>
                        </ModalBody>
                    </Modal>
                }
            </Container>
        </React.Fragment >
    )
}

export default RefundApproval