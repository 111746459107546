import React from "react";
import { Container, Row, Col, Card, Form, Button, Table } from 'react-bootstrap';

class ConsentFormElectrophoresisRequ extends React.Component {

    render() {

        const pdfProps = this.props;
        const patientName = pdfProps.PName;
        const age = pdfProps.Age;
        const gender = pdfProps.Gender;
        const refDoctor = pdfProps.RefDoctor;
        const Address = pdfProps.Address;
        const Phnno = pdfProps.Phnno;
        console.log(pdfProps);
        return (
            <>
                <div className="formWrapper">
                    <div className="header">
                        <div className="logo"> <img src="/static/media/logo-atulaya.fbfb36f9.png" alt="thumb" /> </div>
                        <div className="headinginnersec">
                            <h1> ELECTROPHORESIS REQUEST FORM </h1>
                        </div>
                    </div>
                    <div className="main">
                        <div className="row mb-2">
                            <div className="col-sm-6"> Name:  <span ><b>{patientName}</b></span></div>
                            <div className="col-sm-6"> Age/Sex:  <span ><b>{age}/{gender}</b></span></div>
                        </div>
                        <div className="row mb-2">
                            <div className="col-sm-6"> MR No.:</div>
                        </div>
                        <div className="row mb-2">
                            <div className="col-sm-6"> Address:  <span ><b>{Address}</b></span></div>
                        </div>
                        <div className="row mb-2">
                            <div className="col-sm-6"> Phono No.:  <span ><b>{Phnno}</b></span></div>
                        </div>
                        <div className="row mb-2">
                            <div className="col-sm-6"> Religion:</div>
                        </div>
                        <div className="row mb-2">
                            <div className="col-sm-6"> Marital Status:</div>
                            <div className="col-sm-6"> Issues:</div>
                        </div>
                        <div className="row mb-2">
                            <div className="col-sm-6"> Refered by:<span ><b>{refDoctor}</b></span></div>
                        </div>
                        <div className="row mb-2">
                            <div className="col-sm-12"> Cheif complaint(Why Electrophoresis is being advised)</div>
                        </div>
                        <div className="row mb-2">
                            <div className="col-sm-6"> H/o Anemia in previous pregnancy</div>
                        </div>
                        <div className="row mb-2">
                            <div className="col-sm-6"> H/o Drug intake (e.g. haematinics)</div>
                        </div>
                        <div className="row mb-2">
                            <div className="col-sm-6"> H/o Consanginous marriage</div>
                        </div>
                        <div className="row mb-2">
                            <div className="col-sm-6"> H/o Jaundice</div>
                        </div>
                        <div className="row mb-2">
                            <div className="col-sm-6"> H/o Blood Transfusion</div>
                        </div>
                        <div className="row mb-2">
                            <div className="col-sm-6"> Family pedigree chart</div>
                        </div>
                        <div className="row mb-2">
                            <div className="col-sm-6"> Previous Heamogram Findings</div>
                        </div>
                        <div className="row mb-2">
                            <div className="col-sm-6"></div>
                            <div className="col-sm-6">Signature</div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default ConsentFormElectrophoresisRequ;