import React, { useEffect, useState, useRef } from 'react';
import Breadcrumb from '../../components/common/breadcrumb';
import { FrontDesk_URL } from '../../constant';
import { Container, Row, Col, Card, Form, Button } from 'react-bootstrap';
import axios from 'axios';
import SweetAlert from 'sweetalert2';
import InputGroup from 'react-bootstrap/InputGroup';
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { withRouter, useHistory } from "react-router-dom";
import LoadingButton from '../commonComponent/LoadingButton';
import NoRecordFounds from '../commonComponent/DataTable/NoRecordFounds';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { MultiSelect } from 'react-multi-select-component';
import '../../pages/react-multiselect.css';

const B2BInvoices = () => {
    let fromDate = useRef();
    let toDate = useRef();
    let CentreName = useRef("");

    const [crntPageNO, setCrntPageNO] = useState(0);
    const [defaultDate, setDefaultDate] = useState("");
    const [isButtonLoading, setIsButtonLoading] = useState(false);
    const [invoices, setInvoices] = useState([]);


    useEffect(() => {
        const today = new Date();
        const date = today.setDate(today.getDate());
        const defaultValue = new Date(date).toISOString().split('T')[0] // yyyy-mm-dd
        setDefaultDate(defaultValue);
        setTimeout(() => {
            GetB2BInvoiceNo("1");
        }, 1000);
    }, []);
    const paginationOptions = {
        sizePerPage: 50,
        showTotal: false,
        firstPageText: 'First',
        prePageText: 'Back',
        nextPageText: 'Next',
        lastPageText: 'Last',
        onPageChange: (page, sizePerPage) => { setCrntPageNO(((page - 1) * sizePerPage)); }
    };
    const idFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                <span>{crntPageNO + rowIndex + 1}</span>
            </div>
        </React.Fragment>
    }
    const actionFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                {/* <a href='javascriptvoid:(0)' id={`Documents${rowData.B2BInv_ID}`} onClick={() => GetReportDataCheckRecordFound(rowData.B2BInv_ID)}>View Invoice</a> */}
                <button className='btn btn-link' style={{ color: '#e31e25', padding: '0px' }} id={`Documents${rowData.B2BInv_ID}`} onClick={() => GetReportDataCheckRecordFound(rowData.B2BInv_ID)}>View Invoice</button>
            </div>
        </React.Fragment>
    }
    const columns =
        [
            { dataField: '#', text: '#', editable: false, formatter: idFormatter, headerStyle: (colum, colIndex) => { return { width: '5%' }; } },
            // { dataField: 'CentreName', text: 'Centre Name', editable: false },
            { dataField: 'InvoiceNo', text: 'Invoice No', editable: false },
            { dataField: 'BillingCycle', text: 'Billing Cycle', editable: false },
            { dataField: 'FromDate', text: 'From Date', editable: false },
            { dataField: 'ToDate', text: 'To Date', editable: false },
            { dataField: 'Action', text: 'Action', editable: false, formatter: actionFormatter }
        ];

    const GetB2BInvoiceNo = async (Flag) => {
        var fromdte = "0";
        var todte = "0";
        if (Flag == "1") {
            if (fromDate.current.value == "" || fromDate.current.value == null || fromDate.current.value == undefined) {
                SweetAlert.fire({ title: "Warning!", text: "Please select from date", icon: "info" });
                return false;
            }
            if (toDate.current.value == "" || toDate.current.value == null || toDate.current.value == undefined) {
                SweetAlert.fire({ title: "Warning!", text: "Please select to date", icon: "info" });
                return false;
            }
            fromdte = fromDate.current.value;
            todte = toDate.current.value;
        }
        var arr = [];
        arr.push({
            "CentreId": localStorage.CentreSNo
        })
        const requestoptions = {
            FromDate: fromdte,
            ToDate: todte,
            CentreId: JSON.stringify(arr),
            LoginId: localStorage.LoggedInUser
        };
        setIsButtonLoading(true);
        try {
            let url = FrontDesk_URL + 'reports/GetB2BInvoiceNo'
            await axios.post(url, requestoptions).then((response) => {
                const data = response.data;
                setIsButtonLoading(false);
                if (data.Success) {
                    if (data.Data == null) { setInvoices([]); }
                    else { setInvoices(data.Data); }
                }
                else { setInvoices([]); }
            }).catch(function (error) { if (error.response) { setIsButtonLoading(false); ErrorFunction(error.response.status); } });
        }
        catch (error) { setIsButtonLoading(false); SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    }
    const GetReportDataCheckRecordFound = async (InvoiceId) => {
        const ObjServiceCollection = {
            InvoiceId: InvoiceId,
            LoginId: localStorage.LoggedInUser
        };
        try {
            let url = FrontDesk_URL + 'reports/GetB2bInvoiceGenerateReports'
            await axios.post(url, ObjServiceCollection).then((response) => {
                const data = response.data;
                if (data.Success) {
                    let ds = data.Data;
                    // if (ds != null && ds.objB2BInvoiceTestCountDataList != null && ds.objB2BInvoiceTestCountDataList.length > 0) {
                    if (ds != null) {
                        const stateparams = { fromDate: fromDate.current.value, toDate: toDate.current.value };
                        localStorage.removeItem('CustreportData');
                        localStorage.removeItem('IID');
                        localStorage.setItem('CustreportData', JSON.stringify(stateparams));
                        localStorage.setItem('IID', InvoiceId);
                        window.open(`${process.env.PUBLIC_URL}/front-office/printb2binvoice`, '_blank');
                    }
                    else {
                        SweetAlert.fire({ title: "Warning!", text: "record no found", icon: "info" });
                    }
                }
            }).catch(function (error) { });
        }
        catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    }

    const ErrorFunction = async (ErrorCode) => {
        if (ErrorCode == "400") { SweetAlert.fire({ title: "Error 400", text: "Bad request", icon: "error" }); }
        else if (ErrorCode == "401") { SweetAlert.fire({ title: "Error 401", text: "Unauthorized", icon: "error" }); }
        else if (ErrorCode == "403") { SweetAlert.fire({ title: "Error 403", text: "Forbidden", icon: "error" }); }
        else if (ErrorCode == "404") { SweetAlert.fire({ title: "Error 404", text: "Page not found", icon: "error" }); }
        else if (ErrorCode == "500") { SweetAlert.fire({ title: "Error 500", text: "Internal Server Error", icon: "error" }); }
        else if (ErrorCode == "503") { SweetAlert.fire({ title: "Error 503", text: "Service Unavailable", icon: "error" }); }
    }
    const APIError = async (ErrorCode, ErrorMsg) => {
        if (ErrorCode === "-1") { SweetAlert.fire({ title: "Data unavailable", text: "No record found", icon: "error" }) }
        else if (ErrorCode === "-2") { SweetAlert.fire({ title: "API Server Error", text: ErrorMsg, icon: "error" }); }
        else if (ErrorCode === "-3") { SweetAlert.fire({ title: "Runtime Exception", text: "Some error occured! Please try after sometime.", icon: "error" }) }
        else if (ErrorCode === "-4") { SweetAlert.fire({ title: "Wrong Credentials", text: "Please enter correct credentials", icon: "error" }) }
        else if (ErrorCode === "-5") { SweetAlert.fire({ title: "Oops", text: "Data could not be saved, Please try again later", icon: "error" }) }
        else if (ErrorCode === "-6") { SweetAlert.fire({ title: "Failed", text: "Check DB Logs", icon: "error" }) }
        else if (ErrorCode === "-7") { SweetAlert.fire({ title: "Duplicate", text: "Template already exists", icon: "error" }) }
        else if (ErrorCode === "-8") { SweetAlert.fire({ title: "Data unavailable", text: "No record found to import", icon: "error" }) }
        else if (ErrorCode === "-9") { SweetAlert.fire({ title: "LDAP authentication failed", text: ErrorMsg, icon: "error" }) }
        else { SweetAlert.fire({ title: "Application Exception", text: "Some error occured! Please try after sometime", icon: "error" }) }
    }
    return (
        <React.Fragment>
            <Breadcrumb title="B2B Invoices" parent="Reports" />
            <Container fluid>
                <Row className='mt25'>
                    <Col md={12}>
                        <Card>
                            <Card.Body>
                                <Form.Row >

                                    <Col md={4}>
                                        <Form.Group>
                                            <Form.Label>From Date</Form.Label>
                                            <InputGroup className="mb-3 d-flex justify-content-start">
                                                <Form.Control type="date" id="fromDate" name="fromDate" ref={fromDate} defaultValue={defaultDate} />
                                            </InputGroup>
                                        </Form.Group>
                                    </Col>
                                    <Col md={4}>
                                        <Form.Group>
                                            <Form.Label>To Date</Form.Label>
                                            <InputGroup className="mb-3 d-flex justify-content-start">
                                                <Form.Control type="date" id="toDate" name="toDate" ref={toDate} defaultValue={defaultDate} />
                                            </InputGroup>
                                        </Form.Group>
                                    </Col>
                                    <Col md={2}>&nbsp;</Col>
                                    {/* <Col md={4}>
                                        <Form.Group>
                                            <Form.Label>Status</Form.Label>
                                            <Form.Control as="select" name="Status" id="RefStatus" ref={refStatustype} >
                                                <option key="-1" value="-1">---Select All---</option>
                                                <option key="1" value="1">Approved</option>
                                                <option key="2" value="2">Rejected</option>
                                                <option key="0" value="0">Pending</option>
                                            </Form.Control>
                                        </Form.Group>
                                    </Col> */}

                                    <Col md={12} className='text-center __center__buttonsec'>
                                        <Form.Group>
                                            {isButtonLoading ? <LoadingButton variantName='primary' cssName="" /> :
                                                <Button className='ml-2' variant='primary' onClick={() => GetB2BInvoiceNo("1")}>Search</Button>
                                            }
                                        </Form.Group>
                                    </Col>

                                </Form.Row>
                                <div className="mt15">
                                    <BootstrapTable
                                        keyField='B2BInv_ID'
                                        data={invoices}
                                        columns={columns}
                                        bootstrap4
                                        headerWrapperClasses="thead-light"
                                        bordered={false}
                                        noDataIndication={<NoRecordFounds />}
                                        pagination={paginationFactory(paginationOptions)}
                                    >
                                    </BootstrapTable>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </React.Fragment>
    )
}
export default B2BInvoices;