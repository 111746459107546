import React, { useEffect, useState, useRef } from 'react';
import { Container, Row, Col, Card, Form, Button, Dropdown } from 'react-bootstrap';
import axios from 'axios';
import SweetAlert from 'sweetalert2';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Spinner } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ToolTip from '../commonComponent/ToolTip';
import { AllIndustries, FrontDesk_URL } from '../../constant';
import DataTables from '../commonComponent/DataTable/DataTables';
import Breadcrumb from '../../components/common/breadcrumb';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useHistory } from 'react-router';
import LoadingButton from '../commonComponent/LoadingButton';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

var glbRecords = [];
var currentDate = "";
var fdate = "";
const MyRoutes = () => {
    const history = useHistory();
    const [tableData, setTableData] = useState([]);
    const [crntPageNO, setCrntPageNO] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [currdate, setCurrDate] = useState("");
    const [selectedDate, setSelectedDate] = useState();
    const [openfilter, setOpenFilter] = useState(false);
    const [routes, setRoutes] = useState(0);
    const [actionReason, setActionReason] = useState(0);
    const [otherOpen, setOtherOpen] = useState(false);
    const [openmodal, setOpenModal] = useState(false);
    const [actionNo, setActionNo] = useState("0");
    const [appointmentId, setAppointmentId] = useState("0");
    const [isButtonLoading, setIsButtonLoading] = useState(false);
    let phleboAction = useRef();
    let otherReason = useRef();
    let mobno = useRef();
    let patientnme = useRef();
    let routenme = useRef();
    const calendarRef = React.createRef();
    useEffect(() => {
        const current = new Date();
        const tmpDate = current.getDate() < "10" ? "0" + current.getDate() : current.getDate();
        const month = (current.getMonth() + 1) < "10" ? "0" + (current.getMonth() + 1) : (current.getMonth() + 1);
        const year = current.getFullYear();
        currentDate = `${year}-${month}-${tmpDate}`;
        formik.values.routeDate = currentDate;
        setCurrDate(currentDate);
        var datenew = new Date(currentDate),
            mnth = ("0" + (datenew.getMonth() + 1)).slice(-2),
            day = ("0" + datenew.getDate()).slice(-2);
        fdate = [datenew.getFullYear(), mnth, day].join("-");
        setSelectedDate(datenew);
        console.log(fdate);
        GetRoutes(fdate);
        BindGrid("", "0", "", fdate);
    }, [])
    const GetRoutes = async (Date) => {
        let url = FrontDesk_URL + 'Phlebo/getphlebocentreroutes/' + localStorage.getItem("CentreSNo") + '/' + localStorage.getItem("LoggedInUser") + '/' + Date
        await axios.get(url).then((response) => {
            const data = response.data;
            if (data.Success) {
                if (data.Data !== null) {
                    setRoutes([]);
                    setRoutes(data.Data.map((d) => (<option key={d.RouteId} value={d.RouteId}>{d.RouteName}</option>)));
                }
                else {
                    setRoutes([]);
                }
            }
            else { APIError(data.Data, data.ErrorList.length === 0 ? "" : data.ErrorList[0].errorMsg); }
        }).catch(function (error) { if (error.response) { AxiosError(error.response.status); } });
    }
    const GetActionReason = async (rowID, actionID) => {
        let url = FrontDesk_URL + 'Phlebo/GetPhleboActionReason/' + actionID
        await axios.get(url).then((response) => {
            const data = response.data;
            if (data.Success) {
                setAppointmentId(rowID);
                setActionNo(actionID);
                setOpenModal(true);
                setActionReason(data.Data.map((d) => (<option key={d.ID} value={d.ID}>{d.ActionReason}</option>)));
            }
            else { APIError(data.Data, data.ErrorList.length === 0 ? "" : data.ErrorList[0].errorMsg); }
        }).catch(function (error) { if (error.response) { AxiosError(error.response.status); } });
    }
    const paginationOptions = {
        sizePerPage: 20, showTotal: false, firstPageText: 'First', prePageText: 'Back', nextPageText: 'Next', lastPageText: 'Last',
        onPageChange: (page, sizePerPage) => { setCrntPageNO(((page - 1) * sizePerPage)); }
    };
    const idFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                <span>{crntPageNO + rowIndex + 1}</span>
            </div>
        </React.Fragment>
    }
    const actionFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                {
                    rowData.AppointmentStatus.toLowerCase() === "running late" ?
                        <>
                            <ToolTip title="Start" placement="top" linkId="View" onClick={() => editHandler(rowData.Id, rowData.CentreId, rowData.Age, rowData.Gender, rowData.PatientId, rowData.LocationId)} faName="fa fa-eye mr-2"></ToolTip>
                            <ToolTip title="Couldn't Complete" placement="top" linkId="Status" faName="fa fa-info-circle mr-2" onClick={() => GetActionReason(rowData.Id, "1")}></ToolTip>
                        </> :
                        <>
                            <ToolTip title="Start" placement="top" linkId="View" onClick={() => editHandler(rowData.Id, rowData.CentreId, rowData.Age, rowData.Gender, rowData.PatientId, rowData.LocationId)} faName="fa fa-eye mr-2"></ToolTip>
                            <ToolTip title="Running Late" placement="top" linkId="Status-Late" faName="fa fa-clock-o mr-2" onClick={() => GetActionReason(rowData.Id, "2")}></ToolTip>
                            <ToolTip title="Couldn't Complete" placement="top" linkId="Status" faName="fa fa-info-circle mr-2" onClick={() => GetActionReason(rowData.Id, "1")}></ToolTip>
                        </>
                }
            </div>
        </React.Fragment>
    }
    const editHandler = async (aId, CentreId, Age, Gender, PatientId, LocationId) => {
        setAppointmentId(aId);
        setActionNo("3");
        const requestOptions = {
            AppointmentId: aId,
            Status: "3",
            LoginId: localStorage.LoggedInUser,
            ReasonId: "0",
            OtherText: "0",
        };
        setIsLoading(true);
        let url = FrontDesk_URL + 'Phlebo/UpdateStatus'
        await axios.post(url, requestOptions).then((response) => {
            const data = response.data;
            setIsLoading(false);
            if (data.Success) {
                setAppointmentId("0");
                setActionNo("0");
                setOpenModal(false);
                GetRoutes(fdate);
                BindGrid("", routenme.current.value, "", fdate);
            }
            else {
                APIError(data.Data, data.ErrorList.length === 0 ? "" : data.ErrorList[0].errorMsg);
            }
        }).catch(function (error) {
            setIsLoading(false);
            if (error.response) {
                AxiosError(error.response.status);
            }
        });
        localStorage.setItem('aId', aId);
        localStorage.setItem('CentreId', CentreId);
        localStorage.setItem('Age', Age);
        localStorage.setItem('Gender', Gender);
        localStorage.setItem('PatientId', PatientId);
        localStorage.setItem('LocationId', LocationId);
        history.push({ pathname: `${process.env.PUBLIC_URL}/phlebo/routeinfo`, search: `` });
    }
    const columns = [
        { dataField: '#', text: '#', editable: false, formatter: idFormatter, headerStyle: (colum, colIndex) => { return { width: '5%' }; } },
        { dataField: 'RouteName', text: 'Route', editable: false },
        { dataField: 'Name', text: 'Patient Name', editable: false },
        { dataField: 'Mobile', text: 'Mobile No', editable: false },
        { dataField: 'Address', text: 'Address', editable: false },
        { dataField: 'AppointmentStatus', text: 'Status', editable: false },
        { dataField: 'actions', text: 'Actions', formatter: actionFormatter, editable: false, headerStyle: (colum, colIndex) => { return { width: '10%' }; } }
    ];
    const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
    const formik = useFormik({
        initialValues: { mobileNo: '', routeId: '0', routeDate: '', pName: '' },
        validationSchema: Yup.object({
            // mobileNo: Yup.string().max(10, 'Mobile no must be 10 numbers').min(10, 'Mobile no must be 10 numbers').matches(phoneRegExp, 'Mobile no is not valid')
        }),
        onSubmit: values => { handleSearch(values); },
    });
    const handleSearch = async (values) => {
        BindGrid("", values.routeId, "", fdate);
    }
    const BindGrid = async (mNo, routeId, pName, rDate) => {
        setTableData([]);
        setIsLoading(true);
        const requestOptions = { PhleboId: localStorage.getItem("LoggedInUser"), MobileNo: mNo, RouteId: routeId, PatientName: pName, Date: rDate, CentreId: localStorage.getItem("CentreSNo") };
        let url = FrontDesk_URL + 'phlebo/getphleboappointments'
        await axios.post(url, requestOptions).then((response) => {
            const data = response.data;
            glbRecords = [];
            if (data.Success) {
                if (data.Data != null) {
                    glbRecords = data.Data;
                    setTableData(glbRecords);
                }
                setIsLoading(false);
            }
            else { APIError(data.Data, data.ErrorList.length === 0 ? "" : data.ErrorList[0].errorMsg); setIsLoading(false); }
        }).catch(function (error) { if (error.response) { AxiosError(error.response.status); } setIsLoading(false); });
    }
    const Reset = () => {
        formik.values.mobileNo = "";
        formik.values.pName = "";
        formik.values.routeId = "0";
        formik.values.routeDate = currentDate;
        setTableData([]);
        setOpenFilter(false);
    }
    const DisableKeyPress = (e) => { e.preventDefault(); return false; }
    const UpdateStatus = async () => {
        let otherReasonText = "";
        if (otherReason.current != undefined) {
            otherReasonText = otherReason.current.value;
        }
        let reasonID = "0";
        if (phleboAction.current != undefined) {
            reasonID = phleboAction.current.value;
        }
        const requestOptions = {
            AppointmentId: appointmentId,
            Status: actionNo,
            LoginId: localStorage.LoggedInUser,
            ReasonId: reasonID,
            OtherText: otherReasonText,
        };
        if (appointmentId == "0" || actionNo == "0") {
            SweetAlert.fire('Unable to save data.Please try again.');
            return false
        }
        else if ((actionNo == "1" || actionNo == "2") && reasonID == "0") {
            SweetAlert.fire('Please select reason.');
            return false
        }
        else if ((actionNo == "1" || actionNo == "2") && (reasonID == "5" || reasonID == "11") && otherReasonText == "") {
            SweetAlert.fire('Please enter other text.');
            return false
        }
        else {
            setIsLoading(true);
            let url = FrontDesk_URL + 'Phlebo/UpdateStatus'
            await axios.post(url, requestOptions).then((response) => {
                const data = response.data;
                setIsLoading(false);
                if (data.Success) {
                    setAppointmentId("0");
                    setActionNo("0");
                    setOpenModal(false);
                    if (actionNo === "1") { SweetAlert.fire("Successfuly updated Could not complete."); }
                    else if (actionNo === "2") { SweetAlert.fire("Successfuly updated Running late."); }
                    history.push({ pathname: `${process.env.PUBLIC_URL}/phlebo/myroutes`, search: `` });
                    window.location.reload(false);
                    GetRoutes(fdate);
                    BindGrid("", routenme.current.value, "", fdate);
                }
                else {
                    APIError(data.Data, data.ErrorList.length === 0 ? "" : data.ErrorList[0].errorMsg);
                }
            }).catch(function (error) {
                setIsLoading(false);
                if (error.response) {
                    AxiosError(error.response.status);
                }
            });
        }
    }
    const SelectRouteId = async (e) => {
        if (e.target.value === "0") { localStorage.removeItem('RouteId'); SweetAlert.fire("Please select route"); return false; }
        else { localStorage.setItem('RouteId', e.target.value); }
    }
    const handleDateChange = (date) => {
        var datenew = new Date(date),
            mnth = ("0" + (datenew.getMonth() + 1)).slice(-2),
            day = ("0" + datenew.getDate()).slice(-2);
        fdate = [datenew.getFullYear(), mnth, day].join("-");
        setSelectedDate(datenew);
        GetRoutes(fdate);
    }
    const OpenFilter = () => {
        setOpenFilter(true);
    }
    const AxiosError = async (ErrorCode) => {
        if (ErrorCode === 400) { SweetAlert.fire({ title: "Error 400", text: "Bad request", icon: "error" }) }
        else if (ErrorCode === 401) { SweetAlert.fire({ title: "Error 401", text: "Unauthorized", icon: "error" }) }
        else if (ErrorCode === 403) { SweetAlert.fire({ title: "Error 403", text: "Forbidden", icon: "error" }) }
        else if (ErrorCode === 404) { SweetAlert.fire({ title: "Error 404", text: "Page not found", icon: "error" }) }
        else if (ErrorCode === 500) { SweetAlert.fire({ title: "Error 500", text: "Internal Server Error", icon: "error" }) }
        else if (ErrorCode === 503) { SweetAlert.fire({ title: "Error 503", text: "Service Unavailable", icon: "error" }) }
    }
    const APIError = async (ErrorCode, ErrorMsg) => {
        if (ErrorCode === "-1") { SweetAlert.fire({ title: "Data unavailable", text: "No record found", icon: "error" }) }
        else if (ErrorCode === "-2") { SweetAlert.fire({ title: "API Server Error", text: ErrorMsg, icon: "error" }); }
        else if (ErrorCode === "-3") { SweetAlert.fire({ title: "Runtime Exception", text: "Some error occured! Please try after sometime.", icon: "error" }) }
        else if (ErrorCode === "-4") { SweetAlert.fire({ title: "Wrong Credentials", text: "Please enter correct credentials", icon: "error" }) }
        else if (ErrorCode === "-5") { SweetAlert.fire({ title: "Oops", text: "Data could not be saved, Please try again later", icon: "error" }) }
        else if (ErrorCode === "-6") { SweetAlert.fire({ title: "Failed", text: "Check DB Logs", icon: "error" }) }
        else if (ErrorCode === "-7") { SweetAlert.fire({ title: "Duplicate", text: "Template already exists", icon: "error" }) }
        else if (ErrorCode === "-8") { SweetAlert.fire({ title: "Data unavailable", text: "No record found to import", icon: "error" }) }
        else if (ErrorCode === "-9") { SweetAlert.fire({ title: "LDAP authentication failed", text: ErrorMsg, icon: "error" }) }
        else { SweetAlert.fire({ title: "Application Exception", text: "Some error occured! Please try after sometime", icon: "error" }) }
    }
    const ChangePhleboAction = (value) => {
        if (value === "5" || value === "11") {
            setOtherOpen(true);
        }
        else {
            setOtherOpen(false);
        }
    }
    const openRequiredFieldModal = () => { setOtherOpen(false); setOpenModal(!openmodal); }
    return (
        <React.Fragment>
            <Breadcrumb title="My Routes" parent="Phlebo" />
            <Container fluid>
                {
                    openfilter &&
                    <>
                        <Row>
                            <Col md={12}>
                                <Card>
                                    <Card.Body>
                                        <Form>
                                            <Form.Row className='justify-content-center'>
                                                <Col lg={3} md={6}>
                                                    <Form.Group>
                                                        <Form.Label>Date</Form.Label>
                                                        <DatePicker selected={selectedDate} showMonthDropdown={true} showYearDropdown={true} dateFormat="yyyy-MM-dd" className='form-control'
                                                            onChange={handleDateChange} ref={calendarRef}
                                                        />
                                                        <span className='calandericon1' onClick={() => { calendarRef.current.setOpen(true) }}><i className="fa fa-calendar" aria-hidden="true"></i></span>
                                                    </Form.Group>
                                                </Col>
                                                <Col lg={3} md={4}>
                                                    <Form.Group>
                                                        <Form.Label>Route Name</Form.Label>
                                                        <Form.Control as="select" name="routeId" value={formik.values.routeId} onChange={formik.handleChange} onBlur={formik.handleBlur} >
                                                            <option value="0">---Select---</option>
                                                            {routes}
                                                        </Form.Control>
                                                    </Form.Group>
                                                </Col>
                                            </Form.Row>
                                            <Form.Row className='mt15'>
                                                <Col md={12} className='text-center'>
                                                    <Button variant='secondary' onClick={Reset}>Reset</Button>
                                                    <Button variant='primary' className='ml-2' type='submit' onClick={formik.handleSubmit}>Search</Button>
                                                </Col>
                                            </Form.Row>
                                        </Form>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </>
                }
                {
                    openfilter === true ? null :
                        <>
                            <Row className='align-items-center mb-3'>
                                <Col xs={6}>
                                    <h2 className='hdRoute'>Routes Info</h2>
                                </Col>
                                <Col xs={6} className='text-right'>
                                    <Button variant='secondary' onClick={() => OpenFilter()}><i className='fa fa-sliders'></i>Filter</Button>
                                </Col>
                            </Row>
                        </>
                }
                {
                    tableData.length > 0 ?
                        <>
                            {
                                tableData.map((d, index) =>
                                    <Row>
                                        <Col md={12}>
                                            <Card className='routeCard'>
                                                <Card.Header>
                                                    <Row className='align-items-center'>
                                                        <Col xs={6}>
                                                            <h3 className='routeSN'>{parseFloat(index) + 1}</h3>
                                                        </Col>
                                                        {
                                                            d.Status.toLowerCase() === "payment done" || d.Status.toLowerCase() === "completed" || d.Status.toLowerCase() === "canceled" ? null :
                                                                <Col xs={6} className='text-right'>
                                                                    <Dropdown>
                                                                        <Dropdown.Toggle variant="link" id="dropdown-basic">
                                                                            <i className='fa fa-ellipsis-h'></i>
                                                                        </Dropdown.Toggle>
                                                                        <Dropdown.Menu>
                                                                            <Dropdown.Item href="#" onClick={() => GetActionReason(d.Id, "2")}><i className="icofont icofont-clock-time"></i> Running Late</Dropdown.Item>
                                                                            <Dropdown.Item href="#" onClick={() => GetActionReason(d.Id, "1")}><i className="icofont icofont-close"></i> Could Not Complete</Dropdown.Item>
                                                                            {/*  <Dropdown.Item href="#"><i className="icofont icofont-clock-time"></i> Running Late</Dropdown.Item>
                                                                            <Dropdown.Item href="#"><i className="icofont icofont-close"></i> Could Not Complete</Dropdown.Item> */}
                                                                        </Dropdown.Menu>
                                                                    </Dropdown>
                                                                </Col>
                                                        }
                                                    </Row>
                                                </Card.Header>
                                                <Card.Body>
                                                    <Row>
                                                        <Col xs={12}>
                                                            <p><b>Route:</b> {d.RouteName}</p>
                                                            <p><b>Patient Name:</b> {d.Name + ' - ' + d.TotalPatients + ' | (' + d.PaitentAge + ')'}</p>
                                                            <p><b>Mobile:</b> {d.Mobile}</p>
                                                            <p><b>Address:</b> {d.Address}</p>
                                                            <p><b>Status:</b> {d.Status}</p>
                                                            <p><b>Time:</b> {d.RouteTime}</p>
                                                            {d.SpecialNotes != "" ?
                                                                <p><b>Special Notes:</b> {d.SpecialNotes}</p>
                                                                : null
                                                            }
                                                        </Col>
                                                    </Row>
                                                </Card.Body>
                                                {
                                                    d.Status.toLowerCase() === "payment done" || d.Status.toLowerCase() === "completed" || d.Status.toLowerCase() === "canceled" ? null :
                                                        <Card.Footer>
                                                            <Button variant='primary' onClick={() => editHandler(d.Id, d.CentreId, d.Age, d.Gender, d.PatientId, d.LocationId)}>Start <i className="icofont icofont-arrow-right"></i></Button>
                                                        </Card.Footer>
                                                }
                                            </Card>
                                        </Col>
                                    </Row>
                                )
                            }
                        </>
                        :
                        <Row>
                            <Col md={12}>
                                <Card className='routeCard'>
                                    <Card.Body>
                                        <h5 className="text-center mb-0">No data available</h5>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>

                }
            </Container>
            {
                openmodal &&
                <Modal isOpen={openmodal} centered={true} size="lg">
                    <ModalHeader>{actionNo == "2" ? "Running Late " : "Could Not Complete "} Reason</ModalHeader>
                    <ModalBody>
                        <Form.Row>
                            <Col md={12}>
                                <Form.Group>
                                    <Form.Label>Reason</Form.Label>
                                    <Form.Control as="select" ref={phleboAction} onChange={(e) => { ChangePhleboAction(e.target.value) }} >
                                        <option key="0" value="0">----Select-----</option>
                                        {actionReason}
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                            {otherOpen === true ?
                                <>
                                    <Col md={12}>
                                        <Form.Group>
                                            <Form.Label>Other Text</Form.Label>
                                            <Form.Control type="text" name="OtherText" ref={otherReason} />
                                        </Form.Group>
                                    </Col>
                                </>
                                : null}
                            <Col md={12} xs={11}>
                                <Form.Group className='m-t-15'>
                                    <Form.Label className='icRight'>
                                        <Button variant='secondary' className="mr-2" onClick={openRequiredFieldModal}>Cancel</Button>
                                        {
                                            isButtonLoading ?
                                                <LoadingButton variantName='primary' cssName="" /> :
                                                <Button variant='primary' type='submit' onClick={(e) => UpdateStatus()}>Save</Button>
                                        }
                                    </Form.Label>
                                </Form.Group>
                            </Col>
                        </Form.Row>
                    </ModalBody>
                </Modal>
            }
        </React.Fragment>
    )
}

export default MyRoutes;