import React from "react";
import { Container, Row, Col, Card, Form, Button, Table } from 'react-bootstrap';
import Alert from "react-bootstrap/Alert";
import DataTables from '../commonComponent/DataTable/DataTables';

class PrintDayEndReport extends React.Component {

    render() {
        const idFormatter = (cellData, rowData, rowIndex, extraData) => {
            return <React.Fragment>
                <div>
                    <span>{rowIndex + 1}</span>
                </div>
            </React.Fragment>
        }
        const creditFormatter = (cellData, rowData, rowIndex, extraData) => {
            return <React.Fragment>
                <div>
                    {parseFloat(rowData.CreditAmount) >  0 ?
                        rowData.CreditStatus === "Pending" ?
                            <span>{rowData.CreditStatus} on {rowData.CreditAuthorizer}</span>
                            :
                            <span>{rowData.CreditStatus} by {rowData.CreditAuthorizer}</span>
                        : null
                    }
                </div>
            </React.Fragment>
        }
        const discountFormatter = (cellData, rowData, rowIndex, extraData) => {
            return <React.Fragment>
                <div>
                    {parseFloat(rowData.DiscountAmount) >  0 ?
                        rowData.DiscountStatus === "Pending" ?
                            <span>{rowData.DiscountStatus} on {rowData.DiscountAuthorizer}</span>
                            :
                            <span>{rowData.DiscountStatus} by {rowData.DiscountAuthorizer}</span>
                        : null
                    }
                </div>
            </React.Fragment>
        }
        const columns = [
            { dataField: '#', text: '#', editable: false, formatter: idFormatter, headerStyle: (colum, colIndex) => { return { width: '5%' }; } },
            { dataField: 'VisitingNo', text: 'Visiting No', editable: false },
            { dataField: 'PatientName', text: 'Patient Name', editable: false },
            { dataField: 'OrderAmount', text: 'Order Total', editable: false },
            { dataField: 'CashReceived', text: 'Cash', editable: false },
            { dataField: 'OnlineReceived', text: 'Online', editable: false },
            { dataField: 'CreditAmount', text: 'Credit', editable: false },
            { text: 'Credit Status', editable: false, formatter: creditFormatter },
            { dataField: 'DiscountAmount', text: 'Discount', editable: false },
            { text: 'Discount Status', editable: false, formatter: discountFormatter },
        ];
        const pdfProps = this.props;
        const apiData = pdfProps.APIData;
        return (
            <>
                <Container fluid>
                    <Row>
                        <Col md={12}>
                            <Card>
                                <Card.Body>
                                    <Form>
                                        <Form.Row>
                                            <Col md={12}>
                                                <h6 className='text-red'>Summary</h6>
                                                <Alert variant="dark" className="text-center">
                                                    <ul className="list-inline pointsInline">
                                                        <li className="list-inline-item">Name: <strong>{apiData.DayEndDetails[0].LoginName}</strong></li>
                                                        <li className="list-inline-item">No. Of Bookings: <strong>{apiData.DayEndDetails[0].NoOfBookings}</strong></li>
                                                        <li className="list-inline-item">Total Sale: <strong>{apiData.DayEndDetails[0].TotalSale}</strong></li>
                                                        <li className="list-inline-item">Cash Received: <strong>{apiData.DayEndDetails[0].CashReceived}</strong></li>
                                                        <li className="list-inline-item">Online Received: <strong>{apiData.DayEndDetails[0].OnlineReceived}</strong></li>
                                                        <li className="list-inline-item">Discount Given: <strong>{apiData.DayEndDetails[0].DiscountGiven}</strong></li>
                                                        <li className="list-inline-item">Credit Given: <strong>{apiData.DayEndDetails[0].CreditGiven}</strong></li>
                                                        <li className="list-inline-item">Credit Pending: <strong>{apiData.DayEndDetails[0].CreditPending}</strong></li>
                                                        <li className="list-inline-item">Discount Pending: <strong>{apiData.DayEndDetails[0].DiscountPending}</strong></li>
                                                        <li className="list-inline-item">Print On: <strong>{apiData.DayEndDetails[0].PrintOn}</strong></li>
                                                    </ul>
                                                </Alert>
                                            </Col>
                                        </Form.Row>
                                        <Form.Row className='mt15'>
                                            <Col md={12}>
                                                <h6 className='text-red'>DayEnd Order Details</h6>
                                                {<DataTables keyField='id' tableData={apiData.reportdetails.filter((d) => d.VisitingId !== '')} columns={columns} />}
                                            </Col>
                                        </Form.Row>
                                    </Form>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </>
        );
    }
}

export default PrintDayEndReport;