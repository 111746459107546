import React, { useEffect, useState, useRef } from 'react';
import { Container, Row, Col, Card, Form, Button, Table } from 'react-bootstrap';
import Breadcrumb from '../../components/common/breadcrumb';
import paginationFactory from 'react-bootstrap-table2-paginator';
import DataTables from '../commonComponent/DataTable/DataTables';
import SweetAlert from 'sweetalert2';
import { FrontDesk_URL } from '../../constant';
import axios from 'axios';
import { Spinner } from 'react-bootstrap';
import ToolTip from '../commonComponent/ToolTip';
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { useHistory } from 'react-router';

const Appointments = () => {
    const history = useHistory();
    let mobileNo = useRef();
    let patientName = useRef();
    let sampleStatus = useRef();
    let fromDate = useRef();
    const [isLoading, setIsLoading] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [defaultDate, setDefaultDate] = useState("");
    const [itemModal, setItemModal] = useState();
    const [popupTableData, setPopupTableData] = useState([]);
    const [crntPageNO, setCrntPageNO] = useState(0);
    const [crntPageNO1, setCrntPageNO1] = useState(0);
    const openModal = () => { setItemModal(!itemModal); };

    const idFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                <span>{crntPageNO + rowIndex + 1}</span>
            </div>
        </React.Fragment>
    }
    const actionFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                <ToolTip title="View Services" placement="top" linkId={"VwItm" + rowIndex} onClick={() => GetInfo(rowData.NotificationsItem)} faName="fa fa-info-circle mr-2"></ToolTip>
                &nbsp;
                <ToolTip title="Complete Payment" placement="top" linkId={"CmPymnt" + rowIndex} onClick={() => CallRegistrationScreen(rowData.PatientId, rowData.VisitingId)} faName="fa fa-credit-card mr-2"></ToolTip>
            </div>
        </React.Fragment>
    }
    const serviceTypeFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                {rowData.IsPathologyOrder === "1" && rowData.IsRadiologyOrder === "1" ? <span>Radiology + Pathology</span> :
                    rowData.IsPathologyOrder === "0" && rowData.IsRadiologyOrder === "1" ? <span>Radiology</span> :
                        rowData.IsPathologyOrder === "1" && rowData.IsRadiologyOrder === "0" ? <span>Pathology</span> : null
                }
            </div>
        </React.Fragment>
    }
    const columns = [
        { dataField: '#', text: '#', editable: false, formatter: idFormatter, headerStyle: (colum, colIndex) => { return { width: '5%' }; } },
        { dataField: 'MobileNo', text: 'Mobile No.', editable: false },
        { dataField: 'PatientName', text: 'Name', editable: false },
        { dataField: 'Appointment', text: 'Appointment On', editable: false },
        { dataField: 'VisitSource', text: 'Visit Source', editable: false },
        { dataField: 'ServiceStatus', text: 'Service Status', editable: false },
        { text: 'Service Type', editable: false, formatter: serviceTypeFormatter },
        { dataField: 'Actions', isDummyField: true, text: 'Actions', editable: false, formatter: actionFormatter }
    ];
    const paginationOptions = {
        sizePerPage: 50,
        showTotal: false,
        firstPageText: 'First',
        prePageText: 'Back',
        nextPageText: 'Next',
        lastPageText: 'Last',
        onPageChange: (page, sizePerPage) => { setCrntPageNO(((page - 1) * sizePerPage)); }
    };
    const popuppaginationOptions = {
        sizePerPage: 50,
        showTotal: false,
        firstPageText: 'First',
        prePageText: 'Back',
        nextPageText: 'Next',
        lastPageText: 'Last',
        onPageChange: (page, sizePerPage) => { setCrntPageNO1(((page - 1) * sizePerPage)); }
    };
    useEffect(() => {
        const today = new Date();
        const date = today.setDate(today.getDate());
        const defaultValue = new Date(date).toISOString().split('T')[0] // yyyy-mm-dd
        setDefaultDate(defaultValue);
        BindGrid("", "", "1", defaultValue);
    }, []);
    const handleSearch = async () => {
        BindGrid(mobileNo.current.value, patientName.current.value, sampleStatus.current.value, fromDate.current.value);
    }
    const BindGrid = async (mNo, pName, sStats, fDte) => {
        setIsLoading(true);
        const requestOptions = { CentreId: localStorage.getItem('CentreSNo'), ApppointmentDate: fDte, MobileNo: mNo, PatientName: pName, Status: sStats };
        let url = FrontDesk_URL + 'master/getappointmentlist'
        await axios.post(url, requestOptions).then((response) => {
            const data = response.data;
            if (data.Success) {
                if (data.Data !== null) { setTableData(data.Data); } else { setTableData([]) }
                setIsLoading(false);
            }
            else { SweetAlert.fire({ title: "Application Exception", text: "Some error occured! Please try after sometime", icon: "error" }); setIsLoading(false); }
        }).catch(function (error) { if (error.response) { ErrorFunction(error.response.status); } setIsLoading(false); });
    }
    const GetInfo = async (rowData) => {
        console.log(rowData);
        setPopupTableData(rowData);
        openModal();
    }
    const modalityTypeFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                {rowData.ServiceType === "1" ? <span>Pathology</span> : <span>Radiology</span>}
            </div>
        </React.Fragment>
    }
    const popupColumns = [
        { dataField: 'Investigation', text: 'Investigation', editable: false },
        { dataField: 'TestCode', text: 'Test Code', editable: false },
        { text: 'Modality', editable: false, formatter: modalityTypeFormatter },
    ];
    const CallRegistrationScreen = async (PatientId, VisitingId) => {
        localStorage.setItem("WebsitePatientId",PatientId);
        localStorage.setItem("WebsiteVisitingId",VisitingId);
        history.push({ pathname: `${process.env.PUBLIC_URL}/registration`, search: `` });
    }
    const ErrorFunction = async (ErrorCode) => {
        if (ErrorCode == "400")
            SweetAlert.fire({ title: "Error 400", text: "Bad request", icon: "error" })
        else if (ErrorCode == "401")
            SweetAlert.fire({ title: "Error 401", text: "Unauthorized", icon: "error" })
        else if (ErrorCode == "403")
            SweetAlert.fire({ title: "Error 403", text: "Forbidden", icon: "error" })
        else if (ErrorCode == "404")
            SweetAlert.fire({ title: "Error 404", text: "Page not found", icon: "error" })
        else if (ErrorCode == "500")
            SweetAlert.fire({ title: "Error 500", text: "Internal Server Error", icon: "error" })
        else if (ErrorCode == "503")
            SweetAlert.fire({ title: "Error 503", text: "Service Unavailable", icon: "error" })
    }
    return (
        <>
            <Breadcrumb title="Online Appointments" parent="Master" />
            <Container fluid>
                <Row>
                    <Col md={12}>
                        <Card>
                            <Card.Body>
                                <Form>
                                    <Form.Row>
                                        <Col md={3}>
                                            <Form.Group>
                                                <Form.Label>Mobile No.:</Form.Label>
                                                <Form.Control type='text' ref={mobileNo} />
                                            </Form.Group>
                                        </Col>
                                        <Col md={3}>
                                            <Form.Group>
                                                <Form.Label>Name:</Form.Label>
                                                <Form.Control type='text' ref={patientName} />
                                            </Form.Group>
                                        </Col>
                                        <Col md={3}>
                                            <Form.Group>
                                                <Form.Label>Status</Form.Label>
                                                <Form.Control as="select" id="sample" name="sample" ref={sampleStatus} >
                                                    <option value="-1" >All</option>
                                                    <option value="0">Ordered</option>
                                                    <option value="1" selected>Pending</option>
                                                </Form.Control>
                                            </Form.Group>
                                        </Col>
                                        <Col md={3}>
                                            <Form.Group>
                                                <Form.Label>Appointment Date</Form.Label>
                                                <Form.Control type="date" id="date1" name="date1" ref={fromDate} defaultValue={defaultDate} />
                                            </Form.Group>
                                        </Col>
                                    </Form.Row>
                                    <Form.Row className='mt15'>
                                        <Col md={12} className='text-center'>
                                            <Button variant='primary' onClick={handleSearch} >Search</Button>
                                        </Col>
                                    </Form.Row>
                                </Form>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <Card>
                            <Card.Header className='pb-0'>
                                <Card.Title>Patient Detail</Card.Title>
                            </Card.Header>
                            <Card.Body>

                                {
                                    isLoading ?
                                        <div className='text-center'> <Spinner animation="border" size='sm' /></div> :
                                        <>
                                            <DataTables keyField='VisitingId' tableData={tableData} columns={columns} pagination={paginationFactory(paginationOptions)} />
                                        </>
                                }
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                {itemModal &&
                    <Modal isOpen={itemModal} toggle={openModal} centered={true} size="lg">
                        <ModalHeader toggle={openModal}>Investigations</ModalHeader>
                        <ModalBody>
                            <Row>
                                <Col md={12}>
                                    <Card>
                                        <Card.Body>
                                            <DataTables keyField='VisitingId' tableData={popupTableData} columns={popupColumns} pagination={paginationFactory(popuppaginationOptions)} />
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        </ModalBody>
                    </Modal>
                }
            </Container>
        </>
    )
}

export default Appointments