import React, { useEffect, useState, useRef } from 'react';
import Breadcrumb from '../../components/common/breadcrumb';
import { FrontDesk_URL } from '../../constant';
import { Container, Row, Col, Card, Form, Button } from 'react-bootstrap';
import DataTable from '../commonComponent/DataTable/DataTables';
import NoRecordFounds from '../commonComponent/DataTable/NoRecordFounds';
import axios from 'axios';
import SweetAlert from 'sweetalert2';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { CSVLink } from "react-csv";
import InputGroup from 'react-bootstrap/InputGroup';

const BillsPending = () => {
    const [tableData, setTableData] = useState();
    const [crntPageNO, setCrntPageNO] = useState(0);
    const [downloadvisible, setDownloadVisible] = useState(false);
    const [CSVData, setCSVData] = useState([]);
    const [defaultDate, setDefaultDate] = useState("");
    
    let fromDate = useRef();
    let toDate = useRef();

    useEffect(() => {
        const today = new Date();
        const date = today.setDate(today.getDate());
        const defaultValue = new Date(date).toISOString().split('T')[0] // yyyy-mm-dd
        setDefaultDate(defaultValue);
        BindGrid("0", "0", localStorage.CentreSNo, localStorage.LoggedInUser);
    }, []);
    const paginationOptions = {
        sizePerPage: 10,
        showTotal: false,
        firstPageText: 'First',
        prePageText: 'Back',
        nextPageText: 'Next',
        lastPageText: 'Last',
        onPageChange: (page, sizePerPage) => { setCrntPageNO(((page - 1) * sizePerPage)); }
    };
    const idFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                <span>{crntPageNO + rowIndex + 1}</span>
            </div>
        </React.Fragment>
    }
    const columns = [{ dataField: '#', text: '#', editable: false, formatter: idFormatter, headerStyle: (colum, colIndex) => { return { width: '5%' }; } },
    { dataField: 'OrderNo', text: 'Order No', editable: false },
    { dataField: 'VisitNo', text: 'Visit No', editable: false },
    // { dataField: 'CentreName', text: 'Centre Name', editable: false },
    { dataField: 'OrderAddedBy', text: 'Order Added By', editable: false },
    { dataField: 'OrderDate', text: 'Order Date', editable: false },
    { dataField: 'TotalOrderAmount', text: 'Order Amount', editable: false },
    { dataField: 'PendingSince', text: 'Pending Since', editable: false }
    ];
    const BindGrid = async (fromDateSelected, toDateSelected, centreid, loginid) => {
        if (fromDateSelected == undefined || fromDateSelected == null || fromDateSelected == "") { fromDateSelected = "0" }
        if (toDateSelected == undefined || toDateSelected == null || toDateSelected == "") { toDateSelected = "0" }
        if (fromDateSelected == "0" && toDateSelected != "0") { SweetAlert.fire("Please enter from order date"); return false; }
        if (fromDateSelected != "0" && toDateSelected == "0") { SweetAlert.fire("Please enter to order date"); return false; }
        try {
            let url = FrontDesk_URL + 'labreport/ordersbillnotgenerated/' + fromDateSelected + '/' + toDateSelected + '/' + centreid + '/' + loginid
            await axios.get(url).then((response) => {
                const data = response.data;
                if (data.Success) { if (data.Data != null) { setTableData(data.Data); } else { setTableData([]); } }
                else { setTableData([]); SweetAlert.fire("Some db error occured.Please report to support team."); }
            }).catch(function (error) { if (error.response) { setTableData([]); ErrorFunction(error.response.status); } });
        }
        catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    }
    const GetReportData = async (fromDateSelected, toDateSelected, centreid, loginid) => {
        if (fromDateSelected == undefined || fromDateSelected == null || fromDateSelected == "") { fromDateSelected = "0" }
        if (toDateSelected == undefined || toDateSelected == null || toDateSelected == "") { toDateSelected = "0" }
        if (fromDateSelected == "0" && toDateSelected != "0") { SweetAlert.fire("Please enter from order date"); return false; }
        if (fromDateSelected != "0" && toDateSelected == "0") { SweetAlert.fire("Please enter to order date"); return false; }
        setDownloadVisible(false);
        try {
            let url = FrontDesk_URL + 'labreport/ordersbillnotgenerated/' + fromDateSelected + '/' + toDateSelected + '/' + centreid + '/' + loginid
            await axios.get(url).then((response) => {
                const data = response.data;
                if (data.Success) {
                    if (data.Data != null) { setDownloadVisible(true); setCSVData(data.Data); document.getElementById("csvdownload").click(); }
                    else { setCSVData([]); }
                }
                else { setCSVData([]); }
            }).catch(function (error) { if (error.response) { setCSVData([]); setTableData([]); ErrorFunction(error.response.status); } });
        }
        catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    }
    const handleSearch = async () => {
        BindGrid(fromDate.current.value, toDate.current.value, localStorage.CentreSNo, localStorage.LoggedInUser);
    }
    const handleDownload = async () => {
        GetReportData(fromDate.current.value, toDate.current.value, localStorage.CentreSNo, localStorage.LoggedInUser);
    }
    const ErrorFunction = async (ErrorCode) => {
        if (ErrorCode == "400")
            SweetAlert.fire({ title: "Error 400", text: "Bad request", icon: "error" })
        else if (ErrorCode == "401")
            SweetAlert.fire({ title: "Error 401", text: "Unauthorized", icon: "error" })
        else if (ErrorCode == "403")
            SweetAlert.fire({ title: "Error 403", text: "Forbidden", icon: "error" })
        else if (ErrorCode == "404")
            SweetAlert.fire({ title: "Error 404", text: "Page not found", icon: "error" })
        else if (ErrorCode == "500")
            SweetAlert.fire({ title: "Error 500", text: "Internal Server Error", icon: "error" })
        else if (ErrorCode == "503")
            SweetAlert.fire({ title: "Error 503", text: "Service Unavailable", icon: "error" })
    }
    return (
        <>
            <React.Fragment>
                <Breadcrumb title="Order Generated & Bills Pending" parent="Reports" />
                <Container fluid>
                    <Row className='mt25'>
                        <Col md={12}>
                            <Card>
                                <Card.Body>
                                    <Form.Row>
                                        <Col md={3}>
                                            <Form.Group>
                                                <Form.Label>From Order Date</Form.Label>
                                                <InputGroup className="mb-3 d-flex justify-content-start">
                                                    <Form.Control type="date" id="fromDate" name="fromDate" ref={fromDate} max={defaultDate} />
                                                </InputGroup>
                                            </Form.Group>
                                        </Col>
                                        <Col md={3}>
                                            <Form.Group>
                                                <Form.Label>To Order Date</Form.Label>
                                                <InputGroup className="mb-3 d-flex justify-content-start">
                                                    <Form.Control type="date" id="toDate" name="toDate" ref={toDate} max={defaultDate} />
                                                </InputGroup>
                                            </Form.Group>
                                        </Col>
                                        <Col md={6} className='topmargin30'>
                                            <Button variant='secondary' onClick={handleSearch}>Search</Button>
                                            <Button className='ml-2' variant='primary' onClick={handleDownload}>Download CSV</Button>
                                        </Col>
                                        <Col lg={12} md={12}>
                                            {
                                                <DataTable
                                                    keyField='OrderId'
                                                    tableData={tableData}
                                                    columns={columns}
                                                    noDataIndication={NoRecordFounds}
                                                    pagination={paginationOptions ? paginationFactory(paginationOptions) : null}
                                                />
                                            }
                                        </Col>
                                    </Form.Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    {
                        downloadvisible &&
                        <CSVLink data={CSVData} filename="OrderGenerated-BillsPending.csv" target="_blank" id="csvdownload"></CSVLink>
                    }
                </Container>
            </React.Fragment>
        </>
    )
}

export default BillsPending