import React, { useState, useEffect, useContext, useRef } from 'react'
import Breadcrumb from '../../components/common/breadcrumb';
import SweetAlert from 'sweetalert2';
import { Container, Row, Col, Card, Button, Form } from 'react-bootstrap';
import axios from 'axios';
import { FrontDesk_URL, CallCentre_URL } from '../../constant';
import { Typeahead } from 'react-bootstrap-typeahead';
import InputGroup from 'react-bootstrap/InputGroup';
import SignUp from '../commonComponent/Phlebo/signup';
import AppointmentNewOrder from '../commonComponent/Phlebo/AppointmentNewOrder';

const NewOrder = () => {
    const [appointmentid, setAppointmentId] = useState("0");
    const [age, setAge] = useState("");
    const [gender, setGender] = useState("");
    const [centerid, setCenterId] = useState("0");
    const [userexistflag, setUserExistFlag] = useState("");
    const [moboruhidoptions, setMobOrUhidOptions] = useState([]);
    const [hidebackbutton, setHideBackButton] = useState(false);
    const [holdmobvalue, setHoldMobValue] = useState("");
    let moboruhid = useRef();
    let btnnew = useRef();
    useEffect(() => {
        ClearStoredState();       
        const pageAccessedByReload = (
            (window.performance.navigation && window.performance.navigation.type === 1) ||
            window.performance
                .getEntriesByType('navigation')
                .map((nav) => nav.type)
                .includes('reload')
        );
        if (pageAccessedByReload === true) {
            ClearStoredState();
            localStorage.removeItem("samplerid");
            localStorage.removeItem("sampledid");
            localStorage.removeItem("samplecid");
            localStorage.removeItem("sampleitemmasterdetails");
        }
        return () => {
            ClearStoredState();
            localStorage.removeItem("samplerid");
            localStorage.removeItem("sampledid");
            localStorage.removeItem("samplecid");
            localStorage.removeItem("sampleitemmasterdetails");
        }

    }, [])

    const ClearStoredState = () => {
        setAppointmentId("0");
        setAge("");
        setGender("");
        setCenterId("0"); 
        setMobOrUhidOptions([]);   
        localStorage.setItem('loginid', '0');
        localStorage.setItem('centreid', '0');
        localStorage.setItem('districtid', '0');
        localStorage.setItem('regionid', '0');
        localStorage.setItem('itemmasterdetails', []);
        localStorage.setItem('cartsummary', []);
        localStorage.removeItem('lid');
        localStorage.removeItem('cid');
        localStorage.removeItem('did');
        localStorage.removeItem('rid');
        localStorage.removeItem('itemmasterdetails');
        localStorage.removeItem("appdate");
        localStorage.removeItem("apptime");
        localStorage.removeItem("appaddress");
        localStorage.removeItem("apptimetext");
        localStorage.removeItem("addedmoreitems");
        localStorage.removeItem("RateTempId");
        localStorage.removeItem('aId');

    }
    const ClickSearchPatient = async () => {
        if (moboruhid.current.value != undefined) {
            if (moboruhid.current.value.length != "10") SweetAlert.fire('Patient mobile must be exactly 10 characters');
        }
    }
    const SearchPatientByMobNo = async (e) => {
        if (e.target.value.length == "10") {
            try {
                let url = CallCentre_URL + 'master/getsearchpatientbymobno/' + e.target.value
                await axios.get(url).then((response) => {
                    const data = response.data.Data;
                    if (response.data.Success === true) {
                        if (data !== null) {
                            var isexist = data.filter((d) => d.MobileNo === e.target.value);
                            if (isexist.length === 0) {
                                setHoldMobValue(e.target.value)
                                moboruhid.current.clear();
                                setMobOrUhidOptions([]);   
                                openSignUp(e.target.value);
                            }
                            else {     
                                setMobOrUhidOptions(data.filter((d) => d.MobileNo === e.target.value));
                                moboruhid.current.clear();
                                btnnew.current.focus();
                                setTimeout(() => {
                                    moboruhid.current.focus();
                                }, 100);
                            }
                        }
                        else {
                            setHoldMobValue(e.target.value);              
                            setMobOrUhidOptions([]);
                            moboruhid.current.clear();                
                            openSignUp(e.target.value);
                        }
                    }
                    else {      
                        setHoldMobValue(e.target.value)
                        setMobOrUhidOptions([]);
                        moboruhid.current.clear();              
                        openSignUp(e.target.value);
                    }
                }).catch(function (error) { if (error.response) { ErrorFunction(error.response.status); } });
            }
            catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
            e.preventDefault();
        }
    }
    const BindNewUserOrder = async (UserId, Age, Gender) => {
        localStorage.setItem("lid", UserId);
        localStorage.setItem('PatientId', UserId);
        setAge(Age);
        setGender(Gender);
        setCenterId(centerid);
        setHideBackButton(false);
        setAppointmentId("-1");
        setUserExistFlag("0");
    } 
    const openSignUp = async () => {
        setAppointmentId("-1");
        setUserExistFlag("");
    }
    const ErrorFunction = async (ErrorCode) => {
        if (ErrorCode == "400") {
            SweetAlert.fire({ title: "Error 400", text: "Bad request", icon: "error" })
        }
        else if (ErrorCode == "401") {
            SweetAlert.fire({ title: "Error 401", text: "Unauthorized", icon: "error" })
        }
        else if (ErrorCode == "403") {
            SweetAlert.fire({ title: "Error 403", text: "Forbidden", icon: "error" })
        }
        else if (ErrorCode == "404") {
            SweetAlert.fire({ title: "Error 404", text: "Page not found", icon: "error" })
        }
        else if (ErrorCode == "500") {
            SweetAlert.fire({ title: "Error 500", text: "Internal Server Error", icon: "error" })
        }
        else if (ErrorCode == "503") {
            SweetAlert.fire({ title: "Error 503", text: "Service Unavailable", icon: "error" })
        }
    }
    return (
        <>

            <Container fluid>
                <Row className='align-items-center pt-2 pb-2'>
                    <Col md={8}>
                        <Breadcrumb title="Phlebo" parent="New Order" />
                    </Col>
                    {
                        appointmentid === "0" ? null :
                            <>
                                {
                                    hidebackbutton === false ?
                                        <>
                                            <Col md={4} className='text-right'>
                                                <Button variant='secondary' type='submit' onClick={() => ClearStoredState()}>Back</Button>
                                            </Col>
                                        </> : null
                                }
                            </>
                    }
                </Row>
            </Container>
            <Container fluid>
                {
                    appointmentid === "-1" ?
                        <>
                            {
                                userexistflag === "" ?
                                    <SignUp appointmentid={(appointmentid) => setAppointmentId(appointmentid)}
                                        age={(age) => setAge(age)}
                                        gender={(gender) => setGender(gender)}
                                        centerid={(centerid) => setCenterId(centerid)}
                                        mobileNo={holdmobvalue}
                                        userexistflag={(userexistflag) => setUserExistFlag(userexistflag)}
                                        hidebackbutton={(hidebackbutton) => setHideBackButton(hidebackbutton)} /> :
                                    <AppointmentNewOrder AppointmentId={appointmentid} Age={age} Gender={gender} CenterId={centerid} UserExistFlag={userexistflag} Status="0" />
                            }

                        </>
                        :
                        <>
                            <Row>
                                <Col md={12}>
                                    <Card>
                                        <Card.Body>
                                            <Row className='justify-content-center align-items-center mt-3'>
                                                <Col lg={4} md={6} className='adPatientColLeft'>
                                                    <Form.Row>
                                                        <Col md={12}>
                                                            <Form.Group>
                                                                <InputGroup id='inpt'>
                                                                    <Typeahead
                                                                        id="mobuhid-typeahead"
                                                                        labelKey="PatientUHID"
                                                                        multiple={false}
                                                                        options={moboruhidoptions}
                                                                        ref={moboruhid}
                                                                        placeholder="Search by patient mobile"
                                                                        onInputChange={(text, e) => { SearchPatientByMobNo(e, text); }}
                                                                        onChange={(text) => { JSON.stringify(text) == "[]" ? BindNewUserOrder("0", "", "") : BindNewUserOrder(text[0].UserId, text[0].Age, text[0].Gender) }}
                                                                    />
                                                                    <InputGroup.Append>
                                                                        <Button variant="secondary" onClick={ClickSearchPatient}><i className='fa fa-search'></i></Button>
                                                                    </InputGroup.Append>
                                                                    <input type='button' className='hidebutton' style={{ backgroundColor: 'white !important' }} ref={btnnew} />
                                                                </InputGroup>
                                                            </Form.Group>
                                                        </Col>                                                      
                                                    </Form.Row>
                                                </Col>                                                
                                            </Row>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        </>
                }
            </Container>
        </>
    )
}

export default NewOrder;