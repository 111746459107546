import React from "react";
import { Container, Row, Col, Card, Form, Button, Table } from 'react-bootstrap';

class ConsentFormMaternalScreen extends React.Component {

    render() {

        const pdfProps = this.props;
        const patientName = pdfProps.PName;
        const age = pdfProps.Age;
        const gender = pdfProps.Gender;
        const refDoctor = pdfProps.RefDoctor;
        console.log(pdfProps);
        return (
            <>
                <div className="formWrapper">
                    <div className="header">
                        <div className="logo"> <img src="/static/media/logo-atulaya.fbfb36f9.png" alt="thumb" /> </div>
                        <div className="headinginnersec">
                            <h1> MATERNAL SCCREEN REQUEST FORM </h1>
                        </div>
                    </div>
                    <div className="main">
                        <div className="row mb-2">
                            <div className="col-sm-12"><b>(All information is mandatory)</b></div>
                            <div className="col-sm-6">Name of Patient <span className="dotedclass"><b>{patientName}</b></span></div>
                            <div className="col-sm-6"> Specimen Collected Date: <span className="dotedclass"></span></div>
                        </div>
                        <div className="row mb-2">
                                <div className="col-sm-6"> D.O.B:  <span className="dotedclass"></span></div>
                                <div className="col-sm-6"> LMP Date:  <span className="dotedclass"></span></div>
                        </div>
                        <div className="row mb-2">
                            <div className="col-sm-6"> Weight:<span className="dotedclass"></span></div>
                            <div className="col-sm-6"> Referring Doctor Name:<span className="dotedclass"><b>{refDoctor}</b></span></div>
                        </div>
                        <div className="row mt-3 mb-3">
                            <div className="col-sm-12"> <b> Maternal History: </b> </div>
                            <div className="col-sm-12"> Smoking <span className="checkboxclass"></span>&nbsp;&nbsp;&nbsp;&nbsp; Diabetic <span className="checkboxclass"></span> &nbsp;&nbsp;&nbsp;&nbsp; Gestation: Single <span className="checkboxclass"></span> &nbsp;&nbsp;&nbsp;&nbsp; Double <span className="checkboxclass"></span></div>
                            <div className="col-sm-12">Race  &nbsp;&nbsp; Asian <span className="checkboxclass"></span> &nbsp;&nbsp; Caucasian<span className="checkboxclass"></span>&nbsp;&nbsp; African<span className="checkboxclass"></span>&nbsp;&nbsp; Others<span className="checkboxclass"></span>&nbsp;&nbsp; Previous Trisomy 21 Pregnancy<span className="checkboxclass"></span></div>
                        </div>
                        <div className="row mt-3 mb-3">
                            <div className="col-sm-6"> IVF :<span className="checkboxclass"></span></div>
                            <div className="col-sm-6"> (If Yes)IVF Date <span className="dotedclass"></span></div>
                        </div>
                        <div className="row mt-3 mb-3">
                            <div className="col-sm-6">Donor D.O.B:<span className="dotedclass"></span></div>
                            <div className="col-sm-6">Donor Weight:<span className="dotedclass"></span></div>
                        </div>
                        <div className="row mt-3 mb-3">
                            <div className="col-sm-6">Initial Screening:&nbsp;&nbsp;&nbsp;&nbsp; Yes<span className="checkboxclass"></span> &nbsp;&nbsp;&nbsp;&nbsp; No <span className="checkboxclass"></span></div>
                            <div className="col-sm-6">Repeat Testing :&nbsp;&nbsp;&nbsp;&nbsp; Yes<span className="checkboxclass"></span> &nbsp;&nbsp;&nbsp;&nbsp; No <span className="checkboxclass"></span></div>
                        </div>
                        <div className="row mb-2">
                            <div className="col-sm-12"><b>Photocopy of latest USG report with mandatory information:-</b></div>
                        </div>
                        <div className="row mb-2">
                            <div className="col-sm-12"> Date of USG Reported            :</div>
                        </div>
                        <div className="row mb-2">
                            <div className="col-sm-12"> Mean Gestation Age           :</div>
                        </div>
                        <div className="row mb-2">
                            <div className="col-sm-12"> Number of Foetus            :</div>
                        </div>
                        <div className="row mb-2">
                            <div className="col-sm-12"> (Not valid for &gt;=3 foetus)           :</div>
                        </div>
                        <div className="row mb-2">
                            <div className="col-sm-12"> Crown rump length(CRL)(mm)         :</div>
                        </div>
                        <div className="row mb-2">
                            <div className="col-sm-12"> Nuchal Translucency(NT)(mm)         :</div>
                        </div>
                        <div className="row mb-2">
                            <div className="col-sm-12"> Nasal Bone(Present/Absent)         :</div>
                        </div>
                        <div className="row mb-2">
                            <div className="col-sm-12"> Biparietal Diameter(BPD)(mm)         :</div>
                        </div>
                        <div className="row mb-2">
                            <div className="col-sm-12"> Name & Qualification of Radiologist         :</div>
                        </div>
                        <div className="row mb-2">
                            <div className="col-sm-12"><b>CRL and NT Mandatory in USG conducted in First trimester(Before 13 weeks 6 days).</b></div>
                        </div>
                    </div>
                </div >
            </>
        );
    }
}

export default ConsentFormMaternalScreen;